import { __awaiter, __generator } from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import moment from 'moment';
// app
import { environment } from '@environment';
import { InsightsService } from './insights.service';
import { SnackbarService } from './snackbar.service';
import { locationRefsToPaths } from '../constants/firestore/location-object';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./session.service";
import * as i3 from "./insights.service";
import * as i4 from "@angular/fire/storage";
import * as i5 from "./snackbar.service";
var ReviewsService = /** @class */ (function () {
    function ReviewsService(http, _sessionS, insightS, afsStorage, snackS) {
        this.http = http;
        this._sessionS = _sessionS;
        this.insightS = insightS;
        this.afsStorage = afsStorage;
        this.snackS = snackS;
    }
    ReviewsService.prototype.aggregationRating = function (locations, dataPicker) {
        var timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var params = new HttpParams()
            .set('startDate', dataPicker.range.start)
            .set('endDate', dataPicker.range.end)
            .set('aggregate', dataPicker.aggregation.toLowerCase())
            .set('timezoneId', timezoneId);
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/aggregation/rating", body, {
            params: params,
        }).pipe(map(function (value) { return value.data; }));
    };
    // TODO: Unused (but referenced from commented code). Remove?
    // aggregationResponses(locations: LocationRef[], dataPicker: DataPicker) {
    //   const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //   let params = new HttpParams();
    //   if (dataPicker)
    //     params = (params.set('startDate',  dataPicker.range.start)
    //                     .set('endDate',    dataPicker.range.end)
    //                     .set('aggregate',  dataPicker.aggregation.toLowerCase())
    //                     .set('timezoneId', timezoneId))
    //
    //   const body = {locations: locationRefsToPaths(locations)}
    //
    //   return this.http.post<any>(`${environment.apiUrl}/v2/reviews/aggregation/responses`, body, { params });
    // }
    ReviewsService.prototype.aggregationNumber = function (locations, dataPicker) {
        var _a, _b, _c, _d, _e;
        var timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var params = new HttpParams()
            .set('startDate', (_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.range) === null || _b === void 0 ? void 0 : _b.start)
            .set('endDate', (_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.range) === null || _d === void 0 ? void 0 : _d.end)
            .set('aggregate', (_e = dataPicker) === null || _e === void 0 ? void 0 : _e.aggregation.toLowerCase())
            .set('timezoneId', timezoneId);
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/aggregation/number", body, {
            params: params,
        }).pipe(map(function (value) { return value.data; }));
    };
    ReviewsService.prototype.getRatingsAndReviewsData = function (accountId, gid, locationId, dataPicker) {
        var _a, _b, _c, _d, _e, _f;
        var body = {
            startDate: moment((_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.range) === null || _b === void 0 ? void 0 : _b.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            endDate: moment((_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.range) === null || _d === void 0 ? void 0 : _d.end).format('YYYY-MM-DD'),
            viewBy: (_f = ((_e = dataPicker) === null || _e === void 0 ? void 0 : _e.aggregation)) === null || _f === void 0 ? void 0 : _f.toLowerCase(),
            accountId: accountId,
            gid: gid,
            locationId: locationId
        };
        return this.http.post(environment.apiUrl + "/v2/reviews/ratings", body);
    };
    ReviewsService.prototype.getRatingsAndReviewsReportData = function (reportId, dataPicker) {
        var _a, _b;
        var body = {
            reportId: reportId,
            startDate: moment(dataPicker.range.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            endDate: moment(dataPicker.range.end, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            viewBy: (_b = ((_a = dataPicker) === null || _a === void 0 ? void 0 : _a.aggregation)) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
        };
        return this.http.post(environment.apiUrl + "/v3/reports/reviews", body);
    };
    ReviewsService.prototype.reportReviewList = function (locations, dataPicker, page, size, sort) {
        var params = new HttpParams()
            .set('startDate', dataPicker.range.start)
            .set('endDate', dataPicker.range.end);
        if (page && size) {
            params = params.append('page', page.toString());
            params = params.append('size', size.toString());
        }
        if (sort) {
            params = params.append('sortBy', sort.sortBy.toString());
            params = params.append('direction', sort.direction.toString());
        }
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/report/list", body, {
            params: params,
        }).pipe(map(function (value) { return value.data; }));
    };
    ReviewsService.prototype.ratingDistribution = function (locations) {
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/ratingDistribution", body);
    };
    ReviewsService.prototype.keywordsByStat = function (locations, dataPicker) {
        var params = (new HttpParams()
            .set('startDate', dataPicker.range.start)
            .set('endDate', dataPicker.range.end));
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/keywords/status", body, { params: params }).pipe(map(function (value) {
            return value.data;
        }));
    };
    ReviewsService.prototype.buildDate = function (date) {
        var d = date.includes('T') ? date.split('T') : date.split(' ');
        return d[0] + " 00:00:00";
    };
    ReviewsService.prototype.filter = function (locations, page, size, stars, filterStatus, hasResponse, hasComment, keyword, sort, gidExternal, start, end) {
        if (page === void 0) { page = null; }
        if (size === void 0) { size = null; }
        if (stars === void 0) { stars = null; }
        var _a, _b, _c, _d;
        start = !start ? null : start.includes('T') || start.includes(' ') ? this.buildDate(start) : start + " 00:00:00";
        end = !end ? null : end.includes('T') || end.includes(' ') ? this.buildDate(end) : end + " 00:00:00";
        var body = {
            "gids": [gidExternal],
            // TODO: Review backend endpoint implementation, maybe is mixing accountIds and locationIds, it must
            // be (locationRefs : [])       
            "accountIds": locations.map(function (l) { return l.accountId; }),
            "locationIds": locations.map(function (l) { return l.locationId; }),
            "filter": {
                "has_response": hasResponse,
                "has_comment": hasComment,
                "startDate": start,
                "endDate": end,
                "keyword": keyword,
                "status": ((_a = filterStatus) === null || _a === void 0 ? void 0 : _a.length) ? filterStatus : null,
                "stars": ((_b = stars) === null || _b === void 0 ? void 0 : _b.length) ? stars : null
            },
            "page": page,
            "pageSize": size,
            "sortDesc": (((_c = sort) === null || _c === void 0 ? void 0 : _c.direction) === 'asc'),
            "sortKey": (_d = sort) === null || _d === void 0 ? void 0 : _d.sortBy
        };
        return this.http.post(environment.coreApiUrl + "/reviews/list", body).pipe(map(function (value) { return value.data; }));
    };
    ReviewsService.prototype.protocolValidateReviews = function (gid, protocol) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.coreApiUrl + "/protocols/check/" + protocol)
                        .toPromise().then(function (response) { return response.data; })];
            });
        });
    };
    ReviewsService.prototype.protocolCheckCounterReview = function (protocol) {
        return this.http.post(environment.apiUrl + "/v2/reviews/check_review", { protocol: protocol }).toPromise().then(function (response) { return response.data; });
    };
    ReviewsService.prototype.changeViewed = function (reviewId, viewed) {
        return this.http.put(environment.apiUrl + "/v2/reviews/viewed", {
            reviewId: reviewId,
            viewed: viewed
        }).pipe(map(function (response) { return response.data; }));
    };
    ReviewsService.prototype.reply = function (accountId, locationId, reviewId, comment) {
        return this.http.put(environment.apiUrl + "/v2/reviews/" + accountId + "/" + locationId + "/" + reviewId + "/reply", {
            comment: comment,
        }).pipe(map(function (response) { return response.data; }));
    };
    ReviewsService.prototype.reply_with_errors = function (accountId, locationId, reviewId, comment, protocol, reply) {
        return this.http.post(environment.apiUrl + "/v2/reviews/" + accountId + "/" + locationId + "/" + reviewId + "/reply", {
            comment: comment,
        }).pipe(map(function (response) { return response.data; }));
    };
    ReviewsService.prototype.replyDelete = function (accountId, locationId, reviewId) {
        return this.http.delete(environment.apiUrl + "/v2/reviews/" + accountId + "/" + locationId + "/" + reviewId + "/reply")
            .pipe(map(function (response) { return response.data; }));
    };
    ReviewsService.prototype.saveReviews = function (accountId, locationId /*, isExternal = false*/) {
        var params = (new HttpParams().set('type', 'Reviews'));
        //const gid = isExternal ? this.auth.externalSession.gid : this.auth.session.gid
        var gid = this._sessionS.getSession().gid;
        return this.http.get(environment.coreApiUrl + "/update/" + gid + "/" + accountId + "/" + locationId, { params: params });
    };
    ReviewsService.prototype.exportCsvData = function (filename, data) {
        return this.http.post(environment.apiUrl + "/v2/reviews/export-csv-data", {
            file_name: filename,
            data: data
        });
    };
    ReviewsService.prototype.handleExportCsv = function (locations, page, size, stars, filterStatus, hasResponse, hasComment, keyword, sort, gidExternal, start, end, filename, reviewIds) {
        if (page === void 0) { page = null; }
        if (size === void 0) { size = null; }
        if (stars === void 0) { stars = null; }
        if (reviewIds === void 0) { reviewIds = null; }
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var body, res, filename2, filename3, err_1;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        body = {
                            "gids": [gidExternal],
                            // TODO: Review backend endpoint implementation, maybe is mixing accountIds and locationIds, it must
                            // be (locationRefs : []) 
                            "accountIds": locations.map(function (l) { return l.accountId; }),
                            "locationIds": locations.map(function (l) { return l.locationId; }),
                            "filter": {
                                "has_response": hasResponse,
                                "has_comment": hasComment,
                                "startDate": start,
                                "endDate": end,
                                "keyword": keyword,
                                "status": ((_a = filterStatus) === null || _a === void 0 ? void 0 : _a.length) ? filterStatus : null,
                                "stars": ((_b = stars) === null || _b === void 0 ? void 0 : _b.length) ? stars : null,
                                "review_ids": reviewIds
                            },
                            "filename": filename,
                            "page": page,
                            "pageSize": size,
                            "sortDesc": (((_c = sort) === null || _c === void 0 ? void 0 : _c.direction) === 'asc'),
                            "sortKey": (_d = sort) === null || _d === void 0 ? void 0 : _d.sortBy
                        };
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.downloadReviewCsv(body).toPromise()];
                    case 2:
                        res = _f.sent();
                        filename2 = (_e = res) === null || _e === void 0 ? void 0 : _e.data;
                        return [4 /*yield*/, this.afsStorage.ref(filename2).getDownloadURL().toPromise()];
                    case 3:
                        filename3 = _f.sent();
                        this.insightS.openDownloadInNewTab(filename3);
                        this.snackS.openSuccess("The file exported successfully");
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _f.sent();
                        console.error(err_1);
                        this.snackS.openError("There was an error in the export");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ReviewsService.prototype.downloadReviewCsv = function (body) {
        return this.http.post(environment.coreApiUrl + "/export/reviews", body);
    };
    ReviewsService.prototype.getUpdatedData = function (gid, accountId, locationId, reviewId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(environment.coreApiUrl + "/reviews/difference/" + gid + "/" + accountId + "/" + locationId + "/" + reviewId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ReviewsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReviewsService_Factory() { return new ReviewsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.InsightsService), i0.ɵɵinject(i4.AngularFireStorage), i0.ɵɵinject(i5.SnackbarService)); }, token: ReviewsService, providedIn: "root" });
    return ReviewsService;
}());
export { ReviewsService };
