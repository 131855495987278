<div class="dialog--ultimate">
	<div class="dialog__header">
		<img
				src="/assets/images/modal-rocket.png"
				alt="Image of a rocket."
				class="dialog__icon dialog__icon--top dialog__icon--sm"
				width="73"
				height="73"
		/>
		<button mat-dialog-close="" class="btn btn-dialog-close btn--absolute">
			<i class="txt--lg fal fa-times"></i>
		</button>
		<div class="txt--center">
			<h2 mat-dialog-title class="txt--xxl txt--white m--0">
				<strong>
					Current Plan
					<span *ngIf="titleCurrentPlan" class="d-block txt--price txt--uppercase mt--5">
						{{titleCurrentPlan}}
					</span>
				</strong>
			</h2>
			<p *ngIf="subscriptionTrialDays !== null" class="txt--white txt--md m--0">
				{{subscriptionTrialDays}} Days Left
			</p>
		</div>
	</div>
	<div class="dialog__content" mat-dialog-content>
		<div class="row box--height">

			<!--------------->
			<!-- ESSENTIAL -->			
			<!--------------->
			<div *ngIf="optionAction(PLAN.ESSENTIAL) !== null"
			     [ngClass]="optionsClass()">
				<div
						class="box box--rounded border--solid border--gray d-flex flex-column p--5"
				>
					<h2 class="heading--bigger mb--0 txt--center txt--dark height--m">
						<strong>Essential<span *ngIf="basicPlan" class="d-block txt--xl txt--green">FREE FOREVER</span></strong>
					</h2>
					<div class="essential-plan vh--24 mt--10 overflow--auto" [ngClass]="{'activated' : activated}">
						<ul class="list mb--5">
							<li class="list__item list__item--sm list__item--check mb--5">Last 12 Months of Insights & Reviews</li>
							<li class="list__item list__item--sm list__item--check mb--5">Listing Grader</li>
						</ul>
					</div>
					<button mat-flat-button color="accent" class="btn btn--full btn--xxl txt--md mt--10"
							(click)="handleSelectOption(PLAN.ESSENTIAL)">
						{{optionButtonLabel(PLAN.ESSENTIAL)}} to Essential
					</button>
					<div *ngIf="hasPricingURL" class="mt--15 txt--center">
						<a class="cursor--pointer txt--underline" (click)="navigationS.goToPricingWeb()">See full comparison</a>
					</div>
				</div>
			</div>

			<!--------------->
            <!-- BASIC     -->
			<!--------------->
			<div *ngIf="optionAction(PLAN.BASIC) !== null"
        		 [ngClass]="optionsClass()">
				<div
						class="box box--rounded border--solid border--gray d-flex flex-column p--5"
				>
					<h2 class="heading--bigger mb--0 txt--center txt--dark height--m">
						<strong>Basic<span *ngIf="basicPlan && !basicPlan.isFree" class="d-block txt--xl txt--green"><sup class="icon-price">$</sup>{{basicPlan?.amount}}/{{this.basicPlan?.interval}}</span></strong>
						<ngx-skeleton-loader *ngIf="!basicPlan" count="1" [theme]="{height: '35px', width: '150px', display: 'block', 'margin-left': 'auto', 'margin-right': 'auto'}"></ngx-skeleton-loader>
						<p *ngIf="basicPlan && !basicPlan.isFree" class="mb--0">Per Listing</p>
					</h2>
					<div class="basic-plan vh--24 mt--10 overflow--auto" [ngClass]="{'activated' : activated}">
						<ul class="list mb--5">
							<li class="list__item list__item--check mb--5">
								18+ Months of Insights & Reviews
							</li>
							<li class="list__item list__item--check mb--5">
								Unlimited Users
							</li>
							<li class="list__item list__item--check mb--5">
								Listing Grader
							</li>
							<li class="list__item list__item--check mb--5">
								Post Management + Scheduling
							</li>
							<li class="list__item list__item--check mb--5">
								Listing Defender
							</li>
							<li class="list__item list__item--check mb--5">
								Review Widgets
							</li>
							<li class="list__item list__item--check mb--5">
								Public Link Sharing
							</li>
							<li class="list__item list__item--check mb--5">
								Export Data to CSV/PDF
							</li>
						</ul>
					</div>
					<button mat-flat-button color="accent"
							class="btn btn--full btn--xxl txt--md mt--10"
							(click)="handleSelectOption(PLAN.BASIC)"
							[disabled]="!basicPlan">
						{{optionButtonLabel(PLAN.BASIC)}} to Basic
					</button>
					<div *ngIf="hasPricingURL" class="mt--15 txt--center">
						<a class="cursor--pointer txt--underline" (click)="navigationS.goToPricingWeb()">See full comparison</a>
					</div>
				</div>
			</div>

			<!--------------->
			<!-- ULTIMATE  -->
			<!--------------->
			<div *ngIf="optionAction(PLAN.ULTIMATE) !== null"
        		 [ngClass]="optionsClass()">
			<div class="box box--rounded border--dashed border--blue d-flex flex-column p--5 bg--lightblue bg--circles-blue">
					<h2 class="heading--bigger mb--0 txt--center txt--dark height--m">
						<strong>Ultimate<span *ngIf="ultimatePlan && !ultimatePlan.isFree" class="d-block txt--xl txt--blue"><sup>$</sup>{{ultimatePlan?.amount}}/{{ultimatePlan?.interval}}</span></strong>
						<ngx-skeleton-loader *ngIf="!ultimatePlan" count="1" [theme]="{height: '35px', width: '150px', display: 'block', 'margin-left': 'auto', 'margin-right': 'auto'}"></ngx-skeleton-loader>
						<p *ngIf="ultimatePlan && !ultimatePlan.isFree" class="mb--0">Per Listing</p>
					</h2>
					<div class="ultimate-plan vh--24 mt--10 overflow--auto" [ngClass]="{'activated' : activated}">
            			<p class="txt--center txt--md mb--5">All from Basic plus:</p>
						<ul class="list mb--5">
							<li class="list__item list__item--check mb--5">
								Unlimited Users
							</li>
							<li class="list__item list__item--check mb--5">
								Roll Up (Aggregated) Reports
							</li>
							<li class="list__item list__item--check mb--5">
								Comparison Reports
							</li>
							<li class="list__item list__item--check mb--5">
								Revenue Projection Calculator
							</li>
							<li class="list__item list__item--check mb--5">
								Review Assistant
							</li>
							<li class="list__item list__item--check mb--5">
								Bulk Post Management + Scheduling
							</li>
							<li class="list__item list__item--check mb--5">
								Bulk Listing Updates
							</li>
							<li class="list__item list__item--check mb--5">
								Priority Support
							</li>
						</ul>
					</div>
					<button mat-flat-button [color]="!ultimatePlan ? 'accent' : 'primary'"
							class="btn btn--full btn--xxl txt--md mt--10"
							(click)="handleSelectOption(PLAN.ULTIMATE)"
							[disabled]="!ultimatePlan">
						{{optionButtonLabel(PLAN.ULTIMATE)}} to Ultimate
					</button>
					<div *ngIf="hasPricingURL" class="mt--15 txt--center">
						<a class="cursor--pointer txt--underline" (click)="navigationS.goToPricingWeb()">See full comparison</a>
					</div>
				</div>
			</div>
		</div>

		<!---------------->
        <!-- Contact Us -->
		<!---------------->
		<div class="mt--20" *ngIf="(domain$ | async).hideContactUsButton">
			<p class="txt--center">
				<a (click)="navigationS.goToContactSupport()" class="cursor--pointer txt--underline">Contact us</a> if
				you have more than 10 locations
				for bulk pricing. White Label and Reseller packages are also
				available.
			</p>
		</div>
	</div>
</div>
