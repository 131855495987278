// dep
import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable } from 'rxjs';

// app
import {AuthService} from '../../services/auth.service';
import {LocationService} from '../../services/location.service';
import {ReviewsService} from '../../services/reviews.service';
import { SessionService } from 'src/app/services/session.service';
import { LocationRef } from 'src/app/constants/firestore/location-object';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent extends BaseComponent implements OnInit, OnChanges {
  // input data
  @Input() backColor;
  @Input() btnColor;
  @Input() btnTextColor;
  @Input() textColorAll;
  @Input() orderI: AbstractControl;
  @Input() startsI;
  @Input() responsesI;
  @Input() events: Observable<void>;

  location = null;
  locationId: string;
  reviews: any[];
  reviewsProgress = true;
  url: string;
  color: any;
  backgroundColor: any;
  buttonColor: any;
  textColor: any;
  buttonTextColor: any;
  form: FormGroup;
  selectedStarts: number[];
  average: number;
  _starts: any;
  _order: any = 'createTime';
  total: number;
  _responses: any;
  private accountId: string;
  private locations: LocationRef[];
  gid: string;

  get starts() {
    return this.startsI;
  }

  get order() {
    return this.orderI?.value;
  }


  get responses() {
    return this.responsesI?.value;
  }

  constructor(
    private reviewS: ReviewsService,
    private _sessionS: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private _authS: AuthService,
    private locationS: LocationService,
    private cdf: ChangeDetectorRef
  ) {
    super();
    const session = this._sessionS.getSession();

    this.locationId = this.route.parent.snapshot.params.locationId;
    this.accountId  = this.route.parent.snapshot.params.accountId;
    this.gid        = this.route.parent.snapshot.params.gid  || session.gid;
    // this.uid = this.route.parent.snapshot.queryParams.uid || session.uid;

    this.locations = [{accountId: this.accountId, locationId: this.locationId}];
    const order = this.route.parent.snapshot.queryParams.order;
    if (order) {
      this._order = order;
    }
    const starts = this.route.parent.snapshot.queryParams.filter;
    if (starts) {
      this._starts = starts;
      const nStarts = [];
      const selectedStarts = this._starts.split(',');
      selectedStarts.forEach(item => {
        nStarts.push(parseInt(item, 10));
      });
      this.selectedStarts = nStarts;
    }
    const backColor = this.route.parent.snapshot.queryParams.back_color;
    if (backColor) {
      this.backgroundColor = '#' + backColor;
    }
    const btnColor = this.route.parent.snapshot.queryParams.btn_color;
    if (btnColor) {
      this.buttonColor = '#' + btnColor;
    }
    const btnTextColor = this.route.parent.snapshot.queryParams.btn_txt_color;
    if (btnTextColor) {
      this.buttonTextColor = '#' + btnTextColor;
    }
    const textColorAll = this.route.parent.snapshot.queryParams.text_color;
    if (textColorAll) {
      this.textColor = '#' + textColorAll;
    }
  }

  async ngOnInit() : Promise<void> {
    this.cdf.detectChanges();
    if (!this.router.url.includes('location')) {
      await this._authS.signInAnonymously(this.gid);
    } else {
      this._subscribeSafe(this.events, () => this._filter());
    }

    this.locationS.review_summary(this.gid, this.locations).subscribe(resume => {
      this.average = resume?.averageRating;
      this.total   = resume?.totalReviewCount;
    });


    this._filter();
    this.locationS.getRef(this.gid, this.accountId, this.locationId).subscribe(location => {
      this.location = location;
      this.reviewsProgress = false;
    });

  }

  private async _filter() : Promise<void> {
    try {
      this.reviewsProgress = true;
      const sort = {
        'direction': null,
        'sortBy': this.order
      }

      const reviews = await this.reviewS.filter(this.locations, 1, null, this.selectedStarts, null, null, null, null, sort,  this.gid).toPromise();
      this.reviews = reviews?.items;
    } catch(err) {
      console.error(err);
    } finally {
      this.reviewsProgress = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.backColor) {
      this.backgroundColor = changes.backColor.currentValue;
    }
    if (changes.btnColor) {
      this.buttonColor = changes.btnColor.currentValue;
    }
    if (changes.btnTextColor) {
      this.buttonTextColor = changes.btnTextColor.currentValue;
    }
    if (changes.textColorAll) {
      this.textColor = changes.textColorAll.currentValue;
    }


    if (changes.orderI) {
      this._order = changes.orderI.currentValue;
    }

    if (changes.responsesI) {
      this._responses = changes.responsesI.currentValue;
    }
    if (changes.startsI) {
      this._starts = changes.startsI.currentValue;
      if (this._starts) 
        this.selectedStarts = this._starts.split(',').map(item => parseInt(item, 10))
    }
  }


}
