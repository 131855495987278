import { __assign, __awaiter, __extends, __generator, __values } from "tslib";
// dep
import { OnInit, OnDestroy, ChangeDetectorRef, ViewRef, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import moment from 'moment';
var FlexSearch = require('flexsearch');
// app
import { ReportService } from '../../services/report.service';
import { ModalCreateReportComponent } from '../modal-create-report/modal-create-report.component';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { SubscriptionService } from '../../services/subscription.service';
import { DatesService } from '../../services/dates.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { FeaturedReportComponent } from 'src/app/components/featured-report.component';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var ReportListComponent = /** @class */ (function (_super) {
    __extends(ReportListComponent, _super);
    function ReportListComponent(router, _sessionS, _dialog, _reportService, _modalService, _snackS, _locationService, _groupService, _apiSubscription, _dateService, _spinnerService, _cdRef, _renderer2) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this._sessionS = _sessionS;
        _this._dialog = _dialog;
        _this._reportService = _reportService;
        _this._modalService = _modalService;
        _this._snackS = _snackS;
        _this._locationService = _locationService;
        _this._groupService = _groupService;
        _this._apiSubscription = _apiSubscription;
        _this._dateService = _dateService;
        _this._spinnerService = _spinnerService;
        _this._cdRef = _cdRef;
        _this._renderer2 = _renderer2;
        _this.hasToggleMode = false;
        _this.checked = false;
        _this.flexSearch = new FlexSearch({
            encode: 'advanced',
            tokenize: 'reverse',
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'accountName',
                    'locName',
                    'address',
                    'reportName',
                    'labels',
                ]
            }
        });
        _this.sort = {
            sortBy: 'createdAt',
            direction: 'desc'
        };
        _this.progress = true;
        _this.reportsExists = false;
        _this.selectedSearch = new FormControl();
        _this.filtered = false;
        _this.reportData = {};
        _this.isSliderOpened = false;
        _this.viewModeChecked = 'new';
        _this.hasLegacy = false;
        _this.loadingLegacyFooter = false;
        _this.timeout = null;
        _this._backupData = [];
        _this._paginate = { page: 1, size: 10 };
        _this.session$ = _this._sessionS.session$;
        return _this;
    }
    ReportListComponent.prototype.selected = function () {
        var value = this.selectedSearch.value;
        this.router.navigateByUrl("/report/" + value.source);
    };
    ReportListComponent.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                if ((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('-report')) {
                    this.reportType = this.reportType.replace('-report', '');
                }
                switch (this.reportType) {
                    case 'qanda':
                        this.nameReportType = 'Questions And Answers';
                        break;
                    case 'performance-rollup':
                        this.nameReportType = 'Performance';
                        break;
                    default:
                        this.nameReportType = (_b = this.reportType) === null || _b === void 0 ? void 0 : _b.replace('-', ' ');
                        break;
                }
                this.viewModeChecked = this.router.url.includes('performance') || this.router.url.includes('keywords') || this.router.url.includes('qanda') || this.router.url.includes('review') ? 'new' : 'legacy';
                this._subscribeSafe(this._groupService.getReportIndexes(this._sessionS.getSession().gid, this.reportType), function (result) {
                    var e_1, _a;
                    var _b;
                    if (!((_b = result) === null || _b === void 0 ? void 0 : _b.length))
                        return;
                    _this.results = result;
                    try {
                        for (var result_1 = __values(result), result_1_1 = result_1.next(); !result_1_1.done; result_1_1 = result_1.next()) {
                            var index = result_1_1.value;
                            index.id = JSON.stringify(index);
                            index.address = _this._locationService.formatAddress(index.address);
                            _this._addDoc(index);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (result_1_1 && !result_1_1.done && (_a = result_1.return)) _a.call(result_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (_this.filtered)
                        _this.applyFilter(_this.searchText);
                });
                if (!this.filtered)
                    this._getData();
                return [2 /*return*/];
            });
        });
    };
    ReportListComponent.prototype.closeSliderReport = function () {
        var _a;
        this.isSliderOpened = false;
        this._getData();
        var body = (_a = document.getElementsByTagName('body')) === null || _a === void 0 ? void 0 : _a[0];
        this._renderer2.removeClass(body, 'overflow--hidden');
    };
    ReportListComponent.prototype.ngOnDestroy = function () {
        var content = document.getElementsByClassName('content');
        if (content.length > 0) {
            this._renderer2.removeClass(content[0], 'pr--0');
        }
        _super.prototype.ngOnDestroy.call(this);
    };
    ReportListComponent.prototype._addDoc = function (doc) {
        this.flexSearch.add(doc);
    };
    ReportListComponent.prototype._getData = function ($event) {
        var searchKeywords = null;
        var last = null;
        var next = null;
        if (this._previousPageable) {
            if (this._previousPageable.page < this.pagination.page) {
                if (this.dataSource.data) {
                    last = this.dataSource.data[this.pagination.per_page - 1];
                }
            }
            else if (this._previousPageable.page > this.pagination.page) {
                if (this.dataSource.data) {
                    next = this.dataSource.data[0];
                }
            }
        }
        if ($event) {
            searchKeywords = $event;
        }
        if (this._sessionS.hasSession()) {
            this.progress = true;
            if (this.reportType == 'keyword' || this.viewModeChecked == 'new') {
                this._getLegacy();
                this._getDataFromMongo();
            }
            else {
                this._getDataFromFire(last, next, searchKeywords);
            }
        }
    };
    ReportListComponent.prototype._getDataFromMongo = function (reportName) {
        if (reportName === void 0) { reportName = null; }
        return __awaiter(this, void 0, void 0, function () {
            var sort, res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.progress = true;
                        sort = this.sort.direction == 'asc' ? 1 : -1;
                        return [4 /*yield*/, this._reportService.getReportsFromMongo(this._sessionS.getSession().gid, this.reportType, this._paginate, this.sort.sortBy, sort, reportName)];
                    case 1:
                        res = _a.sent();
                        if (res && res['items']) {
                            res['items'].forEach(function (el) {
                                el.fromDate = _this.builDate(el.startDatetime),
                                    el.toDate = _this.builDate(el.endDatetime);
                            });
                            this.dataSource = new MatTableDataSource(res['items']);
                            this._previousPageable = { size: res['per_page'], page: res['page'] };
                            this.pagination = res;
                            this.errorMessage = false;
                            this.formatBasiclocations(res['items']);
                        }
                        else {
                            this.dataSource = new MatTableDataSource([]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.progress = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ReportListComponent.prototype._getDataFromFire = function (last, next, searchKeywords) {
        var _this = this;
        this._subscribeSafe(this._reportService.getByGIdAndType(this._sessionS.getSession().gid, this.reportType, this._paginate, last, next, searchKeywords, this.sort.sortBy, this.sort.direction), function (result) {
            result.items.forEach(function (el) {
                el.fromDate = _this.builDate(el.startDatetime),
                    el.toDate = _this.builDate(el.endDatetime);
            });
            _this._previousPageable = { size: result.per_page, page: result.page };
            _this.pagination = result;
            if (!_this.filtered) {
                _this.formatBasiclocations(result.items);
            }
            _this.manualPage = 1;
            _this.errorMessage = false;
        });
    };
    ReportListComponent.prototype._getLegacy = function () {
        var _this = this;
        this.loadingLegacyFooter = true;
        var reportTypeLegacy = this._getReportType();
        this._subscribeSafe(this._reportService.getByGIdAndTypeCount(this._sessionS.getSession().gid, reportTypeLegacy), function (res) {
            _this.hasLegacy = (res.size > 0);
            _this.loadingLegacyFooter = false;
        });
    };
    ReportListComponent.prototype.builDate = function (date) {
        if (date) {
            var s = date.split(date.includes('T') ? 'T' : ' ')[0].split('-');
            return s[1] + "-" + s[2] + "-" + s[0];
        }
        return '-';
    };
    ReportListComponent.prototype.handleReload = function ($event) {
        this._paginate = $event;
        this._getData();
    };
    ReportListComponent.prototype.formatBasiclocations = function (items) {
        this._updateTable(items);
    };
    ReportListComponent.prototype.checkLocations = function (items) {
        var e_2, _a, e_3, _b;
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                if (!_.isEmpty(item.accounts)) {
                    try {
                        for (var _c = (e_3 = void 0, __values(item.accounts)), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var account = _d.value;
                            var hasInvalidAccounts = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
                            var hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
                            if (hasInvalidAccounts || hasInvalidLocations) {
                                item.invalidLocations = true;
                            }
                            var hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
                            if (hasDuplicated) {
                                item.duplicatedLocations = true;
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
                else {
                    delete item.invalidLocations;
                    delete item.duplicatedLocations;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    ReportListComponent.prototype.filterByLocationMember = function (items) {
        var e_4, _a;
        var user = this._sessionS.getSession().user;
        var memberLocations = [];
        user.accounts.forEach(function (account) {
            account.locations.forEach(function (location) {
                memberLocations.push(location.locationId);
            });
        });
        var validReports = [];
        var _loop_1 = function (item) {
            var e_5, _a;
            var reportLocations = [];
            if (!_.isEmpty(item.accounts)) {
                try {
                    for (var _b = (e_5 = void 0, __values(item.accounts)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var account = _c.value;
                        account.locations.forEach(function (location) {
                            reportLocations.push(location.locationId);
                        });
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                if (reportLocations.every(function (locationId) { return memberLocations.includes(locationId); })) {
                    validReports.push(item);
                }
            }
        };
        try {
            for (var items_2 = __values(items), items_2_1 = items_2.next(); !items_2_1.done; items_2_1 = items_2.next()) {
                var item = items_2_1.value;
                _loop_1(item);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (items_2_1 && !items_2_1.done && (_a = items_2.return)) _a.call(items_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return validReports;
    };
    ReportListComponent.prototype.sortData = function (sort) {
        this.sort = {
            sortBy: sort.active,
            direction: sort.direction,
        };
        var data = this._backupData.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }
        if (this.filtered) {
            this.dataSource.data = _.orderBy(data, [sort.active], [sort.direction]);
        }
        else {
            return this._getData(null);
        }
    };
    ReportListComponent.prototype._updateTable = function (items) {
        if (!this._sessionS.getSession().isAdmin) {
            items = this.filterByLocationMember(items);
        }
        // data for table
        if (this.reportType === 'Review Assistant') {
            this.displayedColumns = ['name', 'created', 'modified', 'locations', 'actions'];
        }
        else {
            this.displayedColumns = ['name', 'created', 'modified', 'locations', 'date', 'actions'];
        }
        this.progress = false;
        this.dataSource = new MatTableDataSource(items);
        this._backupData = this.dataSource.data;
        this.dataSource.paginator = this.paginator;
        this.reportsExists = !!(this.dataSource.data.length);
        this.progress = false;
    };
    // apply filter from search  
    // Legacy reports do not support filtering due to the unmaintained Firestore index. 
    ReportListComponent.prototype.applyFilter = function (inputValue) {
        var _this = this;
        var _a;
        clearTimeout(this.timeout);
        // Use a trimmed version to avoid trailing and leading spaces
        var trimmedValue = (_a = inputValue) === null || _a === void 0 ? void 0 : _a.trim();
        this.timeout = setTimeout(function () {
            if (!trimmedValue) {
                _this._getData();
                _this.filtered = false;
            }
            else {
                _this._getDataFromMongo(trimmedValue.toLowerCase());
            }
        }, 350);
    };
    ReportListComponent.prototype.openCreateReportDialog = function () {
        var dialogRef = this._dialog.open(ModalCreateReportComponent, {
            width: '680px',
            data: { reportType: this.reportType }
        });
        dialogRef.disableClose = true;
    };
    ReportListComponent.prototype.openSliderReport = function (drawer, element, stepLocations) {
        var _a;
        var body = (_a = document.getElementsByTagName('body')) === null || _a === void 0 ? void 0 : _a[0];
        this._renderer2.addClass(body, 'overflow--hidden');
        this.reportData = element ? { reportType: this.reportType, edit: element, stepLocations: stepLocations } : { reportType: this.reportType };
        this.isSliderOpened = true;
        drawer.toggle();
    };
    ReportListComponent.prototype.showDeleteDialog = function (element) {
        var _this = this;
        var data = { name: element.reportName };
        var dialog = this._modalService.openGenericModal(ModalDeleteComponent, data, null, 680);
        var subscription = dialog.componentInstance.deleted.pipe(filter(function (r) { return r; })).subscribe(function () {
            if (_this.reportType !== 'keyword' && _this.viewModeChecked != 'new') {
                _this.delete(element.gid, element.id).then(function () {
                    dialog.componentInstance.complete();
                    setTimeout(function () {
                        _this.ngOnInit();
                        dialog.close();
                        subscription.unsubscribe();
                    }, 800);
                });
            }
            else {
                _this._reportService.deleteFromMongo(element['_id']).subscribe(function (res) {
                    dialog.componentInstance.complete();
                    setTimeout(function () {
                        _this.ngOnInit();
                        dialog.close();
                        subscription.unsubscribe();
                    }, 800);
                });
            }
        });
    };
    ReportListComponent.prototype.delete = function (gid, reportId) {
        return this._reportService.delete(gid, reportId);
    };
    ReportListComponent.prototype.countLocation = function (element) {
        return this._reportService.countLocation(element);
    };
    ReportListComponent.prototype.handleShared = function (element) {
        var _a;
        var id = element.id || element._id;
        var dataPicker = this._reportService.reportToDataPicker(element, id, false, this.viewModeChecked);
        this._modalService.openGenericModal(ModalShareComponent, {
            createNew: false,
            dataPicker: dataPicker,
            placeId: dataPicker.locations,
            reportType: this.reportType,
            reportName: (_a = element) === null || _a === void 0 ? void 0 : _a.reportName,
            report: __assign({}, element)
        });
    };
    ReportListComponent.prototype.copy = function (element, type) {
        var _this = this;
        if (type === void 0) { type = null; }
        var _a, _b, _c, _d, _e;
        var copyElement;
        if (!element) {
            return;
        }
        else {
            copyElement = _.clone(element);
        }
        if (type) {
            copyElement.reportType = type;
        }
        copyElement.reportName += '(Copy)';
        copyElement.accounts = this._locationService.deleteServiceArea(copyElement.accounts);
        copyElement.accounts = this._locationService.deleteAddress(copyElement.accounts);
        delete copyElement['id'];
        delete copyElement['_id'];
        if (type && type != 'keyword' && (!type && this.viewModeChecked != 'new')) {
            this.prepareToSave(copyElement);
            this._reportService.save(this._sessionS.getSession().gid, copyElement).then(function (result) {
                _this._snackS.openSuccess('The report was copied successfully');
                _this.ngOnInit();
            });
        }
        else {
            if (this.reportType == 'performance-comparison') {
                copyElement['metrics'] = copyElement['metricsName'];
                delete copyElement['metricsName'];
            }
            else {
                copyElement['metrics'] = [];
                copyElement['metricsName'] = [];
            }
            if (type == "performance-comparison") {
                copyElement.dynamicRange = null;
                if (!((_a = copyElement) === null || _a === void 0 ? void 0 : _a.dynamic)) {
                    var data = this._reportService.periodChange('period', (_b = element) === null || _b === void 0 ? void 0 : _b.startDatetime, (_c = element) === null || _c === void 0 ? void 0 : _c.endDatetime);
                    copyElement.period = 'period';
                    copyElement.startDatetimeB = (_d = data) === null || _d === void 0 ? void 0 : _d.startDateB;
                    copyElement.endDatetimeB = (_e = data) === null || _e === void 0 ? void 0 : _e.endDateB;
                }
            }
            if (type == 'keyword') {
                var startDatetime = moment(element.startDatetime).startOf('month');
                copyElement.startDatetime = startDatetime.format('YYYY-MM-DD') + " 00:00:00";
                var endDatetime = moment(element.endDatetime).endOf('month');
                copyElement.endDatetime = endDatetime.format('YYYY-MM-DD') + " 00:00:00";
            }
            this._reportService.saveReportInMongo(copyElement).subscribe(function (res) {
                var _a;
                if ((_a = res) === null || _a === void 0 ? void 0 : _a.error) {
                    _this._snackS.openError('There was an error while trying to copy the report', 3000);
                }
                else {
                    _this._snackS.openSuccess('The report was copied successfully', 3000);
                    _this.ngOnInit();
                }
            });
        }
    };
    ReportListComponent.prototype.action = function (element, drawer) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isTrial, isAdmin, location, isAllLocationsUltimate, response, locations_1, message;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this._sessionS.getSession(), isTrial = _a.isTrial, isAdmin = _a.isAdmin;
                        this._spinnerService.loading$.next(true);
                        location = this.formatLocationsPath(element.accounts);
                        return [4 /*yield*/, this._locationService.isAllLocationsUltimate(location)];
                    case 1:
                        isAllLocationsUltimate = _b.sent();
                        this.checkLocations([element]);
                        this._spinnerService.loading$.next(false);
                        if (!(!isTrial && !isAllLocationsUltimate)) return [3 /*break*/, 9];
                        if (!!isAdmin) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._modalService.openInfoModal('Contact your administrator', 'To access this report, you must upgrade all locations to the Ultimate plan or remove them from the report.' +
                                'Your user doesn’t have permissions to do this.')];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                    case 3: return [4 /*yield*/, this._modalService.openModal(FeaturedReportComponent, null)];
                    case 4:
                        response = _b.sent();
                        if (!(response === 'upgrade')) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.getLocationsToUpgrade(element.accounts)];
                    case 5:
                        locations_1 = _b.sent();
                        message = 'Are you sure you want to upgrade ' + (locations_1.length === 1 ?
                            'this location?' :
                            "these " + locations_1.length + " locations?");
                        return [4 /*yield*/, this._modalService.openConfirmModal('Upgrade', message)];
                    case 6:
                        if (_b.sent()) {
                            delete element.needToUpgrade;
                            // tslint:disable-next-line: no-shadowed-variable
                            locations_1.forEach(function (location, i) {
                                _this._apiSubscription.upgradeLocation(location.locationId, location.accountId, element.gid).then(function () {
                                    return i === locations_1.length - 1 && _this._snackS.openSuccess('Locations successfully upgraded');
                                }, function (err) {
                                    _this._snackS.openError('There was a problem upgrading locations');
                                });
                            });
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        if (response === 'edit') {
                            this.openSliderReport(drawer, element, false);
                        }
                        _b.label = 8;
                    case 8: return [2 /*return*/];
                    case 9:
                        if (!(this.reportType !== 'Review Assistant')) return [3 /*break*/, 22];
                        if (!((!element.startDatetime || !element.endDatetime) && this.reportType != 'qanda')) return [3 /*break*/, 10];
                        this._modalService.openWarningModal('Alert', 'Please edit this report and set a valid start and end date.');
                        return [3 /*break*/, 21];
                    case 10:
                        if (!(this.reportType == 'performance-comparison' && (!element.startDatetimeB || !element.endDatetimeB))) return [3 /*break*/, 11];
                        this._modalService.openWarningModal('Alert', 'Please edit this report and set a valid start and end date.');
                        return [3 /*break*/, 21];
                    case 11:
                        if (!(this.reportType == 'performance-comparison' && !element.metricsName.length)) return [3 /*break*/, 12];
                        this._modalService.openWarningModal('Alert', 'Please edit this report and select the metrics you wish to compare.');
                        return [3 /*break*/, 21];
                    case 12:
                        if (!(this.countLocation(element) == 0)) return [3 /*break*/, 13];
                        this._modalService.openWarningModal('Heads up', 'This report has no locations selected. Please edit the report and choose at least 1 location before trying to access the report again.');
                        return [3 /*break*/, 21];
                    case 13:
                        if (!_.has(element, 'invalidLocations')) return [3 /*break*/, 15];
                        return [4 /*yield*/, this._modalService.openConfirmModal('Alert', 'This report contains locations that are no longer available. They will be removed from the report.')];
                    case 14:
                        if (_b.sent())
                            this._validateLocations(element, 'invalid');
                        return [3 /*break*/, 21];
                    case 15:
                        if (!_.has(element, 'duplicatedLocations')) return [3 /*break*/, 17];
                        return [4 /*yield*/, this._modalService.openConfirmModal('Alert', 'This report contains duplicated locations. They will be unified.')];
                    case 16:
                        if (_b.sent())
                            this._validateLocations(element, 'duplicated');
                        return [3 /*break*/, 21];
                    case 17:
                        if (!(this.viewModeChecked == 'new')) return [3 /*break*/, 19];
                        return [4 /*yield*/, this.router.navigateByUrl("/report/" + element.gid + "/" + (element.id || element._id) + "/" + this.reportType)];
                    case 18:
                        _b.sent();
                        return [3 /*break*/, 21];
                    case 19: return [4 /*yield*/, this.router.navigateByUrl("/report/" + element.gid + "/" + (element.id || element._id) + "/" + this.reportType)];
                    case 20:
                        _b.sent();
                        _b.label = 21;
                    case 21: return [3 /*break*/, 28];
                    case 22:
                        if (!_.has(element, 'invalidLocations')) return [3 /*break*/, 24];
                        return [4 /*yield*/, this._modalService.openConfirmModal('Alert', 'This report contains locations that are no longer available. ' +
                                'They will be removed from the report.')];
                    case 23:
                        if (_b.sent())
                            this._validateLocations(element, 'invalid');
                        return [3 /*break*/, 28];
                    case 24:
                        if (!_.has(element, 'duplicatedLocations')) return [3 /*break*/, 26];
                        return [4 /*yield*/, this._modalService.openConfirmModal('Alert', 'This report contains duplicated locations. They will be unified.')];
                    case 25:
                        if (_b.sent())
                            this._validateLocations(element, 'duplicated');
                        return [3 /*break*/, 28];
                    case 26: return [4 /*yield*/, this.router.navigate(['/report/' + element.gid + '/' + element.id])];
                    case 27:
                        _b.sent();
                        _b.label = 28;
                    case 28: return [2 /*return*/];
                }
            });
        });
    };
    ReportListComponent.prototype.formatLocationsPath = function (accounts) {
        var e_6, _a, e_7, _b;
        var formated = [];
        try {
            for (var accounts_1 = __values(accounts), accounts_1_1 = accounts_1.next(); !accounts_1_1.done; accounts_1_1 = accounts_1.next()) {
                var account = accounts_1_1.value;
                try {
                    for (var _c = (e_7 = void 0, __values(account.locations)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var location_1 = _d.value;
                        formated.push(account.gid + "/" + account.accountId + "/" + location_1.locationId);
                    }
                }
                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_7) throw e_7.error; }
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (accounts_1_1 && !accounts_1_1.done && (_a = accounts_1.return)) _a.call(accounts_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        return formated;
    };
    ReportListComponent.prototype.getLocationsToUpgrade = function (accounts) {
        return __awaiter(this, void 0, void 0, function () {
            var locations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._locationService.basicLocations([{ accounts: accounts }])];
                    case 1:
                        _a.sent();
                        locations = [];
                        accounts.forEach(function (account) {
                            var free = _.pull(account.locationsBasics.map(function (location) { return (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL ||
                                location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC) && {
                                locationId: location.locationId,
                                accountId: account.accountId
                            }; }), false);
                            if (!_.isEmpty(free)) {
                                locations = Object.assign(locations, free);
                            }
                        });
                        return [2 /*return*/, locations];
                }
            });
        });
    };
    ReportListComponent.prototype._validateLocations = function (element, action) {
        var e_8, _a, e_9, _b;
        var _c;
        if (action === 'invalid') {
            try {
                for (var _d = __values(element.accounts), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var account = _e.value;
                    if (_.isEqual(_.get(account, 'accountId'), 'accounts')) {
                        element.accounts = _.pull(element.accounts, account);
                    }
                    else {
                        var validLocations = _.filter(account.locations, function (o) {
                            return o.accountId !== 'accounts' && o.locationId !== 'locations';
                        });
                        if (_.isEmpty(validLocations)) {
                            element.accounts = _.pull(element.accounts, account);
                        }
                        else {
                            account.locations = validLocations;
                        }
                    }
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_8) throw e_8.error; }
            }
            delete element.invalidLocations;
        }
        else {
            try {
                for (var _f = __values(element.accounts), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var account = _g.value;
                    account.locations = _.uniqBy(account.locations, 'locationId');
                    account.locationsBasics = _.uniqBy(account.locationsBasics, 'locationId');
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_9) throw e_9.error; }
            }
            delete element.duplicatedLocations;
        }
        element.startDatetime && (element.startDatetime = moment(element.startDatetime).utc().toISOString());
        element.endDatetime && (element.endDatetime = moment(element.endDatetime).utc().toISOString());
        if (((_c = element) === null || _c === void 0 ? void 0 : _c.reportType) === 'comparison' && element.startDatetimeB && element.endDatetimeB) {
            element.startDatetimeB = moment(element.startDatetimeB).utc().toISOString();
            element.endDatetimeB = moment(element.endDatetimeB).utc().toISOString();
        }
        this._reportService.update(false, this._sessionS.getSession().gid, element.id, element);
    };
    ReportListComponent.prototype.display = function (value) {
        if (!value) {
            return null;
        }
        return value.type + ": " + value.reportName;
    };
    ReportListComponent.prototype.getSearchReports = function (reports) {
        var result = [];
        reports.forEach(function (report) {
            var source = _.split(report.source, '/');
            var value = __assign(__assign({}, report), { id: source[1] });
            result = result.concat(value);
        });
        return result;
    };
    ReportListComponent.prototype.prepareToSave = function (report) {
        var e_10, _a;
        var accounts = report.accounts;
        if (accounts && !_.isEmpty(accounts)) {
            try {
                for (var accounts_2 = __values(accounts), accounts_2_1 = accounts_2.next(); !accounts_2_1.done; accounts_2_1 = accounts_2.next()) {
                    var account = accounts_2_1.value;
                    delete account.locationsBasics;
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (accounts_2_1 && !accounts_2_1.done && (_a = accounts_2.return)) _a.call(accounts_2);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
        delete report.updateAt;
        report.createdAt = this._dateService.nowTimestamp;
    };
    // toggleModePerformaceLegacy(): void {
    //   this.reportType = this._getReportType();
    //   this.router.navigateByUrl(`/${this.reportType}-report`).then();
    //   this.detectChanges();
    //   //this.ngOnInit();
    // }
    ReportListComponent.prototype.detectChanges = function () {
        if (this._cdRef !== null &&
            this._cdRef !== undefined &&
            !this._cdRef.destroyed) {
            this._cdRef.detectChanges();
        }
    };
    ReportListComponent.prototype._getReportType = function () {
        var _a;
        return this.viewModeChecked == 'new' ? (_a = this.reportType) === null || _a === void 0 ? void 0 : _a.slice("performance-".length) : "performance-" + this.reportType;
    };
    // TODO: Getter used on angular templates, slow.
    ReportListComponent.prototype.hasDateInFire = function () {
        return (this.viewModeChecked === 'legacy');
    };
    return ReportListComponent;
}(BaseComponent));
export { ReportListComponent };
