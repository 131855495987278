// dep
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

// app
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LoadingService } from '../../services/loading.service';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-grade-metrics',
  templateUrl: './grade-metrics.component.html',
  styleUrls: ['./grade-metrics.component.scss']
})
export class GradeMetricsComponent implements OnInit {
  metrics: any = false;
  progress = true;
  hasError = false;
  @Input() gid: any;
  @Input() accountId: any;
  @Input() locationId: any;
  @Input() dataPicker: any;
  @Input() isReport: boolean;
  @Input() isExternal = false;
  @Output() finish = new EventEmitter();
  @Output() grade = new EventEmitter();
  locationType: string;

  constructor(
    private googleS: GoogleService,
    private loadingService: LoadingService,
    public snackService: SnackbarService,
    private _sessionS : SessionService,
    private gradeLoaded: GradeLoadedService
    ) {
  }

  ngOnInit() {
    this.getMetrics();
  }

  async getMetrics() : Promise<void> {
    try {
      this.progress = true;
      this.hasError = false;  
      const result = await this.googleS.metrics(this.accountId, this.locationId, this.dataPicker);
      const metrics = _.omitBy(result, _.isNil);
      this.metrics = this._calculateGrade(metrics);
      this.progress = false;
      this.finish.emit(true);
      this.loadingService.reportAdvanced(1, "Metrics")
      this.gradeLoaded.loaded$.next(true);
    } catch(error) {
      this.hasError = true;
      this.progress = false;
      this.finish.emit(true);
      this.snackService.openError(error.error.message);
    }
  }

  private _calculateGrade(metrics) {
    const totals = Object.keys(metrics).reduce((total, prop) => {
      return total + metrics[prop].total;
    }, 0);

    const maxs = Object.keys(metrics).reduce((max, prop) => {
      const value = metrics[prop].max || 0;
      return max + value;
    }, 0);
    this.grade.emit({ maxs, totals });
    
    return metrics;
  }

}
