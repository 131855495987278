import { MatDialog, MatDialogRef } from '@angular/material';
export var SECTION_DATA = [];
var ModalMenuComponent = /** @class */ (function () {
    function ModalMenuComponent(dialogRef, dialog, data) {
        var _a, _b;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.foodMenu = [];
        this.onlyShow = false;
        this.error = {};
        this.error_details = [];
        if (this.data) {
            this.onlyShow = this.data.onlyShow;
            this.foodMenu = this.data.foodMenu;
            this.accountId = this.data.accountId;
            this.locationId = this.data.locationId;
            this.error = (_a = this.data) === null || _a === void 0 ? void 0 : _a.error;
            this.error_details = (_b = this.data) === null || _b === void 0 ? void 0 : _b.error_details;
        }
    }
    ModalMenuComponent.prototype.apply = function () {
    };
    return ModalMenuComponent;
}());
export { ModalMenuComponent };
