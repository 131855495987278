import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/app';
// app
import { NavigationService } from 'src/app/services/navigation.service';
import { VerificationEmailService } from 'src/app/services/verification-email.service';
import { SessionService } from 'src/app/services/session.service';
var VerifyEmailComponent = /** @class */ (function () {
    function VerifyEmailComponent(_route, _verificationService, _sessionS, navigationS) {
        this._route = _route;
        this._verificationService = _verificationService;
        this._sessionS = _sessionS;
        this.navigationS = navigationS;
        this.domain$ = this._sessionS.domain$;
    }
    VerifyEmailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hash, ver;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loginProgress = true;
                        hash = this._route.snapshot.queryParams.hash;
                        return [4 /*yield*/, this._verificationService.getVerificationToHash(hash).toPromise()];
                    case 1:
                        ver = _a.sent();
                        ver.forEach(function (v) {
                            if (!v) {
                                _this.verified = false;
                                return;
                            }
                            var data = v.data();
                            if (data.emailVerified == null) {
                                _this._verificationService.update(v.id, { 'emailVerified': firebase.firestore.Timestamp.now() });
                            }
                            _this.verified = true;
                        });
                        this.loginProgress = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return VerifyEmailComponent;
}());
export { VerifyEmailComponent };
