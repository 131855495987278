/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./info-tooltip.component";
var styles_InfoTooltipComponent = [i0.styles];
var RenderType_InfoTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoTooltipComponent, data: {} });
export { RenderType_InfoTooltipComponent as RenderType_InfoTooltipComponent };
export function View_InfoTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "span", [["class", "mat-tooltip tooltip tooltip--info--dark"], ["matTooltipClass", "mat-tooltip--override"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"], tooltipClass: [2, "tooltipClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [["class", "fas fa-info"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.position; var currVal_1 = _co.tooltip; var currVal_2 = "mat-tooltip--override"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_InfoTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-tooltip", [], null, null, null, View_InfoTooltipComponent_0, RenderType_InfoTooltipComponent)), i1.ɵdid(1, 49152, null, 0, i9.InfoTooltipComponent, [], null, null)], null, null); }
var InfoTooltipComponentNgFactory = i1.ɵccf("app-info-tooltip", i9.InfoTooltipComponent, View_InfoTooltipComponent_Host_0, { tooltip: "tooltip", position: "position" }, {}, []);
export { InfoTooltipComponentNgFactory as InfoTooltipComponentNgFactory };
