import { __awaiter, __generator } from "tslib";
// dep
import { ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// app
import { ModalService } from '../services/modal.service';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { FeaturedComponent } from '../components/featured.component';
import { SessionService } from '../services/session.service';
var LocationGatedFreeDirective = /** @class */ (function () {
    function LocationGatedFreeDirective(_sessionS, route, modalService, locationService, subscriptionService, element) {
        this._sessionS = _sessionS;
        this.route = route;
        this.modalService = modalService;
        this.locationService = locationService;
        this.subscriptionService = subscriptionService;
        this.element = element;
        this.denyAccess = new EventEmitter();
    }
    LocationGatedFreeDirective.prototype.ngOnInit = function () {
        var _a, _b, _c;
        var locationLGF = this.gmbLGF.location;
        var placeId = ((_a = locationLGF) === null || _a === void 0 ? void 0 : _a.locationId) || this.route.snapshot.parent.params.locationId;
        var account = this.gmbLGF.account || this.route.snapshot.parent.params.accountId;
        var checked = (_b = locationLGF) === null || _b === void 0 ? void 0 : _b.checked;
        if (!placeId || !account || checked) {
            return;
        }
        this.location = this.gmbLGF.location;
        var subscriptionType = (_c = this.location) === null || _c === void 0 ? void 0 : _c.subscriptionType;
        var _d = this._sessionS.getSession(), subscription = _d.subscription, isTrial = _d.isTrial;
        if (subscription.pricingVersion < 3 && !isTrial &&
            (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
            this.element.nativeElement.removeAllListeners('click');
            this.element.nativeElement.addEventListener('click', this.handler.bind(this));
            this.denyAccess.emit(true);
            return;
        }
        this.denyAccess.emit(false);
    };
    LocationGatedFreeDirective.prototype.ngOnChanges = function (changes) {
        if (!changes.gmbLGF.firstChange) {
            this.ngOnInit();
        }
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationGatedFreeDirective.prototype.handler = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var locationLGF, locationId, accountId, _b, isTrial, gid, _c, subscriptionType;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        console.log('LocationGatedFreeDirective handler');
                        locationLGF = this.gmbLGF.location;
                        locationId = ((_a = locationLGF) === null || _a === void 0 ? void 0 : _a.locationId) || this.route.snapshot.parent.params.locationId;
                        accountId = this.gmbLGF.account || this.route.snapshot.parent.params.accountId;
                        _b = this._sessionS.getSession(), isTrial = _b.isTrial, gid = _b.gid;
                        _c = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(gid, accountId, locationId)];
                    case 1:
                        _c.location = _d.sent();
                        subscriptionType = this.location.subscriptionType;
                        if (!(!isTrial && (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                            subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.modalService.openModal(FeaturedComponent, null)];
                    case 2:
                        if (!(_d.sent()))
                            return [2 /*return*/];
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan([{ locationId: locationId, accountId: accountId }])];
                    case 3:
                        if (_d.sent()) {
                            this.denyAccess.emit(false);
                            this.element.nativeElement.removeAllListeners('click');
                        }
                        _d.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return LocationGatedFreeDirective;
}());
export { LocationGatedFreeDirective };
var LocationGatedUltimateTrialDirective = /** @class */ (function () {
    function LocationGatedUltimateTrialDirective(_sessionS, route, modalService, locationService, subscriptionService, element) {
        this._sessionS = _sessionS;
        this.route = route;
        this.modalService = modalService;
        this.locationService = locationService;
        this.subscriptionService = subscriptionService;
        this.element = element;
        this.gmbLGUF = function () { };
    }
    LocationGatedUltimateTrialDirective.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locationId, accountId, _a, gid, subscription, isTrial, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        locationId = this.route.snapshot.parent.params.locationId;
                        accountId = this.route.snapshot.parent.params.accountId;
                        if (!locationId || !accountId) {
                            return [2 /*return*/];
                        }
                        _a = this._sessionS.getSession(), gid = _a.gid, subscription = _a.subscription, isTrial = _a.isTrial;
                        _b = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(gid, accountId, locationId)];
                    case 1:
                        _b.location = _c.sent();
                        if (subscription.pricingVersion < 3 && isTrial &&
                            (this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                            this.element.nativeElement.removeAllListeners('click');
                            this.element.nativeElement.addEventListener('click', this.handler.bind(this));
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationGatedUltimateTrialDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var locRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('LocationGatedUltimateTrialDirective handler');
                        return [4 /*yield*/, this.modalService.openModal(FeaturedComponent, null)];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        locRef = { locationId: this.location.locationId,
                            accountId: this.route.snapshot.parent.params.accountId };
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan([locRef])];
                    case 2:
                        if (_a.sent())
                            console.log("upgrade successful - location-gated.directive");
                        return [2 /*return*/];
                }
            });
        });
    };
    return LocationGatedUltimateTrialDirective;
}());
export { LocationGatedUltimateTrialDirective };
var LocationGatedBasicDirective = /** @class */ (function () {
    function LocationGatedBasicDirective(_sessionS, route, modalService, locationService, subscriptionService, element) {
        this._sessionS = _sessionS;
        this.route = route;
        this.modalService = modalService;
        this.locationService = locationService;
        this.subscriptionService = subscriptionService;
        this.element = element;
        this.denyAccess = new EventEmitter();
    }
    LocationGatedBasicDirective.prototype.ngOnInit = function () {
        var _a, _b, _c;
        var locationLGB = this.gmbLGB.location;
        var placeId = ((_a = locationLGB) === null || _a === void 0 ? void 0 : _a.locationId) || this.route.snapshot.parent.params.locationId;
        var account = this.gmbLGB.account || this.route.snapshot.parent.params.accountId;
        var checked = (_b = locationLGB) === null || _b === void 0 ? void 0 : _b.checked;
        if (!placeId || !account || checked) {
            return;
        }
        this.location = this.gmbLGB.location;
        var subscriptionType = (_c = this.location) === null || _c === void 0 ? void 0 : _c.subscriptionType;
        var subscription = this._sessionS.getSession().subscription;
        if (subscription.pricingVersion < 3 &&
            subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.gmbLGB.user) {
            this.element.nativeElement.removeAllListeners('click');
            this.element.nativeElement.addEventListener('click', this.handler.bind(this));
            this.denyAccess.emit(true);
        }
    };
    LocationGatedBasicDirective.prototype.ngOnChanges = function (changes) {
        if (!changes.gmbLGB.firstChange) {
            this.ngOnInit();
        }
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationGatedBasicDirective.prototype.handler = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, isTrial, gid, isMember, locationLGB, locationId, accountId, _c, r;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        console.log('LocationGatedBasicDirective handler');
                        _b = this._sessionS.getSession(), isTrial = _b.isTrial, gid = _b.gid, isMember = _b.isMember;
                        if (!isMember) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.modalService.openWarningModalNoPermissions()];
                    case 1:
                        _d.sent();
                        return [2 /*return*/];
                    case 2:
                        locationLGB = this.gmbLGB.location;
                        locationId = ((_a = locationLGB) === null || _a === void 0 ? void 0 : _a.locationId) || this.route.snapshot.parent.params.locationId;
                        accountId = this.gmbLGB.account || this.route.snapshot.parent.params.accountId;
                        _c = this;
                        return [4 /*yield*/, this.locationService.fetchLocation(gid, accountId, locationId)];
                    case 3:
                        _c.location = _d.sent();
                        if (!(this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.modalService.openModal(FeaturedComponent, null)];
                    case 4:
                        if (!(_d.sent()))
                            return [2 /*return*/];
                        return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan([{ locationId: locationId, accountId: accountId }])];
                    case 5:
                        r = _d.sent();
                        if (r) {
                            // FIXME: redundant comparison against ULTIMATE, bug?
                            if (!isTrial && (r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE // ||
                            //r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE
                            )) {
                                this.denyAccess.emit(false);
                            }
                            else {
                                this.ngOnInit();
                            }
                        }
                        _d.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return LocationGatedBasicDirective;
}());
export { LocationGatedBasicDirective };
