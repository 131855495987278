import { __awaiter, __extends, __generator } from "tslib";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// app
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { BaseComponent } from 'src/app/components/base.component';
// import { Messages } from 'src/app/constants/messages';
var LoginComponent = /** @class */ (function (_super) {
    __extends(LoginComponent, _super);
    function LoginComponent(_authS, _sessionS, _snack, _userService, _modalService, _route, navigationS) {
        var _this = _super.call(this) || this;
        _this._authS = _authS;
        _this._sessionS = _sessionS;
        _this._snack = _snack;
        _this._userService = _userService;
        _this._modalService = _modalService;
        _this._route = _route;
        _this.navigationS = navigationS;
        _this.loginProgress = false;
        // TODO: This seems an unfinished part of a button to show/hide password
        _this.hidePassword = true;
        _this.domain$ = _this._sessionS.domain$;
        var domain = _this._sessionS.getDomain();
        if (domain.blockedDomain)
            _this._modalService.openInfoModal('Domain Blocked', "Contact your account administrator");
        _this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)])
        });
        _this._waitPersistedAuthSession();
        return _this;
    }
    LoginComponent.prototype._waitPersistedAuthSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var auth, s, domain;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authS.waitAuthSession()];
                    case 1:
                        auth = _a.sent();
                        this._subscribeSafe(this._route.queryParams, function (params) { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!((_a = params) === null || _a === void 0 ? void 0 : _a.impersonate_token)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this._impersonate(params.impersonate_token)];
                                    case 1:
                                        _b.sent();
                                        _b.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        if (!auth) {
                            return [2 /*return*/];
                        }
                        if (!(auth.sessionType === 'EXTERNAL_GRADER')) return [3 /*break*/, 3];
                        // this._auth.signOut(true, false);
                        return [4 /*yield*/, this._authS.signOut()];
                    case 2:
                        // this._auth.signOut(true, false);
                        _a.sent();
                        return [2 /*return*/]; // never reached
                    case 3: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 4:
                        s = _a.sent();
                        return [4 /*yield*/, this._authS.logoutIfDomainValidationFails(s.user)];
                    case 5:
                        _a.sent();
                        domain = this._sessionS.getDomain();
                        return [4 /*yield*/, this._userService.updateLastLogin(s.gid, s.uid, new Date(), domain.xDomainName)];
                    case 6:
                        _a.sent();
                        this._snack.openSuccess('You are being automatically logged in using your existing session!');
                        return [4 /*yield*/, this._authS.redirectAfterLogin()];
                    case 7:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype._impersonate = function (impersonate_token) {
        return __awaiter(this, void 0, void 0, function () {
            var err_msg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug("Impersonate login with token=" + impersonate_token);
                        return [4 /*yield*/, this._authS.waitAuthSession()];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 3];
                        // Sign out of the current session when receiving an ?impersonate_token=XX
                        // param, by clearing the current session and reloading to the
                        // same /login?impersonate_token=XX url
                        return [4 /*yield*/, this._authS.signOut(false)];
                    case 2:
                        // Sign out of the current session when receiving an ?impersonate_token=XX
                        // param, by clearing the current session and reloading to the
                        // same /login?impersonate_token=XX url
                        _a.sent(); // impersonate
                        return [2 /*return*/]; // signed-out, never reached
                    case 3:
                        _a.trys.push([3, , 9, 10]);
                        this.loginProgress = true;
                        return [4 /*yield*/, this._authS.signInWithImpersonateToken(impersonate_token)];
                    case 4:
                        err_msg = _a.sent();
                        if (!err_msg) return [3 /*break*/, 7];
                        return [4 /*yield*/, this._modalService.openWarningModal('Error', "Error impersonating: " + err_msg)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this._authS.signOut()];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        console.debug("Impersonate OK");
                        _a.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        this.loginProgress = false;
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.signInWithEmailAndPassword = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, email, password, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        event.preventDefault();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        this.loginProgress = true;
                        _a = this.loginForm.value, email = _a.email, password = _a.password;
                        return [4 /*yield*/, this._authS.signInWithEmailAndPassword(email, password)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _b.sent();
                        // TODO: Comparison against error string, code smell
                        if (err_1.message === 'registration domain is not the current domain') {
                            this._openWrongDomainModal();
                        }
                        else if (err_1.code === "auth/user-not-found") {
                            this._snack.openError('The email you entered is incorrect or not registered!');
                        }
                        else {
                            console.log(err_1);
                            this._snack.openError('The combination of email/password you entered is incorrect!');
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        this.loginProgress = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.signInWithGoogle = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 7, 8]);
                        this.loginProgress = true;
                        return [4 /*yield*/, this._authS.signInWithGooglePopup()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 3:
                        err_2 = _a.sent();
                        this.loginProgress = false;
                        if (!(err_2.message === 'registration domain is not the current domain')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._openWrongDomainModal()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        this._snack.openError('This account could not be found or the popup was closed. You need to register first');
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        this.loginProgress = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype._openWrongDomainModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openInfoModal('You can’t login here…', 'You appear to have a valid account but you are logging-in to the wrong domain. ' +
                            'Please visit the correct site and try again.')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}(BaseComponent));
export { LoginComponent };
