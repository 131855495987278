// dep
import { Component, Inject } from '@angular/core'
import { MatDialogRef } from '@angular/material'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NavigationService } from 'src/app/services/navigation.service';

// app
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-modal-trial-ended',
  templateUrl: './modal-trial-ended.component.html',
  styleUrls: ['./modal-trial-ended.component.scss']
})
export class ModalTrialEnded {
    public price: number
    public locations_qty: number
    // private _data
    constructor(
        public dialogRef: MatDialogRef<ModalTrialEnded>,
        @Inject(MAT_DIALOG_DATA) 
        public data: any,
        private _sessionS : SessionService,
        private _navigationS : NavigationService
    ){
        // this._data = data
        this.getPrice()
     }

    
    private async getPrice() : Promise<void> {
        const s = await this._sessionS.waitSession()

        const group = s.group
        const sub   = s.subscription

        this.locations_qty = group.totalLocationsCount
        const tiers = sub.pricing?.pkg_bulk_actions?.tiers
        for (let i = 0; i < tiers.length; i++) {
            const t = tiers[i]
            if (this.locations_qty >= t?.locationsQtyMin && 
                this.locations_qty <= t?.locationsQtyMax) {
                this.price = t?.price
                return
            }
        }
    }

    public accept_cta() : void {
        window.open(this.data.buttons['accept_cta'], "_blank")
    }

    public close() : void {
        this.dialogRef.close()
        this._navigationS.signOut()
    }
}