import { __assign, __awaiter, __extends, __generator } from "tslib";
// dep
import { EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { EventMessageService } from '../../services/event-message.service';
import { ReportService } from '../../services/report.service';
import { SubscriptionService } from '../../services/subscription.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from "../../services/modal.service";
import { DatesService } from '../../services/dates.service';
import { InsightsService } from '../../services/insights.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ChartService } from '../../services/chart.service';
import { ReviewsService } from '../../services/reviews.service';
import { SearchKeywordsService } from '../../services/search-keywords.service';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";
import { EventNames } from '../../constants/event-names';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var DashboardPerformanceInsightComponent = /** @class */ (function (_super) {
    __extends(DashboardPerformanceInsightComponent, _super);
    function DashboardPerformanceInsightComponent(_route, _sessionS, _reportS, _eventMessageS, _modalS, _subscriptionS, _locationS, _dateS, _insightS, _loadingS, _snackS, _chartS, _reviewS, _searchKeywordsS, _changeRef) {
        var _this = _super.call(this) || this;
        _this._route = _route;
        _this._sessionS = _sessionS;
        _this._reportS = _reportS;
        _this._eventMessageS = _eventMessageS;
        _this._modalS = _modalS;
        _this._subscriptionS = _subscriptionS;
        _this._locationS = _locationS;
        _this._dateS = _dateS;
        _this._insightS = _insightS;
        _this._loadingS = _loadingS;
        _this._snackS = _snackS;
        _this._chartS = _chartS;
        _this._reviewS = _reviewS;
        _this._searchKeywordsS = _searchKeywordsS;
        _this._changeRef = _changeRef;
        _this.first = true;
        _this.multiChartDataSet = [];
        _this.isProgress = true;
        _this.stats = [];
        _this.viewsStats = {
            title: null,
            totals: 0,
            labels: null,
            items: []
        };
        // public isNoData: boolean;  // TODO: Unused, remove?
        _this.isProgressCSV = false;
        _this.reviewsData = {
            data: null,
            labels: null,
            total: 0
        };
        _this.averageData = {
            data: null,
            labels: null,
            total: 0
        };
        _this.viewModeChecked = 'new';
        _this.refresh = new Subject();
        _this.showMultilocations = true;
        _this.hasFoodOrdering = true;
        _this.isDatePickerFirstOpened = true;
        _this.showTooltipsOnDisabledDates = true;
        _this.lockDates = false;
        _this.shared = false;
        _this.minDate = null;
        _this.maxDate = null;
        _this.report = null;
        _this.changeDataPicker = new EventEmitter();
        _this.accountId = _this._route.parent.snapshot.params.accountId;
        _this.locationId = _this._route.parent.snapshot.params.locationId;
        _this.locations = [{ accountId: _this.accountId,
                locationId: _this.locationId }];
        return _this;
    }
    DashboardPerformanceInsightComponent.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var gid, dateValidations, dates, datesFormatted, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        this.refresh.next(true);
                        if (!this.reportDataPicker) return [3 /*break*/, 1];
                        this.typeReport = 'rollup-report';
                        this.isReport = true;
                        // TODO: Changed to locations[0].locationId
                        this.locationId = this.reportDataPicker.locations[0].locationId;
                        this.reportDataPicker.aggregation = this._reportS.correctDateAggregate(this.reportDataPicker);
                        this.dataPicker = this.reportDataPicker;
                        this.selectDate = this._reportS.selectDatesFromDataPicker(this.dataPicker);
                        this.locations = this.dataPicker.locations;
                        this.reportId = this.reportId || ((_a = this._route.snapshot.params) === null || _a === void 0 ? void 0 : _a.id);
                        if ((_b = this.report) === null || _b === void 0 ? void 0 : _b.dynamic) {
                            this._modalS.openDynamicReportReminderModal();
                        }
                        return [2 /*return*/];
                    case 1:
                        this.typeReport = 'rollup';
                        return [4 /*yield*/, this._locationS.getDateValidations(this.typeReport, [this.accountId], [gid], [this.locationId])];
                    case 2:
                        dateValidations = _d.sent();
                        dates = this._locationS.dateValidation(dateValidations);
                        this.minDate = dates.minDate;
                        this.maxDate = dates.maxDate;
                        datesFormatted = this._locationS.buildDatepickerDate(this.typeReport, this.maxDate, this.minDate);
                        this.selectDate = {
                            start: datesFormatted.start.isBefore(this.minDate) ? this.minDate : datesFormatted.start,
                            end: datesFormatted.end.isAfter(this.maxDate) ? this.maxDate : datesFormatted.end
                        };
                        _d.label = 3;
                    case 3:
                        this._subscribeSafe(this._eventMessageS.getEmitter(), function (res) {
                            if (res.name !== EventNames.EVENT_DATEPICKER_SUBSCRIPTION) {
                                return;
                            }
                            var group = _this._sessionS.getSession().group;
                            var dateDismiss = group.dismissModalDatePicker || new Date();
                            if (new Date() >= dateDismiss) {
                                _this._modalS.openModal(FeaturedDatePickerComponent, {
                                    accountId: _this.accountId,
                                    locationId: _this.locationId
                                }, { onClose: function (res) {
                                        if (!res)
                                            return;
                                        console.log('DashboardInsightsComponent upgrade suscription');
                                        _this._subscriptionS.flowChangeLocationsPlan([{ locationId: _this._locationUpgraded.locationId,
                                                accountId: _this.accountId }]);
                                    } });
                            }
                            else {
                                _this.showDatepickerModal();
                            }
                        });
                        _c = this;
                        return [4 /*yield*/, this._locationS.fetchLocation(gid, this.accountId, this.locationId)];
                    case 4:
                        _c._locationUpgraded = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Unused, remove?
    // datepickerSubscription() {
    //   this._subscribeSafe(this._eventMessageS.getEmitter(), res => {
    //     if (res.name === EventNames.EVENT_DATEPICKER_FIRST_TIME_SUBSCRIPTION && this.isDatePickerFirstOpened) {
    //       this.showDatepickerModal();
    //     }
    //   });
    // }
    DashboardPerformanceInsightComponent.prototype.handleDataPicker = function (event) {
        var _a, _b, _c, _d, _e, _f, _g;
        this._changeRef.detectChanges();
        this.refresh.next(true);
        // Init get data
        if (this.isReport && this.first) {
            this.first = false;
            this._getInsightDataReport(event, false);
            return;
        }
        if (!this.dataPicker) {
            this.dataPicker = __assign({}, event);
            this.getInsightData(this.dataPicker);
            return;
        }
        // when multiChart Change
        this.dataPicker.multiChart = event.multiChart;
        this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
        // ranges or aggregation changes
        if (((_a = this.dataPicker) === null || _a === void 0 ? void 0 : _a.range) !== event.range || ((_b = this.dataPicker) === null || _b === void 0 ? void 0 : _b.aggregation) !== event.aggregation) {
            if (((_c = this.dataPicker) === null || _c === void 0 ? void 0 : _c.aggregation) === 'month') {
                var endMonth = this._dateS.stringToDate((_e = (_d = this.dataPicker) === null || _d === void 0 ? void 0 : _d.range) === null || _e === void 0 ? void 0 : _e.end).getMonth();
                var startMonth = this._dateS.stringToDate((_g = (_f = this.dataPicker) === null || _f === void 0 ? void 0 : _f.range) === null || _g === void 0 ? void 0 : _g.start).getMonth();
                if (startMonth === endMonth) {
                    this.dataPicker.aggregation = 'day';
                }
            }
            this.dataPicker = __assign({}, event);
            if (this.isReport) {
                this._getInsightDataReport(this.dataPicker, true);
            }
            else {
                this.getInsightData(this.dataPicker);
            }
        }
        this.dataPicker.locations = this.locations;
        this.changeDataPicker.emit(this.dataPicker);
    };
    DashboardPerformanceInsightComponent.prototype.getInsightData = function (dataPicker, refresh) {
        var _this = this;
        if (refresh === void 0) { refresh = false; }
        var _a;
        if (this.isReport) {
            return;
        }
        this.resetData();
        var startDateView = moment(dataPicker.range.start, 'YYYY-MM-DD').format('YYYY-MM-DD');
        var endDateView = moment(dataPicker.range.end).format('YYYY-MM-DD');
        var gid = this._sessionS.getSession().gid;
        forkJoin([
            this._insightS.getPerformanceInsights(this.accountId, gid, this.locationId, dataPicker),
            this._reviewS.getRatingsAndReviewsData(this.accountId, gid, this.locationId, dataPicker),
            this._searchKeywordsS.getViews(this.locationId, gid, this.accountId, startDateView, endDateView, (_a = this.dataPicker) === null || _a === void 0 ? void 0 : _a.aggregation),
        ]).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var foodOrdering;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                this.isProgress = false;
                if (((_b = (_a = res[0]) === null || _a === void 0 ? void 0 : _a.labels) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    this.labels = res[0].labels;
                    this.stats = this.orderingStats(res[0].stats);
                    (_c = this.stats) === null || _c === void 0 ? void 0 : _c.forEach(function (el) { return el.labels = res[0].labels; });
                    foodOrdering = ((_d = this.stats[1]) === null || _d === void 0 ? void 0 : _d.FOOD_ORDERING) || null;
                    this.hasFoodOrdering = ((_e = foodOrdering) === null || _e === void 0 ? void 0 : _e.hasOwnProperty('display')) ? foodOrdering['display'] : false;
                    this.buildStats();
                    this.buildViewsGraph(res[2]);
                    this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
                }
                else {
                    // this.isNoData = true;
                }
                this.reviewsData = {
                    data: res[1].reviews,
                    labels: res[1].dates,
                    total: res[1].total_reviews
                };
                this.averageData = {
                    data: res[1].avg_rating,
                    labels: res[1].dates,
                    total: res[1].total_avg
                };
                this._loadingS.reportAdvanced(1, 'Insights');
                if (refresh)
                    this._snackS.openSuccess('Finished refreshing!');
                return [2 /*return*/];
            });
        }); }, function (_err) {
            // this.isNoData = true;
            _this.isProgress = false;
        });
    };
    DashboardPerformanceInsightComponent.prototype._getInsightDataReport = function (dataPicker, refresh) {
        var _this = this;
        if (refresh === void 0) { refresh = false; }
        var _a;
        this.resetData();
        var startDateView = moment(this._locationS.formatDates(dataPicker.range.start), 'YYYY-MM-DD').format('YYYY-MM-DD');
        var endDateView = moment(this._locationS.formatDates(dataPicker.range.end)).format('YYYY-MM-DD');
        var gid = this._sessionS.getSession().gid;
        forkJoin([
            this._reportS.getPerformanceRollupReports(this.reportId, dataPicker.aggregation, startDateView, endDateView),
            this._reviewS.getRatingsAndReviewsReportData(this.reportId, dataPicker),
            this._reportS.getReportsByType(gid, this.reportId, 'views', startDateView, endDateView, (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.aggregation),
            this._reportS.getReportDetails(this.reportId)
        ])
            .subscribe(function (res) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            var reportParam = {
                isScheduledReport: false,
                startDatetime: moment(startDateView).utc().toISOString(),
                endDatetime: moment(endDateView).utc().toISOString(),
                locations: res[3],
                dynamicRange: _this.report.dynamicRange,
                reportType: _this.typeReport
            };
            _this.reportDataPicker = _this._reportS.reportToDataPicker(reportParam, _this.reportId, false, _this.viewModeChecked, refresh);
            _this.isProgress = false;
            if (((_b = (_a = res[0]) === null || _a === void 0 ? void 0 : _a.labels) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                _this.labels = (_c = res[0]) === null || _c === void 0 ? void 0 : _c.labels;
                _this.stats = _this.orderingStats([
                    (_d = res[0]) === null || _d === void 0 ? void 0 : _d.primaryActions,
                    (_e = res[0]) === null || _e === void 0 ? void 0 : _e.secondaryActions,
                    (_f = res[0]) === null || _f === void 0 ? void 0 : _f.biewByDevice
                ]);
                var foodOrdering = (_g = _this.stats) === null || _g === void 0 ? void 0 : _g[1]['FOOD_ORDERING'];
                _this.hasFoodOrdering = ((_h = foodOrdering) === null || _h === void 0 ? void 0 : _h.hasOwnProperty('display')) ? foodOrdering['display'] : false;
                (_j = _this.stats) === null || _j === void 0 ? void 0 : _j.forEach(function (el) { return el.labels = res[0].labels; });
                _this.buildStats();
                _this.buildViewsGraph(res[2]);
                if (_this.dataPicker) {
                    _this.multiChartDataSet = _this.handleMultiChart(_this.dataPicker);
                }
            }
            else {
                // this.isNoData = true;
            }
            _this.reviewsData = {
                data: (_k = res[1]) === null || _k === void 0 ? void 0 : _k.reviews,
                labels: (_l = res[1]) === null || _l === void 0 ? void 0 : _l.dates,
                total: res[1].total_reviews
            };
            _this.averageData = {
                data: (_m = res[1]) === null || _m === void 0 ? void 0 : _m.avg_rating,
                labels: (_o = res[1]) === null || _o === void 0 ? void 0 : _o.dates,
                total: res[1].total_avg
            };
            _this._loadingS.reportAdvanced(1, 'Insights');
        }, function (err) {
            // this.isNoData = true;
            _this.isProgress = false;
        });
    };
    DashboardPerformanceInsightComponent.prototype.buildStats = function () {
        var _a;
        (_a = this.stats) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            var _a;
            var keys = Object.keys(el);
            keys = keys.filter(function (k) { return k != 'title' && k != 'totals'; });
            (_a = keys) === null || _a === void 0 ? void 0 : _a.forEach(function (k) {
                var display = Object.keys(el[k]).includes('display') ? el[k].display : true;
                if (display == false)
                    delete el[k];
            });
        });
        this.stats = this.stats.filter(function (s) { return Object.keys(s).length > 2; });
        this._changeRef.detectChanges();
    };
    DashboardPerformanceInsightComponent.prototype.buildViewsGraph = function (views) {
        var _a;
        var title = views.title, totals = views.totals, labels = views.labels, items = views.items;
        (_a = items) === null || _a === void 0 ? void 0 : _a.forEach(function (el) { el.labels = labels; });
        this.viewsStats = { title: title, totals: totals, labels: labels, items: items };
        this._changeRef.detectChanges();
    };
    DashboardPerformanceInsightComponent.prototype.orderingStats = function (stats) {
        var _a;
        var orderedData = [];
        if (!stats) {
            return orderedData;
        }
        var order = [
            "Primary Actions",
            "Secondary Actions",
        ];
        (_a = order) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            var item = stats.find(function (s) { var _a; return ((_a = s) === null || _a === void 0 ? void 0 : _a.title) == el; });
            if (item) {
                orderedData.push(item);
            }
        });
        return orderedData;
    };
    DashboardPerformanceInsightComponent.prototype.handleMultiChart = function (dataPicker) {
        return this._chartS.multiCharset(this.stats, dataPicker);
    };
    DashboardPerformanceInsightComponent.prototype.clearChecked = function (event) {
        this.multiChartDataSet = [];
        this.checks = event;
    };
    DashboardPerformanceInsightComponent.prototype.handleExport = function ($event) {
        if ($event === 'csv')
            this.exportCSV();
    };
    DashboardPerformanceInsightComponent.prototype.handleRefresh = function () {
        this.getInsightData(this.dataPicker, true);
    };
    DashboardPerformanceInsightComponent.prototype.exportCSV = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, locationId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.isProgressCSV = true;
                        gid = void 0, locationId = null;
                        if (!this.reportId) {
                            gid = this._sessionS.getSession().gid;
                            locationId = this.locationId;
                        }
                        return [4 /*yield*/, this._reportS.handleExportCsvMongo(this.reportId, gid, this.accountId, locationId, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation, this.typeReport)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.isProgressCSV = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPerformanceInsightComponent.prototype.statusChecks = function (event) {
        this.checks = event;
    };
    // TODO: Don't use getters from Angular templates
    DashboardPerformanceInsightComponent.prototype.hasNoDate = function () {
        var _a, _b, _c;
        var createdData = this._locationUpgraded ? moment((_c = (_b = (_a = this._locationUpgraded[0]) === null || _a === void 0 ? void 0 : _a.locationId) === null || _b === void 0 ? void 0 : _b.createdAt) === null || _c === void 0 ? void 0 : _c.$date) : null;
        var hasMoreThan24hs = createdData ? this._insightS.hasMoreThan24hs(createdData) : true;
        return hasMoreThan24hs ? 'No performance insights data' : 'The performance insights for this business profile are still being imported, please refresh and check again in 10 minutes';
    };
    DashboardPerformanceInsightComponent.prototype.resetData = function () {
        this.labels = [];
        this.stats = [];
        this.isProgress = true;
    };
    // changedViewMode(value) {
    //   this._router.navigateByUrl(`/account/${this.accountId}/location/${this.locationId}/legacy-insights`);
    // }
    DashboardPerformanceInsightComponent.prototype.showDatepickerModal = function () {
        if (this.isDatePickerFirstOpened) {
            this.isDatePickerFirstOpened = false;
            this._modalS.openDatepickerdates();
        }
    };
    return DashboardPerformanceInsightComponent;
}(BaseComponent));
export { DashboardPerformanceInsightComponent };
