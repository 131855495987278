import { MatDialogRef } from '@angular/material';
// app
import { LocationEditService } from "../../services/location-edit.service";
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
var ModalCompanyAddressComponent = /** @class */ (function () {
    function ModalCompanyAddressComponent(dialogRef, data, _locationEditS) {
        var _a;
        this.dialogRef = dialogRef;
        this.data = data;
        this._locationEditS = _locationEditS;
        this.address = JSON.parse(JSON.stringify(this._locationEditS.locationEdit.address));
        if (!this.address.languageCode)
            this.address.languageCode = 'en';
        this.optionsGoogle = {
            types: ["street_address"],
            componentRestrictions: { country: (_a = this.address) === null || _a === void 0 ? void 0 : _a.regionCode }
        };
    }
    ModalCompanyAddressComponent.prototype.addStreet = function () {
        this.address.addressLines.push('');
    };
    ModalCompanyAddressComponent.prototype.removeAddress = function (index) {
        if (index > 0) {
            this.address.addressLines.splice(index, 1);
        }
    };
    ModalCompanyAddressComponent.prototype.apply = function () {
        var _this = this;
        this.address.addressLines = this.address.addressLines.filter(function (el) { return (el != null) && (el != ""); });
        this._locationEditS.locationEdit.address = this.address;
        this._locationEditS.setAndUpdate().toPromise().then(function () {
            _this.dialogRef.close();
        });
    };
    ModalCompanyAddressComponent.prototype.handleAddressChange = function (adress, i) {
        var administrativeArea = adress.vicinity;
        var stateCodeComponent = adress.address_components.find(function (component) { return component.types.includes("administrative_area_level_1"); });
        var postalCodeComponent = adress.address_components.find(function (component) { return component.types.includes("postal_code"); });
        // city
        this.address.locality = administrativeArea || this.address.administrativeArea;
        // state
        this.address.administrativeArea = stateCodeComponent ? stateCodeComponent.short_name : this.address.locality;
        // zip code
        this.address.postalCode = postalCodeComponent ? postalCodeComponent.short_name : this.address.postalCode;
        // street address
        this.address.addressLines[i] = adress.name;
    };
    ModalCompanyAddressComponent.prototype.handleChange = function ($event, i) {
        this.address.addressLines[i] = $event.target.value;
    };
    return ModalCompanyAddressComponent;
}());
export { ModalCompanyAddressComponent };
