import { __extends } from "tslib";
// dep
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';
// app
import { ModalService } from 'src/app/services/modal.service';
export var PROTOCOL_SCAN_ACTION = 1;
export var NORMAL_ACTION = 0;
var ModalStepperComponent = /** @class */ (function (_super) {
    __extends(ModalStepperComponent, _super);
    function ModalStepperComponent(dir, changeDetectorRef, elementRef, _modalService) {
        var _this = _super.call(this, dir, changeDetectorRef, elementRef) || this;
        _this.dir = dir;
        _this.changeDetectorRef = changeDetectorRef;
        _this.elementRef = elementRef;
        _this._modalService = _modalService;
        _this.title = [];
        _this.scan_action = false;
        _this.isEdit = false;
        _this.isScan = false;
        _this.isDraft = false;
        _this.hasDraft = false;
        _this.disabledStep = false;
        _this.drawer = null;
        _this.isSliderOpened = false;
        _this.isSlider = false;
        _this.stepTitle = [];
        _this.nextButtonLabels = [];
        _this.cancelText = {
            title: 'Heads up…',
            message: 'Your changes will not be saved if you exit the report creation wizard.'
        };
        _this.closeDrawerAfterComplete = true;
        _this.closeLabel = 'Cancel';
        _this.verifiedDataLabel = null;
        _this.dataIsBeingVerified = false;
        _this.cancelButton = 'Confirm';
        _this.completed = new EventEmitter();
        _this.backStep = new EventEmitter();
        _this.step_activate = new EventEmitter();
        _this.destroyData = new EventEmitter();
        _this.doValidationMetrics = new EventEmitter();
        _this.onStepChange = new EventEmitter();
        _this.postDraftEvent = new EventEmitter();
        return _this;
    }
    ModalStepperComponent.prototype.ngOnInit = function () {
        this.selectedIndex = this.step;
        this.title = typeof (this.title) === 'object' ? this.title : [this.title];
    };
    ModalStepperComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        if (!this.isSliderOpened && this.isSlider) {
            this.selectedIndex = 0;
        }
        var step = (_a = changes) === null || _a === void 0 ? void 0 : _a.step;
        if (step) {
            this.selectedIndex = step.currentValue;
        }
        this.title = typeof (this.title) === 'object' ? this.title : [this.title];
    };
    Object.defineProperty(ModalStepperComponent.prototype, "percent", {
        get: function () {
            return (this.selectedIndex + 1) * (100 / this.steps.length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalStepperComponent.prototype, "index", {
        get: function () {
            return this.selectedIndex + 1;
        },
        enumerable: true,
        configurable: true
    });
    ModalStepperComponent.prototype.get_step = function () {
        this.doValidationMetrics.emit(this.reportType == 'performance-comparison' && this.selectedIndex == 2);
        this.step_activate.emit(this.selectedIndex);
        this.onStepChange.emit(this.selectedIndex);
    };
    ModalStepperComponent.prototype.stepperPrevious = function () {
        this.backStep.emit(this.selectedIndex);
    };
    ModalStepperComponent.prototype.complete = function () {
        var _a;
        if (this.closeDrawerAfterComplete) {
            (_a = this.drawer) === null || _a === void 0 ? void 0 : _a.toggle();
        }
        this.completed.emit(NORMAL_ACTION);
    };
    ModalStepperComponent.prototype.create_scan = function () {
        this.completed.emit(PROTOCOL_SCAN_ACTION);
    };
    ModalStepperComponent.prototype.postDraft = function () {
        this.postDraftEvent.emit();
    };
    ModalStepperComponent.prototype.dialogClose = function () {
        var _this = this;
        var _a, _b;
        this._modalService.openConfirmModal((_a = this.cancelText) === null || _a === void 0 ? void 0 : _a.title, (_b = this.cancelText) === null || _b === void 0 ? void 0 : _b.message, (function (response) {
            var _a;
            if (response) {
                (_a = _this.drawer) === null || _a === void 0 ? void 0 : _a.toggle();
                _this.selectedIndex = 0;
                _this.destroyData.emit();
            }
        }), null, this.cancelButton);
    };
    ModalStepperComponent.prototype.buttonNextVisible = function () {
        return !(this.isScan && !this.isEdit && this.selectedIndex === 2);
    };
    return ModalStepperComponent;
}(CdkStepper));
export { ModalStepperComponent };
