// dep
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// app
import { SearchKeywordsComponent as oldSearchKeywordsComponent } from 'src/app/modules/search-keywords/search-keywords-report/search-keywords.component';
import { SearchKeywordsComponent as newSearchKeywordsComponent } from 'src/app/modules/reporting/keywords/search-keywords-wrapper/search-keywords/search-keywords.component';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagResolver implements Resolve<any> {

    constructor(
        private _sessionS : SessionService
    ) {}
      
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): (typeof oldSearchKeywordsComponent | 
                                                                         typeof newSearchKeywordsComponent) {
        // return oldSearchKeywordsComponent; // for testing purposes
        return (this._sessionS.getSession().features.userFeatures.updatedDatePicker ? 
                newSearchKeywordsComponent :
                oldSearchKeywordsComponent);
    }
}
