import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ServiceList, ServiceData, Service, Category } from '../../../constants/google/service-list';
import { LocationService } from '../../../services/location.service';
import { GoogleService } from '../../../services/google.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ModalAddServiceComponent } from '../../modal-add-service/modal-add-service.component';
import { ModalServiceItemComponent } from '../../modal-service-item/modal-service-item.component';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-services-location',
  templateUrl: './services-location.component.html',
  styleUrls: ['./services-location.component.scss']
})
export class ServicesLocationComponent implements OnInit {
  @Input() serviceList: ServiceList[];
  dataSource: ServiceData[] = [];
  @Input() primaryCategory: {categoryId: string, displayName: string,  primary: boolean, serviceTypes: {displayName: string, serviceTypeId: string}[]};
  @Input() aditionalCategories: {categoryId: string, displayName: string,  primary: boolean, serviceTypes: {displayName: string, serviceTypeId: string}[]}[] = [];
  combineCategories: {categoryId: string, displayName: string,  primary: boolean, serviceTypes: {displayName: string, serviceTypeId: string}[]}[] = [];
  @Input() accountId = '';
  @Input() locationId = '';
  loading = false;
  showMoreMessage = false;
  showMoreMessageIndex: number;
  showOnly = false;
  updateService = false;

   constructor(
    public dialog: MatDialog,
    private locationS: LocationService,
    private googleS: GoogleService,
    private snackS: SnackbarService,
    private  activateRoute: ActivatedRoute,
    private _sessionS: SessionService,
  ) { }

  ngOnInit() {
    if (this.activateRoute.snapshot.parent.params.locationId) {
      this.locationId = this.activateRoute.snapshot.parent.params.locationId;
      this.accountId = this.activateRoute.snapshot.parent.params.accountId;
      //TODO Add 'type' in getRef service. 
      this.locationS.getRef(this._sessionS.getSession().gid, this.accountId, this.locationId).subscribe(result => {
        if ('serviceList' in result) this.serviceList = result.serviceList;
        if (!result['location']['priceLists']) result.location.priceLists = [];
        const location = result.location;
        this.primaryCategory = location.primaryCategory;
        this.aditionalCategories = location.additionalCategories;
        this.checkData();
      });
    } else {
      this.checkData();
    }

  }

  get ServiceList(): ServiceList[] {
    return this.serviceList
  }

  checkData()  {
    this.primaryCategory = {...this.primaryCategory, primary: true};
    if (this.aditionalCategories) this.combineCategories = [this.primaryCategory, ...this.aditionalCategories]
    else this.combineCategories = [this.primaryCategory];
    this.serviceList = this.serviceList.map( s => {
      if ('price' in s) {
        if (s.price.nanos && s.price.nanos != 0) {
          s.price.nanos = s.price.nanos * Math.pow(10, -7);
        }
      }
      return s;
    });
    this.dataSource = this.locationS.organizeServiceList(this.serviceList, this.combineCategories);
  }

  toggleMessage(i): void {
    this.showMoreMessage = !this.showMoreMessage;
    this.showMoreMessageIndex = i;
    return;
  }


  addItem(position: number) {
    const dialogRef = this.dialog.open(ModalAddServiceComponent, {
      width: '480px',
      panelClass: 'overflow--visible',
      data: {
        category: this.combineCategories[position]
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.serviceList.push(result.item);
        const data = this.dataSource.find(x => x.categoryId === result.category)
        if (data)  {
          data.services.push(result.structuredItem)
        }
      }
    });
  }

  refreshServices(serviceList: ServiceList[]) {
    this.serviceList = serviceList;
    this.checkData();
    this.snackS.openSuccess('Services updated', 3000)
  }

  editItem(service: Service, position: number, sectionIndex: number, serviceMaskIndex: number) {
    if (!this.showOnly) {
      //TODO: When the getRef service returns 'type', remove this logyc 
      let type: string = 'fixed';
      if (service.price) {
        if (Number(service.price.units) === 0 || (service.price.units == null && service.price.nanos == null)) {
          type = 'free';
        }
      } else {
        type = 'noPrice'
      }
      //
      const dialogRef = this.dialog.open(ModalServiceItemComponent, {
        width: '480px',
        panelClass: 'overflow--visible',
        data: {
          structuredService: service,
          item: this.serviceList[position],
          typeServiceItem: type,
          position
        }
      });

      dialogRef.afterClosed().subscribe((result: {action: string, position: number, item?: ServiceList, structuredItem?: Service, category?: Category}) => {
        if (result) {
          if (result.action === 'removed') {
            this.serviceList.splice(position, 1);
            this.dataSource = this.locationS.organizeServiceList(this.serviceList, this.combineCategories);
          } else if (result.action === 'updated') {
            this.serviceList[position] = result.item;
            this.dataSource[sectionIndex].services[serviceMaskIndex] = result.structuredItem;
          }
        }
      });
    }
  }

  parseMoney(units, nanos) : string {
    return `${units || 0}.${nanos || 0}`
  }

  setServiceList(serviceList: ServiceList[]) {
    this.serviceList = serviceList;
    this.dataSource = this.locationS.organizeServiceList(this.serviceList, this.combineCategories);
  }

  apply(): void {
    this.loading = true;
    this.googleS.updateServices(this.accountId, this.locationId, this.serviceList).toPromise().then( data => {
      if (data) {
        this.loading = false;
        this.snackS.openSuccess('The services was uploaded successfully', 2000)
        this.serviceList = data;
        this.dataSource = this.locationS.organizeServiceList(this.serviceList, this.combineCategories);
      }
    }, err => {
      this.loading = false;
      this.snackS.openSuccess(`An error has occurred ${err}`, 2000)
    })
  }

}
