import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { HttpClient } from "@angular/common/http";
// app
import { environment as ENV } from "@environment";
import { ModalService } from "./modal.service";
import { UpdateCardComponent } from "../settings/billing/update-card/update-card.component";
import { SpinnerService } from "./spinner.service";
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE_PriceOrder } from "../constants/firestore/account-location";
import { normalizeLocationRef } from "../constants/firestore/location-object";
import { UpgradeLocationsComponent } from '../components/upgrade-locations';
import { ChangePlanLocationComponent } from "../modules/change-plan-location/change-plan-location.component";
import { LocationService } from "./location.service";
import { PaymentsService } from "./payments.service";
import { NavigationService } from "./navigation.service";
import { SessionService } from "./session.service";
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService(_sessionS, _navigationS, locationService, paymentsService, spinnerService, http, modalService) {
        this._sessionS = _sessionS;
        this._navigationS = _navigationS;
        this.locationService = locationService;
        this.paymentsService = paymentsService;
        this.spinnerService = spinnerService;
        this.http = http;
        this.modalService = modalService;
    }
    SubscriptionService.prototype.fetchInvoices = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(ENV.billingApiUrl + "/invoice/gid/" + gid).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Asks the user to add a Payment Method (only a Credit Card for now) if his Subscription needs it and
     * there is none.
     * @returns string if the user added a PM of if he didn't need to.
     * @returns false if the subscription needed a PM but the user didn't add one.
     * TODO: Move to UX flows module
     */
    SubscriptionService.prototype.askForPaymentMethodIfNeededAndNoneFound = function (sub) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!sub.collectionByBillingOverride[sub.billingOverride.toString()].requiresPaymentMethod)
                            return [2 /*return*/, 'OK_NOT_NEEDED'];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this.spinnerService.loading$.next(true);
                        return [4 /*yield*/, this.paymentsService.hasPaymentMethods(sub.gid)];
                    case 2:
                        if (_a.sent())
                            return [2 /*return*/, 'OK_HAS_ONE'];
                        return [3 /*break*/, 4];
                    case 3:
                        this.spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.modalService.openModal(UpdateCardComponent, {})];
                    case 5:
                        if (_a.sent())
                            return [2 /*return*/, 'OK_ADDED'];
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        console.error("Error adding Credit Card", e_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, false];
                }
            });
        });
    };
    /**
     * Stops Trial if it's possible
     * @returns true if the Trial was stopped
     */
    SubscriptionService.prototype.stopTrial = function (sub) {
        return __awaiter(this, void 0, void 0, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (sub.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL)
                            return [2 /*return*/, false];
                        return [4 /*yield*/, this.askForPaymentMethodIfNeededAndNoneFound(sub)];
                    case 1:
                        r = _a.sent();
                        if (!r)
                            return [2 /*return*/, false];
                        if (r === 'OK_ADDED')
                            // When the PM is added, the subscription is automatically stoped, we don't need to do anything
                            return [2 /*return*/, true
                                // assert r !== 'OK_HAS_ONE'  // if has one, then should not be on trial
                            ];
                        // assert r !== 'OK_HAS_ONE'  // if has one, then should not be on trial
                        return [4 /*yield*/, this.http.post(ENV.billingApiUrl + "/subscription/" + sub.gid + "/stop-trial", {}).toPromise()];
                    case 2:
                        // assert r !== 'OK_HAS_ONE'  // if has one, then should not be on trial
                        _a.sent();
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 3:
                        _a.sent();
                        // Locations are changed from FREE to ESSENTIAL
                        this.locationService.someLocationChanged$.next(null);
                        return [2 /*return*/, true];
                }
            });
        });
    };
    // TODO: Move this to an UX flows module, as this is too high level to be put
    // on a service that should only handle the API calls.
    SubscriptionService.prototype.flowChangeLocationsPlan = function (locations) {
        return __awaiter(this, void 0, void 0, function () {
            var sub, changePlan_1, locationsPromiseFinished_1, locationsPromise, requiresPaymentMethod, message, title, description, loc_desc, currentPlans, spinnerStarted, _a, has_downgrades, has_upgrades, only_ask_for_payment_method, mail, name_1, total, changeResult, e_2;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        sub = this._sessionS.getSession().subscription;
                        // Normalize, TODO: make the callers send this on the correct format and remove this
                        locations = locations.map(function (l) { return normalizeLocationRef(l); });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 16, 18, 20]);
                        return [4 /*yield*/, this.modalService.openModal(ChangePlanLocationComponent, locations)];
                    case 2:
                        changePlan_1 = _b.sent();
                        if (!changePlan_1)
                            return [2 /*return*/, null
                                //------------------------------------------------------------
                                // 2- Ask to select locations if they weren't selected before 
                                //------------------------------------------------------------
                            ];
                        if (!!locations) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._openSelectLocationsToChangePlanDialog()];
                    case 3:
                        locations = _b.sent();
                        if (!locations)
                            // No location selected
                            return [2 /*return*/, null];
                        _b.label = 4;
                    case 4:
                        locationsPromiseFinished_1 = false;
                        locationsPromise = (function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, , 2, 3]);
                                        return [4 /*yield*/, this.locationService.fetchMultipleLocations(sub.gid, locations, ['subscriptionType'])];
                                    case 1: return [2 /*return*/, _a.sent()];
                                    case 2:
                                        locationsPromiseFinished_1 = true;
                                        return [7 /*endfinally*/];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })();
                        requiresPaymentMethod = sub.collectionByBillingOverride[sub.billingOverride.toString()].requiresPaymentMethod;
                        message = "";
                        title = "";
                        description = "";
                        loc_desc = (locations.length == 1 ? "this location" : "these " + locations.length + " locations");
                        switch (changePlan_1.action) {
                            case 'upgrade':
                                title = "Upgrade Listing";
                                message = "upgrade " + loc_desc + "?";
                                description = "By clicking 'Confirm' " + (requiresPaymentMethod ? "your card will be charged immediately." : "the changes will be applied immediately.");
                                break;
                            case 'downgrade':
                                title = "Downgrade Listing";
                                message = "downgrade " + loc_desc + "?";
                                description = "By clicking confirm, you will still have access to all the features on your current plan through the end of the current billing cycle.";
                                break;
                            case 'change_plan':
                                title = "Change Listing Plan";
                                message = "change the subscription plan for " + loc_desc + "?";
                                description = "By clicking confirm, you will still have access to all the features on your current plan through the end of the current billing cycle.";
                                break;
                        }
                        return [4 /*yield*/, this.modalService.openConfirmModal(title, "Are you sure you want to " + message, null, null, null, false, description, true)];
                    case 5:
                        if (!(_b.sent()))
                            return [2 /*return*/, null
                                //------------------------------------
                                // 4- Check if plan change is allowed
                                // TODO: After all the refactor works, move check to backend
                                //------------------------------------
                            ];
                        currentPlans = void 0;
                        spinnerStarted = false;
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, , 8, 9]);
                        if (!locationsPromiseFinished_1) {
                            this.spinnerService.loading$.next(true);
                            spinnerStarted = true;
                        }
                        _a = Set.bind;
                        return [4 /*yield*/, locationsPromise];
                    case 7:
                        currentPlans = __spread.apply(void 0, [new (_a.apply(Set, [void 0, (_b.sent()).map(function (l) { return l.subscriptionType; })]))()]);
                        return [3 /*break*/, 9];
                    case 8:
                        if (spinnerStarted)
                            this.spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 9:
                        has_downgrades = !!currentPlans.find(function (plan) { return LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(plan) >
                            LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(changePlan_1.nextPlan); });
                        has_upgrades = !!currentPlans.find(function (plan) { return LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(plan) <
                            LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(changePlan_1.nextPlan); });
                        only_ask_for_payment_method = false;
                        if (!(has_downgrades && requiresPaymentMethod && sub.customerPendingChargesTotal > 0)) return [3 /*break*/, 11];
                        mail = this._navigationS.supportMailURL;
                        name_1 = this._sessionS.getSession().user.displayName;
                        total = sub.customerPendingChargesTotal.toFixed(2);
                        return [4 /*yield*/, this.modalService.openErrorModal('Unpaid balance on your account', "Hi " + name_1 + ", it looks like your subscription have an unpaid balance " +
                                ("of $" + total + ". After paying your balance, you\u2019ll be ") +
                                "able to downgrade. If you believe this is an error, " +
                                ("<a class=\"cursor--pointer txt--underline\" [href]=\"'" + mail + "'\">") +
                                "<span>contact support</span>" +
                                "</a> and we’ll help.")];
                    case 10:
                        _b.sent();
                        only_ask_for_payment_method = true;
                        _b.label = 11;
                    case 11: return [4 /*yield*/, this.askForPaymentMethodIfNeededAndNoneFound(sub)];
                    case 12:
                        // TODO: Disallow upgrades if there are pending charges? Check MAP-2243
                        //-------------------------------------
                        // 5- Ask to add a Card if he needs to 
                        //-------------------------------------
                        if (!(_b.sent()))
                            return [2 /*return*/, null];
                        if (only_ask_for_payment_method)
                            return [2 /*return*/, null
                                //------------------
                                // 6- Apply changes
                                //------------------
                            ];
                        //------------------
                        // 6- Apply changes
                        //------------------
                        this.spinnerService.loading$.next(true);
                        return [4 /*yield*/, this.applyChangePlan(locations, changePlan_1.nextPlan)];
                    case 13:
                        changeResult = _b.sent();
                        this.spinnerService.loading$.next(false);
                        if (!(changeResult && changeResult.total > 0 && requiresPaymentMethod)) return [3 /*break*/, 15];
                        // Only show this if he will be charged directly, see MS-716
                        return [4 /*yield*/, this.modalService.openInfoModal('Payment Confirmation', "Your transaction was successful. You were charged $" + changeResult.total / 100)];
                    case 14:
                        // Only show this if he will be charged directly, see MS-716
                        _b.sent();
                        _b.label = 15;
                    case 15: return [2 /*return*/, changeResult];
                    case 16:
                        e_2 = _b.sent();
                        console.error("Error on flowChangeLocationsPlan", e_2);
                        return [4 /*yield*/, this.modalService.openErrorLoadingModal(6)];
                    case 17:
                        _b.sent();
                        return [2 /*return*/, null];
                    case 18: return [4 /*yield*/, this._sessionS.refresh()];
                    case 19:
                        _b.sent();
                        this.locationService.someLocationChanged$.next(null);
                        return [7 /*endfinally*/];
                    case 20: return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionService.prototype.applyChangePlan = function (locations, nextPlan) {
        return __awaiter(this, void 0, void 0, function () {
            var locationsToChange, user, changeResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        locationsToChange = locations.map(function (l) { return (__assign(__assign({}, normalizeLocationRef(l)), { nextPlan: nextPlan })); });
                        user = this._sessionS.getSession().user;
                        return [4 /*yield*/, this._sendChangePlan(user.registrationDomain, user.gid, locationsToChange)];
                    case 1:
                        changeResult = _a.sent();
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, { total: changeResult.total,
                                nextPlan: nextPlan,
                                success: true }];
                }
            });
        });
    };
    SubscriptionService.prototype._openSelectLocationsToChangePlanDialog = function () {
        return __awaiter(this, void 0, void 0, function () {
            var als, locations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.openModal(UpgradeLocationsComponent, { closeButtonLabel: 'Ok' }, { disableClose: true })];
                    case 1:
                        als = _a.sent();
                        locations = [];
                        (als || []).forEach(function (acc) {
                            acc.locations.forEach(function (loc) { return locations.push(loc); });
                        });
                        return [2 /*return*/, locations];
                }
            });
        });
    };
    SubscriptionService.prototype._sendChangePlan = function (domain, gid, locations) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(ENV.billingApiUrl + "/locations/plan/update", { domain: domain, gid: gid, locations: locations }).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    // TODO: Review this endpoint, as is still using main-api for plan change. 
    SubscriptionService.prototype.upgradeLocation = function (placeId, accountId, gid, plan) {
        return this.http.post(ENV.apiUrl + "/v2/locations/" + placeId + "/upgrade", { accountId: accountId, gid: gid, plan: plan }).toPromise();
    };
    // response is a blob
    SubscriptionService.prototype.getInvoicePDF = function (invId) {
        return this.http.get(ENV.billingApiUrl + "/invoice/" + invId + "/pdf", { responseType: 'blob' });
    };
    SubscriptionService.prototype.createSubscription = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // All parameters required by the endpoint are sent as headers (gid, uid and x-domain)
                    return [4 /*yield*/, this.http.post(ENV.billingApiUrl + "/customer/subscription", {}).toPromise()];
                    case 1:
                        // All parameters required by the endpoint are sent as headers (gid, uid and x-domain)
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionService.prototype.fetchSubscription = function (gid, products) {
        if (products === void 0) { products = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(ENV.billingApiUrl + "/subscription/" + gid + "?products=" + (products ? 1 : 0)).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    return SubscriptionService;
}());
export { SubscriptionService };
