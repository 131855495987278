import { __assign, __awaiter, __generator } from "tslib";
import { MatDialog } from '@angular/material';
// app
// IMPORTANT: Only add simple components here that doesn't depend on auth.service or
// other services, to avoid circular deps. In doubt, don't modify this file and call openModal()
// from an outer module importing the component on that outer module. Or inject the data needed
// from the services to the component from outside.
import { AlertComponent, AlertType } from '../components/alert.component';
import { ConfirmComponent } from '../components/confirm.component';
import { ModalConfirmData } from "../classes/modal-confirm-data";
// import { LoadComponent } from '../components/load.component';
import { makeOpenPromise } from '../helpers/utils.helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var ModalService = /** @class */ (function () {
    function ModalService(dialog) {
        this.dialog = dialog;
    }
    /**
    * Generic awaitable openModal method
    */
    ModalService.prototype.openModal = function (modalClass, dialogData, opts) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var d, p;
            return __generator(this, function (_j) {
                opts = __assign(__assign(__assign({}, modalClass.OPTS_DEFAULT), opts), (((_a = modalClass.OPTS_DEFAULT) === null || _a === void 0 ? void 0 : _a.config) || ((_b = opts) === null || _b === void 0 ? void 0 : _b.config) ?
                    { config: __assign(__assign({}, (_c = modalClass.OPTS_DEFAULT) === null || _c === void 0 ? void 0 : _c.config), (_d = opts) === null || _d === void 0 ? void 0 : _d.config) } : null));
                d = this.dialog.open(modalClass, __assign(__assign({}, (_e = opts) === null || _e === void 0 ? void 0 : _e.config), { data: dialogData }));
                if (((_f = opts) === null || _f === void 0 ? void 0 : _f.autoCloseMS) !== undefined && ((_g = opts) === null || _g === void 0 ? void 0 : _g.autoCloseMS) !== null)
                    setTimeout(function () { d.close(); }, opts.autoCloseMS);
                p = makeOpenPromise();
                if (((_h = opts) === null || _h === void 0 ? void 0 : _h.disableClose) !== undefined)
                    d.disableClose = opts.disableClose;
                d.afterClosed().subscribe(function (res) {
                    var _a;
                    if ((_a = opts) === null || _a === void 0 ? void 0 : _a.onClose)
                        opts.onClose(res);
                    p.resolve(res);
                });
                return [2 /*return*/, p];
            });
        });
    };
    // openLoadingModal(title: string, message: string, dismiss: number = null) : Promise<boolean> {
    //   return this.openModal(LoadComponent,                           
    //     {
    //       title, 
    //       content: message
    //     }, 
    //     {
    //       config: { width: '680px' }, 
    //       autoCloseMS : dismiss
    //     }
    //   )
    // }
    // TODO: Change signature so the parameters are passed in an opts obj
    ModalService.prototype.openAlertModal = function (title, message, alertType, dismiss, closeButton, callBackFunction, colorCloseBtn, hideCancel, hideContactSupport) {
        if (dismiss === void 0) { dismiss = null; }
        if (closeButton === void 0) { closeButton = 'Ok'; }
        if (hideCancel === void 0) { hideCancel = false; }
        if (hideContactSupport === void 0) { hideContactSupport = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openModal(AlertComponent, {
                        title: title,
                        alertType: alertType,
                        content: message,
                        closeButtonLabel: closeButton,
                        colorCloseBtn: colorCloseBtn ? colorCloseBtn : 'primary',
                        hideCancel: hideCancel,
                        hideContactSupport: hideContactSupport
                    }, {
                        onClose: callBackFunction,
                        autoCloseMS: dismiss
                    })];
            });
        });
    };
    // TODO: Replace calls to this with openModal and remove it
    ModalService.prototype.openGenericModal = function (modalComponentRef, data, callBackFunction, modalWidth) {
        var dialogRef = this.dialog.open(modalComponentRef, {
            width: modalWidth ? modalWidth + 'px' : '680px',
            data: new ModalConfirmData({
                data: data,
                title: null,
                content: null,
                confirmButtonLabel: 'Save',
                closeButtonLabel: 'Cancel'
            })
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) { return callBackFunction && callBackFunction(result); });
        return dialogRef;
    };
    ModalService.prototype.openInfoModal = function (title, message, cancelButton, hideCancel, hideContactSupport) {
        if (cancelButton === void 0) { cancelButton = 'Ok'; }
        if (hideCancel === void 0) { hideCancel = false; }
        if (hideContactSupport === void 0) { hideContactSupport = true; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openAlertModal(title, message, AlertType.INFO, null, cancelButton, null, null, hideCancel, hideContactSupport)];
            });
        });
    };
    ModalService.prototype.openWarningModal = function (title, message) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openAlertModal(title, message, AlertType.WARNING)];
            });
        });
    };
    ModalService.prototype.openErrorModal = function (title, message) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.openAlertModal(title, message, AlertType.ERROR, null, 'Ok', null, null, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalService.prototype.openConfirmModal = function (title, message, callBackFunction, alertClass, button, modalPayment, description, typePaid) {
        return this.openModal(ConfirmComponent, new ModalConfirmData({ title: title,
            content: message,
            confirmButtonLabel: button || 'Confirm',
            closeButtonLabel: 'Cancel',
            alertType: alertClass || null,
            modalPayment: modalPayment || false,
            description: description || null,
            typePaid: typePaid || false }), { config: { width: '680px' },
            onClose: callBackFunction,
            disableClose: true
        });
    };
    //-- TODO: move this to a more specific module?
    ModalService.prototype.openInfoProfiles = function (title) {
        return this.openInfoModal(title, "The data for the selected date range is unavailable on Google. Performance data is usually delayed by up to 7 days. Visit this report at a later date for more up-to-date insights.");
    };
    ModalService.prototype.openDatepickerdates = function () {
        return this.openInfoProfiles('Date range');
    };
    ModalService.prototype.openDynamicReportReminderModal = function () {
        return this.openInfoProfiles('Dynamic report');
    };
    //---
    ModalService.prototype.openWarningModalNoPermissions = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.openWarningModal('Contact your administrator', 'Your user doesn’t have permissions to do this. ' +
                            'Contact your account administrator to change your role.')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Modal used for unexpected application errors
     * TODO: Add link to support
     * TODO: Replace some usages of ConfirmComponent with this
     */
    ModalService.prototype.openErrorLoadingModal = function (errorCode) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.openErrorModal('Loading error', ('There was an error while loading. ' +
                            ("Please try again or contact support (error code " + errorCode + ")")))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i1.MatDialog)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
