import { MatDialog } from '@angular/material';
// app
import { LoadModalComponent } from '../components/load-modal/load-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var LoadingService = /** @class */ (function () {
    function LoadingService(dialog) {
        this.dialog = dialog;
        this.dialogRef = null;
    }
    LoadingService.prototype.open = function (steps) {
        var _this = this;
        this.dialogRef = this.dialog.open(LoadModalComponent, { data: { steps: steps, complete: 0, completed: null } });
        this.dialogRef.afterClosed().take(2).subscribe(function () {
            _this.dialogRef = null;
        });
        return this.dialogRef;
    };
    LoadingService.prototype.reportAdvanced = function (complete, completed) {
        if (this.dialogRef === null)
            return;
        this.dialogRef.componentInstance.updatedData(complete, completed);
    };
    LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(i0.ɵɵinject(i1.MatDialog)); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}());
export { LoadingService };
