<!-- TRIAL subscriptions -->
<div *ngIf="messageType === 'SUBSCRIPTION_TRIAL'"
      class="trial-message js-trial-message">
	<p class="m--0">
		Only {{(session$ | async).daysInTrial}} days remaining in trial!
		<button *ngIf="(session$ | async)?.subscription.pricingVersion < 3"
		    mat-button 
			id="upgradeTrialLocBtn"
			class="btn btn--white btn--xs ml--10"
			(click)="openSubscriptionUpgrade()">
			Upgrade Now
		</button>
	</p>
</div>

<!-- Essential Location of non-TRIAL subscriptions -->
<div *ngIf="messageType === 'LOCATION_FREE'"
     class="trial-message js-trial-message">
	<p class="m--0">
		This is an Essential Location!
		<button mat-button 
		        id="upgradeFreeLocBtn" 
				class="btn btn--white btn--xs ml--10" 
				(click)="openLocationUpgrade()">
			Upgrade Now
		</button>
	</p>
</div>
