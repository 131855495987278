/* tslint:disable:no-trailing-whitespace */
// dep
import {Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as uuid from 'uuid';

// app
import {Product} from '../../constants/firestore/product';

export const PRODUCT_DATA: Product[] = [];

@Component({
  selector: 'app-modal-products-collection',
  templateUrl: './modal-products-collection.component.html',
  styleUrls: ['./modal-products-collection.component.scss']
})
export class ModalProductsCollectionComponent {

  productCollectionName: string;
  productCollectionNameInvalid: boolean;
  productCollectionDesc: string;

  productsList: any;

  productName: string;
  productDesc: string;
  productImage: string;

  priceRange: boolean;
  productPrice: number;
  productPriceMax: number;

  buttonAdded: boolean;
  buttonType: string;
  buttonText: string;

  linkType: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalProductsCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.productCollectionNameInvalid = false;

    if (data.products === null || data.products === undefined) {
      this.productsList = PRODUCT_DATA;
    } else {
      this.productsList = data.products;
    }
    this.priceRange = false;
    this.buttonAdded = false;
    this.linkType = ['Order Online', 'Buy', 'Learn More', 'Get offer'];
  }


  showLinkText(type): void {
    this.buttonAdded = (type !== 'hideButton');
    this.buttonType = type;
  }

  apply(): void {
    if (this.productCollectionName) {
      this.productCollectionNameInvalid = false;
      const id = uuid.v4()
      const productCollectionName = this.productCollectionName;
      const productCollectionDesc = (this.productCollectionDesc) ? this.productCollectionDesc : '';

      const productItem = [];
      

      const data = {id, productCollectionName, productCollectionDesc, productItem};
      this.dialogRef.close(data);

    } else {
      this.productCollectionNameInvalid = true;
    }
  }
}
