// dep
import {ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as uuid from 'uuid';

// app
import {LocationEditService} from "../../services/location-edit.service";
import {PriceList, Section, SectionType} from "../../constants/google/price-list-section";
import {LocationService} from "../../services/location.service";

@Component({
  selector: 'app-modal-service-section-edit',
  templateUrl: './modal-service-section.component.html',
  styleUrls:  ['./modal-service-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ModalServiceSectionComponent {

  isEdit: boolean;
  public form: FormGroup;
  private readonly type: string;
  private readonly priceIndex: number;
  private readonly sectionIndex: number;
  constructor(public dialogRef: MatDialogRef<ModalServiceSectionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private locationEditS: LocationEditService,
              private locationS: LocationService,
              private fb: FormBuilder,
  ) {

    this.isEdit = this.data.edit;
    this.type = this.data.type;
    this.priceIndex = this.data.priceIndex;
    this.initForm();

    if (this.data.section) {
      this.isEdit = true;
      this.sectionIndex = this.data.sectionIndex;
      this.name.setValue(this.data.section.labels[0].displayName);
    }
  }

  apply() {
    if (this.form.invalid) {
      return;
    }
    this.savePriceList();
    this._saveInFirestore();
  }

  savePriceList() {
    if (this.isEdit){
      this.editSection(this.priceIndex, this.sectionIndex, this.name.value);
    } else if (this.priceIndex === undefined) {
      this.setPriceListItem()
    } else {
      this.setSection(this.priceIndex, this.name.value);
    }
  }

  setPriceListItem() : void {
    const priceItem: PriceList = this.createPriceList(this.type);
    if (this.locationEditS.locationEdit.priceLists && this.locationEditS.locationEdit.priceLists.length == 0) {
      this.locationEditS.locationEdit.priceLists = [priceItem]
      this.setSection(0, this.name.value);
    } else {
      this.locationEditS.locationEdit.priceLists.push(priceItem);
      const index  = this.locationEditS.locationEdit.priceLists.length - 1;
      this.setSection(index, this.name.value);
    }
  }

  setSection(index, name: string) : void {
    const section = this.createSection(name);
    this.locationEditS.locationEdit.priceLists[index].sections.push(section);
  }

  editSection(index, sectionIndex, name: string) : void {
    const section = this.createSection(name);
    this.locationEditS.locationEdit.priceLists[index].sections[this.sectionIndex] = section;
  }

  private async _saveInFirestore() : Promise<void> { 
    await this.locationEditS.setAndUpdate().toPromise()
    this.dialogRef.close()
  }

  createPriceList(displayName: string) : PriceList {
    displayName = this.locationS.convertPriceListType(displayName);
    const priceItem: PriceList = {
      priceListId: uuid.v4(),
      sections: [],
      labels: [{displayName}]
    };
    return priceItem;
  }

  createSection(displayName: string) : Section {
    let secType: SectionType;
    if (this.type == 'menu') {
      secType = SectionType.FOOD
    } else if (this.type == 'services' || this.type == 'jobs') {
      secType = SectionType.SERVICES
    }
    
    const section: Section = {
      sectionId: uuid.v4(),
      labels: [{displayName}],
      sectionType: secType,
      items: []
    };
    return section;
  }

  remove() : void {
    this.locationEditS.locationEdit.priceLists[this.priceIndex].sections.splice(this.sectionIndex);
    this._saveInFirestore();
  }


  // form init and fields

  initForm() : void {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }


  get name() {
    return this.form.get('name');
  }


}
