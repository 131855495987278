import { __awaiter, __generator, __read, __spread } from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BulkLocationsEditService } from 'src/app/services/bulk-locations-edit.service';
import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { ModalPhotosComponent } from '../modal-photos/modal-photos.component';
import { ModalServiceComponent } from '../modal-service/modal-service.component';
import { LocationService } from '../../services/location.service';
import { ModalMenuComponent } from '../modal-menu/modal-menu.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SessionService } from 'src/app/services/session.service';
var ModalViewBulkLocationsChangesComponent = /** @class */ (function () {
    function ModalViewBulkLocationsChangesComponent(dialogRef, dialog, locationS, _sessionS, data, bulkService, snackS) {
        var _a, _b;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.locationS = locationS;
        this._sessionS = _sessionS;
        this.data = data;
        this.bulkService = bulkService;
        this.snackS = snackS;
        this.accounts = [];
        this.component = '';
        this.dataSource = [];
        this.page = 1;
        this.pages = 1;
        this.loadingNoti = false;
        this.loading = true;
        if (data) {
            this.accounts = data.accounts;
            this.component = data.component;
            this.bulk_id = data._id;
            this.bulkStatus = (_a = (data.status)) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            this.showDeleted = (_b = data) === null || _b === void 0 ? void 0 : _b.deleted;
        }
    }
    ModalViewBulkLocationsChangesComponent.prototype.ngOnInit = function () {
        this.getData(this.page);
    };
    ModalViewBulkLocationsChangesComponent.prototype.getData = function (page) {
        var _this = this;
        if (this.component !== '') {
            this.bulkService.getResumeEdits(this.bulk_id, page, !!this.showDeleted).take(2).subscribe(function (data) {
                _this.pages = data.pages;
                _this.dataSource = __spread(_this.dataSource, data.response);
                _this.loadingNoti = false;
                _this.loading = false;
            });
        }
        else {
            this.loading = false;
        }
    };
    ModalViewBulkLocationsChangesComponent.prototype.openFetchDialog = function (loc, notifyErrors) {
        if (notifyErrors === void 0) { notifyErrors = false; }
        this.dialog.open(ModalFetchComponent, {
            width: '1000px',
            data: {
                location: loc.log,
                difference: loc.log.difference,
                placeId: loc.location_id,
                history: true,
                notifyErrors: notifyErrors,
                error: loc.log.error,
                error_details: loc.error_details
            }
        });
    };
    ModalViewBulkLocationsChangesComponent.prototype.showMedia = function (accountId, locationId, location) {
        var _a, _b, _c;
        this.dialog.open(ModalPhotosComponent, {
            width: '1000px',
            data: {
                accountId: accountId,
                locationId: locationId,
                showOnlyMedia: true,
                error: (_b = (_a = location) === null || _a === void 0 ? void 0 : _a.log) === null || _b === void 0 ? void 0 : _b.error,
                error_details: (_c = location) === null || _c === void 0 ? void 0 : _c.error_details
            }
        });
    };
    ModalViewBulkLocationsChangesComponent.prototype.showServices = function (accountId, locationId, loc) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var result, serviceList, primaryCategory, additionalCategories, location;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.locationS.fetchLocation(this._sessionS.getSession().gid, accountId, locationId)];
                    case 1:
                        result = _d.sent();
                        serviceList = [];
                        primaryCategory = null;
                        additionalCategories = [];
                        location = result.locationEdit || result.location;
                        if ('serviceList' in result) {
                            serviceList = result.serviceList;
                        }
                        if ('additionalCategories' in location) {
                            additionalCategories = location['additionalCategories'];
                        }
                        primaryCategory = location['primaryCategory'];
                        this.dialog.open(ModalServiceComponent, {
                            width: '1000px',
                            data: {
                                type: 'Services',
                                serviceList: serviceList,
                                primaryCategory: primaryCategory,
                                additionalCategories: additionalCategories,
                                accountId: accountId,
                                locationId: locationId,
                                showOnly: true,
                                error: (_b = (_a = loc) === null || _a === void 0 ? void 0 : _a.log) === null || _b === void 0 ? void 0 : _b.error,
                                error_details: (_c = loc) === null || _c === void 0 ? void 0 : _c.error_details
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalViewBulkLocationsChangesComponent.prototype.showMenu = function (accountId, locationId, loc) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var result, menuList;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.locationS.fetchLocation(this._sessionS.getSession().gid, accountId, locationId)];
                    case 1:
                        result = _d.sent();
                        menuList = [];
                        // const location = result.locationEdit || result.location
                        if ('menuList' in result) {
                            menuList = result.menuList;
                        }
                        this.dialog.open(ModalMenuComponent, {
                            width: '1000px',
                            data: {
                                foodMenu: menuList,
                                accountId: accountId,
                                locationId: locationId,
                                onlyShow: true,
                                error: (_b = (_a = loc) === null || _a === void 0 ? void 0 : _a.log) === null || _b === void 0 ? void 0 : _b.error,
                                error_details: (_c = loc) === null || _c === void 0 ? void 0 : _c.error_details
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalViewBulkLocationsChangesComponent.prototype.handleReload = function (e) {
        e.preventDefault();
        if (!this.loadingNoti) {
            this.page += 1;
            this.loadingNoti = true;
            this.getData(this.page);
        }
    };
    ModalViewBulkLocationsChangesComponent.prototype.retryFailedLocations = function () {
        var _this = this;
        this.loading = true;
        this.bulkService.retryFailedLocations(this.bulk_id).subscribe(function (res) {
            _this.dialogRef.close(true);
            _this.snackS.openSuccess('Failed locations were updated successfully', 2000);
        }, function (err) {
            _this.loading = false;
            _this.dialogRef.close(false);
            _this.snackS.openError('There was an error while trying to update the failed locations', 2000);
        });
    };
    ModalViewBulkLocationsChangesComponent.prototype.purgeFailedLocations = function () {
        var _this = this;
        this.loading = true;
        this.bulkService.purgeFailedLocations(this.bulk_id).subscribe(function (res) {
            _this.snackS.openSuccess('Failed locations were deleted successfully', 2000);
            _this.dialogRef.close(true);
        }, function (err) {
            _this.loading = false;
            _this.dialogRef.close(false);
            _this.snackS.openError('There was an error while deleting failed locations', 2000);
        });
    };
    return ModalViewBulkLocationsChangesComponent;
}());
export { ModalViewBulkLocationsChangesComponent };
