// dep
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

// app
import AccountObject from '../../constants/firestore/account-object';
import { GoogleLocationService } from '../../services/google-location.service';
import User from '../../constants/firestore/user';
import { DataPicker } from '../../constants/data-picker';
import GoogleLocation from 'src/app/constants/firestore/google-location';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';
// import AccountLocation from '../../constants/firestore/account-location';


/**
 * Dialog to select an Account (from a list provided externally) and a Location.
 * Used only from External Grade
 */
@Component({
  selector: 'app-account-loc-selector',
  templateUrl: './account-loc-selector.component.html',
  styleUrls:  ['./account-loc-selector.component.scss']
})
export class AccountLocSelectorComponent extends BaseComponent {
  googleAccounts: AccountObject[];
  user: User;
  selectedAccount: AccountObject;
  selectedLocation: GoogleLocation;
  dataPicker: DataPicker;

  readonly locations$ = this._googleLocationS.locations$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AccountLocSelectorComponent>,
    private _googleLocationS: GoogleLocationService,
    private _sessionS : SessionService
  ) {
    super();
    this.user = this.data.user;
    this.googleAccounts = this.data.accounts;
    this._subscribeSafe(this._googleLocationS.locations$,
                        locations => this._verificateLocations(locations) );
  }

  backAccount() : void {
    this._googleLocationS.reset();
  }


  /**
   * When the user chooses an Account, fetch all his locations
   */
  selectAccount(): void {
    const {externalGradeId} = this._sessionS.getSession('EXTERNAL_GRADER');
    this._googleLocationS.loadAllGrade(this.user, this.selectedAccount.name, externalGradeId);
  }


  /**
   * User press 'Grade location' with a Location selected, and the dialog is closed. 
   */
  selectLocation() : void {
    this.dialogRef.close({location: this.selectedLocation, account: this.selectedAccount});
    this._googleLocationS.reset();
  }

  private _verificateLocations(locations: GoogleLocation[]) : void {
    locations.forEach( location => {
      if ( location?.locationState ) {
        const s = location.locationState;
        if ( s.hasPendingVerification ) {
          s.description = 'Pending verification';
          s.fill = 'review-response--error';
          s.txt = 'txt--orange';
        } else if ( s.isDisabled ) {
          s.description = 'Disabled';
          s.fill = 'review-response--info';
          s.txt = 'txt--blue';
        } else if ( s.needsReverification ) {
          s.description = 'Needs Reverification';
          s.fill = 'review-response--error';
          s.txt = 'txt--orange';
        } else if ( s.isSuspended ) {
          s.description = 'Suspended';
          s.fill = 'review-response--info';
          s.txt = 'txt--blue';
        } else if ( s.isDuplicate ) {
          s.description = 'Duplicate';
          s.fill = 'review-response--error';
          s.txt = 'txt--orange';
        }
      }
    })
  }

}
