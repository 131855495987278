<div id="dashboard-filter" class="d-flex bg--white pl--0 pr--0" 
  [class.p--20]="reportType == 'qanda'"
  *ngIf="dataPicker || reportType === 'qanda'"
  [ngClass]="{'filter-dashboard': accountId !== undefined, 
                        'filter': accountId === undefined}" 
  [class.is-impersonating]="(session$ | async).isImpersonating"
  [style.top]=" ((session$ | async).isTrial || topBanner) ? '101px' : '64px' "
  style="z-index: 1000;">
  <div *ngIf="progress" class="capturing-spinner">
      <h1>Exporting file...</h1>
      <mat-spinner></mat-spinner>
  </div>

  <!--export dropdown -->
  <div class="">
    <ng-container *ngIf="accountId === undefined; else goLocations">
      <div class="d-flex justify-content-start" (click)="GoBack(reportType)" data-html2canvas-ignore *ngIf="!isShared">
        <button mat-flat-button [style.color]="'#8c91a2'" class="btn btn--default btn--icon btn--md cursor--pointer"
                [disabled]="!(locationP | async)" 
                [gmbLGF]="{location: (locationP | async), 
                            account: locations[0]?.accountId}">
          <i class="fas fa-chevron-left mr--5"></i>
          <span *ngIf="reportType != 'qanda'">Back To Reports</span>
          <span *ngIf="reportType == 'qanda'">Back</span>
        </button>
      </div>
    </ng-container>
    <ng-template #goLocations>
      <div class="d-flex justify-content-start ml--15 ml--290" (click)="GoLocations()" data-html2canvas-ignore
        *ngIf="!isShared">
        <button mat-flat-button [style.color]="'#8c91a2'"
          class="btn btn--default btn--icon btn--md mr--5 cursor--pointer">
          <i class="fas fa-chevron-left mr--5"></i>
          Back To Locations
        </button>
      </div>
    </ng-template>
  </div>

  <div class="d-flex flex-grow-1 justify-content-end" *ngIf="reportType != 'qanda' && dataPicker">
    <!-- <div class="filter__item alternate-theme" *ngIf="hasClearValues" data-html2canvas-ignore>
      <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg" matTooltip="Clear values" (click)="clearValues()">
        <i class="far fa-trash-alt"></i>
      </button>
    </div> -->

    <div class="filter__item" *ngIf="hasSaveValues" data-html2canvas-ignore>
      <button mat-button class="btn btn-icon btn-icon--lg btn-outline-transparent" (click)="saveValues()" matTooltip="Save and remember the values entered on this report for next time">
        Save Report Data
      </button>
    </div>

    <!--custom chart-->
    <div class="filter__item" *ngIf="hasCustomChart">
      <button mat-button [matMenuTriggerFor]="metricsMenu" #metricsTrigger="matMenuTrigger" class="filter__item__btn">
        Custom Chart <i class="fal fa-angle-down" data-html2canvas-ignore="true"></i>
      </button>

      <mat-menu #metricsMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="filter__item__content">
        <div (click)="$event.stopPropagation()">
          <div class="filter__header d-flex justify-content-between align-items-center">
            <h3 class="txt--uppercase"><strong>metrics</strong></h3>
            <mat-checkbox name="allChecked" [(ngModel)]="allChecked" (change)="toggleCheckAll()">
              Select All
            </mat-checkbox>
          </div>
          <div class="filter__content">
            <div class="row checkbox-list">
              <div class="col-12 col-md-6" 
                   *ngFor="let metric of metricsCustomChart">
                <mat-checkbox name="checked" [(ngModel)]="metric.checked" (change)="isAllChecked()">
                  {{ metric.name }}
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="filter__item__footer">
            <button class="btn btn--md" mat-flat-button color="accent" (click)="handleMultiChart()">
              Apply
            </button>
          </div>
        </div>
      </mat-menu>
    </div>

    <!--calendar dropdown-->
    <div class="filter__item d-flex align-items-baseline mr--10"
      *ngIf="dataPicker.range && reportType!=='Review Assistant' && reportType!=='keyword'">
      <span *ngIf="reportType ==='comparison' || reportType == 'performance-comparison'"
            class="d-inline-block txt--normal ml--10 mr--10 mb--0">
        Date Range A
      </span>
      <span *ngIf="reportType !=='comparison' && reportType !== 'performance-comparison'"
            class="d-inline-block txt--normal ml--10 mr--10 mb--0">
        Date Range
      </span>
      <app-date-picker 
        [reportType]="reportType" 
        [dataPicker]="dataPicker" 
        (dateSelected)="datesUpdated($event)"
        [minRange]="minRange" 
        [maxDate]="maxDate" 
        [minDateRange]="minDate" 
        [subscribeToDatepicker]="subscribeToDatepicker" 
        [disabled]="isShared && lockDates" 
        style="width: 250px;"
        [select]="selectedDateA" 
        [showTooltipsOnDisabledDates]="showTooltipsOnDisabledDates" 
        [hasDateLimitsInfo]="hasDateLimitsInfo">
      </app-date-picker>
    </div>


    <!--calendar dropdown-->
    <div class="filter__item d-flex align-items-baseline mr--10"
      *ngIf="dataPicker.range && (reportType =='comparison' || reportType == 'performance-comparison')">
      <span *ngIf="reportType==='comparison' || reportType == 'performance-comparison'"
        class="d-inline-block txt--normal ml--10 mr--10 mb--0">
        Date Range B
      </span>
      <app-date-picker 
        [reportType]="reportType" 
        [dataPicker]="dataPicker" 
        [rangeB]="true" 
        (dateSelected)="datesUpdated($event)"
        [minRange]="minRange" 
        [minDateRange]="minDate" 
        [maxDate]="maxDate" 
        [subscribeToDatepicker]="subscribeToDatepicker" 
        [disabled]="isShared && lockDates" 
        style="width: 250px;"
        [select]="selectedDateB" 
        [showTooltipsOnDisabledDates]="showTooltipsOnDisabledDates" 
        [hasDateLimitsInfo]="hasDateLimitsInfo">
      </app-date-picker>
    </div>

    <!-- calendar month range -->
    <div class="filter__item d-flex align-items-baseline mr--10" *ngIf="reportType =='keyword'">
      <span class="d-inline-block txt--normal ml--10 mr--10 mb--0">
        Date Range
      </span>
      <ng-container *ngIf="!hasLastMonth() && montPicker?.lastEnd?.toString()?.includes('Dec'); else presets">
        <ejs-daterangepicker 
          #dateRangePickerMonthly 
          (change)="monthlyRangeSelected($event)" 
          [start]="montPicker?.start"
          [startDate]="ejsStartDate || montPicker?.lastYearStart"
          [endDate]="ejsEndDate || montPicker?.lastYearEnd" 
          [format]="montPicker?.format"
          [depth]="montPicker?.depth" 
          (created)="daterangepickerOpen()" 
          (click)="dateRange.show()"
          [max]="maxDate" 
          [min]="minDate" 
          [allowEdit]="false" 
          [showClearButton]="false">
        </ejs-daterangepicker>
      </ng-container>

      <ng-template #presets>
        <ejs-daterangepicker 
          #dateRangePickerMonthly 
          (change)="monthlyRangeSelected($event)" 
          [start]="montPicker?.start"
          [startDate]="ejsStartDate || montPicker?.lastYearStart"
          [endDate]="ejsEndDate || montPicker?.lastYearEnd" 
          [format]="montPicker?.format"
          [depth]="montPicker?.depth" 
          (created)="daterangepickerOpen()" 
          (click)="dateRange.show()"
          [max]="maxDate" 
          [min]="minDate" 
          [allowEdit]="false" 
          [showClearButton]="false">
          <e-presets>
            <e-preset label="Last Month" *ngIf="hasLastMonth()" [start]="montPicker?.lastStart" [end]="montPicker?.lastEnd">              
            </e-preset>
            <e-preset label="YTD" *ngIf="!montPicker?.lastEnd?.toString()?.includes('Dec')"
              [start]="montPicker?.thisYearStart" [end]="montPicker?.lastEnd">
            </e-preset>
          </e-presets>
        </ejs-daterangepicker>
      </ng-template>
    </div>

    <!--view by dropdown-->
    <div class="filter__item"
         *ngIf="(reportType!=='revenue' && reportType!=='Review Assistant' && reportType!=='keyword')">

      <span class="d-inline-block txt--normal ml--10 mr--10 mb--0">
        View By
      </span>

      <button mat-button [matMenuTriggerFor]="viewByMenu" class="filter__item__btn" ngDefaultControl
        [(ngModel)]="dataPicker.aggregation">
        {{ dataPicker.aggregation }} <i class="fal fa-angle-down" data-html2canvas-ignore></i>
      </button>

      <mat-menu #viewByMenu="matMenu" [overlapTrigger]="false" xPosition="before">
        <button mat-button color="accent" (click)="changeText('Month')">Month</button>
        <button mat-button color="accent" (click)="changeText('Week')">Week</button>
        <button mat-button color="accent" (click)="changeText('Day')">Day</button>
      </mat-menu>
    </div>
  </div>

  <!--select periods -->
  <div class="filter__item mr--15" *ngIf="hasPeriodSelect && isComparisonVisible">
    <span class="d-inline-block txt--normal ml--10 mr--10 mb--0">Compare to</span>
    <button mat-button [matMenuTriggerFor]="selectionRange" class="filter__item__btn" ngDefaultControl
      [(ngModel)]="selectRangeValue">
      {{ selectRangeValue?.displayName }} <i class="fal fa-angle-down" data-html2canvas-ignore></i>
    </button>

    <mat-menu #selectionRange="matMenu" [overlapTrigger]="false" xPosition="before">
      <ng-container *ngFor="let option of selectRangeOptions">
        <button class="cursor--pointer" mat-button [class.txt-disabled]="option.disabled" color="accent"
          (click)="changeSelectRangeValue(option)" [disabled]="option.disabled">{{option?.displayName}}</button>
      </ng-container>
    </mat-menu>
  </div>

  <div class="d-flex flex-grow-1 justify-content-end mr--15" *ngIf="reportType == 'qanda'">
    <div class="filter__item search-by-location p--0" *ngIf="isReport && (this.accounts?.length > 1 || this.accounts[0].locations.length > 1)">
      <p class="display-in-bl mr--10 mb--0">Filter By Location</p>
      <button mat-button [matMenuTriggerFor]="locationsFilter" #metricsTrigger="matMenuTrigger" class="filter__item__btn filter-by-location p--0">
        <p>{{labelsLocationsFiltered}}</p> 
        <i class="fal fa-angle-down" data-html2canvas-ignore="true"></i>
      </button>

      <mat-menu #locationsFilter="matMenu" [overlapTrigger]="false" xPosition="before" class="filter__item__content p--0">
        <div (click)="$event.stopPropagation()">
          <ng-container>
            <form>
              <mat-form-field class="col-12 p--0" appearance="fill">
                <mat-label>Search</mat-label>
                <input type="text" aria-label="Search" matInput [formControl]="filterLocationControl">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let account of filteredOptions | async" [value]="account">
                    <ng-container *ngFor="let account of locationsOptions">
                      <div class="search-by-location-options">
                        <mat-option class="m--0" *ngFor="let location of account.locations" [value]="{location: location, gid: account.gid}">
                          <mat-checkbox name="checked" [(ngModel)]="location.checked" (change)="getLocationsFiltered($event, account, location)">
                            <p class="m--0" *ngIf="location.locationName">{{location.locationName}}</p>
                            <gmb-address class="txt--lightgray m--0" 
                              [address]="location?.address"
                              [serviceAreas]="location?.serviceArea?.places?.placeInfos">
                              *ngIf="location?.address || location?.serviceArea">
                            </gmb-address>
                          </mat-checkbox>
                        </mat-option>
                      </div>
                    </ng-container>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </ng-container>
          <div class="filter__content">
            <ng-container *ngFor="let account of locationsOptions">
              <div class="search-by-location-options">
                <mat-option class="m--0" *ngFor="let location of account.locations" [value]="{location: location, gid: account.gid}">
                  <mat-checkbox name="checked" [(ngModel)]="location.checked" (change)="getLocationsFiltered($event, account, location)">
                    <p class="m--0">{{location.locationName}}</p>
                    <gmb-address class="txt--lightgray m--0"
                      [address]="location?.address"
                      [serviceAreas]="location?.serviceArea?.places?.placeInfos">
                      *ngIf="location?.address || location?.serviceArea">
                    </gmb-address>
                  </mat-checkbox>
                </mat-option>
              </div>
            </ng-container>
          </div>
          <mat-divider></mat-divider>
          <div class="search-by-location-apply w--100 bg--white">
            <button mat-flat-button class="btn txt--md p--10 m--10 bg--gray"
              (click)="locationsFilterdChange(locationsFilter, true)">Clear</button>
            <button mat-flat-button color="accent" class="btn txt--md p--10 m--10"
              (click)="locationsFilterdChange(locationsFilter, false)">Apply</button>
          </div>
        </div>
      </mat-menu>
    </div>

    <div class="filter__item">
      <div>
        <span class="input-field input-field--search input-field--header border--solid border--gray">
          <input class="keyword-search-height" type="text" [formControl]='keywordSearchInput' matInput
            id="keywordsSearch" placeholder="Search By Keyword" />
        </span>
      </div>
    </div>

    <div class="filter__item">
      <button mat-button [matMenuTriggerFor]="questionsType" class="filter__item__btn" ngDefaultControl
        [(ngModel)]="questionsTypeValue">{{ questionsTypeValue?.displayName }} <i class="fal fa-angle-down"
          data-html2canvas-ignore></i></button>
      <mat-menu #questionsType="matMenu" [overlapTrigger]="false" xPosition="before">
        <ng-container *ngFor="let option of questionsTypeOptions">
          <button class="cursor--pointer" mat-button color="accent"
            (click)="changeQuestionsTypeValue(option)">{{option?.displayName}}</button>
        </ng-container>
      </mat-menu>
    </div>

    <div class="filter__item">
      <button mat-button [matMenuTriggerFor]="questionsPeriod" class="filter__item__btn" ngDefaultControl
        [(ngModel)]="periodDaysValue">{{ periodDaysValue?.displayName }} <i class="fal fa-angle-down"
          data-html2canvas-ignore></i></button>
      <mat-menu #questionsPeriod="matMenu" [overlapTrigger]="false" xPosition="before">
        <ng-container *ngFor="let option of periodDaysOptions">
          <button class="cursor--pointer" mat-button color="accent"
            (click)="changeQuestionsPeriodValue(option)">{{option?.displayName}}</button>
        </ng-container>
      </mat-menu>
    </div>
  </div>

  <!--export button-->
  <div class="filter__item alternate-theme mr--20 ml--20" *ngIf="!isShared" data-html2canvas-ignore>
    <button *ngIf="isLocationReport && (reportType!=='Review Assistant')" 
      mat-flat-button color="primary" class="btn btn-icon btn-icon--lg"
      [matMenuTriggerFor]="exportMenu" 
      class="filter__item__btn" 
      (click)="scrollTop()"
      [gmbLGF]="{location: (locationP | async), 
                  account: locations[0]?.accountId}" 
      [disabled]="!(locationP | async)"
      gmbSubscriptionInTrial>
      <i class="fa fa-download"></i>
    </button>

    <button *ngIf="!isLocationReport && (reportType!=='Review Assistant')" mat-flat-button color="primary" class="btn btn-icon btn-icon--lg"
      [matMenuTriggerFor]="exportMenu" class="filter__item__btn" (click)="scrollTop()" gmbSubscriptionInTrial>
      <i class="fa fa-download"></i>
    </button>

    <mat-menu #exportMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="d-print-none">
      <button mat-button 
        color="primary" 
        (click)="handleExport('pdf')" 
        *ngIf="reportType !== 'keyword'"
        [disabled]="isComparisonReportMore25Locations()"
      >
        Download PDF
        <span *ngIf="isComparisonReportMore25Locations()"
          matTooltip="PDF export is not supported for Performance Comparison Reports with more than 25 locations."
          matTooltipPosition="above"
          matTooltipClass="mat-tooltip--override">
          <i class="fas fa-info-circle txt--lightgray txt--sm"></i>
        </span>
      </button>
      <button mat-button color="primary" (click)="handleExport('csv')"
        *ngIf="reportType != 'revenue' && reportType != 'comparison' && reportType != 'performance-comparison'">
        Download CSV
      </button>
    </mat-menu>
  </div>

  <!--share button-->
  <div class="filter__item alternate-theme mr--20" *ngIf="!isShared" data-html2canvas-ignore>
    <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg" [disabled]="!(locationP | async)"
      (click)="handleShared()" [gmbLGF]="{location: (locationP | async), 
                                           account: locations[0]?.accountId}">
      <i *ngIf="!(locationP | async)" class="fas fa-share-alt" matTooltip="Coming soon" matTooltipPosition="above"
        matTooltipClass="mat-tooltip--override"></i>
      <i *ngIf="(locationP | async)" class="fas fa-share-alt"></i>
    </button>
  </div>

  <!--refresh button-->
  <div class="filter__item alternate-theme mr--20" (click)="handleRefresh()" data-html2canvas-ignore
    *ngIf="(reportType!='revenue' && reportType!=='comparison' && reportType!=='Review Assistant' && reportType!=='keyword' && reportType!=='qanda') && !isSharedUrl">
    <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg">
      <i class="fas fa-redo" 
          [ngClass]="{ 'active-spin': (locationWasSent$ | async) }">
      </i>
    </button>
  </div>

</div>

