import { __awaiter, __generator } from "tslib";
// dep
import { EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// app
import { GoogleService } from './google.service';
import { ExternalGradeService } from './external-grade.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./google.service";
import * as i2 from "./external-grade.service";
import * as i3 from "./session.service";
var GoogleAccountService = /** @class */ (function () {
    function GoogleAccountService(_googleS, _externalGradeS, _sessionS) {
        this._googleS = _googleS;
        this._externalGradeS = _externalGradeS;
        this._sessionS = _sessionS;
        this._accounts = new BehaviorSubject([]);
        this.accounts$ = this._accounts.asObservable();
        this.onLoadAll$ = new EventEmitter();
    }
    GoogleAccountService.prototype.resetAccounts = function () {
        this._accounts.next([]);
    };
    GoogleAccountService.prototype.loadAll = function () {
        var googleAuth = this._sessionS.getSession().user.googleAuth;
        if (googleAuth)
            this._loadAccounts(googleAuth);
    };
    GoogleAccountService.prototype.loadAllGrade = function (gid, externalGradeId) {
        return __awaiter(this, void 0, void 0, function () {
            var googleAuth;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._externalGradeS.getExternalGradeToken(gid, externalGradeId)];
                    case 1:
                        googleAuth = _a.sent();
                        if (!!googleAuth) return [3 /*break*/, 2];
                        // User probably closed the google authentication popup without loggin-in
                        return [2 /*return*/];
                    case 2:
                        if (!googleAuth.token_expiry) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._loadAccounts(googleAuth)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    GoogleAccountService.prototype._loadAccounts = function (googleAuth) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var accounts, err_1, code, message;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._googleS.getAccounts(googleAuth)];
                    case 1:
                        accounts = _c.sent();
                        accounts.sort(function (a, b) { return (a.accountName > b.accountName) ? 1 : -1; });
                        this._accounts.next(accounts);
                        this.onLoadAll$.emit({ success: true, token: googleAuth });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _c.sent();
                        code = (_b = (_a = err_1) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.status;
                        message = ({ 901: 'Oops! There was an error in the application',
                            401: 'Google said you are not authorized! - Try again' }[code] ||
                            'Some odd error happened. Try again.');
                        this.onLoadAll$.emit({ success: false, details: err_1, message: message });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Must refactor as an high level flow against all callers of google.authenticate
    GoogleAccountService.prototype.openPopupGoogleOAuth = function (gid, uid, externalId) {
        return __awaiter(this, void 0, void 0, function () {
            var urlOAuth, win, popupInterval;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._googleS.authenticate(gid, uid, externalId)];
                    case 1:
                        urlOAuth = _a.sent();
                        win = window.open(urlOAuth, '_blank');
                        popupInterval = setInterval(function () {
                            if (win.closed) {
                                clearInterval(popupInterval);
                                _this.loadAllGrade(gid, externalId);
                            }
                        }, 500);
                        return [2 /*return*/];
                }
            });
        });
    };
    GoogleAccountService.prototype.ngOnDestroy = function () {
        this.onLoadAll$.complete();
    };
    GoogleAccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAccountService_Factory() { return new GoogleAccountService(i0.ɵɵinject(i1.GoogleService), i0.ɵɵinject(i2.ExternalGradeService), i0.ɵɵinject(i3.SessionService)); }, token: GoogleAccountService, providedIn: "root" });
    return GoogleAccountService;
}());
export { GoogleAccountService };
