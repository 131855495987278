import { __awaiter, __generator } from "tslib";
import { Router } from '@angular/router';
// app
import { LocationService } from './location.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./location.service";
import * as i3 from "./session.service";
var RedirectGuardService = /** @class */ (function () {
    function RedirectGuardService(router, locationService, _sessionS) {
        this.router = router;
        this.locationService = locationService;
        this._sessionS = _sessionS;
    }
    RedirectGuardService.prototype.canActivate = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gid, isTrial, _b, locationId, accountId, location;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        _a = _c.sent(), gid = _a.gid, isTrial = _a.isTrial;
                        _b = route.parent.params, locationId = _b.locationId, accountId = _b.accountId;
                        return [4 /*yield*/, this.locationService.fetchLocation(gid, accountId, locationId)];
                    case 2:
                        location = _c.sent();
                        if (!(!isTrial && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.router.navigate(['/account', accountId, 'location', locationId, 'insights'])];
                    case 3:
                        _c.sent();
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/, true];
                }
            });
        });
    };
    RedirectGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectGuardService_Factory() { return new RedirectGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LocationService), i0.ɵɵinject(i3.SessionService)); }, token: RedirectGuardService, providedIn: "root" });
    return RedirectGuardService;
}());
export { RedirectGuardService };
