import { __assign, __awaiter, __generator } from "tslib";
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from "@angular/fire/firestore";
// app
import { environment as ENV } from "@environment";
import { DOMAINS } from "../constants/firestore/collections";
import { HEADERS_NO_AUTH } from '../constants/auth';
import { BROWSER_DOMAIN } from '../helpers/utils.helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
var DomainService = /** @class */ (function () {
    function DomainService(_afs, _http) {
        this._afs = _afs;
        this._http = _http;
    }
    DomainService.prototype._fetchDomainFromFirestore = function (xdomain) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._afs.collection(DOMAINS).doc(xdomain).get().toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data()];
                }
            });
        });
    };
    DomainService.prototype.fetchCurrentDomain = function () {
        return __awaiter(this, void 0, void 0, function () {
            var domainP, domainFs, domain, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        domainP = this._http.get(ENV.coreApiUrl + "/domains/" + BROWSER_DOMAIN.domainName, HEADERS_NO_AUTH).toPromise();
                        return [4 /*yield*/, this._fetchDomainFromFirestore(BROWSER_DOMAIN.xDomainName)];
                    case 1:
                        domainFs = _a.sent();
                        domain = { trialDays: 30 };
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, domainP];
                    case 3:
                        domain = (_a.sent()).data;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, __assign(__assign({}, domainFs), domain)];
                }
            });
        });
    };
    // TODO: Unused, remove
    // get apiUrlForGoogle() : string {
    //   const url = environment.apiUrl
    //   return url.endsWith('/api') ? url.substring(0, url.length - 4) : url
    // }
    /**
      * Returns the public key used to create add-credit-card tokens configured
      * for the current domain for the Development environment (Stripe test mode)
      */
    DomainService.prototype.getStripeTestMaplabsPublicKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fetchDomainFromFirestore('localhost')];
                    case 1: return [2 /*return*/, (_a.sent()).stripe_keys.public_key];
                }
            });
        });
    };
    // TODO: Replace usages of those with usages thru SessionService
    /**
     * Returns the public key used to create add-credit-card tokens configured
     * for the current domain.
     */
    DomainService.prototype.getStripePublicKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._fetchDomainFromFirestore('app_maplabs_com')];
                    case 1: 
                    // Always uses the maplabs PK, no matter the WL domain, because billing-api 
                    // runs with the maplabs Stripe key, not the one with the WL Stripe (Connect)
                    // Accounts.
                    return [2 /*return*/, (_a.sent()).stripe_keys.public_key];
                }
            });
        });
    };
    DomainService.prototype.getStripeConnectAccountId = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.fetchCurrentDomain()];
                    case 1: return [2 /*return*/, (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.stripeConnect) === null || _b === void 0 ? void 0 : _b.stripeAccountId];
                }
            });
        });
    };
    DomainService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
