import { OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
// dep
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
// app
import { PostManagementListComponent } from './post-management-list/post-management-list.component';
import { SnackbarService } from '../../services/snackbar.service';
import { PostService } from 'src/app/services/post.service';
import { SessionService } from 'src/app/services/session.service';
var PostManagementComponent = /** @class */ (function () {
    function PostManagementComponent(_route, _snack, _cdRef, _postS, _renderer2, _sessionS) {
        this._route = _route;
        this._snack = _snack;
        this._cdRef = _cdRef;
        this._postS = _postS;
        this._renderer2 = _renderer2;
        this._sessionS = _sessionS;
        this.location = {};
        this.isSpinner = false;
        this.isPostSliderOpened = false;
        this.selected = new FormControl(0);
        this.postToEdit = {};
        this.updateList = false;
        this.isEdit = false;
        this.focusTab$ = new BehaviorSubject(0);
        this.session$ = this._sessionS.session$;
    }
    PostManagementComponent.prototype.ngOnInit = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.isBulkPost = this.dashboardType === 'BULK_POST';
        if (!this.isBulkPost) {
            this.accountId = (_d = (_c = (_b = (_a = this._route) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.snapshot) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.accountId;
            this.locationId = (_h = (_g = (_f = (_e = this._route) === null || _e === void 0 ? void 0 : _e.parent) === null || _f === void 0 ? void 0 : _f.snapshot) === null || _g === void 0 ? void 0 : _g.params) === null || _h === void 0 ? void 0 : _h.locationId;
        }
    };
    PostManagementComponent.prototype.create = function () { };
    PostManagementComponent.prototype.openSlider = function (drawer, element) {
        if (element === void 0) { element = {}; }
        var _a;
        var body = (_a = document.getElementsByTagName('body')) === null || _a === void 0 ? void 0 : _a[0];
        this._renderer2.addClass(body, 'overflow--hidden');
        this.isPostSliderOpened = true;
        this.updateList = null;
        this.postToEdit = element;
        drawer.toggle();
    };
    PostManagementComponent.prototype.closeSlider = function (update) {
        var _a;
        this.postToEdit = {};
        this.isPostSliderOpened = false;
        if (update) {
            this.updateList = update;
        }
        var body = (_a = document.getElementsByTagName('body')) === null || _a === void 0 ? void 0 : _a[0];
        this._renderer2.removeClass(body, 'overflow--hidden');
        this._cdRef.detectChanges();
    };
    PostManagementComponent.prototype.editElement = function (data, drawer) {
        this.openSlider(drawer, data);
    };
    PostManagementComponent.prototype.tabChange = function ($event) {
        this.refreshPostsByIndex($event.index);
    };
    PostManagementComponent.prototype.indexChange = function ($event) {
        this.focusTab$.next($event);
        this.selected.setValue($event);
    };
    PostManagementComponent.prototype.refreshPostsByIndex = function (index) {
        switch (index) {
            case 0:
                this.dashboardType = this.isBulkPost ? 'BULK_POST' : 'LOCAL_POST';
                this.tabPosted.refresh();
                this._cdRef.detectChanges();
                break;
            default:
                this.dashboardType = 'ARCHIVE';
                this.tabArchive.refresh();
                this._cdRef.detectChanges();
                break;
        }
    };
    PostManagementComponent.prototype.postFetch = function () {
        var _this = this;
        this.isSpinner = true;
        this._postS.retrievePost(this._sessionS.getSession().gid, this.accountId, this.locationId).subscribe(function (res) {
            _this._snack.openSuccess('The recovery was successful');
            _this.updateList = true;
            _this.isSpinner = false;
        }, function (err) {
            _this.isSpinner = false;
            _this.updateList = false;
            _this._snack.openError("There was a problem while trying to recover the posts");
        });
    };
    Object.defineProperty(PostManagementComponent.prototype, "tooltip", {
        get: function () {
            return this.isBulkPost ? 'Create, publish and view the status of bulk posts.' : "Create, publish and view the status of this location's posts.";
        },
        enumerable: true,
        configurable: true
    });
    return PostManagementComponent;
}());
export { PostManagementComponent };
