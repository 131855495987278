// dep
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';

// app
import { SnackbarService } from '../../services/snackbar.service';
import { ReportService } from '../../services/report.service';
import { LocationService } from '../../services/location.service';
import { ReviewsService } from '../../services/reviews.service';
import { DataPicker } from '../../constants/data-picker';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { ProtocolService } from '../../services/protocol.service';
import { Protocol } from '../../constants/firestore/protocol';
import { LoadingService } from '../../services/loading.service';
import { LocationRef } from 'src/app/constants/firestore/location-object';

@Component({
  selector: 'app-protocol-report',
  templateUrl: './protocol-report.component.html',
  styleUrls: ['./protocol-report.component.scss']
})
export class ProtocolReportComponent implements OnInit, OnDestroy {
  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() reportName: string;
  @Input() isShared = false;
  @Input() lockDates = false;
  reviewsStats: any;
  // refreshFilter = new ReplaySubject(1);
  protocols: Protocol[];
  last : any = null;
  next : any = null;
  public dataPicker: DataPicker;
  private resume = new Subject<any>();
  // responses: { answered: number, notAnswered: number };
  count_reviews_replies_protocols$: Observable<number> = of(null);
  count_reviews_remaining = 0;
  page = 1;
  size = 10;
  private paginate: Pageable = {size: this.size, page: this.page};
  private previousPageable: Pageable={page: 1, size: 10};
  pagination: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };

  displayedColumns: string[] = ['name', 'status', 'triggers'];
  dataSource = new MatTableDataSource<Protocol>([]);

  // subscription by close when OnDestroy
  private ratingDistribution$: Subscription;
  // private aggregationResponses$: Subscription;
  private reviewResume$: Subscription;

  noData: boolean;
  isFailedResume: boolean;
  isProgressRating: boolean;
  protocolsExist = false;
  filtered = false;
  searchText

  // progress events
  isProgressResume = true;
  isProgressDistribution = true;
  // isProgressResponses = true;
  progress = new BehaviorSubject(true);
  NotFoundSearch$  = new BehaviorSubject(false);
  rating: any;
  isReport: boolean;
  noDataGoogle: boolean;
  // noDataDistribution: boolean;
  differenceTotal: number;
  differenceAverage: number;

  gid: string;
  locationId: string;
  accountId: string;
  locations: LocationRef[];

  // TimeOut search
  timeout: any = null;

  selectionProtocol = new SelectionModel<Protocol>(true, []);
  searchArrayProtocols: Protocol[] = []

  constructor(
    private protocolS: ProtocolService,
    private route: ActivatedRoute,
    private reviewsS: ReviewsService,
    private locationS: LocationService,
    private reportS: ReportService,
    private snack: SnackbarService,
    private loadingService: LoadingService
  ) {  }

  ngOnDestroy(): void {
    this.reviewResume$.unsubscribe();
    this.ratingDistribution$.unsubscribe();
    // this.aggregationResponses$ .unsubscribe();
  }

  ngOnInit() {
    this.locations = this.reportDataPicker.locations;
    this.dataPicker = this.reportDataPicker;
    this.gid = this.route.snapshot.params.gid;
    this.getReviewData(this.dataPicker, false);
    this.getData()
  }

  /**
   * this method get all data of charts when change date picker
   */
  getReviewData(dataPicker: any, refresh?: boolean) {
    this.isProgressResume = true;
    this.isProgressDistribution = true;
    // this.isProgressResponses = true;

    if (!this.locations) return;


    this.reviewResume$ = this.locationS.review_summary(this.gid, this.locations).take(2).subscribe(resume => {
      if (resume) {
        this.isFailedResume = false;
        this.isProgressResume = false;
        if (resume?.length > 1) {
          this.resumeManyLocations(resume);
        } else {
          this.reviewsStats      = resume;
          this.differenceAverage = resume.difference.average;
          this.differenceTotal   = resume.difference.total;
        }
        this.resume.next(this.reviewsStats);
        this.loadingService.reportAdvanced(1, "Review Summary")
      } else {
        this.isProgressResume = false;
        this.isFailedResume = true;
      }
      refresh && this.snack.openSuccess('Finished refreshing! ', 2000);
    });


    this.count_reviews_replies_protocols$ = this.protocolS.getProtocolDataCounters(this.locations).pipe(
      map( r => r.Count)
    )

    this.ratingDistribution$ = this.reviewsS.ratingDistribution(this.locations).take(2).subscribe(result => {
      if (result.data) {
        this.isProgressDistribution = false;
        this.rating = result.data;
        this.loadingService.reportAdvanced(1, "Review Ratings")
      }
    }, () => {
      // this.noDataDistribution = true;
      this.isProgressDistribution = false;
    });

    // this.aggregationResponses$ = this.reviewsS.aggregationResponses(this.locations, null).take(2).subscribe(result => {
    //   this.responses = result.data;
    //   this.isProgressResponses = false;
    //   this.loadingService.reportAdvanced(1, "Review Responses")
    // });
  }

  isAllSelectedProtocol() {
    const numSelected = this.selectionProtocol.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggleProtocol() {
    this.isAllSelectedProtocol() ?
      this.selectionProtocol.clear() :
      this.dataSource.data.forEach(row => this.selectionProtocol.select(row));
  }

  checkboxLabelProtocol(row?: Protocol): string {
    if (!row) {
      return `${this.isAllSelectedProtocol() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionProtocol.isSelected(row) ? 'deselect' : 'select'} row ${row.protocolId + 1}`;
  }

  progressValue(element): number {
    return (element.status.replied / element.status.total) * 100;
  }

  // TODO: Refactor against dashboard-reviews.component.ts / resumeManyLocations
  resumeManyLocations(resumes: any[]) {
      const stats = { averageRating    : 0, 
                      totalReviewCount : 0, 
                      answered         : 0, 
                      notAnswered      : 0, 
                      googleResume     : { totalReviewCount : 0 }
                    }
      const difference = { average : 0,
                           total   : 0 }
      let length = resumes.length
      for(const r of resumes) {
          if (!r || r.totalReviewCount === 0) {
              if (length > 0) 
                  length--
              continue
          }
          
          difference.total       += r.difference.total
          difference.average     += r.difference.average
          stats.averageRating    += r.averageRating
          stats.totalReviewCount += r.totalReviewCount
          stats.answered         += r.answered
          stats.notAnswered      += r.notAnswered
          stats.googleResume.totalReviewCount += r.googleResume?.totalReviewCount || 0
      }

      stats.averageRating   /= (length || 1)
      difference.average    /= (length || 1)
      this.differenceTotal   = difference.total
      this.differenceAverage = difference.average
      this.reviewsStats      = stats
      this.isProgressResume  = false
  }

  private getData() {
    this.protocolS.getProtocolReport(this.locations, this.paginate)
    .pipe(
      take(1)
    )
    .subscribe( async (result) => {
      const pagination = result.paginate
      await this.locationS.basicLocations(pagination.items);
      this.protocols = pagination.items as Protocol[];
      this.pagination = pagination;
      this.previousPageable = {size: pagination.per_page, page: pagination.page}
      this.progress.next(false);
      this.dataSource = new MatTableDataSource<Protocol>(this.protocols);
      this.protocolsExist = this.dataSource.data.length > 0;
      this.count_reviews_remaining = result.remaining
      this.searchArrayProtocols = result.protocols;
      this.loadingService.reportAdvanced(1, "Protocols")
    });

  }

  countLocation(element) {
    let counter = 0;
    element?.accounts?.forEach(account => {
      counter += account?.locations?.length;
    });
    return counter;
  }

  countLocations(element: any) {
    return this.reportS.countLocation(element);
  }

  // apply filter from search
  applyFilter($event: string, key: any) {
    this.NotFoundSearch$.next(false);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
        if (!$event || $event === '') {
          this.progress.next(true);
          this.initPaginate();
          this.getData();
          this.filtered = false;
          this.searchText = ''
        } else {
          !this.filtered && (this.initPaginate());
          this.progress.next(true);
          const text = $event.toLowerCase().trim();
          this.searchText = text;
          const search = this.searchArrayProtocols.filter(s => s.name.trim().toLowerCase().indexOf(text) > -1);
          this.setData(search)
          this.filtered = true;
        }

    }, 350);
  }

  initPaginate(): void {
    this.paginate = { page: 1, size: 10 };
    this.previousPageable = null;
    this.dataSource = new MatTableDataSource<Protocol>([] as Protocol[]);
  }

  setData(results) {
    const data = _.chunk(results, this.paginate.size);
    const items = data[this.paginate.page - 1];
    const source = this.protocolS.getProtocolsPaginate(results.length, this.paginate, items);
    this.previousPageable = { size: source.per_page, page: source.page };
    this.pagination = source;
    this.dataSource = new MatTableDataSource<Protocol>(source.items);
    this.progress.next(false);
  }

  handleReload($event: Pageable) {
    this.progress.next(true);
    this.paginate = $event;
    if (this.filtered) {
      this.applyFilter(this.searchText, 'Enter');
    } else {
      this.getData();
    }
  }

}
