// dep
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

// app
import { LocationService } from './location.service';
import { ModalService } from './modal.service';
import { SubscriptionService } from './subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { FeaturedComponent } from '../components/featured.component';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class LocationFreeGuardService implements CanActivate {
  constructor(
    private _sessionS: SessionService,
    private locationService: LocationService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
  ) {
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    const {gid, subscription, isTrial} = await this._sessionS.waitSession()
    const {locationId, accountId} = route.parent.params

    // TODO: This should be replaced by getting the current location cached on a global state.
    const {subscriptionType} = (await this.locationService.fetchMultipleLocations(gid, [{locationId, accountId}], 
                                                                                  ['subscriptionType'] as ['subscriptionType']))[0];
    if (subscription.pricingVersion < 3 && !isTrial &&
        (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
         subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
    ) {
      this.modalService.openModal(FeaturedComponent, null, { onClose : async res => {
        if (!res) {
          return;
        }
        console.log('LocationFreeGuardService upgrade suscription');
        if(await this.subscriptionService.flowChangeLocationsPlan([{ locationId, accountId }]))
          console.log("upgrade successful - location-guard")
      }})

      // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
      return false;
    }
    return true;
  }

}


/* TODO: Unused, remove 
@Injectable({
  providedIn: 'root'
})
export class LocationUltimateTrialGuardService implements CanActivate {

  constructor(
    private _sessionS: SessionService,
    private locationService: LocationService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
  ) {
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    const s = await this._sessionS.waitSession()

    const {locationId, accountId} = route.parent.params

    const location = await this.locationService.fetchLocation(s.gid, accountId, locationId)

    const {subscriptionType} = location
    const sub = s.subscription

    if (sub.pricingVersion < 3 && s.isTrial && 
        (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
         subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
      ) {
      this.modalService.openModal(FeaturedComponent, null, { onClose : async res => {
        if (!res) 
          return

        console.log('LocationUltimateTrialGuardService upgrade suscription');
        if(await this.subscriptionService.flowChangeLocationsPlan([{locationId, accountId}]))
          console.log("upgrade successful - location-guard")
      }})

      // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
      return false;
    }
    return true;
  }

}
*/