// dep
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// app
import { SnackbarService } from '../../services/snackbar.service';
import { NavigationService } from '../../services/navigation.service';
import { EmailerService } from 'src/app/services/emailer.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  passwordProgress = false;

  domain$ = this._sessionS.domain$
  
  constructor(
    private _sessionS: SessionService,
    private emailerService: EmailerService,
    private router: Router,
    private snack: SnackbarService,
    public navigationS: NavigationService
  ) {
    this.forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit() : void {
    // This was not needed, TODO: Remove
    // await this.authService.afAuth.auth.signOut();
  }

  async forgotPassword() : Promise<void> {
    const email = this.email.value
    try {
      this.passwordProgress = true;
      await this.emailerService.sendResetPasswordMail({ email })
      this.router.navigateByUrl('/login');
      this.snack.openSuccess(`A reset password email has been sent to ${email}`)
    } catch(e) {
      this.snack.openError(`The email ${email} is incorrect or not registered.`)
    } finally {
      this.passwordProgress = false;
    }
  }

  get email() {
    return this.forgotForm.get('email');
  }



}
