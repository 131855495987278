import { __awaiter, __generator } from "tslib";
// dep
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
// app
import { LocationService } from '../../services/location.service';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { ModalService } from '../../services/modal.service';
import { GradeExplainComponent } from '../grade-explain/grade-explain.component';
import { PdfService } from '../../services/pdf.service';
import { LoadingService } from '../../services/loading.service';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import { SessionService } from 'src/app/services/session.service';
import { BROWSER_DOMAIN } from 'src/app/helpers/utils.helpers';
var GradeHeaderLocationComponent = /** @class */ (function () {
    function GradeHeaderLocationComponent(route, locationS, modalS, _sessionS, titleService, pdfS, loadingService, gradeLoaded, gradeLeadService, externalGradeService) {
        this.route = route;
        this.locationS = locationS;
        this.modalS = modalS;
        this._sessionS = _sessionS;
        this.titleService = titleService;
        this.pdfS = pdfS;
        this.loadingService = loadingService;
        this.gradeLoaded = gradeLoaded;
        this.gradeLeadService = gradeLeadService;
        this.externalGradeService = externalGradeService;
        this.finish = new EventEmitter();
        this.isExternal = false;
        this.progress = true;
        this.exportInProgress = false;
    }
    GradeHeaderLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b, _c, _d;
        // TODO: the two below should be dynamic (the colors too if they depend on the percentage)
        this.circlePercent = 40;
        this.circleGrade = 'A';
        this.circleColorFirst = '#ff4949';
        this.circleColorSecond = '#E91616';
        if (!this.gid) {
            this.id = (_b = (_a = this.route.snapshot) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id;
            // if (this.isExternal) 
            //     this.gid =  this.auth.externalSession.gid;
            // else 
            //     this.gid = this.route.snapshot?.params?.gid || this._sessionS.getSession().gid;
            if (this.isExternal)
                this.gid = this._sessionS.getSession().gid;
            else
                this.gid = ((_d = (_c = this.route.snapshot) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.gid) || this._sessionS.getSession().gid;
            if (this.id) {
                this.isShared = true;
            }
        }
        this.locationS.getRef(this.gid, this.accountId, this.locationId).subscribe(function (loc) {
            _this.progress = false;
            _this.location = loc;
            _this.finish.emit(true);
            if (_this.isExternal) {
                var branding = _this._sessionS.getDomain().branding;
                _this.titleService.setTitle("Grade | " + loc.locationName + " | " + branding.company_name);
            }
            _this.loadingService.reportAdvanced(1, "Locations");
        }, function () {
            _this.finish.emit(false);
            _this.progress = false;
        });
        if (this.isExternal) {
            this.gradeLoaded.loaded$.subscribe(function (result) {
                if (result) {
                    setTimeout(function () {
                        _this._exportPdf(false);
                    }, 2000); // Time to finish animations before export
                }
            });
        }
        this.gradeLeadService.firebaseGradeId$.subscribe(function (id) {
            _this.firebaseGradeLeadId = id;
        });
    };
    GradeHeaderLocationComponent.prototype.handleShared = function () {
        this.modalS.openGenericModal(ModalShareComponent, {
            createNew: true,
            dataPicker: this.dataPicker,
            reportType: 'grade',
        });
    };
    GradeHeaderLocationComponent.prototype.openExplain = function () {
        this.modalS.openGenericModal(GradeExplainComponent, {}, null, 900);
    };
    GradeHeaderLocationComponent.prototype.handlePdf = function () {
        this._exportPdf();
    };
    GradeHeaderLocationComponent.prototype._exportPdf = function (saveToDisk) {
        if (saveToDisk === void 0) { saveToDisk = true; }
        return __awaiter(this, void 0, void 0, function () {
            var filename, pdfBase64, domain, gradeLead;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filename = "Grader - " + this.location.locationName;
                        return [4 /*yield*/, this.pdfS.base64AndSave('charts-container', filename, '', saveToDisk)];
                    case 1:
                        pdfBase64 = _a.sent();
                        this.exportInProgress = true;
                        if (saveToDisk) {
                            this.exportInProgress = false;
                            return [2 /*return*/];
                        }
                        if (!this.isExternal) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.externalGradeService.uploadExternalGradePDF(this.gid, this.locationId, this.accountId, pdfBase64, this.firebaseGradeLeadId, this._sessionS.getDomain().branding)];
                    case 2:
                        _a.sent();
                        domain = BROWSER_DOMAIN.domainNameWithPort;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, , 5, 6]);
                        return [4 /*yield*/, this.gradeLeadService.getGradeLeadById(this.firebaseGradeLeadId, domain)];
                    case 4:
                        gradeLead = _a.sent();
                        this.gradeLeadService.setHasPdf(gradeLead);
                        return [3 /*break*/, 6];
                    case 5:
                        this.exportInProgress = false;
                        return [7 /*endfinally*/];
                    case 6:
                        if (!pdfBase64) {
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return GradeHeaderLocationComponent;
}());
export { GradeHeaderLocationComponent };
