import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton--payment-method',
  templateUrl: './skeleton-payment-method.component.html'
})
export class skeletonPaymentMethodComponent implements OnInit {

  @Input() type: string;

  constructor() { }

  ngOnInit() {
  }

}
