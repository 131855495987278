import { __extends } from "tslib";
// dep
import { EventEmitter, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// app
import { StorageImageService } from '../../services/storage-image.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ModalService } from 'src/app/services/modal.service';
import { BaseComponent } from 'src/app/components/base.component';
var FormInputUploadComponent = /** @class */ (function (_super) {
    __extends(FormInputUploadComponent, _super);
    function FormInputUploadComponent(storageS, changeDetector, snack, modalService) {
        var _this = _super.call(this) || this;
        _this.storageS = storageS;
        _this.changeDetector = changeDetector;
        _this.snack = snack;
        _this.modalService = modalService;
        _this.actionText = 'post';
        _this.urlInput = null;
        _this.imageId = '';
        _this.url = new EventEmitter();
        _this.ref = new EventEmitter();
        _this.percentUpload = new EventEmitter();
        _this.category = 'PHOTO';
        _this.changedImg = new EventEmitter();
        _this.isMultiple = false;
        _this.showPreview$ = new BehaviorSubject(false);
        _this.isUploading = false;
        _this.hasPhoto = true;
        return _this;
    }
    FormInputUploadComponent.prototype.ngOnInit = function () {
        if (this.urlInput) {
            this.showPreview$.next(true);
            this.imageUrl = this.urlInput;
        }
        this.showLoader = (this.noLoader) ? this.noLoader : false;
    };
    FormInputUploadComponent.prototype.ngOnChanges = function (_a) {
        var urlInput = _a.urlInput;
        if (!urlInput.currentValue) {
            this.showPreview$.next(false);
            this.imageUrl = null;
        }
    };
    FormInputUploadComponent.prototype.fileChanged = function (target) {
        var _this = this;
        this.storageS.setUrl(null);
        this._subscribeSafe(this.storageS.url$, function (url) {
            var _a, _b;
            if (url) {
                if ((_a = url) === null || _a === void 0 ? void 0 : _a.error) {
                    _this.reset();
                }
                else if ((_b = url) === null || _b === void 0 ? void 0 : _b.preview) {
                    _this.url.emit({ url: url.url, mediaFormat: 'VIDEO', preview: url.preview, fileName: null });
                    _this.imageUrl = url.preview;
                    _this.category = 'VIDEO';
                    _this.showPreview$.next(true);
                }
                else {
                    _this.storageS.setImageUrl(url.url);
                    _this.url.emit({ url: url.url, mediaFormat: 'PHOTO', preview: null, fileName: url.fileName });
                    _this.imageUrlSubscription = _this.storageS.getImageUrl().subscribe(function (res) {
                        _this.imageUrl = res;
                        _this.hasPhoto = !res.includes('/assets/images/camera.svg');
                    });
                    _this.category = 'PHOTO';
                    _this.showPreview$.next(true);
                }
            }
            else {
                _this.url.emit(null);
                _this.imageUrl = null;
                _this.category = 'PHOTO';
                _this.showPreview$.next(false);
            }
            _this.isUploading = false;
        }, function (e) {
            console.error(e);
            _this.modalService.openErrorModal('Upload Error', "Error uploading the image, try again");
            _this.isUploading = false;
        });
        this.isUploading = true;
        this.changedImg.emit();
        this.storageS.fileChanged(target, true, this.requirements);
    };
    FormInputUploadComponent.prototype.reset = function () {
        this.showPreview$.next(false);
        this.imageUrl = null;
        this.storageS.reset();
        this.InputFile.nativeElement.value = "";
        this.changeDetector.markForCheck();
    };
    FormInputUploadComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    return FormInputUploadComponent;
}(BaseComponent));
export { FormInputUploadComponent };
