<div class="content--full d-flex bg--white align-items-center flex-column text-content">
  <div class="content__header">
    <h1 class="m--0"><strong>{{title}}</strong></h1>
  </div>
  <quill-editor *ngIf="editor" class="mb--20 bg--white w--90" [(ngModel)]="editor"  [readOnly]="true" [modules]="{ toolbar: false }" [theme]="'bubble'"></quill-editor>
  <div class="box box--md box--padding-xxs box--rounded bg--darkerblue txt--center btn-footer">
    <span class="d-inline-block mr--5 txt--white">
      Back to <a class="txt--lightblue" [routerLink]="['/login']"><u>Login</u></a>
    </span>
  </div>
</div>
