<app-skeleton-table-multilocations *ngIf="progress"></app-skeleton-table-multilocations>

<div class="container--full container--padding-lg pt--0 pb--0" *ngIf="!progress">
  <div class="box box--shadow box--rounded bg--white" *ngIf="!isEmpty; else emptyTable">
    <div class="box__header d-flex justify-content-between align-items-center alternate-theme">
      <h2 class="txt--capitalize"><strong>multi locations table</strong></h2>
      <div>
        <button mat-flat-button color="primary" class="btn btn--xs" (click)="resetSort()" style="position: relative; margin-right: 5px;">Reset Sort</button>
        <button mat-flat-button color="primary" class="btn btn--xs" *ngIf="!disableIcons" (click)="exportPDF()" style="margin-right: 5px;" gmbSubscriptionInTrial>Export PDF</button>
        <button [disabled]="isProgressCSV" mat-flat-button color="primary" class="btn btn--xs" *ngIf="!disableIcons" (click)="exportCSV()" gmbSubscriptionInTrial>
          Export CSV  <em *ngIf="isProgressCSV" class="fas fa-spinner fa-pulse"></em>
        </button>
      </div>
    </div>
    <div class="box__content">
      <button class="table-scroller table-scroller--left js-table-scroller-left" style="display: none; z-index: 3; opacity: 0.5;"
              (click)="moveLeft()"><i class="fal fa-angle-left"></i></button>
      <button class="table-scroller table-scroller--right js-table-scroller-right" [ngClass]="{'is-overflown' : isOverflowY}" style="display: block;"
              style="z-index: 3;" (click)="moveRight()"><i class="fal fa-angle-right"></i></button>
      <div class="table-wrapper table-wrapper--height js-table-scroll table-multi-locations" (scroll)="scrollTable()"
           [ngClass]="{'force-show-scrollbars' : showScrollbar }">
        <table id="table-location-container" 
          mat-table 
          [dataSource]="dataSource" 
          [matSortStart]="'desc'" 
          matSort 
          [matSortActive]="sort && sort.sortBy" 
          [matSortDirection]="sort && sort.direction" 
          class="table table--bordered table--overflow width-100-percent" 
          (matSortChange)="sortBy($event)"
          *ngIf="dataSource"
        >

          <!-- Location -->
          <ng-container sticky matColumnDef="location" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header="locationName" style="z-index: 5 !important;" class="txt--center">{{ displayedMetricColumns?.location }}</th>
            <td mat-cell *matCellDef="let row" style="z-index: 5;" [ngClass]="{'highlight':(sort && sort.sortBy === 'locationName')}">
              <div class="cell__content flex-column align-items-start">
                <p class="m--0 txt--black txt--md"><strong>{{ row.locationName }}</strong></p>
                <gmb-address [address]="row.address" [serviceAreas]="row?.serviceArea?.places?.placeInfos"></gmb-address>
                <div class="mt--5" *ngIf="!disableIcons">
                  <a [routerLink]="locationUrl(row.accountId,row.locationId,'insights')" target="_blank"
                     matTooltip="View Insights" matTooltipClass="tooltip tooltip--blue" matTooltipPosition="above"
                     class="d-inline-block btn btn--blue btn-icon mr--5"><img src="/assets/images/icons/graph.png"
                                                                              alt="Graph Icon"></a>
                  <a [routerLink]="locationUrl(row.accountId,row.locationId,'reviews')" target="_blank"
                     matTooltip="See Reviews" matTooltipClass="tooltip tooltip--orange" matTooltipPosition="above"
                     class="d-inline-block btn btn--orange btn-icon mr--5"><img
                    src="/assets/images/icons/star-empty.png" alt="Star Icon"></a>
                  <a [href]="row.mapsUrl" target="_blank" matTooltip="See Location on Map"
                     matTooltipClass="tooltip tooltip--green" matTooltipPosition="above"
                     class="d-inline-block btn btn--green btn-icon"><img src="/assets/images/icons/pin-green.png"
                                                                         alt="Pin Icon"></a>
                </div>
              </div>
            </td>
          </ng-container>
          <!-- Direction Request -->
          <ng-container matColumnDef="directionRequests" [sticky]="(sort && sort.sortBy === 'ACTIONS_DRIVING_DIRECTIONS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="ACTIONS_DRIVING_DIRECTIONS" class="txt--center">{{ displayedMetricColumns?.directionRequests }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'ACTIONS_DRIVING_DIRECTIONS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{row.ACTIONS_DRIVING_DIRECTIONS | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Mobile Phone Calls -->
          <ng-container matColumnDef="mobilePhoneCalls" [sticky]="(sort && sort.sortBy === 'ACTIONS_PHONE')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="ACTIONS_PHONE" class="txt--center">{{ displayedMetricColumns?.mobilePhoneCalls }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'ACTIONS_PHONE')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{row.ACTIONS_PHONE | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Website -->
          <ng-container matColumnDef="websiteVisits" [sticky]="(sort && sort.sortBy === 'ACTIONS_WEBSITE')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="ACTIONS_WEBSITE" class="txt--center">{{ displayedMetricColumns?.websiteVisits }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'ACTIONS_WEBSITE')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{row.ACTIONS_WEBSITE | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Mobile -->
          <ng-container matColumnDef="mobileViews" [sticky]="(sort && sort.sortBy === 'VIEWS_MOBILE')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="VIEWS_MOBILE" class="txt--center">{{ displayedMetricColumns?.mobileViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'VIEWS_MOBILE')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{row.VIEWS_MOBILE | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Desktop -->
          <ng-container matColumnDef="desktopViews" [sticky]="(sort && sort.sortBy === 'VIEWS_DESKTOP')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="VIEWS_DESKTOP" class="txt--center">{{ displayedMetricColumns?.desktopViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'VIEWS_DESKTOP')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{row.VIEWS_DESKTOP | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Local Post Actions -->
          <ng-container matColumnDef="localPostActions" [sticky]="(sort && sort.sortBy === 'LOCAL_POST_ACTIONS_CALL_TO_ACTION')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="LOCAL_POST_ACTIONS_CALL_TO_ACTION" class="txt--center">{{ displayedMetricColumns?.localPostActions }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'LOCAL_POST_ACTIONS_CALL_TO_ACTION')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{row.LOCAL_POST_ACTIONS_CALL_TO_ACTION | number: '1.0-0'}}</p>
              </div>

            </td>
          </ng-container>

          <!-- Local Post Views -->
          <ng-container matColumnDef="localPostViews" [sticky]="(sort && sort.sortBy === 'LOCAL_POST_VIEWS_SEARCH')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="LOCAL_POST_VIEWS_SEARCH" class="txt--center">{{ displayedMetricColumns?.localPostViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'LOCAL_POST_VIEWS_SEARCH')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.LOCAL_POST_VIEWS_SEARCH | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!-- Customer Photos Count -->
          <ng-container matColumnDef="customerPhotosCount" [sticky]="(sort && sort.sortBy === 'PHOTOS_COUNT_CUSTOMERS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="PHOTOS_COUNT_CUSTOMERS" class="txt--center">{{ displayedMetricColumns?.customerPhotosCount }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'PHOTOS_COUNT_CUSTOMERS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.PHOTOS_COUNT_CUSTOMERS | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!--  Merchant Photos Count -->
          <ng-container matColumnDef="merchantPhotosCount" [sticky]="(sort && sort.sortBy === 'PHOTOS_COUNT_CUSTOMERS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="PHOTOS_COUNT_MERCHANT" class="txt--center">{{ displayedMetricColumns?.merchantPhotosCount }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'PHOTOS_COUNT_MERCHANT')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.PHOTOS_COUNT_MERCHANT | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!-- Customer Photo Views -->
          <ng-container matColumnDef="customerPhotoViews" [sticky]="(sort && sort.sortBy === 'PHOTOS_VIEWS_CUSTOMERS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="PHOTOS_VIEWS_CUSTOMERS" class="txt--center">{{ displayedMetricColumns?.customerPhotoViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'PHOTOS_VIEWS_CUSTOMERS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.PHOTOS_VIEWS_CUSTOMERS | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!-- Merchant Photo Views -->
          <ng-container matColumnDef="merchantPhotoViews" [sticky]="(sort && sort.sortBy === 'PHOTOS_VIEWS_MERCHANT')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="PHOTOS_VIEWS_MERCHANT" class="txt--center">{{ displayedMetricColumns?.merchantPhotoViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'PHOTOS_VIEWS_MERCHANT')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.PHOTOS_VIEWS_MERCHANT | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!-- Direct Searches -->
          <ng-container matColumnDef="directSearches" [sticky]="(sort && sort.sortBy === 'QUERIES_DIRECT')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="QUERIES_DIRECT" class="txt--center">{{ displayedMetricColumns?.directSearches }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'QUERIES_DIRECT')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.QUERIES_DIRECT | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!-- Discovery Searches -->
          <ng-container matColumnDef="discoverySearches" [sticky]="(sort && sort.sortBy === 'QUERIES_INDIRECT')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="QUERIES_INDIRECT" class="txt--center">{{ displayedMetricColumns?.discoverySearches }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'QUERIES_INDIRECT')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.QUERIES_INDIRECT | number: '1.0-0' }}</p>
              </div>
            </td>
          </ng-container>

          <!-- Map Views -->
          <ng-container matColumnDef="mapViews" [sticky]="(sort && sort.sortBy === 'VIEWS_MAPS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="VIEWS_MAPS" class="txt--center">{{ displayedMetricColumns?.mapViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'VIEWS_MAPS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.VIEWS_MAPS  | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Search Views -->
          <ng-container matColumnDef="searchViews" [sticky]="(sort && sort.sortBy === 'VIEWS_SEARCH')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="VIEWS_SEARCH" class="txt--center">{{ displayedMetricColumns?.searchViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'VIEWS_SEARCH')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.VIEWS_SEARCH  | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Number of Reviews -->
          <ng-container matColumnDef="numberOfNewReviews" [sticky]="(sort && sort.sortBy === 'reviewCount')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="reviewCount" class="txt--center">{{ displayedMetricColumns?.numberOfNewReviews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'reviewCount')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.reviewCount | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Total Views -->
          <ng-container matColumnDef="totalViews" [sticky]="(sort && sort.sortBy === 'TOTAL_VIEWS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="TOTAL_VIEWS" class="txt--center">{{ displayedMetricColumns?.totalViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'TOTAL_VIEWS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.TOTAL_VIEWS | number: '1.0-0'}}</p>
              </div>
            </td>
          </ng-container>


          <!-- Average Star Rating -->
          <ng-container matColumnDef="averageStarRating" [sticky]="(sort && sort.sortBy === 'starRatingAvg')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="starRatingAvg" class="txt--center">{{ displayedMetricColumns?.averageStarRating }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'starRatingAvg')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.starRatingAvg | number: '1.0-1'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Bookings -->
          <ng-container matColumnDef="bookings" [sticky]="(sort && sort.sortBy === 'BOOKINGS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="BOOKINGS" class="txt--center">{{ displayedMetricColumns?.bookings }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort?.sortBy === 'BOOKINGS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.BOOKINGS | number: '1.0-1'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Conversation -->
          <!-- <ng-container matColumnDef="messages" [sticky]="(sort && sort.sortBy === 'CONVERSATION')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="CONVERSATION" class="txt--center">{{ displayedMetricColumns?.messages }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'CONVERSATION')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.CONVERSATION | number: '1.0-1'}}</p>
              </div>
            </td>
          </ng-container> -->

          <!-- Food Menu Clicks -->
          <ng-container matColumnDef="menuViews" [sticky]="(sort && sort.sortBy === 'FOOD_MENU_CLICKS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="FOOD_MENU_CLICKS" class="txt--center">{{ displayedMetricColumns?.menuViews }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'FOOD_MENU_CLICKS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.FOOD_MENU_CLICKS | number: '1.0-1'}}</p>
              </div>
            </td>
          </ng-container>

          <!-- Food Orders -->
          <ng-container matColumnDef="foodOrders" [sticky]="(sort && sort.sortBy === 'FOOD_ORDERS')">
            <th mat-header-cell *matHeaderCellDef style="z-index: 5;" mat-sort-header="FOOD_ORDERS" class="txt--center">{{ displayedMetricColumns?.foodOrders }}</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'highlight':(sort && sort.sortBy === 'FOOD_ORDERS')}">
              <div class="cell__content justify-content-center">
                <p class="m--0 fw--medium">{{ row.FOOD_ORDERS | number: '1.0-1'}}</p>
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="no-hover"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="no-hover border--full"></tr>
        </table>
      </div>
      <app-paginator data-html2canvas-ignore [pagination]="pagination" (reload)="handleReload($event)" *ngIf="pagination"></app-paginator>
    </div>
  </div>
  <ng-template #emptyTable>
    <div class="box box--rounded box--shadow bg--white mb--30">
      <div class="box__header d-flex align-items-center justify-content-center">
        <h2 class="txt--capitalize"><strong>No multi location data available</strong></h2>
      </div>
    </div>
  </ng-template>
</div>
