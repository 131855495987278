/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-photos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "../dashboard-location/photos-location/photos-location.component.ngfactory";
import * as i9 from "../dashboard-location/photos-location/photos-location.component";
import * as i10 from "../../services/google.service";
import * as i11 from "../../services/storage-image.service";
import * as i12 from "../../services/snackbar.service";
import * as i13 from "@angular/router";
import * as i14 from "../../services/session.service";
import * as i15 from "../../services/location.service";
import * as i16 from "./modal-photos.component";
var styles_ModalPhotosComponent = [i0.styles];
var RenderType_ModalPhotosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalPhotosComponent, data: {} });
export { RenderType_ModalPhotosComponent as RenderType_ModalPhotosComponent };
function View_ModalPhotosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add photos"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalPhotosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "dialog__footer alternate-theme mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(4, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var currVal_4 = "primary"; _ck(_v, 3, 0, currVal_4); var currVal_5 = ""; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 4).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 4).type; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ModalPhotosComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { photoComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPhotosComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-photos-location", [], null, null, null, i8.View_PhotosLocationComponent_0, i8.RenderType_PhotosLocationComponent)), i1.ɵdid(6, 245760, [[1, 4], ["photoComponent", 4]], 0, i9.PhotosLocationComponent, [i2.MatDialog, i10.GoogleService, i11.StorageImageService, i12.SnackbarService, i13.ActivatedRoute, i14.SessionService, i15.LocationService], { accountId: [0, "accountId"], locationId: [1, "locationId"], locationType: [2, "locationType"], showOnlyMedia: [3, "showOnlyMedia"], bulk: [4, "bulk"], error: [5, "error"], error_details: [6, "error_details"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPhotosComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.bulk; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.accountId; var currVal_2 = _co.locationId; var currVal_3 = _co.locationType; var currVal_4 = _co.showOnlyMedia; var currVal_5 = _co.bulk; var currVal_6 = _co.error; var currVal_7 = _co.error_details; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = !_co.bulk; _ck(_v, 8, 0, currVal_8); }, null); }
export function View_ModalPhotosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-photos", [], null, null, null, View_ModalPhotosComponent_0, RenderType_ModalPhotosComponent)), i1.ɵdid(1, 114688, null, 0, i16.ModalPhotosComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalPhotosComponentNgFactory = i1.ɵccf("app-modal-photos", i16.ModalPhotosComponent, View_ModalPhotosComponent_Host_0, { primaryCategory: "primaryCategory", accountId: "accountId", locationId: "locationId", bulk: "bulk", locationType: "locationType", showOnlyMedia: "showOnlyMedia", accounts: "accounts" }, { validResult: "validResult" }, []);
export { ModalPhotosComponentNgFactory as ModalPhotosComponentNgFactory };
