import { __read, __spread } from "tslib";
// dep
import { ChangeDetectorRef, OnInit, ViewRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin } from 'rxjs';
// import { LocationService } from 'src/app/services/location.service';
import { QuestionsAnswerService } from 'src/app/services/questions-answer.service';
import { ReportService } from 'src/app/services/report.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SessionService } from "src/app/services/session.service";
var QuestionsAnswerComponent = /** @class */ (function () {
    function QuestionsAnswerComponent(questionAnswerS, route, cdRef, snackS, reportS, 
    // private locationS: LocationService
    _sessionS) {
        this.questionAnswerS = questionAnswerS;
        this.route = route;
        this.cdRef = cdRef;
        this.snackS = snackS;
        this.reportS = reportS;
        this._sessionS = _sessionS;
        this.locations = [];
        this.updatingData = false;
        this.loading = true;
        this.totalsData = {};
        this.questionsData = {};
        this.paginate = {
            page: 1,
            size: 6
        };
        this.isSliderOpened = false;
        this.startDate = null;
        this.endDate = null;
        this.locationsOptions = [];
        this.accountsFiltered = [];
        this.displayedColumnsMultLoc = ['location', 'total_questions_for_location', 'total_questions_with_answers', 'total_questions_without_answers'];
        this.fieldsColumnsMultiLoc = [
            { displayName: 'locationName', fieldSort: 'locationName' },
            { displayName: 'totalQuestions', fieldSort: 'totalQuestions' },
            { displayName: 'totalAnswerCount', fieldSort: 'totalAnswerCount' },
            { displayName: 'totalNotAnswerCount', fieldSort: 'totalNotAnswerCount' }
        ];
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        this.paginationMultiloc = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.paginateMultiloc = { size: 10, page: 1 };
        this.sortMultiloc = {
            sortBy: 'locationName',
            direction: 'asc'
        };
        this.keywordSearchText = '';
        this.tableLoading = true;
        this.isReport = false;
        this.periodType = null;
        this.isShared = false;
        this.dataPicker = { range: { start: null, end: null } };
        this.session$ = this._sessionS.session$;
    }
    QuestionsAnswerComponent.prototype.ngOnInit = function () {
        var _a, _b, _c, _d, _e, _f;
        this.accountId = this.route.parent.snapshot.params.accountId;
        this.locationId = this.route.parent.snapshot.params.locationId;
        this.location = [{ accountId: this.accountId, locationId: this.locationId }];
        this.questionType = ((_a = this.report) === null || _a === void 0 ? void 0 : _a.questionsType) ? this.report.questionsType : null;
        this.periodType = this.periodType ? this.periodType : 'allTime';
        this.accounts = this.report ? (_b = this.report) === null || _b === void 0 ? void 0 : _b.accounts : [];
        this.accountsFiltered = this.accounts ? __spread(this.accounts) : [];
        this.startDate = (_d = (_c = this.report) === null || _c === void 0 ? void 0 : _c.startDatetime) === null || _d === void 0 ? void 0 : _d.split(' ')[0];
        this.endDate = (_f = (_e = this.report) === null || _e === void 0 ? void 0 : _e.endDatetime) === null || _f === void 0 ? void 0 : _f.split(' ')[0];
        this.buildData();
        this.getMultilocationTable();
    };
    QuestionsAnswerComponent.prototype.buildStatus = function () {
        var _a, _b;
        (_b = (_a = this.questionsData) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.forEach(function (item) {
            var _a, _b, _c, _d;
            if (((_b = Object.keys(((_a = item) === null || _a === void 0 ? void 0 : _a.answers) || [])) === null || _b === void 0 ? void 0 : _b.length) == 0) {
                item.status = 'No Answer';
            }
            else {
                item.hasOwner = ((_d = (_c = item) === null || _c === void 0 ? void 0 : _c.answers) === null || _d === void 0 ? void 0 : _d.find(function (el) { return (el.author.type).toLowerCase() == 'merchant'; })) ? true : false;
                item.status = !item.hasOwner ? 'No Owner Answer' : 'Answered By Owner';
            }
        });
    };
    QuestionsAnswerComponent.prototype.buildData = function () {
        var _this = this;
        var _a, _b;
        if (!this.isReport) {
            this.getData();
        }
        else {
            if (((_b = (_a = this.report) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                this.accounts.forEach(function (acc) { return acc.locations.forEach(function (l) { return _this.locations.push(l); }); });
            }
            this.getDataReport();
        }
    };
    QuestionsAnswerComponent.prototype.getData = function () {
        var _this = this;
        var _a;
        if (!this.keywordSearchText) {
            var gid = this._sessionS.getSession().gid;
            forkJoin([
                this.questionAnswerS.getTotals(gid, this.accountId, this.locationId, this.questionType, this.startDate, this.endDate),
                this.questionAnswerS.getQuestions(gid, this.accountId, this.locationId, this.paginate, this.questionType, this.startDate, this.endDate)
            ]).subscribe(function (result) {
                _this.totalsData = result[0];
                _this.questionsData = result[1];
                _this.buildStatus();
                _this.loading = false;
                _this.updatingData = false;
                _this.detectChanges();
            }, function (err) {
                _this.loading = false;
                _this.updatingData = false;
            });
        }
        else {
            var questionType = {
                "withoutAnswer": "without_answers",
                "withAnswer": "with_answers"
            };
            forkJoin([
                this.questionAnswerS.getQuestionsAtlasSearch([{
                        gid: this._sessionS.getSession().gid,
                        accountId: this.accountId,
                        locationId: this.locationId
                    }], this.keywordSearchText, questionType[this.questionType] || 'all', this.startDate || undefined, this.endDate || undefined, (_a = this.paginate) === null || _a === void 0 ? void 0 : _a.size)
            ]).subscribe(function (result) {
                _this.questionsData = result[0];
                _this.loading = false;
                _this.updatingData = false;
                _this.detectChanges();
            }, function (err) {
                _this.loading = false;
                _this.updatingData = false;
            });
        }
    };
    QuestionsAnswerComponent.prototype.detectChanges = function () {
        if (this.cdRef !== null &&
            this.cdRef !== undefined &&
            !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    };
    QuestionsAnswerComponent.prototype.formattedDate = function (date) {
        var _a, _b, _c, _d;
        var split = (_a = date) === null || _a === void 0 ? void 0 : _a.split('-');
        split[1] = (_b = split[1]) === null || _b === void 0 ? void 0 : _b.padStart(2, '0');
        split[2] = (_c = split[2]) === null || _c === void 0 ? void 0 : _c.padStart(2, '0');
        return (_d = split) === null || _d === void 0 ? void 0 : _d.join('-');
    };
    QuestionsAnswerComponent.prototype.getMultilocationTable = function () {
        var _this = this;
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        this.tableLoading = true;
        var isAllTime = this.periodType == 'allTime';
        this.dataPicker.range = {
            start: isAllTime ? null : this.formattedDate(this.startDate),
            end: isAllTime ? null : this.formattedDate(this.endDate)
        };
        this.dataPicker.locations = [];
        this.accountsFiltered.forEach(function (a) {
            var _a;
            return (_a = _this.dataPicker.locations).push.apply(_a, __spread(a.locations));
        });
        this.reportS.getTableData(this._sessionS.getSession().gid, 'qanda', this.paginateMultiloc, this.sortMultiloc, this.dataPicker).subscribe(function (res) {
            var _a;
            _this.buildMultiLocTable((_a = res) === null || _a === void 0 ? void 0 : _a.data);
            _this.loading = false;
            _this.updatingData = false;
            _this.tableLoading = false;
        }, function (err) {
            _this.loading = false;
            _this.updatingData = false;
            _this.tableLoading = false;
        });
    };
    QuestionsAnswerComponent.prototype.getDataReport = function () {
        var _this = this;
        var _a;
        if (!this.keywordSearchText) {
            var isAllTime = this.periodType == 'allTime';
            this.dataPicker.range = {
                start: this.startDate,
                end: this.endDate
            };
            this.questionAnswerS.getReportData(this.reportId, this.paginate, this.startDate, this.endDate, this.accountsFiltered, this.questionType, isAllTime).subscribe(function (res) {
                _this.totalsData = res['metrics'];
                _this.questionsData = {
                    items: res['items'],
                    total: res['total']
                };
                _this.buildStatus();
                _this.getLocationInfo();
                _this.loading = false;
                _this.updatingData = false;
                _this.detectChanges();
            }, function (err) {
                _this.loading = false;
                _this.updatingData = false;
            });
        }
        else {
            var questionType = {
                "withoutAnswer": "without_answers",
                "withAnswer": "with_answers"
            };
            var accountsArray = this.getAccountsArray(this.accountsFiltered);
            this.questionAnswerS.getQuestionsAtlasSearch(accountsArray, this.keywordSearchText, questionType[this.questionType] || 'all', this.startDate || undefined, this.endDate || undefined, (_a = this.paginate) === null || _a === void 0 ? void 0 : _a.size).subscribe(function (res) {
                // this.totalsData = res[0]['metrics'];
                _this.questionsData = {
                    items: res[0]['items'],
                    total: res[0]['total']
                };
                _this.buildStatus();
                _this.getLocationInfo();
                _this.getMultilocationTable();
                _this.loading = false;
                _this.updatingData = false;
                _this.detectChanges();
            }, function (err) {
                _this.loading = false;
                _this.updatingData = false;
            });
        }
    };
    QuestionsAnswerComponent.prototype.getAccountsArray = function (accounts) {
        var result = [];
        accounts.forEach(function (obj) {
            var accountId = obj.accountId;
            var gid = obj.gid;
            obj.locations.forEach(function (location) {
                var locationId = location.locationId;
                result.push({ accountId: accountId, gid: gid, locationId: locationId });
            });
        });
        return result;
    };
    QuestionsAnswerComponent.prototype.buildMultiLocTable = function (data) {
        this.dataSourceMultiLoc = new MatTableDataSource(data.items);
        this.paginationMultiloc = data;
        this.paginationMultiloc = {
            items: data['items'],
            page: data['page'],
            pages: data['totalPages'],
            per_page: data['pageSize'],
            total: data['total'],
            hasNext: data['hasNext'],
            hasPrev: data['hasPrev']
        };
    };
    QuestionsAnswerComponent.prototype.getLocationInfo = function () {
        var _this = this;
        this.questionsData.items.forEach(function (loc) {
            var _a, _b, _c, _d, _e;
            var account = _this.accounts.find(function (acc) { return acc.accountId == loc.accountId; });
            var location = account.locations.find(function (l) { return l.locationId == loc.locationId; });
            if ((_a = location) === null || _a === void 0 ? void 0 : _a.locationName) {
                loc.locationName = location.locationName;
            }
            if ((_b = location) === null || _b === void 0 ? void 0 : _b.serviceArea) {
                loc.serviceArea = location.serviceArea;
            }
            if ((_e = (_d = (_c = location) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.addressLines) === null || _e === void 0 ? void 0 : _e.length) {
                loc.address = location.address;
            }
            else {
                delete location.address;
            }
        });
    };
    QuestionsAnswerComponent.prototype.loadMoreQuestions = function () {
        this.updatingData = true;
        this.paginate = {
            page: 1,
            size: this.paginate.size + 6
        };
        this.buildData();
    };
    QuestionsAnswerComponent.prototype.keywordSearch = function (event) {
        this.updatingData = true;
        this.keywordSearchText = event;
        this.getMultilocationTable();
        this.buildData();
    };
    QuestionsAnswerComponent.prototype.questionTypeChanged = function (event) {
        this.updatingData = true;
        this.questionType = event.value;
        this.buildData();
    };
    QuestionsAnswerComponent.prototype.questionPeriodChanged = function (event) {
        this.updatingData = true;
        this.periodType = event.value;
        var dates = this.questionAnswerS.buildPeriodDate(event.value);
        this.startDate = dates.startDatetime;
        this.endDate = dates.endDatetime;
        this.getMultilocationTable();
        this.buildData();
    };
    QuestionsAnswerComponent.prototype.locationsFilteredChanged = function (event) {
        this.updatingData = true;
        this.accountsFiltered = event.length > 0 ? event : this.accounts;
        this.getDataReport();
        this.getMultilocationTable();
    };
    QuestionsAnswerComponent.prototype.sendNewAnswer = function (item, answer, errorId) {
        var _this = this;
        if (answer === void 0) { answer = null; }
        if (errorId === void 0) { errorId = null; }
        var _a, _b, _c;
        answer = (!answer ?
            document.getElementById((_a = item) === null || _a === void 0 ? void 0 : _a.questionId) ? document.getElementById((_b = item) === null || _b === void 0 ? void 0 : _b.questionId)['value'] : '' :
            answer);
        var accountId = this.accountId || item.accountId;
        var locationId = this.locationId || item.locationId;
        var hasError = !!document.getElementById(errorId).textContent;
        answer = answer.trim();
        if (!answer || hasError) {
            return;
        }
        this.updatingData = true;
        var s = this._sessionS.getSession();
        this.questionAnswerS.addAnswer(s.gid, accountId, locationId, (_c = item) === null || _c === void 0 ? void 0 : _c.questionId, s.user.displayName, answer).subscribe(function (res) {
            _this.buildData();
            _this.snackS.openSuccess("The answer was added successfully", 4000);
        }, function (err) {
            _this.updatingData = false;
            _this.snackS.openError("There was an error while adding the answer", 4000);
        });
    };
    QuestionsAnswerComponent.prototype.openQuestions = function (slider, question) {
        // const backdrop = document.getElementsByClassName('mat-drawer-backdrop') || [];
        // backdrop[0].className += ' mat-drawer-shown'
        this.isSliderOpened = true;
        this.questionSlider = question;
        slider.open();
    };
    QuestionsAnswerComponent.prototype.sliderClosed = function () {
        this.isSliderOpened = false;
        this.buildData();
    };
    QuestionsAnswerComponent.prototype.handleMultilocSort = function (event) {
        var _a, _b;
        this.updatingData = true;
        this.sortMultiloc = { sortBy: (_a = event) === null || _a === void 0 ? void 0 : _a.active, direction: (_b = event) === null || _b === void 0 ? void 0 : _b.direction };
        this.paginateMultiloc = { size: this.paginateMultiloc.size, page: 1 };
        this.getMultilocationTable();
    };
    QuestionsAnswerComponent.prototype.handleMultilocPaginate = function (event) {
        this.updatingData = true;
        this.paginateMultiloc = event;
        this.getMultilocationTable();
    };
    QuestionsAnswerComponent.prototype.hasSeveralLocations = function () {
        return (this.accountsFiltered.length > 1 ? true :
            this.accountsFiltered[0].locations.length > 1 ? true : false);
    };
    QuestionsAnswerComponent.prototype.deleteQuestions = function (question) {
        var _this = this;
        var _a, _b;
        this.updatingData = true;
        var accountId = this.accountId || question.accountId;
        var locationId = this.locationId || question.locationId;
        var answer = this.getOwnerAnswer(question);
        this.questionAnswerS.deleteAnswer(this._sessionS.getSession().gid, accountId, locationId, (_a = question) === null || _a === void 0 ? void 0 : _a.questionId, (_b = answer) === null || _b === void 0 ? void 0 : _b.name).subscribe(function (res) {
            _this.buildData();
            _this.snackS.openSuccess("The request has been sent to google please check again in some minutes", 4000);
        }, function (err) {
            _this.updatingData = false;
            _this.snackS.openError("There was an error while deleting the answer", 4000);
        });
    };
    QuestionsAnswerComponent.prototype.getOwnerAnswer = function (item) {
        var _a, _b, _c, _d;
        return ((_a = item) === null || _a === void 0 ? void 0 : _a.answers) && ((_b = item) === null || _b === void 0 ? void 0 : _b.hasOwner) ? (_d = (_c = item) === null || _c === void 0 ? void 0 : _c.answers) === null || _d === void 0 ? void 0 : _d.find(function (el) { return (el.author.type).toLowerCase() == 'merchant'; }) : {};
    };
    QuestionsAnswerComponent.prototype.getFullDate = function (date) {
        return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
    };
    QuestionsAnswerComponent.prototype.getErrorId = function (id) {
        return "errorContainer-" + id;
    };
    return QuestionsAnswerComponent;
}());
export { QuestionsAnswerComponent };
