// dep
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';

// app
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-text-reader',
  templateUrl: './text-reader.component.html',
  styleUrls:  ['./text-reader.component.scss']
})
export class TextReaderComponent implements OnInit {
  editor: string;
  title: string;

  constructor(
    private _route: ActivatedRoute,
    private _sessionS: SessionService
  ) {}

  ngOnInit() : void {
    const domain = this._sessionS.getDomain();
    const { text } = this._route.snapshot.params;
    const { content } = domain
    let value = '';
    switch (text) {
      case 'terms-and-conditions':
        value = content?.terms_service_text || ''
        this.title = 'Terms of Service';
        break;
      case 'privacy-policy':
        value = content?.privacy_policy_text || ''
        this.title = 'Privacy Policy';
        break;
      case 'payment-policy':
        value = content?.payment_policy_text || ''
        this.title = 'Payment Policy';
        break;
      default:
        break;
    }
    this.editor = value;
  }

}
