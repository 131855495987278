import { __read, __spread } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { GoogleService } from '../../services/google.service';
import { LocationEditService } from '../../services/location-edit.service';
import * as _ from 'lodash';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEX_COMPLEX_URL } from '../../constants/regex';
var ModalAttributesComponent = /** @class */ (function () {
    function ModalAttributesComponent(_dialogRef, data, _googleS, _locationEditS) {
        var _a, _b;
        this._dialogRef = _dialogRef;
        this.data = data;
        this._googleS = _googleS;
        this._locationEditS = _locationEditS;
        this.groups = [];
        this.excludedAtributes = [];
        this.bulk = false;
        this.validResult = new EventEmitter(false);
        this.isSocialMedia = false;
        this.socialMediaAttributes = [];
        this.allSocialMediaOptions = [];
        if (data) {
            this.isSocialMedia = (_a = data) === null || _a === void 0 ? void 0 : _a.isSocialMedia;
            this.socialMediaAttributes = (_b = data) === null || _b === void 0 ? void 0 : _b.socialMedia;
            if (this.data.groupAttributes) {
                this.groups = this.originalGroups = JSON.parse(JSON.stringify(this.data.groupAttributes));
            }
        }
    }
    ModalAttributesComponent.prototype.ngOnInit = function () {
        this.allSocialMediaOptions = this._locationEditS.allSocialMediaOptions;
        this.initForm();
    };
    ModalAttributesComponent.prototype.initForm = function () {
        var _this = this;
        var _a;
        this.socialMediaData = new FormArray([]);
        (_a = this.socialMediaAttributes) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            _this.addSocialMedia(el.attributeId, el.urlValues[0].url);
        });
    };
    ModalAttributesComponent.prototype.addSocialMedia = function (socialMedia, url) {
        if (socialMedia === void 0) { socialMedia = null; }
        if (url === void 0) { url = null; }
        this.socialMediaData.push(new FormGroup({
            socialMedia: new FormControl(socialMedia, [Validators.required]),
            url: new FormControl(url, [Validators.required, Validators.pattern(REGEX_COMPLEX_URL)])
        }));
    };
    ModalAttributesComponent.prototype.removeSocialMedia = function (index) {
        this.socialMediaData.removeAt(index);
    };
    ModalAttributesComponent.prototype.getLabel = function (label) {
        var _a;
        return (_a = label) === null || _a === void 0 ? void 0 : _a.split('_')[1];
    };
    ModalAttributesComponent.prototype.getAvailableOptions = function (control) {
        var selectedValues = this.socialMediaData.controls
            .filter(function (c) { return c !== control; })
            .map(function (c) { var _a; return (_a = c.get('socialMedia')) === null || _a === void 0 ? void 0 : _a.value; });
        return this.allSocialMediaOptions.filter(function (option) { return !selectedValues.includes(option.value); });
    };
    ModalAttributesComponent.prototype.getOption = function (control) {
        var _a;
        var selectedValue = (_a = control.get('socialMedia')) === null || _a === void 0 ? void 0 : _a.value;
        return this.allSocialMediaOptions.find(function (opt) { return opt.value === selectedValue; });
    };
    /**
     * this method change true of false attributes with bool type
     */
    ModalAttributesComponent.prototype.changeStatus = function (groupIndex, itemIndex) {
        var originalGroupIndex = groupIndex;
        var originalItemIndex = itemIndex;
        if (this.isFiltered) {
            var _a = this.findIndexes(this.groups[groupIndex].groupName, this.groups[groupIndex].items[itemIndex].attributeId), indexGroup = _a.indexGroup, indexItem = _a.indexItem, indexMeta = _a.indexMeta;
            originalGroupIndex = indexGroup;
            originalItemIndex = indexItem;
        }
        var value = this.groups[groupIndex].items[itemIndex].active;
        var type = this.groups[groupIndex].items[itemIndex].valueType;
        var values = this.groups[groupIndex].items[itemIndex].valueMetadata;
        var newValue = null;
        if (type === 'REPEATED_ENUM') {
            newValue = !_.isEmpty(_.reject(values.map(function (v) { return v.active; }), function (o) { return _.isNil(o); }));
        }
        else {
            switch (value) {
                case true:
                    newValue = false;
                    break;
                case false:
                    newValue = null;
                    break;
                case null:
                    newValue = true;
                    break;
            }
        }
        this.groups[groupIndex].items[itemIndex].active = newValue;
        this.originalGroups[originalGroupIndex].items[originalItemIndex].active = newValue;
    };
    ModalAttributesComponent.prototype.apply = function () {
        var _this = this;
        if (this.isSocialMedia && !this.socialMediaData.valid) {
            this.socialMediaData.markAllAsTouched();
            return;
        }
        var attributes = this._googleS.attributesToGMB(this.originalGroups);
        this._locationEditS.locationEdit.attributes = __spread(attributes, this.excludedAtributes);
        this.builSocialMediaData();
        this._locationEditS.setAndUpdate().toPromise().then(function () {
            _this._dialogRef.close({ attributes: attributes });
        });
    };
    ModalAttributesComponent.prototype.builSocialMediaData = function () {
        var _this = this;
        var _a, _b;
        var data = [];
        (_b = (_a = this.socialMediaData) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.forEach(function (el) {
            var _a, _b;
            var url = (_b = (_a = el) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b.replace(/ /g, '%20');
            _this._locationEditS.locationEdit.attributes.push({
                attributeId: el.socialMedia,
                name: "attributes/" + el.socialMedia,
                urlValues: [
                    { url: url }
                ],
                valueType: "URL"
            });
        });
    };
    ModalAttributesComponent.prototype.getResult = function () {
        if (this.isSocialMedia && !this.socialMediaData.valid) {
            this.socialMediaData.markAllAsTouched();
            return;
        }
        var attributes = this._googleS.attributesToGMB(this.originalGroups);
        this.validResult.emit(true);
        // FIXME: Solve typing
        // @ts-ignore
        return { attributes: __spread(attributes, this.excludedAtributes) };
    };
    ModalAttributesComponent.prototype.handleShowAddUrl = function (itemIndex, url) {
        if (url === void 0) { url = undefined; }
        if (url) {
            this.newUrl = url[0].url;
        }
        if (this.addUrlIndex === itemIndex) {
            this.addUrlIndex = null;
            return;
        }
        this.addUrlIndex = itemIndex;
    };
    ModalAttributesComponent.prototype.addUrl = function (groupIndex, itemIndex) {
        if (!this.newUrl) {
            this.isUrlInvalid = true;
            return;
        }
        this.groups[groupIndex].items[itemIndex].urlValues = [{ url: this.newUrl }];
        this.originalGroups[groupIndex].items[itemIndex].urlValues = [{ url: this.newUrl }];
        this.newUrl = null;
        this.addUrlIndex = null;
    };
    ModalAttributesComponent.prototype.changeStatusEnum = function (groupIndex, itemIndex, metaIndex) {
        var originalGroupIndex = groupIndex;
        var originalItemIndex = itemIndex;
        var originalMetaIndex = metaIndex;
        var g = this.groups[groupIndex];
        if (this.isFiltered) {
            var _a = this.findIndexes(g.groupName, g.items[itemIndex].attributeId, g.items[itemIndex].valueMetadata[metaIndex].value), indexGroup = _a.indexGroup, indexItem = _a.indexItem, indexMeta = _a.indexMeta;
            originalGroupIndex = indexGroup;
            originalItemIndex = indexItem;
            originalMetaIndex = indexMeta;
        }
        var value = g.items[itemIndex].valueMetadata[metaIndex].active;
        var newValue = null;
        switch (value) {
            case true:
                newValue = false;
                break;
            case false:
                newValue = null;
                break;
            case undefined:
                newValue = true;
                break;
            case null:
                newValue = true;
                break;
        }
        g.items[itemIndex].valueMetadata[metaIndex].active = newValue;
        this.originalGroups[originalGroupIndex].items[originalItemIndex].valueMetadata[originalMetaIndex].active = newValue;
    };
    // apply filter from search
    ModalAttributesComponent.prototype.applyFilter = function ($event) {
        if (!$event || $event === '') {
            this.groups = this.originalGroups;
            this.isFiltered = false;
            return;
        }
        var result = [];
        this.originalGroups.forEach(function (group) {
            var searchGroupName = group.groupName.toLowerCase().includes($event.toLowerCase());
            var searchItemsName = group.items.filter(function (item) { return item.displayName.toLowerCase().includes($event.toLowerCase()); });
            searchGroupName && result.push({ groupName: group.groupName, items: searchItemsName, active: group.active });
            !searchGroupName && !_.isEmpty(searchItemsName) && result.push({ groupName: group.groupName, items: searchItemsName, active: group.active });
        });
        this.groups = result;
        this.isFiltered = true;
    };
    ModalAttributesComponent.prototype.findIndexes = function (group, attr, meta) {
        var indexGroup = _.findIndex(this.originalGroups, { 'groupName': group });
        var indexItem = _.findIndex(this.originalGroups[indexGroup].items, { 'attributeId': attr });
        var indexMeta = meta ? _.findIndex(this.originalGroups[indexGroup].items[indexItem].valueMetadata, { 'value': meta }) : null;
        return { indexGroup: indexGroup, indexItem: indexItem, indexMeta: indexMeta };
    };
    ModalAttributesComponent.prototype.hasError = function (index, field, error) {
        var _a, _b, _c;
        var control = (_a = this.socialMediaData) === null || _a === void 0 ? void 0 : _a.at(index);
        return ((_c = (_b = control) === null || _b === void 0 ? void 0 : _b.get(field)) === null || _c === void 0 ? void 0 : _c.hasError(error)) && control.touched;
    };
    ModalAttributesComponent.prototype.isBoolean = function (group) {
        var _a, _b, _c, _d, _e;
        var url = (_b = (_a = group) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.filter(function (item) { var _a; return ((_a = item) === null || _a === void 0 ? void 0 : _a.valueType) === 'URL'; });
        return ((_d = (_c = group) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.length) === ((_e = url) === null || _e === void 0 ? void 0 : _e.length);
    };
    ModalAttributesComponent.prototype.isNotLastVisibleGroup = function (groupIndex) {
        var visibleGroups = this.groups.filter(function (group) { var _a, _b; return (_b = (_a = group) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.some(function (item) { var _a; return ((_a = item) === null || _a === void 0 ? void 0 : _a.valueType) !== 'URL'; }); });
        return visibleGroups.indexOf(this.groups[groupIndex]) !== visibleGroups.length - 1;
    };
    return ModalAttributesComponent;
}());
export { ModalAttributesComponent };
