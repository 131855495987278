// dep
import {Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import moment from 'moment';

// app
import {ObservationsDTO} from '../../constants/firestore/observation';
import {LocationService} from '../../services/location.service';
import {ObservationService} from '../../services/observation.service';
import {SnackbarService} from '../../services/snackbar.service';
import {InsightsService} from '../../services/insights.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-dashboard-workshop',
  templateUrl: './dashboard-workshop.component.html',
  styleUrls: ['./dashboard-workshop.component.scss']
})
export class DashboardWorkshopComponent {

  private readonly locationId : string
  observations: ObservationsDTO;
  refreshing = false

  config: any;
  index: number;

  topMetrics;
  isProgressObservation = true;
  isProgressTopMetrics = true;
  accountId: string;

  constructor(
    private route: ActivatedRoute,
    private locationService: LocationService,
    private observationService: ObservationService,
    private snack: SnackbarService,
    private insightsService: InsightsService,
    private _sessionS: SessionService
  ) {
    this.locationId = this.route.parent.snapshot.params.locationId;
    this.accountId  = this.route.parent.snapshot.params.accountId;

    const {gid} = this._sessionS.getSession();

    this.locationService.getObservations(gid, this.accountId, this.locationId).then(observations => {
      this.observations = observations;
      this.isProgressObservation = false;
    }, error => {
      this.isProgressObservation = false;
    });

    this.insightsService.getTopMetrics(this.locationId).subscribe(topMetrics => {
      this.topMetrics = topMetrics;
      this.isProgressTopMetrics = false;
    }, error => {
      this.isProgressTopMetrics = false;
    });

    this.config = {
      loop: true,
      initialSlide: 0,
      slidesPerView: 4,
      navigation: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 30, // Space between each Item,
      grabCursor: true
    };
    this.index = 0;
  }

  async handleRefresh() : Promise<void> {
    const now = moment()
    const prev_year = now.clone().subtract(1, 'year')

    const endDate   = `${now['year']()}-${now['month']() +1}-${now['date']()}`;
    const startDate = `${prev_year['year']()}-${prev_year['month']() +1}-${prev_year['date']()}`

    try {
      this.refreshing = true;
      await this.observationService.save(this.accountId, this.locationId).toPromise()
      this.snack.openSuccess('No new observations or top metrics')
      this.refreshing = false;
    } catch (err) {
      console.error(err);
      this.refreshing = false;
      if (err.status !== 0)
        this.snack.openError(err.error.message)
    }

    try {
      await this.insightsService.saveLocationStats(this._sessionS.getSession().gid, this.accountId, this.locationId, startDate, endDate)
    } catch(err) {
      console.error(err);
      this.snack.openError(err.message)
    }

  }


}
