import { __awaiter, __generator } from "tslib";
// app
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Messages, string_message } from '../../constants/messages';
// import {VerificationEmailService} from "../../services/verification-email.service";
import { SessionTraceService } from 'src/app/services/session-trace.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { NOTIFICATION_GENERAL, TYPE_LOG_USER } from 'src/app/constants/notifications';
import { NotificationService } from 'src/app/services/notification.service';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(_auth, _subscriptionS, _sessionTrace, _sessionS, _snack, _notificationS, navigationS) {
        this._auth = _auth;
        this._subscriptionS = _subscriptionS;
        this._sessionTrace = _sessionTrace;
        this._sessionS = _sessionS;
        this._snack = _snack;
        this._notificationS = _notificationS;
        this.navigationS = navigationS;
        this.loading = false; // TODO: Not needed after adding SessionService, remove
        this.registerProgress = false;
        this.domain$ = this._sessionS.domain$;
    }
    RegisterComponent.prototype.handleRegisterWithGoogle = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var err_msg_1, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // TODO: All of this should be done atomically and server-side
                        event.preventDefault();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 10, 11]);
                        this.registerProgress = true;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this._auth.registerWithGoogleAndSignIn()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_msg_1 = _a.sent();
                        this._snack.openError(err_msg_1);
                        return [2 /*return*/];
                    case 5: 
                    // Note createSubscription needs the AuthSession to be already created
                    // so the HTTP interceptor can send the uid/gid parameters in headers
                    return [4 /*yield*/, this._subscriptionS.createSubscription()];
                    case 6:
                        // Note createSubscription needs the AuthSession to be already created
                        // so the HTTP interceptor can send the uid/gid parameters in headers
                        _a.sent();
                        return [4 /*yield*/, this._auth.initSession()];
                    case 7:
                        _a.sent();
                        this._sessionTrace.onRegistration();
                        return [4 /*yield*/, this._sessionS.waitSession()];
                    case 8:
                        user = (_a.sent()).user;
                        try {
                            this._notificationS.saveNotification(string_message(Messages.notifications.USER_ADDED, [user.displayName, user.role]), NOTIFICATION_GENERAL, TYPE_LOG_USER, this._notificationS.getMetaTypeLog(TYPE_LOG_USER, user));
                        }
                        catch (e) {
                            console.error("Error sending notification", e);
                        }
                        this._snack.openSuccess(Messages.register.SUCCESS);
                        return [4 /*yield*/, this._auth.redirectAfterLogin()];
                    case 9:
                        _a.sent();
                        return [3 /*break*/, 11];
                    case 10:
                        this.registerProgress = false;
                        return [7 /*endfinally*/];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
