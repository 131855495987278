import { __assign, __awaiter, __generator } from "tslib";
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import * as uuid from 'uuid';
// app
import { LocationEditService } from '../../services/location-edit.service';
var ModalMenuItemComponent = /** @class */ (function () {
    function ModalMenuItemComponent(dialogRef, data, locationEditS, fb) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationEditS = locationEditS;
        this.fb = fb;
        this.item = __assign({}, this.data.item);
        this.sectionIndex = this.data.sectionIndex;
        this.priceIndex = this.data.priceIndex;
        if (this.data.item) {
            this.isEdit = true;
            this.itemIndex = this.data.itemIndex;
        }
        this.initForm();
    }
    ModalMenuItemComponent.prototype.apply = function () {
        var units = '0';
        var nanos = 0;
        var amount = this.form.value.units.split('.');
        if (amount.length > 0) {
            nanos = Number(amount[amount.length - 1]);
            if (nanos > 99) {
                nanos = 0;
                units = amount.join('').replace(/\,/gi, '');
            }
            else {
                amount.splice(amount.length - 1, 1);
                units = amount.join('').replace(/\,/gi, '');
            }
        }
        var item = {
            price: { units: units, currencyCode: 'USD', nanos: nanos },
            labels: [{ displayName: this.name.value, description: this.description.value }],
            itemId: uuid.v4()
        };
        var items;
        items = this.locationEditS.locationEdit.priceLists[this.priceIndex].sections[this.sectionIndex].items;
        if (this.isEdit) {
            items[this.itemIndex] = item;
            this.update();
            return;
        }
        if (!items) {
            items = [];
        }
        items.push(item);
        this.update();
    };
    ModalMenuItemComponent.prototype.remove = function () {
        this.locationEditS.locationEdit.priceLists[this.priceIndex].sections[this.sectionIndex].items.splice(this.itemIndex);
        this.update();
    };
    ModalMenuItemComponent.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.locationEditS.setAndUpdate().toPromise()];
                    case 1:
                        _a.sent();
                        this.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalMenuItemComponent.prototype.initForm = function () {
        var _a, _b, _c, _d, _e;
        if (this.isEdit) {
            this.form = this.fb.group({
                name: [this.item.labels[0].displayName, Validators.required],
                units: [(_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.units, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]],
                description: [(_c = this.item) === null || _c === void 0 ? void 0 : _c.labels[0].description],
                currency: [(_e = (_d = this.item) === null || _d === void 0 ? void 0 : _d.price) === null || _e === void 0 ? void 0 : _e.currencyCode, Validators.required],
            });
        }
        else {
            this.form = this.fb.group({
                name: ['', Validators.required],
                units: [0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]],
                description: [''],
                currency: ['USD'],
            });
        }
    };
    Object.defineProperty(ModalMenuItemComponent.prototype, "name", {
        get: function () {
            return this.form.get('name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalMenuItemComponent.prototype, "units", {
        get: function () {
            return this.form.get('units');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalMenuItemComponent.prototype, "description", {
        get: function () {
            return this.form.get('description');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalMenuItemComponent.prototype, "currency", {
        get: function () {
            return this.form.get('currency');
        },
        enumerable: true,
        configurable: true
    });
    return ModalMenuItemComponent;
}());
export { ModalMenuItemComponent };
