// dep
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

// app
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { GoogleService } from '../../services/google.service';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import * as constants_locations from '../../constants/firestore/account-location';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { SubscriptionService } from '../../services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { BaseComponent } from 'src/app/components/base.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls:  ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit {
  // navigationSubscription;
  locationId: string;
  accountId: string;
  location: Observable<SavedLocation> = of({} as any);
  private placeId: string
  // public locationTitle;
  public isRevealPath = false;
  public user;
  public currentLocation : SavedLocation;


  c_auth_ok           = constants_locations.VERIFICATION_OK;
  c_auth_unauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
  c_auth_not_found    = constants_locations.VERIFICATION_NOT_FOUND;

  domain$  = this._sessionS.domain$;
  session$ = this._sessionS.session$;
  
  constructor(
    private _sessionS : SessionService,
    private route: ActivatedRoute,
    private _locationS: LocationService,
    private _modalService: ModalService,
    private googleService: GoogleService,
    private _dataTransfer: DataTransferService<string>,
    private spinnerService: SpinnerService,
    private router: Router,
    private apiSubscription: SubscriptionService,
  ) {
    super();
  }

  ngOnInit() : void {
    this._loadLocation();

    /*this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadLocation();
      }
    });

    this.locationTitle = this.location.subscribe(location => {
      const title = location ? `${location.locationName} @ ${location.location.address.addressLines[0]}`: '';
      this.wl.setTitle(title);
    });*/
  }

  getStatus(status) {
    if(!status) { return; }
    return status.replace(/\_/g, " ")
  }

  private _loadLocation() : void {
    this.locationId = this.route.snapshot.params?.locationId;
    this.accountId  = this.route.snapshot.params?.accountId;

    const {gid} = this._sessionS.getSession()
    this.location = this._locationS.getRef(gid, this.accountId, this.locationId);
    
    this._subscribeSafe(this.location, location => {
      this.currentLocation = this.formData(location);
      this._dataTransfer.setData(location.locationName);
      this.placeId = location ? location.location.locationKey.placeId : null;
    });
  }

  formData(location : SavedLocation) : SavedLocation {
    if (location) {
      const {isTrial} = this._sessionS.getSession();
      const st = location.subscriptionType;
      location.subscriptionStatus = (st === LOCATION_SUBSCRIPTION_TYPE.FREE ?
                                     (isTrial ? LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL : 
                                                LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) :
                                     st);
    }

    return location
  }

  // ngOnDestroy() : void {
  //   // if (this.navigationSubscription) {
  //   //   this.navigationSubscription.unsubscribe();
  //   // }
  // }


  async handleReviewLinkGenerator() : Promise<void> {
    if (!this.placeId) {
      return;
    }

    const url = await this.googleService.dynamicUrl(this.placeId).toPromise()
    this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url);
  }

  public async openDeleteDialog(): Promise<void> {
    const loc = this.currentLocation
    if(!loc)
      return;

    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect ${loc.locationName} from ${domainCompany}?`, 
      `Note: this only disconnects the profile from ${domainCompany}; it does not delete your business profile `+
      `from Google Maps. You can always re-add your business profile to ${domainCompany}.`))
      return;

    try {
      this.spinnerService.loading$.next(true);

      const {gid} = this._sessionS.getSession();
      await this._locationS.deleteLocation(gid, this.accountId, loc);
      await this.router.navigate(['/accounts']); 
    } catch(err) {
      console.error('Error deleting location', err);
    } finally {
      this.spinnerService.loading$.next(false);
    }
  }

  async changePlan() : Promise<void> {
    if (this._sessionS.getSession().isMember) {
      await this._modalService.openWarningModalNoPermissions();
      return;
    }

    await this.apiSubscription.flowChangeLocationsPlan([{ locationId: this.currentLocation.locationId, 
                                                           accountId: this.accountId }]);
  }
}
