import { __awaiter, __generator, __values } from "tslib";
// TODO: Refactor against admin-frontend/auth.service.ts
/*
AuthService

- Manages authentication thru the firebase-auth library (wrapped by AngularFireAuth)
- Session persistence:
 - firebase-auth has his own mechanism of persisting credentials across page
   reloads (the exact mechanism used is unspecified, it can be local storage, indexedDB,
   etc). Those credentials are implicitely loaded on library initialization.
 - AuthService also needs to persist additional session info, as the sessionType,
   gid used for anonymous logins, etc.

- firebase-auth caveats:
 - Using auth.currentUser is not safe on initialization, as it can be null while
   the library is still initializing from saved credentials. See:
  - https://github.com/firebase/firebase-js-sdk/issues/462
  - https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-619957604
  - https://medium.com/firebase-developers/why-is-my-currentuser-null-in-firebase-auth-4701791f74f0
  - https://stackoverflow.com/questions/39231344/how-to-wait-for-firebaseauth-to-finish-initializing
  - https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-735919541
  - This is solved by not relying on currentUser and using onAuthStateChanged instead.
  - Newer library versions have an authStateReady() method, but his implementation
    seems to be similar as using the onAuthStateChanged callback.

- TODO:
 - Rename to AuthNService to use 'AuthN/AuthZ' unambigous nomenclature.
 */
// dep
import { NgZone, isDevMode } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
// app
import { UserService } from './user.service';
import { Messages } from '../constants/messages';
import { STORAGE_ALL_KEYS, STORAGE_EXTERNAL_GRADE_ID, STORAGE_IS_IMPERSONATING, STORAGE_MAPLABS_SESSION, STORAGE_SESSION, STORAGE_SESSION_EXTERNAL } from '../constants/session';
import { VerificationEmailService } from '../services/verification-email.service';
import { PaymentsService } from "./payments.service";
import { ModalService } from "./modal.service";
import { environment as ENV } from '@environment';
import { AuthProxyService } from './auth.proxy.service';
import { HEADERS_NO_AUTH, MAIL_EXTERNAL_GRADE, MAIL_ANONYMOUS } from '../constants/auth';
import { BROWSER_DOMAIN, isRunningEmbedded, makeOpenPromise } from '../helpers/utils.helpers';
import { SessionTraceService } from './session-trace.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
import * as i4 from "./session.service";
import * as i5 from "./user.service";
import * as i6 from "./verification-email.service";
import * as i7 from "./auth.proxy.service";
import * as i8 from "./payments.service";
import * as i9 from "./modal.service";
import * as i10 from "./session-trace.service";
var AuthService = /** @class */ (function () {
    function AuthService(
    // dep
    _afAuth, _router, _http, _ngZone, 
    // app
    _sessionS, _userService, _verificationService, _authProxyService, _paymentsService, _modalService, _sessionTraceService) {
        var _this = this;
        this._afAuth = _afAuth;
        this._router = _router;
        this._http = _http;
        this._ngZone = _ngZone;
        this._sessionS = _sessionS;
        this._userService = _userService;
        this._verificationService = _verificationService;
        this._authProxyService = _authProxyService;
        this._paymentsService = _paymentsService;
        this._modalService = _modalService;
        this._sessionTraceService = _sessionTraceService;
        /**
         * The JWT token generated by firebase-auth on sucessfull authentication,
         * will be sent to the backend on every request on the 'Authorization'
         * HTTP header.
         */
        this._accessToken = null;
        /**
         * Application specific session info that should be persisted across
         * page reloads.
         */
        this._authSession = null;
        this._isFbInitialized = false;
        this._isFbInitializedP = makeOpenPromise();
        this._signOutCalled = false;
        /// 1- Migrate old localStorage persistent session data to the new format
        // TODO: Remove after quarantine period
        this._authSessionMigrateV0ToV1();
        /// 2- Subscribe to Firestore Auth state changes
        // "
        // onAuthStateChanged():
        //   Prior to 4.0.0, this triggered the observer when users were signed in, signed out, 
        //   or when the user's ID token changed in situations such as token expiry or password change. 
        //   After 4.0.0, the observer is only triggered on sign-in or sign-out. 
        // " 
        // When firebase-auth initializes, executes this callback with the following values:
        //  - null if there weren't any persistent session saved (or if it fails to load?)
        //  - not-null if a persistent session was loaded
        //  - it seems is also ensured that there will be no race condition on when we setup
        //    this callback, as the observer will be called at least once (ReplaySubject like behaviour),
        //    see https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-619957604
        // So is possible to use this callback to await for lib initialization, as the first
        // value received is always the resultant from the persistent session loading process
        // at initialization. After that, a null value will mean an user logout.
        //
        // this.afAuth.authState.pipe(
        this._afAuth.auth.onAuthStateChanged(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var url;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = window.location.pathname;
                        if (!(((_a = user) === null || _a === void 0 ? void 0 : _a.email) === MAIL_ANONYMOUS && !(url.startsWith('/widget') ||
                            url.startsWith('/report') ||
                            url.startsWith('/reports')))) return [3 /*break*/, 2];
                        console.error('Unauthorized anonymous login to ' + url);
                        return [4 /*yield*/, this.signOut()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                    case 2:
                        if (isRunningEmbedded() && !this._isFbInitialized) {
                            // Don't continue the auth steps if the app is running inside
                            // an iframe (only review widget)
                            this._isFbInitialized = true;
                            this._isFbInitializedP.reject("Running embedded, Auth will never be initialized");
                            return [2 /*return*/];
                        }
                        if (!!this._isFbInitialized) return [3 /*break*/, 6];
                        if (!!user) return [3 /*break*/, 3];
                        // No fb persistent session found
                        this._authSessionDelete(); // just in case           
                        return [3 /*break*/, 5];
                    case 3: 
                    // initSession will try to load the app specific persistent session and will
                    // fail if not present. 
                    return [4 /*yield*/, this.initSession()];
                    case 4:
                        // initSession will try to load the app specific persistent session and will
                        // fail if not present. 
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        this._isFbInitialized = true;
                        this._isFbInitializedP.resolve();
                        return [3 /*break*/, 9];
                    case 6:
                        if (!!user) return [3 /*break*/, 8];
                        // logout detected
                        return [4 /*yield*/, this.signOut()];
                    case 7:
                        // logout detected
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        if (this._authSession) {
                            console.error('User changed after login? should not happen', user);
                        }
                        else {
                            // ignore
                        }
                        _b.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        }); });
        /// 3- Subscribe to Firestore Auth token changes (token refreshes)
        // "
        // onIdTokenChanged():
        //  Adds an observer for changes to the signed-in user's ID token, which includes sign-in, 
        //  sign-out, and token refresh events. This method has the same behavior as 
        //  firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
        // "
        // Also check:
        //  - https://github.com/angular/angularfire/issues/2694#issuecomment-734052171
        //
        this._afAuth.auth.onIdTokenChanged(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        // Update the accessToken for events not triggered by forceAuthRefresh
                        // (e.g., when firebase-sdk refreshes it automatically in one of his non
                        // angular intercepted queries)
                        _a = this._setAccessToken;
                        if (!user) return [3 /*break*/, 2];
                        return [4 /*yield*/, user.getIdToken()];
                    case 1:
                        _b = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _b = null;
                        _c.label = 3;
                    case 3:
                        // Update the accessToken for events not triggered by forceAuthRefresh
                        // (e.g., when firebase-sdk refreshes it automatically in one of his non
                        // angular intercepted queries)
                        _a.apply(this, [_b, 'onIdTokenChanged']);
                        return [2 /*return*/];
                }
            });
        }); });
        /// 4- Subscribe to storage events from other tabs (logouts)
        window.addEventListener('storage', function (ev) { return _this._onStorageEventFromOtherTab(ev); });
        // Now firestore-auth should trigger the next init steps by executing the
        // onAuthStateChanged callback
    }
    AuthService.prototype.hasAuthSession = function () {
        return this._isFbInitialized;
    };
    /**
     * Returns the current AuthSession if any
     * - Even if this is present, maybe the SessionService session was not
     *   initialized yet. But waiting this is ensured to finish after
     *   service initialization, even with a null result, while waiting
     *   for the SessionService session only finishes with a valid session
     *   (so maybe never).
     */
    AuthService.prototype.waitAuthSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._isFbInitializedP];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this._authSession];
                }
            });
        });
    };
    Object.defineProperty(AuthService.prototype, "headers", {
        get: function () {
            // TODO: Horrible, use only token, don't rely on http-headers for 
            // authentication
            var s = this._authSession;
            if (!s) {
                // should not happen
                var m = 'Authentication headers requested before authSession initialization';
                console.error(m);
                throw Error(m);
            }
            return { headers: new HttpHeaders({ gid: s.gid,
                    uid: s.uid,
                    'Authorization': 'Bearer ' + this._accessToken,
                    // Note ENV.apiUrl is changed after domain is fetched
                    // TODO: Really needed? X-Domain will be added by HTTP interceptor
                    'domain': ENV.apiUrl
                }) };
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.initSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userFb, authSession, session, user, group, features, userCreatedAt, d;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug("initSession");
                        userFb = this._afAuth.auth.currentUser;
                        if (!!userFb) return [3 /*break*/, 2];
                        // Must never happen
                        console.error('initSession: Firebase not authenticated but initSession called');
                        return [4 /*yield*/, this.signOut()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/]; // never reached 
                    case 2:
                        authSession = this._authSessionLoad();
                        if (!!authSession) return [3 /*break*/, 4];
                        // No authSession saved on storage and initSession not called after signIn,
                        // Cannot initialize session
                        // Must never happen, as after a signIn the session always saved before calling initSession
                        console.error('initSession: Firebase is authenticated but no authSession available', userFb);
                        return [4 /*yield*/, this.signOut()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/]; // never reached
                    case 4:
                        if (!(authSession.uid !== userFb.uid)) return [3 /*break*/, 6];
                        // Must never happen
                        console.error("initSession: invalid authSession.uid (" + authSession.uid + ") !== userFb.uid (" + userFb.uid + ")");
                        return [4 /*yield*/, this.signOut()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/]; // never reached 
                    case 6:
                        this._authSession = authSession;
                        this._sessionS.onLogin(authSession);
                        return [4 /*yield*/, this._sessionS.waitSession()];
                    case 7:
                        session = _a.sent();
                        this._sessionTraceService.setEnableGtag(ENV.ga4Enabled);
                        user = session.user, group = session.group, features = session.features;
                        // TODO: Replace 'bento' feature with a generic 'userGuides' after MAP-2240 deployment
                        this._sessionTraceService.setEnableUserGuiding(ENV.userGuidingEnabled && (features.userFeatures.bento ||
                            features.generalFeatures.bento));
                        try {
                            d = user.createdAt;
                            userCreatedAt = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds).toDate().toISOString();
                        }
                        catch (_b) {
                            // Anonymous case
                            userCreatedAt = "2024-01-01T00:00:00.000Z";
                        }
                        this._sessionTraceService.onLogin(user.uid, {
                            domain: this._sessionS.getDomain().domainName,
                            gid: session.gid,
                            email: user.email,
                            name: user.displayName || user.company || user.email,
                            created_at: userCreatedAt,
                            isTrial: session.isTrial,
                            essentialListings: group.freeLocationsCount || 0,
                            basicListings: group.basicLocationsCount || 0,
                            ultimateListings: group.ultimateLocationsCount || 0,
                            totalListings: group.totalLocationsCount || 0,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype._forceAuthRefresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = firebase.auth().currentUser;
                        if (!user)
                            throw Error("No user");
                        return [4 /*yield*/, user.getIdToken(/*forceRefresh =*/ true)
                            // onIdTokenChanged will be called here synchronously
                        ];
                    case 1:
                        token = _a.sent();
                        // onIdTokenChanged will be called here synchronously
                        this._setAccessToken(token, 'forceAuthRefresh');
                        return [2 /*return*/, token];
                }
            });
        });
    };
    /**
     * Sets the current AuthSession info and saves it to local storage.
     * - The Firestore session should be already authenticated
     * - This, plus getting the accessToken from Firestore auth, enables
     *   the correct return value of the headers() method.
     */
    AuthService.prototype._authSessionSet = function (s) {
        if (this._authSession) {
            // Must never happen
            var m = "Trying to set AuthSession but already set!";
            console.error(m);
            this.signOut();
            return;
        }
        this._authSession = s;
        localStorage.setItem(STORAGE_MAPLABS_SESSION, JSON.stringify({ formatVersion: 1,
            authSession: s }));
        this._authProxyTryInit();
    };
    /**
     * Migration function to avoid logouting users with old versions.
     * TODO: Remove after quarantine period.
     */
    AuthService.prototype._authSessionMigrateV0ToV1 = function () {
        var e_1, _a;
        var _b;
        var s = null;
        try {
            var json = localStorage.getItem(STORAGE_SESSION_EXTERNAL);
            if (json) {
                var old = JSON.parse(json);
                var externalGradeId = localStorage.getItem(STORAGE_EXTERNAL_GRADE_ID);
                s = { uid: old.uid,
                    gid: old.gid,
                    sessionType: 'EXTERNAL_GRADER',
                    isImpersonating: false,
                    externalGradeId: externalGradeId };
            }
            else {
                json = localStorage.getItem(STORAGE_SESSION);
                if (json) {
                    var old = JSON.parse(json);
                    var anon = (((_b = old) === null || _b === void 0 ? void 0 : _b.email) === MAIL_ANONYMOUS);
                    var isImpersonating = !anon && !!localStorage.getItem(STORAGE_IS_IMPERSONATING);
                    s = { uid: old.uid,
                        gid: old.gid,
                        sessionType: (anon ? 'ANONYMOUS' : 'NORMAL'),
                        isImpersonating: isImpersonating };
                }
            }
        }
        catch (e) {
            console.error("Error migrating session format", e);
        }
        try {
            for (var _c = __values([STORAGE_SESSION_EXTERNAL, STORAGE_EXTERNAL_GRADE_ID,
                STORAGE_IS_IMPERSONATING, STORAGE_SESSION]), _d = _c.next(); !_d.done; _d = _c.next()) {
                var k = _d.value;
                try {
                    localStorage.removeItem(k);
                }
                catch (_e) {
                    //pass
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (s) {
            localStorage.setItem(STORAGE_MAPLABS_SESSION, JSON.stringify({ formatVersion: 1,
                authSession: s }));
        }
    };
    AuthService.prototype._authSessionLoad = function () {
        var _a;
        var json = localStorage.getItem(STORAGE_MAPLABS_SESSION);
        if (!json)
            return null;
        var d = JSON.parse(json); // TODO: validate
        if (((_a = d) === null || _a === void 0 ? void 0 : _a.formatVersion) !== 1)
            return null;
        var s = d.authSession;
        return s || null;
    };
    AuthService.prototype._authSessionDelete = function () {
        this._authSession = null;
        try {
            localStorage.removeItem(STORAGE_MAPLABS_SESSION);
        }
        catch (_a) {
            // pass
        }
    };
    //--------------------------------------------------------------------------
    // Login methods
    //--------------------------------------------------------------------------
    AuthService.prototype._assertFbInitAndNoAuth = function (redirectToLoginWhenFails) {
        if (redirectToLoginWhenFails === void 0) { redirectToLoginWhenFails = true; }
        return __awaiter(this, void 0, void 0, function () {
            var auth, m;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.waitAuthSession()];
                    case 1:
                        auth = _a.sent();
                        if (!auth) return [3 /*break*/, 3];
                        m = "Trying to signIn but already signedIn!";
                        console.error(m);
                        return [4 /*yield*/, this.signOut(redirectToLoginWhenFails)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // 'NORMAL' sessionType
    AuthService.prototype.signInWithGooglePopup = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var provider, userCred;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._assertFbInitAndNoAuth()];
                    case 1:
                        _c.sent();
                        provider = new firebase.auth.GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' });
                        return [4 /*yield*/, this._afAuth.auth.signInWithPopup(provider)];
                    case 2:
                        userCred = _c.sent();
                        if (!((_b = (_a = userCred) === null || _a === void 0 ? void 0 : _a.additionalUserInfo) === null || _b === void 0 ? void 0 : _b.isNewUser)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._afAuth.auth.currentUser.delete()];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4: return [4 /*yield*/, this._afterNormalLogin(userCred)];
                    case 5:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // 'NORMAL' sessionType + isImpersonating == true
    AuthService.prototype.signInWithImpersonateToken = function (impersonate_token) {
        return __awaiter(this, void 0, void 0, function () {
            var userCred, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._assertFbInitAndNoAuth()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this._afAuth.auth.signInWithCustomToken(impersonate_token)];
                    case 3:
                        userCred = _a.sent();
                        // We don't need to store the impersonate_token after using it to get the user 
                        // credentials, as the Firebase auth lib will store the authentication state
                        // behind scenes. 
                        return [4 /*yield*/, this._afterNormalLogin(userCred, true)];
                    case 4:
                        // We don't need to store the impersonate_token after using it to get the user 
                        // credentials, as the Firebase auth lib will store the authentication state
                        // behind scenes. 
                        _a.sent();
                        return [2 /*return*/, null];
                    case 5:
                        e_2 = _a.sent();
                        console.debug("error impersonating", e_2);
                        return [2 /*return*/, (e_2.code + ' ' + e_2.message)];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    // 'NORMAL' sessionType
    AuthService.prototype.signInWithEmailAndPassword = function (email, password) {
        return __awaiter(this, void 0, void 0, function () {
            var userCred;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._assertFbInitAndNoAuth()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._afAuth.auth.signInWithEmailAndPassword(email, password)];
                    case 2:
                        userCred = _a.sent();
                        return [4 /*yield*/, this._afterNormalLogin(userCred)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // 'ANONYMOUS' sessionType
    AuthService.prototype.signInAnonymously = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            var userCred;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._assertFbInitAndNoAuth(false)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._afAuth.auth.signInWithEmailAndPassword(MAIL_ANONYMOUS, 'password')];
                    case 2:
                        userCred = _a.sent();
                        this._authSessionSet({ uid: userCred.user.uid,
                            gid: gid,
                            sessionType: 'ANONYMOUS',
                            isImpersonating: false
                        });
                        return [4 /*yield*/, this.initSession()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // 'EXTERNAL_GRADE' sessionType
    /**
     * gid and uid will be the ones of the single external grade user
     * TODO: Is the external grade user really necesary? If not use anonymous user.
     */
    AuthService.prototype.signInExternalGradeUser = function (externalGradeId) {
        return __awaiter(this, void 0, void 0, function () {
            var userCred, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._assertFbInitAndNoAuth(false)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._afAuth.auth.signInWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external')];
                    case 2:
                        userCred = _a.sent();
                        return [4 /*yield*/, this._userService.getUserByUid(userCred.user.uid)];
                    case 3:
                        user = _a.sent();
                        this._authSessionSet({ uid: user.uid,
                            gid: user.gid,
                            sessionType: 'EXTERNAL_GRADER',
                            isImpersonating: false,
                            externalGradeId: externalGradeId
                        });
                        return [4 /*yield*/, this.initSession()];
                    case 4:
                        _a.sent();
                        // localStorage.setItem(STORAGE_SESSION_EXTERNAL, JSON.stringify(user));
                        // try {
                        //   user.authToken = await this.afAuth.auth.currentUser.getIdToken(true)
                        // } catch(e) {
                        // }
                        return [2 /*return*/, user];
                }
            });
        });
    };
    /**
     * Creates an account on Firebase Auth using a Google Login popup
     * Here the UID is created
     */
    AuthService.prototype.registerWithGoogleAndSignIn = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var newUserCred, displayName, e_3, _b, uid, gid;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._assertFbInitAndNoAuth()];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, this._afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())];
                    case 2:
                        newUserCred = _c.sent();
                        if (!newUserCred.additionalUserInfo.isNewUser)
                            throw 'This account is already registered. Try logging in.';
                        _c.label = 3;
                    case 3:
                        _c.trys.push([3, 6, , 7]);
                        displayName = (_a = newUserCred.additionalUserInfo.profile) === null || _a === void 0 ? void 0 : _a.name;
                        if (!displayName) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._afAuth.auth.currentUser.updateProfile({ displayName: displayName })];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_3 = _c.sent();
                        console.error("Error updating new user profile:", e_3);
                        return [3 /*break*/, 7];
                    case 7: return [4 /*yield*/, this._createUserAndGroup(newUserCred)];
                    case 8:
                        _b = _c.sent(), uid = _b.uid, gid = _b.gid;
                        this._authSessionSet({ uid: uid,
                            gid: gid,
                            sessionType: 'NORMAL',
                            isImpersonating: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    //--------------------------------------------------------------------------
    AuthService.prototype.signOut = function (redirectToLogin) {
        if (redirectToLogin === void 0) { redirectToLogin = true; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._ngZone.run(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b, f, e_4, e_5_1;
                            var e_5, _c;
                            var _this = this;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        console.debug("signOut(): redirectToLogin=" + redirectToLogin);
                                        if (this._signOutCalled) {
                                            // Avoid dup-call from firebase-auth callbacks
                                            console.debug('signOut(): already called');
                                            return [2 /*return*/];
                                        }
                                        this._signOutCalled = true;
                                        _d.label = 1;
                                    case 1:
                                        _d.trys.push([1, 8, 9, 10]);
                                        _a = __values([(function () { return _this._sessionTraceService.onLogout(); }),
                                            (function () { return _this._storageRemoveAll(); }),
                                            (function () { return _this._afAuth.auth.signOut(); })]), _b = _a.next();
                                        _d.label = 2;
                                    case 2:
                                        if (!!_b.done) return [3 /*break*/, 7];
                                        f = _b.value;
                                        _d.label = 3;
                                    case 3:
                                        _d.trys.push([3, 5, , 6]);
                                        return [4 /*yield*/, f()];
                                    case 4:
                                        _d.sent();
                                        return [3 /*break*/, 6];
                                    case 5:
                                        e_4 = _d.sent();
                                        console.error("signOut(): finalizer error", e_4);
                                        return [3 /*break*/, 6];
                                    case 6:
                                        _b = _a.next();
                                        return [3 /*break*/, 2];
                                    case 7: return [3 /*break*/, 10];
                                    case 8:
                                        e_5_1 = _d.sent();
                                        e_5 = { error: e_5_1 };
                                        return [3 /*break*/, 10];
                                    case 9:
                                        try {
                                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                        }
                                        finally { if (e_5) throw e_5.error; }
                                        return [7 /*endfinally*/];
                                    case 10:
                                        if (!redirectToLogin) return [3 /*break*/, 12];
                                        console.debug('signOut(): reload to /login');
                                        return [4 /*yield*/, this._router.navigate(['/login'], { replaceUrl: true })];
                                    case 11:
                                        _d.sent();
                                        return [3 /*break*/, 13];
                                    case 12:
                                        // Ensure refresh on logout to mitigate memory leaks and oversubscribed observers
                                        // If we don't that, some observers still run and try to make http requests
                                        // without valid authorization headers
                                        // Also for code simplification purposes we dont want SessionServers subscribers 
                                        // to support a transition to session=null.
                                        console.debug('signOut(): reload to same url');
                                        _d.label = 13;
                                    case 13:
                                        // Ensure page reload no matter the RouteReuseStrategy
                                        window.location.reload();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create group and user with gid and get of firebase token id
     * @param newUserCred  Response firestore
     * @param displayName  name for user
     * @param company  company for user
     */
    AuthService.prototype._createUserAndGroup = function (newUserCred, displayName, company, needsVerification) {
        if (displayName === void 0) { displayName = null; }
        if (company === void 0) { company = ''; }
        if (needsVerification === void 0) { needsVerification = false; }
        return __awaiter(this, void 0, void 0, function () {
            var now, domainName, authUser, name, uid, gid, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        now = new Date();
                        domainName = this._sessionS.getDomain().domainName;
                        authUser = newUserCred.user;
                        name = authUser.displayName || displayName;
                        uid = authUser.uid;
                        return [4 /*yield*/, this._createGroup({ users: [uid],
                                admin: uid,
                                domain: domainName,
                                company: company })];
                    case 1:
                        gid = (_a.sent()).gid;
                        user = {
                            uid: uid,
                            gid: gid,
                            email: authUser.email,
                            displayName: name,
                            company: company,
                            photoURL: authUser.photoURL,
                            lastLogin: firebase.firestore.Timestamp.fromDate(now),
                            createdAt: now,
                            timezone: now.getTimezoneOffset(),
                            registrationDomain: domainName,
                            domainSurfing: false,
                            isAI: false,
                            isActive: true,
                            // First user created in group will be his admin 
                            role: 'admin'
                        };
                        // TODO: needsVerification is always false on all call sites, remove it?
                        if (needsVerification) {
                            user.emailVerified = null;
                            user.emailVerificationHash = (new Md5()).appendStr("" + authUser.uid + user.gid + user.displayName).end();
                        }
                        // TODO: At this point the SessionService was not yet initialized, now it doesn't 
                        // matter because this creates the user directly on Firestore and doesn't need it,
                        // but check out for that after changing this to a backend enpdoint. 
                        return [4 /*yield*/, this._userService.save(user)
                            ///// TODO: Refactor against session callbacks
                            // if(!needsVerification) {
                            //   // if (this.session?.gid) {
                            //   //   this._checkUser(this.session?.gid, this.session?.uid);
                            //   // }
                            //   this._setAccessToken(await this.afAuth.auth.currentUser.getIdToken(true), "createUserAndGroup")
                            // }
                            ///// 
                        ];
                    case 2:
                        // TODO: At this point the SessionService was not yet initialized, now it doesn't 
                        // matter because this creates the user directly on Firestore and doesn't need it,
                        // but check out for that after changing this to a backend enpdoint. 
                        _a.sent();
                        ///// TODO: Refactor against session callbacks
                        // if(!needsVerification) {
                        //   // if (this.session?.gid) {
                        //   //   this._checkUser(this.session?.gid, this.session?.uid);
                        //   // }
                        //   this._setAccessToken(await this.afAuth.auth.currentUser.getIdToken(true), "createUserAndGroup")
                        // }
                        ///// 
                        return [2 /*return*/, { uid: uid, gid: gid }];
                }
            });
        });
    };
    // TODO: move to navigationService (maybe without alertIfNotPaymentMethod)
    AuthService.prototype.redirectAfterLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isMember, gid, r, accounts, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this._sessionS.getSession(), isMember = _a.isMember, gid = _a.gid;
                        if (!isMember) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._router.navigate(['/accounts'])];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 2:
                        _b.trys.push([2, 6, , 7]);
                        return [4 /*yield*/, this._http.get(ENV.apiUrl + "/v2/accounts/" + gid + "/all?page=1&pageSize=25&accountIds=").toPromise()];
                    case 3:
                        r = _b.sent();
                        accounts = r["items"];
                        return [4 /*yield*/, this._router.navigate([accounts.length ? "accounts/" + accounts[0].accountId + "/locations" : '/accounts'])];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this._alertIfNotPaymentMethod()];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _b.sent();
                        console.error(err_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype._storageRemoveAll = function () {
        var e_6, _a;
        console.debug("localStorage: Removing all");
        try {
            for (var STORAGE_ALL_KEYS_1 = __values(STORAGE_ALL_KEYS), STORAGE_ALL_KEYS_1_1 = STORAGE_ALL_KEYS_1.next(); !STORAGE_ALL_KEYS_1_1.done; STORAGE_ALL_KEYS_1_1 = STORAGE_ALL_KEYS_1.next()) {
                var k = STORAGE_ALL_KEYS_1_1.value;
                try {
                    localStorage.removeItem(k);
                }
                catch (_b) {
                    //pass
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (STORAGE_ALL_KEYS_1_1 && !STORAGE_ALL_KEYS_1_1.done && (_a = STORAGE_ALL_KEYS_1.return)) _a.call(STORAGE_ALL_KEYS_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    /**
     * Executed after the user logins on a NORMAL session.
     * Note, this is not executed when a NORMAL persistent session is recovered.
     */
    AuthService.prototype._afterNormalLogin = function (userCred, isImpersonating) {
        if (isImpersonating === void 0) { isImpersonating = false; }
        return __awaiter(this, void 0, void 0, function () {
            var uid, user, domain, isEmailVerified, verif, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uid = userCred.user.uid;
                        return [4 /*yield*/, this._userService.getUserByUid(uid)];
                    case 1:
                        user = _a.sent();
                        if (!!user) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._modalService.openErrorModal('Heads up', Messages.register.USER_DOESNT_EXIST)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.signOut()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/]; // never reached, as signOut reloads the page
                    case 4:
                        this._authSessionSet({ uid: uid,
                            gid: user.gid,
                            sessionType: 'NORMAL',
                            isImpersonating: isImpersonating });
                        domain = this._sessionS.getDomain();
                        return [4 /*yield*/, this._userService.updateLastLogin(user.gid, user.uid, new Date(), domain.xDomainName)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this._userService.getEmailIsVerified(user)];
                    case 6:
                        isEmailVerified = _a.sent();
                        if (!isEmailVerified) return [3 /*break*/, 8];
                        // TODO: Why this is not also called when email is not verified?
                        return [4 /*yield*/, this.logoutIfDomainValidationFails(user)];
                    case 7:
                        // TODO: Why this is not also called when email is not verified?
                        _a.sent();
                        return [3 /*break*/, 15];
                    case 8: return [4 /*yield*/, this._verificationService.getVerification(user.uid, user.gid).toPromise()];
                    case 9:
                        verif = _a.sent();
                        if (!verif.docs.length) return [3 /*break*/, 15];
                        data = verif.docs[0].data();
                        if (!(data.emailVerified == null)) return [3 /*break*/, 12];
                        return [4 /*yield*/, this._modalService.openErrorModal('Heads up', Messages.register.EMAIL_NOT_VERIFIED)];
                    case 10:
                        _a.sent();
                        return [4 /*yield*/, this.signOut()];
                    case 11:
                        _a.sent();
                        return [2 /*return*/]; // never reached, as signOut reloads the page
                    case 12: 
                    // TODO: Should be done server-side!
                    return [4 /*yield*/, this._userService.updateUser(user.gid, user.uid, { emailVerified: firebase.firestore.Timestamp.now() })];
                    case 13:
                        // TODO: Should be done server-side!
                        _a.sent();
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 14:
                        _a.sent();
                        _a.label = 15;
                    case 15: 
                    // if (this.session?.gid)
                    //   this._checkUser(this.session.gid, this.session.uid);
                    return [4 /*yield*/, this.initSession()];
                    case 16:
                        // if (this.session?.gid)
                        //   this._checkUser(this.session.gid, this.session.uid);
                        _a.sent();
                        // this._showMessageTypeAuth(this.session)
                        return [4 /*yield*/, this.redirectAfterLogin()];
                    case 17:
                        // this._showMessageTypeAuth(this.session)
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.logoutIfDomainValidationFails = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var domVal, desc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._userService.domainValidation(BROWSER_DOMAIN.domainName, user.gid, user.uid, user.domainSurfing)];
                    case 1:
                        domVal = _a.sent();
                        desc = "Domain [" + BROWSER_DOMAIN.domainName + "], registration domain [" + domVal.domain + "]";
                        if (!isDevMode()) return [3 /*break*/, 2];
                        console.debug("Domain validation Ignored: DevMode=true, " + desc);
                        return [3 /*break*/, 5];
                    case 2:
                        if (!!domVal.allowLogin) return [3 /*break*/, 5];
                        console.debug("Domain validation FAILED");
                        return [4 /*yield*/, this._modalService.openErrorModal('Heads up', "Sorry, we couldn't find your account. " +
                                "Please check your username and password or contact support.")];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.signOut()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/]; // never reached, as signOut reloads the page
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype._createGroup = function (group) {
        return this._http.post(ENV.apiUrl + "/v2/auth/signup", group, HEADERS_NO_AUTH).toPromise();
    };
    AuthService.prototype._setAccessToken = function (accessToken, debugStr) {
        this._accessToken = accessToken;
        console.debug(debugStr + ": accessToken changed to:", accessToken ? accessToken.substring(0, 10) + "..." : 'null');
        this._authProxyTryInit();
    };
    AuthService.prototype._authProxyTryInit = function () {
        var _this = this;
        // Await until both _accessToken and _authSession are ready, as both
        // are required by the headers() method, that is ensured to be called
        // only after authProxyService is initialized
        if (!this._authProxyService.initialized && this._accessToken && this._authSession)
            this._authProxyService.initialize(function () { return _this.signOut(); }, function () { return _this.headers; }, function () { return _this._forceAuthRefresh(); });
    };
    /**
     * Alert the user if he has a paid Subscription and no Credit Card configured
     */
    AuthService.prototype._alertIfNotPaymentMethod = function () {
        return __awaiter(this, void 0, void 0, function () {
            var s, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        s = _b.sent();
                        _a = !s.isTrial &&
                            s.requiresPaymentMethod &&
                            (s.group.basicLocationsCount || s.group.ultimateLocationsCount);
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._paymentsService.hasPaymentMethods(s.gid)];
                    case 2:
                        _a = !(_b.sent());
                        _b.label = 3;
                    case 3:
                        if (!_a) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._modalService.openErrorModal('Heads up', "You don't have a Credit Card set up. Please add one.")];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype._onStorageEventFromOtherTab = function (event) {
        // console.debug('storage', event)
        if (event.key === STORAGE_MAPLABS_SESSION && (!event.newValue || !JSON.parse(event.newValue))) {
            console.debug('Detected signOut from another tab');
            this.signOut();
        }
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i6.VerificationEmailService), i0.ɵɵinject(i7.AuthProxyService), i0.ɵɵinject(i8.PaymentsService), i0.ɵɵinject(i9.ModalService), i0.ɵɵinject(i10.SessionTraceService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
