<ng-container *ngIf="!!(session$ | async)">
  <app-trial-message></app-trial-message>
  <app-impersonate-message></app-impersonate-message>  
</ng-container>

<!-- Global spinner-->
<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="spinnerService.loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<!-- Header-->
<app-header [noToggle]="true"></app-header>

<!-- Main Wrapper (contains router-outlet) -->
<app-main></app-main>
