// dep
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
// app
import { ModalConfirmData } from "../classes/modal-confirm-data";
import { SnackbarService } from "../services/snackbar.service";
var ReviewLinkGeneratorComponent = /** @class */ (function () {
    function ReviewLinkGeneratorComponent(data, dialogRef, snack) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.snack = snack;
        this.isCopy = false;
    }
    ReviewLinkGeneratorComponent.prototype.copy = function ($event) {
        $event.preventDefault();
        var selBox = document.createElement('input');
        selBox.value = this.url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.isCopy = true;
        this.snack.openSuccess('The link was copied to your clipboard.');
        this.cancel();
    };
    ReviewLinkGeneratorComponent.prototype.ngOnInit = function () {
        this.url = this.data.data.shortLink;
    };
    ReviewLinkGeneratorComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    ReviewLinkGeneratorComponent.prototype.saveAsImage = function (parent) {
        var _a;
        // fetches base 64 date from image
        var parentElement = parent.qrcElement.nativeElement.querySelector("img").src;
        // converts base 64 encoded image to blobData
        var blobData = this._convertBase64ToBlob(parentElement);
        // saves as image
        if ((_a = window) === null || _a === void 0 ? void 0 : _a.navigator.msSaveOrOpenBlob) { //IE
            window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
        }
        else { // chrome
            var blob = new Blob([blobData], { type: "image/png" });
            var url = window.URL.createObjectURL(blob);
            // window.open(url);
            var link = document.createElement('a');
            link.href = url;
            link.download = 'Qrcode';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // window.URL.revokeObjectURL(url);
        }
    };
    ReviewLinkGeneratorComponent.prototype._convertBase64ToBlob = function (Base64Image) {
        // SPLIT INTO TWO PARTS
        var parts = Base64Image.split(';base64,');
        // HOLD THE CONTENT TYPE
        var imageType = parts[0].split(':')[1];
        // DECODE BASE64 STRING
        var decodedData = window.atob(parts[1]);
        // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
        var uInt8Array = new Uint8Array(decodedData.length);
        // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
        for (var i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // RETURN BLOB IMAGE AFTER CONVERSION
        return new Blob([uInt8Array], { type: imageType });
    };
    return ReviewLinkGeneratorComponent;
}());
export { ReviewLinkGeneratorComponent };
