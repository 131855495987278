import { __awaiter, __generator } from "tslib";
import { BehaviorSubject } from 'rxjs';
// app
import { SessionService } from './session.service';
import { GoogleService } from './google.service';
import { ExternalGradeService } from './external-grade.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "./google.service";
import * as i3 from "./external-grade.service";
var GoogleLocationService = /** @class */ (function () {
    function GoogleLocationService(_sessionS, _googleS, _externalGradeS) {
        this._sessionS = _sessionS;
        this._googleS = _googleS;
        this._externalGradeS = _externalGradeS;
        this._locations = new BehaviorSubject([]);
        this.locations$ = this._locations.asObservable();
    }
    GoogleLocationService.prototype.loadAll = function (accountId, googleAuth) {
        return __awaiter(this, void 0, void 0, function () {
            var locations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!googleAuth)
                            googleAuth = this._sessionS.getSession().user.googleAuth;
                        return [4 /*yield*/, this._googleS.getLocations(googleAuth, accountId)];
                    case 1:
                        locations = _a.sent();
                        locations.sort(function (a, b) { return (a.locationName > b.locationName) ? 1 : -1; });
                        this._locations.next(locations);
                        return [2 /*return*/, locations];
                }
            });
        });
    };
    GoogleLocationService.prototype.loadAllGrade = function (user, accountId, externalGradeId) {
        return __awaiter(this, void 0, void 0, function () {
            var googleAuth;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._externalGradeS.getExternalGradeToken(user.gid, externalGradeId)];
                    case 1:
                        googleAuth = _a.sent();
                        this.loadAll(accountId, googleAuth);
                        return [2 /*return*/];
                }
            });
        });
    };
    // reset(): void {
    //   // TODO: Why not this._locations.next([]) ?
    //   // Note that subscriptions are made thru the locations() getter, so probably it was a
    //   // (wrong and leaking) way to "desubscribe" previous subscribers
    //   this._locations = new BehaviorSubject<GoogleLocation[]>([]);
    // }
    GoogleLocationService.prototype.reset = function () {
        this._locations.next([]);
    };
    GoogleLocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleLocationService_Factory() { return new GoogleLocationService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.GoogleService), i0.ɵɵinject(i3.ExternalGradeService)); }, token: GoogleLocationService, providedIn: "root" });
    return GoogleLocationService;
}());
export { GoogleLocationService };
