import { __extends } from "tslib";
// dep
import { OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material';
// app
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from '../../services/spinner.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var DashboardLayoutComponent = /** @class */ (function (_super) {
    __extends(DashboardLayoutComponent, _super);
    function DashboardLayoutComponent(spinnerService, router, activatedRoute, titleService, _sessionS, renderer2, dataTransfer) {
        var _this = _super.call(this) || this;
        _this.spinnerService = spinnerService;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.titleService = titleService;
        _this._sessionS = _sessionS;
        _this.renderer2 = renderer2;
        _this.dataTransfer = dataTransfer;
        _this.isFullWidth = false;
        _this.opened = false;
        _this.session$ = _this._sessionS.session$;
        var appTitle = _this.titleService.getTitle();
        _this._subscribeSafe(router.events, function (event) {
            if (!(event instanceof NavigationEnd))
                return;
            var title = _this.activatedRoute.firstChild.snapshot.data.title || appTitle;
            // FIXME: Multiple subscriptions
            _this._subscribeSafe(_this.dataTransfer.getData(), function (locationName) {
                var company_name = _this._sessionS.getDomain().branding.company_name;
                _this.titleService.setTitle(title + " | " + locationName + " | " + company_name);
            });
            _this._updateClass();
        });
        _this.opened = true;
        return _this;
    }
    DashboardLayoutComponent.prototype.toggleSideNav = function () {
        this.sidenav.toggle();
        var elementId = document.getElementById('dashboard-filter');
        if (elementId) {
            if (!this.sidenav.opened) {
                this.renderer2.addClass(elementId, 'remove-padding');
            }
            else {
                this.renderer2.removeClass(elementId, 'remove-padding');
            }
        }
    };
    DashboardLayoutComponent.prototype.ngOnInit = function () {
        this._updateClass();
    };
    DashboardLayoutComponent.prototype._updateClass = function () {
        var url = this.router.url;
        this.isFullWidth = (url.includes('insights') || url.includes('reviews'));
        this.contentClass = {
            'content content--padding': !this.isFullWidth,
            'dashboard-content': this.isFullWidth
        };
    };
    return DashboardLayoutComponent;
}(BaseComponent));
export { DashboardLayoutComponent };
