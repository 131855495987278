import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {ModalUpgradeComponent} from '../modal-upgrade/modal-upgrade.component';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent {

  constructor(private router: Router, public dialog: MatDialog) { }

  openUpgradeDialog(): void {
    const dialogRef = this.dialog.open(ModalUpgradeComponent, {
      width: '730px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  decline() {
    this.router.navigate(['/locations']);
  }

}
