<ng-container *ngIf="!!(session$ | async)">
    <app-trial-message></app-trial-message>
    <app-impersonate-message></app-impersonate-message>
    <app-header (sidenavToggle)="toggleSideNav()"></app-header>
</ng-container>

<!-- Global spinner-->
<div *ngIf="spinnerService.loading$ | async"
     class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" >
    <mat-spinner></mat-spinner>
</div>

<mat-sidenav-container *ngIf="!!(session$ | async)">
    <mat-sidenav #sidenav id="sidenav" role="navigation" mode="side" disableClose="false" opened="true" [fixedInViewport]="true" >
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <main [ngClass]="contentClass">
            <!-- On <router-outlet> the child component referenced on app.router.ts will be rendered,
                 selected by route.
             -->
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container>

