import { __assign, __awaiter, __generator, __read, __spread, __values } from "tslib";
import * as _ from 'lodash';
import { timer } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
// app
import { HEADERS_NO_AUTH } from '../constants/auth';
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DYNAMIC_URL = 'https://search.google.com/local/writereview?placeid=';
var GoogleService = /** @class */ (function () {
    function GoogleService(_http) {
        this._http = _http;
    }
    /**
     * This will continue an OAuth flow, at the end the resultant token will be stored
     * on Firestore under GROUP[gid].USER[uid].googleAuth and in
     * GROUP[gid].EXTERNAL_GRADES(accountId).googleAuth
     * TODO: Note that externalId here is passed as 'accountId', but in the backend is used as
     * 'externalGradeId', accountId is a misleading query param.
     * TODO: Also some callers are passing a real accountId, we are polluting the EXTERNAL
     * grade collections with documents keyed by accountId not later used?
     * @returns an URL
     */
    GoogleService.prototype.authenticate = function (gid, uid, accountId) {
        if (accountId === void 0) { accountId = null; }
        return __awaiter(this, void 0, void 0, function () {
            var apiUrl, domain;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apiUrl = ENV.apiUrl;
                        domain = apiUrl.endsWith('/api') ? apiUrl.substring(0, apiUrl.length - 4) : apiUrl;
                        return [4 /*yield*/, this._http.get(apiUrl + "/google/auth_url?uid=" + uid + "&gid=" + gid + "&accountId=" + accountId + "&domain=" + domain, HEADERS_NO_AUTH).toPromise()];
                    case 1: 
                    // FIXME: This endpoint should be a POST, not a GET?
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    //--------------------------------------------------------------------------
    // Requests to Google
    //--------------------------------------------------------------------------
    // TODO: Unused, remove. 
    //   async updateProductList(accountId, locationId, priceList) : Promise<void> {
    //     this.auth.getGmbToken().subscribe(token => {
    //       this._http.patch(`${this.googleApiUrl}/accounts/${accountId}/locations/${locationId}?updateMask=priceLists`, 
    //                       {"priceLists": priceList}, this.headersGoogle(token)).toPromise();
    //     });
    // 
    //   }
    GoogleService.prototype.getUserProfile = function (googleAuth) {
        return this._http.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" + googleAuth.access_token, HEADERS_NO_AUTH).toPromise();
    };
    GoogleService.prototype._headersGoogle = function (googleAuth) {
        var _a;
        return { headers: HEADERS_NO_AUTH.headers.set('Authorization', "Bearer " + (((_a = googleAuth) === null || _a === void 0 ? void 0 : _a.access_token) || '')) };
    };
    GoogleService.prototype._fetchAllPages = function (url, urlParams, googleAuth) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, pageToken, r, params, page;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = this._headersGoogle(googleAuth);
                        pageToken = '';
                        r = [];
                        _a.label = 1;
                    case 1:
                        params = (urlParams || new HttpParams());
                        if (pageToken)
                            params = params.set('pageToken', pageToken);
                        return [4 /*yield*/, this._http.get(url, __assign({ params: params }, headers)).toPromise()];
                    case 2:
                        page = _a.sent();
                        r.push(page);
                        pageToken = page.nextPageToken;
                        _a.label = 3;
                    case 3:
                        if (pageToken) return [3 /*break*/, 1];
                        _a.label = 4;
                    case 4: return [2 /*return*/, r];
                }
            });
        });
    };
    GoogleService.prototype.getAccounts = function (googleAuth) {
        return __awaiter(this, void 0, void 0, function () {
            var pages, accounts, pages_1, pages_1_1, p;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this._fetchAllPages(ENV.googleAccountManagementApi + '/accounts', null, googleAuth)];
                    case 1:
                        pages = _b.sent();
                        accounts = [];
                        try {
                            for (pages_1 = __values(pages), pages_1_1 = pages_1.next(); !pages_1_1.done; pages_1_1 = pages_1.next()) {
                                p = pages_1_1.value;
                                accounts.push.apply(accounts, __spread(p.accounts));
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (pages_1_1 && !pages_1_1.done && (_a = pages_1.return)) _a.call(pages_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [2 /*return*/, accounts];
                }
            });
        });
    };
    // TODO: Type return value only including readMask values
    GoogleService.prototype.getLocations = function (googleAuth, accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var readMask, url, params, pages, locations, pages_2, pages_2_1, p, _a, _b, l;
            var e_2, _c, e_3, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        readMask = ("storeCode,regularHours,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri," +
                            "regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata," +
                            "profile,relationshipData,moreHours,metadata");
                        url = ENV.googleApiLocations + "/" + accountId + "/locations";
                        params = new HttpParams().set('readMask', readMask)
                            .set('pageSize', '100');
                        return [4 /*yield*/, this._fetchAllPages(url, params, googleAuth)];
                    case 1:
                        pages = _e.sent();
                        locations = [];
                        try {
                            for (pages_2 = __values(pages), pages_2_1 = pages_2.next(); !pages_2_1.done; pages_2_1 = pages_2.next()) {
                                p = pages_2_1.value;
                                try {
                                    for (_a = (e_3 = void 0, __values((p.locations || []))), _b = _a.next(); !_b.done; _b = _a.next()) {
                                        l = _b.value;
                                        l.locationName = l.title;
                                        l.name = accountId + '/' + l.name;
                                        l.address = l.storefrontAddress;
                                        locations.push(l);
                                    }
                                }
                                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                finally {
                                    try {
                                        if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (pages_2_1 && !pages_2_1.done && (_c = pages_2.return)) _c.call(pages_2);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        return [2 /*return*/, locations];
                }
            });
        });
    };
    //--------------------------------------------------------------------------
    GoogleService.prototype.updateMenuList = function (accountId, locationId, priceList) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/menu_list", { priceList: priceList })
                            .pipe(map(function (value) { return value ? value.data : {}; })).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GoogleService.prototype.saveMedia = function (accountId, locationId, data) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media";
        return this._http.post(url, data);
    };
    GoogleService.prototype.saveBulkMedia = function (accountId, locationId, data) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/bulk_media";
        return this._http.post(url, data);
    };
    GoogleService.prototype.deleteMedia = function (accountId, locationId, name) {
        var params = new HttpParams().set('name', name);
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media";
        return this._http.delete(url, { params: params }).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getMedia = function (accountId, locationId) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media";
        return this._http.get(url).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getProfilePhoto = function (accountId, locationId) {
        var url = ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/media/profile";
        return this._http.get(url).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getCategories = function (displayName, usedCategories, region_code) {
        var params = new HttpParams().set('q', displayName).set('region_code', region_code);
        return this._http.get(ENV.apiUrl + "/v2/categories/search", {
            params: params
        }).pipe(map(function (value) {
            var _a;
            if ((_a = value) === null || _a === void 0 ? void 0 : _a.data) {
                var data_1 = __spread(value.data);
                usedCategories.forEach(function (cat) {
                    var _a, _b, _c, _d, _e;
                    var categoryId = ((_b = (_a = cat) === null || _a === void 0 ? void 0 : _a.categoryId) === null || _b === void 0 ? void 0 : _b.includes('categories')) ? (_d = (_c = cat) === null || _c === void 0 ? void 0 : _c.categoryId) === null || _d === void 0 ? void 0 : _d.split('/')[1] : (_e = cat) === null || _e === void 0 ? void 0 : _e.categoryId;
                    var index = data_1.findIndex(function (d) { return d.categoryId == categoryId; });
                    if (index > -1) {
                        data_1.splice(index, 1);
                    }
                });
                return data_1;
            }
            return null;
        }), debounce(function () { return timer(1000); }));
    };
    // ATTRIBUTES SERVICES, FOOD MENU, PRODUCTS
    GoogleService.prototype.saveMenu = function (accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/menu").pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.saveServices = function (accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list").pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.updateServices = function (accountId, locationId, serviceList) {
        return this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list", { serviceList: serviceList }).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.updateBulkServices = function (accountId, locationId, data) {
        return this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list", data).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.updateFoodMenu = function (accountId, locationId, data) {
        return this._http.post(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/menu_list", data).pipe(map(function (value) { return value.data || []; }));
    };
    GoogleService.prototype.getAttributes = function (gids, accountIds, locationIds) {
        // const params = new HttpParams().set('categoryId', categoryId);
        var data = {
            gids: gids,
            accountIds: accountIds,
            locationIds: locationIds
        };
        return this._http.post(ENV.apiUrl + "/v2/google/attributes", data).pipe(map(function (value) { var _a, _b; return value.data ? (_b = (_a = value) === null || _a === void 0 ? void 0 : _a.data[0]) === null || _b === void 0 ? void 0 : _b.attributes : []; }));
    };
    // TODO: Unused, remove
    //
    // getGoogleData(accountId, locationId) {
    //   return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/getGoogleData`)
    //     .pipe(map(value => value.data || null));
    // }
    GoogleService.prototype.fetchDifference = function (accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/fetchDifferences")
            .pipe(map(function (value) { return value.data || null; }));
    };
    GoogleService.prototype.diffMaskPush = function (gid, accountId, locationId, differences) {
        if (differences === void 0) { differences = null; }
        var data = {
            gid: gid,
            differences: differences
        };
        return this._http.post(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/accept_google_updates", data);
    };
    GoogleService.prototype.fetch = function (select, accountId, locationId) {
        var params = new HttpParams().set('select', select);
        return this._http.get(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/fetch", {
            params: params,
        }).pipe(map(function (value) { return value || null; })).toPromise();
    };
    GoogleService.prototype.listLockHistory = function (accountId, placeId, pageable) {
        var params = new HttpParams()
            .set('size', pageable.size.toString())
            .set('accountId', accountId)
            .set('page', pageable.page.toString());
        return this._http.get(ENV.apiUrl + "/v2/google/" + placeId + "/lockHistory", {
            params: params,
        }).pipe(map(function (value) { return value ? value.data : null; }));
    };
    GoogleService.prototype.saveLockHistory = function (accountId, locationId, action, status) {
        return this._http.post(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/lockHistory", {
            status: status,
            action: action
        }).pipe(map(function (value) { return value ? value.data : null; }));
    };
    GoogleService.prototype.push = function (accountId, locationId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/push", {})
                            .pipe(map(function (value) { return value || null; })).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GoogleService.prototype.metrics = function (accountId, locationId, dataPicker) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var loc, r;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        loc = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.locations[0];
                        if (loc && loc.accountId && loc.locationId) {
                            accountId = loc.accountId;
                            locationId = loc.locationId;
                        }
                        return [4 /*yield*/, this._http.get(ENV.apiUrl + "/v2/google/" + accountId + "/" + locationId + "/grade").toPromise()];
                    case 1:
                        r = _b.sent();
                        return [2 /*return*/, r ? r.data : []];
                }
            });
        });
    };
    /**
     * Given a locationId, returns a short url to it by generating a Firebase Dynamic Link
     *
     * See https://firebase.google.com/docs/reference/dynamic-links/link-shortener
     */
    GoogleService.prototype.dynamicUrl = function (locationId) {
        var url = DYNAMIC_URL + locationId;
        return this._http.post(ENV.fbDynamicLinkApi, {
            longDynamicLink: ENV.fbDynamicLinkDomain + "/?link=" + url,
            suffix: {
                option: 'SHORT'
            }
        }, HEADERS_NO_AUTH);
    };
    GoogleService.prototype.attributesToGMB = function (groups) {
        var _this = this;
        var itemsActive = [];
        groups.forEach(function (group) {
            var active = group.items.filter(function (item) {
                return item.active === true || item.active === false || item.attributeId.startsWith('url');
            }).filter(function (item) {
                return !(item.valueType === 'URL' && item.urlValues === undefined);
            });
            itemsActive = itemsActive.concat(active);
        });
        var attributes = itemsActive.map(function (item) { return _this._toLocationAttributes(item); });
        return attributes;
    };
    GoogleService.prototype._toLocationAttributes = function (item) {
        switch (item.valueType) {
            case 'BOOL':
                return { valueType: item.valueType, attributeId: item.attributeId, values: [item.active] };
            case 'ENUM':
                return { valueType: item.valueType, attributeId: item.attributeId, values: [item.valueMetadata] };
            case 'URL':
                return { valueType: item.valueType, attributeId: item.attributeId, urlValues: item.urlValues };
            case 'REPEATED_ENUM': {
                var valuesSet = _.reject(item.valueMetadata.map(function (v) { return v.active && v.value; }), function (o) { return !o; });
                var valuesUnSet = _.reject(item.valueMetadata.map(function (v) { return v.active === false && v.value; }), function (o) { return !o; });
                var enumValues = {};
                if (!_.isEmpty(valuesSet))
                    enumValues['setValues'] = valuesSet;
                if (!_.isEmpty(valuesUnSet))
                    enumValues['unsetValues'] = valuesUnSet;
                return { valueType: item.valueType, attributeId: item.attributeId, repeatedEnumValue: enumValues };
            }
            default:
                return {};
        }
    };
    /**
     * organize the items in google attributes object
     * @return  this.groups organized
     */
    GoogleService.prototype._toAttributeGroups = function (googleAttributes) {
        var attributes = _.cloneDeep(googleAttributes);
        var groups = [];
        if (!googleAttributes) {
            return [];
        }
        attributes.forEach(function (value) {
            var groupName = value.groupDisplayName;
            var exist = false;
            var index = null;
            groups.forEach(function (group, i) {
                if (groupName === group.groupName) {
                    exist = true;
                    index = i;
                }
            });
            value.active = null;
            if (exist) {
                groups[index].items.push(value);
            }
            else {
                groups.push({
                    groupName: groupName,
                    items: [value],
                });
            }
        });
        groups.forEach(function (group) {
            group.items = _.orderBy(group.items, ['valueType'], ['asc']);
        });
        return groups;
    };
    /*
      FIXME: smell bad
      attribute onlyActive never used
    */
    GoogleService.prototype._activeSavedAttributes = function (groups, locationAttributes, onlyActive) {
        if (onlyActive === void 0) { onlyActive = false; }
        if (!locationAttributes) {
            return;
        }
        if (locationAttributes.length === 0) {
            return groups;
        }
        groups.forEach(function (group) {
            group.items.forEach(function (item) {
                if (item.valueType === 'URL')
                    group.active = true;
                locationAttributes.forEach(function (locAttribute) {
                    var _a, _b;
                    if (item.attributeId === locAttribute.attributeId) {
                        if (item.valueType === 'URL') {
                            item.urlValues = locAttribute.urlValues;
                            group.active = true;
                        }
                        if (item.valueType === 'BOOL') {
                            item.active = locAttribute.values[0];
                            group.active = true;
                        }
                        if (item.valueType === 'REPEATED_ENUM' || item.valueType === 'ENUM') {
                            (_b = (_a = item) === null || _a === void 0 ? void 0 : _a.valueMetadata) === null || _b === void 0 ? void 0 : _b.map(function (metadata) {
                                var _a, _b, _c, _d, _e, _f;
                                metadata.active = null;
                                if (((_c = (_b = (_a = locAttribute) === null || _a === void 0 ? void 0 : _a.repeatedEnumValue) === null || _b === void 0 ? void 0 : _b.setValues) === null || _c === void 0 ? void 0 : _c.findIndex(function (value) { return value === metadata.value; })) > -1) {
                                    group.active = true;
                                    item.active = true;
                                    metadata.active = true;
                                }
                                else if (((_f = (_e = (_d = locAttribute) === null || _d === void 0 ? void 0 : _d.repeatedEnumValue) === null || _e === void 0 ? void 0 : _e.unsetValues) === null || _f === void 0 ? void 0 : _f.findIndex(function (value) { return value === metadata.value; })) > -1) {
                                    group.active = true;
                                    item.active = true;
                                    metadata.active = false;
                                }
                            });
                        }
                    }
                });
            });
        });
        return groups;
    };
    GoogleService.prototype._getExcludeAtributes = function (googleAttributes, locationAttributes) {
        if (locationAttributes === void 0) { locationAttributes = []; }
        var result = [];
        locationAttributes.forEach(function (locAttribute) {
            var findAttribute = _.find(googleAttributes, { 'attributeId': locAttribute.attributeId });
            if (!findAttribute || _.isEqual(locAttribute.valueType, 'URL')) {
                result.push(locAttribute);
            }
        });
        return result;
    };
    GoogleService.prototype.groupsWithAttributeSaved = function (gid, accountId, locationId, primaryCategory, locationAttributes, googleAttributes) {
        if (googleAttributes === void 0) { googleAttributes = []; }
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var excludedAtributes, groups;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(((_a = googleAttributes) === null || _a === void 0 ? void 0 : _a.length) === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getAttributes([gid], [accountId], [locationId]).toPromise()];
                    case 1:
                        googleAttributes = _b.sent();
                        _b.label = 2;
                    case 2:
                        excludedAtributes = this._getExcludeAtributes(googleAttributes, locationAttributes);
                        groups = this._toAttributeGroups(googleAttributes);
                        this._activeSavedAttributes(groups, locationAttributes);
                        return [2 /*return*/, { groups: groups, googleAttributes: googleAttributes, excludedAtributes: excludedAtributes }];
                }
            });
        });
    };
    GoogleService.prototype.groupsWithAttribute = function (accountId, gid, locationIds) {
        return __awaiter(this, void 0, void 0, function () {
            var googleAttributes, excludedAtributes, groups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAttributes(gid, accountId, locationIds).toPromise()];
                    case 1:
                        googleAttributes = _a.sent();
                        excludedAtributes = [];
                        groups = this._toAttributeGroups(googleAttributes);
                        return [2 /*return*/, { groups: groups, googleAttributes: googleAttributes, excludedAtributes: excludedAtributes }];
                }
            });
        });
    };
    GoogleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleService_Factory() { return new GoogleService(i0.ɵɵinject(i1.HttpClient)); }, token: GoogleService, providedIn: "root" });
    return GoogleService;
}());
export { GoogleService };
