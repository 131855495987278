// dep
import {Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as uuid from 'uuid';

// app
import {LocationEditService} from '../../services/location-edit.service';
import {PriceListItems} from '../../constants/google/price-list-section';

@Component({
  selector: 'app-modal-menu-item',
  templateUrl: './modal-menu-item.component.html',
  styleUrls: ['./modal-menu-item.component.scss']
})
export class ModalMenuItemComponent {

  item: PriceListItems;
  removeItem: boolean;
  nameInvalid: boolean;
  isEdit: boolean;
  form: FormGroup;
  private priceIndex: number;
  private sectionIndex: number;
  private readonly itemIndex: number;

  constructor(
    public dialogRef: MatDialogRef<ModalMenuItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationEditS: LocationEditService,
    private fb: FormBuilder
  ) {
    this.item = {...this.data.item};

    this.sectionIndex = this.data.sectionIndex;
    this.priceIndex = this.data.priceIndex;

    if (this.data.item) {
      this.isEdit = true;
      this.itemIndex = this.data.itemIndex;
    }
    this.initForm();
  }

  apply(): void {
    let units = '0'
    let nanos = 0

    const amount = this.form.value.units.split('.');
    if (amount.length > 0) {
        nanos = Number(amount[amount.length -1]);
        if (nanos > 99) {
          nanos = 0;
          units = amount.join('').replace(/\,/gi, '')
        } else {
          amount.splice(amount.length -1, 1);
          units = amount.join('').replace(/\,/gi, '')
        }
      
    }

    const item: PriceListItems = {
      price: {units, currencyCode: 'USD', nanos},
      labels: [{displayName: this.name.value, description: this.description.value}],
      itemId: uuid.v4()
    };

    let items;
    items = this.locationEditS.locationEdit.priceLists[this.priceIndex].sections[this.sectionIndex].items;
    if (this.isEdit) {
      items[this.itemIndex] = item;
      this.update();
      return;
    }

    if (!items) {
      items = [];
    }

    items.push(item);
    this.update();
  }

  remove(): void {
    this.locationEditS.locationEdit.priceLists[this.priceIndex].sections[this.sectionIndex].items.splice(this.itemIndex);
    this.update();
  }

  async update() : Promise<void> {
    await this.locationEditS.setAndUpdate().toPromise()
    this.dialogRef.close();
  }


  initForm() {
    if (this.isEdit) {
      this.form = this.fb.group({
        name: [this.item.labels[0].displayName, Validators.required],
        units: [this.item?.price?.units , [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]],
        description: [this.item?.labels[0].description],
        currency: [this.item?.price?.currencyCode, Validators.required],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        units: [0 , [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]],
        description: [''],
        currency: ['USD'],
      });
    }
  }


  get name() {
    return this.form.get('name');
  }

  get units() {
    return this.form.get('units');
  }

  get description() {
    return this.form.get('description');
  }

  get currency() {
    return this.form.get('currency');
  }

}

