import { __extends, __read, __spread } from "tslib";
import { AlertType } from './../../../components/posts-errors';
import { OnInit, OnDestroy, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators, } from '@angular/forms';
import { MatDrawer } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import moment from 'moment';
import { PostService } from 'src/app/services/post.service';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from './../../../services/modal.service';
import { StorageImageService } from './../../../services/storage-image.service';
import { DatesService } from 'src/app/services/dates.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';
var PostManegementSliderComponent = /** @class */ (function (_super) {
    __extends(PostManegementSliderComponent, _super);
    function PostManegementSliderComponent(_dateS, _postS, _sessionS, _accountS, _storageImgService, _modalService) {
        var _this = _super.call(this) || this;
        _this._dateS = _dateS;
        _this._postS = _postS;
        _this._sessionS = _sessionS;
        _this._accountS = _accountS;
        _this._storageImgService = _storageImgService;
        _this._modalService = _modalService;
        _this.isSliderOpened = false;
        _this.postToEdit = {};
        _this.isBulk = true;
        _this.location = {};
        _this.closeSlider = new EventEmitter();
        _this.isEdit = false;
        _this.step = 0;
        _this.accountsSelected = [];
        _this.locationsSelected = [];
        _this.isDraft = false;
        _this.cancelText = {
            title: 'You have unsaved changes',
            message: 'If you close and leave this view, you will lose any unsaved changes.'
        };
        _this.stepTitle = [
            '1. Select Locations',
            '2. Add Post Details',
            '3. When to Publish',
            '4. Confirmation'
        ];
        _this.hoursList = [];
        _this.daysList = [];
        _this.nameOfDaysOptions = [
            { value: [0], displayName: 'Sunday' },
            { value: [1], displayName: 'Monday' },
            { value: [2], displayName: 'Tuesday' },
            { value: [3], displayName: 'Wednesday' },
            { value: [4], displayName: 'Thursday' },
            { value: [5], displayName: 'Friday' },
            { value: [6], displayName: 'Saturday' }
        ];
        _this.imageSelectedUrl = null;
        _this.imageRequirements = {
            type: ['image/png', 'image/jpg'],
            min_width: 400,
            min_height: 300,
            min_size: 10240,
            max_size: 5000000
        };
        _this.isLoading = false;
        _this.actionsUrl = [];
        _this.actionOptions = [
            { value: 'NONE', displayName: 'None' },
            { value: 'BOOK', displayName: 'Book' },
            { value: 'ORDER', displayName: 'Order online' },
            { value: 'SHOP', displayName: 'Buy' },
            { value: 'LEARN_MORE', displayName: 'Learn More' },
            { value: 'SIGN_UP', displayName: 'Sign Up' },
            { value: 'CALL', displayName: 'Call Now' }
        ];
        _this.dataForLastStep = {};
        _this.mediaUrlArray = [];
        _this.selectedUrl = '';
        _this.selectedFileIndex = 0;
        _this.swiperConfig = {
            slidesPerView: 4,
            initialSlide: 0,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            simulateTouch: true
        };
        _this.dataIsBeingVerified = false;
        _this.isCorrectData = false;
        _this.fieldsWithError = [];
        _this.schedulePreviewData = [];
        _this._isUpdating = false;
        _this._subscriptions = [];
        return _this;
    }
    PostManegementSliderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initData();
        this._subscribeSafe(this._storageImgService.multipleMediaUrl$, // multiple media subscription
        function (urlsArray) {
            if (urlsArray && urlsArray.length > 0) {
                _this.mediaUrlArray = __spread(urlsArray).filter(function (url) { return !url.error; });
            }
            else {
                _this.mediaUrlArray = [];
            }
            // this.isUploading = false;
        }, function (e) {
            console.error(e);
            _this._modalService.openErrorModal('Upload Error', "Error uploading the image, try again");
            // this.isUploading = false;
        });
    };
    PostManegementSliderComponent.prototype.initData = function () {
        var _this = this;
        var _a, _b, _c;
        // const currentDate = new Date();
        // const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        this.hoursList = this._dateS.loadHoursDropdown(true, true);
        this.hoursListOptions = (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('eventTimeStart')) === null || _b === void 0 ? void 0 : _b.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
        this.daysList = __spread([
            { value: 1, displayName: 'First day of the month' },
            { value: 15, displayName: '15th day of the month' },
            { value: -1, displayName: 'Last day of the month' }
        ], this._postS.generateOrdinalDays(2, 28));
        this.isDraft = this.isEdit && ((_c = this.postToEdit) === null || _c === void 0 ? void 0 : _c.status) === 'DRAFT';
        this.initForm();
    };
    PostManegementSliderComponent.prototype.initForm = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102, _103, _104, _105, _106, _107, _108, _109, _110, _111, _112, _113, _114, _115, _116, _117, _118, _119, _120, _121, _122, _123, _124, _125, _126, _127, _128, _129, _130, _131, _132, _133, _134, _135, _136, _137, _138, _139, _140;
        //first Step
        if (this.isEdit) {
            var accounts = this._accountS.buildToggleLocationAccounts((_a = this.postToEdit) === null || _a === void 0 ? void 0 : _a.locations);
            this.postToEdit.accounts = accounts;
            this.accountsSelected = accounts;
            if (((_d = (_c = (_b = this.postToEdit) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.media) === null || _d === void 0 ? void 0 : _d.length) > 0) {
                (_f = (_e = this.postToEdit) === null || _e === void 0 ? void 0 : _e.post) === null || _f === void 0 ? void 0 : _f.media.forEach(function (el) {
                    var _a, _b;
                    _this.mediaUrlArray.push({
                        url: (_a = el) === null || _a === void 0 ? void 0 : _a.sourceUrl,
                        category: (_b = el) === null || _b === void 0 ? void 0 : _b.mediaFormat
                    });
                });
                this._storageImgService.setMultipleMediaUrl(this.mediaUrlArray);
            }
        }
        if (!this.isBulk) {
            this.accountsSelected = this.location;
        }
        this.form = new FormGroup({
            postType: new FormControl(((_h = (_g = this.postToEdit) === null || _g === void 0 ? void 0 : _g.post) === null || _h === void 0 ? void 0 : _h.topicType) || null, [Validators.required]),
            cadence: new FormControl(((_k = (_j = this.postToEdit) === null || _j === void 0 ? void 0 : _j.scheduleRules) === null || _k === void 0 ? void 0 : _k.type) || null, [Validators.required]),
            schedulePublish: new FormControl(((_m = (_l = this.postToEdit) === null || _l === void 0 ? void 0 : _l.scheduleRules) === null || _m === void 0 ? void 0 : _m.type) != "INSTANT" ? null :
                ((_r = (_q = (_p = (_o = this.postToEdit) === null || _o === void 0 ? void 0 : _o.scheduleRules) === null || _p === void 0 ? void 0 : _p.userInput) === null || _q === void 0 ? void 0 : _q.firstPublish) === null || _r === void 0 ? void 0 : _r.publish_now) ? 'publish_immediately' : 'custom'),
            postStartDate: new FormControl(((_t = (_s = this.postToEdit) === null || _s === void 0 ? void 0 : _s.scheduleRules) === null || _t === void 0 ? void 0 : _t.type) != "INSTANT" ? null :
                ((_x = (_w = (_v = (_u = this.postToEdit) === null || _u === void 0 ? void 0 : _u.scheduleRules) === null || _v === void 0 ? void 0 : _v.userInput) === null || _w === void 0 ? void 0 : _w.firstPublish) === null || _x === void 0 ? void 0 : _x.publish_now) ? null : this.getFormDate((_1 = (_0 = (_z = (_y = this.postToEdit) === null || _y === void 0 ? void 0 : _y.scheduleRules) === null || _z === void 0 ? void 0 : _z.userInput) === null || _0 === void 0 ? void 0 : _0.firstPublish) === null || _1 === void 0 ? void 0 : _1.date)),
            repeatsOnMonthly: new FormControl(((_3 = (_2 = this.postToEdit) === null || _2 === void 0 ? void 0 : _2.scheduleRules) === null || _3 === void 0 ? void 0 : _3.type) != 'MONTHLY' || !((_6 = (_5 = (_4 = this.postToEdit) === null || _4 === void 0 ? void 0 : _4.scheduleRules) === null || _5 === void 0 ? void 0 : _5.publishRule) === null || _6 === void 0 ? void 0 : _6.daysOfMonth) ? null :
                ((_10 = (_9 = (_8 = (_7 = this.postToEdit) === null || _7 === void 0 ? void 0 : _7.scheduleRules) === null || _8 === void 0 ? void 0 : _8.publishRule) === null || _9 === void 0 ? void 0 : _9.daysOfMonth) === null || _10 === void 0 ? void 0 : _10.includes(1)) || ((_11 = this.postToEdit.scheduleRules.publishRule.daysOfMonth) === null || _11 === void 0 ? void 0 : _11.includes(15)) || ((_12 = this.postToEdit.scheduleRules.publishRule.daysOfMonth) === null || _12 === void 0 ? void 0 : _12.includes(-1)) ? (_16 = (_15 = (_14 = (_13 = this.postToEdit) === null || _13 === void 0 ? void 0 : _13.scheduleRules) === null || _14 === void 0 ? void 0 : _14.publishRule) === null || _15 === void 0 ? void 0 : _15.daysOfMonth) === null || _16 === void 0 ? void 0 : _16[0] : 'custom'),
            repeatsOnDay: new FormControl(((_19 = (_18 = (_17 = this.postToEdit) === null || _17 === void 0 ? void 0 : _17.scheduleRules) === null || _18 === void 0 ? void 0 : _18.publishRule) === null || _19 === void 0 ? void 0 : _19.daysOfMonth) ? (_21 = (_20 = this.daysList) === null || _20 === void 0 ? void 0 : _20.find(function (el) { var _a, _b, _c, _d, _e; return ((_a = el) === null || _a === void 0 ? void 0 : _a.value) == ((_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.scheduleRules) === null || _c === void 0 ? void 0 : _c.publishRule) === null || _d === void 0 ? void 0 : _d.daysOfMonth) === null || _e === void 0 ? void 0 : _e[0]); })) === null || _21 === void 0 ? void 0 : _21.value :
                null),
            repeatsOnWeekly: new FormControl(((_23 = (_22 = this.postToEdit) === null || _22 === void 0 ? void 0 : _22.scheduleRules) === null || _23 === void 0 ? void 0 : _23.type) == 'WEEKLY' && ((_26 = (_25 = (_24 = this.postToEdit) === null || _24 === void 0 ? void 0 : _24.scheduleRules) === null || _25 === void 0 ? void 0 : _25.publishRule) === null || _26 === void 0 ? void 0 : _26.daysOfWeek) ? (_28 = (_27 = this.nameOfDaysOptions) === null || _27 === void 0 ? void 0 : _27.find(function (el) { var _a; return el.value == ((_a = _this.postToEdit.scheduleRules.publishRule.daysOfWeek) === null || _a === void 0 ? void 0 : _a[0]); })) === null || _28 === void 0 ? void 0 : _28.value : []),
            publishDay: new FormControl(((_30 = (_29 = this.postToEdit) === null || _29 === void 0 ? void 0 : _29.scheduleRules) === null || _30 === void 0 ? void 0 : _30.type) == 'DAILY' ? (_33 = (_32 = (_31 = this.postToEdit) === null || _31 === void 0 ? void 0 : _31.scheduleRules) === null || _32 === void 0 ? void 0 : _32.publishRule) === null || _33 === void 0 ? void 0 : _33.daysOfWeek : []),
            publishTime: new FormControl(this._dateS.hours24To12((_36 = (_35 = (_34 = this.postToEdit) === null || _34 === void 0 ? void 0 : _34.scheduleRules) === null || _35 === void 0 ? void 0 : _35.publishRule) === null || _36 === void 0 ? void 0 : _36.time) || null),
            unpublishTime: new FormControl(this._dateS.hours24To12((_39 = (_38 = (_37 = this.postToEdit) === null || _37 === void 0 ? void 0 : _37.scheduleRules) === null || _38 === void 0 ? void 0 : _38.unpublishRule) === null || _39 === void 0 ? void 0 : _39.time) || '11:59 PM'),
            matchScheduleDate: new FormControl(this.isEdit ? (_43 = (_42 = (_41 = (_40 = this.postToEdit) === null || _40 === void 0 ? void 0 : _40.scheduleRules) === null || _41 === void 0 ? void 0 : _41.userInput) === null || _42 === void 0 ? void 0 : _42.schedule) === null || _43 === void 0 ? void 0 : _43.matchEventDates : true),
            deleteTime: new FormControl(this._dateS.hours24To12((_48 = (_47 = (_46 = (_45 = (_44 = this.postToEdit) === null || _44 === void 0 ? void 0 : _44.scheduleRules) === null || _45 === void 0 ? void 0 : _45.userInput) === null || _46 === void 0 ? void 0 : _46.schedule) === null || _47 === void 0 ? void 0 : _47.end) === null || _48 === void 0 ? void 0 : _48.time) || null),
            deleteDate: new FormControl(((_50 = (_49 = this.postToEdit) === null || _49 === void 0 ? void 0 : _49.scheduleRules) === null || _50 === void 0 ? void 0 : _50.type) != "INSTANT" ? null :
                !((_55 = (_54 = (_53 = (_52 = (_51 = this.postToEdit) === null || _51 === void 0 ? void 0 : _51.scheduleRules) === null || _52 === void 0 ? void 0 : _52.userInput) === null || _53 === void 0 ? void 0 : _53.schedule) === null || _54 === void 0 ? void 0 : _54.end) === null || _55 === void 0 ? void 0 : _55.date) ? null : this.getFormDate(this.postToEdit.scheduleRules.userInput.schedule.end.date)),
            endsOn: new FormControl(!((_56 = Object.keys(this.postToEdit)) === null || _56 === void 0 ? void 0 : _56.length) ? null :
                ((_58 = (_57 = this.postToEdit) === null || _57 === void 0 ? void 0 : _57.scheduleRules) === null || _58 === void 0 ? void 0 : _58.interval) ? 'custom_occurrences' :
                    ((_60 = (_59 = this.postToEdit) === null || _59 === void 0 ? void 0 : _59.scheduleRules) === null || _60 === void 0 ? void 0 : _60.endDate) ? 'custom_date' :
                        ((_64 = (_63 = (_62 = (_61 = this.postToEdit) === null || _61 === void 0 ? void 0 : _61.scheduleRules) === null || _62 === void 0 ? void 0 : _62.userInput) === null || _63 === void 0 ? void 0 : _63.schedule) === null || _64 === void 0 ? void 0 : _64.type) != "INSTANT" ? 'never' : null),
            endsOnCustomDate: new FormControl(this.getFormDate((_69 = (_68 = (_67 = (_66 = (_65 = this.postToEdit) === null || _65 === void 0 ? void 0 : _65.scheduleRules) === null || _66 === void 0 ? void 0 : _66.userInput) === null || _67 === void 0 ? void 0 : _67.schedule) === null || _68 === void 0 ? void 0 : _68.end) === null || _69 === void 0 ? void 0 : _69.date) || null),
            endsOnAfterOcurrences: new FormControl(((_74 = (_73 = (_72 = (_71 = (_70 = this.postToEdit) === null || _70 === void 0 ? void 0 : _70.scheduleRules) === null || _71 === void 0 ? void 0 : _71.userInput) === null || _72 === void 0 ? void 0 : _72.schedule) === null || _73 === void 0 ? void 0 : _73.end) === null || _74 === void 0 ? void 0 : _74.limit_occurrences) || null),
            firstPublish: new FormControl(((_78 = (_77 = (_76 = (_75 = this.postToEdit) === null || _75 === void 0 ? void 0 : _75.scheduleRules) === null || _76 === void 0 ? void 0 : _76.userInput) === null || _77 === void 0 ? void 0 : _77.firstPublish) === null || _78 === void 0 ? void 0 : _78.publish_now) ? "post_immediately" :
                ((_82 = (_81 = (_80 = (_79 = this.postToEdit) === null || _79 === void 0 ? void 0 : _79.scheduleRules) === null || _80 === void 0 ? void 0 : _80.userInput) === null || _81 === void 0 ? void 0 : _81.firstPublish) === null || _82 === void 0 ? void 0 : _82.publish_next_occurrence) ? "next_occurrence" :
                    ((_86 = (_85 = (_84 = (_83 = this.postToEdit) === null || _83 === void 0 ? void 0 : _83.scheduleRules) === null || _84 === void 0 ? void 0 : _84.userInput) === null || _85 === void 0 ? void 0 : _85.firstPublish) === null || _86 === void 0 ? void 0 : _86.date) ? "custom" : null),
            firstPublishDate: new FormControl(this.getFormDate((_90 = (_89 = (_88 = (_87 = this.postToEdit) === null || _87 === void 0 ? void 0 : _87.scheduleRules) === null || _88 === void 0 ? void 0 : _88.userInput) === null || _89 === void 0 ? void 0 : _89.firstPublish) === null || _90 === void 0 ? void 0 : _90.date) || null),
            title: new FormControl((_92 = (_91 = this.postToEdit) === null || _91 === void 0 ? void 0 : _91.post) === null || _92 === void 0 ? void 0 : _92.title),
            summary: new FormControl(((_94 = (_93 = this.postToEdit) === null || _93 === void 0 ? void 0 : _93.post) === null || _94 === void 0 ? void 0 : _94.summary) || null),
            actionType: new FormControl(((_97 = (_96 = (_95 = this.postToEdit) === null || _95 === void 0 ? void 0 : _95.post) === null || _96 === void 0 ? void 0 : _96.callToAction) === null || _97 === void 0 ? void 0 : _97.actionType) || 'NONE'),
            actionUrl: new FormControl(this.getActionUrl()),
            instantEventDatesStart: new FormControl(this.getFormDate((_101 = (_100 = (_99 = (_98 = this.postToEdit) === null || _98 === void 0 ? void 0 : _98.post) === null || _99 === void 0 ? void 0 : _99.eventSchedule) === null || _100 === void 0 ? void 0 : _100.publishRule) === null || _101 === void 0 ? void 0 : _101.date) || null),
            instantEventDatesEnd: new FormControl(this.getFormDate((_105 = (_104 = (_103 = (_102 = this.postToEdit) === null || _102 === void 0 ? void 0 : _102.post) === null || _103 === void 0 ? void 0 : _103.eventSchedule) === null || _104 === void 0 ? void 0 : _104.deleteRule) === null || _105 === void 0 ? void 0 : _105.date) || null),
            eventTimeStart: new FormControl(this._dateS.hours24To12((_109 = (_108 = (_107 = (_106 = this.postToEdit) === null || _106 === void 0 ? void 0 : _106.post) === null || _107 === void 0 ? void 0 : _107.eventSchedule) === null || _108 === void 0 ? void 0 : _108.publishRule) === null || _109 === void 0 ? void 0 : _109.time)),
            eventTimeEnd: new FormControl(this._dateS.hours24To12((_113 = (_112 = (_111 = (_110 = this.postToEdit) === null || _110 === void 0 ? void 0 : _110.post) === null || _111 === void 0 ? void 0 : _111.eventSchedule) === null || _112 === void 0 ? void 0 : _112.deleteRule) === null || _113 === void 0 ? void 0 : _113.time) || null),
            weeklyEventsDates: new FormControl(!((_114 = this.postToEdit) === null || _114 === void 0 ? void 0 : _114.post) ? null :
                ((_118 = (_117 = (_116 = (_115 = this.postToEdit) === null || _115 === void 0 ? void 0 : _115.post) === null || _116 === void 0 ? void 0 : _116.eventSchedule) === null || _117 === void 0 ? void 0 : _117.publishRule) === null || _118 === void 0 ? void 0 : _118.matchScheduleDate) && ((_122 = (_121 = (_120 = (_119 = this.postToEdit) === null || _119 === void 0 ? void 0 : _119.post) === null || _120 === void 0 ? void 0 : _120.eventSchedule) === null || _121 === void 0 ? void 0 : _121.publishRule) === null || _122 === void 0 ? void 0 : _122.matchScheduleDate) ?
                    'corresponds_publish_date' : 'custom'),
            eventDatesStartWeekly: new FormControl(((_124 = (_123 = this.nameOfDaysOptions) === null || _123 === void 0 ? void 0 : _123.find(function (el) { var _a, _b, _c, _d, _e, _f, _g; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.includes((_g = (_f = (_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.scheduleRules) === null || _c === void 0 ? void 0 : _c.userInput) === null || _d === void 0 ? void 0 : _d.schedule) === null || _e === void 0 ? void 0 : _e.publishRule) === null || _f === void 0 ? void 0 : _f.daysOfWeek) === null || _g === void 0 ? void 0 : _g[0]); })) === null || _124 === void 0 ? void 0 : _124.value) || null),
            eventDatesEndWeekly: new FormControl(((_126 = (_125 = this.nameOfDaysOptions) === null || _125 === void 0 ? void 0 : _125.find(function (el) { var _a, _b, _c, _d, _e, _f; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.includes((_f = (_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.eventSchedule) === null || _d === void 0 ? void 0 : _d.deleteRule) === null || _e === void 0 ? void 0 : _e.daysOfWeek) === null || _f === void 0 ? void 0 : _f[0]); })) === null || _126 === void 0 ? void 0 : _126.value) || null),
            eventDatesStartMonthly: new FormControl(((_128 = (_127 = this.daysList) === null || _127 === void 0 ? void 0 : _127.find(function (el) { var _a, _b, _c, _d, _e, _f, _g; return ((_a = el) === null || _a === void 0 ? void 0 : _a.value) == ((_g = (_f = (_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.scheduleRules) === null || _c === void 0 ? void 0 : _c.userInput) === null || _d === void 0 ? void 0 : _d.schedule) === null || _e === void 0 ? void 0 : _e.publishRule) === null || _f === void 0 ? void 0 : _f.daysOfMonth) === null || _g === void 0 ? void 0 : _g[0]); })) === null || _128 === void 0 ? void 0 : _128.value) || 1),
            eventDatesEndMonthly: new FormControl(((_130 = (_129 = this.daysList) === null || _129 === void 0 ? void 0 : _129.find(function (el) { var _a, _b, _c, _d, _e, _f; return ((_a = el) === null || _a === void 0 ? void 0 : _a.value) == ((_f = (_e = (_d = (_c = (_b = _this.postToEdit) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.eventSchedule) === null || _d === void 0 ? void 0 : _d.deleteRule) === null || _e === void 0 ? void 0 : _e.daysOfMonth) === null || _f === void 0 ? void 0 : _f[0]); })) === null || _130 === void 0 ? void 0 : _130.value) || -1),
            couponCode: new FormControl(((_133 = (_132 = (_131 = this.postToEdit) === null || _131 === void 0 ? void 0 : _131.post) === null || _132 === void 0 ? void 0 : _132.offer) === null || _133 === void 0 ? void 0 : _133.couponCode) || null),
            linkToRedeem: new FormControl(((_136 = (_135 = (_134 = this.postToEdit) === null || _134 === void 0 ? void 0 : _134.post) === null || _135 === void 0 ? void 0 : _135.offer) === null || _136 === void 0 ? void 0 : _136.redeemOnlineUrl) || null),
            termsAndCondition: new FormControl(((_139 = (_138 = (_137 = this.postToEdit) === null || _137 === void 0 ? void 0 : _137.post) === null || _138 === void 0 ? void 0 : _138.offer) === null || _139 === void 0 ? void 0 : _139.termsConditions) || null),
        });
        if ((_140 = this.cadence) === null || _140 === void 0 ? void 0 : _140.value) {
            var cadenceSub = this.cadence.valueChanges.subscribe(function () {
                _this.addDynamicValidations();
            });
            this._subscriptions.push(cadenceSub);
        }
        this.addDynamicValidations();
    };
    PostManegementSliderComponent.prototype.getActionUrl = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        return (!((_c = (_b = (_a = this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.callToAction) === null || _c === void 0 ? void 0 : _c.url) ? null :
            ((_e = (_d = this.actionsUrl) === null || _d === void 0 ? void 0 : _d.find(function (el) { var _a, _b, _c; return el.key == ((_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.callToAction) === null || _c === void 0 ? void 0 : _c.url); })) === null || _e === void 0 ? void 0 : _e.value) ? (_g = (_f = this.actionsUrl) === null || _f === void 0 ? void 0 : _f.find(function (el) { var _a, _b, _c; return el.key == ((_c = (_b = (_a = _this.postToEdit) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.callToAction) === null || _c === void 0 ? void 0 : _c.url); })) === null || _g === void 0 ? void 0 : _g.value :
                this.postToEdit.post.callToAction.url);
    };
    PostManegementSliderComponent.prototype.getActionLinks = function () {
        var _this = this;
        var _a, _b, _c;
        this.actionsUrl = [];
        if (!((_a = this.actionType) === null || _a === void 0 ? void 0 : _a.value) || ((_b = this.actionType) === null || _b === void 0 ? void 0 : _b.value) === 'CALL' || ((_c = this.actionType) === null || _c === void 0 ? void 0 : _c.value) === 'NONE') {
            this.actionUrl.setValue(null);
            return;
        }
        this.isLoading = true;
        var accounts = this.accountsSelected.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (loc) { return loc.name = loc.locationName; });
        });
        this._postS.getActionLinks(this.accountsSelected).subscribe(function (res) {
            var _a, _b;
            if ((_a = res) === null || _a === void 0 ? void 0 : _a.data) {
                _this.actionsUrl = (_b = res) === null || _b === void 0 ? void 0 : _b.data;
                if (_this.isEdit) {
                    _this.actionUrl.setValue(_this.getActionUrl());
                }
                _this.isLoading = false;
            }
        }, function (err) {
            _this.isLoading = false;
        });
    };
    PostManegementSliderComponent.prototype.clearActionUrl = function () {
        this.actionUrl.setValue(null);
    };
    PostManegementSliderComponent.prototype.validateInput = function (event) {
        var allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'A', 'P', 'M', ':', 'BACKSPACE', ' ', 'TAB', 'ARROWLEFT', 'ARROWRIGHT', 'DELETE'];
        var key = event.key.toUpperCase();
        if (!allowedKeys.includes(key)) {
            event.preventDefault();
        }
    };
    PostManegementSliderComponent.prototype.convertToUppercase = function (event, field) {
        var _a;
        var inputElement = event.target;
        var uppercasedValue = inputElement.value.toUpperCase();
        inputElement.value = uppercasedValue;
        (_a = this.form.get(field)) === null || _a === void 0 ? void 0 : _a.setValue(uppercasedValue, { emitEvent: false });
    };
    PostManegementSliderComponent.prototype.formatTime = function (event, field) {
        var _a;
        var inputElement = event.target;
        var value = inputElement.value.toUpperCase();
        // Remove invalid characters
        value = value.replace(/[^0-9APM:]/g, '');
        // Match the input against "hh:mm AM/PM" or "hh:mm" without AM/PM specified
        var match = value.match(/^(\d{1,2}):?(\d{0,2})\s?(AM|PM)?$/);
        if (match) {
            var hours = match[1].padStart(2, '0'); // Ensure hours have 2 digits
            var minutes = (match[2] || '00').padStart(2, '0'); // Ensure minutes have 2 digits
            var period = match[3] || ''; // May be empty or "AM"/"PM"
            // Limit hours to the range 0-23 (for initial input validation)
            hours = Math.min(parseInt(hours, 10), 23).toString().padStart(2, '0');
            // Limit minutes to the range 0-59
            minutes = Math.min(parseInt(minutes, 10), 59).toString().padStart(2, '0');
            // Convert 24-hour format to 12-hour format
            if (parseInt(hours, 10) >= 13) {
                if (parseInt(hours, 10) > 12) {
                    // Subtract 12 to convert 24-hour to 12-hour
                    hours = (parseInt(hours, 10) - 12).toString().padStart(2, '0');
                }
                period = 'PM';
            }
            else if (parseInt(hours, 10) === 0) {
                // Convert "00" to "12 AM"
                hours = '12';
                period = 'AM';
            }
            else if (!period) {
                // Default to "AM" if no period is provided
                period = 'AM';
            }
            // Rebuild the formatted value in "hh:mm AM/PM" format
            value = hours + ":" + minutes + " " + period;
        }
        else {
            value = ''; // Clear the value if it doesn't match the expected format
        }
        inputElement.value = value;
        (_a = this.form.get(field)) === null || _a === void 0 ? void 0 : _a.setValue(value, { emitEvent: false });
    };
    PostManegementSliderComponent.prototype.applyDynamicValidation = function (condition, targetControl, referenceControl, alwaysRequired, hasvalidateEndTimeSameDay, validateEndTimeSameDay) {
        if (this._isUpdating)
            return;
        this._isUpdating = true;
        var newValidators = [
            alwaysRequired() || referenceControl ? Validators.required : null,
            hasvalidateEndTimeSameDay() ? validateEndTimeSameDay() : null
        ].filter(Boolean);
        if (targetControl) {
            targetControl.setValidators(!condition ? null : Validators.compose(newValidators));
            targetControl.updateValueAndValidity({ emitEvent: false });
        }
        this._isUpdating = false;
    };
    PostManegementSliderComponent.prototype.addDynamicValidations = function () {
        var _this = this;
        this._subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        this._subscriptions = [];
        var validations = [
            {
                condition: function () { var _a, _b; return ((_a = _this.postType) === null || _a === void 0 ? void 0 : _a.value) !== 'STANDARD' && ((_b = _this.cadence) === null || _b === void 0 ? void 0 : _b.value) === 'INSTANT'; },
                fieldsToWatch: ['instantEventDatesStart', 'eventTimeStart', 'instantEventDatesEnd'],
                targetControl: this.eventTimeEnd,
                referenceControl: this.eventTimeStart,
                alwaysRequired: function () { return false; },
                hasvalidateEndTimeSameDay: function () { return true; },
                validateEndTimeSameDay: function () { return _this._validateEndTimeSameDay('eventTimeStart', 'eventTimeEnd'); },
            },
            {
                condition: function () { var _a; return ((_a = _this.postType) === null || _a === void 0 ? void 0 : _a.value) !== 'STANDARD'; },
                fieldsToWatch: ['eventTimeStart', 'eventTimeEnd'],
                targetControl: this.eventTimeEnd,
                referenceControl: this.eventTimeStart,
                alwaysRequired: function () { return false; },
                hasvalidateEndTimeSameDay: function () { var _a; return ((_a = _this.cadence) === null || _a === void 0 ? void 0 : _a.value) === 'DAILY'; },
                validateEndTimeSameDay: function () { return _this._validateEndTimeSameDay('eventTimeStart', 'eventTimeEnd'); },
            },
            {
                condition: function () { var _a; return _this.step === 2 && ((_a = _this.cadence) === null || _a === void 0 ? void 0 : _a.value) === 'INSTANT'; },
                fieldsToWatch: ['deleteTime', 'publishTime', 'deleteDate', 'postStartDate'],
                targetControl: this.deleteTime,
                referenceControl: null,
                alwaysRequired: function () { return false; },
                hasvalidateEndTimeSameDay: function () { return true; },
                validateEndTimeSameDay: function () { return _this._validateEndTimeSameDay('publishTime', 'deleteTime'); },
            },
            {
                condition: function () { var _a, _b; return ((_a = _this.postType) === null || _a === void 0 ? void 0 : _a.value) === 'STANDARD' || (_this.step === 2 && ((_b = _this.cadence) === null || _b === void 0 ? void 0 : _b.value) !== 'INSTANT'); },
                fieldsToWatch: ['postStartDate', 'firstPublishDate'],
                targetControl: this.publishTime,
                referenceControl: function () { var _a; return (((_a = _this.postType) === null || _a === void 0 ? void 0 : _a.value) === 'STANDARD' ? _this.postStartDate : null); },
                alwaysRequired: function () { var _a; return _this.step === 2 && ((_a = _this.cadence) === null || _a === void 0 ? void 0 : _a.value) !== 'INSTANT'; },
                hasvalidateEndTimeSameDay: function () { return false; },
            },
        ];
        validations.forEach(function (_a) {
            var condition = _a.condition, fieldsToWatch = _a.fieldsToWatch, targetControl = _a.targetControl, referenceControl = _a.referenceControl, alwaysRequired = _a.alwaysRequired, hasvalidateEndTimeSameDay = _a.hasvalidateEndTimeSameDay, validateEndTimeSameDay = _a.validateEndTimeSameDay;
            fieldsToWatch.forEach(function (fieldToWatch) {
                var control = _this.form.get(fieldToWatch);
                if (control) {
                    var sub = control.valueChanges.subscribe(function () {
                        var _a;
                        _this.applyDynamicValidation(condition(), targetControl, (_a = referenceControl) === null || _a === void 0 ? void 0 : _a.value, alwaysRequired, hasvalidateEndTimeSameDay, validateEndTimeSameDay);
                    });
                    _this._subscriptions.push(sub);
                }
            });
        });
    };
    PostManegementSliderComponent.prototype.addValidationsStepTwo = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        this.removeAllValidations();
        if ((_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value) {
            this.form.controls.summary.setValidators([Validators.required]);
            (_b = this.title) === null || _b === void 0 ? void 0 : _b.setValidators(((_c = this.postType) === null || _c === void 0 ? void 0 : _c.value) !== 'STANDARD' ? [Validators.required] : null);
            if (((_d = this.cadence) === null || _d === void 0 ? void 0 : _d.value) == 'INSTANT') {
                if (((_e = this.postType) === null || _e === void 0 ? void 0 : _e.value) !== 'STANDARD') {
                    this.form.controls.instantEventDatesEnd.setValidators([
                        Validators.required,
                        this._validateStartBeforeEnd('instantEventDatesStart', 'instantEventDatesEnd')
                    ]);
                    this.form.controls.instantEventDatesStart.setValidators([Validators.required]);
                    this.form.controls.instantEventDatesEnd.markAsTouched();
                }
            }
            else {
                switch ((_f = this.cadence) === null || _f === void 0 ? void 0 : _f.value) {
                    case 'DAILY':
                        if (((_g = this.postType) === null || _g === void 0 ? void 0 : _g.value) !== 'STANDARD') {
                            this.setTimeHours();
                            (_h = this.publishDay) === null || _h === void 0 ? void 0 : _h.setValidators(Validators.required);
                        }
                        break;
                    case 'WEEKLY':
                        if (((_j = this.postType) === null || _j === void 0 ? void 0 : _j.value) !== 'STANDARD') {
                            (_k = this.eventDatesStartWeekly) === null || _k === void 0 ? void 0 : _k.setValidators(Validators.required);
                            (_l = this.eventDatesEndWeekly) === null || _l === void 0 ? void 0 : _l.setValidators(Validators.required);
                        }
                        break;
                    case 'MONTHLY':
                        (_m = this.eventDatesStartMonthly) === null || _m === void 0 ? void 0 : _m.setValidators(Validators.required);
                        (_o = this.eventDatesEndMonthly) === null || _o === void 0 ? void 0 : _o.setValidators(Validators.required);
                        break;
                }
            }
            this.updateValueAndValidity();
        }
    };
    PostManegementSliderComponent.prototype.addFormFieldsStepThree = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        this.removeAllValidations();
        if (((_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value) !== 'INSTANT') {
            (_b = this.endsOn) === null || _b === void 0 ? void 0 : _b.setValidators(Validators.required);
            (_c = this.endsOnCustomDate) === null || _c === void 0 ? void 0 : _c.setValidators(this.isRequired((_d = this.endsOn) === null || _d === void 0 ? void 0 : _d.value, 'custom_date') ? [Validators.required] : null);
            (_e = this.endsOnAfterOcurrences) === null || _e === void 0 ? void 0 : _e.setValidators(this.isRequired((_f = this.endsOn) === null || _f === void 0 ? void 0 : _f.value, 'custom_occurrences') ? [Validators.required] : null);
        }
        else {
            (_g = this.deleteDate) === null || _g === void 0 ? void 0 : _g.setValidators([this._validateStartBeforeEnd('postStartDate', 'deleteDate')]);
        }
        if (((_h = this.postType) === null || _h === void 0 ? void 0 : _h.value) !== 'STANDARD') {
            if (!this.isEdit && ((_j = this.matchScheduleDate) === null || _j === void 0 ? void 0 : _j.value) && ((_k = this.cadence) === null || _k === void 0 ? void 0 : _k.value) !== 'INSTANT') {
                (_l = this.publishTime) === null || _l === void 0 ? void 0 : _l.setValue(((_m = this.eventTimeStart) === null || _m === void 0 ? void 0 : _m.value) || '12:00 AM');
                switch ((_o = this.cadence) === null || _o === void 0 ? void 0 : _o.value) {
                    case 'WEEKLY':
                        (_p = this.repeatsOnWeekly) === null || _p === void 0 ? void 0 : _p.setValue((_q = this.eventDatesStartWeekly) === null || _q === void 0 ? void 0 : _q.value);
                        break;
                    case 'MONTHLY':
                        (_r = this.repeatsOnDay) === null || _r === void 0 ? void 0 : _r.setValue((_s = this.eventDatesStartMonthly) === null || _s === void 0 ? void 0 : _s.value);
                        break;
                }
            }
        }
        else {
            (_t = this.publishDay) === null || _t === void 0 ? void 0 : _t.setValidators(((_u = this.cadence) === null || _u === void 0 ? void 0 : _u.value) === 'DAILY' ? [Validators.required] : null);
            (_v = this.form.get('publishTime')) === null || _v === void 0 ? void 0 : _v.setValidators(((_w = this.cadence) === null || _w === void 0 ? void 0 : _w.value) === 'DAILY' ? [Validators.required] : null);
            (_x = this.repeatsOnWeekly) === null || _x === void 0 ? void 0 : _x.setValidators(((_y = this.cadence) === null || _y === void 0 ? void 0 : _y.value) === 'WEEKLY' ? [Validators.required] : null);
            (_z = this.repeatsOnDay) === null || _z === void 0 ? void 0 : _z.setValidators(((_0 = this.cadence) === null || _0 === void 0 ? void 0 : _0.value) === 'MONTHLY' ? [Validators.required] : null);
        }
        this.updateValueAndValidity();
    };
    PostManegementSliderComponent.prototype.setTimeHours = function () {
        var _a, _b;
        if (this.isEdit) {
            return;
        }
        var start = ((_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value) === 'DAILY' ? '12:00 AM' : null;
        var end = ((_b = this.cadence) === null || _b === void 0 ? void 0 : _b.value) === 'DAILY' ? '11:59 PM' : null;
        this.eventTimeStart.setValue(start);
        this.eventTimeEnd.setValue(end);
    };
    PostManegementSliderComponent.prototype.removeAllValidations = function () {
        var _this = this;
        var keys = Object.keys(this.form.controls);
        keys.forEach(function (k) {
            if (k != 'postType' && k != 'cadence')
                _this.form.controls[k].clearValidators();
        });
        this.updateValueAndValidity();
    };
    PostManegementSliderComponent.prototype.updateValueAndValidity = function () {
        var _this = this;
        var keys = Object.keys(this.form.controls);
        keys.forEach(function (k) {
            _this.form.controls[k].updateValueAndValidity();
        });
    };
    PostManegementSliderComponent.prototype.isRequired = function (field, value) {
        if (value === void 0) { value = null; }
        return !value ? true : field === value;
    };
    PostManegementSliderComponent.prototype.selectDay = function (day) {
        var _a;
        if (this.isEdit && !this.isDraft) {
            return;
        }
        var values = (_a = this.publishDay) === null || _a === void 0 ? void 0 : _a.value;
        var repeteadElement = this.publishDay.value.findIndex(function (el) { return el == day; });
        if (repeteadElement > -1) {
            values.splice(repeteadElement, 1);
        }
        else {
            values.push(day);
        }
        values = values.sort(function (a, b) { return a - b; });
        this.publishDay.setValue(values);
    };
    PostManegementSliderComponent.prototype.parseTime = function (time) {
        var _a, _b, _c, _d, _e, _f;
        if (!time) {
            return null;
        }
        var hours = time.slice(0, 2);
        var parsedTime = this._dateS.hours12To24(time);
        var hour = (_c = (_b = ((_a = parsedTime) === null || _a === void 0 ? void 0 : _a.hours)) === null || _b === void 0 ? void 0 : _b.toString()) === null || _c === void 0 ? void 0 : _c.padStart(2, '0');
        var minutes = (_f = (_e = ((_d = parsedTime) === null || _d === void 0 ? void 0 : _d.minutes)) === null || _e === void 0 ? void 0 : _e.toString()) === null || _f === void 0 ? void 0 : _f.padStart(2, '0');
        return hour + ":" + minutes;
    };
    PostManegementSliderComponent.prototype.getFormDate = function (date) {
        return date ? date + "T00:00:00" : null;
    };
    PostManegementSliderComponent.prototype.getServiceDate = function (date, isYMD) {
        if (isYMD === void 0) { isYMD = true; }
        return (date && isYMD ?
            this._dateS.getStringDateYMD(moment(date)) :
            date && !isYMD ?
                this._dateS.getStringDateMDY(moment(date)) :
                null);
    };
    PostManegementSliderComponent.prototype.getUrlsMedia = function () {
        var urlsArray = [];
        this.mediaUrlArray.forEach(function (el) {
            var _a, _b, _c, _d, _e;
            var format = !((_b = (_a = el) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.includes('/')) ? (_c = el) === null || _c === void 0 ? void 0 : _c.category : ((_d = el) === null || _d === void 0 ? void 0 : _d.category.split('/')[0]) === 'image' ? 'PHOTO' : 'VIDEO';
            urlsArray.push({
                "mediaFormat": format,
                "sourceUrl": (_e = el) === null || _e === void 0 ? void 0 : _e.url
            });
        });
        return urlsArray.length ? urlsArray : null;
    };
    PostManegementSliderComponent.prototype.buildServiceData = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        this.accountsSelected.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (loc) { return loc.name = loc.locationName; });
        });
        return {
            "gid": this._sessionS.getSession().gid,
            "accounts": this.accountsSelected,
            "post": {
                "summary": this.summary.value,
                "callToAction": this.postType.value == 'OFFER' || (!((_a = this.actionType) === null || _a === void 0 ? void 0 : _a.value) || ((_b = this.actionType) === null || _b === void 0 ? void 0 : _b.value) === 'NONE') ? null :
                    {
                        "actionType": (_c = this.actionType) === null || _c === void 0 ? void 0 : _c.value,
                        "url": !((_d = this.actionUrl) === null || _d === void 0 ? void 0 : _d.value) ? null : ((_e = this.actionsUrl.find(function (el) { return el.value == _this.actionUrl.value; })) === null || _e === void 0 ? void 0 : _e.key) ? (_f = this.actionsUrl.find(function (el) { return el.value == _this.actionUrl.value; })) === null || _f === void 0 ? void 0 : _f.key : this.actionUrl.value,
                    },
                "title": this.postType.value !== 'STANDARD' ? this.title.value : null,
                "eventSchedule": this.postType.value == 'STANDARD' ? null : {
                    "publishRule": {
                        "daysOfWeek": (this.cadence.value == 'DAILY' ? this.publishDay.value :
                            this.cadence.value == 'WEEKLY' ? this.eventDatesStartWeekly.value : null),
                        "daysOfMonth": this.cadence.value == 'MONTHLY' && this.eventDatesStartMonthly.value ? [this.eventDatesStartMonthly.value] : null,
                        "time": ((_g = this.eventTimeStart) === null || _g === void 0 ? void 0 : _g.value) ? this.parseTime(this.eventTimeStart.value) : this.parseTime("12:00 AM"),
                        "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesStart.value) : null,
                        "matchScheduleDate": ((_h = this.cadence) === null || _h === void 0 ? void 0 : _h.value) === 'DAILY' ? true : ((_j = this.cadence) === null || _j === void 0 ? void 0 : _j.value) !== 'INSTANT' ? (_k = this.matchScheduleDate) === null || _k === void 0 ? void 0 : _k.value : false
                    },
                    "deleteRule": {
                        "daysOfWeek": (this.cadence.value == 'DAILY' ? this.publishDay.value :
                            this.cadence.value == 'WEEKLY' ? this.eventDatesEndWeekly.value : null),
                        "daysOfMonth": (this.cadence.value == 'MONTHLY' && this.eventDatesEndMonthly.value ? [this.eventDatesEndMonthly.value] : null),
                        "time": ((_l = this.eventTimeEnd) === null || _l === void 0 ? void 0 : _l.value) ? this.parseTime(this.eventTimeEnd.value) : this.parseTime("11:59 PM"),
                        "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesEnd.value) : null,
                        "matchScheduleDate": ((_m = this.cadence) === null || _m === void 0 ? void 0 : _m.value) === 'DAILY' ? true : ((_o = this.cadence) === null || _o === void 0 ? void 0 : _o.value) !== 'INSTANT' ? (_p = this.matchScheduleDate) === null || _p === void 0 ? void 0 : _p.value : false
                    }
                },
                "media": this.getUrlsMedia(),
                "topicType": (_q = this.postType) === null || _q === void 0 ? void 0 : _q.value,
                "offer": this.postType.value !== 'OFFER' || (!((_r = this.form.controls.couponCode) === null || _r === void 0 ? void 0 : _r.value) && !((_s = this.form.controls.linkToRedeem) === null || _s === void 0 ? void 0 : _s.value) && !((_t = this.form.controls.termsAndCondition) === null || _t === void 0 ? void 0 : _t.value)) ? null :
                    {
                        "couponCode": (_u = this.form.controls.couponCode) === null || _u === void 0 ? void 0 : _u.value,
                        "redeemOnlineUrl": (_v = this.form.controls.linkToRedeem) === null || _v === void 0 ? void 0 : _v.value,
                        "termsConditions": (_w = this.form.controls.termsAndCondition) === null || _w === void 0 ? void 0 : _w.value
                    }
            },
            "status": isDraft ? "DRAFT" : "ACTIVE",
            "is_bulk": this.isBulk,
            "is_recurring": this.cadence.value != 'INSTANT' ? true : false,
            "is_scheduled": this.cadence.value != 'INSTANT' || (this.cadence.value == 'INSTANT' && ((_x = this.postStartDate) === null || _x === void 0 ? void 0 : _x.value)) ? true : false,
            "first_publish": {
                "date": (this.cadence.value == 'INSTANT' && ((_y = this.postStartDate) === null || _y === void 0 ? void 0 : _y.value) ? this.getServiceDate(this.postStartDate.value) :
                    this.firstPublishDate.value ? this.getServiceDate(this.firstPublishDate.value) : null),
                "publish_now": (((_z = this.cadence) === null || _z === void 0 ? void 0 : _z.value) == 'INSTANT' && !((_0 = this.postStartDate) === null || _0 === void 0 ? void 0 : _0.value)) ? true : false,
                "publish_next_occurrence": ((_1 = this.cadence) === null || _1 === void 0 ? void 0 : _1.value) !== 'INSTANT' && !((_2 = this.firstPublishDate) === null || _2 === void 0 ? void 0 : _2.value) ? true : false,
            },
            "schedule": {
                "type": this.cadence.value,
                "end": this.endsOn.value != 'custom_date' && this.endsOn.value != 'custom_occurrences' && !this.deleteDate.value ? null : {
                    "date": (this.cadence.value == 'INSTANT' && this.deleteDate.value ? this.getServiceDate(this.deleteDate.value) :
                        this.endsOn.value == 'custom_date' ? this.getServiceDate(this.endsOnCustomDate.value) : null),
                    "limit_occurrences": this.endsOn.value == 'custom_occurrences' ? this.endsOnAfterOcurrences.value : null,
                    "time": this.deleteTime.value ? this.parseTime(this.deleteTime.value) : this.parseTime("11:59 PM")
                },
                "publishRule": this.cadence.value == 'INSTANT' && !((_3 = this.postStartDate) === null || _3 === void 0 ? void 0 : _3.value) ? null : {
                    "daysOfWeek": (this.cadence.value == 'DAILY' ? this.publishDay.value :
                        this.cadence.value == 'WEEKLY' ? this.repeatsOnWeekly.value : null),
                    "daysOfMonth": (this.cadence.value == 'MONTHLY' ? [(_4 = this.eventDatesStartMonthly) === null || _4 === void 0 ? void 0 : _4.value] : null),
                    "time": ((_5 = this.publishTime) === null || _5 === void 0 ? void 0 : _5.value) ? this.parseTime(this.publishTime.value) : this.parseTime("12:00 AM")
                },
                "unpublishRule": this.cadence.value == 'INSTANT' || ((_6 = this.postType) === null || _6 === void 0 ? void 0 : _6.value) === 'STANDARD' ? null : {
                    "daysOfWeek": (this.cadence.value == 'WEEKLY' ? this.eventDatesEndWeekly.value : null),
                    "daysOfMonth": (this.cadence.value == 'MONTHLY' ? [(_7 = this.eventDatesEndMonthly) === null || _7 === void 0 ? void 0 : _7.value] : null),
                    "time": ((_8 = this.eventTimeEnd) === null || _8 === void 0 ? void 0 : _8.value) ? this.parseTime(this.eventTimeEnd.value) : this.parseTime("11:59 PM")
                },
                "matchEventDates": (_9 = this.matchScheduleDate) === null || _9 === void 0 ? void 0 : _9.value
            }
        };
    };
    PostManegementSliderComponent.prototype.completeSteeper = function (isDraft) {
        if (isDraft === void 0) { isDraft = false; }
        this.isLoading = true;
        this.dataIsBeingVerified = true;
        this.isCorrectData = false;
        this.fieldsWithError = [];
        var data = this.buildServiceData(isDraft);
        if (this.isEdit) {
            this.editPost(data);
        }
        else {
            this.createPost(data);
        }
    };
    PostManegementSliderComponent.prototype.createPost = function (data) {
        var _this = this;
        this._postS.createPost(data).subscribe(function (res) {
            _this.isLoading = false;
            _this.dataIsBeingVerified = false;
            _this.drawer.toggle();
            _this.closeSlider.emit(true);
        }, function (err) {
            var _a, _b;
            _this.handleApiError((_b = (_a = err) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.errors);
        });
    };
    PostManegementSliderComponent.prototype.editPost = function (data) {
        var _this = this;
        var _a;
        var postId = (_a = this.postToEdit) === null || _a === void 0 ? void 0 : _a._id;
        this._postS.editPost(data, postId).subscribe(function (res) {
            _this.isLoading = false;
            _this.dataIsBeingVerified = false;
            _this.drawer.toggle();
            _this.closeSlider.emit(true);
        }, function (err) {
            var _a, _b;
            _this.handleApiError((_b = (_a = err) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.errors);
        });
    };
    PostManegementSliderComponent.prototype.handleApiError = function (errors) {
        var _this = this;
        errors.forEach(function (el) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if ((_a = el) === null || _a === void 0 ? void 0 : _a.parameter) {
                var error = (_c = (_b = el) === null || _b === void 0 ? void 0 : _b.displayable_message) === null || _c === void 0 ? void 0 : _c.split('. ')[1];
                var field = (_d = el.parameter) === null || _d === void 0 ? void 0 : _d.split('->');
                var lastPart = (_e = field) === null || _e === void 0 ? void 0 : _e[((_f = field) === null || _f === void 0 ? void 0 : _f.length) - 1];
                lastPart = lastPart.replaceAll('occurrence', 'occurence');
                lastPart = (_h = (_g = lastPart) === null || _g === void 0 ? void 0 : _g.replace(/([A-Z])/g, ' $1')) === null || _h === void 0 ? void 0 : _h.replace(/_/g, ' ');
                _this.fieldsWithError.push({ field: lastPart, error: error });
            }
        });
        this.isLoading = false;
        this.dataIsBeingVerified = false;
        this.isCorrectData = true;
    };
    PostManegementSliderComponent.prototype.selectedLocations = function ($event) {
        this.accountsSelected = $event;
    };
    PostManegementSliderComponent.prototype.addActionUrl = function (placeHolder) {
        var _a;
        this.actionUrl.setValue((_a = placeHolder) === null || _a === void 0 ? void 0 : _a.value);
    };
    PostManegementSliderComponent.prototype.handleRef = function ($event) {
        this.firestorRef = $event;
    };
    PostManegementSliderComponent.prototype.onStepChange = function (event) {
        this.step = event;
        if (this.step === 1) {
            this.addValidationsStepTwo();
            this.buildLocationsNames();
            this.getActionLinks();
        }
        if (this.step === 2) {
            this.addFormFieldsStepThree();
        }
        if (this.step === 3) {
            this.buildDataForLastStep();
            //this.getSchedulePreview();
        }
    };
    PostManegementSliderComponent.prototype.backStep = function (event) {
        this.step = event;
        this.removeAllValidations();
        if (event === 1) {
            this.updateValueAndValidity();
        }
    };
    PostManegementSliderComponent.prototype.getActionType = function (value) {
        var _a;
        return (_a = this.actionOptions.find(function (el) { var _a; return ((_a = el) === null || _a === void 0 ? void 0 : _a.value) === value; })) === null || _a === void 0 ? void 0 : _a.displayName;
    };
    PostManegementSliderComponent.prototype.buildLocationsNames = function () {
        var _this = this;
        this.locationsSelected = [];
        this.accountsSelected.forEach(function (acc) {
            acc.locations.map(function (loc) {
                _this.locationsSelected.push({
                    'accountName': loc.accountName,
                    'locationName': loc.locationName,
                    'address': loc.address,
                    'serviceArea': loc.serviceArea
                });
            });
        });
    };
    PostManegementSliderComponent.prototype.buildDataForLastStep = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62;
        this.dataForLastStep = {};
        switch ((_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value) {
            case 'INSTANT':
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: 'Does Not Repeat',
                    timeOfPublish: ((_b = this.publishTime) === null || _b === void 0 ? void 0 : _b.value) ? (_c = this.publishTime) === null || _c === void 0 ? void 0 : _c.value : null,
                    schedulePublish: !((_d = this.postStartDate) === null || _d === void 0 ? void 0 : _d.value) ? 'Now' : this.getServiceDate((_e = this.postStartDate) === null || _e === void 0 ? void 0 : _e.value, false),
                    scheduledDetele: this.getServiceDate((_f = this.deleteDate) === null || _f === void 0 ? void 0 : _f.value, false),
                    timeOfDetele: ((_g = this.deleteTime) === null || _g === void 0 ? void 0 : _g.value) ? (_h = this.deleteTime) === null || _h === void 0 ? void 0 : _h.value : null,
                    dailyDate: null,
                    startDate: ((_j = this.postType) === null || _j === void 0 ? void 0 : _j.value) === 'STANDARD' ? null : this.getServiceDate((_k = this.instantEventDatesStart) === null || _k === void 0 ? void 0 : _k.value, false),
                    endDate: ((_l = this.postType) === null || _l === void 0 ? void 0 : _l.value) === 'STANDARD' ? null : this.getServiceDate((_m = this.instantEventDatesEnd) === null || _m === void 0 ? void 0 : _m.value, false),
                    startTime: ((_o = this.postType) === null || _o === void 0 ? void 0 : _o.value) === 'STANDARD' ? null : (_p = this.eventTimeStart) === null || _p === void 0 ? void 0 : _p.value,
                    endTime: ((_q = this.postType) === null || _q === void 0 ? void 0 : _q.value) === 'STANDARD' ? null : (_r = this.eventTimeEnd) === null || _r === void 0 ? void 0 : _r.value,
                    couponCode: ((_s = this.postType) === null || _s === void 0 ? void 0 : _s.value) !== 'OFFER' ? null : (_t = this.form.controls.couponCode) === null || _t === void 0 ? void 0 : _t.value,
                    linkToRedeem: ((_u = this.postType) === null || _u === void 0 ? void 0 : _u.value) !== 'OFFER' ? null : (_v = this.form.controls.linkToRedeem) === null || _v === void 0 ? void 0 : _v.value,
                    termsAndCondition: ((_w = this.postType) === null || _w === void 0 ? void 0 : _w.value) !== 'OFFER' ? null : (_x = this.form.controls.termsAndCondition) === null || _x === void 0 ? void 0 : _x.value,
                    endsOn: null,
                    firstPublish: null,
                    timeOfUnpublish: null,
                    repeatsOn: null
                };
                break;
            case 'DAILY':
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: (_y = this.cadence) === null || _y === void 0 ? void 0 : _y.value,
                    timeOfPublish: (!((_z = this.publishTime) === null || _z === void 0 ? void 0 : _z.value) ? null :
                        ((_0 = this.postType) === null || _0 === void 0 ? void 0 : _0.value) !== 'STANDARD' ?
                            this.publishTime.value + " until " + this.getEndDate('DAILY') :
                            this.publishTime.value),
                    repeatsOn: this._postS.getDaysUnified(this.publishDay.value),
                    endsOn: (this.endsOn.value === 'never' ? 'Never' :
                        this.endsOn.value === 'custom_date' ? this.getServiceDate((_1 = this.endsOnCustomDate) === null || _1 === void 0 ? void 0 : _1.value, false) :
                            "After " + ((_2 = this.endsOnAfterOcurrences) === null || _2 === void 0 ? void 0 : _2.value) + " Occurences"),
                    firstPublish: ((_3 = this.firstPublishDate) === null || _3 === void 0 ? void 0 : _3.value) ? this.getServiceDate((_4 = this.firstPublishDate) === null || _4 === void 0 ? void 0 : _4.value, false) : 'Wait for the next occurence',
                    dailyDate: "Daily on " + this._postS.getDaysUnified(this.publishDay.value),
                    startDate: null,
                    endDate: null,
                    startTime: ((_5 = this.postType) === null || _5 === void 0 ? void 0 : _5.value) === 'STANDARD' ? null : (_6 = this.eventTimeStart) === null || _6 === void 0 ? void 0 : _6.value,
                    endTime: ((_7 = this.postType) === null || _7 === void 0 ? void 0 : _7.value) === 'STANDARD' ? null : (_8 = this.eventTimeEnd) === null || _8 === void 0 ? void 0 : _8.value,
                    couponCode: ((_9 = this.postType) === null || _9 === void 0 ? void 0 : _9.value) !== 'OFFER' ? null : (_10 = this.form.controls.couponCode) === null || _10 === void 0 ? void 0 : _10.value,
                    linkToRedeem: ((_11 = this.postType) === null || _11 === void 0 ? void 0 : _11.value) !== 'OFFER' ? null : (_12 = this.form.controls.linkToRedeem) === null || _12 === void 0 ? void 0 : _12.value,
                    termsAndCondition: ((_13 = this.postType) === null || _13 === void 0 ? void 0 : _13.value) !== 'OFFER' ? null : (_14 = this.form.controls.termsAndCondition) === null || _14 === void 0 ? void 0 : _14.value,
                    schedulePublish: null,
                    scheduledDetele: null
                };
                break;
            case 'WEEKLY':
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: (_15 = this.cadence) === null || _15 === void 0 ? void 0 : _15.value,
                    repeatsOn: (((_16 = this.postType) === null || _16 === void 0 ? void 0 : _16.value) !== 'STANDARD' ?
                        ((_17 = this.nameOfDaysOptions.find(function (el) { return el.value === _this.repeatsOnWeekly.value; })) === null || _17 === void 0 ? void 0 : _17.displayName) + " at " + ((_18 = this.publishTime) === null || _18 === void 0 ? void 0 : _18.value) + " until <br> " + this.getEndDate('WEEKLY') + " at " + this.getEndTime('WEEKLY') :
                        ((_19 = this.nameOfDaysOptions.find(function (el) { return el.value === _this.repeatsOnWeekly.value; })) === null || _19 === void 0 ? void 0 : _19.displayName) + " at " + ((_20 = this.publishTime) === null || _20 === void 0 ? void 0 : _20.value)),
                    endsOn: (this.endsOn.value === 'never' ? 'Never' :
                        this.endsOn.value === 'custom_date' ? this.getServiceDate((_21 = this.endsOnCustomDate) === null || _21 === void 0 ? void 0 : _21.value, false) :
                            "After " + ((_22 = this.endsOnAfterOcurrences) === null || _22 === void 0 ? void 0 : _22.value) + " Occurences"),
                    firstPublish: ((_23 = this.firstPublishDate) === null || _23 === void 0 ? void 0 : _23.value) ? this.getServiceDate((_24 = this.firstPublishDate) === null || _24 === void 0 ? void 0 : _24.value, false) : 'Wait for the next occurence',
                    dailyDate: null,
                    startDate: ((_25 = this.postType) === null || _25 === void 0 ? void 0 : _25.value) === 'STANDARD' ? null : this.nameOfDaysOptions.find(function (el) { return el.value === _this.eventDatesStartWeekly.value; }).displayName,
                    endDate: ((_26 = this.postType) === null || _26 === void 0 ? void 0 : _26.value) === 'STANDARD' ? null : this.nameOfDaysOptions.find(function (el) { return el.value === _this.eventDatesEndWeekly.value; }).displayName,
                    startTime: ((_27 = this.postType) === null || _27 === void 0 ? void 0 : _27.value) === 'STANDARD' ? null : (_28 = this.eventTimeStart) === null || _28 === void 0 ? void 0 : _28.value,
                    endTime: ((_29 = this.postType) === null || _29 === void 0 ? void 0 : _29.value) === 'STANDARD' ? null : (_30 = this.eventTimeEnd) === null || _30 === void 0 ? void 0 : _30.value,
                    couponCode: ((_31 = this.postType) === null || _31 === void 0 ? void 0 : _31.value) !== 'OFFER' ? null : (_32 = this.form.controls.couponCode) === null || _32 === void 0 ? void 0 : _32.value,
                    linkToRedeem: ((_33 = this.postType) === null || _33 === void 0 ? void 0 : _33.value) !== 'OFFER' ? null : (_34 = this.form.controls.linkToRedeem) === null || _34 === void 0 ? void 0 : _34.value,
                    termsAndCondition: ((_35 = this.postType) === null || _35 === void 0 ? void 0 : _35.value) !== 'OFFER' ? null : (_36 = this.form.controls.termsAndCondition) === null || _36 === void 0 ? void 0 : _36.value,
                    schedulePublish: null,
                    scheduledDetele: null,
                };
                break;
            case 'MONTHLY':
                var repeatsOn = (((_37 = this.eventDatesStartMonthly) === null || _37 === void 0 ? void 0 : _37.value) === -1 ?
                    'last day' :
                    this._postS.getOrdinalNumbers((_38 = this.eventDatesStartMonthly) === null || _38 === void 0 ? void 0 : _38.value));
                this.dataForLastStep = {
                    postType: this.postTypeLabel,
                    cadence: (_39 = this.cadence) === null || _39 === void 0 ? void 0 : _39.value,
                    repeatsOn: (((_40 = this.postType) === null || _40 === void 0 ? void 0 : _40.value) !== 'STANDARD' ?
                        repeatsOn + " of the month at " + ((_41 = this.publishTime) === null || _41 === void 0 ? void 0 : _41.value) + " until <br> " + this.getEndDate('MONTHLY') + " of the month at " + this.getEndTime('MONTHLY') :
                        repeatsOn + " of the month at " + ((_42 = this.publishTime) === null || _42 === void 0 ? void 0 : _42.value)),
                    endsOn: (this.endsOn.value === 'never' ? 'Never' :
                        this.endsOn.value === 'custom_date' ? this.getServiceDate((_43 = this.endsOnCustomDate) === null || _43 === void 0 ? void 0 : _43.value, false) :
                            "After " + ((_44 = this.endsOnAfterOcurrences) === null || _44 === void 0 ? void 0 : _44.value) + " Occurences"),
                    firstPublish: ((_45 = this.firstPublishDate) === null || _45 === void 0 ? void 0 : _45.value) ? this.getServiceDate((_46 = this.firstPublishDate) === null || _46 === void 0 ? void 0 : _46.value, false) : 'Wait for the next occurence',
                    dailyDate: "Monthly on the " + repeatsOn + " of the month",
                    startDate: (((_47 = this.postType) === null || _47 === void 0 ? void 0 : _47.value) === 'STANDARD' ? null :
                        (((_48 = this.eventDatesStartMonthly) === null || _48 === void 0 ? void 0 : _48.value) === -1 ? 'Last day' : this._postS.getOrdinalNumbers((_49 = this.eventDatesStartMonthly) === null || _49 === void 0 ? void 0 : _49.value)) + " of the month"),
                    endDate: (((_50 = this.postType) === null || _50 === void 0 ? void 0 : _50.value) === 'STANDARD' ? null :
                        (((_51 = this.eventDatesEndMonthly) === null || _51 === void 0 ? void 0 : _51.value) === -1 ? 'Last day' : this._postS.getOrdinalNumbers((_52 = this.eventDatesEndMonthly) === null || _52 === void 0 ? void 0 : _52.value)) + " of the month"),
                    startTime: ((_53 = this.postType) === null || _53 === void 0 ? void 0 : _53.value) === 'STANDARD' ? null : (_54 = this.eventTimeStart) === null || _54 === void 0 ? void 0 : _54.value,
                    endTime: ((_55 = this.postType) === null || _55 === void 0 ? void 0 : _55.value) === 'STANDARD' ? null : (_56 = this.eventTimeEnd) === null || _56 === void 0 ? void 0 : _56.value,
                    couponCode: ((_57 = this.postType) === null || _57 === void 0 ? void 0 : _57.value) !== 'OFFER' ? null : (_58 = this.form.controls.couponCode) === null || _58 === void 0 ? void 0 : _58.value,
                    linkToRedeem: ((_59 = this.postType) === null || _59 === void 0 ? void 0 : _59.value) !== 'OFFER' ? null : (_60 = this.form.controls.linkToRedeem) === null || _60 === void 0 ? void 0 : _60.value,
                    termsAndCondition: ((_61 = this.postType) === null || _61 === void 0 ? void 0 : _61.value) !== 'OFFER' ? null : (_62 = this.form.controls.termsAndCondition) === null || _62 === void 0 ? void 0 : _62.value,
                    schedulePublish: null,
                    scheduledDetele: null,
                };
                break;
        }
    };
    PostManegementSliderComponent.prototype.openDelete = function (item) {
        var _this = this;
        this._modalService.openAlertModal('Confirmation', "Are you sure you want to delete this image? This action cannot be undone.", AlertType.ERROR, null, 'Delete', function (response) {
            if (response) {
                _this._storageImgService.removeFileFromArray(_this.mediaUrlArray, item);
                _this._storageImgService.notifyFileDeleted();
            }
        }, 'red-alert');
    };
    PostManegementSliderComponent.prototype.getSchedulePreview = function () {
        var _this = this;
        this.isLoading = true;
        var data = this.buildServiceData();
        this.schedulePreviewData = [];
        this._postS.getSchedulePreview(data).subscribe(function (res) {
            var _a;
            _this.schedulePreviewData = (_a = res) === null || _a === void 0 ? void 0 : _a.data;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
        });
    };
    PostManegementSliderComponent.prototype.changePreview = function (file) {
        this.selectedUrl = file.url;
    };
    // custom functions for custom swiper
    PostManegementSliderComponent.prototype.customSwiperPrev = function () {
        if (this.selectedFileIndex === 0) {
            return;
        }
        this.selectedFileIndex--;
    };
    PostManegementSliderComponent.prototype.customSwiperNext = function () {
        if (this.mediaUrlArray.length - 1 === this.selectedFileIndex) {
            return;
        }
        this.selectedFileIndex++;
    };
    // custom swiper
    PostManegementSliderComponent.prototype.ngOnChanges = function (changes) {
        var _a, _b;
        if ((_a = changes) === null || _a === void 0 ? void 0 : _a.isSliderOpened) {
            this.isEdit = ((_b = changes) === null || _b === void 0 ? void 0 : _b.postToEdit) && Object.keys(changes.postToEdit.currentValue).length ? true : false;
            // const content = document.getElementsByClassName('content');
            // if (content.length > 0) {
            //   content[0]['className'] += ' pr--0';
            // }
            if (!this.isSliderOpened) {
                this.destroyData();
            }
            else {
                this.initData();
            }
        }
    };
    PostManegementSliderComponent.prototype.ngOnDestroy = function () {
        this.destroyData();
        _super.prototype.ngOnDestroy.call(this);
    };
    PostManegementSliderComponent.prototype.destroyData = function () {
        this.accountsSelected = [];
        this.locationsSelected = [];
        this.actionsUrl = [];
        this.dataForLastStep = {};
        this.step = 0;
        this.isEdit = false;
        this.isDraft = false;
        this.postToEdit = null;
        this.isCorrectData = false;
        this.fieldsWithError = [];
        this._storageImgService.reset();
        this._subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        this.closeSlider.emit();
    };
    PostManegementSliderComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        var filtered = this.hoursList.filter(function (option) { return option.toLowerCase().includes(filterValue); });
        return filtered;
    };
    PostManegementSliderComponent.prototype._maxCurrentTimeValidation = function () {
        return function (control) {
            var _a;
            if (!((_a = control) === null || _a === void 0 ? void 0 : _a.value)) {
                return null;
            }
            else {
                var currentTime = moment();
                var inputTime = moment(control.value, 'hh:mm A', true);
                if (!inputTime.isValid()) {
                    return { invalidTime: true };
                }
                return inputTime.isBefore(currentTime) ? { maxTime: true } : null;
            }
        };
    };
    PostManegementSliderComponent.prototype._validateStartBeforeEnd = function (startControlName, endControlName) {
        var _this = this;
        return function (formGroup) {
            var _a, _b;
            var start = (_a = _this.form.get(startControlName)) === null || _a === void 0 ? void 0 : _a.value;
            var end = (_b = _this.form.get(endControlName)) === null || _b === void 0 ? void 0 : _b.value;
            if (!start || !end) {
                return null;
            }
            if (start && end && moment(start).isAfter(moment(end))) {
                return { startAfterEnd: true };
            }
            return null;
        };
    };
    PostManegementSliderComponent.prototype._validateEndTimeSameDay = function (startTimeControl, endTimeControl) {
        var _this = this;
        return function (formGroup) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            var startDateControl = (((_a = _this.cadence) === null || _a === void 0 ? void 0 : _a.value) === 'INSTANT' && _this.step === 2 ? 'postStartDate' :
                ((_b = _this.cadence) === null || _b === void 0 ? void 0 : _b.value) === 'INSTANT' ? 'instantEventDatesStart' :
                    ((_c = _this.cadence) === null || _c === void 0 ? void 0 : _c.value) === 'WEEKLY' ? 'eventDatesStartWeekly' :
                        ((_d = _this.cadence) === null || _d === void 0 ? void 0 : _d.value) === 'MONTHLY' ? 'eventDatesStartMonthly' :
                            null);
            var endDateControl = (((_e = _this.cadence) === null || _e === void 0 ? void 0 : _e.value) === 'INSTANT' && _this.step === 2 ? 'deleteDate' :
                ((_f = _this.cadence) === null || _f === void 0 ? void 0 : _f.value) === 'INSTANT' ? 'instantEventDatesEnd' :
                    ((_g = _this.cadence) === null || _g === void 0 ? void 0 : _g.value) === 'WEEKLY' ? 'eventDatesEndWeekly' :
                        ((_h = _this.cadence) === null || _h === void 0 ? void 0 : _h.value) === 'MONTHLY' ? 'eventDatesEndMonthly' :
                            null);
            var startDate = ((_j = _this.cadence) === null || _j === void 0 ? void 0 : _j.value) === 'DAILY' ? moment() : (_k = _this.form.get(startDateControl)) === null || _k === void 0 ? void 0 : _k.value;
            var startTime = ((_l = _this.form.get(startTimeControl)) === null || _l === void 0 ? void 0 : _l.value) ? moment(_this.form.get(startTimeControl).value, 'hh:mm A') : null;
            var endDate = ((_m = _this.cadence) === null || _m === void 0 ? void 0 : _m.value) === 'DAILY' ? moment() : (_o = _this.form.get(endDateControl)) === null || _o === void 0 ? void 0 : _o.value;
            var endTime = ((_p = _this.form.get(endTimeControl)) === null || _p === void 0 ? void 0 : _p.value) ? moment(_this.form.get(endTimeControl).value, 'hh:mm A') : null;
            if (!startTime || !endTime) {
                return null;
            }
            var isSameDay = (((_q = _this.cadence) === null || _q === void 0 ? void 0 : _q.value) === 'INSTANT' ? moment(startDate).isSame(moment(endDate), 'day') :
                typeof (startDate) === 'object' && typeof (endDate) === 'object' ? startDate[0] === endDate[0] : startDate === endDate);
            if (isSameDay) {
                if ((startTime).isSameOrAfter(endTime)) {
                    return { endTimeBeforeStartTime: true };
                }
            }
            return null;
        };
    };
    PostManegementSliderComponent.prototype.getError = function (control, displayName, fieldToCompare) {
        var _a;
        var errors = Object.keys((_a = control) === null || _a === void 0 ? void 0 : _a.errors);
        return (errors.includes('required') ? displayName + " is required" :
            errors.includes('startAfterEnd') ? displayName + " should be later than the " + fieldToCompare :
                errors.includes('endTimeBeforeStartTime') ? displayName + " should be later than the " + fieldToCompare :
                    null);
    };
    PostManegementSliderComponent.prototype.getMinDate = function () {
        return new Date();
    };
    PostManegementSliderComponent.prototype.getDisabledFieldTooltip = function () {
        var message = this.cadence.value === 'INSTANT' ?
            "You can\u2019t edit the schedule for this post. If you\u2019d like to change the schedule, you need to create a new post with the desired schedule change" :
            "You can\u2019t edit the schedule for a recurring post. If you\u2019d like to change the schedule, you need to create a new post with the desired schedule change";
        return (this.isEdit && !this.isDraft ?
            message :
            null);
    };
    Object.defineProperty(PostManegementSliderComponent.prototype, "getPublishingSchedule", {
        get: function () {
            var _this = this;
            var _a, _b, _c, _d, _e, _f, _g;
            var txt = "Posts will be published " + (this.cadence.value).toLowerCase() + " on ";
            var startDate, startTime;
            switch (this.cadence.value) {
                case 'DAILY':
                    startDate = ((_a = this.publishTime) === null || _a === void 0 ? void 0 : _a.value) ? this.publishTime.value : '12:00 AM';
                    txt += "<strong>" + this.daysUnified + "</strong> at <strong>" + startDate + "</strong>, until <strong>" + this.getEndDate() + "</strong>";
                    break;
                case 'WEEKLY':
                    startDate = ((_b = this.nameOfDaysOptions.find(function (el) { var _a; return el.value === ((_a = _this.eventDatesStartWeekly) === null || _a === void 0 ? void 0 : _a.value); })) === null || _b === void 0 ? void 0 : _b.displayName) || '';
                    startTime = ((_c = this.publishTime) === null || _c === void 0 ? void 0 : _c.value) ? (_d = this.publishTime) === null || _d === void 0 ? void 0 : _d.value : '12:00 AM';
                    txt += "<strong>" + startDate + "</strong> at <strong>" + startTime + "</strong>, until <strong>" + this.getEndDate() + "</strong> at <strong>" + this.getEndTime() + "</strong>";
                    break;
                case 'MONTHLY':
                    startDate = ((_e = this.eventDatesStartMonthly) === null || _e === void 0 ? void 0 : _e.value) > -1 ? this._postS.getOrdinalNumbers(this.eventDatesStartMonthly.value) : 'last day';
                    startTime = ((_f = this.publishTime) === null || _f === void 0 ? void 0 : _f.value) ? (_g = this.publishTime) === null || _g === void 0 ? void 0 : _g.value : '12:00 AM';
                    txt += "<strong>" + startDate + " of the month</strong> at <strong>" + startTime + "</strong>, until <strong>" + this.getEndDate() + " of the month</strong> at <strong>" + this.getEndTime() + "</strong>";
                    break;
            }
            return txt;
        },
        enumerable: true,
        configurable: true
    });
    PostManegementSliderComponent.prototype.getEndDate = function (frequency) {
        var _this = this;
        var _a, _b, _c, _d;
        var cadence = frequency ? frequency : (_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value;
        var endDate;
        switch (cadence) {
            case 'DAILY':
                endDate = ((_b = this.eventTimeEnd) === null || _b === void 0 ? void 0 : _b.value) ? this.eventTimeEnd.value : '11:59 PM';
                break;
            case 'WEEKLY':
                endDate = ((_c = this.nameOfDaysOptions.find(function (el) { var _a; return el.value === ((_a = _this.eventDatesEndWeekly) === null || _a === void 0 ? void 0 : _a.value); })) === null || _c === void 0 ? void 0 : _c.displayName) || '';
                break;
            case 'MONTHLY':
                endDate = ((_d = this.eventDatesEndMonthly) === null || _d === void 0 ? void 0 : _d.value) > -1 ? this._postS.getOrdinalNumbers(this.eventDatesEndMonthly.value) : 'last day';
                break;
        }
        return endDate;
    };
    PostManegementSliderComponent.prototype.getEndTime = function (frequency) {
        var _a, _b, _c, _d, _e;
        var cadence = frequency ? frequency : (_a = this.cadence) === null || _a === void 0 ? void 0 : _a.value;
        var endTime;
        switch (cadence) {
            case 'WEEKLY':
                endTime = ((_b = this.eventTimeEnd) === null || _b === void 0 ? void 0 : _b.value) ? (_c = this.eventTimeEnd) === null || _c === void 0 ? void 0 : _c.value : '11:59 PM';
                break;
            case 'MONTHLY':
                endTime = ((_d = this.eventTimeEnd) === null || _d === void 0 ? void 0 : _d.value) ? (_e = this.eventTimeEnd) === null || _e === void 0 ? void 0 : _e.value : '11:59 PM';
                break;
        }
        return endTime;
    };
    Object.defineProperty(PostManegementSliderComponent.prototype, "daysUnified", {
        get: function () {
            return this._postS.getDaysUnified(this.publishDay.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "formattedSummary", {
        get: function () {
            var _a, _b;
            var summary = (_b = (_a = this.summary) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.replaceAll(/\n/g, '<br>');
            return summary;
        },
        enumerable: true,
        configurable: true
    });
    PostManegementSliderComponent.prototype.getLowercase = function (value) {
        return value.toLowerCase();
    };
    PostManegementSliderComponent.prototype.getPlaceholder = function (field) {
        return field.value ? 'Time' : 'Time (Optional)';
    };
    Object.defineProperty(PostManegementSliderComponent.prototype, "postTypeLabel", {
        get: function () {
            var _a;
            return ((_a = this.postType) === null || _a === void 0 ? void 0 : _a.value) === 'STANDARD' ? 'Update' : this.postType.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "completeStep1", {
        get: function () {
            var _a, _b, _c;
            var isComplete = false;
            if (this.isBulk) {
                var totalLocations_1 = 0;
                (_a = this.accountsSelected) === null || _a === void 0 ? void 0 : _a.forEach(function (account) {
                    var _a, _b;
                    totalLocations_1 += (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length;
                });
                isComplete = ((_b = this.accountsSelected) === null || _b === void 0 ? void 0 : _b.length) > 0 && totalLocations_1 >= 2;
            }
            else {
                isComplete = ((_c = this.accountsSelected) === null || _c === void 0 ? void 0 : _c.length) > 0;
            }
            return isComplete;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "completeStep2", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "completeStep3", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "postType", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.postType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "cadence", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.cadence;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "schedulePublish", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.schedulePublish;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "postStartDate", {
        get: function () {
            var _a;
            return (_a = this.form.controls) === null || _a === void 0 ? void 0 : _a.postStartDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "deleteDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.deleteDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "publishTime", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.publishTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "deleteTime", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.deleteTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "publishDay", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.publishDay;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "unifiedPublishDayValues", {
        get: function () {
            return this._postS.getDaysUnified(this.publishDay.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "endsOn", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.endsOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "endsOnCustomDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.endsOnCustomDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "endsOnAfterOcurrences", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.endsOnAfterOcurrences;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "repeatsOnMonthly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.repeatsOnMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "repeatsOnWeekly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.repeatsOnWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "repeatsOnDay", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.repeatsOnDay;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "firstPublish", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.firstPublish;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "firstPublishDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.firstPublishDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "title", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "summary", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.summary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesStartWeekly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesStartWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesEndWeekly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesEndWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesStartMonthly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesStartMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventDatesEndMonthly", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventDatesEndMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "instantEventDatesStart", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.instantEventDatesStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "instantEventDatesEnd", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.instantEventDatesEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "weeklyEventsDates", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.weeklyEventsDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "matchScheduleDate", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.matchScheduleDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventTimeStart", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventTimeStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "actionType", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.actionType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "actionUrl", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.actionUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "eventTimeEnd", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls) === null || _b === void 0 ? void 0 : _b.eventTimeEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManegementSliderComponent.prototype, "disabledNextStep", {
        get: function () {
            return !(this.step === 0 ? this.completeStep1 :
                this.step === 1 ? this.completeStep2 :
                    this.step === 2 ? this.completeStep3 : true);
        },
        enumerable: true,
        configurable: true
    });
    return PostManegementSliderComponent;
}(BaseComponent));
export { PostManegementSliderComponent };
