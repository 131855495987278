// dep
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/app';

// app
import { NavigationService } from 'src/app/services/navigation.service';
import { VerificationEmailService } from 'src/app/services/verification-email.service';
import { SessionService } from 'src/app/services/session.service';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls:  ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  verified: boolean;
  loginProgress : boolean
  
  domain$ = this._sessionS.domain$
  
  constructor(
    private _route: ActivatedRoute,
    private _verificationService: VerificationEmailService,
    private _sessionS : SessionService,
    public navigationS: NavigationService,
    // private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.loginProgress = true;
    const {hash} = this._route.snapshot.queryParams;
    const ver = await this._verificationService.getVerificationToHash(hash).toPromise()
    ver.forEach( v => {
      if (!v) {
        this.verified = false;
        return;
      }
      const data = v.data()
      if ( data.emailVerified == null ) {
        this._verificationService.update(v.id, { 'emailVerified': firebase.firestore.Timestamp.now()})
      }
      this.verified = true;
    })
    
    this.loginProgress = false;
  }

}
