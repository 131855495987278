/**
 * This is the old Billing page used for subscriptions with pricingVersion <= 2
 */
import { __awaiter, __extends, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
// app
import { ModalService } from 'src/app/services/modal.service';
import { UpdateCardComponent } from './update-card/update-card.component';
// import { EditCardComponent } from './edit-card/edit-card.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PaymentsService } from '../../services/payments.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var BillingComponent = /** @class */ (function (_super) {
    __extends(BillingComponent, _super);
    function BillingComponent(_sessionS, paymentService, subscriptionService, navigationS, _modalS, _snackS) {
        var _this = _super.call(this) || this;
        _this._sessionS = _sessionS;
        _this.paymentService = paymentService;
        _this.subscriptionService = subscriptionService;
        _this.navigationS = navigationS;
        _this._modalS = _modalS;
        _this._snackS = _snackS;
        _this.listColumns = ['number', 'date', 'amount', 'status'];
        _this.session$ = _this._sessionS.session$;
        _this._subscribeSafe(_this.session$, function (_) {
            // if (this.subscription?.blocked) {
            //   this.modalService.openWarningModal(
            //     'Your payment method failed.',
            //     'Please update your credit card or contact your bank.'
            //   );
            // } 
            _this.getCards();
        });
        return _this;
    }
    BillingComponent.prototype.onAddCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openModal(UpdateCardComponent, {})];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getCards()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.ngOnInit = function () {
        this.loadingCards = true;
        this._getPlanInfo();
        this.fetchInvoices();
        this.getCards();
    };
    BillingComponent.prototype._getPlanInfo = function () {
        var _this = this;
        this.paymentService.getPlan(LOCATION_SUBSCRIPTION_TYPE.ULTIMATE)
            .subscribe(function (plan) {
            var interval = plan['data']['interval'];
            var amount = plan['data']['price'];
            _this.ultimatePlan = {
                interval: interval,
                amount: amount,
                isFree: (amount == 0)
            };
        });
        this.paymentService.getPlan(LOCATION_SUBSCRIPTION_TYPE.BASIC)
            .subscribe(function (plan) {
            var interval = plan['data']['interval'];
            var amount = plan['data']['price'];
            _this.basicPlan = {
                interval: interval,
                amount: amount,
                isFree: (amount == 0)
            };
        });
    };
    BillingComponent.prototype.fetchInvoices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var invoices;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.subscriptionService.fetchInvoices(this._sessionS.getSession().gid)];
                    case 1:
                        invoices = _a.sent();
                        this.paymentsHistory = invoices.data.filter(function (el) { return ['paid', 'pending', 'pre authorized', 'unpaid', 'canceled'].includes(el.status.toLowerCase()); });
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.getCards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.loadingCards = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, 4, 5]);
                        _a = this;
                        return [4 /*yield*/, this.paymentService.getCards(this._sessionS.getSession().gid)];
                    case 2:
                        _a.cards = _c.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        _b = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.loadingCards = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.handleDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openConfirmModal("Set as default", "Are you sure you want use this Credit Card as the default one?")];
                    case 1:
                        if (_a.sent()) {
                            this.loadingCards = true;
                            this.applyDefaultSource(card.card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.applyDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.paymentService.setDefaultSource(this._sessionS.getSession().gid, card.id)];
                    case 1:
                        _a.sent();
                        this._snackS.openSuccess('Set as default Credit Card succesfully');
                        this.getCards();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.handleDeleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openConfirmModal("Remove Credit Card", "Are you sure you want to remove this Credit Card?")];
                    case 1:
                        if (_a.sent()) {
                            this.loadingCards = true;
                            this.deleteSource(card.card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.deleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.paymentService.deleteCard(this._sessionS.getSession().gid, card.id)];
                    case 1:
                        _a.sent();
                        this._snackS.openSuccess('Removed Credit Card succesfully');
                        this.getCards();
                        return [2 /*return*/];
                }
            });
        });
    };
    return BillingComponent;
}(BaseComponent));
export { BillingComponent };
