/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-service.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../error-message/error-message.component.ngfactory";
import * as i4 from "../error-message/error-message.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../review-auto-responder/review-auto-responder.component";
import * as i11 from "./modal-service.component";
import * as i12 from "../../services/location.service";
import * as i13 from "../../services/google.service";
import * as i14 from "../../services/snackbar.service";
var styles_ModalServiceComponent = [i0.styles];
var RenderType_ModalServiceComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ModalServiceComponent, data: {} });
export { RenderType_ModalServiceComponent as RenderType_ModalServiceComponent };
function View_ModalServiceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.type)); _ck(_v, 4, 0, currVal_1); }); }
function View_ModalServiceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-error-message", [], null, null, null, i3.View_ErrorMessageComponent_0, i3.RenderType_ErrorMessageComponent)), i1.ɵdid(2, 114688, null, 0, i4.ErrorMessageComponent, [], { error: [0, "error"], error_details: [1, "error_details"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = _co.error_details; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ModalServiceComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "txt--left w--100"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editItem(_v.parent.parent.context.$implicit, ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.positionEdit), _v.parent.parent.parent.parent.context.index, _v.parent.parent.context.index);
        var pd_0 = ($event.stopPropagation !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.description); _ck(_v, 1, 0, currVal_0); }); }
function View_ModalServiceComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn txt--underline txt--lightblue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMessage(_v.parent.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.showMoreMessage && (_co.showMoreMessageIndex === _v.parent.parent.parent.context.index)) ? "read less" : "read more"); _ck(_v, 1, 0, currVal_0); }); }
function View_ModalServiceComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "txt--left txt--black mb--10"], ["style", "white-space: normal; overflow-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_10)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (((_v.parent.parent.context.$implicit == null) ? null : ((_v.parent.parent.context.$implicit.description == null) ? null : _v.parent.parent.context.$implicit.description.length)) > 60); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.showMoreMessage && (_co.showMoreMessageIndex === _v.parent.parent.context.index)) ? ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.description) : i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 2), ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.description), "60"))); _ck(_v, 1, 0, currVal_0); }); }
function View_ModalServiceComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_8)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_9)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.description.length) <= 60); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.description.length) > 60); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ModalServiceComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "list-group-item border-bottom"], ["style", "margin: 5px 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row box__content box__content--padding-xs w--100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "txt--left"], ["style", "margin: 5px 0;cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editItem(_v.context.$implicit, ((_v.context.$implicit == null) ? null : _v.context.$implicit.positionEdit), _v.parent.parent.context.index, _v.context.index);
        var pd_0 = ($event.stopPropagation !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h5", [["class", "txt--left"], ["style", "margin: 5px 0;cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editItem(_v.context.$implicit, ((_v.context.$implicit == null) ? null : _v.context.$implicit.positionEdit), _v.parent.parent.context.index, _v.context.index);
        var pd_0 = ($event.stopPropagation !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_7)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "col-2 txt--right"], ["style", "margin: auto;cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editItem(_v.context.$implicit, ((_v.context.$implicit == null) ? null : _v.context.$implicit.positionEdit), _v.parent.parent.context.index, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fas fa-chevron-right txt--lg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.displayName); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((((_v.context.$implicit == null) ? null : ((_v.context.$implicit.price == null) ? null : _v.context.$implicit.price.type)) === "noPrice") ? "" : ((((_v.context.$implicit == null) ? null : ((_v.context.$implicit.price == null) ? null : _v.context.$implicit.price.type)) === "free") ? "Free" : i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.price == null) ? null : _v.context.$implicit.price.units)))))); _ck(_v, 6, 0, currVal_1); }); }
function View_ModalServiceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "box__header box__header--xs justify-content-between align-items-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-color": 0, "border-radius": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "txt--lg m--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_6)), i1.ɵdid(13, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "box__content box__content--padding-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "button", [["mat-button", ""], ["style", "color: #1a73e8;"], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(17, 0, null, 0, 3, "p", [["class", "m--0"], ["style", "padding: 10px 5px; font-weight: 100;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["style", "font-size: 17px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵted(-1, null, [" Add Item"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "#f1f3f4", "8px 8px 0px 0px"); _ck(_v, 3, 0, currVal_0); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.services); _ck(_v, 13, 0, currVal_3); var currVal_6 = _co.showOnly; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.displayName); _ck(_v, 7, 0, currVal_1); var currVal_2 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.principal) ? "Main Category" : ""); _ck(_v, 9, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 16).disabled || null); var currVal_5 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_4, currVal_5); }); }
function View_ModalServiceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dialog-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "box box--shadow"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "border-radius": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_5)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "8px"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 6, 0, currVal_1); }, null); }
function View_ModalServiceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_4)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalServiceComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse"]], null, null, null, null, null))], null, null); }
function View_ModalServiceComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn--action"], ["color", "accent"], ["mat-flat-button", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Apply "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ModalServiceComponent_13)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loading; var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.loading; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModalServiceComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "dialog__footer mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dialogRef.close(i1.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(5, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_12)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "primary"; _ck(_v, 4, 0, currVal_4); var currVal_5 = ""; _ck(_v, 5, 0, currVal_5); var currVal_6 = !_co.showOnly; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 5).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 5).type; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ModalServiceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.TitleCasePipe, []), i1.ɵpid(0, i5.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵpid(0, i10.TruncatePipe, []), i1.ɵqud(671088640, 1, { editbutton: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], [[4, "height", null]], null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_3)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalServiceComponent_11)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.bulk; _ck(_v, 5, 0, currVal_0); var currVal_2 = ((_co.error != i1.ɵEMPTY_MAP) && (((_co.error_details == null) ? null : _co.error_details.length) > 0)); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.serviceList; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.bulk; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.bulk ? "300px" : "100%"); _ck(_v, 6, 0, currVal_1); }); }
export function View_ModalServiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-service", [], null, null, null, View_ModalServiceComponent_0, RenderType_ModalServiceComponent)), i1.ɵdid(1, 114688, null, 0, i11.ModalServiceComponent, [i2.MatDialogRef, i2.MatDialog, i12.LocationService, i13.GoogleService, i14.SnackbarService, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalServiceComponentNgFactory = i1.ɵccf("app-modal-service", i11.ModalServiceComponent, View_ModalServiceComponent_Host_0, { bulk: "bulk", serviceList: "serviceList", primaryCategory: "primaryCategory", aditionalCategories: "aditionalCategories" }, { validResult: "validResult" }, []);
export { ModalServiceComponentNgFactory as ModalServiceComponentNgFactory };
