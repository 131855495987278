// dep
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// app
import { KeywordsFilterConfigService } from './../services/keywords-filter-config.service';
import { IDateRange, IFilterConfig, Granularity } from '../../../constants/data-picker';
// import { IReportFilterConfigData } from '../../../constants/report-filter-config-data';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.scss']
})

export class ReportsFilterComponent extends BaseComponent implements OnInit {
  @Output() navigateBackEmitter = new EventEmitter(); // <- the parent should handle where to redirect

  public initialized = false;

  public minDateSelected = '';
  public maxDateSelected = '';

  public dateReady = false;

  public dateRangeObject: IFilterConfig = {
    dateRangeLimits: {
      minDate: null,
      maxDate: null
    },
    selectedRange: { minDate: null, maxDate: null },
    granularity: {
      start: Granularity.Year,
      depth: Granularity.Year
    },
    presetDateRanges: null,
    viewBy: Granularity.Day
  }

  public readonly session$ = this._sessionS.session$;

  constructor(
    private _keywordsFilterConfigS: KeywordsFilterConfigService,
    private _sessionS: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this._subscribeSafe(this._keywordsFilterConfigS.dateRestrictionSubject$,
     (dateRestriction: IDateRange) => {
      console.log('dateRestriction', dateRestriction);
      
      if (dateRestriction) { 
        this.dateRangeObject.dateRangeLimits.minDate = dateRestriction.minDate;
        this.dateRangeObject.dateRangeLimits.maxDate = dateRestriction.maxDate;
        console.log('initialDates updated');
        this.dateReady = true;
        if (!this.initialized) {
          this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({
            // testing, we need to initialize with the default min/max dates (defined in doc) if possible
            minDate: dateRestriction?.minDate?.toLocaleDateString('en-CA'),
            maxDate: dateRestriction?.maxDate?.toLocaleDateString('en-CA')
          })
          this.initialized = true;
        }
      }
    })
  } 

  dateChanged(event): void {
    // Date().toISOString() creates the wrong date, 31/12/2023 if you select 01/01/2024 and you are in Tokyo (UTC+9)
    // Date().toLocaleDateString() creates the correct date, 01/01/2024 if you select 01/01/2024 and you are in Tokyo (UTC+9)
    // same scenario if you are in LA (UTC-8) or anywhere else in the world
    
    this.minDateSelected = event.startDate.toLocaleDateString('en-CA');
    this.maxDateSelected = event.endDate.toLocaleDateString('en-CA');

    // should prompt a report update
    this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({minDate: this.minDateSelected, maxDate: this.maxDateSelected});
    console.log('dateChanged', this.minDateSelected, this.maxDateSelected);
    
  }

  hasLastMonth(): boolean {
    return false; // TESTING
  }

  navigateBack(): void {
    this.navigateBackEmitter.emit(true);
  }

  
}
