// dep
import { Component, Inject, OnInit, isDevMode } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Element as StripeElement, Elements, ElementsOptions, StripeService } from 'ngx-stripe'

// app
import { PaymentsService } from '../../../services/payments.service'
import { DomainService } from "../../../services/domain.service"
import { ModalService } from "../../../services/modal.service"
import { SnackbarService } from '../../../services/snackbar.service'
import { NavigationService } from 'src/app/services/navigation.service'
import { SessionService } from 'src/app/services/session.service'
import { BaseComponent } from 'src/app/components/base.component'

// TODO: Rename to AddCardComponent, this component shows a form to add a new Credit Card, 
// NOT to update an existing one. Misleading name.
// Also see MAP-2234

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls:  ['./update-card.component.scss']
})
export class UpdateCardComponent extends BaseComponent implements OnInit {

  RETURN_TYPE : boolean
  
  elements: Elements;
  card: StripeElement;
  elementOptions: ElementsOptions = {
    locale: 'en'
  };

  stripe: FormGroup;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<UpdateCardComponent>,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private paymentService: PaymentsService,
    public snack: SnackbarService,
    private _domainS: DomainService,
    private modalService: ModalService,
    private _sessionS : SessionService,
    public navigationS : NavigationService,
  ) {
    super();
  }

  get form() {
    return this.stripe;
  }

  async ngOnInit() {
    this.stripe = this.formBuilder.group({ name: ['', Validators.required] });

    const [stripeDomainAccountId, stripePubKey] = await Promise.all([
      this._domainS.getStripeConnectAccountId(),
      isDevMode() ? this._domainS.getStripeTestMaplabsPublicKey() :
                    this._domainS.getStripePublicKey()])

    this.stripeService.changeKey(stripePubKey, { 'stripeAccount': stripeDomainAccountId });

    this._subscribeSafe(this.stripeService.elements(this.elementOptions),
    elements => {
      this.elements = elements;
      if (!this.card) {
        this.card = this.elements.create('card', {
          iconStyle: 'solid',
          style: {
            base: {
              iconColor: '#8c91a2',
              lineHeight: '1.2',
              fontWeight: 300,
              fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
              fontSize: '16px',

              '::placeholder': { color: '#8c91a2' },
            },
            invalid: {
              iconColor: '#e85746',
              color: '#e85746',
            }
          },
          // @ts-ignore
          classes: {
            focus: 'is-focused',
            empty: 'is-empty',
          },
        });
      }
    });

    this.card.mount('#card-element');
    const inputs = document.querySelectorAll('input.field');
    Array.prototype.forEach.call(inputs, (input) => {

      input.addEventListener('focus', () => { input.classList.add('is-focused') })
      input.addEventListener('blur',  () => { input.classList.remove('is-focused')})
      input.addEventListener('keyup', () => {
        if (input.value.length === 0) {
          input.classList.add('is-empty');
        } else {
          input.classList.remove('is-empty');
        }
      });

    });

    function setOutcome(result) {
      const successElement = document.querySelector('.success');
      const errorElement   = document.querySelector('.error');
      
      successElement.classList.remove('visible');
      errorElement.classList.remove('visible');

      if (result.token) {
        successElement.querySelector('.token').textContent = result.token.id;
        successElement.classList.add('visible');
      } else if (result.error) {
        errorElement.textContent = result.error.message;
        errorElement.classList.add('visible');
      }
    }

    this.card.on('change', (event) => {
      setOutcome(event);
    })
    
  }


  /**
   * "Save" button handler
   */
  async save() : Promise<void> {
    let token_id : string 
    try {
       this.loading = true

       const name = this.stripe.get('name').value

       // 1- Send the private card details and get a token that can be safely passed
       // Some validation is done here by Stripe.
       // - https://docs.stripe.com/api/tokens
       // - https://docs.stripe.com/docs/api/tokens/create_card
       const res = await this.stripeService.createToken(this.card, { name }).toPromise()        
    
       if (res.error)
         throw new Error(res.error?.message)

       // "token.id" has the pattern "tok_XXXXX"
       token_id = res.token.id

       // 2- Pass the token to the backend
       // It will call https://docs.stripe.com/api/cards/create
       // Stripe will do more card validation on that endpoint.
       await this.paymentService.addNewCard(this._sessionS.getSession().gid, token_id)
       this.snack.openSuccess('New card added succesfully', 3000)
       this.dialogRef.close(true)
    } catch(e) {
      // User message comes from Stripe detailing Card validation/authorization errors
      const user_msg : string | undefined = (!token_id && e?.message) ? e.message : e?.error?.detail?.user_message

      if(token_id && !user_msg)
         // Log backend non-Stripe error to Datadog
         console.error("Error adding new Card (backend)", e)
       else
         console.log("Error adding new Card", e)
       
       // Show the error we get from Stripe or a fixed one if it's a backend non-Stripe error
       this.modalService.openErrorModal('Credit Card Failed', user_msg || 'Please try with a different credit card')
       this.dialogRef.close(false)
    } finally {
       this.loading = false
    }

  }

}
