// dep
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import moment from 'moment';
import firebase from 'firebase/app';

// app
import Report, {REPORT_TYPE} from '../constants/firestore/report';
import {DataPicker} from '../constants/data-picker';
import {FirestoreService} from './firestore.service';
import {Pageable} from '../constants/pageable';
import {ZuluToDatePipe} from '../pipes/zulu-to-date.pipe';
import { FIRESTORE_AUTOMATED_REPORTS, GROUPS, REPORTS } from '../constants/firestore/collections';
import {environment} from "@environment";
import { InsightsService } from './insights.service';
import { SnackbarService } from './snackbar.service';
import { DatesService } from './dates.service';
import { LocationService } from './location.service';
import { LocationRef } from '../constants/firestore/location-object';
import { Pagination } from '../constants/api-response';
import { SessionService } from './session.service';



@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private firestoreS: FirestoreService,
    private insightS: InsightsService,
    private afsStorage: AngularFireStorage,
    private snackS: SnackbarService,
    private dateS: DatesService,
    private locationS: LocationService,
    private _sessionS : SessionService
  ) {
  }

  private _getRef(gid : string) {
    return this.afs.collection(GROUPS).doc(gid).collection(REPORTS);
  }

  private _getAutomatedRef(gid : string) {
    return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS);
  }

  /**
   * this method get hash and token for public report
   */
  getHash() : Observable<{hash: string, token: string }> {
    // TODO: Why this check?
    // if (!this.auth.session)
    if(!this._sessionS.hasSession())
      return

    return this.http.get<{ hash: string, token: string }>(`${environment.apiUrl}/users/hash`);
  }

  // CRUD firestore operations
  get(gid: string, reportId: string): Observable<Report> {
    return this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`).doc<Report>(reportId).valueChanges();
  }


  getAutomated(gid : string, reportId: string): Observable<Report> {
    return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS).doc<Report>(reportId).valueChanges();
  }

  getById(gid : string, reportId: string) {
    return this.afs.collection(GROUPS).doc(gid).collection(REPORTS).doc<Report>(reportId);
  }

  /**
   * search by uid and gid
   */
  getByGIdAndType(gid: string, type: string,
                  pageable: Pageable = {size: 10, page: 1},
                  next, prev, searchKeywords, order = 'createdAt',
                  direction = 'desc'): Observable<Pagination> {
    return this.firestoreS.paginateValuesReports(`${GROUPS}/${gid}/${REPORTS}`,
                                                  order, direction, gid, type, pageable, next, prev, searchKeywords);
  }


  getByGIdAndTypeCount(gid : string, reportType : string){
    return this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`, query => query.where('gid', '==', gid)
                                                                            .where('reportType', '==', reportType)).get()
  }
   
// TODO: Unused, remove
//   async getLegacy(gid: string, type: string) {
//     const res = await this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`, ref => 
//       ref.where('gid', '==', gid)
//       .where('reportType', '==', type)
//     ).get().toPromise();
// 
//    // console.log(res.forEach(doc => console.log(doc.data())))
//    return res.docs.length;
//   }

  getReportsFromMongo(gid : string, reportType, paginate, sortBy, sortOrder, reportName = null) : Promise<any> {
    const data = {
      gid,
      reportType,
      size: paginate.size,
      page: paginate.page,
      sortBy,
      sortOrder,
      reportName 
    }
    return this.http.post<any>(`${environment.apiUrl}/v3/reports/list`, data).toPromise()
  }
  
  saveReportInMongo(report: Report): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v3/reports/create`, report)
  }

  updateReportsInMongo(reportId : string, report): Observable<any> {
    const data = { updatedData: report };
    report.accounts = this.locationS.deleteServiceArea(report.accounts);
    return this.http.post(`${environment.apiUrl}/v3/reports/${reportId}/edit`, data)
  }

  deleteFromMongo(reportId : string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/v3/reports/${reportId}/delete`)
  }

  // We need to update the endpoint to work with dynamic aggregation, as it should be able to switch between months, weeks and days
  getSearchKeywordsBrands(accountId = null, locationId = null, gid, reportId = null, startDate = null, endDate = null, period): Observable<any> {
    period = period ? period?.replace('Comparison', '') : null;

    const data = {
      "accountId": accountId,
      "locationId": locationId,
      "reportId": reportId,
      "gid": gid,
      "startDate": startDate,
      "endDate": endDate,
      "period": period
    }
    
    return this.http.post(`${environment.apiUrl}/v2/locations/keyword-brands`, data);
  }

  getPerformanceRollupReports(reportId : string, viewBy = 'month', startDate : string, endDate : string): Observable<any> {
    const data = {
      reportId,
      viewBy: viewBy.toLocaleLowerCase(),
      startDate,
      endDate
    }
    
    return this.http.post(`${environment.apiUrl}/v3/reports/insights`, data);
  }

  validateDateFormat(date: string): string {
    const dateParts = date?.split('-') || [];
    const year = dateParts[0].padStart(4, '0');
    const month = dateParts[1].padStart(2, '0');
    const day = dateParts[2].padStart(2, '0');

    return `${year}-${month}-${day} 00:00:00`;
  }

  resumeExportCsv(gid : string, reportType, sort, dataPicker, filename): Observable<any> {
    const startDatetimeMoment = moment(dataPicker.range.start);
    const endDatetimeMoment = moment(dataPicker.range.end);
    
    let startDatetime = !startDatetimeMoment.isValid() ? null : startDatetimeMoment['_i'].includes('T') ? (startDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (startDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');
    let endDatetime = !endDatetimeMoment.isValid() ? null :  endDatetimeMoment['_i'].includes('T') ? (endDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (endDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');

    if (!!startDatetime) { startDatetime = this.validateDateFormat(startDatetime); }
    if (!!endDatetime) { endDatetime = this.validateDateFormat(endDatetime); }

    let accounts = dataPicker?.locations?.map(l => l?.accountId);
    accounts = [...new Set(accounts)];
    const locations = dataPicker?.locations?.map(l => l?.locationId);

    const data =
    {
      "gids": [gid],
      "accountIds": accounts,
      "locationIds": locations,
      "filter": {
        "startDate": startDatetime,
        "endDate": endDatetime
      },
      "type": reportType,
      "filename": filename
    }

    if (sort) {
      data['sortDesc'] = (sort?.direction !== 'asc')
      data['sortKey'] = sort?.sortBy;
    }
    return this.http.post(`${environment.coreApiUrl}/export/multilocation`, data);
  }

  getTableData(gid, reportType, pageable, sort, dataPicker): Observable<any> {
    let startDatetime, endDatetime, endDatetimeMoment, startDatetimeMoment = null
    
    if(dataPicker.range.start) {
        startDatetimeMoment = moment(dataPicker.range.start);
        startDatetime = !startDatetimeMoment.isValid() ? null : startDatetimeMoment['_i']?.includes('T') ? (startDatetimeMoment['_i']?.split('T')[0])?.replaceAll('/', '-') : (startDatetimeMoment['_i']?.split(' ')[0])?.replaceAll('/', '-');
        startDatetime = `${startDatetime} 00:00:00`;
    }
    if(dataPicker.range.end) {
      endDatetimeMoment = moment(dataPicker.range.end);
      endDatetime = !endDatetimeMoment.isValid() ? null :  endDatetimeMoment['_i']?.includes('T') ? (endDatetimeMoment['_i']?.split('T')[0])?.replaceAll('/', '-') : (endDatetimeMoment['_i']?.split(' ')[0])?.replaceAll('/', '-');
      endDatetime = `${endDatetime} 00:00:00`;
    }

    let accounts = dataPicker?.locations?.map(l => l?.accountId);
    accounts = [...new Set(accounts)];
    const locations = dataPicker?.locations?.map(l => l?.locationId);

    const data =
    {
      "gids": [gid],
      "accountIds": accounts,
      "locationIds": locations,
      "filter": {
        "startDate": startDatetime,
        "endDate": endDatetime
      },
      "type": reportType,
      "page": pageable.page,
      "pageSize": pageable.size,
    }

    if (sort) {
      data['sortDesc'] = (sort.direction !== 'asc')
      data['sortKey']  = sort.sortBy
    }

    return this.http.post(`${environment.coreApiUrl}/reports/multilocation`, data);
  }

  getReportsByType(gid : string, reportId : string, reporType : string, startDate = null, endDate = null, viewBy = null): Observable<any> {
    const data = {
      gid,
      reportId,
      startDate,
      endDate
    }

    if (viewBy) {
      data['viewBy'] = viewBy.toLowerCase()
    }
    
    return this.http.post(`${environment.apiUrl}/v3/reports/${reporType}`, data);
  }

  getReportDetails(reportId : string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v3/reports/accounts`, { reportId })
  }
  
  getReportById(reportId : string, isAutomatedReport = false): Observable<any> {
    const type = isAutomatedReport ? 'automated' : 'default';
    return this.http.get(`${environment.apiUrl}/v3/reports/${reportId}?type=${type}`);
  }

  async handleExportCsvFire(locations : LocationRef[], startDate : string, endDate : string, viewBy, reportName : string) : Promise<void> {
    const filename = (await this.insightS.getExportFile(locations, startDate, endDate, viewBy, 'csv', reportName).toPromise()).data

    try {
      const filename2 = await this.afsStorage.ref(filename).getDownloadURL().toPromise()
      this.insightS.openDownloadInNewTab(filename2)
      this.snackS.openSuccess("The file exported successfully")
    } catch(err) {
      this.snackS.openError("There was an error in the export")
    }
  }

  async handleExportCsvMongo(reportId : string, gid : string, accountId : string, locationId : string, 
                             startDate : string, endDate : string, viewBy, type = null, period = null) : Promise<void> {
    const startDatetime = this.locationS.formatDates(startDate);
    const endDatetime   = this.locationS.formatDates(endDate);

    try {
      const filename  = await this._downloadReportCsv(reportId, gid, accountId, locationId, startDatetime, endDatetime, viewBy, type, period)
      const filename2 = await this.afsStorage.ref(filename).getDownloadURL().toPromise()
      this.insightS.openDownloadInNewTab(filename2);
      this.snackS.openSuccess("The file exported successfully")
    } catch(err) {
      this.snackS.openError("There was an error in the export")
    }
  }

  private _downloadReportCsv(reportId = null, gid = null, accountId = null, locationId = null, 
                             startDate = null, endDate = null, viewBy = null, type = null, period = null): Promise<any> {
    const data = {
      reportId,
      gid,
      accountId,
      locationId,
      startDate,
      endDate,
      viewBy: viewBy?.toLowerCase(),
      type,
      period
    }

    return this.http.post(`${environment.apiUrl}/v3/reports/export`, data).toPromise()
  }

// TODO: Unused, remove?
//   downloadReportCsvReview(gid, reportId, startDate, endDate, type): Observable<any> {
//     const data = {
//       gid,
//       reportId, 
//       startDate, 
//       endDate, 
//       type
//     };
// 
//     return this.http.post(`${environment.apiUrl}/v3/reports/export`, data);
//   }

  save(gid : string, report: Report) {
    return this._getRef(gid).add(report);
  }

  delete(gid : string, reportId : string) : Promise<void> {
    return this._getRef(gid)
      .doc(reportId)
      .delete();
  }

  update(automatedReport= false, gid : string, reportId : string, report: Report) {
    if (automatedReport) {
      return this._getAutomatedRef(gid)
                    .doc(reportId)
                    .update({...report,
                             updateAt: firebase.firestore.Timestamp.now()});
    }

    return this._getRef(gid)
      .doc(reportId)
      .update({...report, updateAt: firebase.firestore.Timestamp.now()});
  }

  setMerge(gid : string, reportId : string, report: Partial<Report>) : Promise<void>  {
    return this._getRef(gid)
      .doc(reportId)
      .set(report, {merge: true});
  }

  share(dataPicker: DataPicker, type: REPORT_TYPE, metrics?: any[], reportName?: string, showComparison?, questionsType?, periodDaysValue?, compareToValue?) {

    return this.getHash()
      .pipe(
        map(result => {
          const {gid, uid} = this._sessionS.getSession();
          const report: Report = {
            reportName: reportName || '',
            reportType: type,
            createdAt: firebase.firestore.Timestamp.now(),
            locations: dataPicker.locations,
            gid,
            hash: result.hash,
            token: result.token,
            aggregation: dataPicker.aggregation,
            lockDates: false,
            uid,
            shared: true,
            sharedOnly: true
          };

          if (dataPicker.multiChart) {
            report.multiChart = dataPicker.multiChart;
          }


          if (dataPicker.range) {
            report.startDatetime = dataPicker?.range?.start;
            report.endDatetime = dataPicker?.range?.end;
          }

          if (dataPicker.rangeB) {

            if (dataPicker.rangeB.start !== undefined && dataPicker.rangeB.end !== undefined) {
              report.startDatetimeB = dataPicker.rangeB.start;
              report.endDatetimeB = dataPicker.rangeB.end;
            }

          }

          if(metrics.length) {
            report.metrics = metrics;
          }

          if (!report.accounts) {
            report.accounts = this.buildAccounts(dataPicker.locations);
          }

          if (type.includes('keyword') && showComparison) {
            report.showComparison = showComparison;
          }
          if (type.includes('keyword') && compareToValue) {
            report.compareToValue = compareToValue;
          }

          if (type.includes('qanda') && questionsType) {
            report.questionsType = questionsType;
          }

          if (type.includes('qanda') && periodDaysValue) {
            report.dynamicRange = periodDaysValue;
          }

          return report;
        }),


        switchMap(report => (type != 'performance-insights' && !type?.includes('-location') && !type?.includes('-report')) ? 
                            this.save(report.gid, report) : 
                            this.saveReportInMongo(report))
      );
  }

  buildAccounts(locations : {accountId: string}[])  {
    const {gid} = this._sessionS.getSession();

    const r : {accountId : string, gid : string, locations : any[] }[] = []

    locations.forEach(l =>{
      const idx = r.findIndex(loc => loc.accountId == l?.accountId);
      if (idx > -1) {
        r[idx].locations.push(l)
      } else {
        r.push({
          accountId: l?.accountId,
          gid,
          locations: [l]
        });
      }
    });
    return r;
  }

  async fetchByHash(hash: string): Promise<Report | null> {
    const r = await this.afs.collectionGroup<Report>(REPORTS, ref => ref.where('hash', '==', hash).limit(1)).get().toPromise();
    if(!r.docs.length) {
      return null;
    } else {
      const d = r.docs[0];
      return { id: d.id, 
               ... d.data()} as Report;
    }
    // return this.firestoreS.colWithIds$<Report>(GROUPS + '/' + REPORTS, ref => ref.where('hash', '==', hash).limit(1))
  }


  reportSelectDatesFromReport(report : {startDatetime : string, 
                                          endDatetime : string}) : { start : moment.Moment, 
                                                                       end : moment.Moment } {
    const selectStart = report.startDatetime;
    const selectEnd   = report.endDatetime;

    return {start: moment(selectStart), 
            end:   moment(selectEnd) }
  }

  converDynamicDateComparison(selector: string, reportType?: null): {
    startDatetime: string, endDatetime: string, startDatetimeB: string, endDatetimeB: string
    } {
    const dates = this.getDates(selector, reportType);

    const startDatetime  = dates.startDatetime?.toISOString()
    const endDatetime    = dates.endDatetime?.toISOString()
    const startDatetimeB = dates.startDatetimeB?.toISOString()
    const endDatetimeB   = dates.endDatetimeB?.toISOString()

    return {startDatetime, endDatetime, startDatetimeB, endDatetimeB};
  }

  updateDynamicDates(report : Report, reportId : string, automatedReport : boolean, viewMode = 'new') : void {
    if (!report?.dynamic || !report?.dynamicRange) 
      return

    const dynamic = report?.dynamicRange;
    const dates = this.getDates(dynamic, report.reportType);

    report.startDatetime = moment(dates.startDatetime).utc().toISOString();
    report.endDatetime   = moment(dates.endDatetime).utc().toISOString();

    if (report.reportType?.includes('comparison')) {
      // FIXME: Fix typing
      // @ts-ignore
      report.startDatetimeB =  dates.startDatetimeB;
      // FIXME: Fix typing
      // @ts-ignore
      report.endDatetimeB   =  dates.endDatetimeB;
    }
    this._updateReport(viewMode, reportId, report, automatedReport);
  }

  getDates(selector : string, reportType : string) : {startDatetime  : moment.Moment | null,
                                                      endDatetime    : moment.Moment | null,
                                                      startDatetimeB : moment.Moment | null,
                                                      endDatetimeB   : moment.Moment | null } {
    const now = moment()

    let startDatetime  : moment.Moment | null = null;
    let endDatetime    : moment.Moment | null = null;
    let startDatetimeB : moment.Moment | null = null;
    let endDatetimeB   : moment.Moment | null = null;

    const date  = now.clone().utc().toDate();
    const year  = date.getFullYear();
    const month = date.getMonth();
    const day   = date.getDate();

    switch (selector) {
      case 'LastMonth':
        startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
        endDatetime   = moment(new Date(year, month, 0).setHours(0, 0, 0));
        break
        
      case 'ThisMonth':
        startDatetime = moment(new Date(year, month , 1).setHours(0, 0, 0));
        endDatetime   = moment(new Date(year, month, day).setHours(0, 0, 0));
        break

      case 'Last7':
        startDatetime = moment(new Date(year, month, day - 7).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);
        
        endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        break

      case 'Last30':
        startDatetime = moment(new Date(year, month, day - 30).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);
        
        endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        break

      case 'Last90':
        startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);
        
        endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        break

      case 'LastYear':
        startDatetime = moment(new Date(year - 1, 0, 1));
        endDatetime   = moment(new Date(year - 1, 11, 31));
        break

      case 'YTD': 
        // Year-to-Date (beginning of the year to current date)
        startDatetime = moment(new Date(year, 0, 1).setHours(0, 0, 0));
        
        endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        break

      case 'OneMonth':
        startDatetime = now.clone().subtract(1, 'month').startOf('month');
        endDatetime   = moment(new Date().setDate(0));
        break

      case 'Last3Month':
        startDatetime = now.clone().subtract(3, 'month').startOf('month');
        endDatetime   = moment(new Date().setDate(0));
        break

      case 'OneYear':
        startDatetime = moment(new Date(year, 0, 1));
        endDatetime   = moment(new Date().setDate(0));
        break
    
      case 'LastMonth-PrevMonth':
        startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
        endDatetime   = moment(new Date(year, month, 0).setHours(0, 0, 0));
        
        startDatetimeB = moment(new Date(year, month - 2, 1).setHours(0, 0, 0));
        endDatetimeB   = moment(new Date(year, month - 1, 0).setHours(0, 0, 0));
        break

      case 'LastMonth-YearAgoMonth':
        startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
        endDatetime   = moment(new Date(year, month, 0).setHours(0, 0, 0));
        
        startDatetimeB = moment(new Date(year - 1, month - 1, 1).setHours(0, 0, 0));
        endDatetimeB   = moment(new Date(year - 1, month, 0).setHours(0, 0, 0));
        break

      case 'Last90-PrevLast90':
        startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);

        endDatetime = moment(new Date().setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);

        startDatetimeB = moment(new Date(year, month, day - 180).setHours(0, 0, 0));
        startDatetimeB = this.removeSevenDays(startDatetimeB, reportType);

        endDatetimeB = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
        endDatetimeB = this.removeSevenDays(endDatetimeB, reportType);
        break

    }

    return {startDatetime, 
            endDatetime, 
            startDatetimeB, 
            endDatetimeB }
  }

  removeSevenDays(date : moment.Moment, reportType : string) : moment.Moment {
    if (reportType?.includes('rollup') || reportType?.includes('comparison'))
      return moment(date).subtract(7, 'days');
    
    return date;
  }

  addSevenDays(date : moment.Moment, reportType : string) : moment.Moment {
    if (reportType?.includes('rollup') || reportType?.includes('comparison'))
      return moment(date).add(7, 'days');
    
    return date;
  }

  private _updateReport(viewMode, reportId : string, report : Report, automatedReport : boolean) : void {
    if (viewMode == 'new') {
      this.updateReportsInMongo(reportId, { ...report, 
                                            startDatetime: report.startDatetime, 
                                            endDatetime: report.endDatetime })
    } else {
      this.update(
        automatedReport,
        report.gid,
        reportId,
        { ...report, 
          startDatetime: report.startDatetime, 
          endDatetime:   report.endDatetime })
    }


  }

  reportToDataPicker(report: Report, reportId: string, automatedReport= false, viewMode = 'new', refresh = false) : DataPicker {
    if(!refresh)
      this.updateDynamicDates(report, reportId, automatedReport, viewMode)
    
    const dataPicker: DataPicker = {
      range: {
        start: report.startDatetime,
        end:   report.endDatetime
      },
      rangeB: null,
      aggregation: 'Month'
    };
    
    if (report.reportType.includes('comparison') || report.reportType === 'grade') {
      const startMoment = moment(report.startDatetimeB);
      const endMoment   = moment(report.endDatetimeB);

      dataPicker.rangeB = {
        start:  `${startMoment.year()}-${startMoment.month() +1}-${startMoment.date()}`,
        end:    `${endMoment.year()}-${endMoment.month() +1}-${endMoment.date()}`
      };
    }

    let locations : LocationRef[] = []
    if (report.accounts) {
      report.accounts.forEach(account => {
        locations = locations.concat(account.locations);
      });
    } else if (report.locations) {
      locations = report.locations;
    }
    dataPicker.locations = locations;
    return dataPicker;
  }


  selectDatesFromDataPicker(dataPicker: DataPicker, rangeB = false)  : { start : moment.Moment, 
                                                                           end : moment.Moment } {
    const r = rangeB ? dataPicker.rangeB : dataPicker.range
    return {
        start: moment(r.start).utc(false).utcOffset(0),
          end: moment(r.end)  .utc(false).utcOffset(0)
      }
  }


  convertReportDate(report: Report) : Report {
    if (!report)
      return

    report.startDatetime = this.convertDate(report.startDatetime);
    report.endDatetime   = this.convertDate(report.endDatetime);

    if (report.reportType === 'comparison') {
      report.startDatetimeB = this.convertDate(report.startDatetimeB);
      report.endDatetimeB   = this.convertDate(report.endDatetimeB);
    }

    return report;
  }

  convertDate(date) {
    return (new ZuluToDatePipe()).transform(date);
  }


  countLocation(element) : number {
    let counter = 0;
    element?.accounts?.forEach(account => {
      counter += account?.locations?.length;
    });
    return counter;
  }

  // TODO: Unused, remove?
  // getReports(gid : string, reports) {
  //   const reports$ = [];
  //   reports.forEach(r => {
  //     reports$.push(this.afs.collection<any>(`${GROUPS}/${gid}/${REPORTS}`,
  //       ref => ref.orderBy('reportName')).doc(r.id).snapshotChanges().pipe(
  //         map(report => {
  //           const data = report.payload.data();
  //           if (!data) {
  //             return;
  //           }
  //           // tslint:disable-next-line: no-string-literal
  //           data['id'] = report.payload.id;
  //           return data;
  //         }),
  //     ));
  //   });
  //   return combineLatest(reports$);
  // }

  correctDateAggregate(reportDataPicker : DataPicker) : 'Month' | 'Week' | 'Day' {
    const start = moment(reportDataPicker.range.start).utc(true);
    const end   = moment(reportDataPicker.range.end).utc(true);
    const diff = end.diff(start, 'days') + 1;
    
    if (diff >= 90)
      return 'Month'
    else if (diff >= 32)
      return 'Week'
    else if (diff <= 31)
      return 'Day'
    else
      return 'Month'
  }
 
  periodChange(period : 'period' | 'year' | 'month', startDateTime, endDateTime) {
    const startDate = moment(startDateTime).utc();
    const endDate = moment(endDateTime).utc();
    const data = {
      difference: null,
      startDateB: null,
      endDateB: null,
      rangeBDisabled: true
    }
    switch (period) {
      case 'period':
        data.difference = endDate.diff(startDate, 'days');
        data.endDateB = this.getFormattedMomentDate(startDate.subtract(1, 'days'));
        data.startDateB = this.getFormattedMomentDate(moment(data.endDateB).subtract(data.difference, 'days'));
        break;
      case 'year':
        data.startDateB = this.dateS.momentSubtractYearReport(startDate, 1);
        data.endDateB = this.dateS.momentSubtractYearReport(endDate, 1);
        break;
      case 'month':
        data.difference = endDate.diff(startDate, 'days');
        data.startDateB = this.getFormattedMomentDate(startDate.subtract(1, 'month').startOf('month'));
        data.endDateB = this.getFormattedMomentDate(moment(data.startDateB).endOf('month'));
        break;
      default:
        data.rangeBDisabled = false;
        break;
    }
    return data;
  }

  getFormattedMomentDate(date : moment.Moment) : string {
    return `${date['year']()}-${date['month']() +1}-${date['date']()}`;
  }
  
  getFormattedStringDate(date : string) : string {
    return date?.includes('T') ? `${date.split('T')[0]}` : date;
  }

}
