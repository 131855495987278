/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bulk-post.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../local-posts/local-posts.component.ngfactory";
import * as i3 from "../local-posts/local-posts.component";
import * as i4 from "../../services/post.service";
import * as i5 from "@angular/router";
import * as i6 from "../../services/session.service";
import * as i7 from "../../services/snackbar.service";
import * as i8 from "../../services/location.service";
import * as i9 from "../post-management/post-management.component.ngfactory";
import * as i10 from "../post-management/post-management.component";
import * as i11 from "@angular/common";
import * as i12 from "./bulk-post.component";
var styles_BulkPostComponent = [i0.styles];
var RenderType_BulkPostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulkPostComponent, data: {} });
export { RenderType_BulkPostComponent as RenderType_BulkPostComponent };
function View_BulkPostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-local-posts", [["dashboardType", "BULK_POST"]], null, null, null, i2.View_LocalPostsComponent_0, i2.RenderType_LocalPostsComponent)), i1.ɵdid(1, 245760, null, 0, i3.LocalPostsComponent, [i4.PostService, i5.ActivatedRoute, i6.SessionService, i7.SnackbarService, i8.LocationService, i5.Router], { dashboardType: [0, "dashboardType"] }, null)], function (_ck, _v) { var currVal_0 = "BULK_POST"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulkPostComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-management", [["dashboardType", "BULK_POST"]], null, null, null, i9.View_PostManagementComponent_0, i9.RenderType_PostManagementComponent)), i1.ɵdid(1, 114688, null, 0, i10.PostManagementComponent, [i5.ActivatedRoute, i7.SnackbarService, i1.ChangeDetectorRef, i4.PostService, i1.Renderer2, i6.SessionService], { dashboardType: [0, "dashboardType"] }, null)], function (_ck, _v) { var currVal_0 = "BULK_POST"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BulkPostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPostComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPostComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isNewPost; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isNewPost; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BulkPostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bulk-post", [], null, null, null, View_BulkPostComponent_0, RenderType_BulkPostComponent)), i1.ɵdid(1, 114688, null, 0, i12.BulkPostComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BulkPostComponentNgFactory = i1.ɵccf("app-bulk-post", i12.BulkPostComponent, View_BulkPostComponent_Host_0, {}, {}, []);
export { BulkPostComponentNgFactory as BulkPostComponentNgFactory };
