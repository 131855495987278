import { __awaiter, __generator } from "tslib";
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
// app
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
// TODO: Unused, remove
// interface jsAuto {
//   columns: { dataKey: number, header: any}[],
//   data: [{colSpan: number, content: string, rowSpan: number,styles: any }][]
// }
var PdfService = /** @class */ (function () {
    function PdfService(_sessionS) {
        var _this = this;
        this._sessionS = _sessionS;
        this.blobToBase64 = function (blob) { return __awaiter(_this, void 0, void 0, function () {
            var reader;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        reader = new FileReader();
                        reader.readAsDataURL(blob);
                        return [4 /*yield*/, new Promise(function (resolve) {
                                reader.onloadend = function () {
                                    resolve(reader.result);
                                };
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
    }
    PdfService.prototype.base64AndSave = function (id, filename, urlWeb, saveToDisk) {
        if (saveToDisk === void 0) { saveToDisk = true; }
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var logoHeight, content, logo, image, relation, orientation, doc, height, width, downloadBlob, converToBase64, typeImage, h, w;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        logoHeight = 115;
                        window['html2canvas'] = html2canvas;
                        content = document.getElementById(id);
                        if (!content) {
                            return [2 /*return*/];
                        }
                        logo = ((_a = this._sessionS.getDomain().branding) === null || _a === void 0 ? void 0 : _a.pdfLogo) || '';
                        this._i2Canvas(id + " i");
                        return [4 /*yield*/, html2canvas(content, {
                                allowTaint: false,
                                scale: 2,
                                removeContainer: true,
                                useCORS: true,
                                logging: false,
                            })];
                    case 1:
                        image = _b.sent();
                        relation = image.width / image.height;
                        orientation = 'p';
                        if (relation > 1) {
                            orientation = 'l';
                        }
                        if (logo) {
                            doc = new jsPDF(orientation, 'px', [image.width, image.height + logoHeight]);
                        }
                        else {
                            doc = new jsPDF(orientation, 'px', [image.width, image.height]);
                        }
                        doc.internal.pageSize.height = image.height;
                        doc.internal.pageSize.width = image.width;
                        height = doc.internal.pageSize.height;
                        width = doc.internal.pageSize.width;
                        if (!logo) return [3 /*break*/, 4];
                        return [4 /*yield*/, fetch(logo).then(function (d) { return d.blob(); })];
                    case 2:
                        downloadBlob = _b.sent();
                        return [4 /*yield*/, this.blobToBase64(downloadBlob)];
                    case 3:
                        converToBase64 = _b.sent();
                        typeImage = downloadBlob.type.split('/')[1].toUpperCase();
                        doc.addImage(converToBase64, typeImage, 20, 10, 300, logoHeight);
                        doc.addImage(image, 'JPEG', 5, 135, width, height - logoHeight, 'a', 'FAST');
                        return [3 /*break*/, 5];
                    case 4:
                        doc.addImage(image, 'JPEG', 5, 20, width, height, 'a', 'FAST');
                        _b.label = 5;
                    case 5:
                        doc.setFontSize(40);
                        doc.setTextColor(229, 229, 229);
                        for (h = 200; h < height; h = h + 900) {
                            for (w = 200; w < width; w = w + 700) {
                                doc.text(w, h, urlWeb, null, 45);
                            }
                        }
                        if (saveToDisk) {
                            doc.save(filename + ".pdf");
                        }
                        return [2 /*return*/, doc.output('datauristring')];
                }
            });
        });
    };
    PdfService.prototype._i2Canvas = function (idContainer) {
        var _this = this;
        var icontainers = Array.prototype.slice.call(document.querySelectorAll('#' + idContainer), 0) || [], trimquotes_rg = /^["']*|["']+$/g;
        icontainers.forEach(function (icontainer) {
            var canvas = document.createElement("canvas"), iconparent = icontainer.parentNode, compsty = window.getComputedStyle(icontainer, ':before'), fontColor = window.getComputedStyle(icontainer, null).color, textSize = compsty.fontSize, charAwe = compsty.content.replace(trimquotes_rg, "");
            canvas.setAttribute("style", "height: 1em; width: .7em;");
            canvas.setAttribute("class", icontainer.className);
            iconparent.replaceChild(canvas, icontainer);
            var ctx = canvas.getContext("2d");
            _this._prepareContext(ctx, fontColor, textSize);
            var charW = Math.ceil(ctx.measureText(charAwe).width);
            canvas.style.width = charW + 'px';
            canvas.width = charW;
            canvas.height = parseInt(textSize);
            if (icontainer.className.includes('icon-respose')) {
                var style = window.innerWidth < 1550 ? 'height: 20px; width: 20px; margin-right: 10px;' : 'height: 25px; width: 25px; margin-right: 15px;';
                canvas.setAttribute('style', style);
            }
            _this._prepareContext(ctx, fontColor, textSize);
            ctx.scale(1, 1);
            ctx.fillText(charAwe, canvas.width / 2, canvas.height / 2);
        });
    };
    PdfService.prototype._prepareContext = function (context, fontColor, textSize) {
        context.font = textSize + " 'Font Awesome 5 Pro'";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = fontColor;
    };
    PdfService.prototype.exportTableToPDF = function (idTable, filename, title, exclude, formatName, columnStyles) {
        if (exclude === void 0) { exclude = false; }
        if (formatName === void 0) { formatName = null; }
        if (columnStyles === void 0) { columnStyles = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var doc, idElement, res, indexFormat_1, headers_1, columns;
            return __generator(this, function (_a) {
                doc = new jsPDF('p', 'pt', [window.innerWidth, window.innerHeight]);
                doc.text(40, 35, title);
                if (exclude) {
                    idElement = idTable.substring(1);
                    res = doc.autoTableHtmlToJson(document.getElementById(idElement));
                    indexFormat_1 = -1;
                    headers_1 = res.data[0].reduce(function (response, data, i) {
                        if (formatName && formatName.toLowerCase().trim().includes(data.content.toLowerCase().trim()))
                            indexFormat_1 = i;
                        if (data._element.classList.contains('exclude'))
                            response.push(i);
                        return response;
                    }, []);
                    columns = res.columns.filter(function (c, i) { return !headers_1.some(function (postion) { return postion === i; }); });
                    doc.autoTable({
                        columns: columns,
                        cellWidth: { name: { columnWidth: 'auto' }, country: { columnWidth: 'auto' } },
                        body: res.data.map(function (d) {
                            d[0].content = d[0].content.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                            if (indexFormat_1 >= 0) {
                                var chararacter = d[indexFormat_1].content.match(/[A-Za-z]\d/)[0].split('');
                                var message = d[indexFormat_1].content.split(/[A-Za-z]\d/);
                                var result = "" + message[0] + chararacter[0] + " " + chararacter[1] + message[1];
                                d[indexFormat_1].content = result;
                            }
                            return d;
                        }),
                        columnStyles: columnStyles
                    });
                }
                else {
                    doc.autoTable({
                        html: idTable,
                        body: [
                            [{ content: 'Text', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }],
                        ]
                    });
                }
                doc.save(filename + ".pdf");
                return [2 /*return*/];
            });
        });
    };
    PdfService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PdfService_Factory() { return new PdfService(i0.ɵɵinject(i1.SessionService)); }, token: PdfService, providedIn: "root" });
    return PdfService;
}());
export { PdfService };
