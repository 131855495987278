import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService<T> {

  private _dataSource: BehaviorSubject<T | null> = new BehaviorSubject(null);
  currentMessage$ = this._dataSource.asObservable();

  /**
   * funcion que setea información en mi behavior subject
   * @param d tipo de informacion de conjuto dinamico dependiendo de la clase que se necesite
   */
  setData(d: T) {
    this._dataSource.next(d);
  }

  getData(): Observable<T> {
      return this.currentMessage$;
  }

}
