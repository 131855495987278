// dep
import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {Router} from '@angular/router';
import * as moment from 'moment';

// app
import {ReportService} from '../../services/report.service';
import SavedAccount from '../../constants/firestore/saved-account';
import Report, {
  COMPARISON_LIST,
  COMPARISON_LIST_DEFAULT,
  DATAPICKER_REPORT_DEFAULT,
  FormReport,
  REPORT_TYPE
} from '../../constants/firestore/report';
import AccountReport from '../../services/account-report';
import {DatesService} from '../../services/dates.service';
import {DataPicker} from '../../constants/data-picker';
import {DEFAULT_CHART_DATE_SELECT} from '../../constants/datePicker';
import { SessionService } from 'src/app/services/session.service';


@Component({
  selector: 'app-modal-create-report',
  templateUrl: './modal-create-report.component.html',
  styleUrls: ['./modal-create-report.component.scss'],
})
export class ModalCreateReportComponent implements OnDestroy, AfterViewChecked {
  public reportType: REPORT_TYPE;
  public form: FormGroup;
  public formReport: FormReport;
  public step = 0;
  public stepTotal = 0;
  public location: any;
  public accounts: SavedAccount[];
  public isEdit: boolean;
  public reportEdit: Report;
  public rangeBDisabled = true;
  public dataPicker: DataPicker = DATAPICKER_REPORT_DEFAULT;
  public selectDate: any;
  public compareList = COMPARISON_LIST;
  public defaultSelected = COMPARISON_LIST_DEFAULT;
  public metricsChecked: { name: string; checked: boolean }[] = [];
  public difference: number;
  public showLoader: boolean;
  public DynamicRangeControl: FormControl = new FormControl('LastMonth', [Validators.required]);
  public FrecuencyControl: FormControl = new FormControl('WeeklyOn', [Validators.required]);
  public RecipentsControl: FormControl = new FormControl('', [Validators.email]);
  public RecipentsEmails: string[] = [];
  public repeatOn = '0';
  
  private accountsReport: AccountReport[] = [];

  constructor(
    private _sessionS : SessionService, 
    private reportS: ReportService,
    private fb: FormBuilder,
    private dateS: DatesService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public dialogRef: MatDialogRef<ModalCreateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data?.data) {
      this.reportType = this.data.data.reportType as REPORT_TYPE;
      this.isEdit = !!this.data.data.edit;
      this.stepTotal = 2;
    } else {
      this.reportType = this.data.reportType as REPORT_TYPE;
      this.isEdit = !!this.data.edit;
      this.stepTotal = 2;
    }

    if (this.data.stepLocations) {
      this.step++;
    }

    if (this.reportType === 'grade') {
      const now = new Date().toISOString()
      this.dataPicker.rangeB = {start: now, end: now};
    }

    if (this.isEdit) {
      this.reportEdit = this.data.data ? this.data.data.edit :  this.data.edit
      this.reportEdit = this.reportS.convertReportDate(this.reportEdit);
      // FIXME: Solve typing
      // @ts-ignore
      this.selectDate = this.reportS.reportSelectDatesFromReport(this.reportEdit);
    } else {
      this.selectDate = DEFAULT_CHART_DATE_SELECT;
    }

    this.initForm(this.reportEdit);
  }

  handleDateSelected($event: any): void {
    const dateStart = moment($event.range.start);
    const dateEnd = moment($event.range.end);
    this.startDateTime.setValue(dateStart);
    this.endDateTime.setValue(dateEnd);
  }

  handleDateBSelected($event: DataPicker): void {
    if (this.reportType === 'comparison') {
      this.startDateTimeB.setValue($event.rangeB.start);
      this.endDateTimeB.setValue($event.rangeB.end);
    }
  }


  changeLockDates($event: any): void {
    this.lockDates.setValue($event.checked);
  }

  changeDynamicDates($event: any): void {
    this.dynamic.setValue($event.checked);
  }

  next(): void {
    this.step++;
  }


  periodChange($event: MatSelectChange): void {
    const startDate = moment(this.startDateTime.value).utc();
    const endDate = moment(this.endDateTime.value).utc();
    switch ($event.value) {
      case 'period': {

        this.difference = endDate.diff(startDate, 'days');
        let periodStart;
        let periodEnd;

        const years = endDate.diff(startDate, 'years');
        const months = endDate.diff(startDate, 'months') - (years * 12);
        const days = endDate.diff(startDate, 'days')
        if (months && (months + 1) === 12) {
          periodStart = this.dateS.momentSubtractYearReport(startDate, 1);
          periodEnd = this.dateS.momentSubtractYearReport(endDate, 1);
        } else if (months && months > 0) {
          if (months === 1) {
            periodStart = this.dateS.momentSubtractMonthOfReport(startDate, months, true);
            periodEnd = this.dateS.momentSubtractMonthOfReport(endDate, months);
          } else {
            periodStart = this.dateS.momentSubtractMonthReport(startDate, months + 1);
            periodEnd = this.dateS.momentSubtractMonthReport(endDate, months + 1);
          }
        } else {
          if (days >= 29) {
            periodStart = this.dateS.momentSubtractMonthOfReport(startDate, 1, true);
            periodEnd = this.dateS.momentSubtractMonthOfReport(endDate, 1);
          } else {
            periodStart = this.dateS.momentSubtractReport(startDate, days, 'days');
            periodEnd = this.dateS.momentSubtractReport(endDate, days, 'days');
          }
        }

        this.dataPicker.rangeB.start = periodStart;
        this.dataPicker.rangeB.end = periodEnd;
        if (this.startDateTimeB) {
          this.startDateTimeB.setValue(periodStart);
          this.endDateTimeB.setValue(periodEnd);
        }
        this.period.setValue($event.value);
        break;
      }
      case 'year': {
        const yearStart = this.dateS.momentSubtractYearReport(startDate, 1);
        const yearEnd = this.dateS.momentSubtractYearReport(endDate, 1);
        this.dataPicker.rangeB.start = yearStart;
        this.dataPicker.rangeB.end = yearEnd;
        this.startDateTimeB.setValue(yearStart);
        this.endDateTimeB.setValue(yearEnd);
        this.period.setValue($event.value);
        break;
      }
      case 'month': {
        this.difference = endDate.diff(startDate, 'days');
        let monthStart;
        let monthEnd;

        if (this.difference > 31) {
          monthStart = this.dateS.momentSubtractMonthReport(startDate, 1);
          monthEnd = this.dateS.momentSubtractMonthReport(endDate, 1);
        } else {
          monthStart = this.dateS.momentSubtractMonthOfReport(startDate, 1, true);
          monthEnd = this.dateS.momentSubtractMonthOfReport(endDate, 1, false);
        }
        this.dataPicker.rangeB.start = monthStart;
        this.dataPicker.rangeB.end = monthEnd;
        this.startDateTimeB.setValue(monthStart);
        this.endDateTimeB.setValue(monthEnd);
        this.period.setValue($event.value);
        break;
      }
      default:
        this.rangeBDisabled = false;
        break;
    }
    this.period.setValue($event.value);
  }

  selectedLocations($event: AccountReport[]): void {
    this.accountsReport = $event;
  }

  save(): void {
    if (this.accountsReport.length == 0) {
      return;
    }
    this.showLoader = true;
    this.formReport = this.form.value;
    const startDatetime = moment(this.startDateTime.value).utc().toISOString();
    const endDatetime = moment(this.endDateTime.value).utc().toISOString();


    if (this.isEdit) {
      this.reportEdit.accounts = this.accountsReport;
      this.reportEdit.startDatetime = startDatetime;
      this.reportEdit.endDatetime = endDatetime;
      this.reportEdit.reportName = this.formReport.reportName;
      this.reportEdit.lockDates = this.formReport.lockDates;
      this.reportEdit.dynamic = this.formReport.dynamic;

      if (this.scheduleReport.value) {
        this.reportEdit = this.configAutomatedReport(this.reportEdit);
        if (!this.reportEdit) return;

        this.reportEdit.scheduleReport = this.formReport.scheduleReport;
      }

      if (this.reportEdit.dynamic){
        this.reportEdit.dynamicRange = this.DynamicRangeControl.value;
        const dateDynamic = this.reportS.converDynamicDateComparison(this.reportEdit.dynamicRange);
        this.reportEdit.startDatetime = dateDynamic.startDatetime;
        this.reportEdit.endDatetime = dateDynamic.endDatetime;
      }

      delete this.reportEdit['needToUpgrade'];
      delete this.reportEdit['invalidLocations'];
      delete this.reportEdit['duplicatedLocations'];
      this.reportS.update(false, this.reportEdit.gid, this.reportEdit.id, this.reportEdit).then(() => {
        this.showLoader = false;
        this.dialogRef.close();
      });
    } else {
      this.reportS.getHash().subscribe(hashToken => {
        let report: Report = null;
        if (this.reportType !== 'Review Assistant') {
          report = {
            reportName: this.formReport.reportName,
            lockDates: this.formReport.lockDates,
            dynamic: this.formReport.dynamic,
            accounts: this.accountsReport,
            reportType: this.reportType,
            startDatetime,
            endDatetime,
            createdAt: this.dateS.nowTimestamp,
            token: hashToken.token,
            hash: hashToken.hash,
            uid: this._sessionS.getSession().uid,
            gid: this._sessionS.getSession().gid,
            shared: false,
            sharedOnly: false,
            scheduleReport: this.formReport.scheduleReport,
          };

          if (this.scheduleReport.value) {
            report = this.configAutomatedReport(report);
            if (!report) return;
          }

          if (this.dynamic.value) {
            report.dynamicRange = this.DynamicRangeControl.value;
            const dateDynamic = this.reportS.converDynamicDateComparison(this.DynamicRangeControl.value);
            report.startDatetime = dateDynamic.startDatetime;
            report.endDatetime = dateDynamic.endDatetime;
          }
        }

        if (this.reportType === 'Review Assistant') {
          report = {
            accounts: this.accountsReport,
            reportType: this.reportType,
            reportName: this.formReport.reportName,
            createdAt: this.dateS.nowTimestamp,
            token: hashToken.token,
            hash: hashToken.hash,
            uid: this._sessionS.getSession().uid,
            gid: this._sessionS.getSession().gid,
            shared: false,
            sharedOnly: false,
            scheduleReport: false
          }
        }

        this.reportS.save(report.gid, report).then(reportSaved => {
          this.showLoader = false;
          this.dialogRef.close();
          this.router.navigateByUrl(`/report/${report.gid}/${reportSaved.id}`).then();
        });
      });
    }
  }

  configAutomatedReport(report: Report): Report {
    if (!this.FrecuencyControl.valid && this.repeatOn === '' || !this.DynamicRangeControl.valid || this.RecipentsEmails.length === 0) {
      return
    }

    report.frecuencySelector = this.FrecuencyControl.value;
    report.repeatOn = this.repeatOn;
    report.dynamicRange = this.DynamicRangeControl.value;
    report.recipentsEmails = this.RecipentsEmails;
    report.shared = true;
    report.domain = this._sessionS.getDomain().xDomainName;
    const date = moment().utc().toDate(), year = date.getFullYear(), month = date.getMonth(), day = date.getDate(), dayOfWeek = date.getDay();
    if (report.frecuencySelector === 'WeeklyOn') {
      if (dayOfWeek >= Number(report.repeatOn)) {
        const daysToDate =  7 - (dayOfWeek - Number(report.repeatOn));
        report.scheduleDate = moment(new Date(year, month, day + daysToDate )).utc().toISOString();
      } else {
        const daysToDate = Number(report.repeatOn) - dayOfWeek;
        report.scheduleDate = moment(new Date(year, month, day + daysToDate )).utc().toISOString();
      }
    } else {
      if (day >= Number(report.repeatOn)) {
        report.scheduleDate = moment(new Date(year, month + 1, Number(report.repeatOn) )).utc().toISOString();
      } else {
        report.scheduleDate = moment(new Date(year, month, Number(report.repeatOn) )).utc().toISOString();
      }
    }
    return report
  }

  initForm(report: Report): void {
    if (report) {
      this.accountsReport = report.accounts
      this.form = this.fb.group({
        reportName: [report.reportName, Validators.required],
        scheduleReport: [report.scheduleReport ? report.scheduleReport : false],
        startDate: [''],
        endDate: [''],
        lockDates: [false],
        dynamic: [false],
      });

      if (report.startDatetime && report.endDatetime) {
        this.dataPicker = {
          range: {start: report.startDatetime, end: report.endDatetime},
        };
      }
    } else {
      this.form = this.fb.group({
        reportName: ['', Validators.required],
        scheduleReport: [false],
        startDate: [''],
        endDate: [''],
        lockDates: [false],
        dynamic: [false],
      });
    }
  }

  addNewRecipent(): void {
    if (this.RecipentsControl.valid) {
      this.RecipentsEmails.push(this.RecipentsControl.value);
      this.RecipentsControl.setValue('');
    }
  }

  deleteRecipent(email: string): void {
    this.RecipentsEmails = this.RecipentsEmails.filter( e => e !== email)
  }

  get reportName(): AbstractControl {
    return this.form.get('reportName');
  }

  get scheduleReport(): AbstractControl {
    return this.form.get('scheduleReport');
  }

  get startDateTime(): AbstractControl {
    return this.form.get('startDate');
  }

  get endDateTime(): AbstractControl {
    return this.form.get('endDate');
  }

  get startDateTimeB(): AbstractControl {
    return this.form.get('startDateB');
  }

  get period(): AbstractControl {
    return this.form.get('period');
  }

  get endDateTimeB(): AbstractControl {
    return this.form.get('endDateB');
  }

  get lockDates(): AbstractControl {
    return this.form.get('lockDates');
  }

  get dynamic(): AbstractControl {
    return this.form.get('dynamic');
  }

  selectedMetrics($event: { name: string, checked: boolean }[]): void {
    this.metricsChecked = $event;
  }

  completeSteeper(): void {
    if (this.reportType !== 'comparison') {
      if (this.completeStep1) {
        this.save();
      }
    }
  }

  get completeStep1(): boolean {
    if (this.scheduleReport.value) {
      if (this.FrecuencyControl.valid && this.repeatOn !== '' && this.DynamicRangeControl.valid && this.RecipentsEmails.length > 0) {
        // const repeatOnNumber = Number(this.repeatOn);
        return true;
      }
    } else if (this.form.valid ) {
      return true;
    }
  }

  get completeStep2(): boolean {
    return this.accountsReport?.length > 0;
  }

  get completeStep3(): boolean {
    return this.metricsChecked?.length > 0;
  }

  ngOnDestroy(): void {
    this.accountsReport = [];
    this.metricsChecked = [];
    this.reportEdit = null;
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
