
// dep
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';

// app
import { SessionService } from './session.service'
import { AuthProxyService } from './auth.proxy.service';
import { Domain } from '../constants/firestore/domain';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
    constructor(
        private _router: Router,
        private _sessionS : SessionService,
        private _authProxyS : AuthProxyService
  ) {
  }

  private get _branding() : Domain['branding'] {
    return this._sessionS.getDomain().branding;
  }

  get supportMailURL() : string {
    const b = this._branding;
    return b?.contact_us_link || `mailto:${b?.contact_us}`;
  }

  // TODO: These methods should be moved to a separate NavigationService
  // remove after moving all their implementations
  private _openTextReaderNewTab(link_name : string, routePostfix : string) : void {
    const {content} = this._sessionS.getDomain();
    window.open(content?.[link_name] || `/text-reader/${routePostfix}`, '_blank');
  }
  
  async goToTermsOfService(): Promise<void> {
    return this._openTextReaderNewTab('terms_service_link', 'terms-and-conditions');
  }

  async goToPrivacyPolicy(): Promise<void> {
    return this._openTextReaderNewTab('privacy_policy_link', 'privacy-policy');
  }

  async goToPaymentPolicy(): Promise<void> {
    return this._openTextReaderNewTab('payment_policy_link', 'payment-policy');
  }

  goToContactSupport() : void {
    window.open(this.supportMailURL)
  }

  goToRegister(): Promise<boolean> {
    const {signUp_url} = this._branding;
    if(signUp_url)
      return this._router.navigateByUrl(signUp_url);
    else
      return this._router.navigate(['register']);
  }

  goToLogin(): Promise<boolean>  {
    return this._router.navigate(['/login'])
  }

  goToAdmin() : void {
    window.open('https://admin.maplabs.com/', '_blank', 'noopener');
  }

  goToPricingWeb(): void {
    const url = this._branding.pricing_url || 'https://www.maplabs.com/pricing/';
    window.open(url, '_blank');
  }
  
  goToProfessionalServicesWeb(): void {
    // should be replaced with a variable if possible, retrieving this path from a config file to allow whitelabels to work too
    window.open('https://calendly.com/maplabs/discovery-call', '_blank');
  }

  /**
   * Signouts and reloads the page
   */
  async signOut() : Promise<void> {
    await this._authProxyS.signOut();
  }

}
 