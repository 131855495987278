import { __awaiter, __extends, __generator, __read, __spread, __values } from "tslib";
// dep
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, timer } from 'rxjs';
import * as _ from 'lodash';
// app
import { LocationService } from '../../services/location.service';
import { SubscriptionService } from '../../services/subscription.service';
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ObservationService } from '../../services/observation.service';
import { ModalService } from '../../services/modal.service';
import * as constants_locations from '../../constants/firestore/account-location';
import * as constants_plans from '../../constants/firestore/plan';
import { PaginatorComponent } from '../charts/paginator/paginator.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AlertType } from 'src/app/components/alert.component';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { markedPositions } from 'src/app/helpers/utils.helpers';
var LocationsComponent = /** @class */ (function (_super) {
    __extends(LocationsComponent, _super);
    function LocationsComponent(_router, _route, _detectStrategy, _spinnerService, _snack, _locationService, _accountService, _apiSubscription, _obsService, _modalService, _angularLocation, _sessionS) {
        var _this = _super.call(this) || this;
        _this._router = _router;
        _this._route = _route;
        _this._detectStrategy = _detectStrategy;
        _this._spinnerService = _spinnerService;
        _this._snack = _snack;
        _this._locationService = _locationService;
        _this._accountService = _accountService;
        _this._apiSubscription = _apiSubscription;
        _this._obsService = _obsService;
        _this._modalService = _modalService;
        _this._angularLocation = _angularLocation;
        _this._sessionS = _sessionS;
        _this.STATUS_CHANGE_PLAN = constants_plans.STATUS_CHANGE_PLAN;
        _this.LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
        // public authOk           = constants_locations.VERIFICATION_OK;
        _this.authUnauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
        _this.authUnauthorized_2 = constants_locations.VERIFICATION_UNAUTHORIZED_2;
        _this.authNotFound = constants_locations.VERIFICATION_NOT_FOUND;
        _this.authNotFound_2 = constants_locations.VERIFICATION_NOT_FOUND_2;
        _this.accountError = constants_locations.VERIFICATION_ACCOUNT_ERROR;
        _this.verifRequired = constants_locations.VERIFICATION_REQUIRED;
        _this.totalLocations = [];
        _this.filteredLocationIds = [];
        // public countLocations: number; // TODO: Unused, remove.
        _this.locations = [];
        _this.accountsOptions = [];
        _this.filterAccountsControl = new FormControl('');
        _this.allLocationCheck = false;
        _this.allChecked = false;
        // public checkedLocations; // TODO: Unused, remove. 
        _this.listCheckLocations = [];
        _this.upgradeCheckSelect = false;
        _this.downgradeCheckSelect = false;
        _this.locationsUser = [];
        _this.textSearch = null;
        _this.size = 10;
        _this.page = 1;
        _this.pagination = {
            items: [],
            per_page: _this.size,
            page: _this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        _this.selectedSearch = new FormControl();
        _this.filtered = false;
        _this.loadingTable$ = new BehaviorSubject(true);
        _this.NotFoundSearch$ = new BehaviorSubject(false);
        _this.timeout = null;
        _this.errorAccount = false;
        _this._hasAccountsFilter = false;
        _this._refreshTimerStarted = false;
        // private _previousPageable: Pageable = null;
        _this.session$ = _this._sessionS.session$;
        _this._subscribeSafe(_this.session$, function (_) { return _this._updateTable(); });
        // Reload the locations table when we trigger a location plan change from this
        // component or the plan change is externally started by TrialMessageComponent
        var is_first_value = true;
        _this._subscribeSafe(_this._locationService.someLocationChanged$, function (_) {
            if (is_first_value)
                // Avoid running _init() on component creation, as ngOnInit already
                // runs it.
                is_first_value = false;
            else
                _this._init();
        });
        return _this;
    }
    LocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this._locationService.getPaginate(), function (res) { return _this._paginate = res; });
        this._init();
        this._subscribeSafe(this._router.events, function (event) {
            if (event instanceof NavigationEnd) {
                _this.loadingTable$.next(true);
                _this.accountObjectId = event.url.split('/')[2];
                _this.filterAccountsByAccountId();
                _this._init();
            }
        });
    };
    LocationsComponent.prototype.ngAfterViewInit = function () {
        this.manualPage = 1;
        this.errorMessage = false;
        this._detectStrategy.detectChanges();
    };
    LocationsComponent.prototype.ngOnDestroy = function () {
        this._locationService.reset();
        _super.prototype.ngOnDestroy.call(this);
    };
    LocationsComponent.prototype.resetListSelect = function () {
        this.listCheckLocations = [];
    };
    LocationsComponent.prototype.onAddAccount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._router.navigate(['accounts'])];
                    case 1:
                        _a.sent();
                        this._obsService.sendAddAccount();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.openDeleteDialog = function (location) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var domainCompany, gid, accountWasDeleted, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        domainCompany = this._sessionS.getDomain().branding.company_name;
                        return [4 /*yield*/, this._modalService.openConfirmModal("Are you sure you want to disconnect " + ((_a = location) === null || _a === void 0 ? void 0 : _a.locationName) + " from " + domainCompany + "?", "Note: this only disconnects the profile from " + domainCompany + "; it does not delete your business profile from Google Maps. You can always re-add your business profile to " + domainCompany + ".")];
                    case 1:
                        if (!(_b.sent())) {
                            return [2 /*return*/];
                        }
                        gid = this._sessionS.getSession().gid;
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 7, 8, 9]);
                        this._spinnerService.loading$.next(true);
                        return [4 /*yield*/, this._locationService.deleteLocation(gid, this.accountObjectId, location)];
                    case 3:
                        accountWasDeleted = _b.sent();
                        if (!accountWasDeleted) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._router.navigate(['/accounts'])];
                    case 4:
                        _b.sent();
                        return [2 /*return*/];
                    case 5:
                        this.listCheckLocations = [];
                        this._init();
                        return [2 /*return*/];
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        err_1 = _b.sent();
                        console.error('Error deleting locations', err_1);
                        return [3 /*break*/, 9];
                    case 8:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype._initPaginator = function () {
        this._paginate = { page: 1, size: 10 };
        this.paginatorComponent.reset();
        // this._previousPageable = null;
    };
    // apply filter from search
    LocationsComponent.prototype.applyFilter = function ($event) {
        var _this = this;
        this.NotFoundSearch$.next(false);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            if (!$event) {
                _this.filtered = false;
                _this.filteredLocationIds = [];
                _this.loadingTable$.next(true);
                _this._initPaginator();
                _this._getData(_this._paginate);
            }
            else {
                if ($event[$event.length - 1] !== ' ') {
                    if (!_this.filtered)
                        _this._initPaginator();
                    _this.filtered = true;
                    _this.loadingTable$.next(true);
                    var text = $event.toLowerCase();
                    _this.searchText = text;
                    _this._updateData(_this._paginate, text);
                }
            }
        }, 350);
    };
    LocationsComponent.prototype._getData = function ($event, locationsIds) {
        if (locationsIds === void 0) { locationsIds = []; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, gid, isAdmin, r, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this._sessionS.getSession(), gid = _a.gid, isAdmin = _a.isAdmin;
                        if (!isAdmin) {
                            locationsIds = this.locationsUser;
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this._locationService.fetchAccountLocationsPaginated(gid, this.accountObjectId, $event, locationsIds)];
                    case 2:
                        r = _b.sent();
                        // this._previousPageable = { size: r['perPage'], 
                        //                            page: r['currentPage'] };
                        this.pagination = {
                            items: r['items'],
                            per_page: r['perPage'],
                            page: r['currentPage'],
                            hasNext: r['currentPage'] < r['totalPages'],
                            hasPrev: r['currentPage'] > 1,
                            pages: r['totalPages'],
                            total: r['totalItems']
                        };
                        this.locations = this._formatData(r['items']);
                        // this.countLocations = r['totalItems']; // TODO: Unused, remove.
                        this._updateTable();
                        return [3 /*break*/, 5];
                    case 3:
                        err_2 = _b.sent();
                        console.error(err_2);
                        return [3 /*break*/, 5];
                    case 4:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype._updateData = function ($event, text) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var gid, response, filteredAccount, locationIds;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this._locationService.getLocationsIdsByStringQuery(gid, text)];
                    case 1:
                        response = _e.sent();
                        filteredAccount = ((_b = (_a = response) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.find(function (account) { return account.accountId === _this.accountObjectId; })) || null;
                        this.filteredLocationIds = ((_d = (_c = filteredAccount) === null || _c === void 0 ? void 0 : _c.locations) === null || _d === void 0 ? void 0 : _d.map(function (location) { return location.locationId; })) || [];
                        locationIds = this.filteredLocationIds;
                        if (locationIds.length > 0) {
                            this._getData($event, locationIds);
                        }
                        else {
                            this.NotFoundSearch$.next(true);
                            this.loadingTable$.next(false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.setElementSwitch = function (element) {
        var _a, _b, _c, _d, _e, _f;
        var returnStatus = '';
        if (((_c = (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.locationState) === null || _c === void 0 ? void 0 : _c.isVerified) === false) {
            returnStatus = this.verifRequired;
        }
        else if (((_e = (_d = element) === null || _d === void 0 ? void 0 : _d.lastCheck) === null || _e === void 0 ? void 0 : _e.status) && element.lastCheck.status !== 'OK') {
            returnStatus = (element === 'NOT AUTHORIZED' || element === 'NOT_AUTHORIZED' ? 'NOT_AUTHORIZED' :
                element === 'NOT FOUND' || element === 'NOT_FOUND' ? 'NOT_FOUND' : element.lastCheck.status);
        }
        else if ((_f = element) === null || _f === void 0 ? void 0 : _f.errorLog) {
            returnStatus = 'errorLog';
        }
        return returnStatus;
    };
    // check for nested objects
    LocationsComponent.prototype._nestedFilterCheck = function (search, data, key) {
        if (typeof data[key] === 'object') {
            for (var k in data[key]) {
                if (data[key][k] !== null) {
                    search = this._nestedFilterCheck(search, data[key], k);
                }
            }
        }
        else {
            search += data[key];
        }
        return search;
    };
    LocationsComponent.prototype.toggleCheckAll = function (check) {
        var _this = this;
        this.allChecked = check.checked;
        this._getTotalLocations();
        this.locations.forEach(function (location) {
            if (_this.allChecked) {
                var isChecked = _this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
                if (!isChecked) {
                    _this.listCheckLocations.push({
                        locationId: location.locationId,
                        subscriptionType: location.subscriptionType,
                        pendingChange: location.pendingChange,
                        locationName: location.locationName,
                        location: location.location,
                        checked: _this.allChecked
                    });
                }
            }
            else {
                _this.listCheckLocations = [];
                _this.allLocationCheck = false;
                // Uncheck location if it's checked.
                _this.listCheckLocations = _this.listCheckLocations.filter(function (l) { return l.locationId !== location.locationId; });
            }
            location.isChecked = _this.allChecked;
        });
        this._isUpgradeDowngradeActions();
    };
    LocationsComponent.prototype._getTotalLocations = function () {
        var _this = this;
        // FIXME: Multiple subscriptions!
        this._subscribeSafe(this._locationService.accountAllLocations$, function (locations) {
            if (_this.filteredLocationIds.length > 0)
                _this.totalLocations = locations.filter(function (l) { return _this.filteredLocationIds.includes(l.locationId); });
            else
                _this.totalLocations = locations;
        });
    };
    LocationsComponent.prototype.selectLocation = function (location, check) {
        var isChecked = this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
        if (isChecked) {
            this.listCheckLocations = this.listCheckLocations.filter(function (l) { return l.locationId !== location.locationId; });
        }
        else {
            this.listCheckLocations.push({
                locationId: location.locationId,
                pendingChange: location.pendingChange,
                subscriptionType: location.subscriptionType,
                locationName: location.locationName,
                location: location.location,
                checked: check.checked
            });
        }
        this._isUpgradeDowngradeActions();
    };
    LocationsComponent.prototype._isUpgradeDowngradeActions = function () {
        if (this.listCheckLocations.length > 0) {
            this.upgradeCheckSelect = !!this.listCheckLocations.find(function (l) { return l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE; });
            this.downgradeCheckSelect = !!this.listCheckLocations.find(function (l) { return l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC ||
                l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE; });
        }
        else {
            this.upgradeCheckSelect = false;
            this.downgradeCheckSelect = false;
        }
    };
    LocationsComponent.prototype._generateBulkSubscriptionErrorMessage = function (unverifiedLocations, locationsChange) {
        var _this = this;
        var message = "<div class='txt--left'>\n    The following location(s) need to be verified in your Google Business Profile before you can update the subscription(s).\n      <div>\n      <br>\n        <ul>";
        unverifiedLocations.forEach(function (location) {
            var _a, _b, _c;
            message += "<div>" + ((_a = location) === null || _a === void 0 ? void 0 : _a.locationName) + "</div><li>" + _this._locationService.formatAddress((_c = (_b = location) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.address) + "</li>";
        });
        message += "</ul>\n      </div>";
        if (locationsChange.length >= 1) {
            message += "Would you like to proceed with updating the other locations?";
        }
        message += "</div>";
        return message;
    };
    // Function change to plan locations
    LocationsComponent.prototype.changePlan = function (locations) {
        return __awaiter(this, void 0, void 0, function () {
            var locationsChange, unverifiedLocations, ret;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._sessionS.getSession().isMember) {
                            this._modalService.openWarningModalNoPermissions();
                            return [2 /*return*/];
                        }
                        locationsChange = [];
                        unverifiedLocations = [];
                        if (!Array.isArray(locations)) {
                            if (!this._validateVerifiedStatus(locations, true))
                                return [2 /*return*/];
                            locationsChange = [{ locationId: locations.locationId,
                                    accountId: this.accountObjectId }];
                        }
                        else {
                            locations.forEach(function (location) {
                                var _a, _b, _c;
                                if (((_c = (_b = (_a = location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.locationState) === null || _c === void 0 ? void 0 : _c.isVerified) == false) {
                                    unverifiedLocations.push(location);
                                }
                                else {
                                    locationsChange.push({ locationId: location,
                                        accountId: _this.accountObjectId });
                                }
                            });
                        }
                        if (!(unverifiedLocations.length >= 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._modalService.openAlertModal("Verification required in Google Business Profile", this._generateBulkSubscriptionErrorMessage(unverifiedLocations, locationsChange), AlertType.ERROR, null, 'Ok', function () { return true; })];
                    case 1:
                        ret = _a.sent();
                        if (!ret || locationsChange.length === 0) {
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this._apiSubscription.flowChangeLocationsPlan(locationsChange)
                        // locationService.someLocationsChanged will fire if a plan change was made
                    ];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.refreshLocation = function (locationId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.filtered)
                            this.applyFilter(this.searchText);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this._locationService.locationRefreshAllDeps(this.accountObjectId, locationId, this._sessionS.getSession().gid)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        if (this.filtered)
                            this.applyFilter(this.searchText);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.refreshSelected = function () {
        var _this = this;
        this._snack.openInfo("The selected locations are being refreshed. Please wait while this is completed.");
        var count_refreshed = 0;
        this.listCheckLocations.forEach(function (l) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._locationService.locationRefreshAllDeps(this.accountObjectId, l.locationId, this._sessionS.getSession().gid)];
                    case 1:
                        _a.sent();
                        count_refreshed++;
                        return [2 /*return*/];
                }
            });
        }); });
        if (count_refreshed === this.listCheckLocations.length) {
            this._snack.openSuccess("The selected locations was updated");
            this.resetListSelect();
        }
        if (this.filtered)
            this.applyFilter(this.searchText);
    };
    LocationsComponent.prototype.deleteSelected = function () {
        return __awaiter(this, void 0, void 0, function () {
            var domainCompany, gid, accountWasDeleted, _a, _b, e, e_1_1;
            var e_1, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(this.listCheckLocations.length === 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.openDeleteDialog(_.head(this.listCheckLocations))];
                    case 1:
                        _d.sent();
                        return [2 /*return*/];
                    case 2:
                        domainCompany = this._sessionS.getDomain().branding.company_name;
                        return [4 /*yield*/, this._modalService.openConfirmModal("Are you sure you want to disconnect these business profiles from " + domainCompany + "?", "Note: this only disconnects the profiles from " + domainCompany + "; it does not delete your business profile from Google Maps. You can always re-add your business profiles to " + domainCompany + ".")];
                    case 3:
                        if (!(_d.sent())) {
                            return [2 /*return*/];
                        }
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, , 15, 16]);
                        this._spinnerService.loading$.next(true);
                        gid = this._sessionS.getSession().gid;
                        accountWasDeleted = false;
                        _d.label = 5;
                    case 5:
                        _d.trys.push([5, 10, 11, 12]);
                        _a = __values(markedPositions(this.listCheckLocations)), _b = _a.next();
                        _d.label = 6;
                    case 6:
                        if (!!_b.done) return [3 /*break*/, 9];
                        e = _b.value;
                        return [4 /*yield*/, this._locationService.deleteLocation(gid, this.accountObjectId, e.value, { notifyChange: e.isLast,
                                deleteEmptyAccount: e.isLast })];
                    case 7:
                        accountWasDeleted = _d.sent();
                        _d.label = 8;
                    case 8:
                        _b = _a.next();
                        return [3 /*break*/, 6];
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 12];
                    case 11:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 12:
                        this.locations = this.locations.filter(function (l) { return !_this.listCheckLocations.find(function (lc) { return lc.locationId === l.locationId; }); });
                        this.listCheckLocations = [];
                        this.allChecked = false;
                        if (!accountWasDeleted) return [3 /*break*/, 14];
                        return [4 /*yield*/, this._router.navigate(['/accounts'])
                            // this._init() will be called by someLocationsChanged subscription. 
                        ];
                    case 13:
                        _d.sent();
                        _d.label = 14;
                    case 14: return [3 /*break*/, 16];
                    case 15:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 16: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.getAccountsFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, this._accountService.getAccountPaginate(this._sessionS.getSession().gid, { page: 1, size: 1000 }, [])];
                    case 1:
                        r = _a.sent();
                        this.accountsOptions = __spread(r.items);
                        this.filterAccountsByAccountId();
                        this._hasAccountsFilter = true;
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 4];
                    case 3:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.selectAll = function () {
        var _this = this;
        if (!this.allLocationCheck) {
            this.allLocationCheck = true;
            this.totalLocations.forEach(function (location) {
                var result = _this.listCheckLocations.find(function (l) { return l.locationId == location.locationId; });
                if (!result) {
                    _this.listCheckLocations.push({
                        locationId: location.locationId,
                        pendingChange: location.pendingChange,
                        subscriptionType: location.subscriptionType,
                        locationName: location.locationName,
                        checked: _this.allChecked,
                        location: location.location,
                    });
                }
            });
            this.locations.forEach(function (location) {
                _this.allChecked = true;
                location.isChecked = _this.allChecked;
            });
        }
        else {
            this.listCheckLocations = [];
            this.allLocationCheck = false;
            this.locations.forEach(function (location) {
                _this.allChecked = false;
                location.isChecked = _this.allChecked;
            });
        }
    };
    LocationsComponent.prototype._formatData = function (locations) {
        var e_3, _a;
        var isTrial = this._sessionS.getSession().isTrial;
        var existSelected = this.listCheckLocations.length > 0;
        locations = locations.filter(Boolean);
        var _loop_1 = function (location_1) {
            if (location_1) {
                var status_1 = '';
                if (location_1.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
                    status_1 = (isTrial ?
                        LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL :
                        LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL);
                }
                else {
                    status_1 = location_1.subscriptionType;
                }
                location_1['subscriptionStatus'] = status_1;
                if (existSelected)
                    location_1.isChecked = !!this_1.listCheckLocations.find(function (l) { return l.locationId === location_1.locationId; });
            }
        };
        var this_1 = this;
        try {
            for (var locations_1 = __values(locations), locations_1_1 = locations_1.next(); !locations_1_1.done; locations_1_1 = locations_1.next()) {
                var location_1 = locations_1_1.value;
                _loop_1(location_1);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (locations_1_1 && !locations_1_1.done && (_a = locations_1.return)) _a.call(locations_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.loadingTable$.next(false);
        return locations;
    };
    LocationsComponent.prototype.handleReload = function ($event) {
        this.loadingTable$.next(true);
        this._locationService.setPaginate($event);
        this._paginate = $event;
        if (this.filtered) {
            this.applyFilter(this.searchText);
        }
        else {
            this._getData($event);
        }
    };
    LocationsComponent.prototype.goLocation = function (accountId, location) {
        if (!this._validateVerifiedStatus(location))
            return;
        this._router.navigate(['/account', accountId, 'location', location.locationId, 'insights']);
    };
    LocationsComponent.prototype._validateVerifiedStatus = function (location, typeIsSubscription) {
        if (typeIsSubscription === void 0) { typeIsSubscription = false; }
        var _a, _b, _c;
        if (((_c = (_b = (_a = location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.locationState) === null || _c === void 0 ? void 0 : _c.isVerified) == false) {
            this._modalService.openErrorModal("Verification required in Google Business Profile", "<div class='txt--left'>\n        " + (typeIsSubscription ?
                'In order to update your profile subscription, you need to verify your location.' :
                'This location requires verification, which prevents access to your dashboard.') + "\n        <div>\n          <br>Step 1: Log in to your Google Business Profile and verify your location.\n          <br>Step 2: Once verified, return and click the refresh button.\n        </div>\n      </div>");
            return false;
        }
        return true;
    };
    LocationsComponent.prototype.goNewTab = function (accountId, location, event) {
        if (event)
            event.stopPropagation();
        if (!this._validateVerifiedStatus(location))
            return;
        var url = this._router.serializeUrl(this._router.createUrlTree(['/account', accountId, 'location', location.locationId, 'insights']));
        window.open(url, '_blank');
    };
    LocationsComponent.prototype.goNewTabSelected = function () {
        var e_4, _a;
        try {
            for (var _b = __values(this.listCheckLocations), _c = _b.next(); !_c.done; _c = _b.next()) {
                var location_2 = _c.value;
                this.goNewTab(this.accountObjectId, location_2);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
    };
    LocationsComponent.prototype.getNextDueDate = function () {
        var nextDueDate = this._sessionS.getSession().subscription.nextDueDate;
        if (nextDueDate) {
            var d = this._locationService.formatDates(nextDueDate).split('-');
            return d[1] + "/" + d[2] + "/" + d[0];
        }
        return '-';
    };
    LocationsComponent.prototype.cancelChangePlan = function (location) {
        return __awaiter(this, void 0, void 0, function () {
            var locationCancel, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openConfirmModal('Cancel Listing Plan Change', ("This location is scheduled to change subscription plan to " + location.pendingChange.nextPlan + " " +
                            ("on " + this.getNextDueDate() + ". ") +
                            ("Do you want to cancel this scheduled change and keep the location as " + location.subscriptionType + "?")))];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 8];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, 7, 8]);
                        this._spinnerService.loading$.next(true);
                        locationCancel = { locationId: location.locationId,
                            accountId: this.accountObjectId };
                        return [4 /*yield*/, this._apiSubscription.applyChangePlan([locationCancel], location.subscriptionType)];
                    case 3:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        return [4 /*yield*/, this._getData(this._paginate)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this._locationService.fetchAccountLocationsAndEmit(this._sessionS.getSession().gid, this.accountObjectId)];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 6:
                        err_3 = _a.sent();
                        console.error(err_3);
                        return [3 /*break*/, 8];
                    case 7:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    LocationsComponent.prototype.filterChanged = function (account) {
        this.listCheckLocations = [];
        this.allLocationCheck = true;
        this.selectAll();
        this.loadingTable$.next(true);
        this.accountObjectId = account[0].accountId;
        this._router.navigate(["accounts/" + this.accountObjectId + "/locations"]);
    };
    LocationsComponent.prototype.filterAccountsByAccountId = function () {
        var _this = this;
        var _a, _b, _c;
        var matchingAccount = (_a = this.accountsOptions) === null || _a === void 0 ? void 0 : _a.find(function (account) { var _a; return ((_a = account) === null || _a === void 0 ? void 0 : _a.accountId) === _this.accountObjectId; });
        this.selectedAccount = matchingAccount || null;
        this.labelAccountsFiltered = ((_c = (_b = matchingAccount) === null || _b === void 0 ? void 0 : _b.account) === null || _c === void 0 ? void 0 : _c.accountName) || null;
    };
    LocationsComponent.prototype._updateTable = function () {
        var _this = this;
        // data for table
        var session = this._sessionS.getSession();
        this.displayedColumns = ['id', 'company'];
        if (session.subscription.pricingVersion < 3) {
            this.displayedColumns.push('subscription');
        }
        if (session.isAdmin) {
            this.displayedColumns.push('actions');
        }
        // if (this.auth.isAdmin) {
        //   this.displayedColumns = ['id', 'company', 'subscription', 'actions'];
        // } else {
        //   this.displayedColumns = ['id', 'company', 'subscription'];
        // }
        this.dataSource = new MatTableDataSource(this.locations);
        this.dataSource.filterPredicate = function (data, filter) {
            var accumulator = function (currentTerm, key) {
                return _this._nestedFilterCheck(currentTerm, data, key);
            };
            var dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            var transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };
    };
    LocationsComponent.prototype._init = function () {
        var _this = this;
        var _a, _b;
        this.filtered = false;
        this.textSearch = '';
        var errorAccount = this._route.snapshot.queryParamMap.get("errorAccount");
        this.errorAccount = (((_a = errorAccount) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true");
        if (errorAccount !== null) {
            var url = this._router.url.split('?')[0];
            this._angularLocation.go(url);
        }
        this.accountObjectId = this._route.snapshot.paramMap.get('accountObjectId');
        var _c = this._sessionS.getSession(), gid = _c.gid, user = _c.user;
        this.locationsUser = ((_b = (user.accounts || []).find(function (acc) {
            return acc.accountId === _this.accountObjectId;
        })) === null || _b === void 0 ? void 0 : _b.locations.map(function (loc) { return loc.locationId; })) || [];
        // TODO: 5s forced delay, why? (single shot)
        if (!this._refreshTimerStarted) {
            this._refreshTimerStarted = true;
            this._subscribeSafe(timer(5000), function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, , 3, 4]);
                            return [4 /*yield*/, this._getData(this._paginate)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this._locationService.fetchAccountLocationsAndEmit(gid, this.accountObjectId)];
                        case 2:
                            _a.sent();
                            this._updateTable();
                            return [3 /*break*/, 4];
                        case 3:
                            this._refreshTimerStarted = false;
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        }
        if (!this._hasAccountsFilter)
            this.getAccountsFilter();
    };
    return LocationsComponent;
}(BaseComponent));
export { LocationsComponent };
