// dep
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

// app
import { LocationService } from './location.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {
  constructor(
    private router: Router,
    private locationService: LocationService,
    private _sessionS : SessionService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    const {gid, isTrial} = await this._sessionS.waitSession();

    const {locationId, accountId} = route.parent.params;

    const location = await this.locationService.fetchLocation(gid, accountId, locationId)

    if (!isTrial && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
      await this.router.navigate(['/account', accountId , 'location', locationId, 'insights']);
      return false;
    }
    return true;
  }
}
