import { MatDialogRef } from '@angular/material';
import * as uuid from 'uuid';
var ModalProductsItemComponent = /** @class */ (function () {
    function ModalProductsItemComponent(dialogRef, data) {
        var _a, _b, _c, _d, _e, _f;
        this.dialogRef = dialogRef;
        this.data = data;
        this.linkType = ['Order Online', 'Buy', 'Learn More', 'Get offer'];
        this.priceRange = false;
        this.buttonAdded = false;
        this.productNameInvalid = false;
        this.currencyCode = ((_c = (_b = (_a = data) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.currencyCode) ? (_f = (_e = (_d = data) === null || _d === void 0 ? void 0 : _d.productItem) === null || _e === void 0 ? void 0 : _e.price) === null || _f === void 0 ? void 0 : _f.currencyCode : '';
    }
    ModalProductsItemComponent.prototype.showLinkText = function (type) {
        this.buttonAdded = (type !== 'hideButton');
        this.buttonType = type;
    };
    ModalProductsItemComponent.prototype.apply = function () {
        if (this.productName) {
            this.productNameInvalid = false;
            var productItem = {
                itemId: uuid.v4(),
                labels: [
                    {
                        displayName: this.productName,
                        description: this.productDescription || ''
                    }
                ],
                price: {
                    currencyCode: "USD",
                    units: this.productPrice || ""
                },
            };
            var data = { productItem: productItem };
            this.dialogRef.close(data);
        }
        else {
            this.productNameInvalid = true;
        }
    };
    return ModalProductsItemComponent;
}());
export { ModalProductsItemComponent };
