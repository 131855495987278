// GMB Location Object (See Google specs)

export default interface AccountLocation {
  [x: string]: any;

  gid: string;
  locationId: string;
  locationName: string;
  address: any;
  subscriptionType: LOCATION_SUBSCRIPTION_TYPE;
  metadata: { mapsUrl: string, newReviewUrl: string };
}

export enum LOCATION_SUBSCRIPTION_TYPE {
  FREE = 'FREE',
  ULTIMATE_TRIAL = 'ULTIMATE TRIAL',

  ESSENTIAL = 'ESSENTIAL',
  ULTIMATE = 'ULTIMATE',
  BASIC = 'BASIC',
}

export const LOCATION_SUBSCRIPTION_TYPE_PriceOrder = [
  LOCATION_SUBSCRIPTION_TYPE.FREE,
  LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL,
  LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL,
  LOCATION_SUBSCRIPTION_TYPE.BASIC,
  LOCATION_SUBSCRIPTION_TYPE.ULTIMATE
] as const


// TODO: Rename to Subscription.Status
export enum GROUP_SUBSCRIPTION_TYPE {
  TRIAL = 'TRIAL',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  BLOCKED = 'BLOCKED'
}

export const VERIFICATION_OK = 'OK';
// export const VERIFICATION_NO_DATA = 'NO DATA';
export const VERIFICATION_UNAUTHORIZED = 'NOT AUTHORIZED';
export const VERIFICATION_UNAUTHORIZED_2 = 'NOT_AUTHORIZED';

export const VERIFICATION_NOT_FOUND = 'NOT FOUND';
export const VERIFICATION_NOT_FOUND_2 = 'NOT_FOUND';

// export const VERIFICATION_UNKNOWN = 'UNKNOWN';
export const VERIFICATION_ACCOUNT_ERROR = 'ACCOUNT GAUTH ERROR';
export const VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED';