import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss']
})
export class DialogTemplateComponent implements OnInit {
  @Input() apply: Function;
  @Input() title: string;
  @Input() actionText: string;
  @Input() color: 'primary' | 'accent' | 'warn';

  ngOnInit() {
    if (!this.color) {
      this.color = 'accent'
    }
  }

  action() {
    this.apply()
  }
}
