<div class="content--full p--tb-20 bg--darkblue d-flex justify-content-center align-items-center flex-column" 
     [ngStyle]="{'background-color': (domain$ | async).branding?.primaryColor || ''}">
  <!--loader-->
  
  <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="loginProgress">
    <mat-spinner></mat-spinner>
  </div>
  
  <div class="logo mb--20 mt--20">
    <a mat-button>
      <img alt="Logo" src="{{ (domain$ | async).branding?.mainLogo || '/assets/images/logo/blankmap.png' }}" width="170" height="59"/>
    </a>
  </div>

  <!-- Interim login implementation -->
  <div class="box box--md box--padding-md box--rounded bg--white mb--20">
    <form >
      <h1 class="txt--xl mb--10 txt--center"><strong>Email Verification</strong></h1>
      <p *ngIf="verified" class="txt--center">Your email has been verified!</p>
      <p *ngIf="!verified" class="txt--center">Your email has been verified already or could not be found.</p>

      <!--log in button-->
      <button *ngIf="verified" mat-raised-button color="primary" class="btn btn--xxl btn--full mb--20" [routerLink]="['/login']"><strong>Login Now</strong></button>
      <button *ngIf="!verified" mat-raised-button color="primary" class="btn btn--xxl btn--full mb--20" (click)="navigationS.goToContactSupport()"><strong>Contact Support</strong></button>
	</form>

  </div>


  <div class="footer footer--noborder flex-column box box--md txt--center">
      <ul class="footer__nav d-flex justify-content-center mb--20">
        <li><a class="txt--white" (click)="navigationS.goToTermsOfService()">Terms and Conditions</a></li>
        <li><a class="txt--white" (click)="navigationS.goToPrivacyPolicy()">Privacy Policy</a></li>
        <li><a class="txt--white" (click)="navigationS.goToPaymentPolicy()">Payment Policy</a></li>
      </ul>
      <p class="txt--normal txt--gray m--0">&copy; {{ (domain$ | async).branding?.company_name }} All Rights Reserved.</p>
  </div>
</div>