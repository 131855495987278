<table class="table table--sm table--last-row-border bg--white">
  <thead>
  <tr class="mat-row no-hover">
    <th class="mat-header-cell txt--left"><strong>Invoice</strong></th>
    <th class="mat-header-cell txt--left"><strong>Date</strong></th>
    <th class="mat-header-cell txt--left"><strong>Amount</strong></th>
    <th class="mat-header-cell txt--left"><strong>Paid</strong></th>
    <th class="mat-header-cell txt--center"><strong>Status</strong></th>
    <th class="mat-header-cell txt--right"><strong>Actions</strong></th>
  </tr>
  </thead>
  <tbody>
  <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">

    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>
    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>
    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>
    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>
    <td class="mat-cell">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
    </td>
    <td class="mat-cell mat-column-actions txt--right">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
    </td>
  </tr>
  </tbody>
</table>
