/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./protocol-scan.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../protocol-list/protocol-list.component.ngfactory";
import * as i3 from "../protocol-list/protocol-list.component";
import * as i4 from "../../services/protocol.service";
import * as i5 from "../../services/location.service";
import * as i6 from "../../services/session.service";
import * as i7 from "../../services/report.service";
import * as i8 from "../../services/modal.service";
import * as i9 from "../../services/snackbar.service";
import * as i10 from "./protocol-scan.component";
var styles_ProtocolScanComponent = [i0.styles];
var RenderType_ProtocolScanComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProtocolScanComponent, data: {} });
export { RenderType_ProtocolScanComponent as RenderType_ProtocolScanComponent };
export function View_ProtocolScanComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { protocolList: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-protocol-list", [], null, [[null, "selectedLogsEmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedLogsEmit" === en)) {
        var pd_0 = (_co.selectedLogs.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProtocolListComponent_0, i2.RenderType_ProtocolListComponent)), i1.ɵdid(2, 8634368, [[1, 4], ["protocolList", 4]], 0, i3.ProtocolListComponent, [i4.ProtocolService, i5.LocationService, i6.SessionService, i7.ReportService, i8.ModalService, i1.ChangeDetectorRef, i9.SnackbarService], { displayedColumns: [0, "displayedColumns"], ColumnsSize: [1, "ColumnsSize"], isScan: [2, "isScan"] }, { selectedLogsEmit: "selectedLogsEmit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayedColumns; var currVal_1 = _co.ColumnSize; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProtocolScanComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-protocol-scan", [], null, null, null, View_ProtocolScanComponent_0, RenderType_ProtocolScanComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProtocolScanComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProtocolScanComponentNgFactory = i1.ɵccf("app-protocol-scan", i10.ProtocolScanComponent, View_ProtocolScanComponent_Host_0, {}, { selectedLogs: "selectedLogs" }, []);
export { ProtocolScanComponentNgFactory as ProtocolScanComponentNgFactory };
