import { __awaiter, __extends, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { BaseComponent } from 'src/app/components/base.component';
var UserSettingDialogComponent = /** @class */ (function (_super) {
    __extends(UserSettingDialogComponent, _super);
    function UserSettingDialogComponent(data, dialogRef, usersService) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this.usersService = usersService;
        _this.user = _this.data.user;
        if (_this.user.accounts) {
            _this.accountsSelected = _this.user.accounts;
        }
        return _this;
    }
    UserSettingDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this.usersService.users, function (users) { return _this.users = users; });
    };
    UserSettingDialogComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.accountsSelected) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.usersService.updateUser(this.user.gid, this.user.uid, { accounts: this.accountsSelected })];
                    case 1:
                        _a.sent();
                        this.dialogRef.close({
                            accounts: this.accountsSelected
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    UserSettingDialogComponent.prototype.selectedLocations = function ($event) {
        this.accountsSelected = $event;
    };
    UserSettingDialogComponent.OPTS_DEFAULT = { config: { width: '680px' } };
    return UserSettingDialogComponent;
}(BaseComponent));
export { UserSettingDialogComponent };
