/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-view-bulk-locations-changes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../components/address.component.ngfactory";
import * as i9 from "../../components/address.component";
import * as i10 from "../../services/location.service";
import * as i11 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "./modal-view-bulk-locations-changes.component";
import * as i15 from "../../services/session.service";
import * as i16 from "../../services/bulk-locations-edit.service";
import * as i17 from "../../services/snackbar.service";
var styles_ModalViewBulkLocationsChangesComponent = [i0.styles];
var RenderType_ModalViewBulkLocationsChangesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalViewBulkLocationsChangesComponent, data: {} });
export { RenderType_ModalViewBulkLocationsChangesComponent as RenderType_ModalViewBulkLocationsChangesComponent };
function View_ModalViewBulkLocationsChangesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Location Update Summary"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Location Deleted Summary"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ModalViewBulkLocationsChangesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmb-address", [["class", "text--muted"]], null, null, null, i8.View_AddressComponent_0, i8.RenderType_AddressComponent)), i1.ɵdid(1, 638976, null, 0, i9.AddressComponent, [i10.LocationService], { address: [0, "address"], serviceAreas: [1, "serviceAreas"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.location_address; var currVal_1 = ((_v.parent.context.$implicit.service_area == null) ? null : ((_v.parent.context.$implicit.service_area.places == null) ? null : _v.parent.context.$implicit.service_area.places.placeInfos)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModalViewBulkLocationsChangesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn--badge btn--badge--sm txt--uppercase txt--xs"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMedia(_v.parent.context.$implicit.log.accountId, _v.parent.context.$implicit.log.locationId, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.log.status === "success") ? "accent" : "warn"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.log.status; _ck(_v, 2, 0, currVal_3); }); }
function View_ModalViewBulkLocationsChangesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "list-group-item d-flex justify-content-between align-items-center row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["style", "margin-bottom: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_7)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"], ["style", "width: min-content;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_8)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "link link--blue txt--normal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMedia(_v.context.$implicit.log.accountId, _v.context.$implicit.log.locationId, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View Changes"]))], function (_ck, _v) { var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.location_address) || ((_v.context.$implicit == null) ? null : _v.context.$implicit.service_area)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.log; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.location_name; _ck(_v, 4, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_6)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ModalViewBulkLocationsChangesComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmb-address", [["class", "text--muted"]], null, null, null, i8.View_AddressComponent_0, i8.RenderType_AddressComponent)), i1.ɵdid(1, 638976, null, 0, i9.AddressComponent, [i10.LocationService], { address: [0, "address"], serviceAreas: [1, "serviceAreas"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.location_address; var currVal_1 = ((_v.parent.context.$implicit.service_area == null) ? null : ((_v.parent.context.$implicit.service_area.places == null) ? null : _v.parent.context.$implicit.service_area.places.placeInfos)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModalViewBulkLocationsChangesComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn--badge btn--badge--sm txt--uppercase txt--xs"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showServices(_v.parent.context.$implicit.log.accountId, _v.parent.context.$implicit.log.locationId, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.log.status === "success") ? "accent" : "warn"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.log.status; _ck(_v, 2, 0, currVal_3); }); }
function View_ModalViewBulkLocationsChangesComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "list-group-item d-flex justify-content-between align-items-center mb--20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["style", "margin-bottom: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_12)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"], ["style", "width: min-content;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_13)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "link link--blue txt--normal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showServices(_v.context.$implicit.log.accountId, _v.context.$implicit.log.locationId, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View Changes"]))], function (_ck, _v) { var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.location_address) || ((_v.context.$implicit == null) ? null : _v.context.$implicit.service_area)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.log; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.location_name; _ck(_v, 4, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_11)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalViewBulkLocationsChangesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_10)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataSource.length > 0); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ModalViewBulkLocationsChangesComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmb-address", [["class", "text--muted"]], null, null, null, i8.View_AddressComponent_0, i8.RenderType_AddressComponent)), i1.ɵdid(1, 638976, null, 0, i9.AddressComponent, [i10.LocationService], { address: [0, "address"], serviceAreas: [1, "serviceAreas"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.location_address; var currVal_1 = ((_v.parent.context.$implicit.service_area == null) ? null : ((_v.parent.context.$implicit.service_area.places == null) ? null : _v.parent.context.$implicit.service_area.places.placeInfos)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModalViewBulkLocationsChangesComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn--badge btn--badge--sm txt--uppercase txt--xs"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu(_v.parent.context.$implicit.log.accountId, _v.parent.context.$implicit.log.locationId, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.log.status === "success") ? "accent" : "warn"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.log.status; _ck(_v, 2, 0, currVal_3); }); }
function View_ModalViewBulkLocationsChangesComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "list-group-item d-flex justify-content-between align-items-center row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["style", "margin-bottom: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_17)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"], ["style", "width: min-content;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_18)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "link link--blue txt--normal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu(_v.context.$implicit.log.accountId, _v.context.$implicit.log.locationId, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View Changes"]))], function (_ck, _v) { var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.location_address) || ((_v.context.$implicit == null) ? null : _v.context.$implicit.service_area)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.log; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.location_name; _ck(_v, 4, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_16)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalViewBulkLocationsChangesComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_15)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accounts; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ModalViewBulkLocationsChangesComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmb-address", [["class", "text--muted"]], null, null, null, i8.View_AddressComponent_0, i8.RenderType_AddressComponent)), i1.ɵdid(1, 638976, null, 0, i9.AddressComponent, [i10.LocationService], { address: [0, "address"], serviceAreas: [1, "serviceAreas"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.location_address; var currVal_1 = ((_v.parent.context.$implicit.service_area == null) ? null : ((_v.parent.context.$implicit.service_area.places == null) ? null : _v.parent.context.$implicit.service_area.places.placeInfos)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModalViewBulkLocationsChangesComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn--badge btn--badge--sm txt--uppercase txt--xs"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFetchDialog(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.log.status === "success") ? "accent" : "warn"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.log.status; _ck(_v, 2, 0, currVal_3); }); }
function View_ModalViewBulkLocationsChangesComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "list-group-item d-flex justify-content-between align-items-center mb--20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["style", "margin-bottom: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_22)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"], ["style", "width: min-content;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_23)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "link link--blue txt--normal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFetchDialog(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View Changes"]))], function (_ck, _v) { var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.location_address) || ((_v.context.$implicit == null) ? null : _v.context.$implicit.service_area)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.log; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.location_name; _ck(_v, 4, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_21)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalViewBulkLocationsChangesComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "notification__item text-center a"]], [[4, "backgroundColor", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.handleReload($event);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "w--100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h3", [["style", "text-align: center; color: #fff;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Load more "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "i", [["class", "fas fa-redo-alt"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "fa-spin": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-redo-alt"; var currVal_2 = _ck(_v, 7, 0, _co.loadingNoti); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loadingNoti ? "#ddd" : "#39b54a"); _ck(_v, 0, 0, currVal_0); }); }
function View_ModalViewBulkLocationsChangesComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_20)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_24)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataSource.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.page < _co.pages); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ModalViewBulkLocationsChangesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "dialog-row pb--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_5)), i1.ɵdid(4, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_9)), i1.ɵdid(6, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_14)), i1.ɵdid(8, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_19)), i1.ɵdid(10, 16384, null, 0, i6.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component; _ck(_v, 2, 0, currVal_0); var currVal_1 = "photos"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "service"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "menu"; _ck(_v, 8, 0, currVal_3); }, null); }
function View_ModalViewBulkLocationsChangesComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn--badge btn--badge--sm txt--uppercase txt--xs"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retryFailedLocations() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Retry"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn--badge btn--badge--sm txt--uppercase txt--xs"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.purgeFailedLocations() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Purge"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); var currVal_5 = "warn"; _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 5).disabled || null); var currVal_4 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_ModalViewBulkLocationsChangesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_3)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_4)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "dialog__footer mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(16, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalViewBulkLocationsChangesComponent_25)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showDeleted; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showDeleted; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 10, 0, currVal_3); var currVal_8 = "primary"; _ck(_v, 15, 0, currVal_8); var currVal_9 = ""; _ck(_v, 16, 0, currVal_9); var currVal_10 = (((_co.bulkStatus == "error") || (_co.bulkStatus == "partial")) && (_co.component != "photos")); _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 15).disabled || null); var currVal_5 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 16).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 16).type; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ModalViewBulkLocationsChangesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-view-bulk-locations-changes", [], null, null, null, View_ModalViewBulkLocationsChangesComponent_0, RenderType_ModalViewBulkLocationsChangesComponent)), i1.ɵdid(1, 114688, null, 0, i14.ModalViewBulkLocationsChangesComponent, [i2.MatDialogRef, i2.MatDialog, i10.LocationService, i15.SessionService, i2.MAT_DIALOG_DATA, i16.BulkLocationsEditService, i17.SnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalViewBulkLocationsChangesComponentNgFactory = i1.ɵccf("app-modal-view-bulk-locations-changes", i14.ModalViewBulkLocationsChangesComponent, View_ModalViewBulkLocationsChangesComponent_Host_0, {}, {}, []);
export { ModalViewBulkLocationsChangesComponentNgFactory as ModalViewBulkLocationsChangesComponentNgFactory };
