import { __awaiter, __generator } from "tslib";
// dep
import { EventEmitter, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
// app
import { ReportService } from '../../services/report.service';
import { LocationService } from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from 'src/app/services/modal.service';
import { SessionService } from 'src/app/services/session.service';
import { locationNameToRef } from 'src/app/constants/firestore/location-object';
// import { EventMessageService } from 'src/app/services/event-message.service';
var ComparisonExpansionComponent = /** @class */ (function () {
    function ComparisonExpansionComponent(locationS, reportS, loadingService, modalS, _sessionS) {
        this.locationS = locationS;
        this.reportS = reportS;
        this.loadingService = loadingService;
        this.modalS = modalS;
        this._sessionS = _sessionS;
        this.changeDataPicker = new EventEmitter();
        this.isShared = false;
        this.lockDates = false;
        this.reportId = "";
        this.viewModeChecked = 'legacy';
        this.minDate = null;
        this.maxDate = null;
        this.isReport = false;
        this.report = null;
        this.filterRefresh = new ReplaySubject(1);
        this.first = 0;
        this.locations = null;
        this.panelExpand = false;
        this.isDatePickerFirstOpened = true;
        this.showTooltipsOnDisabledDates = false;
    }
    ComparisonExpansionComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        if (this.metrics) {
            this.metricsNames = this.metrics.map(function (m) { return m.name; });
        }
        if (this.first < 2) {
            this.dataPicker = this.reportDataPicker;
        }
        this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
        this.selectDateB = this.reportS.selectDatesFromDataPicker(this.dataPicker, true);
        this.showTooltipsOnDisabledDates = this.isReport;
        var locations = this.reportDataPicker.locations;
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.locationS.fetchMultipleLocations(this._sessionS.getSession().gid, locations)];
                    case 1:
                        r = _a.sent();
                        this.loadingService.reportAdvanced(1, "Locations");
                        this.locations = r;
                        return [2 /*return*/];
                }
            });
        }); })();
        if (this.isReport && ((_a = this.report) === null || _a === void 0 ? void 0 : _a.dynamic)) {
            this.modalS.openDynamicReportReminderModal();
        }
    };
    ComparisonExpansionComponent.prototype.handleDataPicker = function ($event) {
        if (this.first < 1) {
            this.first++;
            return;
        }
        if ($event.aggregation)
            this.dataPicker.aggregation = $event.aggregation;
        if ($event.range)
            this.dataPicker.range = $event.range;
        if ($event.rangeB)
            this.dataPicker.rangeB = $event.rangeB;
        this.filterRefresh.next(this.dataPicker);
        this.dataPicker.locations = this.reportDataPicker.locations;
        this.changeDataPicker.emit(this.dataPicker);
    };
    ComparisonExpansionComponent.prototype.expandPanel = function (event) {
        this.panelExpand = event;
    };
    ComparisonExpansionComponent.prototype.showAll = function () {
        this.panelExpand = true;
    };
    ComparisonExpansionComponent.prototype.closeAll = function () {
        this.panelExpand = false;
    };
    ComparisonExpansionComponent.prototype.locationUrl = function (location, destiny) {
        if (location) {
            var accountId = location.accountId || locationNameToRef(location.location).accountId;
            var locationId = location.locationId;
            return "/account/" + accountId + "/location/" + locationId + "/" + destiny;
        }
    };
    ComparisonExpansionComponent.prototype.exportCSV = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (event != 'csv')
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 6, 7]);
                        this.isProgressCSV = true;
                        if (!(this.viewModeChecked == 'legacy')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.reportS.handleExportCsvMongo(this.reportId, null, null, null, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.isProgressCSV = false;
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ComparisonExpansionComponent.prototype.showDatepickerModal = function () {
        if (this.isDatePickerFirstOpened) {
            this.isDatePickerFirstOpened = false;
            this.modalS.openDatepickerdates();
        }
    };
    return ComparisonExpansionComponent;
}());
export { ComparisonExpansionComponent };
