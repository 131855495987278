// dep
import {Component, Input} from '@angular/core';

// app
import {Observations} from '../../../constants/firestore/observation';

@Component({
  selector: 'app-workshop-observation',
  templateUrl: './workshop-observation.component.html',
  styleUrls: ['./workshop-observation.component.scss']
})
export class WorkshopObservationComponent {

  @Input() observation: Observations;
  @Input() lastUpdate: any;


}
