<footer *ngIf="showFooter"
  class="footer" 
  [ngClass]="{'custom-position': customPosition}" 
>
  <ul class="footer__nav">
    <li> <span> &copy; {{ (domain$ | async).branding?.company_name }}  {{date | date:'yyyy'}}</span> </li>
    <li><a (click)="navigationS.goToTermsOfService()">Terms and Conditions</a></li>
    <li><a (click)="navigationS.goToPrivacyPolicy()">Privacy Policy</a></li>
    <li><a (click)="navigationS.goToPaymentPolicy()">Payment Policy</a></li>
  </ul>
  <a (click)="navigationS.goToContactSupport()">Help</a>
</footer>
