// dep
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialogRef} from '@angular/material/dialog';

// app
import User from '../../../constants/firestore/user';
import {UserService} from '../../../services/user.service';
import AccountReport from '../../../services/account-report';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-user-setting-dialog',
  templateUrl: './user-setting-dialog.component.html',
  styleUrls: []
})
export class UserSettingDialogComponent extends BaseComponent implements OnInit {
  static readonly OPTS_DEFAULT = { config: { width: '680px'} };
  RESULT_TYPE : boolean;

  user: User;
  users: User[];
  accountsSelected;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: { user : User },
    public dialogRef: MatDialogRef<UserSettingDialogComponent>,
    public usersService: UserService,
  ) {
    super();
    this.user = this.data.user;
    if (this.user.accounts) {
      this.accountsSelected = this.user.accounts;
    }
  }

  ngOnInit() {
    this._subscribeSafe(this.usersService.users, 
                        users => this.users = users);
  }


  async save() : Promise<void> {
    if (this.accountsSelected) {
      await this.usersService.updateUser(this.user.gid, this.user.uid, {accounts: this.accountsSelected});
      this.dialogRef.close({
        accounts: this.accountsSelected
      });
    }
  }

  selectedLocations($event: AccountReport[]) : void {
    this.accountsSelected = $event;
  }

}
