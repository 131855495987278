<div class="trial-message js-trial-message row" *ngIf="(session$ | async).isImpersonating">
    <div class="col">
        <p class="m--4">
            Impersonating user:
            <strong class="m--4">
                {{ (session$ | async).user.email }}
            </strong>
        </p>
    </div>
    <div class="col-auto">
        <button mat-button class="btn btn--white btn--xs ml--10" (click)="navigationS.signOut()">
            <strong>Stop Impersonation...</strong>
        </button>
    </div>
</div>
