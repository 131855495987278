// dep
import { Component } from '@angular/core';

// app
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Messages, string_message } from '../../constants/messages';
// import {VerificationEmailService} from "../../services/verification-email.service";
import { SessionTraceService } from 'src/app/services/session-trace.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { NOTIFICATION_GENERAL, TYPE_LOG_USER } from 'src/app/constants/notifications';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  loading = false // TODO: Not needed after adding SessionService, remove
  registerProgress = false

  domain$ = this._sessionS.domain$
  
  constructor(
    private _auth: AuthService,
    private _subscriptionS: SubscriptionService,
    private _sessionTrace: SessionTraceService,
    private _sessionS: SessionService,
    private _snack: SnackbarService,
    private _notificationS : NotificationService,
    public navigationS : NavigationService
  ) {}


  async handleRegisterWithGoogle(event : Event): Promise<void> {
    // TODO: All of this should be done atomically and server-side
    event.preventDefault();
    try {
      this.registerProgress = true;

      try {
        await this._auth.registerWithGoogleAndSignIn();
      } catch(err_msg) {
        this._snack.openError(err_msg);
        return;
      }
      
      // Note createSubscription needs the AuthSession to be already created
      // so the HTTP interceptor can send the uid/gid parameters in headers
      await this._subscriptionS.createSubscription()
      
      await this._auth.initSession(); 

      this._sessionTrace.onRegistration();

      const {user} = await this._sessionS.waitSession()

      try {
        this._notificationS.saveNotification(string_message(Messages.notifications.USER_ADDED, 
                                            [user.displayName, user.role]), 
                                            NOTIFICATION_GENERAL, 
                                            TYPE_LOG_USER, 
                                            this._notificationS.getMetaTypeLog(TYPE_LOG_USER, user));
      } catch(e) {
        console.error("Error sending notification", e);
      }
  
      this._snack.openSuccess(Messages.register.SUCCESS);
      await this._auth.redirectAfterLogin();
    } finally {
      this.registerProgress = false;
    }
  }

}
