import { __awaiter, __extends, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
// app
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { GoogleService } from '../../services/google.service';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import * as constants_locations from '../../constants/firestore/account-location';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { SubscriptionService } from '../../services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var SidebarComponent = /** @class */ (function (_super) {
    __extends(SidebarComponent, _super);
    function SidebarComponent(_sessionS, route, _locationS, _modalService, googleService, _dataTransfer, spinnerService, router, apiSubscription) {
        var _this = _super.call(this) || this;
        _this._sessionS = _sessionS;
        _this.route = route;
        _this._locationS = _locationS;
        _this._modalService = _modalService;
        _this.googleService = googleService;
        _this._dataTransfer = _dataTransfer;
        _this.spinnerService = spinnerService;
        _this.router = router;
        _this.apiSubscription = apiSubscription;
        _this.location = of({});
        // public locationTitle;
        _this.isRevealPath = false;
        _this.c_auth_ok = constants_locations.VERIFICATION_OK;
        _this.c_auth_unauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
        _this.c_auth_not_found = constants_locations.VERIFICATION_NOT_FOUND;
        _this.domain$ = _this._sessionS.domain$;
        _this.session$ = _this._sessionS.session$;
        return _this;
    }
    SidebarComponent.prototype.ngOnInit = function () {
        this._loadLocation();
        /*this.navigationSubscription = this.router.events.subscribe((e: any) => {
          // If it is a NavigationEnd event re-initalise the component
          if (e instanceof NavigationEnd) {
            this.loadLocation();
          }
        });
    
        this.locationTitle = this.location.subscribe(location => {
          const title = location ? `${location.locationName} @ ${location.location.address.addressLines[0]}`: '';
          this.wl.setTitle(title);
        });*/
    };
    SidebarComponent.prototype.getStatus = function (status) {
        if (!status) {
            return;
        }
        return status.replace(/\_/g, " ");
    };
    SidebarComponent.prototype._loadLocation = function () {
        var _this = this;
        var _a, _b;
        this.locationId = (_a = this.route.snapshot.params) === null || _a === void 0 ? void 0 : _a.locationId;
        this.accountId = (_b = this.route.snapshot.params) === null || _b === void 0 ? void 0 : _b.accountId;
        var gid = this._sessionS.getSession().gid;
        this.location = this._locationS.getRef(gid, this.accountId, this.locationId);
        this._subscribeSafe(this.location, function (location) {
            _this.currentLocation = _this.formData(location);
            _this._dataTransfer.setData(location.locationName);
            _this.placeId = location ? location.location.locationKey.placeId : null;
        });
    };
    SidebarComponent.prototype.formData = function (location) {
        if (location) {
            var isTrial = this._sessionS.getSession().isTrial;
            var st = location.subscriptionType;
            location.subscriptionStatus = (st === LOCATION_SUBSCRIPTION_TYPE.FREE ?
                (isTrial ? LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL :
                    LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) :
                st);
        }
        return location;
    };
    // ngOnDestroy() : void {
    //   // if (this.navigationSubscription) {
    //   //   this.navigationSubscription.unsubscribe();
    //   // }
    // }
    SidebarComponent.prototype.handleReviewLinkGenerator = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.placeId) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.googleService.dynamicUrl(this.placeId).toPromise()];
                    case 1:
                        url = _a.sent();
                        this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url);
                        return [2 /*return*/];
                }
            });
        });
    };
    SidebarComponent.prototype.openDeleteDialog = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loc, domainCompany, gid, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loc = this.currentLocation;
                        if (!loc)
                            return [2 /*return*/];
                        domainCompany = this._sessionS.getDomain().branding.company_name;
                        return [4 /*yield*/, this._modalService.openConfirmModal("Are you sure you want to disconnect " + loc.locationName + " from " + domainCompany + "?", "Note: this only disconnects the profile from " + domainCompany + "; it does not delete your business profile " +
                                ("from Google Maps. You can always re-add your business profile to " + domainCompany + "."))];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, 6, 7]);
                        this.spinnerService.loading$.next(true);
                        gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this._locationS.deleteLocation(gid, this.accountId, loc)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.router.navigate(['/accounts'])];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 5:
                        err_1 = _a.sent();
                        console.error('Error deleting location', err_1);
                        return [3 /*break*/, 7];
                    case 6:
                        this.spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    SidebarComponent.prototype.changePlan = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._sessionS.getSession().isMember) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._modalService.openWarningModalNoPermissions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2: return [4 /*yield*/, this.apiSubscription.flowChangeLocationsPlan([{ locationId: this.currentLocation.locationId,
                                accountId: this.accountId }])];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SidebarComponent;
}(BaseComponent));
export { SidebarComponent };
