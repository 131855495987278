
export enum ProductEnum {
    ESSENTIAL = 'essential',
    BASIC     = 'basic',
    ULTIMATE  = 'ultimate'
}

export enum PackageEnum {
    CORE         = 'pkg_core',
    BULK_ACTIONS = 'pkg_bulk_actions'
}

export enum ChargeTypeEnum {
    CHARGE_PER_UNIT  = 'CHARGE_PER_UNIT',
    CHARGE_FLAT_RATE = 'CHARGE_FLAT_RATE',
    CHARGE_FREE      = 'CHARGE_FREE'
}

export type IPackage = 'pkg_core' | 'pkg_bulk_actions';
export type IProduct = 'essential' | 'basic' | 'ultimate';


export interface IPricingTier {
    locationsQtyMin?: number
    locationsQtyMax?: number
    price?: number
    priceChargeType?: string

    // Fee not returned for main-frontend
    platformFeeChargeType?: string
    platformFee?: number
    description?: string
}

export interface IPricingProduct {
    tiers: IPricingTier[]
    description: string
}


export type Pricing = Partial<{
    // Products is used for:
    //   pricingVersion == 1 (legacy pricing when translated to new pricing matrix)
    //   pricingVersion == 2
    // Pakages is used for:
    //   pricingVersion == 3
    
    [key in IPackage | IProduct]: IPricingProduct
}>

export function pricing_product_get_matching_tier(p : IPricingProduct, locationQty : number) : IPricingTier | null {
    for (const t of p.tiers)
        if ((t.locationsQtyMin === undefined || locationQty >= t.locationsQtyMin) &&
            (t.locationsQtyMax === undefined || locationQty <= t.locationsQtyMax))
            return t
    return null
}
