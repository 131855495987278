// dep
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import * as _ from 'lodash';
import { switchMap } from 'rxjs/operators';

// app
import { Sort } from '../../constants/sort';
import { Pagination } from 'src/app/constants/api-response';
import { IReviewList } from 'src/app/constants/review-list';
import { SIZE_OPTIONS } from 'src/app/constants/filter-reviews';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import { LocationService } from '../../services/location.service';
import { GoogleService } from '../../services/google.service';
import { ModalService } from '../../services/modal.service';
import { PdfService } from '../../services/pdf.service';
import { LoadingService } from '../../services/loading.service';
import { ReviewsService } from '../../services/reviews.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReportService } from 'src/app/services/report.service';
import { InsightsService } from 'src/app/services/insights.service';
import { DataPicker } from 'src/app/constants/data-picker';
import { SessionService } from 'src/app/services/session.service';


@Component({
  selector: 'app-review-report-table',
  templateUrl: './review-report-list.component.html'
})
export class ReviewReportTableComponent implements OnInit {
  @Input() pagination: Pagination;
  @Input() dataPicker: DataPicker
  @Input() locations: any[];
  @Input() reportName: string;
  @Input() isShared = false;
  @Input() refreshFilter;

  public sizeOption = SIZE_OPTIONS;
  public errorMessage: boolean;
  public displayedColumns: string[];
  public isLoading = false;
  public gid: string
  public source: any[];
  public sort: Sort;
  public isProgressCSV = false;
  public isDowloadReviewLinks = false;
  public isReportListAccordionOpen = false

  constructor(
    private _sessionS: SessionService,
    private _route: ActivatedRoute,
    private _locationS: LocationService,
    private _googleService: GoogleService,
    private _modalService: ModalService,
    private _pdfS: PdfService,
    private _loadingService: LoadingService,
    private _reviewService: ReviewsService,
    private _afsStorage: AngularFireStorage,
    private _snack: SnackbarService,
    private _reportS: ReportService,
    private _insightS: InsightsService
  ) {
    this.gid = this._route.snapshot.params.gid || this._sessionS.getSession().gid;

  }

  public ngOnInit() : void {
    this.sort = {
      sortBy: 'reviewCount',
      direction: 'desc'
    };
    this.pagination = {page: 1, per_page: 25, items: [], hasNext: false, hasPrev: false, total: this.locations.length, pages: 0};
    const numberPage: number = Math.ceil(this.locations.length / this.pagination.per_page);
    this.pagination.pages = numberPage;
    if (numberPage > 1) {
      this.pagination.hasNext = true;
    }
    this.refreshFilter?.subscribe(result => {
      this.dataPicker = result;
      this.getTable();
    });
    this.getTable();
  }

  public async getTable(): Promise<void> {
    this.isLoading = true;
    this.displayedColumns = ['location', 'rating', 'total', 'responded', 'unresponded', 'link'];

    if(!this.dataPicker.hasOwnProperty('locations')) {
      this.dataPicker.locations = this.locations
    }

    this._reportS.getTableData(this.gid, 'reviews', {page: this.pagination.page, size: this.pagination.per_page}, this.sort, this.dataPicker).subscribe(
      res => {
        const data = res.data;
        this.pagination = {
          items: data['items'],
          page: data['page'],
          pages: data['totalPages'],
          per_page: data['pageSize'],
          total: data['total'],
          hasNext: data['hasNext'],
          hasPrev: data['hasPrev']
        };
        this.setItems();
        this._loadingService.reportAdvanced(1, null)
        this.isLoading = false;
      }),
      err => {
        this.isLoading = false;
      }
  }

  /**
   * change to previous page
   */
  public onPrev(): void {
    if (!this.pagination.hasPrev) {
      return;
    }
    this.onChangePage(this.pagination.page - 1);
  }

  /**
   * change a next page
   */
  public onNext(): void {
    if (this.pagination.hasNext)
      this.onChangePage(this.pagination.page + 1);
  }

  /**
   * this method change page and consume http resource that filter reviews
   * @param $event
   */
  public onChangePage($event: any): void {
    if ($event instanceof Object) {
      this.pagination.per_page = $event.pageSize;
      this.onChangePage(1);
      return;
    }
    this.pagination.page = $event;
    
    this.getTable()
  }

  public setItems(): void {
    this.pagination.items = this.sort.direction === '' ? _.orderBy(this.pagination.items, ['location'], ['asc']) : _.orderBy(this.pagination.items, [this.sort.sortBy], [this.sort.direction]);
    this.source = this.pagination.items;
  }

  public sortBy($event): void {
    this.sort = {
      sortBy: $event.active,
      direction: $event.direction,
    };
    this.onChangePage(1);
  }

  public showReviewLink(locationId : string): void {
    this._googleService.dynamicUrl(locationId).subscribe(url => {
      this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url);
    });
  }

  public locationUrl(accountId, locationId, destiny): string {
    return `/account/${accountId}/location/${locationId}/${destiny}`;
  }

  public exportPDF(event): void {
    event.stopPropagation();
    this._pdfS.exportTableToPDF('#table-location-container', 'Locations-PDF', 'Locations Table', true, 'Location');
  }

  public exportCSV(event): void {
    event.stopPropagation();

    // TODO: Never used, remove
    // let sort;
    // if (this.sort && this.sort.sortBy) {
    //   sort = this.sort;
    // }
    this.isProgressCSV = true;
    
    this._reportS.resumeExportCsv(this._sessionS.getSession().gid, 'reviews', this.sort, this.dataPicker, this.reportName)
    .pipe(
      switchMap(result => this._afsStorage.ref(result['data']).getDownloadURL())
    )
    .subscribe(
      filename => {
        this._insightS.openDownloadInNewTab(filename);
        this.isProgressCSV = false;
        this._snack.openSuccess("The file exported successfully")
    },
    err => {
      this.isProgressCSV = false;
      console.error(err);
      this._snack.openError("The file export failed. Please try again later.")
    });
    
  }

  public reportListAccordionChange(): void {
    this.isReportListAccordionOpen = !this.isReportListAccordionOpen
  }

  public async exportCSVReviewLinks(event): Promise<void> {
    event.stopPropagation();
    this.isDowloadReviewLinks = true;
    this._reviewService.reportReviewList(this.locations, this.dataPicker, 1, this.locations.length, this.sort).take(1).subscribe(
      data => {
        this.exportToCsv(data.items)
      },
      err => {
        this.isDowloadReviewLinks = false;
        console.error(err);
        this._snack.openError("The file export failed. Please try again later.", 2000)
      }
    )
  }

  public async exportToCsv(locations: IReviewList[]): Promise<void> {
    try {
      const dataToExport = await this.getExportData(locations);
      const result = await this._reviewService
        .exportCsvData(`${this.reportName} review links.csv`, dataToExport)
        .toPromise();
      const fileName = await this._afsStorage
        .ref(result['message']["srcFilename"])
        .getDownloadURL()
        .toPromise();

      window.open(`${fileName}`, "_blank");
      this._snack.openSuccess("The file exported successfully", 2000);
      this.isDowloadReviewLinks = false;
    } catch (e) {
      console.error(e);
      this._snack.openError("Error exporting the file, try again", 4000);
      this.isDowloadReviewLinks = false;
    }
  }

  public async getExportData(locations: IReviewList[]): Promise<{ [ key: string ]: string }[]> {
    const urlPromises = locations.map(location =>
      this._googleService.dynamicUrl(location?.placeId).toPromise()
    );

    const urls = await Promise.all(urlPromises);

    
    return locations.map((location, i) => {
      const address = this._locationS.formatAddress(location?.address);
      const serviceAreaPlaces = this._locationS.formatServiceArea(location?.serviceArea);
      
      return {
        'Location name': location.name,
        'Address/City/State/Zip/Country/Service Area': (address !== '--' && !!address) ? address : serviceAreaPlaces ? serviceAreaPlaces : '--',
        'Review link': urls[i].shortLink
      };
    });
  }
}
