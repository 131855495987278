
/* TODO: Unused, remove
export class FilterReviews {
  stars: number[];
  responded: boolean;
  keyword: string;
  page: number;
  size: number;
}
*/

export const CHECKBOX_DATA: FilterCheckbox[] = [
  {id: 5, value: 5, isChecked: false},
  {id: 4, value: 4, isChecked: false},
  {id: 3, value: 3, isChecked: false},
  {id: 2, value: 2, isChecked: false},
  {id: 1, value: 1, isChecked: false},

];


export interface FilterCheckbox {
  id: number;
  value: number;
  isChecked: boolean;
}


// TODO: Unused, remove
/*
export interface RatingDistribution {
  ONE: { count: number, percent: number },
  TWO: { count: number, percent: number },
  THREE: { count: number, percent: number },
  FOUR: { count: number, percent: number },
  FIVE: { count: number, percent: number }
};
*/

export const SIZE_OPTIONS = [10, 25, 50, 100];

// TODO: Unused, remove
// export const INIT_PAGINATION = {page_size: 10, page: 1, items: [], is_first: true, is_last: false, length: 0};
