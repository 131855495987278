import { __assign, __awaiter, __extends, __generator } from "tslib";
// dep
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
// app
import { InsightsService } from '../../services/insights.service';
import { ChartService } from '../../services/chart.service';
import { DatesService } from '../../services/dates.service';
import { ReportService } from '../../services/report.service';
import { SnackbarService } from '../../services/snackbar.service';
import { EventMessageService } from "../../services/event-message.service";
import { ModalService } from "../../services/modal.service";
import { SubscriptionService } from "../../services/subscription.service";
import { LocationService } from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";
import { CHART_BLUE_COLOR, CHART_CORAL_COLOR } from '../../constants/chart-color';
import { EventNames } from '../../constants/event-names';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var DashboardInsightsComponent = /** @class */ (function (_super) {
    __extends(DashboardInsightsComponent, _super);
    function DashboardInsightsComponent(insightS, route, chartS, dateS, _sessionS, reportS, snackS, em, modalService, subscriptionService, locationService, loadingService) {
        var _this = _super.call(this) || this;
        _this.insightS = insightS;
        _this.route = route;
        _this.chartS = chartS;
        _this.dateS = dateS;
        _this._sessionS = _sessionS;
        _this.reportS = reportS;
        _this.snackS = snackS;
        _this.em = em;
        _this.modalService = modalService;
        _this.subscriptionService = subscriptionService;
        _this.locationService = locationService;
        _this.loadingService = loadingService;
        _this.shared = false;
        _this.lockDates = false;
        _this.changeDataPicker = new EventEmitter();
        _this.stats = [];
        _this.isProgress = true;
        _this.multiChartDataSet = [];
        _this.typeReport = null;
        _this.colorsViewChart = [
            CHART_BLUE_COLOR,
            CHART_CORAL_COLOR,
        ];
        _this.first = true;
        _this.isProgressCSV = false;
        _this.viewModeOptions = [];
        _this.viewModeChecked = 'legacy';
        _this.accountId = _this.route.parent.snapshot.params.accountId;
        _this.locationId = _this.route.parent.snapshot.params.locationId;
        _this.locations = [{ accountId: _this.accountId, locationId: _this.locationId }];
        _this.localRoute = route;
        return _this;
    }
    DashboardInsightsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.reportDataPicker) {
            this.typeReport = 'rollup';
            this.isReport = true;
            // FIXME: This was done this way but must be locations[0].locationId? Probably a bug
            // @ts-ignore
            this.locationId = this.reportDataPicker.locations;
            this.reportDataPicker.aggregation = this.reportS.correctDateAggregate(this.reportDataPicker);
            this.dataPicker = this.reportDataPicker;
            this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
            this.locations = this.dataPicker.locations;
            return;
        }
        else {
            this.typeReport = 'rollup';
            this.selectDate = {
                start: moment('12-31-2022', 'MM-DD-YYYY').subtract(3, 'month'),
                end: moment('12-31-2022', 'MM-DD-YYYY')
            };
        }
        this._subscribeSafe(this.em.getEmitter(), function (res) {
            if (res.name !== EventNames.EVENT_DATEPICKER_SUBSCRIPTION)
                return;
            var group = _this._sessionS.getSession().group;
            var dateDismiss = group.dismissModalDatePicker || new Date();
            if (new Date() >= dateDismiss) {
                _this.modalService.openModal(FeaturedDatePickerComponent, {
                    accountId: _this.accountId,
                    locationId: _this.locationId
                }, { onClose: function (res) {
                        if (!res)
                            return;
                        console.log('DashboardInsightsComponent upgrade suscription');
                        _this.subscriptionService.flowChangeLocationsPlan([{ locationId: _this._locationUpgraded.locationId,
                                accountId: _this._locationUpgraded.accountId }]);
                    } });
            }
        });
        // TODO: Here locationId is always a single element array? Note `${[1]}` == "1"
        this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId).then(function (loc) { return _this._locationUpgraded = loc; });
    };
    DashboardInsightsComponent.prototype.getInsightData = function (dataPicker, refresh) {
        if (refresh === void 0) { refresh = false; }
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var res, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.resetData();
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.insightS.getInsights(this.locations, dataPicker)];
                    case 2:
                        res = _d.sent();
                        this.popularStats = res.popularTime;
                        this.isProgress = false;
                        if (((_b = (_a = res) === null || _a === void 0 ? void 0 : _a.labels) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                            this.labels = res.labels;
                            this.stats = this._orderingStats(res.stats);
                            this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
                        }
                        else {
                            // this.isNoData = true;
                        }
                        this.loadingService.reportAdvanced(1, 'Insights');
                        if (refresh)
                            this.snackS.openSuccess('Finished refreshing!');
                        return [3 /*break*/, 4];
                    case 3:
                        _c = _d.sent();
                        // this.isNoData = true;
                        this.isProgress = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardInsightsComponent.prototype.exportCSV = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.isProgressCSV = true;
                        return [4 /*yield*/, this.reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.isProgressCSV = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardInsightsComponent.prototype.handleExport = function ($event) {
        if ($event === 'csv') {
            this.exportCSV();
        }
    };
    DashboardInsightsComponent.prototype.handleDataPicker = function ($event) {
        // Init get data
        if (this.isReport && this.first) {
            this.first = false;
            this.getInsightData(this.dataPicker);
            return;
        }
        else if (!this.dataPicker) {
            this.dataPicker = __assign({}, $event);
            this.getInsightData(this.dataPicker);
            return;
        }
        // when multiChart Change
        this.dataPicker.multiChart = $event.multiChart;
        this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
        // ranges or aggregation changes
        if (this.dataPicker.range !== $event.range || this.dataPicker.aggregation !== $event.aggregation) {
            if (this.dataPicker.aggregation === 'month') {
                var endMonth = this.dateS.stringToDate(this.dataPicker.range.end).getMonth();
                var startMonth = this.dateS.stringToDate(this.dataPicker.range.start).getMonth();
                if (startMonth === endMonth) {
                    this.dataPicker.aggregation = 'day';
                }
            }
            this.dataPicker = __assign({}, $event);
            this.getInsightData(this.dataPicker);
            // Calendar change event here
        }
        this.dataPicker.locations = this.locations;
        this.changeDataPicker.emit(this.dataPicker);
    };
    DashboardInsightsComponent.prototype.handleMultiChart = function (dataPicker) {
        return this.chartS.multiCharset(this.stats, dataPicker);
    };
    DashboardInsightsComponent.prototype.resetData = function () {
        this.labels = [];
        this.stats = [];
        this.isProgress = true;
    };
    DashboardInsightsComponent.prototype.clearChecked = function (event) {
        this.multiChartDataSet = [];
        this.checks = event;
    };
    DashboardInsightsComponent.prototype.statusChecks = function (event) {
        this.checks = event;
    };
    DashboardInsightsComponent.prototype.handleRefresh = function () {
        this.getInsightData(this.dataPicker, true);
    };
    DashboardInsightsComponent.prototype._orderingStats = function (stats) {
        var orderedData = [];
        var order = [
            "Actions on your Google Business Profile",
            "Search impressions",
            "total views on search",
            "Google Posts Activity",
            "Photo views",
            "Photo quantity"
        ];
        order.forEach(function (el) {
            var item = stats.find(function (s) { var _a; return ((_a = s) === null || _a === void 0 ? void 0 : _a.title) == el; });
            if (item) {
                orderedData.push(item);
            }
        });
        return orderedData;
    };
    DashboardInsightsComponent.prototype.hasNoDate = function () {
        var _a, _b;
        var date = (_b = (_a = this._locationUpgraded) === null || _a === void 0 ? void 0 : _a.createdAt) === null || _b === void 0 ? void 0 : _b.$date;
        var createdData = date ? moment(date) : null;
        var hasMoreThan24hs = !!(createdData && this.insightS.hasMoreThan24hs(createdData));
        return hasMoreThan24hs ? 'No insights data' : ('The performance insights for this business profile are still being imported, ' +
            'please refresh and check again in 10 minutes');
    };
    return DashboardInsightsComponent;
}(BaseComponent));
export { DashboardInsightsComponent };
