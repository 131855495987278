import { __assign, __awaiter, __generator } from "tslib";
// dep
import { EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
// app
import { ProtocolService } from '../../services/protocol.service';
import { SAVE_COMPLETE_EDIT_PROTOCOL, SAVE_COMPLETE_EDIT_SCAN, SAVE_COMPLETE_PROTOCOL, SAVE_COMPLETE_SCAN } from '../../constants/firestore/protocol';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PROTOCOL_SCAN_ACTION } from '../modal-stepper/modal-stepper.component';
import { ResumeCreateProtocolComponent } from '../resume-create-protocol/resume-create-protocol.component';
import { ModalService } from '../../services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { SessionService } from 'src/app/services/session.service';
var ModalCreateProtocolComponent = /** @class */ (function () {
    function ModalCreateProtocolComponent(_protocolService, _dialogRef, _sessionS, _changeDetector, _snackS, _modalService, data) {
        this._protocolService = _protocolService;
        this._dialogRef = _dialogRef;
        this._sessionS = _sessionS;
        this._changeDetector = _changeDetector;
        this._snackS = _snackS;
        this._modalService = _modalService;
        this.data = data;
        this.completeAll = new EventEmitter();
        this.titleModal = 'New Review Response Protocol';
        this.step = 0;
        this.complete1 = false;
        this.step_complete2 = false;
        this.step_complete4 = false;
        this.responsesAdditional = new FormArray([]);
        this.locationsSelected = [];
        this.isEdit = false;
        this.isScan = false;
        this.protocolEdit = null;
        this.progress = new BehaviorSubject(false);
        this.scan_action = true;
        this.index_focus_out = 0;
        this.isLoadingStep3 = false;
        this.defaultResponse = new FormControl('', [Validators.required, Validators.minLength(10)]);
        if (this.data) {
            if ('data' in this.data)
                this.data = this.data.data;
            this.isEdit = this.data.edit ? this.data.edit : false;
            this.isScan = this.data.hasOwnProperty('scan') ? this.data.scan : true;
            if (this.isEdit) {
                this.titleModal = 'Edit Review Response Protocol';
                this.protocolEdit = this.data.protocol;
                this.defaultResponse.setValue(this.protocolEdit.response[0]);
                this.scan_action = false;
                if (this.isScan) {
                    this.scan_action = true;
                    this.titleModal = 'Edit Scan Review Response Protocol';
                    this.parcial_protocol = this.protocolEdit;
                }
                for (var i = 1; i < this.protocolEdit.response.length; i++) {
                    this.responsesAdditional.push(new FormControl(this.protocolEdit.response[i]));
                }
                if (this.protocolEdit.accounts) {
                    this.locationsSelected = this.protocolEdit.accounts;
                }
            }
        }
    }
    ModalCreateProtocolComponent.prototype.createIsValid = function ($event) {
        if (!$event) {
            this.complete1 = $event;
            return $event;
        }
        this.formCreate = $event;
        this.complete1 = true;
        this.parcial_protocol = __assign(__assign({}, this.formCreate), { status: { replied: 0, total: 0 }, enabled: false, retroactive: false });
        var _a = this._sessionS.getSession(), gid = _a.gid, uid = _a.uid;
        this.parcial_protocol.gid = gid;
        this.parcial_protocol.uid = uid;
        this.placeholders = '%username% %city% %business_name%';
        this.placeholders_array = ['%username%', '%city%', '%business_name%'];
        this.keywords = this.formCreate.triggers.keywords;
        for (var i = 0; i < this.keywords.length; i++) {
            this.placeholders = this.placeholders.concat(" %keyword%");
            this.placeholders_array.push(" %keyword%");
        }
    };
    ModalCreateProtocolComponent.prototype.addResponse = function () {
        this.responsesAdditional.push(new FormControl('', [Validators.required, Validators.minLength(10)]));
    };
    ModalCreateProtocolComponent.prototype.AddPlaceHolder = function (placeholder) {
        var textAreas = document.getElementsByTagName('textarea');
        var text = textAreas[this.index_focus_out].value;
        if (text !== '')
            textAreas[this.index_focus_out].value = text + " " + placeholder;
        else
            textAreas[this.index_focus_out].value = "" + placeholder;
    };
    ModalCreateProtocolComponent.prototype.removeResponse = function (i) {
        this.responsesAdditional.removeAt(i);
    };
    ModalCreateProtocolComponent.prototype.handleSelect = function ($event) {
        this.locationsSelected = $event;
    };
    ModalCreateProtocolComponent.prototype.current_step = function (e) {
        if (e === 2) {
            this.resumeComponent.GetCounters();
        }
    };
    Object.defineProperty(ModalCreateProtocolComponent.prototype, "complete2", {
        get: function () {
            var _a;
            if (((_a = this.locationsSelected) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return false;
            }
            this.parcial_protocol.accounts = this.locationsSelected;
            this.parcial_protocol.triggers = this.formCreate.triggers;
            this.step_complete2 = true;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateProtocolComponent.prototype, "complete3", {
        get: function () {
            return !this.isLoadingStep3;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalCreateProtocolComponent.prototype, "complete4", {
        get: function () {
            if (!this.defaultResponse.valid) {
                this.defaultResponse.touched = true;
            }
            return this.responsesAdditional.valid && this.defaultResponse.valid;
        },
        enumerable: true,
        configurable: true
    });
    ModalCreateProtocolComponent.prototype.edit_protocol = function (protocol) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var exists_protocol, update;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._protocolService.verifyProtocol(protocol, this.protocolEdit.protocolId)];
                    case 1:
                        exists_protocol = _c.sent();
                        if (!((_a = exists_protocol) === null || _a === void 0 ? void 0 : _a.result)) return [3 /*break*/, 3];
                        this.progress.next(true);
                        protocol.scan = false;
                        return [4 /*yield*/, this._protocolService.update(protocol, this.protocolEdit.protocolId)];
                    case 2:
                        update = _c.sent();
                        this._protocolService.check_protocol(this.protocolEdit.gid, this.protocolEdit.protocolId).then(function (check_protocols) {
                            _this.completeAll.emit(SAVE_COMPLETE_EDIT_PROTOCOL);
                            if (!check_protocols) {
                                _this._snackS.openError('Failed update protocol', 4000);
                            }
                        });
                        this.completeAll.emit(SAVE_COMPLETE_EDIT_PROTOCOL);
                        this._dialogRef.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        this.duplicatedProtocolError((_b = exists_protocol) === null || _b === void 0 ? void 0 : _b.duplicatedProtocol);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ModalCreateProtocolComponent.prototype.Edit_scan_protocol = function (protocol) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var exists_protocol;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._protocolService.verifyProtocol(protocol, this.protocolEdit.protocolId)];
                    case 1:
                        exists_protocol = _c.sent();
                        if (!((_a = exists_protocol) === null || _a === void 0 ? void 0 : _a.result)) return [3 /*break*/, 3];
                        this.progress.next(true);
                        protocol.scan = true;
                        return [4 /*yield*/, this._protocolService.update(protocol, this.protocolEdit.protocolId)];
                    case 2:
                        _c.sent();
                        this.completeAll.emit(SAVE_COMPLETE_EDIT_SCAN);
                        this._dialogRef.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        this.duplicatedProtocolError((_b = exists_protocol) === null || _b === void 0 ? void 0 : _b.duplicatedProtocol);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ModalCreateProtocolComponent.prototype.Save_new_protocol = function (protocol) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var no_exists_protocol, saved;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._protocolService.verifyProtocol(protocol)];
                    case 1:
                        no_exists_protocol = _c.sent();
                        if (!((_a = no_exists_protocol) === null || _a === void 0 ? void 0 : _a.result)) return [3 /*break*/, 3];
                        this.progress.next(true);
                        return [4 /*yield*/, this._protocolService.save(protocol, false)];
                    case 2:
                        saved = _c.sent();
                        if (!saved) {
                            this._snackS.openError('Failed save protocol', 2500);
                        }
                        this.completeAll.emit(SAVE_COMPLETE_PROTOCOL);
                        this._dialogRef.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        this.duplicatedProtocolError((_b = no_exists_protocol) === null || _b === void 0 ? void 0 : _b.duplicatedProtocol);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ModalCreateProtocolComponent.prototype.Save_scan_protocol = function (protocol) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var no_exists_protocol, saved;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._protocolService.verifyProtocol(protocol)];
                    case 1:
                        no_exists_protocol = _c.sent();
                        if (!((_a = no_exists_protocol) === null || _a === void 0 ? void 0 : _a.result)) return [3 /*break*/, 3];
                        this.progress.next(true);
                        return [4 /*yield*/, this._protocolService.save(protocol, true)];
                    case 2:
                        saved = _c.sent();
                        if (!saved) {
                            this._snackS.openError('Failed save scan protocol', 2500);
                        }
                        this.completeAll.emit(SAVE_COMPLETE_SCAN);
                        this._dialogRef.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        this.duplicatedProtocolError((_b = no_exists_protocol) === null || _b === void 0 ? void 0 : _b.duplicatedProtocol);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ModalCreateProtocolComponent.prototype.duplicatedProtocolError = function (protocol) {
        var _a, _b;
        this._snackS.openError("Looks like one or more locations already exist in another Protocol with the same Triggers."
            + (" Please check " + (((_a = protocol) === null || _a === void 0 ? void 0 : _a.name) !== undefined ? '"' + ((_b = protocol) === null || _b === void 0 ? void 0 : _b.name) + '"' : 'the existing Protocols') + "."), 4000);
    };
    ModalCreateProtocolComponent.prototype.completed = function (typeAction) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var responses, fusion, protocol, _c, gid, uid;
            var _this = this;
            return __generator(this, function (_d) {
                if (((_a = this.locationsSelected) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                    return [2 /*return*/];
                }
                if (!this.responsesAdditional.valid || (!this.defaultResponse.valid && typeAction !== PROTOCOL_SCAN_ACTION))
                    return [2 /*return*/];
                responses = [this.defaultResponse.value];
                fusion = responses.concat(this.responsesAdditional.value);
                protocol = __assign(__assign({}, this.formCreate), { response: fusion, status: { replied: this.resumeComponent.Counters.Replied, total: this.resumeComponent.Counters.Total }, enabled: false, retroactive: false });
                _c = this._sessionS.getSession(), gid = _c.gid, uid = _c.uid;
                protocol.gid = gid;
                protocol.uid = uid;
                protocol.accounts = this.locationsSelected;
                if ((_b = protocol) === null || _b === void 0 ? void 0 : _b.withOutComment) {
                    protocol.triggers.keywords = [];
                }
                if (this.isEdit) {
                    this._modalService.openConfirmModal('Notice', 'Editing an autoresponder will disable it as well as the retroactive. Make sure to re-enable it afterwards.', function (response) {
                        if (!response)
                            return;
                        if (_this.protocolEdit) {
                            if (typeAction === PROTOCOL_SCAN_ACTION) {
                                _this.Edit_scan_protocol(protocol);
                            }
                            else {
                                _this.edit_protocol(protocol);
                            }
                        }
                        else {
                            if (typeAction === PROTOCOL_SCAN_ACTION) {
                                _this.Save_scan_protocol(protocol);
                            }
                            else {
                                _this.Save_new_protocol(protocol);
                            }
                        }
                    }, AlertType.INFO, 'Ok');
                }
                else {
                    if (this.protocolEdit) {
                        if (typeAction === PROTOCOL_SCAN_ACTION) {
                            this.Edit_scan_protocol(protocol);
                        }
                        else {
                            this.edit_protocol(protocol);
                        }
                    }
                    else {
                        if (typeAction === PROTOCOL_SCAN_ACTION) {
                            this.Save_scan_protocol(protocol);
                        }
                        else {
                            this.Save_new_protocol(protocol);
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ModalCreateProtocolComponent.prototype.ngAfterViewChecked = function () {
        this._changeDetector.detectChanges();
    };
    ModalCreateProtocolComponent.prototype.onStepChange = function (event) {
        this.step = event;
        if (this.step === 2) {
            this.isLoadingStep3 = true;
        }
    };
    ModalCreateProtocolComponent.prototype.loadingStep3 = function (event) {
        this.isLoadingStep3 = false;
    };
    Object.defineProperty(ModalCreateProtocolComponent.prototype, "disabledNextStep", {
        get: function () {
            return !(this.step === 0 ? this.complete1 && !this.progress.value :
                this.step === 1 ? this.complete2 && !this.progress.value :
                    this.step === 2 ? this.complete3 && !this.progress.value :
                        this.step === 3 ? this.complete4 && !this.progress.value : true);
        },
        enumerable: true,
        configurable: true
    });
    return ModalCreateProtocolComponent;
}());
export { ModalCreateProtocolComponent };
