import { __awaiter, __generator } from "tslib";
import { MatDialogRef } from '@angular/material';
import { NavigationService } from 'src/app/services/navigation.service';
// app
import { SessionService } from 'src/app/services/session.service';
var ModalTrialEnded = /** @class */ (function () {
    // private _data
    function ModalTrialEnded(dialogRef, data, _sessionS, _navigationS) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._sessionS = _sessionS;
        this._navigationS = _navigationS;
        // this._data = data
        this.getPrice();
    }
    ModalTrialEnded.prototype.getPrice = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var s, group, sub, tiers, i, t;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        s = _f.sent();
                        group = s.group;
                        sub = s.subscription;
                        this.locations_qty = group.totalLocationsCount;
                        tiers = (_b = (_a = sub.pricing) === null || _a === void 0 ? void 0 : _a.pkg_bulk_actions) === null || _b === void 0 ? void 0 : _b.tiers;
                        for (i = 0; i < tiers.length; i++) {
                            t = tiers[i];
                            if (this.locations_qty >= ((_c = t) === null || _c === void 0 ? void 0 : _c.locationsQtyMin) &&
                                this.locations_qty <= ((_d = t) === null || _d === void 0 ? void 0 : _d.locationsQtyMax)) {
                                this.price = (_e = t) === null || _e === void 0 ? void 0 : _e.price;
                                return [2 /*return*/];
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalTrialEnded.prototype.accept_cta = function () {
        window.open(this.data.buttons['accept_cta'], "_blank");
    };
    ModalTrialEnded.prototype.close = function () {
        this.dialogRef.close();
        this._navigationS.signOut();
    };
    return ModalTrialEnded;
}());
export { ModalTrialEnded };
