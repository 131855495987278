<div class="reports-filter-wrapper filter-dashboard" [class.is-impersonating]="(session$ | async).isImpersonating || (session$ | async).isTrial">
    <div class="d-flex justify-content-start ml--15 ml--290" (click)="navigateBack()" data-html2canvas-ignore>
        <button mat-flat-button [style.color]="'#8c91a2'"
          class="btn btn--default btn--icon btn--md mr--5 cursor--pointer">
          <i class="fas fa-chevron-left mr--5"></i>
          Back To Locations
        </button>
      </div>
      <div class="w--100 d-flex justify-content-end" *ngIf="dateReady">
        <!-- Monthly Datepicker -->

        <div>
            <ejs-daterangepicker
                id="date-range-picker"
                #dateRangePickerMonthly 
                (change)="dateChanged($event)"
                [start]="dateRangeObject?.granularity?.start"
                [startDate]="dateRangeObject?.dateRangeLimits?.minDate"
                [endDate]="dateRangeObject?.dateRangeLimits?.maxDate"
                [format]="'MMM yyyy'"
                [depth]="dateRangeObject?.granularity?.depth" 
                [min]="dateRangeObject?.dateRangeLimits?.minDate"
                [max]="dateRangeObject?.dateRangeLimits?.maxDate"
                [allowEdit]="false" 
                [showClearButton]="false">
            </ejs-daterangepicker>
        </div>
        <!-- Presets -->
        <e-presets>
            <!-- Check possible ngIf cases for each preset (needs rework after IPreset rework) -->
            <!-- <e-preset 
                label="Last Month" 
                [start]="dateRangeObject?.presetDateRanges?.lastMonthStart" 
                [end]="dateRangeObject?.presetDateRanges?.lastMonthEnd">              
            </e-preset>
            <e-preset 
                label="YTD" 
                [start]="dateRangeObject?.presetDateRanges?.yearStart" 
                [end]="dateRangeObject?.presetDateRanges?.lastMonthEnd">
            </e-preset> -->
        </e-presets>
    </div>
</div>
