// dep
import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, ViewRef, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import moment from 'moment';
const FlexSearch = require('flexsearch');

// app
import { ReportService } from '../../services/report.service';
import { ModalCreateReportComponent } from '../modal-create-report/modal-create-report.component';
import { Pagination } from '../../constants/api-response';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { Pageable } from '../../constants/pageable';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SearchIndexElastic } from '../../constants/firestore/group';
import { Sort } from '../../constants/sort';
import { DatesService } from '../../services/dates.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import Report from 'src/app/constants/firestore/report';
import { FeaturedReportComponent } from 'src/app/components/featured-report.component';
import AccountReport from 'src/app/services/account-report';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';


@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() hasToggleMode = false;
  @Input() reportType;
  @ViewChild(MatPaginator, { static: true })

  public displayedColumns: string[];
  public dataSource: any;
  public checked = false;
  // custom pagination elements
  public manualPage: number;
  public errorMessage: boolean;
  public flexSearch = new FlexSearch({
    encode: 'advanced',
    tokenize: 'reverse',
    suggest: true,
    cache: true,
    doc: {
      id: 'id',
      field: [
        'accountName',
        'locName',
        'address',
        'reportName',
        'labels',
      ]
    }
  });
  public results: SearchIndexElastic[];
  public sort : Sort = {
    sortBy: 'createdAt',
    direction: 'desc'
  }

  public paginator: MatPaginator;
  public reports: Pagination;
  public progress = true;
  public reportsExists = false;
  public pagination: any;
  public selectedSearch = new FormControl();
  public filtered = false;
  public searchText: string;
  public reportData: any = {};
  public isSliderOpened = false;
  public viewModeChecked = 'new';
  public hasLegacy = false
  public nameReportType;
  public loadingLegacyFooter = false;
  public timeout: any = null;

  private _backupData = [];
  private _previousPageable: { size: any; page: any };
  private _paginate: Pageable = { page: 1, size: 10 };

  session$ = this._sessionS.session$

  constructor(
    public router: Router,
    private _sessionS : SessionService,
    private _dialog: MatDialog,
    private _reportService: ReportService,
    private _modalService: ModalService,
    private _snackS: SnackbarService,
    private _locationService: LocationService,
    private _groupService: GroupService,
    private _apiSubscription: SubscriptionService,
    private _dateService: DatesService,
    private _spinnerService: SpinnerService,
    private _cdRef: ChangeDetectorRef,
    private _renderer2: Renderer2,
  ) {
    super();
  }

  selected() : void {
    const value = this.selectedSearch.value;
    this.router.navigateByUrl(`/report/${value.source}`)
  }

  async ngOnInit() {
    if (this.reportType?.includes('-report')) {
      this.reportType = this.reportType.replace('-report', '');
    }
    switch (this.reportType) {
      case 'qanda':
        this.nameReportType = 'Questions And Answers';
        break;
      case 'performance-rollup':
        this.nameReportType = 'Performance';
        break;
      default:
        this.nameReportType = this.reportType?.replace('-', ' ');
        break;
    }
    this.viewModeChecked = this.router.url.includes('performance') || this.router.url.includes('keywords') || this.router.url.includes('qanda') || this.router.url.includes('review') ? 'new' : 'legacy';

    this._subscribeSafe(this._groupService.getReportIndexes(this._sessionS.getSession().gid, this.reportType),
     (result : any[]) => {
      if(!result?.length)
        return;

      this.results = result;
      for (const index of result) {
        index.id = JSON.stringify(index);
        index.address = this._locationService.formatAddress(index.address);
        this._addDoc(index);
      }
      if (this.filtered) 
        this.applyFilter(this.searchText);
    });

    if (!this.filtered) 
      this._getData();
  }

  closeSliderReport(): void {
    this.isSliderOpened = false;
    this._getData();
    const body = document.getElementsByTagName('body')?.[0];
    this._renderer2.removeClass(body, 'overflow--hidden');
  }

  ngOnDestroy(): void {
    const content = document.getElementsByClassName('content')
    if (content.length > 0) {
      this._renderer2.removeClass(content[0], 'pr--0')
    }
    super.ngOnDestroy();
  }

  private _addDoc(doc): void {
    this.flexSearch.add(doc);
  }

  private _getData($event?): void {
    let searchKeywords = null;
    let last = null;
    let next = null;

    if (this._previousPageable) {
      if (this._previousPageable.page < this.pagination.page) {
        if (this.dataSource.data) {
          last = this.dataSource.data[this.pagination.per_page - 1];
        }
      } else if (this._previousPageable.page > this.pagination.page) {
        if (this.dataSource.data) {
          next = this.dataSource.data[0];
        }
      }
    }

    if ($event) {
      searchKeywords = $event;
    }

    if (this._sessionS.hasSession()) {
      this.progress = true;
      if (this.reportType == 'keyword' || this.viewModeChecked == 'new') {
        this._getLegacy();
        this._getDataFromMongo();
      } else {
        this._getDataFromFire(last, next, searchKeywords)
      }
    }
  }

  private async _getDataFromMongo(reportName = null) {
    try {
      this.progress = true;
      const sort = this.sort.direction == 'asc' ? 1 : -1;

      const res = await this._reportService.getReportsFromMongo(this._sessionS.getSession().gid, this.reportType, this._paginate, this.sort.sortBy, sort, reportName)
      if (res && res['items']) {
        res['items'].forEach(el => {
          el.fromDate = this.builDate(el.startDatetime),
            el.toDate = this.builDate(el.endDatetime)
        })
        this.dataSource = new MatTableDataSource<Report>(res['items']);
        this._previousPageable = { size: res['per_page'], page: res['page'] };
        this.pagination = res;
        this.errorMessage = false;
        this.formatBasiclocations(res['items']);
      } else {
        this.dataSource = new MatTableDataSource<Report>([]);
      }
   } finally {      
      this.progress = false;
   }
  }

  private _getDataFromFire(last, next, searchKeywords) {
    this._subscribeSafe(
     this._reportService.getByGIdAndType(this._sessionS.getSession().gid, this.reportType, this._paginate, last, next, searchKeywords, this.sort.sortBy, this.sort.direction),
     result => {
      result.items.forEach(el => {
        el.fromDate = this.builDate(el.startDatetime),
          el.toDate = this.builDate(el.endDatetime)
      })
      this._previousPageable = { size: result.per_page, page: result.page };
      this.pagination = result;
      if (!this.filtered) {
        this.formatBasiclocations(result.items);
      }
      this.manualPage = 1;
      this.errorMessage = false;
    });
  }

  private _getLegacy() {
    this.loadingLegacyFooter = true;
    const reportTypeLegacy = this._getReportType();

    this._subscribeSafe(this._reportService.getByGIdAndTypeCount(this._sessionS.getSession().gid, reportTypeLegacy),
      res => { 
        this.hasLegacy = (res.size > 0) 
        this.loadingLegacyFooter = false;
      }
    )
  }

  builDate(date  : string) : string {
    if (date) {
      const s = date.split(date.includes('T') ? 'T' : ' ')[0].split('-')
      return `${s[1]}-${s[2]}-${s[0]}`
    }
    return '-'
  }


  handleReload($event: Pageable): void {
    this._paginate = $event;
    this._getData();
  }

  formatBasiclocations(items) : void {
    this._updateTable(items);
  }

  checkLocations(items): void {
    for (const item of items) {
      if (!_.isEmpty(item.accounts)) {
        for (const account of item.accounts) {

          const hasInvalidAccounts  = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
          const hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
          if (hasInvalidAccounts || hasInvalidLocations) {
            item.invalidLocations = true;
          }

          const hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
          if (hasDuplicated) {
            item.duplicatedLocations = true;
          }
        }
      } else {
        delete item.invalidLocations;
        delete item.duplicatedLocations;
      }
    }
  }

  filterByLocationMember(items): any[] {
    const {user} = this._sessionS.getSession();
    const memberLocations = [];

    user.accounts.forEach(account => {
      account.locations.forEach(location => {
        memberLocations.push(location.locationId);
      });
    });

    const validReports = [];

    for (const item of items) {
      const reportLocations = [];
      if (!_.isEmpty(item.accounts)) {
        for (const account of item.accounts) {
          account.locations.forEach(location => {
            reportLocations.push(location.locationId);
          });
        }

        if (reportLocations.every(locationId => memberLocations.includes(locationId))) {
          validReports.push(item);
        }
      }
    }

    return validReports;
  }

  sortData(sort): void {
    this.sort = {
      sortBy: sort.active,
      direction: sort.direction,
    };
    const data = this._backupData.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    if (this.filtered) {
      this.dataSource.data = _.orderBy(data, [sort.active], [sort.direction]);
    } else {
      return this._getData(null);
    }
  }

  _updateTable(items): void {
    if (!this._sessionS.getSession().isAdmin) {
      items = this.filterByLocationMember(items);
    }

    // data for table
    if (this.reportType === 'Review Assistant') {
      this.displayedColumns = ['name', 'created', 'modified', 'locations', 'actions'];
    } else {
      this.displayedColumns = ['name', 'created', 'modified', 'locations', 'date', 'actions'];
    }
    this.progress = false;

    this.dataSource = new MatTableDataSource<Report>(items);
    this._backupData = this.dataSource.data;
    this.dataSource.paginator = this.paginator;
    this.reportsExists = !!(this.dataSource.data.length);
    this.progress = false;
  }

  // apply filter from search  
  // Legacy reports do not support filtering due to the unmaintained Firestore index. 
  applyFilter(inputValue: string): void {
    clearTimeout(this.timeout);

    // Use a trimmed version to avoid trailing and leading spaces
    const trimmedValue = inputValue?.trim();

    this.timeout = setTimeout(() => {
      if (!trimmedValue) {
        this._getData();
        this.filtered = false;
      } else {
        this._getDataFromMongo(trimmedValue.toLowerCase());
      }
    }, 350);
  }

  openCreateReportDialog() : void {
    const dialogRef = this._dialog.open(ModalCreateReportComponent, {
      width: '680px',
      data: { reportType: this.reportType }
    });

    dialogRef.disableClose = true;
  }

  openSliderReport(drawer, element: Report, stepLocations: boolean): void {
    const body = document.getElementsByTagName('body')?.[0];
    this._renderer2.addClass(body, 'overflow--hidden');

    this.reportData = element ? { reportType: this.reportType, edit: element, stepLocations } : { reportType: this.reportType };
    this.isSliderOpened = true;
    drawer.toggle();
  }

  showDeleteDialog(element: Report) : void {
    const data = { name: element.reportName };
    const dialog = this._modalService.openGenericModal(ModalDeleteComponent, data, null, 680);

    const subscription = dialog.componentInstance.deleted.pipe(
      filter(r => r)
    ).subscribe(() => {
      if (this.reportType !== 'keyword' && this.viewModeChecked != 'new') {
        this.delete(element.gid, element.id).then(() => {
          dialog.componentInstance.complete();
          setTimeout(() => {
            this.ngOnInit();
            dialog.close();
            subscription.unsubscribe();
          }, 800);
        });
      } else {
        this._reportService.deleteFromMongo(element['_id']).subscribe(
          res => {
            dialog.componentInstance.complete();
            setTimeout(() => {
              this.ngOnInit();
              dialog.close();
              subscription.unsubscribe();
            }, 800);
          }
        );
      }
    });
  }

  delete(gid : string, reportId: string) : Promise<void>{
    return this._reportService.delete(gid, reportId);
  }


  countLocation(element): number {
    return this._reportService.countLocation(element);
  }

  handleShared(element): void {
    const id = element.id || element._id;
    const dataPicker = this._reportService.reportToDataPicker(element, id, false, this.viewModeChecked);

    this._modalService.openGenericModal(ModalShareComponent, {
      createNew: false,
      dataPicker,
      placeId: dataPicker.locations,
      reportType: this.reportType,
      reportName: element?.reportName,
      report: { ...element }
    })
  }


  copy(element: Report, type = null): void {
    let copyElement: Report;
    if (!element) {
      return;
    } else {
      copyElement = _.clone(element);
    }

    if (type) {
      copyElement.reportType = type;
    }

    copyElement.reportName += '(Copy)';
    copyElement.accounts = this._locationService.deleteServiceArea(copyElement.accounts);
    copyElement.accounts = this._locationService.deleteAddress(copyElement.accounts);
    delete copyElement['id'];
    delete copyElement['_id'];
    if (type && type != 'keyword' && (!type && this.viewModeChecked != 'new')) {
      this.prepareToSave(copyElement);
      this._reportService.save(this._sessionS.getSession().gid, copyElement).then(result => {
        this._snackS.openSuccess('The report was copied successfully');
        this.ngOnInit();
      });
    } else {
      if (this.reportType == 'performance-comparison') {
        copyElement['metrics'] = copyElement['metricsName']
        delete copyElement['metricsName'];
      } else {
        copyElement['metrics'] = [];
        copyElement['metricsName'] = [];
      }

      if (type == "performance-comparison") {
        copyElement.dynamicRange = null;
        if (!copyElement?.dynamic) {
          const data = this._reportService.periodChange('period', element?.startDatetime, element?.endDatetime);
          copyElement.period = 'period';
          copyElement.startDatetimeB = data?.startDateB;
          copyElement.endDatetimeB = data?.endDateB;
        }
      }

      if (type == 'keyword') {
        const startDatetime = moment(element.startDatetime).startOf('month');
        copyElement.startDatetime = `${startDatetime.format('YYYY-MM-DD')} 00:00:00`;
        const endDatetime = moment(element.endDatetime).endOf('month');
        copyElement.endDatetime = `${endDatetime.format('YYYY-MM-DD')} 00:00:00`;
      }
      this._reportService.saveReportInMongo(copyElement).subscribe(
        res => {
          if (res?.error) {
            this._snackS.openError('There was an error while trying to copy the report', 3000);
          } else {
            this._snackS.openSuccess('The report was copied successfully', 3000);
            this.ngOnInit();
          }
        }
      )
    }
  }

  async action(element, drawer): Promise<void> {
    const {isTrial, isAdmin} = this._sessionS.getSession();
      this._spinnerService.loading$.next(true);
      const location = this.formatLocationsPath(element.accounts);
      const isAllLocationsUltimate = await this._locationService.isAllLocationsUltimate(location);
      this.checkLocations([element]);
      this._spinnerService.loading$.next(false);

      if (!isTrial && !isAllLocationsUltimate) {
        if (!isAdmin) {
          await this._modalService.openInfoModal('Contact your administrator',
            'To access this report, you must upgrade all locations to the Ultimate plan or remove them from the report.'+
            'Your user doesn’t have permissions to do this.')
          return
        }
        const response = await this._modalService.openModal(FeaturedReportComponent, null)

        if (response === 'upgrade') {
          const locations = await this.getLocationsToUpgrade(element.accounts);
          const message = 'Are you sure you want to upgrade ' + (locations.length === 1 ? 
                                                                'this location?' : 
                                                                `these ${locations.length} locations?`);

          if(await this._modalService.openConfirmModal('Upgrade', message)) {
            delete element.needToUpgrade;
            // tslint:disable-next-line: no-shadowed-variable
            locations.forEach((location, i) => {
              this._apiSubscription.upgradeLocation(location.locationId, location.accountId, element.gid).then(() =>
                i === locations.length - 1 && this._snackS.openSuccess('Locations successfully upgraded'),
                err => {
                  this._snackS.openError('There was a problem upgrading locations');
                });
            });
          }

        } else if (response === 'edit') {
          this.openSliderReport(drawer, element, false)
        }
        return      

      } else {
        if (this.reportType !== 'Review Assistant') {
          if ((!element.startDatetime || !element.endDatetime) && this.reportType != 'qanda') {
            this._modalService.openWarningModal('Alert', 'Please edit this report and set a valid start and end date.');
          } else if (this.reportType == 'performance-comparison' && (!element.startDatetimeB || !element.endDatetimeB)) {
            this._modalService.openWarningModal('Alert', 'Please edit this report and set a valid start and end date.');
          } else if (this.reportType == 'performance-comparison' && !element.metricsName.length) {
            this._modalService.openWarningModal('Alert', 'Please edit this report and select the metrics you wish to compare.');
          } else if (this.countLocation(element) == 0) {
            this._modalService.openWarningModal('Heads up', 'This report has no locations selected. Please edit the report and choose at least 1 location before trying to access the report again.');
          } else if (_.has(element, 'invalidLocations')) {
            if(await this._modalService.openConfirmModal('Alert', 'This report contains locations that are no longer available. They will be removed from the report.'))
                this._validateLocations(element, 'invalid');

          } else if (_.has(element, 'duplicatedLocations')) {
            if(await this._modalService.openConfirmModal('Alert', 'This report contains duplicated locations. They will be unified.'))
              this._validateLocations(element, 'duplicated');
          } else {
            // FIXME: Both urls are the same (?)
            if(this.viewModeChecked == 'new') {
              await this.router.navigateByUrl(`/report/${element.gid}/${element.id || element._id}/${this.reportType}`)
            } else {
              await this.router.navigateByUrl(`/report/${element.gid}/${element.id || element._id}/${this.reportType}`)
            }
          }
        } else {
          if (_.has(element, 'invalidLocations')) {
            if(await this._modalService.openConfirmModal('Alert', 'This report contains locations that are no longer available. '+
                                                                 'They will be removed from the report.')) 
                this._validateLocations(element, 'invalid');

          } else if (_.has(element, 'duplicatedLocations')) {
            if(await this._modalService.openConfirmModal('Alert', 'This report contains duplicated locations. They will be unified.'))
              this._validateLocations(element, 'duplicated');

          } else {
            await this.router.navigate(['/report/' + element.gid + '/' + element.id]);
          }
        }
      }
  }

  formatLocationsPath(accounts : AccountReport[] ) : string[] {
    const formated = [];
    for (const account of accounts) {
      for (const location of account.locations) {
        formated.push(`${account.gid}/${account.accountId}/${location.locationId}`);
      }
    }
    return formated;
  }

  async getLocationsToUpgrade(accounts): Promise<any[]> {
    await this._locationService.basicLocations([{ accounts: accounts }])
    let locations = [];
    accounts.forEach(account => {
      const free = _.pull(account.locationsBasics.map(location => (
        location.subscriptionType ===LOCATION_SUBSCRIPTION_TYPE.FREE || 
        location.subscriptionType ===LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL ||
        location.subscriptionType ===LOCATION_SUBSCRIPTION_TYPE.BASIC
        ) && {
        locationId: location.locationId,
        accountId: account.accountId
      }), false);
      if (!_.isEmpty(free)) {
        locations = Object.assign(locations, free);
      }
    });

    return locations;
  }
  
  private _validateLocations(element, action) : void {
    if (action === 'invalid') {
      for (const account of element.accounts) {
        if (_.isEqual(_.get(account, 'accountId'), 'accounts')) {
          element.accounts = _.pull(element.accounts, account);
        } else {
          const validLocations = _.filter(account.locations, (o) => {
            return o.accountId !== 'accounts' && o.locationId !== 'locations';
          });
          if (_.isEmpty(validLocations)) {
            element.accounts = _.pull(element.accounts, account);
          } else {
            account.locations = validLocations;
          }
        }

      }
      delete element.invalidLocations;
    } else {
      for (const account of element.accounts) {
        account.locations       = _.uniqBy(account.locations, 'locationId');
        account.locationsBasics = _.uniqBy(account.locationsBasics, 'locationId');
      }
      delete element.duplicatedLocations;
    }

    element.startDatetime && (element.startDatetime = moment(element.startDatetime).utc().toISOString());
    element.endDatetime && (element.endDatetime = moment(element.endDatetime).utc().toISOString());
    
    if (element?.reportType === 'comparison' && element.startDatetimeB && element.endDatetimeB) {
      element.startDatetimeB = moment(element.startDatetimeB).utc().toISOString();
      element.endDatetimeB   = moment(element.endDatetimeB).utc().toISOString();
    }
    this._reportService.update(false, this._sessionS.getSession().gid, element.id, element);

  }

  display(value): string | null {
    if (!value) {
      return null;
    }

    return `${value.type}: ${value.reportName}`;
  }

  getSearchReports(reports : any[]) : any[] {
    let result = [];
    reports.forEach(report => {
      const source = _.split(report.source, '/');
      const value = { ...report, id: source[1] };
      result = result.concat(value);
    });

    return result;
  }

  prepareToSave(report : Report) : void {
    const accounts = report.accounts;
    if (accounts && !_.isEmpty(accounts)) {
      for (const account of accounts) {
        delete account.locationsBasics;
      }
    }
    delete report.updateAt;
    report.createdAt = this._dateService.nowTimestamp;
  }

  // toggleModePerformaceLegacy(): void {
  //   this.reportType = this._getReportType();
  //   this.router.navigateByUrl(`/${this.reportType}-report`).then();
  //   this.detectChanges();
  //   //this.ngOnInit();
  // }

  detectChanges(): void {
    if (this._cdRef !== null &&
      this._cdRef !== undefined &&
      !(this._cdRef as ViewRef).destroyed
    ) {
      this._cdRef.detectChanges();
    }
  }

  private _getReportType(): string {
    return this.viewModeChecked == 'new' ? this.reportType?.slice("performance-".length) : `performance-${this.reportType}`;
  }


  // TODO: Getter used on angular templates, slow.
  hasDateInFire() : boolean {
    return (this.viewModeChecked === 'legacy')
  }
}
