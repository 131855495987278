// dep
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

// app
import { GoogleAccountService } from '../services/google-account.service';
import { GoogleService } from '../services/google.service';
import { SnackbarService } from '../services/snackbar.service';
import { PostService } from '../services/post.service';
import { ModalConfirmData } from '../classes/modal-confirm-data';
import { SessionService } from '../services/session.service';
import { BaseComponent } from './base.component';


@Component({
  selector: 'app-posts-errors',
  template: `
    <div class="dialog__header txt--center dialog__header--info">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>Posting Error Details</strong></h2>
    </div>

    <div class="dialog__content" mat-dialog-content>
      <mat-progress-bar mode="indeterminate" *ngIf="loadingPostErrors"></mat-progress-bar>
      <ng-container *ngIf="!loadingPostErrors">
      <mat-card *ngFor="let post of locationsPost;let i = index"  class="mb--20">
        <div class="row">
          <div class="col-3">
            <h3><b>{{ post?.meta?.locationName }}</b></h3>

            <gmb-address *ngIf="post?.meta?.address" [address]="post?.meta?.address"></gmb-address>
          </div>
          <div class="col-2">
            <button mat-flat-button [color]=" post.hasError !== null ? 'warn' : 'accent' "
                    class="btn--badge btn--badge--sm">
              <i class="fas fa-exclamation-triangle"></i>
              <span>Failed</span>
            </button>
          </div>
          <div class="col-6">
            <h3><b>Details:</b></h3>
            <ng-container *ngIf="!post.error?.description; else normalStructure">
              <read-more [text]="post.error?.message" [maxLength]="300"></read-more>
            </ng-container>
            <ng-template #normalStructure>
              <div class="m--10" *ngIf="post.error?.description?.details?.length > 0; else noDetail">
                <div *ngFor="let error of post.error?.description?.details">
                  <div *ngFor="let errorChild of error.errorDetails">
                    - {{errorChild.message}}
                  </div>
                </div>
              </div>
              <ng-template #noDetail>
                {{post.error?.message||'Unknown error'}}
              </ng-template>
            </ng-template>
          </div>
          <div class="col-1">
            <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg" (click)="retry(post)">
                <i class="fas fa-redo" [ngClass]="{'fa-spin': false}"></i>
            </button>
          </div>
        </div>
      </mat-card>
    </ng-container>
    </div>
    <div mat-dialog-actions class="dialog__footer">
      <button mat-button
              tabIndex="-1"
              color="primary"
              mat-dialog-close
              class="btn btn-cancel">
              Cancel
      </button>
      <button *ngIf="!this.loadingPostErrors && this.consumeData?.bulk"
          tabIndex="-1"
          mat-flat-button
          color="primary"
          class="btn btn--action"
          (click)="retryAll()">
          Retry all
      </button>
    </div>
  `,
})
export class PostsErrorsComponent extends BaseComponent {
  consumeData: { bulk: boolean, location: any, bulkId: string } | null = null;
  locationsPost: any[] = [];
  loadingPostErrors = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalConfirmData,
    public dialogRef: MatDialogRef<any>,
    private postService: PostService,
    private googleAccountService: GoogleAccountService,
    private google: GoogleService,
    private snack: SnackbarService,
    private _sessionS : SessionService
  ) {
    super();
    this.setUp();
  }

  setUp(): void {
    this.consumeData = this.data.data;
    if (this.consumeData.bulk) {
      this.postService.getBulkLocationPost(this.consumeData.bulkId).then( data => {
        this.loadingPostErrors = false;
        this.locationsPost = data;
      });
    } else {
      this.loadingPostErrors = false;
      this.locationsPost = [this.consumeData.location];
    }
  }

  async openReauth(element): Promise<void> {
    await this.onAddNewAccount(element);
  }

  async onAddNewAccount(element): Promise<void> {

    this._subscribeSafe(this.googleAccountService.onLoadAll$,
      ev => {
        if (ev.success !== true) {
          return this.snack.openError(ev.message);
        } else {
          //
        }
      });

    // TODO: Must refactor as an high level flow against all callers of google.authenticate
    try {
      this.snack.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`);
      const {uid, gid} = this._sessionS.getSession();
      const url = await this.google.authenticate(element.gid || gid, uid, element.accountId);
      const oauth = window.open(url, '_blank');

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this.
      const popupTick = setInterval(() => {
        if (oauth.closed) {
          clearInterval(popupTick);
          this.googleAccountService.loadAll();
        }
      }, 1000);
    } catch (e) {

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this, with an event
      const message = 'There was an error with the GBP Authentication service';
      return this.snack.openError(message, 4000);
    }
  }

  // TODO: Unused, remove?
  // cleanErrors(): void {
  //   if (this.consumeData.bulk) {
  //     //
  //   } else {
  //     this.postService.clearErrorsV3(this.locationsPost[0].id, 'LOCAL').then();
  //   }
  // }

  async retry(post) : Promise<void> {
    const {id} =  post;
    const type = this.consumeData?.bulk ? 'BULK' : 'LOCAL';
    try {
      this.loadingPostErrors = true;
      await this.postService.retry(id, type, 'Pending');
      this.loadingPostErrors = false;
      this.dialogRef.close(true);
    } catch (error) {
      this.snack.openError(error?.error?.message || error, 3500);
      this.loadingPostErrors = false;
    }
  }

  async retryAll() : Promise<void> {
    try {
      this.loadingPostErrors = true;
      await this.postService.retryAll(this.consumeData.bulkId, 'BULK', 'Pending');
      this.loadingPostErrors = false;
      this.dialogRef.close(true);
    } catch (error) {
      this.snack.openError(error?.error?.message || error, 3500);
      this.loadingPostErrors = false;
    }
  }
}

export enum AlertType {
  INFO, WARNING, ERROR
}
