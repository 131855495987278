// app
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// app
import {LocationService} from './location.service';

@Injectable({
  providedIn: 'root'
})
export class LocationEditService {

  private locationSubject = new BehaviorSubject<any>(null);
  gid : string
  accountId : string
  locationId : string

  constructor(private locationS: LocationService) {
  }

  setLocationEdit(value, gid?, accountId?, locationId?) : void {
    this.locationSubject.next(value);

    if (gid) {
      this.gid = gid;
    }

    if (accountId) {
      this.accountId = accountId;
    }

    if (locationId) {
      this.locationId = locationId;
    }
  }

  get locationEdit() {
    return this.locationSubject.getValue();
  }

  get locationEdit$() {
    return this.locationSubject.asObservable();
  }

  // TODO: Unused, remove
  // get placeId() {
  //   return this.locationSubject.getValue().locationKey.placeId;
  // }

  setAndUpdate() {
    this.setLocationEdit(this.locationEdit);
    return this.locationS.update(this.gid, this.accountId, this.locationId, {locationEdit: this.locationEdit});
  }

  update(gid, accountId, locationId, value) {
    return this.locationS.update(gid, accountId, locationId, {locationEdit: value});
  }
  
  isSocialMedia(item): boolean {
    return (
      item.attributeId == 'url_facebook' ||
      item.attributeId == 'url_instagram' ||
      item.attributeId == 'url_linkedin' ||
      item.attributeId == 'url_pinterest' ||
      item.attributeId == 'url_tiktok' ||
      item.attributeId == 'url_twitter' ||
      item.attributeId == 'url_youtube' ?
      true :
      false
    )
  }

  get allSocialMediaOptions(): { value: string; label: string; icon: string }[] {
    return [
      { value: 'url_twitter', label: 'Twitter', icon: '/assets/images/social-media/twitter.svg' },
      { value: 'url_facebook', label: 'Facebook', icon: '/assets/images/social-media/facebook.svg' },
      { value: 'url_instagram', label: 'Instagram', icon: '/assets/images/social-media/instagram.svg' },
      { value: 'url_linkedin', label: 'LinkedIn', icon: '/assets/images/social-media/linkedin.svg' },
      { value: 'url_pinterest', label: 'Pinterest', icon: '/assets/images/social-media/pinterest.svg' },
      { value: 'url_tiktok', label: 'TikTok', icon: '/assets/images/social-media/tiktok.svg' },
      { value: 'url_youtube', label: 'YouTube', icon: '/assets/images/social-media/youtube.svg' }
    ];
  }
}
