<div *ngIf="!loading" class="content--full p--tb-20 bg--radial-gradient-blue d-flex justify-content-center align-items-center flex-column">
	<div class="login--cont">
		<div *ngIf="registerProgress" class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed">
			<mat-spinner></mat-spinner>
		</div>

		<div cols="2" class="login--card-container pb--0">
			<div class="login--card-container-col col-first blue-background pt--80">
				<div class="logo">
					<div>
						<img 
						class="login-logo mb--20" 
						alt="Logo" 
						[src]="(domain$ | async).branding?.loginLogo || (domain$ | async).branding?.mainLogo || '/assets/images/logo/blankmap.png'"
						width="170" 
						height="59"/>
					</div>
					<div>
						<span class="txt--24 mb--10">Start your free {{ (domain$ | async).trialDays }}-day<br>trial today</span>
					</div>
				</div>
			</div>
			<div class="login--card-container-col">
				<div class="col-cont">
					<h1 class="secondary-font txt--xl mb--20 txt--black txt--center fw--regular">Create an Account</h1>
					<button mat-stroked-button class="btn--rounded" (click)="handleRegisterWithGoogle($event)">
						<div class="signup-button p--lr-14 p--tb-10">
							<img class="google-icon" src="/assets/images/icons/google-color.png">
							<span class="signup-text txt--22">Sign up with Google</span>
						</div>
					</button>
					<div class="login--signin mt--75">
						<span>Already have an account? <a class="login--link" (click)="navigationS.goToLogin()">Login Here</a></span>
					</div>
				</div>
			</div>
		</div>
		<div class="footer footer--noborder flex-column box box--md txt--center">
			<ul class="footer__nav d-flex justify-content-center mt--20 mb--40">
				<li><a class="txt--white" (click)="navigationS.goToTermsOfService()">Terms and Conditions</a></li>
				<li><a class="txt--white" (click)="navigationS.goToPrivacyPolicy()">Privacy Policy</a></li>
				<li><a class="txt--white" (click)="navigationS.goToPaymentPolicy()">Payment Policy</a></li>
			</ul>
			<p class="txt--normal txt--white m--0">&copy; {{ (domain$ | async).branding?.company_name }} All Rights Reserved.</p>
		</div>
	</div>
</div>
