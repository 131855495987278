import { __awaiter, __generator } from "tslib";
// dep
import { OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// app
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session.service';
var DashboardFilterComponent = /** @class */ (function () {
    function DashboardFilterComponent(router, route, locationService, modalService, _sessionS) {
        this.router = router;
        this.route = route;
        this.locationService = locationService;
        this.modalService = modalService;
        this._sessionS = _sessionS;
        this.accountId = null;
        this.progress = false;
        this.ClickEvent = new EventEmitter();
        //---Location Info type
        this.location = null;
        this.locationId = null;
        this.isProgressPush = false;
        this.isProgressFetch = false;
        this.topBanner = false;
        // --- Services
        this.updateService = false;
        this.UpdateServices = new EventEmitter();
        this.UpdateMenuList = new EventEmitter();
        this.RefreshButtonPressed = new EventEmitter();
        this.PurgeButtonPressed = new EventEmitter();
        this.isRefreshing = false;
        this.isPurging = false;
        this.isProgressSave = false;
        this.isShared = false;
        this.session$ = this._sessionS.session$;
    }
    DashboardFilterComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loc, isTrial;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.route.snapshot.parent.params.locationId) return [3 /*break*/, 2];
                        if (this.locationId === null) {
                            this.locationId = this.route.snapshot.parent.params.locationId;
                        }
                        return [4 /*yield*/, this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId)];
                    case 1:
                        loc = _a.sent();
                        isTrial = this._sessionS.getSession().isTrial;
                        if (isTrial || (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                            loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                            this.topBanner = true;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.topBanner = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.updateServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateService = true;
                        return [4 /*yield*/, this.locationService.saveServices(this.accountId, this.locationId).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getServices()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.getServices = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var loc;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId)];
                    case 1:
                        loc = _b.sent();
                        this.UpdateServices.emit((_a = loc.serviceList, (_a !== null && _a !== void 0 ? _a : [])));
                        this.updateService = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.updateMenu = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateService = true;
                        return [4 /*yield*/, this.locationService.saveMenu(this.accountId, this.locationId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getMenu()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.getMenu = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId)];
                    case 1:
                        loc = _a.sent();
                        this.UpdateMenuList.emit(loc.menuList);
                        this.updateService = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.launcAction = function (action) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(action === 'lock')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.locationService.isLocked(this.locationId).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (!res.locked) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.modalService.openInfoModal("Heads Up", "An identical location in our network is already locked. Please contact support.")];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                    case 3:
                        this.ClickEvent.emit(action);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardFilterComponent.prototype.GoBack = function () {
        this.router.navigate(['/accounts', this.accountId, 'locations']);
    };
    DashboardFilterComponent.prototype.refreshButtonPressed = function () {
        this.RefreshButtonPressed.emit();
    };
    DashboardFilterComponent.prototype.purgeButtonPressed = function () {
        this.PurgeButtonPressed.emit();
    };
    return DashboardFilterComponent;
}());
export { DashboardFilterComponent };
