/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./search-keywords-wrapper.component";
import * as i2 from "@angular/router";
var styles_SearchKeywordsWrapperComponent = [];
var RenderType_SearchKeywordsWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchKeywordsWrapperComponent, data: {} });
export { RenderType_SearchKeywordsWrapperComponent as RenderType_SearchKeywordsWrapperComponent };
function View_SearchKeywordsWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_SearchKeywordsWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["dynamicContainer", 2]], null, 0, null, View_SearchKeywordsWrapperComponent_1))], null, null); }
export function View_SearchKeywordsWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-search-keywords-wrapper", [], null, null, null, View_SearchKeywordsWrapperComponent_0, RenderType_SearchKeywordsWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i1.SearchKeywordsWrapperComponent, [i2.ActivatedRoute, i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchKeywordsWrapperComponentNgFactory = i0.ɵccf("app-search-keywords-wrapper", i1.SearchKeywordsWrapperComponent, View_SearchKeywordsWrapperComponent_Host_0, {}, {}, []);
export { SearchKeywordsWrapperComponentNgFactory as SearchKeywordsWrapperComponentNgFactory };
