<div class="p--30">
  <div *ngIf="hasInfoNotification" class="alert alert-notification-dark w--100 mt--20">
    <span class="icon-info-message col-2 vertical--align--top">
      <em class="fas fa-info"></em>
    </span>
    <p class="col-10 display-in-bl">{{txtInfoNotification}}</p>
  </div>

  <!-- Search field -------->
  <div class="mt--20 mb--10">
    <h4 class="txt--black">Search Locations</h4>
  </div>

  <div class="dialog__search">
    <div class="input-field input-field--search">
      <div class="table__icon">
        <em class="fas fa-search"></em>
      </div>
      <input placeholder="Type to search" [(ngModel)]="search" (input)="filterLocation($event.target.value, $event)">
    </div>
  </div>
  
  <!-- Accounts List -------->
  <div class="dialog__content dialog__content--height pt--0" mat-dialog-content>
    <div class="mt--20 mb--10">
      <h4 class="txt--black">Accounts</h4>
    </div>

    <ng-container *ngIf="!noAccounts; else NoDataAccounts">
      <mat-progress-bar mode="indeterminate" *ngIf="(accounts?.length == 0 && !isFilter) || loadingSearch ">
      </mat-progress-bar>

      <mat-accordion *ngIf="!loadingSearch" multi>
        <mat-expansion-panel [expanded]="accounts.length === 1 " #AccordionPanel (opened)="openAccount(a)"
          *ngFor="let a of accounts;let i = index;" hideToggle
          [ngStyle]="{'margin-left': '-30px', 'margin-right': '-30px'}">

         <!-- Account Name -------->
          <mat-expansion-panel-header (click)="detectToggle()">
            <div
              class="box box--padding-xxxs box--rounded bg--lightblue d-flex justify-content-between align-items-center">
              <h6 class="fw--medium txt--blue m--0">{{a.accountName}}</h6>
              <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
                <span class="mat-expansion-panel-icon" data-html2canvas-ignore="true"></span>
              </mat-icon>
            </div>
          </mat-expansion-panel-header>

          <!-- Account Locations -------->
          <mat-progress-bar *ngIf="!a.locations" mode="indeterminate">
          </mat-progress-bar>

          <ng-container *ngIf="a.locations && a.locations?.length > 0">
            <div class="Adjust">
              Select all <mat-slide-toggle class="float-right" [checked]="a?.selectAll" [disabled]="a?.deny"
                (change)="changeAccountSelected(a)" 
                (drag)="changeAccountSelected(a)"
                (click)="$event.stopPropagation();" color="primary">
              </mat-slide-toggle>
            </div>

            <div class="dialog-row pr--15 pl--15 d-flex justify-content-between align-items-center" 
                  [id]="l.accountId"
                 *ngFor="let l of a.locations">

              <!-- Account Location -------->
              <div class="company">
                <div class="table__icon table__icon--lg">
                  <svg-icon src="/assets/images/icons/pin.svg"></svg-icon>
                </div>
                <div class="company__info">
                  <h3 class="company__title m--0"><strong>{{l.locationName}}</strong></h3>
                  <gmb-address 
                    [address]="l?.location?.address || l?.address"
                    [serviceAreas]="l?.location?.serviceArea?.places?.placeInfos || l?.serviceArea?.places?.placeInfos">
                  </gmb-address>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <!-- Go to Location -->
                <div *ngIf="hasGoToLocation">
                  <button mat-icon-button color="primary" class="btn btn-icon buttons-actions" (click)="goToLocation(a,l)">
                      <em class="fal fa-external-link fw--black txt--md"></em>
                  </button>
                </div>

                <!--switch-->
                <mat-slide-toggle [checked]="l.checked" [disabled]="!checkUpgrade && !l.checked && l.deny"
                  (change)="changeLocationsSelected(l,a)" (drag)="changeLocationsSelected(l,a)"
                  [gmbLGF]="{location: l, account: a.accountId}"
                  [gmbLGB]="{location: l, account: a.accountId, user: selectByUser}"
                  (denyAccess)="l.deny=$event"
                  [color]="!(session$ | async).isTrial && !checkUpgrade && l.checked && l.deny ? 'warn' : 'primary'">
                </mat-slide-toggle>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <!-- No Accounts message -------->
    <ng-template #NoDataAccounts>
      <h3 style="text-align: center; margin: 10px 0px">No Accounts</h3>
    </ng-template>
  </div>
</div>