// dep
import {Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute } from '@angular/router';

// app
import {AuthService} from 'src/app/services/auth.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { BaseComponent } from 'src/app/components/base.component';
// import { Messages } from 'src/app/constants/messages';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:  ['./login.component.scss']
})
export class LoginComponent extends BaseComponent {
  public loginForm: FormGroup;
  public loginProgress = false;

  // TODO: This seems an unfinished part of a button to show/hide password
  public hidePassword = true;

  domain$ = this._sessionS.domain$
  
  constructor(
    private _authS: AuthService,
    private _sessionS: SessionService,
    private _snack: SnackbarService,
    private _userService: UserService,
    private _modalService: ModalService,
    private _route: ActivatedRoute,
    public navigationS : NavigationService
  ) {
    super();
    const domain = this._sessionS.getDomain()
    if (domain.blockedDomain) 
        this._modalService.openInfoModal('Domain Blocked', `Contact your account administrator`)

    this.loginForm = new FormGroup({
      email:    new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });

    this._waitPersistedAuthSession();
  }


  private async _waitPersistedAuthSession() : Promise<void> {
    // Wait if there is some Auth session persisted
    const auth = await this._authS.waitAuthSession();

    this._subscribeSafe(this._route.queryParams,
      async (params) => {
      // Receive an "?impersonate_token=XXX" query param on the route and login with it
      // Will logout and login with impersonate_token if is already logged-in
      if (params?.impersonate_token) 
        await this._impersonate(params.impersonate_token);
    });

    if(!auth) {
      return;
    } 
    
    // /login was loaded not by a fallback redirect when no session was found but
    // by a direct navigation to /login with a persistent session already available
    
    if(auth.sessionType === 'EXTERNAL_GRADER') {
      // this._auth.signOut(true, false);
      await this._authS.signOut();
      return // never reached
    }

    const s = await this._sessionS.waitSession();
    await this._authS.logoutIfDomainValidationFails(s.user);

    // This is misleading, as here the lastLogin is updated after a persisted session is
    // recovered, NOT after a manual-login.
    const domain = this._sessionS.getDomain();
    await this._userService.updateLastLogin(s.gid, s.uid, new Date(), domain.xDomainName);

    this._snack.openSuccess('You are being automatically logged in using your existing session!');
    await this._authS.redirectAfterLogin();

  }


  private async _impersonate(impersonate_token : string) : Promise<void> {
    console.debug("Impersonate login with token="+impersonate_token);
    if(await this._authS.waitAuthSession()) {
      // Sign out of the current session when receiving an ?impersonate_token=XX
      // param, by clearing the current session and reloading to the
      // same /login?impersonate_token=XX url
      await this._authS.signOut(false); // impersonate
      return // signed-out, never reached
    }

    try {
      this.loginProgress = true;
      const err_msg = await this._authS.signInWithImpersonateToken(impersonate_token);
      if(err_msg) {
        await this._modalService.openWarningModal('Error', "Error impersonating: "+err_msg);
        await this._authS.signOut();
      } else {
        console.debug("Impersonate OK")
        // Redundant, already done in signInWithImpersonateToken, TODO: Remove
        // await this._auth.initSession(); 
      }
    } finally {
      this.loginProgress = false
    }
  }

  public async signInWithEmailAndPassword(event : Event) : Promise<void> {
    event.preventDefault();
    try {
      this.loginProgress = true;
      const { email, password } = this.loginForm.value;
      await this._authS.signInWithEmailAndPassword(email, password)
    } catch(err) {
      // TODO: Comparison against error string, code smell
      if (err.message === 'registration domain is not the current domain') {
        this._openWrongDomainModal();
      } else if (err.code === "auth/user-not-found") {
        this._snack.openError('The email you entered is incorrect or not registered!');
      } else {
        console.log(err);
        this._snack.openError('The combination of email/password you entered is incorrect!');
      }
    } finally {
      this.loginProgress = false
    } 

 }

  public async signInWithGoogle(event : Event): Promise<void> {
    event.preventDefault();
    try {
      this.loginProgress = true;
      await this._authS.signInWithGooglePopup();
    } catch(err) {
      this.loginProgress = false;
      // TODO: Comparison against error string, code smell
      if (err.message === 'registration domain is not the current domain') {
        await this._openWrongDomainModal();
      } else {
        this._snack.openError('This account could not be found or the popup was closed. You need to register first');
      }
    } finally {
      this.loginProgress = false;
    }
  }

  private async _openWrongDomainModal() : Promise<void> {
    await this._modalService.openInfoModal('You can’t login here…',
      'You appear to have a valid account but you are logging-in to the wrong domain. '+
      'Please visit the correct site and try again.');
  }

}
