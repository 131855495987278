import { __awaiter, __generator } from "tslib";
import { Router } from '@angular/router';
// app
import { SessionService } from './session.service';
import { AuthProxyService } from './auth.proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./session.service";
import * as i3 from "./auth.proxy.service";
var NavigationService = /** @class */ (function () {
    function NavigationService(_router, _sessionS, _authProxyS) {
        this._router = _router;
        this._sessionS = _sessionS;
        this._authProxyS = _authProxyS;
    }
    Object.defineProperty(NavigationService.prototype, "_branding", {
        get: function () {
            return this._sessionS.getDomain().branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationService.prototype, "supportMailURL", {
        get: function () {
            var _a, _b;
            var b = this._branding;
            return ((_a = b) === null || _a === void 0 ? void 0 : _a.contact_us_link) || "mailto:" + ((_b = b) === null || _b === void 0 ? void 0 : _b.contact_us);
        },
        enumerable: true,
        configurable: true
    });
    // TODO: These methods should be moved to a separate NavigationService
    // remove after moving all their implementations
    NavigationService.prototype._openTextReaderNewTab = function (link_name, routePostfix) {
        var _a;
        var content = this._sessionS.getDomain().content;
        window.open(((_a = content) === null || _a === void 0 ? void 0 : _a[link_name]) || "/text-reader/" + routePostfix, '_blank');
    };
    NavigationService.prototype.goToTermsOfService = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._openTextReaderNewTab('terms_service_link', 'terms-and-conditions')];
            });
        });
    };
    NavigationService.prototype.goToPrivacyPolicy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._openTextReaderNewTab('privacy_policy_link', 'privacy-policy')];
            });
        });
    };
    NavigationService.prototype.goToPaymentPolicy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._openTextReaderNewTab('payment_policy_link', 'payment-policy')];
            });
        });
    };
    NavigationService.prototype.goToContactSupport = function () {
        window.open(this.supportMailURL);
    };
    NavigationService.prototype.goToRegister = function () {
        var signUp_url = this._branding.signUp_url;
        if (signUp_url)
            return this._router.navigateByUrl(signUp_url);
        else
            return this._router.navigate(['register']);
    };
    NavigationService.prototype.goToLogin = function () {
        return this._router.navigate(['/login']);
    };
    NavigationService.prototype.goToAdmin = function () {
        window.open('https://admin.maplabs.com/', '_blank', 'noopener');
    };
    NavigationService.prototype.goToPricingWeb = function () {
        var url = this._branding.pricing_url || 'https://www.maplabs.com/pricing/';
        window.open(url, '_blank');
    };
    NavigationService.prototype.goToProfessionalServicesWeb = function () {
        // should be replaced with a variable if possible, retrieving this path from a config file to allow whitelabels to work too
        window.open('https://calendly.com/maplabs/discovery-call', '_blank');
    };
    /**
     * Signouts and reloads the page
     */
    NavigationService.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authProxyS.signOut()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.AuthProxyService)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
