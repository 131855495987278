import { __awaiter, __generator } from "tslib";
import { MatDialog } from "@angular/material/dialog";
// app
import { GroupService } from '../services/group.service';
import { SpinnerService } from '../services/spinner.service';
import { SubscriptionService } from '../services/subscription.service';
import { SessionService } from '../services/session.service';
var FeaturedDatePickerComponent = /** @class */ (function () {
    function FeaturedDatePickerComponent(data, dialog, _groupService, _sessionS, _spinnerService, _subscriptionService) {
        this.data = data;
        this.dialog = dialog;
        this._groupService = _groupService;
        this._sessionS = _sessionS;
        this._spinnerService = _spinnerService;
        this._subscriptionService = _subscriptionService;
        this.session$ = this._sessionS.session$;
        this._spinnerService.loading$.next(false);
    }
    FeaturedDatePickerComponent.prototype.dismissModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var now, tomorrow;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        now = new Date();
                        tomorrow = new Date(now);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 4, 5]);
                        this._spinnerService.loading$.next(true);
                        return [4 /*yield*/, this._groupService.updateGroup(this._sessionS.getSession().gid, { dismissModalDatePicker: tomorrow })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FeaturedDatePickerComponent.prototype.openUpgrade = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._subscriptionService.flowChangeLocationsPlan([this.data])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FeaturedDatePickerComponent.OPTS_DEFAULT = { config: { width: '470px',
            height: '420px' } };
    return FeaturedDatePickerComponent;
}());
export { FeaturedDatePickerComponent };
