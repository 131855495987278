import { ISelectedDateRange } from './../../../constants/data-picker';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ReportsFilterConfigService } from './reports-filter-config.service';
import { KeywordsReportDataService } from './keywords-report-data.service';
import { IReportFilterConfigData, IPaginationData } from './../../../constants/report-filter-config-data';
@Injectable({
    providedIn: 'root'
})
export class KeywordsFilterConfigService extends ReportsFilterConfigService {

    public keywordsFilterConfigObject: IReportFilterConfigData = null;

    constructor(
        protected _http: HttpClient,
        private _keyRepDataS: KeywordsReportDataService
    ) {
        super(_http)
    }

    setInitialFilterConfigData(configData: any): void {
        this.keywordsFilterConfigObject = {
            gid: configData.gid,
            accountId: configData.accountId,
            locationId: configData.locationId,
            startDate: this._starterMinDate,
            endDate: this._starterMaxDate,
            sort: {
                sortBy: 'impressions',
                sortOrder: -1
            },
            page: 1,
            size: 25,
            filteredKeyword: ''
        }
    }

    // calls keywordsReportDataService and prompts the report data retrieval process
    // name pending, this one is not good
    updateFilterConfigAndGetReportData(selectedDates: ISelectedDateRange): void {
        console.log('updateFilterConfig called');
        
        
        // update the keywordsFilterConfigObject accordingly

        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject, 
            startDate: selectedDates.minDate, 
            endDate: selectedDates.maxDate 
        }

        // call the getReportData method
        this._keyRepDataS.getKeywordsResults(this.keywordsFilterConfigObject);
    }

    getTableDataFilteredByKeyword(keyword: string): void {
        // when a new keyword filter is requested (or removed), we reset sorting and pagination
        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject,
            sort: {
                sortBy: 'impressions',
                sortOrder: -1
            },
            page: 1,
            size: 25,
            filteredKeyword: keyword
        }
        this._keyRepDataS.getKeywordsTableData(this.keywordsFilterConfigObject, keyword);
    }

    getTableDataPaginated(tableType: string, pagination: IPaginationData): void {
        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject,
            page: pagination.page,
            size: pagination.size
        }
        
        this._keyRepDataS.getTableDataPaginated(this.keywordsFilterConfigObject, tableType);
    }
    
    getTableDataSorted(sort: {sortBy: string, sortOrder: string}): void {
        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject,
            sort:{
                sortBy: sort.sortBy,
                sortOrder: sort.sortOrder === 'asc' ? 1 : -1
            }
        }
        this._keyRepDataS.getTableDataSorted(this.keywordsFilterConfigObject);
    }
}