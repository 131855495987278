// dep
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject } from "rxjs";

// app
import {
  GRADE_LEADS, LOCATIONS
} from "../constants/firestore/collections";
// import { FirestoreService } from "./firestore.service";
// import { Pageable } from "../constants/pageable";
import GradeLead from "../constants/firestore/grade-leads";

@Injectable({
  providedIn: "root"
})

export class GradeLeadService {
  // public loading = false;
  // private _gradeLeads = new BehaviorSubject<GradeLead[]>([])
  public firebaseGradeId$ = new BehaviorSubject("")


  constructor(
    private afs: AngularFirestore,
    // private fs: FirestoreService
  ) {
  }

  // TODO: Unused, remove
  // getAllDomains() {   
  //   return  this.afs.collectionGroup<any>(GRADE_LEADS).valueChanges()
  // }
  // 
  // getAllGradeLeads(domain: string) {
  //   return  this.afs.collection(GRADE_LEADS).doc(domain).collection(LOCATIONS).valueChanges()
  // }
  // 
  // getGradeLeads(pageable?: Pageable, filter?: { domain: string, viewed: boolean}, next?, prev?, order = 'createdAt', direction = 'desc', values?): Observable<any> {
  //   return this.fs.paginateGrades(GRADE_LEADS, filter, order, direction, pageable, next, prev, values);
  // }
  //  
  // set(gradeLeads: GradeLead[]) {
  //   this._gradeLeads.next(gradeLeads);
  // }
  //
  // getGradeLeadPaginate(count, pageable, actions) {
  //   return this.fs.formatPagination(count, pageable, actions);    
  // }
  
  /**
   * this method save in firestore entity Grade Lead
   * @param GradeLead 
   */
  save(gradeLead: GradeLead) : void {
    this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).get().toPromise().then( domainCollection => {
      if ( !domainCollection.exists ) {
        this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).set({ domain: gradeLead.registrationDomain });
      }
      this.afs.collection(GRADE_LEADS)
        .doc(gradeLead.registrationDomain)
        .collection(LOCATIONS)
        .add(gradeLead)
        .then((docRef) => {
          this.firebaseGradeId$.next(docRef.id);
        })
        .catch(() => { });
    });
  }

  // TODO: Unused, remove
  // update(gradeLead: GradeLead) {
  //   return this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).collection(LOCATIONS).doc(gradeLead.id).set(gradeLead)
  // }

  getGradeLeadById(id: string, domain: string): Promise<GradeLead> {
    return this.afs.collection(GRADE_LEADS)
      .doc(domain)
      .collection(LOCATIONS)
      .doc(id)
      .get()
      .toPromise()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const gradeLead: GradeLead = {
            id: doc.id,
            name: data.name,
            locationName: data.locationName,
            locationId: data.locationId,
            accountId: data.accountId,
            accountName: data.accountName,
            registrationDomain: data.registrationDomain,
            address: data.address,
            email: data.email,
            viewed: data.viewed,
            createdAt: data.createdAt,
          };
          return gradeLead;
        }
      });
  }

  setHasPdf(gradeLead: GradeLead) : void{
    this.afs.collection(GRADE_LEADS)
      .doc(gradeLead.registrationDomain)
      .collection(LOCATIONS)
      .doc(gradeLead.id)
      .update({ hasPdf: true });
  }
}
