import { __extends, __read } from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { getNextReccurringDate } from '../../../helpers/functions.helpers';
import * as _moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../../components/confirm.component';
import { ModalConfirmData } from '../../../classes/modal-confirm-data';
import { of } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
var RecurringComponent = /** @class */ (function (_super) {
    __extends(RecurringComponent, _super);
    function RecurringComponent(snack, dialog) {
        var _this = _super.call(this) || this;
        _this.snack = snack;
        _this.dialog = dialog;
        _this.postDateAvaible = false;
        _this.postTypeName = '';
        _this.recurring = null;
        _this.recurringChange = new EventEmitter();
        _this.publishRecurringNow = false;
        _this.publishRecurringNowChange = new EventEmitter();
        _this.isRecurringValid = new EventEmitter();
        _this.eventTimeActivate = false;
        _this.deactivateDuration = false;
        _this.previewDate = new Date();
        _this.formRecurring = new FormGroup({
            time: new FormControl('00:01', [Validators.required]),
            frecuency: new FormControl('WeeklyOn', [Validators.required]),
            isAllMonth: new FormControl(false),
            repeatOn: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
            duration: new FormControl(null, [Validators.min(0), Validators.max(7)]),
            eventTimeStart: new FormControl(null),
            eventTimeEnd: new FormControl(null),
        });
        return _this;
    }
    RecurringComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formRecurring.statusChanges.subscribe(function (status) {
            if (status === 'INVALID') {
                _this.isRecurringValid.emit(false);
                return;
            }
            _this.isRecurringValid.emit(true);
        });
        this.formRecurring.valueChanges.subscribe(function () {
            var date = new Date();
            var nextDate = getNextReccurringDate(date, _this.repeatOn.value, _this.frecuency.value, _this.time.value);
            var recurring = {
                time: _this.time.value,
                frecuency: _this.frecuency.value,
                repeatOn: _this.repeatOn.value,
                nextDate: nextDate,
                duration: _this.duration.value,
                eventTimeStart: _this.eventStart.value,
                eventTimeEnd: _this.eventEnd.value,
                isAllMonth: _this.isAllMonth.value
            };
            _this.recurringChange.emit(recurring);
        });
        if (this.postDateAvaible) {
            this.duration.setValidators([Validators.required, Validators.min(0), Validators.max(7)]);
        }
        if (this.recurring) {
            if (this.recurring.frecuency === 'MonthlyOn') {
                if (this.postDateAvaible)
                    this.duration.setValidators([Validators.required, Validators.min(0), Validators.max(31)]);
                if (this.recurring.isAllMonth)
                    this.duration.setValidators([]);
            }
            this.deactivateDuration = this.recurring.isAllMonth ? this.recurring.isAllMonth : false;
            if (this.deactivateDuration) {
                this.formRecurring.controls.duration.disable();
            }
            this.eventTimeActivate = this.recurring.eventTimeEnd ? true : false;
            if (this.recurring.duration !== null && this.recurring.duration > 0) {
                this.eventEnd.setValidators([]);
                this.eventStart.setValidators([]);
            }
            else if (this.recurring.duration !== null && this.recurring.duration === 0) {
                this.eventTimeActivate = true;
                this.eventStart.setValidators([Validators.required]);
                this.eventEnd.setValidators([this.valitadeGreaterTime(), Validators.required]);
            }
            this.formRecurring.setValue({
                time: this.recurring.time,
                frecuency: this.recurring.frecuency,
                repeatOn: this.recurring.repeatOn,
                duration: this.recurring.duration !== null ? this.recurring.duration : null,
                isAllMonth: this.deactivateDuration,
                eventTimeStart: this.recurring.eventTimeStart ? this.recurring.eventTimeStart : null,
                eventTimeEnd: this.recurring.eventTimeEnd ? this.recurring.eventTimeEnd : null
            });
        }
        this._subscribeSafe(this.isAllMonth.valueChanges, function (data) {
            _this.deactivateDuration = data;
            if (data) {
                _this.formRecurring.controls.duration.disable();
                _this.duration.setValue(null);
                _this.duration.setValidators([]);
                _this.eventEnd.setValidators([]);
                _this.eventStart.setValidators([]);
            }
            else {
                _this.formRecurring.controls.duration.enable();
                _this.duration.setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
            }
            _this.duration.updateValueAndValidity();
            _this.eventEnd.updateValueAndValidity();
            _this.eventStart.updateValueAndValidity();
        });
        this._subscribeSafe(this.duration.valueChanges, function (data) {
            _this.eventStart.updateValueAndValidity();
            _this.eventEnd.updateValueAndValidity();
        });
        this._subscribeSafe(this.repeatOn.valueChanges, function (data) {
            _this.eventStart.updateValueAndValidity();
            _this.eventEnd.updateValueAndValidity();
        });
    };
    RecurringComponent.prototype.valitadeGreaterTime = function () {
        var _this = this;
        return function (control) {
            var dateClean = new Date();
            var time = _this.time.value.split(':');
            if (!control.value)
                return { minorDate: true };
            if (_this.publishRecurringNow) {
                dateClean.setTime(dateClean.getTime() + 30 * 60000);
            }
            else {
                dateClean.setHours(time[0], time[1], 0, 0);
            }
            _this.previewDate = dateClean;
            var value = control.value.split(":");
            var endDate = new Date();
            endDate.setHours(value[0], value[1], 0, 0);
            if (dateClean.getTime() >= endDate.getTime()) {
                return { minorDate: true };
            }
            return null;
        };
    };
    RecurringComponent.prototype.valitadeGreaterThanTodayTime = function () {
        var _this = this;
        return function (control) {
            if (!control.value)
                return null;
            var _a = __read(control.value.split(':'), 2), controlHours = _a[0], controlMinutes = _a[1];
            var now = _moment();
            var nextDateStart = new Date(_this.recurring.nextDate.getFullYear(), _this.recurring.nextDate.getMonth(), _this.recurring.nextDate.getDate(), 0, 0, 0, 0);
            var nextDateEnd = _this.frecuency.value === 'WeeklyOn'
                ? new Date(nextDateStart.getFullYear(), nextDateStart.getMonth(), nextDateStart.getDate() + (_this.duration.value - 1), controlHours, controlMinutes)
                : new Date(nextDateStart.getFullYear(), nextDateStart.getMonth() + 1, nextDateStart.getDate() - 1, controlHours, controlMinutes);
            var nextDate = _moment(nextDateEnd);
            var isBefore = nextDate.isBefore(now);
            if (isBefore)
                return { minorDate: true };
            return null;
        };
    };
    RecurringComponent.prototype.frecuencyControl = function (event) {
        if (event.value === 'MonthlyOn') {
            this.duration.setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
            this.repeatOn.setValidators([Validators.required, Validators.min(1), Validators.max(29)]);
            this.repeatOn.setValue("1");
        }
        else {
            this.duration.setValidators([Validators.required, Validators.min(0), Validators.max(7)]);
            this.repeatOn.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
            this.repeatOn.setValue("1");
        }
        this.frecuency.setValue(event.value);
    };
    Object.defineProperty(RecurringComponent.prototype, "frecuency", {
        get: function () {
            return this.formRecurring.get('frecuency');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecurringComponent.prototype, "repeatOn", {
        get: function () {
            return this.formRecurring.get('repeatOn');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecurringComponent.prototype, "time", {
        get: function () {
            return this.formRecurring.get('time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecurringComponent.prototype, "isAllMonth", {
        get: function () {
            return this.formRecurring.get('isAllMonth');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecurringComponent.prototype, "duration", {
        get: function () {
            return this.formRecurring.get('duration');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecurringComponent.prototype, "eventEnd", {
        get: function () {
            return this.formRecurring.get('eventTimeEnd');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecurringComponent.prototype, "eventStart", {
        get: function () {
            return this.formRecurring.get('eventTimeStart');
        },
        enumerable: true,
        configurable: true
    });
    RecurringComponent.prototype.callBackRecurring = function (result, nextDate) {
        if (!result) {
            this.publishRecurringNow = false;
            return;
        }
        var recurring = {
            frecuency: this.frecuency.value,
            repeatOn: this.repeatOn.value,
            time: this.time.value,
            nextDate: nextDate,
            duration: this.duration.value,
            eventTimeStart: this.eventStart.value,
            eventTimeEnd: this.eventEnd.value,
            isAllMonth: this.isAllMonth.value
        };
        return { data: recurring, publishNow: this.publishRecurringNow, duplicate: result };
    };
    RecurringComponent.prototype.apply = function () {
        var _this = this;
        if (this.formRecurring.invalid) {
            this.snack.openInfo('The form has errors', 3500);
            return of(null);
        }
        if (this.frecuency.value === 'MonthlyOn' && this.repeatOn.value === '0') {
            this.snack.openInfo('The day of the month cannot be 0', 3500);
            return of(null);
        }
        var date = new Date();
        var nextDate = getNextReccurringDate(date, this.repeatOn.value, this.frecuency.value, this.time.value);
        var utcNow = _moment.utc(date).toDate();
        var nextDateString = nextDate.getUTCFullYear() + "/" + nextDate.getUTCMonth() + "/" + nextDate.getUTCDate();
        var nowDateString = utcNow.getUTCFullYear() + "/" + utcNow.getUTCMonth() + "/" + utcNow.getUTCDate();
        if (this.publishRecurringNow && nextDateString === nowDateString) {
            var dialogRef = this.dialog.open(ConfirmComponent, {
                width: '680px',
                data: new ModalConfirmData({
                    title: 'Heads Up',
                    content: "The combination you have chosen will potentially create a duplicate post. Are you sure?",
                    confirmButtonLabel: 'Yes',
                    closeButtonLabel: 'No',
                    alertType: null,
                    modalPayment: false,
                    description: null,
                    typePaid: false
                })
            });
            return dialogRef.afterClosed().map(function (result) { return _this.callBackRecurring(result, nextDate); });
        }
        else {
            var recurring = {
                frecuency: this.frecuency.value,
                repeatOn: this.repeatOn.value,
                time: this.time.value,
                nextDate: nextDate,
                duration: this.duration.value,
                eventTimeEnd: this.eventEnd.value,
                isAllMonth: this.isAllMonth.value
            };
            return of({ data: recurring, publishNow: this.publishRecurringNow, duplicate: false });
        }
    };
    RecurringComponent.prototype.changePublishNow = function (checked) {
        this.publishRecurringNow = checked;
        this.publishRecurringNowChange.emit(checked);
        /* if (this.publishRecurringNow && this.postDateAvaible) {
          this.eventTimeActivate = true;
          this.eventStart.setValidators([Validators.required])
          this.eventStart.updateValueAndValidity()
          this.eventEnd.setValidators([this.valitadeGreaterTime(), Validators.required])
          this.eventEnd.updateValueAndValidity()
        } */
    };
    RecurringComponent.prototype.deactivateTime = function (checked) {
        this.eventTimeActivate = checked;
        if (checked) {
            this.eventStart.setValue('00:01');
            this.eventEnd.setValue('23:59');
            this.eventStart.setValidators([Validators.required]);
            this.eventEnd.setValidators([this.valitadeGreaterThanTodayTime(), Validators.required]);
            return;
        }
        this.eventStart.setValidators(null);
        this.eventEnd.setValidators(null);
        this.eventStart.setValue(null);
        this.eventEnd.setValue(null);
    };
    RecurringComponent.prototype.handleFrecuency = function (event) {
        this.repeatOn.setValue(event.day);
        this.duration.setValue(event.duration);
    };
    return RecurringComponent;
}(BaseComponent));
export { RecurringComponent };
