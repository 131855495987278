import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// app
import { SessionService } from './../../../../../services/session.service';
import { KeywordsReportDataService } from './../../../services/keywords-report-data.service';
import { KeywordsFilterConfigService } from './../../../services/keywords-filter-config.service';
import { COLORS_CHART } from './../../../../../constants/chart-color';
var SearchKeywordsComponent = /** @class */ (function () {
    function SearchKeywordsComponent(_keyFilConfS, _keyRepDataS, _route, _router, _sessionS) {
        this._keyFilConfS = _keyFilConfS;
        this._keyRepDataS = _keyRepDataS;
        this._route = _route;
        this._router = _router;
        this._sessionS = _sessionS;
        this.isReport = false;
        this.isShared = false;
        // original identifiers (might be changed)
        this.loading = true;
        this.updatingData = true;
        this.isComparisonVisible = false;
        this.keywordSearchInput = '';
        this.dataRange = { value: null, displayName: 'None' }; // <-- not entirely sure what it does
        this.colors = COLORS_CHART;
        this.currentVisibleGraph = "Total Impressions";
        this.visibleGraph = 'total-impressions';
        this.sort = {
            sortBy: 'impressions',
            sortOrder: -1,
            sortDirection: 'desc'
        };
        this.sortMultiloc = {
            sortBy: 'locationName',
            direction: 'asc'
        };
        this.displayedColumnsKeywords = ['keywords', 'impressions'];
        this.displayedColumnsMultLoc = ['location', 'Impressions', 'Keywords', 'Branded', 'Non-branded'];
        this.fieldsColumnsMultiLoc = [
            { displayName: 'locationName', fieldSort: 'locationName' },
            { displayName: 'totalImpressions', fieldSort: 'totalImpressions' },
            { displayName: 'totalKeywords', fieldSort: 'totalKeywords' },
            { displayName: 'brandedImpressions', fieldSort: 'brandedImpressions' },
            { displayName: 'nonBrandedImpressions', fieldSort: 'nonBrandedImpressions' }
        ];
        this.fieldsColumnsTopics = ['rank', 'details'];
        this.keywordsPaginate = { size: 25, page: 1 };
        this.keywordsPagination = {
            items: [],
            total: 0,
            page: 1,
            pages: 0,
            per_page: this.keywordsPaginate.size,
            hasNext: false,
            hasPrev: false,
        };
        this.topicsPaginate = { size: 25, page: 1 };
        this.topicsPagination = {
            items: [],
            total: 0,
            page: 1,
            pages: 0,
            per_page: this.topicsPaginate.size,
            hasNext: false,
            hasPrev: false,
        };
        this._keywordChangedSubject$ = new Subject();
        this._paginationChangedSubject$ = new Subject();
        this._tableOrderChangedSubject$ = new Subject();
    }
    SearchKeywordsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.gid = this._sessionS.getSession().gid;
                        if (!!this.isReport) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this._route.parent.snapshot.params.accountId];
                    case 1:
                        _a.accountId = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this._route.parent.snapshot.params.locationId];
                    case 2:
                        _b.locationId = _c.sent();
                        this.keywordsReportParams = {
                            gid: this.gid,
                            accountId: this.accountId,
                            locationId: this.locationId
                        };
                        this._keyFilConfS.setInitialFilterConfigData(this.keywordsReportParams);
                        return [3 /*break*/, 3];
                    case 3:
                        this._initializeFilterConfig();
                        this._keyRepDataS.keywordsReportDataSubject$
                            .subscribe(function (keywordsReportData) {
                            console.log('report data', keywordsReportData);
                            _this.keywordsReportData = keywordsReportData;
                            _this.lineChartDataSet = _this.keywordsReportData.impressionsStats; // <- we initialize the graph with keywords data
                            _this.loading = false;
                            _this.updatingData = false;
                        }, function (error) {
                            console.error('error while retrieving report data');
                            _this.loading = false;
                            _this.updatingData = false;
                        });
                        // reload table data when the keyword filter changes
                        this._keywordChangedSubject$
                            .pipe(debounceTime(650))
                            .subscribe(function (value) {
                            _this.updatingData = true;
                            _this._keyFilConfS.getTableDataFilteredByKeyword(value);
                        });
                        // reload table data when pagination changes
                        this._paginationChangedSubject$
                            .subscribe(function (value) {
                            _this.updatingData = true;
                            _this._keyFilConfS.getTableDataPaginated(value.tableType, value.pagination);
                        });
                        this._tableOrderChangedSubject$
                            .subscribe(function (value) {
                            _this.updatingData = true;
                            _this._keyFilConfS.getTableDataSorted(value);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.changedVisibleGraph = function (event) {
        this.visibleGraph = event.value;
        this.currentVisibleGraph = event.value.replace('-', ' ');
        this.setGraphData();
    };
    SearchKeywordsComponent.prototype.setGraphData = function () {
        this.lineChartDataSet = this.visibleGraph === 'total-impressions'
            ? this.keywordsReportData.impressionsStats
            : this.keywordsReportData.keywordsStats;
    };
    SearchKeywordsComponent.prototype.getImpressionsValue = function (numberOfImpressions) {
        return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
    };
    SearchKeywordsComponent.prototype.getComparisonBoxClass = function (field, row) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var percentage;
        switch (field) {
            case 'keywords':
                percentage = (_c = (_b = (_a = this.keywordsReportData) === null || _a === void 0 ? void 0 : _a.searchKeywordsData) === null || _b === void 0 ? void 0 : _b.comparison) === null || _c === void 0 ? void 0 : _c.trend;
                break;
            case 'impressions':
                percentage = (_f = (_e = (_d = this.keywordsReportData) === null || _d === void 0 ? void 0 : _d.searchImpressionsData) === null || _e === void 0 ? void 0 : _e.trend) === null || _f === void 0 ? void 0 : _f.total;
                break;
            case 'branded':
                percentage = (_j = (_h = (_g = this.keywordsReportData) === null || _g === void 0 ? void 0 : _g.searchImpressionsData) === null || _h === void 0 ? void 0 : _h.trend) === null || _j === void 0 ? void 0 : _j.direct;
                break;
            case 'non-branded':
                percentage = (_m = (_l = (_k = this.keywordsReportData) === null || _k === void 0 ? void 0 : _k.searchImpressionsData) === null || _l === void 0 ? void 0 : _l.trend) === null || _m === void 0 ? void 0 : _m.discovery;
                break;
            case 'keywords-table':
                percentage = (_p = (_o = row) === null || _o === void 0 ? void 0 : _o.prevComparison) === null || _p === void 0 ? void 0 : _p.percentage;
                break;
        }
        if (percentage > 0) {
            return 'chip--trend--success';
        }
        else if (percentage < 0) {
            return 'chip--trend--fail';
        }
        else {
            return 'chip--trend--info';
        }
    };
    SearchKeywordsComponent.prototype.getChipValue = function (value) {
        return (value === 0 ? '-' :
            value > 1000 ? 1000 :
                value);
    };
    SearchKeywordsComponent.prototype.keywordsSortChanged = function (event) {
        console.log(event);
        this._tableOrderChangedSubject$.next({ sortBy: event.active, sortOrder: event.direction });
    };
    SearchKeywordsComponent.prototype.keywordsFilterChanged = function (event) {
        this._keywordChangedSubject$.next(event);
    };
    SearchKeywordsComponent.prototype.tablePaginationChanged = function (tableType, event) {
        this._paginationChangedSubject$.next({ tableType: tableType, pagination: event });
    };
    SearchKeywordsComponent.prototype.goToLocations = function () {
        this._router.navigate(['/accounts', this.accountId, 'locations']);
    };
    SearchKeywordsComponent.prototype.handleExportCsvKeywordsSearch = function () {
        // to be defined, or removed
    };
    SearchKeywordsComponent.prototype.handleExportCsvTopic = function (value) {
        // to be defined, or removed
    };
    SearchKeywordsComponent.prototype._initializeFilterConfig = function () {
        var _this = this;
        this.updatingData = true;
        this._keyFilConfS.getDateValidation('keyword', [this.accountId], [this.gid], [this.locationId])
            .subscribe(function (data) {
            if (data && data.minDate && data.maxDate) {
                _this._keyFilConfS.initializeDateRestriction(data, 'keyword');
            }
            _this.updatingData = false;
        });
    };
    return SearchKeywordsComponent;
}());
export { SearchKeywordsComponent };
