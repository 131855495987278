/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * This is the old Billing page used for subscriptions with pricingVersion <= 2

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".half-width[_ngcontent-%COMP%], .mat-column-number[_ngcontent-%COMP%]{width:50%}.mat-column-amount[_ngcontent-%COMP%], .mat-column-date[_ngcontent-%COMP%]{width:20%}.mat-column-actions[_ngcontent-%COMP%], .mat-column-status[_ngcontent-%COMP%]{width:5%}.icon-price-billing[_ngcontent-%COMP%]{font-size:55%;top:-6px}"];
export { styles as styles };
