// dep
import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// app
import { ModalService } from '../services/modal.service';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import SavedLocation from '../constants/firestore/saved-location';
import { FeaturedComponent } from '../components/featured.component';
import { LocationRef } from '../constants/firestore/location-object';
import { SessionService } from '../services/session.service';

@Directive({
  exportAs: 'gmbLGF',
  selector: '[gmbLGF]'
})
export class LocationGatedFreeDirective implements OnChanges, OnInit {
  location: SavedLocation

  @Input() gmbLGF: { location: SavedLocation, account: string };
  @Output() denyAccess = new EventEmitter<boolean>();

  constructor(
    private _sessionS : SessionService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private locationService: LocationService,
    private subscriptionService: SubscriptionService,
    private element: ElementRef,
  ) {
  }

  ngOnInit() : void {
    const locationLGF = this.gmbLGF.location;
    const placeId = locationLGF?.locationId || this.route.snapshot.parent.params.locationId;
    const account = this.gmbLGF.account || this.route.snapshot.parent.params.accountId;
    const checked = locationLGF?.checked;
    if (!placeId || !account || checked) {
      return;
    }

    this.location = this.gmbLGF.location;
    const subscriptionType = this.location?.subscriptionType;
    const {subscription, isTrial} = this._sessionS.getSession();
    if (subscription.pricingVersion < 3 && !isTrial &&
        (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
         subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
    ) {
      this.element.nativeElement.removeAllListeners('click');
      this.element.nativeElement.addEventListener('click', this.handler.bind(this));
      this.denyAccess.emit(true);
      return;
    }
    this.denyAccess.emit(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.gmbLGF.firstChange) {
      this.ngOnInit();
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler(): Promise<void> {
    console.log('LocationGatedFreeDirective handler');
    const locationLGF = this.gmbLGF.location;

    const locationId = locationLGF?.locationId || this.route.snapshot.parent.params.locationId;
    const accountId  = this.gmbLGF.account     || this.route.snapshot.parent.params.accountId;
    const {isTrial, gid} = this._sessionS.getSession();

    this.location = await this.locationService.fetchLocation(gid, accountId, locationId)

    const subscriptionType = this.location.subscriptionType

    if (!isTrial && (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                     subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {

      if (!await this.modalService.openModal(FeaturedComponent, null))
        return

      if (await this.subscriptionService.flowChangeLocationsPlan([{ locationId, accountId }])) {
        this.denyAccess.emit(false);
        this.element.nativeElement.removeAllListeners('click');
      }
    }
  }
}

@Directive({
  selector: '[gmbLGUT]'
})
export class LocationGatedUltimateTrialDirective implements OnInit {
  location: SavedLocation
  @Input() gmbLGUF = () => { };

  constructor(
    private _sessionS : SessionService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private locationService: LocationService,
    private subscriptionService: SubscriptionService,
    private element: ElementRef,
  ) {
  }

  async ngOnInit() {
    const locationId : string = this.route.snapshot.parent.params.locationId
    const accountId  : string = this.route.snapshot.parent.params.accountId

    if (!locationId || !accountId) {
      return;
    }
    const {gid, subscription, isTrial} = this._sessionS.getSession();

    this.location = await this.locationService.fetchLocation(gid, accountId, locationId);

    if (subscription.pricingVersion < 3 && isTrial &&
        (this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
         this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
      this.element.nativeElement.removeAllListeners('click');
      this.element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler() : Promise<void> {
    console.log('LocationGatedUltimateTrialDirective handler');

    if(!(await this.modalService.openModal(FeaturedComponent, null)))
      return

    const locRef : LocationRef = { locationId: this.location.locationId, 
                                    accountId: this.route.snapshot.parent.params.accountId }

    if(await this.subscriptionService.flowChangeLocationsPlan([locRef]))
        console.log("upgrade successful - location-gated.directive");
    }
}

@Directive({
  exportAs: 'gmbLGB',
  selector: '[gmbLGB]'
})
export class LocationGatedBasicDirective implements OnChanges, OnInit {
  location: SavedLocation;
  @Input() gmbLGB: { location: SavedLocation, account: string, user?: boolean };
  @Output() denyAccess = new EventEmitter<boolean>();

  constructor(
    private _sessionS : SessionService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private locationService: LocationService,
    private subscriptionService: SubscriptionService,
    private element: ElementRef,
  ) {
  }

  ngOnInit() : void {
    const locationLGB = this.gmbLGB.location;
    const placeId = locationLGB?.locationId || this.route.snapshot.parent.params.locationId;
    const account = this.gmbLGB.account || this.route.snapshot.parent.params.accountId;
    const checked = locationLGB?.checked;
    if (!placeId || !account || checked) {
      return;
    }
    this.location = this.gmbLGB.location;
    const subscriptionType = this.location?.subscriptionType;
    const {subscription} = this._sessionS.getSession();
    if (subscription.pricingVersion < 3 &&
        subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.gmbLGB.user) {
      this.element.nativeElement.removeAllListeners('click');
      this.element.nativeElement.addEventListener('click', this.handler.bind(this));
      this.denyAccess.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.gmbLGB.firstChange) {
      this.ngOnInit();
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler(): Promise<void> {
    console.log('LocationGatedBasicDirective handler');
    const {isTrial, gid, isMember } = this._sessionS.getSession();

    if (isMember) {
      await this.modalService.openWarningModalNoPermissions();
      return
    }

    const locationLGB = this.gmbLGB.location;

    const locationId = locationLGB?.locationId || this.route.snapshot.parent.params.locationId;
    const accountId  = this.gmbLGB.account     || this.route.snapshot.parent.params.accountId;

    this.location = await this.locationService.fetchLocation(gid, accountId, locationId);

    if (this.location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC) {
      if (!await this.modalService.openModal(FeaturedComponent, null))
        return

      const r = await this.subscriptionService.flowChangeLocationsPlan([{ locationId, accountId }])

      if (r) {
        // FIXME: redundant comparison against ULTIMATE, bug?
        if (!isTrial && (r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE // ||
                       //r.nextPlan === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE
                        )) {
          this.denyAccess.emit(false);
        } else {
          this.ngOnInit();
        }
      }
    }
  }

}