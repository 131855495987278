export const environment = {
  production: false,
  env: 'base', // should never see base...

  // App backends URLs
  // TODO: Remove all those urls, as they should be routed on the
  // backend or dev proxy side, not from the frontend.

  // main-api
  // IMPORTANT: If (environment.apiUrlOverwriteWithWL == true) then
  // 'apiUrl' is overwritten at runtime using getDomain().apiUrl
  apiUrl:        'https://localhost:5000/api',

  // core-api
  coreApiUrl:    'http://localhost:5008',

  // post-api
  apiUrlV3:      'https://localhost:5001/api/v3',

  // billing-api
  billingApiUrl: 'https://localhost:5002/api',

  // Google
  googleApi: 'https://mybusiness.googleapis.com/v4',
  googleApiLocations: "https://mybusinessbusinessinformation.googleapis.com/v1",
  googleApiVerifications: "https://mybusinessverifications.googleapis.com/v1",
  googleAccountManagementApi: 'https://mybusinessaccountmanagement.googleapis.com/v1',

  // FIXME: this key is a secret leak? This endpoint should be used thru backend endpoints
  fbDynamicLinkApi: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAna8iSk-6bFXPsKwcD6Enj4fvgQIioW18',
  fbDynamicLinkDomain: 'https://gmb.page.link',

  // Stripe
  defaultStripePublicKey: 'pk_test_f1y3zSBWhe4bj6IHwQjmH4yi', // Just for initialization purposes

  // Flags
  queuesEnabled: true,
  saveLocationInChain: false,
  apiUrlOverwriteWithWL : true,
  userGuidingEnabled: false,
  ga4Enabled: false
};
