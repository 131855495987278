// dep
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {firestore} from 'firebase/app';
import { HttpClient } from '@angular/common/http';

// app
import {FirestoreService} from './firestore.service';
import {GROUPS, NOTIFICATIONS} from '../constants/firestore/collections';
import {TYPE_LOG_USER, TYPE_LOG_LOCATION, TYPE_LOG_GRADE, TYPE_LOG_SUBSCRIPTION} from "../constants/notifications";
import { environment } from '@environment';
import { Pageable } from '../constants/pageable';
import { SessionService } from './session.service';

type NOTIFICATION_TYPE = 'success' | 'error' | 'info' | 'Informative';
type TYPE_LOG = 'USER' | 'LOCATION' | 'GRADE' | 'SUBSCRIPTION'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private _fs: FirestoreService,
    private _afs: AngularFirestore,
    private _http: HttpClient,
    private _sessionS : SessionService
  ) { }

  private _col() {
    const {gid}  = this._sessionS.getSession()
    return this._afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS)
  }

  async saveNotification(message: string, type: NOTIFICATION_TYPE, typeLog: TYPE_LOG, meta?) : Promise<void> {
    const {gid}        = this._sessionS.getSession();
    const {domainName} = this._sessionS.getDomain();
    await this._col().add({gid, domainName, message, type, meta, createAt: firestore.Timestamp.now(), status: 'unviewed', typeLog});
  }

  getNotifications() {
    const {gid}  = this._sessionS.getSession();
    return this._fs.colWithIds$(`${GROUPS}/${gid}/${NOTIFICATIONS}`, ref => ref
      .where('status', '==', 'unviewed').orderBy('createAt', 'desc').limit(100));

  }

  paginateNotifications(pageable: Pageable, next?, prev?, where?:  {field: string, operator: "==" | "<" | ">" | "<=" | ">=" | "array-contains" | "!=", value: any}) {
    const {gid}  = this._sessionS.getSession();

    return this._fs.paginate<any[]>(`${GROUPS}/${gid}/${NOTIFICATIONS}`, 'createAt', null
    , {
      size: pageable.size,
      page: pageable.page
    }, false, next, prev, null, null, 'desc');

  }

  deleteAll() : Promise<void> {
    const {gid}  = this._sessionS.getSession();

    return this._http.get(`${environment.apiUrl}/v2/groups/gid/${gid}/delete/notification`).toPromise<any>();
  }

  deleteNotification(docId : string) : void {
    this._col().doc(docId).delete();
  }

  // TODO: Unused, remove
  // countNotifications(gid) {
  //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS,
  //     ref => ref.where('status', '==', 'unviewed')).snapshotChanges().pipe(map(documents => documents.length));
  // }
  // 
  // merge(gid, docId, data) {
  //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS).doc(docId).set(data, {merge: true});
  // }

  getMetaTypeLog(typeLog: string, data: any) {
    switch (typeLog) {
      case TYPE_LOG_USER:
        return { email: data.email, displayName: data.displayName, role: data.role, uid: data.uid}
      case TYPE_LOG_LOCATION:
        return { accountId: data.accountId, locationName: data.locationName, locationId: data.locationId, address: data.address}
      case TYPE_LOG_GRADE:
        return { accountId: data.accountId, email: data.email, displayName: data.displayName, role: data.role, uid: data.uid}
      case TYPE_LOG_SUBSCRIPTION:
        return { accountId: data.accountId, locationId: data.locationId, locationName: data.locationName, 
                 subscriptionType: data.subscriptionType, planBefore: data.planBefore, planAfter: data.planAfter}
      default:
        break;
    }
  }

}
