// TODO: This file should be refactored against admin-frontend/modal.service.ts

// dep
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ComponentType } from "@angular/cdk/portal"; // TODO: use "import type" after upgrading TS

// app
// IMPORTANT: Only add simple components here that doesn't depend on auth.service or
// other services, to avoid circular deps. In doubt, don't modify this file and call openModal()
// from an outer module importing the component on that outer module. Or inject the data needed
// from the services to the component from outside.
import { AlertComponent, AlertType } from '../components/alert.component';
import { ConfirmComponent } from '../components/confirm.component';
import { ModalConfirmData } from "../classes/modal-confirm-data";
// import { LoadComponent } from '../components/load.component';
import { makeOpenPromise } from '../helpers/utils.helpers';

type OnCloseFn = (res : boolean) => void
type MODAL_OPTS<C> = { config?       : MatDialogConfig<C>,
                       autoCloseMS?  : number,
                       disableClose? : boolean
                     }
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(public dialog: MatDialog) {}

  /**
  * Generic awaitable openModal method
  */
 async openModal<C extends { data: D, RESULT_TYPE? : R }, 
                 D, 
                 R = unknown extends C['RESULT_TYPE'] ? boolean : C['RESULT_TYPE']>
                (modalClass : ComponentType<C> & { OPTS_DEFAULT?: MODAL_OPTS<C> }, 
                 dialogData : C['data'],
                 opts?      : MODAL_OPTS<C> & { onClose? : (res : R) => unknown}
                ): Promise<R> {

    opts = {
           ... modalClass.OPTS_DEFAULT,
           ... opts, 
           ...(modalClass.OPTS_DEFAULT?.config || opts?.config ? 
               { config : {... modalClass.OPTS_DEFAULT?.config,
                           ... opts?.config }} : null)
           }
    
    const d = this.dialog.open(modalClass, {... opts?.config,
                                            data : dialogData 
                                            });

    if (opts?.autoCloseMS !== undefined && opts?.autoCloseMS !== null)
      setTimeout(() => { d.close() }, opts.autoCloseMS)

    const p = makeOpenPromise<R>()

    if(opts?.disableClose !== undefined)
      d.disableClose = opts.disableClose;

    d.afterClosed().subscribe((res: R) => {
      if(opts?.onClose) 
         opts.onClose(res)
      p.resolve(res)
    })

    return p
  }

  // openLoadingModal(title: string, message: string, dismiss: number = null) : Promise<boolean> {
  //   return this.openModal(LoadComponent,                           
  //     {
  //       title, 
  //       content: message
  //     }, 
  //     {
  //       config: { width: '680px' }, 
  //       autoCloseMS : dismiss
  //     }
  //   )
  // }


  // TODO: Change signature so the parameters are passed in an opts obj
  async openAlertModal(title: string, message: string, alertType: AlertType, dismiss: number = null, closeButton = 'Ok', 
                       callBackFunction? : OnCloseFn, colorCloseBtn?: string, hideCancel = false,
                       hideContactSupport = false): Promise<boolean> {
    return this.openModal(AlertComponent,
      {
        title,
        alertType,
        content: message,
        closeButtonLabel: closeButton,
        colorCloseBtn: colorCloseBtn ? colorCloseBtn : 'primary',
        hideCancel: hideCancel,
        hideContactSupport: hideContactSupport
      },
      { 
        onClose : callBackFunction,
        autoCloseMS : dismiss
      }
    )
  }

  // TODO: Replace calls to this with openModal and remove it
  openGenericModal<T>(modalComponentRef: ComponentType<T>, data, callBackFunction? : (r?:any) => any, modalWidth?: number): MatDialogRef<T, any> {
    const dialogRef = this.dialog.open(modalComponentRef,
      {
        width: modalWidth ? modalWidth + 'px' : '680px',
        data: new ModalConfirmData({
          data: data,
          title: null,
          content: null,
          confirmButtonLabel: 'Save',
          closeButtonLabel: 'Cancel'
        })
      });
      dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => callBackFunction && callBackFunction(result));

    return dialogRef;
  }

  async openInfoModal(title: string, message: string, cancelButton = 'Ok', hideCancel = false, hideContactSupport = true) : Promise<boolean> {
    return this.openAlertModal(title, message, AlertType.INFO, null, cancelButton, null, null, hideCancel, hideContactSupport)
  }

  async openWarningModal(title: string, message: string) : Promise<boolean> {
    return this.openAlertModal(title, message, AlertType.WARNING)
  }

  async openErrorModal(title: string, message: string) : Promise<void> {
    await this.openAlertModal(title, message, AlertType.ERROR, null, 'Ok', null, null, true); 
  }


  openConfirmModal(title: string, message: string, callBackFunction?: OnCloseFn, 
                   alertClass?: AlertType, button?: string, modalPayment?: boolean, description?: string, typePaid?: boolean) : Promise<boolean> {
    return this.openModal(ConfirmComponent, 
                          new ModalConfirmData({title,
                                                content: message,
                                                confirmButtonLabel: button || 'Confirm',
                                                closeButtonLabel: 'Cancel',
                                                alertType: alertClass || null,
                                                modalPayment: modalPayment || false,
                                                description: description || null,
                                                typePaid: typePaid || false}),
                          { config: { width: '680px' },
                            onClose: callBackFunction,
                            disableClose: true 
                          })
  }





  //-- TODO: move this to a more specific module?
  private openInfoProfiles(title : string) {
    return this.openInfoModal(title, 
      `The data for the selected date range is unavailable on Google. Performance data is usually delayed by up to 7 days. Visit this report at a later date for more up-to-date insights.`);
  }

  openDatepickerdates() {
    return this.openInfoProfiles('Date range')
  }

  openDynamicReportReminderModal() {  
    return this.openInfoProfiles('Dynamic report')
  }
  //---

  async openWarningModalNoPermissions() : Promise<void> {
    await this.openWarningModal('Contact your administrator',
                                'Your user doesn’t have permissions to do this. '+
                                'Contact your account administrator to change your role.');
  }

  /**
   * Modal used for unexpected application errors
   * TODO: Add link to support
   * TODO: Replace some usages of ConfirmComponent with this
   */
  async openErrorLoadingModal(errorCode : number) : Promise<void> {
    await this.openErrorModal('Loading error', ('There was an error while loading. ' +
                                                `Please try again or contact support (error code ${errorCode})`));
  }

}
