import { __assign, __awaiter, __extends, __generator } from "tslib";
// dep
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
var FlexSearch = require('flexsearch');
// app
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserService } from '../../services/user.service';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { DeleteUserDialogComponent } from './delete-user-dialog/delete-user-dialog.component';
import { UserSettingDialogComponent } from './user-setting-dialog/user-setting-dialog.component';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ModalService } from 'src/app/services/modal.service';
var UsersManagementComponent = /** @class */ (function (_super) {
    __extends(UsersManagementComponent, _super);
    function UsersManagementComponent(accountService, usersService, _sessionS, cdr, snack, _modalS) {
        var _this = _super.call(this) || this;
        _this.accountService = accountService;
        _this.usersService = usersService;
        _this._sessionS = _sessionS;
        _this.cdr = cdr;
        _this.snack = snack;
        _this._modalS = _modalS;
        _this.displayedColumns = ['name', 'username', 'date', 'role', 'actions'];
        _this.dataSource = new MatTableDataSource(_this.accounts);
        // errorMessage: boolean; // TODO: Unused
        _this.timeout = null;
        _this.flexSearch = new FlexSearch({
            encode: "advanced",
            tokenize: "reverse",
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'displayName',
                    'email',
                ]
            }
        });
        _this.paginate = { page: 1, size: 10 };
        // @ViewChild(MatSort, {static: true}) sort: MatSort;
        _this.session$ = _this._sessionS.session$;
        return _this;
    }
    UsersManagementComponent.prototype.ngOnInit = function () {
        this.cdr.detectChanges();
        this._setData();
        this._loadUsers();
    };
    UsersManagementComponent.prototype.ngOnDestroy = function () {
        this.usersService.setUsers([]);
        _super.prototype.ngOnDestroy.call(this);
    };
    UsersManagementComponent.prototype._setData = function () {
        this.accounts = this.usersService.users
            .pipe(map(function (account) { return account.filter(function (user) { return !user.masterGid; }); }))
            .pipe(map(function (res) { return res.sort(function (a, b) {
            var aRole = a.role;
            var bRole = b.role;
            var aLow = a.displayName;
            var bLow = b.displayName;
            if (aRole === bRole) {
                return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
            }
            else {
                return (aRole < bRole) ? -1 : 1;
            }
        }); }));
        this.paginator.pageSize = 100;
        this.usersService.paginator = this.paginator;
        this.manualPage = 1;
        // this.errorMessage = false; 
    };
    // apply filter from search
    UsersManagementComponent.prototype.applyFilter = function ($event, key) {
        var _this = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            if (!$event || $event === '') {
                _this.initPaginator();
                _this._setData();
            }
            else {
                var searchValue_1 = $event.toLowerCase();
                _this.accounts = _this.usersService.users.pipe(map(function (r) { return r.filter(function (user) { return user.displayName.toLowerCase().indexOf(searchValue_1) > -1 ||
                    user.email.toLowerCase().indexOf(searchValue_1) > -1; }); }));
            }
        }, 350);
    };
    UsersManagementComponent.prototype.initPaginator = function () {
        this.paginate = { page: 1, size: 10 };
        this.previousPageable = null;
    };
    UsersManagementComponent.prototype.onAddNewUser = function () {
        this._displayModalUser(null);
    };
    UsersManagementComponent.prototype.onEditUser = function (user) {
        this._displayModalUser(user);
    };
    UsersManagementComponent.prototype.onEditSetting = function (user) {
        this._displayModalSettingUser(user);
    };
    UsersManagementComponent.prototype.onDeleteUser = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openModal(DeleteUserDialogComponent, { user: user })];
                    case 1:
                        if (_a.sent()) {
                            this.snack.openSuccess('The user was successfully deleted.');
                            this._loadUsers();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UsersManagementComponent.prototype._displayModalSettingUser = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openModal(UserSettingDialogComponent, { user: user })];
                    case 1:
                        if (_a.sent()) {
                            this.snack.openSuccess('The user was updated successfully.');
                            this._loadUsers();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UsersManagementComponent.prototype._displayModalUser = function (user) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, gid, error_1, domain, userData, verificationEmailData, error_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._modalS.openModal(UserDialogComponent, { user: user })];
                    case 1:
                        response = _c.sent();
                        if (!response) {
                            return [2 /*return*/];
                        }
                        gid = this._sessionS.getSession().gid;
                        if (!((_a = user) === null || _a === void 0 ? void 0 : _a.uid)) return [3 /*break*/, 6];
                        user = __assign(__assign({}, user), response);
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.usersService.updateUser(gid, user.uid, user)];
                    case 3:
                        _c.sent();
                        this.snack.openSuccess('The user was updated successfully.');
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _c.sent();
                        this.snack.openError('An error occurred trying updating the user.');
                        return [3 /*break*/, 5];
                    case 5:
                        this._loadUsers();
                        return [2 /*return*/];
                    case 6:
                        domain = this._sessionS.getDomain();
                        userData = {
                            email: response.email,
                            displayName: response.displayName,
                            timezoneOffsetMinutes: new Date().getTimezoneOffset(),
                            registrationDomain: domain.domainName,
                            role: response.role,
                            isActive: response.isActive
                        };
                        verificationEmailData = {
                            domain: domain.domainNameWithPort,
                            companyName: domain.branding.company_name,
                            mainWebsite: domain.branding.main_website
                        };
                        _c.label = 7;
                    case 7:
                        _c.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, this.usersService.addUserToGroup(gid, { userData: userData, verificationEmailData: verificationEmailData }).toPromise()];
                    case 8:
                        _c.sent();
                        this.snack.openSuccess('The user was created successfully.');
                        return [3 /*break*/, 10];
                    case 9:
                        error_2 = _c.sent();
                        this.snack.openError(((_b = error_2.error) === null || _b === void 0 ? void 0 : _b.message) ? "Error: " + error_2.error.message :
                            'Unexpected Error: Please contact support');
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    UsersManagementComponent.prototype.updateManualPage = function ($event) { };
    UsersManagementComponent.prototype.getPages = function () {
        return [1];
    };
    UsersManagementComponent.prototype.lastPage = function () {
        return this.paginator.pageSize * (this.paginator.pageIndex + 1) >= this.paginator.length;
    };
    UsersManagementComponent.prototype.onPage = function (index) {
        this.updateManualPage(this.paginator.pageIndex);
    };
    UsersManagementComponent.prototype.onPrev = function () {
        this.updateManualPage(this.paginator.pageIndex);
    };
    UsersManagementComponent.prototype.onNext = function () {
        this.paginator.pageIndex += 2;
        this.updateManualPage(this.paginator.pageIndex);
    };
    UsersManagementComponent.prototype._loadUsers = function () {
        this.usersService.setUsers([]);
        this.usersService.loadAll(this._sessionS.getSession().gid);
    };
    return UsersManagementComponent;
}(BaseComponent));
export { UsersManagementComponent };
