import { __awaiter, __generator } from "tslib";
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { LocationService } from '../../services/location.service';
import { ObservationService } from '../../services/observation.service';
import { SnackbarService } from '../../services/snackbar.service';
import { InsightsService } from '../../services/insights.service';
import { SessionService } from 'src/app/services/session.service';
var DashboardWorkshopComponent = /** @class */ (function () {
    function DashboardWorkshopComponent(route, locationService, observationService, snack, insightsService, _sessionS) {
        var _this = this;
        this.route = route;
        this.locationService = locationService;
        this.observationService = observationService;
        this.snack = snack;
        this.insightsService = insightsService;
        this._sessionS = _sessionS;
        this.refreshing = false;
        this.isProgressObservation = true;
        this.isProgressTopMetrics = true;
        this.locationId = this.route.parent.snapshot.params.locationId;
        this.accountId = this.route.parent.snapshot.params.accountId;
        var gid = this._sessionS.getSession().gid;
        this.locationService.getObservations(gid, this.accountId, this.locationId).then(function (observations) {
            _this.observations = observations;
            _this.isProgressObservation = false;
        }, function (error) {
            _this.isProgressObservation = false;
        });
        this.insightsService.getTopMetrics(this.locationId).subscribe(function (topMetrics) {
            _this.topMetrics = topMetrics;
            _this.isProgressTopMetrics = false;
        }, function (error) {
            _this.isProgressTopMetrics = false;
        });
        this.config = {
            loop: true,
            initialSlide: 0,
            slidesPerView: 4,
            navigation: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            spaceBetween: 30,
            grabCursor: true
        };
        this.index = 0;
    }
    DashboardWorkshopComponent.prototype.handleRefresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var now, prev_year, endDate, startDate, err_1, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        now = moment();
                        prev_year = now.clone().subtract(1, 'year');
                        endDate = now['year']() + "-" + (now['month']() + 1) + "-" + now['date']();
                        startDate = prev_year['year']() + "-" + (prev_year['month']() + 1) + "-" + prev_year['date']();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.refreshing = true;
                        return [4 /*yield*/, this.observationService.save(this.accountId, this.locationId).toPromise()];
                    case 2:
                        _a.sent();
                        this.snack.openSuccess('No new observations or top metrics');
                        this.refreshing = false;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        this.refreshing = false;
                        if (err_1.status !== 0)
                            this.snack.openError(err_1.error.message);
                        return [3 /*break*/, 4];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.insightsService.saveLocationStats(this._sessionS.getSession().gid, this.accountId, this.locationId, startDate, endDate)];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        err_2 = _a.sent();
                        console.error(err_2);
                        this.snack.openError(err_2.message);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    return DashboardWorkshopComponent;
}());
export { DashboardWorkshopComponent };
