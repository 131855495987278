	<div class="content__body">
		<!--if no accounts are linked-->
		<div class="row m--0" *ngIf="!(session$ | async).isAdmin">
			<div class="col-12">
				<div class="notice notice--red notice--lg">
					<p>You are not allowed, only the group administrator can make updates.</p>
				</div>
			</div>
		</div>

		<div class="row" *ngIf="(session$ | async).isAdmin">
			<div class="col-7">
				<!--Plan Details-->
				<ng-container *ngIf="(session$ | async).subscription.status">
					<div class="box box--rounded box--shadow bg--white">
						<div class="box__header">
							<h2 class="txt--black txt--capitalize"><strong>Plan Details</strong></h2>
						</div>
						<div class="box__content box--padding-xs">
							<div class="row gutters-xsm box--height">
								<div class="col-12 col-md-3 mb--20">
									<div
										class="box box--rounded box--padding-tiny d-flex align-items-center justify-content-start bg--gray">
										<span class="billing-icon">{{ (session$ | async).group.totalLocationsCount || 0 }}</span>
										<p class="m--0 txt--lg-md">Total Locations</p>
									</div>
								</div>
								<div class="col-12 col-md-3 mb--20">
									<div
										class="box box--rounded box--padding-tiny d-flex align-items-center justify-content-start bg--lightyellow">
										<span class="billing-icon">{{ (session$ | async).group.freeLocationsCount || 0 }}</span>
										<p class="m--0 txt--lg-md">Essential Locations</p>
									</div>
								</div>
								<div class="col-12 col-md-3 mb--20">
									<div
										class="box box--rounded box--padding-tiny d-flex align-items-center justify-content-start bg--lightgreen">
										<span class="billing-icon">{{ (session$ | async).group.basicLocationsCount || 0 }}</span>
										<p class="m--0 txt--lg-md">Basic Locations</p>
									</div>
								</div>
								<div class="col-12 col-md-3 mb--20">
									<div
										class="box box--rounded box--padding-tiny d-flex align-items-center justify-content-start bg--lightblue">
										<span class="billing-icon">{{ (session$ | async).group.ultimateLocationsCount || 0 }}</span>
										<p class="m--0 txt--lg-md">Ultimate Locations</p>
									</div>
								</div>
							</div>

							<div class="border-bottom mb--20"></div>

							<div class="row gutters-sm box--height">
								<div class="col-12 col-md-4 mb--20">
									<div
										class="box box--rounded box--border-sm box--border-light box--padding-tiny box--padding-t">
										<h2 class="heading--bigger mb--20 txt--center txt--dark height-xs">
											<strong><span class="txt--lg d-block">Essential</span>Free Forever</strong>
										</h2>

										<ul class="list">
											<li class="list__item list__item--sm list__item--check">Last 12 Months of
												Insights & Reviews
											</li>
											<li class="list__item list__item--sm list__item--check">Listing Grader</li>
											<li class="list__item list__item--sm list__item--fail">Post Management +
												Scheduling
											</li>
											<li class="list__item list__item--sm list__item--fail">Listing Defender</li>
											<li class="list__item list__item--sm list__item--fail">Embedded Review
												Widget
											</li>
											<li class="list__item list__item--sm list__item--fail">Public Link Sharing
											</li>
											<li class="list__item list__item--sm list__item--fail">Export Data to
												CSV/PDF
											</li>
											<li class="list__item list__item--sm list__item--fail">Priority Support</li>
											<li class="list__item list__item--sm list__item--fail">Roll Up (Aggregated)
												Reports
											</li>
											<li class="list__item list__item--sm list__item--fail">Comparison Reports
											</li>
											<li class="list__item list__item--sm list__item--fail">Revenue Projection
												Calculator
											</li>
											<li class="list__item list__item--sm list__item--fail">Review Assistant</li>
											<li class="list__item list__item--sm list__item--fail">Bulk Post Management
												+ Scheduling
											</li>
										</ul>

									</div>
								</div>

								<div class="col-12 col-md-4 mb--20">
									<div
										class="box box--rounded box--border-sm box--border-light bg--lightgray box--circles box--circles-green">
										<div class="box--padding-tiny box--padding-t box--circle-green">
											<h2 class="heading--bigger mb--30 txt--center txt--dark height-xs">
												<strong><span class="txt--lg d-block">Basic</span> <span
														*ngIf="basicPlan && !basicPlan.isFree" class="txt--green"><sup
															class="icon-price-billing">$</sup>{{basicPlan?.amount}}
														/{{basicPlan?.interval}}</span></strong>
												<p *ngIf="basicPlan && !basicPlan.isFree">Per Listing</p>
											</h2>

											<ul class="list">
												<li class="list__item list__item--sm list__item--check">18+ Months of
													Insights & Reviews
												</li>
												<li class="list__item list__item--sm list__item--check">
													Unlimited Users
												</li>
												<li class="list__item list__item--sm list__item--check">Listing Grader
												</li>
												<li class="list__item list__item--sm list__item--check">Post Management
													+ Scheduling
												</li>
												<li class="list__item list__item--sm list__item--check">Listing
													Defender
												</li>
												<li class="list__item list__item--sm list__item--check">Embedded Review
													Widget
												</li>
												<li class="list__item list__item--sm list__item--check">Public Link
													Sharing
												</li>
												<li class="list__item list__item--sm list__item--check">Export Data to
													CSV/PDF
												</li>
												<li class="list__item list__item--sm list__item--check">Priority
													Support
												</li>
												<li class="list__item list__item--sm list__item--fail">Roll Up
													(Aggregated) Reports
												</li>
												<li class="list__item list__item--sm list__item--fail">Comparison
													Reports
												</li>
												<li class="list__item list__item--sm list__item--fail">Revenue
													Calculator
												</li>
												<li class="list__item list__item--sm list__item--fail">Review
													Assistant
												</li>
												<li class="list__item list__item--sm list__item--fail">Bulk Post
													Management + Scheduling
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="col-12 col-md-4 mb--20">
									<div
										class="box box--rounded box--border-sm box--border-light bg--lightgray box--circles box--circles-blue">
										<div class="box--padding-tiny box--padding-t box--circle-blue">
											<h2 class="heading--bigger mb--30 txt--center txt--dark height-xs">
												<strong><span class="txt--lg d-block">Ultimate</span><span
														*ngIf="ultimatePlan && !ultimatePlan.isFree"
														class="txt--blue"><sup
															class="icon-price-billing">$</sup>{{ultimatePlan?.amount}}
														/{{ultimatePlan?.interval}}</span></strong>
												<p *ngIf="ultimatePlan && !ultimatePlan.isFree">Per Listing</p>
											</h2>

											<ul class="list">
												<li class="list__item list__item--sm list__item--check">18+ Months of
													Insights & Reviews
												</li>
												<li class="list__item list__item--sm list__item--check">
													Unlimited Users
												</li>
												<li class="list__item list__item--sm list__item--check">Listing Grader
												</li>
												<li class="list__item list__item--sm list__item--check">Post Management
													+ Scheduling
												</li>
												<li class="list__item list__item--sm list__item--check">Listing
													Defender
												</li>
												<li class="list__item list__item--sm list__item--check">Embedded Review
													Widget
												</li>
												<li class="list__item list__item--sm list__item--check">Public Link
													Sharing
												</li>
												<li class="list__item list__item--sm list__item--check">Export Data to
													CSV/PDF
												</li>
												<li class="list__item list__item--sm list__item--check">Priority
													Support
												</li>
												<li class="list__item list__item--sm list__item--check">Roll Up
													(Aggregated) Reports
												</li>
												<li class="list__item list__item--sm list__item--check">Comparison
													Reports
												</li>
												<li class="list__item list__item--sm list__item--check">Revenue
													Calculator
												</li>
												<li class="list__item list__item--sm list__item--check">Review
													Assistant
												</li>
												<li class="list__item list__item--sm list__item--check">Bulk Post
													Management + Scheduling
												</li>
												<li class="list__item list__item--sm list__item--check">
													Bulk Listing Updates
												</li>
											</ul>
										</div>
									</div>
								</div>

							</div>

							<p class="txt--center">
								<a (click)="navigationS.goToContactSupport()" class="cursor--pointer txt--underline">Contact
									us</a> if you have more than 10 locations
								for bulk pricing. White Label and Reseller packages are also
								available.
							</p>

						</div>
					</div>
				</ng-container>

			</div>
			<!--Payment history-->
			<div class="col-5">
				<!--Payment method-->
				<div class="box box--rounded box--shadow bg--white mb--30"
					*ngIf="!(session$ | async).subscription.billingOverride">
					<div class="box__header">
						<h2 class="txt--black txt--capitalize"><strong>Payment Method</strong></h2>
					</div>
					<app-skeleton--payment-method *ngIf="loadingCards" class="box__content box--padding-xs"></app-skeleton--payment-method>
					<div class="box__content box--padding-xs" *ngIf="!loadingCards">
						<div *ngFor="let card_info of cards; let i = index">
							<div class="box__item pt--0 mb--20 border-bottom">
								<div class="row align-items-center">
									<div class="col-4">
										<div class="d-flex align-items-center">
											<div class="credit-card">
												<img src="/assets/images/cards/american-express.jpg"
													alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'American Express'">
												<img src="/assets/images/cards/diners-club.png"
													alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'Diners Club'">
												<img src="/assets/images/cards/discover.png"
													alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'Discover'">
												<img src="/assets/images/cards/jcb.png" alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'JCB'">
												<img src="/assets/images/cards/mastercard.png"
													alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'MasterCard'">
												<img src="/assets/images/cards/union-pay.png"
													alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'UnionPay'">
												<img src="/assets/images/cards/visa.png" alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'Visa'">
												<img src="/assets/images/cards/unknown.png"
													alt="{{card_info.brand}} Logo"
													*ngIf="card_info.brand === 'Unknown'">
											</div>
											<div>
												<p class="mb--5">Credit Card</p>
												<p class="m--0 txt--black"><strong>**** {{ card_info.last4 }}</strong>
												</p>
											</div>
										</div>
									</div>
									<div class="col-3">
										<p class="mb--5">Expiration Date</p>
										<p class="m--0 txt--black"><strong>{{ card_info.exp_month }}/{{
												card_info.exp_year }}</strong></p>
									</div>
									<div *ngIf="i === 0"
										class="col-3 review-response review-response--xs review-response--info">
										<p class="m--5 txt--center txt--blue txt--uppercase" on><strong>Default</strong>
										</p>
									</div>
									<div *ngIf="i !== 0"
										class="col-3 review-response review-response--xs review-response--error cursor--pointer">
										<p class="m--5 txt--center txt--orange txt--uppercase"
											(click)="handleDefaultSource({card: card_info})"><strong>Set as
												default</strong></p>
									</div>
									<div *ngIf="i !== 0" class="col-2">
										<button (click)="handleDeleteSource({card: card_info})" mat-flat-button
											color="warn" class="btn btn-icon">
											<i class="far fa-trash-alt"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<button mat-flat-button color="accent" (click)="onAddCard()" class="btn btn--md mr--20">
							{{ "Add Credit Card" }}
						</button>
					</div>

				</div>

				<!--Billing Override-->
				<div class="box box--rounded box--shadow bg--white mb--30"
					*ngIf="(session$ | async).subscription.billingOverride">
					<div class="box__header">
						<h2 class="txt--black txt--capitalize"><strong>Billing override</strong></h2>
					</div>

					<div class="box__content box--padding-xs">
						<p>This account has billing override enabled</p>
					</div>

				</div>

				<div class="box box--rounded box--shadow bg--white">
					<div class="box__header">
						<h2 class="txt--capitalize txt--black"><strong>Invoices History</strong></h2>
					</div>

					<div class="box__content box--scrollable">
						<div class="box--padding-xs"
							*ngIf="!loadingHistory && !paymentsHistory || paymentsHistory && paymentsHistory.length == 0 ">
							<p class="m--0">There is no data yet</p>
						</div>
						<app-skeleton-invoices *ngIf="loadingHistory"></app-skeleton-invoices>
						<ng-container *ngIf="!loadingHistory && paymentsHistory && paymentsHistory.length > 0">
							<table mat-table [dataSource]="paymentsHistory"
								class="table table--bordered cells-no-border">

								<!-- Number Column -->
								<ng-container matColumnDef="number">
									<th mat-header-cell *matHeaderCellDef><strong>Invoice #</strong></th>
									<td mat-cell *matCellDef="let element" class=""> {{element._id.$oid}}</td>
								</ng-container>

								<!-- Date Column -->
								<ng-container matColumnDef="date">
									<th mat-header-cell *matHeaderCellDef><strong>Date</strong></th>
									<td mat-cell *matCellDef="let element" class=""> {{element.date.$date | date:
										'MM/dd/yy'}}</td>
								</ng-container>

								<!-- Amount Column -->
								<ng-container matColumnDef="amount">
									<th mat-header-cell *matHeaderCellDef><strong>Amount</strong></th>
									<td mat-cell *matCellDef="let element"> {{element.total | currency}} </td>
								</ng-container>

								<!-- Status Column -->
								<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef class="mat-header-cell txt--center">
										<strong>Status </strong>
									</th>
									<td mat-cell *matCellDef="let element" class="txt--center">
										<span
											class="d-inline-block btn--action btn--action-xs btn--green txt--uppercase txt--center"
											*ngIf="element.status.toLowerCase() === 'paid'">{{element.status |
											uppercase}}</span>
										<span
											class="d-inline-block btn--action btn--action-xs btn--orange txt--uppercase txt--center"
											*ngIf="element.status.toLowerCase() === 'pending' || element.status.toLowerCase() === 'pre authorized'">{{element.status |
											uppercase}}</span>
										<span
											class="d-inline-block btn--action btn--action-xs btn--red txt--uppercase txt--center"
											*ngIf="element.status.toLowerCase() === 'unpaid' || element.status.toLowerCase() === 'canceled'">{{element.status |
											uppercase}}</span>
									</td>
								</ng-container>

								<!-- Actions Column -->
								<ng-container matColumnDef="actions">
									<th mat-header-cell *matHeaderCellDef class="txt--right column--sm">
										<strong>Actions</strong>
									</th>
									<td mat-cell *matCellDef="let element" class="txt--right column--sm">
										<a href="{{element?.invoice_pdf}}" target="_blank" rel="noopener">
											<svg-icon src="/assets/images/icons/pdf.svg" class="filter__item__icon"
												data-html2canvas-ignore="true"></svg-icon>
										</a>
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="listColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: listColumns;"></tr>
							</table>
						</ng-container>

					</div>
				</div>
			</div>
		</div>
	</div>

