/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../footer/footer.component.ngfactory";
import * as i5 from "../footer/footer.component";
import * as i6 from "../../services/session.service";
import * as i7 from "../../services/navigation.service";
import * as i8 from "./main.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../services/data-transfer.service";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
function View_MainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_MainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "main", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-footer", [], null, null, null, i4.View_FooterComponent_0, i4.RenderType_FooterComponent)), i1.ɵdid(7, 180224, null, 0, i5.FooterComponent, [i2.Router, i6.SessionService, i7.NavigationService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.pageNeedsSession || !!i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.session$))); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 180224, null, 0, i8.MainComponent, [i2.Router, i2.ActivatedRoute, i9.Title, i6.SessionService, i10.DataTransferService], null, null)], null, null); }
var MainComponentNgFactory = i1.ɵccf("app-main", i8.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
