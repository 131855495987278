import { __awaiter, __generator, __read } from "tslib";
// dep
import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
// app
import { LOCATION_SUBSCRIPTION_TYPE as PLAN } from '../../constants/firestore/account-location';
import { normalizeLocationRef } from "../../constants/firestore/location-object";
import { PaymentsService } from '../../services/payments.service';
import { LocationService } from '../../services/location.service';
import { SpinnerService } from "../../services/spinner.service";
import { NavigationService } from "src/app/services/navigation.service";
import { SessionService } from "src/app/services/session.service";
/**
 Shows a dialog with the possible plans for the chosen location(s) asking the user to select one.

 - For a single location only shows the all the plans excluding the one the location currently has.
 - For multiple locations always shows all the plans, no matter which ones the locations currently have.

 - Always returns a SINGLE destination plan for all locations
*/
var ChangePlanLocationComponent = /** @class */ (function () {
    function ChangePlanLocationComponent(dialogRef, data, _spinnerService, _locationService, _paymentsService, _sessionS, navigationS) {
        var _a;
        this.dialogRef = dialogRef;
        this.data = data;
        this._spinnerService = _spinnerService;
        this._locationService = _locationService;
        this._paymentsService = _paymentsService;
        this._sessionS = _sessionS;
        this.navigationS = navigationS;
        this.activated = false;
        this.PLAN = PLAN;
        this.titleCurrentPlan = '';
        this.subscriptionTrialDays = null;
        this.isMultipleLocations = false;
        this.currentPlan = null;
        this.domain$ = this._sessionS.domain$;
        this._spinnerService.loading$.next(true);
        this.hasPricingURL = !!((_a = this._sessionS.getDomain().branding) === null || _a === void 0 ? void 0 : _a.pricing_url);
        this.isMultipleLocations = (this.locations.length > 1);
        this._initFromSubscription();
        this._spinnerService.loading$.next(false);
    }
    Object.defineProperty(ChangePlanLocationComponent.prototype, "locations", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    ChangePlanLocationComponent.prototype.ngOnInit = function () {
        this._getPlanInfo();
    };
    ChangePlanLocationComponent.prototype.handleSelectOption = function (option) {
        var action = this.optionAction(option);
        this.dialogRef.close({ nextPlan: option,
            action: action });
        this.dialogRef.disableClose = true;
    };
    ChangePlanLocationComponent.prototype._initFromSubscription = function () {
        return __awaiter(this, void 0, void 0, function () {
            var session, singleLocationPlan, loc, locationId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        session = this._sessionS.getSession();
                        singleLocationPlan = null;
                        if (!(this.locations.length == 1)) return [3 /*break*/, 2];
                        loc = this.locations[0];
                        if (!(loc && loc.locationId && loc.accountId)) return [3 /*break*/, 2];
                        locationId = normalizeLocationRef(loc).locationId;
                        return [4 /*yield*/, this._locationService.fetchLocation(session.gid, loc.accountId, locationId)];
                    case 1:
                        singleLocationPlan = (_a.sent()).subscriptionType;
                        _a.label = 2;
                    case 2:
                        this.subscriptionTrialDays = (session.isTrial ? session.daysInTrial : null);
                        if (this.isMultipleLocations) {
                            //-- Multiple Locations --
                            this.currentPlan = null;
                            this.titleCurrentPlan = "MULTIPLE (" + this.locations.length + ")";
                        }
                        else if (singleLocationPlan) {
                            //-- Single Location --
                            this.currentPlan = ((session.isTrial && singleLocationPlan === PLAN.FREE) ? PLAN.ULTIMATE_TRIAL :
                                (singleLocationPlan === PLAN.FREE ? PLAN.ESSENTIAL :
                                    singleLocationPlan));
                            this.titleCurrentPlan = this.currentPlan;
                        }
                        else {
                            //-- No Location --
                            // Current assumed as Ultimate
                            // TODO: This case is ever reached?
                            this.currentPlan = (session.isTrial ? PLAN.ULTIMATE_TRIAL : PLAN.ULTIMATE);
                            this.titleCurrentPlan = this.currentPlan;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ChangePlanLocationComponent.prototype._getPlanInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, p_ultimate, p_basic, parsePlan;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this._paymentsService.getPlan(PLAN.ULTIMATE).toPromise(),
                            this._paymentsService.getPlan(PLAN.BASIC).toPromise()
                        ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), p_ultimate = _a[0], p_basic = _a[1];
                        parsePlan = function (plan) {
                            var price = plan['data']['price'];
                            var interval = plan['data']['interval'];
                            return {
                                interval: interval,
                                amount: price,
                                isFree: (price == 0)
                            };
                        };
                        // 'Essential' is not loaded as is always assumed essential.price == 0
                        this.ultimatePlan = parsePlan(p_ultimate);
                        this.basicPlan = parsePlan(p_basic);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChangePlanLocationComponent.prototype.optionAction = function (option) {
        var _a, _b;
        var currentPlan = (this.isMultipleLocations ? 'MULTIPLE' : this.currentPlan);
        if (!currentPlan)
            // undefined if subscription is not loaded yet
            return null;
        // 'change_plan' means both change the plan of multiple locations and
        // also move the Subscription out of the Trial phase. 
        // TODO: If multiple locations were selected and, for example, all are
        // 'ESSENTIAL', then maybe show the "ULTIMATE" option as 'upgrade' and not
        // 'change_plan'
        var C = 'change_plan';
        var U = 'upgrade';
        var D = 'downgrade';
        // don't show option:
        var _ = null;
        var column = (_a = {}, _a[PLAN.ESSENTIAL] = 0, _a[PLAN.BASIC] = 1, _a[PLAN.ULTIMATE] = 2, _a)[option];
        // E  B  U
        return (_b = { 'MULTIPLE': [C, C, C] }, _b[PLAN.ULTIMATE_TRIAL] = [_, C, C], _b[PLAN.ESSENTIAL] = [_, U, U], _b[PLAN.BASIC] = [D, _, U], _b[PLAN.ULTIMATE] = [D, D, _], _b)[currentPlan][column];
    };
    ChangePlanLocationComponent.prototype.optionButtonLabel = function (option) {
        return { 'downgrade': 'Downgrade',
            'upgrade': 'Upgrade',
            'change_plan': 'Change' }[this.optionAction(option)];
    };
    ChangePlanLocationComponent.prototype.optionsClass = function () {
        return this.isMultipleLocations ? 'col-4' : 'col-6';
    };
    ChangePlanLocationComponent.OPTS_DEFAULT = { config: { width: '860px',
            panelClass: 'dialog--ultimate-wrapper',
            height: '100%' },
        disableClose: true };
    return ChangePlanLocationComponent;
}());
export { ChangePlanLocationComponent };
