/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external-grade.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "../dashboard-grade/dashboard-grade.component.ngfactory";
import * as i11 from "../dashboard-grade/dashboard-grade.component";
import * as i12 from "@angular/router";
import * as i13 from "../../services/session.service";
import * as i14 from "../../services/dates.service";
import * as i15 from "../../services/modal.service";
import * as i16 from "../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i17 from "@angular/material/toolbar";
import * as i18 from "./external-grade.component";
import * as i19 from "@angular/material/dialog";
import * as i20 from "../../services/snackbar.service";
import * as i21 from "../../services/google.service";
import * as i22 from "../../services/google-account.service";
import * as i23 from "../../services/account.service";
import * as i24 from "../../services/location.service";
import * as i25 from "../../services/grade-lead.service";
import * as i26 from "../../services/auth.service";
import * as i27 from "@angular/platform-browser";
import * as i28 from "../../services/logs.service";
import * as i29 from "../../services/external-grade.service";
import * as i30 from "../../services/navigation.service";
var styles_ExternalGradeComponent = [i0.styles];
var RenderType_ExternalGradeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExternalGradeComponent, data: {} });
export { RenderType_ExternalGradeComponent as RenderType_ExternalGradeComponent };
function View_ExternalGradeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ExternalGradeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "screen-height d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "container--md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "box box--padding bg--white flex-column justify-content-center align-items-center d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Google My Business Logo"], ["class", "mb--20 img--round"], ["src", "/assets/images/my_business_logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "heading--bigger txt--black fw--black txt--center txt--gray mb--15"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Grade your Google Business Profile "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "txt--lg txt--center mb--30"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" To get your report, just log in with Google My Business account on the next page and click \"allow\". "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "button", [["class", "btn btn--icon btn--lg "], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAuth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "span", [["class", "txt--lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Start Grading"])), (_l()(), i1.ɵeld(13, 0, null, 0, 0, "i", [["class", "far fa-arrow-right ml--15"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 9).disabled || null); var currVal_1 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_0, currVal_1); }); }
function View_ExternalGradeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "container--full container--padding-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-dashboard-grade", [], null, null, null, i10.View_DashboardGradeComponent_0, i10.RenderType_DashboardGradeComponent)), i1.ɵdid(2, 114688, null, 0, i11.DashboardGradeComponent, [i12.ActivatedRoute, i13.SessionService, i14.DatesService, i15.ModalService], { user: [0, "user"], locations: [1, "locations"], externalAccountId: [2, "externalAccountId"], externalLocationId: [3, "externalLocationId"], userExternal: [4, "userExternal"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "grade-popup grade-circles"]], [[2, "close", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "grade-popup__content grade-border txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "grade-popup-close"], ["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fal fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [["class", "heading--bigger txt--black fw--black mb--10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Improve your grade!"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "txt--lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get Started Today"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn--md"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationS.goToRegister() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Sign Up Now"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.session$)).user; var currVal_1 = _ck(_v, 4, 0, _co.location); var currVal_2 = _co.accountId; var currVal_3 = _co.locationId; var currVal_4 = _co.userExternal; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_8 = "accent"; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = !_co.showGradePopup; _ck(_v, 5, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
function View_ExternalGradeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "grade-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "p", [["class", "m--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Improve your grade! Get started on ", ""])), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "btn btn--white btn--xs ml--10"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationS.goToRegister() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign Up Now"])), (_l()(), i1.ɵeld(10, 0, null, null, 23, "mat-toolbar", [["class", "header header-grade d-flex justify-content-between bg--white mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i16.View_MatToolbar_0, i16.RenderType_MatToolbar)), i1.ɵdid(11, 4243456, null, 1, i17.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(13, 0, null, 0, 5, "div", [["class", "logo logo-grade"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "a", [["class", "d-block p--0"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i12.RouterLinkWithHref, [i12.Router, i12.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵeld(17, 0, null, null, 1, "img", [["alt", "Homepage."], ["class", "logo"], ["height", "115"], ["width", "300"]], [[8, "src", 4]], null, null, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, 0, 14, "nav", [["class", "nav-grade"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "span", [["class", "d-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "a", [["href", "https://maplabs.codeart.rocks/articles/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["articles"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "a", [["href", "https://maplabs.codeart.rocks/pricing/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pricing"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "a", [["href", "https://maplabs.codeart.rocks/contact/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["contact"])), (_l()(), i1.ɵeld(27, 0, null, null, 3, "button", [["class", "btn btn--white btn--grade ml--15"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 16384, null, 0, i12.RouterLink, [i12.Router, i12.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(29, 1), (_l()(), i1.ɵted(-1, null, ["Log In"])), (_l()(), i1.ɵeld(31, 0, null, null, 2, "button", [["class", "btn btn--grade ml--20"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationS.goToRegister() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(32, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Sign Up"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ExternalGradeComponent_4)), i1.ɵdid(35, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _ck(_v, 16, 0, "/login"); _ck(_v, 15, 0, currVal_7); var currVal_9 = _ck(_v, 29, 0, "/login"); _ck(_v, 28, 0, currVal_9); var currVal_12 = "accent"; _ck(_v, 32, 0, currVal_12); var currVal_13 = !!i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform(_co.session$)); _ck(_v, 35, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.domain$)).branding.company_name; _ck(_v, 4, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 7).disabled || null); var currVal_2 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 11)._toolbarRows.length > 0); var currVal_4 = (i1.ɵnov(_v, 11)._toolbarRows.length === 0); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 15).target; var currVal_6 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵinlineInterpolate(1, "", (i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.domain$)).branding.loginLogo || "/assets/images/logo/blankmap.png"), ""); _ck(_v, 17, 0, currVal_8); var currVal_10 = (i1.ɵnov(_v, 32).disabled || null); var currVal_11 = (i1.ɵnov(_v, 32)._animationMode === "NoopAnimations"); _ck(_v, 31, 0, currVal_10, currVal_11); }); }
export function View_ExternalGradeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalGradeComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalGradeComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalGradeComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !(_co.location && _co.grade); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.location && _co.grade); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ExternalGradeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-external-grade", [], null, null, null, View_ExternalGradeComponent_0, RenderType_ExternalGradeComponent)), i1.ɵdid(1, 180224, null, 0, i18.ExternalGradeComponent, [i19.MatDialog, i20.SnackbarService, i21.GoogleService, i22.GoogleAccountService, i23.AccountService, i24.LocationService, i25.GradeLeadService, i26.AuthService, i27.Title, i28.LogsService, i29.ExternalGradeService, i13.SessionService, i30.NavigationService], null, null)], null, null); }
var ExternalGradeComponentNgFactory = i1.ɵccf("app-external-grade", i18.ExternalGradeComponent, View_ExternalGradeComponent_Host_0, {}, {}, []);
export { ExternalGradeComponentNgFactory as ExternalGradeComponentNgFactory };
