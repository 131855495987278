<ng-container>
  <div class="box--padding-xxs border-bottom">
    <div class="row align-items-center">
      <div class="col-4">
        <div class="d-flex align-items-center">
          <ngx-skeleton-loader count="1" style="margin-right: 15px;" [theme]="{ width: '60px', height: '38px' }"></ngx-skeleton-loader>
          <div>
            <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '70px', height: '15px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '70px', height: '15px' }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="col-3">
        <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '80px', height: '15px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '80px', height: '15px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-3">
        <ngx-skeleton-loader count="1" style="margin-right: 15px;" [theme]="{ width: '270px', height: '29px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-container>