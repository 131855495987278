// localStorage keys

// DEPRECATED, TODO: Remove
export const STORAGE_IS_IMPERSONATING = 'impersonating' 
export const STORAGE_SESSION = 'session' 
export const STORAGE_SESSION_EXTERNAL = 'session_external'
export const STORAGE_EXTERNAL_GRADE_ID = 'external_grade_id' 
//


// TODO: Never read? Maybe for communication with an
// included third-party library?
export const STORAGE_CUSTOM_SCRIPT = 'custom-script'

// TODO: Never saved. It seems this was a unfinished way to save
// the external grade account/location selection. 
// export const STORAGE_GRADE_LEAD = 'gradeLead'  

// TODO: Must replace all previous with this:
export const STORAGE_MAPLABS_SESSION = 'maplabs_session'

export const STORAGE_ALL_KEYS = [STORAGE_IS_IMPERSONATING, 
                                 STORAGE_SESSION, 
                                 STORAGE_SESSION_EXTERNAL, 
                                 STORAGE_EXTERNAL_GRADE_ID, 
                                 STORAGE_CUSTOM_SCRIPT, 
                                 // STORAGE_GRADE_LEAD,
                                 STORAGE_MAPLABS_SESSION] as const

