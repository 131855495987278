// dep
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

// app
import { InsightsService } from '../../services/insights.service';
import { DataPicker } from '../../constants/data-picker';
import { ChartService } from '../../services/chart.service';
import { DatesService } from '../../services/dates.service';
import { ReportService } from '../../services/report.service';
import { SelectedDatePicker } from '../date-picker/date-picker.component';
import { REPORT_TYPE } from '../../constants/firestore/report';
import { SnackbarService } from '../../services/snackbar.service';
import { EventMessageService } from "../../services/event-message.service";
import { ModalService } from "../../services/modal.service";
import { SubscriptionService } from "../../services/subscription.service";
import { LocationService } from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";

import {CHART_BLUE_COLOR, CHART_CORAL_COLOR} from '../../constants/chart-color';
import { EventNames } from '../../constants/event-names';
import { LocationRef } from 'src/app/constants/firestore/location-object';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-dashboard-insights',
  templateUrl: './dashboard-insights.component.html',
  styleUrls: ['./dashboard-insights.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardInsightsComponent extends BaseComponent implements OnInit {

  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() reportName: string;
  @Input() shared = false;
  @Input() lockDates = false;
  @Input() checks: boolean;
  @Output() changeDataPicker = new EventEmitter<DataPicker>();

  locationId: string;
  stats: any[] = [];
  labels: string[];
  dataPicker: DataPicker;
  isProgress = true;
  multiChartDataSet: any[] = [];
  // isNoData: boolean; // TODO: Unused, remove?
  isReport: boolean;
  selectDate: SelectedDatePicker;
  typeReport: REPORT_TYPE = null;
  colorsViewChart = [
    CHART_BLUE_COLOR,
    CHART_CORAL_COLOR,
  ]

  popularStats: any;
  private first = true;
  public readonly accountId: string;
  locations: LocationRef[];
  private _locationUpgraded: SavedLocation | undefined
  isProgressCSV = false
  public viewModeOptions = [];
  public viewModeChecked = 'legacy';
  public localRoute : ActivatedRoute

  constructor(
    private insightS: InsightsService,
    private route: ActivatedRoute,
    private chartS: ChartService,
    private dateS: DatesService,
    private _sessionS: SessionService,
    private reportS: ReportService,
    private snackS: SnackbarService,
    private em: EventMessageService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private locationService: LocationService,
    private loadingService: LoadingService,
  ) {
    super();
    this.accountId  = this.route.parent.snapshot.params.accountId
    this.locationId = this.route.parent.snapshot.params.locationId
    this.locations = [{ accountId: this.accountId, locationId: this.locationId }];
    this.localRoute = route;
  }

  ngOnInit() : void {

    if (this.reportDataPicker) {
      this.typeReport = 'rollup';
      this.isReport = true;

      // FIXME: This was done this way but must be locations[0].locationId? Probably a bug
      // @ts-ignore
      this.locationId = this.reportDataPicker.locations;

      this.reportDataPicker.aggregation = this.reportS.correctDateAggregate(this.reportDataPicker);
      this.dataPicker = this.reportDataPicker;
      this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
      this.locations = this.dataPicker.locations;
      return;
    } else {
      this.typeReport = 'rollup';
      this.selectDate = {
        start: moment('12-31-2022', 'MM-DD-YYYY').subtract(3, 'month'), // month - 3 for future reference
        end:   moment('12-31-2022', 'MM-DD-YYYY')
      };
    }

    this._subscribeSafe(this.em.getEmitter(),
     res => {
      if (res.name !== EventNames.EVENT_DATEPICKER_SUBSCRIPTION)
        return;

      const {group} = this._sessionS.getSession()
      const dateDismiss = group.dismissModalDatePicker || new Date()

      if (new Date() >= dateDismiss) {
        this.modalService.openModal(FeaturedDatePickerComponent,
          { 
            accountId: this.accountId,
            locationId: this.locationId
          },
          { onClose : res => {
            if (!res)
              return;
            console.log('DashboardInsightsComponent upgrade suscription');
            this.subscriptionService.flowChangeLocationsPlan([{locationId : this._locationUpgraded.locationId,
                                                               accountId  : this._locationUpgraded.accountId}])
        }});
      }

    });

    // TODO: Here locationId is always a single element array? Note `${[1]}` == "1"
    this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId).then(
      loc => this._locationUpgraded = loc
    )
  }

  async getInsightData(dataPicker: DataPicker, refresh = false): Promise<void> {
    this.resetData();

    try {
      const res = await this.insightS.getInsights(this.locations, dataPicker)
      this.popularStats = res.popularTime;
      this.isProgress = false
      if (res?.labels?.length > 0) {
        this.labels = res.labels;
        this.stats = this._orderingStats(res.stats);
        this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
      } else {
        // this.isNoData = true;
      }
      this.loadingService.reportAdvanced(1, 'Insights')
      if(refresh)
        this.snackS.openSuccess('Finished refreshing!')
    } catch {
      // this.isNoData = true;
      this.isProgress = false; 
    } 
  }

  async exportCSV() : Promise<void> {
    try {
      this.isProgressCSV = true;
      await this.reportS.handleExportCsvFire(this.locations, 
                                            this.dataPicker.range.start, 
                                            this.dataPicker.range.end, 
                                            this.dataPicker.aggregation.toLowerCase(), 
                                            this.reportName)
    } finally {
      this.isProgressCSV = false;
    }
  }

  handleExport($event) : void {
    if ($event === 'csv') {
      this.exportCSV();
    }
  }

  handleDataPicker($event: DataPicker) : void {
    // Init get data
    if (this.isReport && this.first) {
      this.first = false;
      this.getInsightData(this.dataPicker);
      return;
    } else if (!this.dataPicker) {
      this.dataPicker = { ...$event };
      this.getInsightData(this.dataPicker);
      return;
    }

    // when multiChart Change
    this.dataPicker.multiChart = $event.multiChart;
    this.multiChartDataSet = this.handleMultiChart(this.dataPicker);

    // ranges or aggregation changes
    if (this.dataPicker.range !== $event.range || this.dataPicker.aggregation !== $event.aggregation) {

      if (this.dataPicker.aggregation === 'month') {
        const endMonth   = this.dateS.stringToDate(this.dataPicker.range.end).getMonth();
        const startMonth = this.dateS.stringToDate(this.dataPicker.range.start).getMonth();
        if (startMonth === endMonth) {
          this.dataPicker.aggregation = 'day';
        }
      }

      this.dataPicker = { ...$event };
      this.getInsightData(this.dataPicker);

      // Calendar change event here
    }

    this.dataPicker.locations = this.locations;
    this.changeDataPicker.emit(this.dataPicker);
  }

  private handleMultiChart(dataPicker: DataPicker) {
    return this.chartS.multiCharset(this.stats, dataPicker);
  }

  private resetData() : void {
    this.labels = [];
    this.stats = [];
    this.isProgress = true;
  }

  clearChecked(event) : void {
    this.multiChartDataSet = [];
    this.checks = event;
  }

  statusChecks(event) : void {
    this.checks = event;
  }

  handleRefresh(): void {
    this.getInsightData(this.dataPicker, true);
  }

  private _orderingStats(stats) {
    const orderedData = [];
    const order = [
      "Actions on your Google Business Profile",
      "Search impressions",
      "total views on search",
      "Google Posts Activity",
      "Photo views",
      "Photo quantity"
    ];
    order.forEach(el => {
      const item = stats.find(s => s?.title == el);
      if (item) {
        orderedData.push(item);
      }
    });
    return orderedData;
  }

  hasNoDate() : string {
    const date = this._locationUpgraded?.createdAt?.$date
    const createdData = date ? moment(date) : null;
    const hasMoreThan24hs =  !!(createdData && this.insightS.hasMoreThan24hs(createdData))

    return hasMoreThan24hs ? 'No insights data' : ('The performance insights for this business profile are still being imported, '+
                                                   'please refresh and check again in 10 minutes')
  }

  // changedViewMode(value) {
  //   this.router.navigateByUrl(`/account/${this.accountId}/location/${this.locationId}/insights`);
  // }
}



