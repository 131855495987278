import { __awaiter, __generator, __values } from "tslib";
// dep
import { Title } from '@angular/platform-browser';
import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, of } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
// app
import { ReportService } from '../../services/report.service';
import { AuthService } from '../../services/auth.service';
import { DataTransferService } from '../../services/data-transfer.service';
import { LocationService } from '../../services/location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { ValidateLocations } from 'src/app/shared/Auxiliary/validate-locations';
import { LoadingService } from '../../services/loading.service';
import { DialogDeleteLocationsComponent } from 'src/app/components/dialog-delete-locations.component';
import { ModalService } from 'src/app/services/modal.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(_router, route, reportS, _authS, _sessionS, titleService, dataTrasnfer, locationService, validateLocation, loadingService, dialog, modalService, _spinnerS, snackS, _navigationS, _subscriptionS) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this._router = _router;
        this.route = route;
        this.reportS = reportS;
        this._authS = _authS;
        this._sessionS = _sessionS;
        this.titleService = titleService;
        this.dataTrasnfer = dataTrasnfer;
        this.locationService = locationService;
        this.validateLocation = validateLocation;
        this.loadingService = loadingService;
        this.dialog = dialog;
        this.modalService = modalService;
        this._spinnerS = _spinnerS;
        this.snackS = snackS;
        this._navigationS = _navigationS;
        this._subscriptionS = _subscriptionS;
        this.locations = [];
        this.shared = false;
        this.lockDates = false;
        this.sharedNotAllowed = false;
        this.refresh = new Subject();
        this.showMultilocations = true;
        this.viewModeChecked = 'legacy';
        this.isProcessed = false;
        this.minDate = null;
        this.maxDate = null;
        this.loadDataObservable$ = of({ total: 0, completed: null });
        this.loadingSpinner$ = this._spinnerS.loading$;
        this._spinnerS.loading$.next(true);
        this.reportType = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot.params) === null || _b === void 0 ? void 0 : _b.reportType;
        this.reportType = (((_c = this.reportType) === null || _c === void 0 ? void 0 : _c.includes('-location')) ? (_d = this.reportType) === null || _d === void 0 ? void 0 : _d.replace('-location', '') :
            ((_e = this.reportType) === null || _e === void 0 ? void 0 : _e.includes('-report')) ? (_f = this.reportType) === null || _f === void 0 ? void 0 : _f.replace('-report', '') :
                this.reportType);
        this.viewModeChecked = (((_g = this.reportType) === null || _g === void 0 ? void 0 : _g.includes('performance')) || ((_h = this.reportType) === null || _h === void 0 ? void 0 : _h.includes('review')) || ((_j = this.reportType) === null || _j === void 0 ? void 0 : _j.includes('keyword')) || ((_k = this.reportType) === null || _k === void 0 ? void 0 : _k.includes('qanda'))) ? 'new' : 'legacy';
        console.debug("reportType=" + this.reportType + " viewModeChecked=" + this.viewModeChecked);
        this._checkAuthAndLoad();
    }
    ReportsComponent.prototype._checkAuthAndLoad = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var automatedReport;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (!((_c = (_b = (_a = this.route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.hash)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadRouteWithHash(this.route.snapshot.params.hash)];
                    case 1:
                        _g.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        automatedReport = (_f = (_e = (_d = this.route) === null || _d === void 0 ? void 0 : _d.snapshot) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.automatedReport;
                        if (!(automatedReport && this.viewModeChecked == 'legacy')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._loadAutomatedData(automatedReport)];
                    case 3:
                        _g.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this._loadData(automatedReport)];
                    case 5:
                        _g.sent();
                        _g.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype._loadRouteWithHash = function (hash) {
        return __awaiter(this, void 0, void 0, function () {
            var report, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.reportS.fetchByHash(hash)];
                    case 1:
                        report = _a.sent();
                        this.id = report.id;
                        this.gid = report.gid;
                        return [4 /*yield*/, this._ensureAnonymous()];
                    case 2:
                        if (!_a.sent()) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._router.navigate(["/report/" + this.gid + "/" + this.id + "/shared"])];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        console.error('error on:', err_1);
                        this._spinnerS.loading$.next(false);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype._ensureAnonymous = function () {
        return __awaiter(this, void 0, void 0, function () {
            var auth;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authS.waitAuthSession()];
                    case 1:
                        auth = _a.sent();
                        if (!!auth) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._authS.signInAnonymously(this.gid)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        if (!(auth.sessionType !== 'ANONYMOUS' || auth.gid !== this.gid)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._showNotSupportedModal()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/, true];
                }
            });
        });
    };
    ReportsComponent.prototype._loadAutomatedData = function (automated) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.gid = (_b = (_a = this.route.snapshot) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.gid;
                        this.id = automated;
                        this.reportId = this.id;
                        this.shared = true;
                        this.lockDates = ((_c = this.report) === null || _c === void 0 ? void 0 : _c.lockDates) || false;
                        return [4 /*yield*/, this._ensureAnonymous()];
                    case 1:
                        if (_d.sent())
                            this.getAutomatedReport(this.gid, automated, true);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.AlloweReport = function (report) {
        return __awaiter(this, void 0, void 0, function () {
            var sub, subscriptionType, _loop_1, this_1, _a, _b, account, err_2;
            var e_1, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (_.isEmpty(report.accounts))
                            return [2 /*return*/];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 7, , 10]);
                        return [4 /*yield*/, this.locationService.basicLocations([{ accounts: report.accounts }])];
                    case 2:
                        _d.sent();
                        return [4 /*yield*/, this._subscriptionS.fetchSubscription(report.gid)];
                    case 3:
                        sub = _d.sent();
                        if (sub.status == GROUP_SUBSCRIPTION_TYPE.TRIAL)
                            return [2 /*return*/];
                        if (!report.sharedOnly) return [3 /*break*/, 6];
                        subscriptionType = report.accounts[0].locationsBasics[0].subscriptionType;
                        if (!(subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL ||
                            subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._router.navigate(['/login'])];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5: return [2 /*return*/];
                    case 6:
                        _loop_1 = function (account) {
                            var hasFreeLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.FREE }));
                            var hasEssentialLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL }));
                            var hasBasicLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.BASIC }));
                            this_1._subscriptionS.fetchSubscription(account.gid).then(function (subscription) {
                                if (subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
                                    (hasFreeLocations || hasEssentialLocations || hasBasicLocations)) {
                                    _this._router.navigate(['/login']);
                                }
                            });
                            var hasInvalidAccounts = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
                            var hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
                            if (hasInvalidAccounts || hasInvalidLocations) {
                                this_1._router.navigate(['/login']);
                            }
                            var hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
                            if (hasDuplicated) {
                                this_1._router.navigate(['/login']);
                            }
                        };
                        this_1 = this;
                        try {
                            for (_a = __values(report.accounts), _b = _a.next(); !_b.done; _b = _a.next()) {
                                account = _b.value;
                                _loop_1(account);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [3 /*break*/, 10];
                    case 7:
                        err_2 = _d.sent();
                        console.error(err_2);
                        return [4 /*yield*/, this.modalService.openErrorLoadingModal(1)];
                    case 8:
                        _d.sent();
                        return [4 /*yield*/, this._navigationS.signOut()];
                    case 9:
                        _d.sent();
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype._loadData = function (automatedReport) {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function () {
            var isAnonymous, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        isAnonymous = false;
                        this.id = automatedReport || ((_b = (_a = this.route.snapshot) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id);
                        this.gid = (_d = (_c = this.route.snapshot) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.gid;
                        this.reportId = this.id;
                        this.reportType = (this.reportType == 'performance-insights' ? 'performance-rollup' : this.reportType);
                        _h = ((_f = (_e = this.route.snapshot) === null || _e === void 0 ? void 0 : _e.url) === null || _f === void 0 ? void 0 : _f.findIndex(function (segment) { return segment.toString() === 'shared'; })) !== -1;
                        if (_h) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._authS.waitAuthSession()];
                    case 1:
                        _h = !(_j.sent());
                        _j.label = 2;
                    case 2:
                        if (!_h) return [3 /*break*/, 4];
                        this.shared = true;
                        this.lockDates = ((_g = this.report) === null || _g === void 0 ? void 0 : _g.lockDates) || false;
                        return [4 /*yield*/, this._ensureAnonymous()];
                    case 3:
                        if (!(_j.sent()))
                            return [2 /*return*/];
                        isAnonymous = true;
                        _j.label = 4;
                    case 4:
                        if (this.viewModeChecked == 'new') {
                            this.getReportMongo(this.gid, this.id, isAnonymous, automatedReport);
                        }
                        else {
                            this._getReport(this.gid, this.id, isAnonymous);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype._showNotSupportedModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.openWarningModal('Alert', ('Opening a shared report while logged-in is not supported. ' +
                            'Please use an incognito session or log-out.'))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.getAutomatedReport = function (gid, reportId, isAnonymous) {
        var _this = this;
        if (isAnonymous === void 0) { isAnonymous = false; }
        var _a;
        (_a = this.reportS.getAutomated(gid, reportId)) === null || _a === void 0 ? void 0 : _a.pipe(take(1)).subscribe(function (report) {
            _this._buildAutomatedReport(report, isAnonymous);
        }, function (error) {
            _this._spinnerS.loading$.next(false);
            console.log(error);
        });
    };
    ReportsComponent.prototype._getReport = function (gid, reportId, isAnonymous) {
        var _this = this;
        if (isAnonymous === void 0) { isAnonymous = false; }
        var _a;
        (_a = this.reportS.get(gid, reportId)) === null || _a === void 0 ? void 0 : _a.pipe(take(1)).subscribe(function (report) {
            _this.report = report;
            _this._verifyLocations();
            _this.buildReport(gid, reportId, isAnonymous, report);
        }, function (error) {
            console.log(error);
            _this.snackS.openError('There was an error while loading the data. Please try again or contact support');
            _this._spinnerS.loading$.next(false);
        });
    };
    ReportsComponent.prototype._verifyLocations = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var hasLocations, reportType;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        hasLocations = (_a = this.report) === null || _a === void 0 ? void 0 : _a.accounts.find(function (el) { var _a, _b; return ((_b = (_a = el) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length) > 0; });
                        if (!!hasLocations) return [3 /*break*/, 2];
                        reportType = this.reportType == 'keyword' ? 'keywords' : (this.reportType == 'qanda' ? 'qanda' : this.reportType + "-report");
                        return [4 /*yield*/, this._router.navigateByUrl("" + reportType)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype._buildAutomatedReport = function (report, isAnonymous) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var accounts, _c, waitingPopup, ReportTypeName, company_name;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.report = report;
                        accounts = report.accounts || [
                            {
                                gid: this.gid,
                                accountId: (_b = (_a = report) === null || _a === void 0 ? void 0 : _a.locations[0]) === null || _b === void 0 ? void 0 : _b.accountId,
                                locations: report.locations
                            }
                        ];
                        _c = this.report;
                        return [4 /*yield*/, this.validateLocation.validateArrayLocations(accounts)];
                    case 1:
                        _c.accounts = _d.sent();
                        this._spinnerS.loading$.next(false);
                        waitingPopup = this._loadDataModal(this.report.accounts, report.reportType);
                        if (!isAnonymous) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.AlloweReport(report)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3:
                        if (!report)
                            return [2 /*return*/];
                        ReportTypeName = report.reportType.replace(/\b\w/g, function (l) { return l.toUpperCase(); });
                        ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
                        if (this.shared) {
                            company_name = this._sessionS.getDomain().branding.company_name;
                            this.titleService.setTitle(ReportTypeName + " Report | " + company_name);
                        }
                        else {
                            this.dataTrasnfer.setData(ReportTypeName + " Report | " + report.reportName);
                        }
                        this.processReport(isAnonymous, report, true);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.getReportMongo = function (gid, reportId, isAnonymous, automatedReport) {
        var _this = this;
        if (isAnonymous === void 0) { isAnonymous = false; }
        if (automatedReport === void 0) { automatedReport = null; }
        var _a;
        (_a = this.reportS.getReportById(reportId, automatedReport)) === null || _a === void 0 ? void 0 : _a.pipe(take(1)).subscribe(function (report) { return __awaiter(_this, void 0, void 0, function () {
            var id, accountIds, locationIds_1, dateValidations, dates;
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        if (!((_a = report) === null || _a === void 0 ? void 0 : _a.error)) return [3 /*break*/, 1];
                        console.log((_b = report) === null || _b === void 0 ? void 0 : _b.error);
                        this.snackS.openError('There was an error while loading the data. Please try again or contact support');
                        this._spinnerS.loading$.next(false);
                        return [3 /*break*/, 3];
                    case 1:
                        id = ((_c = report) === null || _c === void 0 ? void 0 : _c.id) || ((_d = report) === null || _d === void 0 ? void 0 : _d._id);
                        //added it until the new version of back that handles the existence of locations and accounts is integrated
                        report.accounts = (_e = report) === null || _e === void 0 ? void 0 : _e.accounts.filter(function (r) { return r.locations.length; });
                        report.reportType = ((_f = report) === null || _f === void 0 ? void 0 : _f.reportType) == 'performance-insights' ? 'performance-rollup' : report.reportType;
                        (_g = report) === null || _g === void 0 ? void 0 : _g.multiChart.forEach(function (m) { return m.checked = true; });
                        this.report = report;
                        accountIds = report.accounts.map(function (a) { return a.accountId; });
                        locationIds_1 = [];
                        report.accounts.forEach(function (a) { return a.locations.forEach(function (l) { return locationIds_1.push(l.locationId); }); });
                        return [4 /*yield*/, this.locationService.getDateValidations(this.reportType, accountIds, [gid], locationIds_1)];
                    case 2:
                        dateValidations = _j.sent();
                        dates = this.locationService.dateValidation(dateValidations);
                        this.minDate = dates.minDate;
                        this.maxDate = dates.maxDate;
                        this._verifyLocations();
                        this.lockDates = ((_h = this.report) === null || _h === void 0 ? void 0 : _h.lockDates) || false;
                        this.reportId = automatedReport ? report.parentReport : id;
                        this.buildReport(gid, id, isAnonymous, report);
                        _j.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.log(error);
            _this.snackS.openError('There was an error while loading the data. Please try again or contact support');
            _this._spinnerS.loading$.next(false);
        });
    };
    ReportsComponent.prototype.buildReport = function (gid, id, isAnonymous, report) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var accounts, waitingPopup, _c, ReportTypeName, company_name;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        accounts = ((_a = report) === null || _a === void 0 ? void 0 : _a.accounts) || [
                            {
                                gid: gid,
                                accountId: report.locations[0].accountId,
                                locations: report.locations
                            }
                        ];
                        this.report.accounts = accounts;
                        this._spinnerS.loading$.next(false);
                        waitingPopup = this._loadDataModal(this.report.accounts, report.reportType);
                        return [4 /*yield*/, this.existAllLocations(report, id)];
                    case 1:
                        if (!_d.sent()) return [3 /*break*/, 5];
                        _c = this.report;
                        return [4 /*yield*/, this.validateLocation.validateArrayLocations(accounts)];
                    case 2:
                        _c.accounts = _d.sent();
                        if (!isAnonymous) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.AlloweReport(report)];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        if (!report)
                            return [2 /*return*/];
                        ReportTypeName = report.reportType.replace(/\b\w/g, function (l) { return l.toUpperCase(); });
                        ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
                        if (this.shared) {
                            company_name = this._sessionS.getDomain().branding.company_name;
                            this.titleService.setTitle(ReportTypeName + " Report | " + company_name);
                        }
                        else {
                            this.dataTrasnfer.setData(ReportTypeName + " Report | " + report.reportName);
                        }
                        this.processReport(isAnonymous, report);
                        return [3 /*break*/, 6];
                    case 5:
                        (_b = waitingPopup) === null || _b === void 0 ? void 0 : _b.close();
                        _d.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype.existAllLocations = function (report, reportId) {
        return __awaiter(this, void 0, void 0, function () {
            var locationsIds, _loop_2, _a, _b, account, state_1, AllLocations, DeletedLocations_1, nouns, textDescription, dialogRef_1;
            var e_2, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        locationsIds = [];
                        _loop_2 = function (account) {
                            if (account.locations && account.locations.length > 0) {
                                account.locations.forEach(function (l) {
                                    locationsIds.push({
                                        'gid': account.gid,
                                        'accountId': account.accountId,
                                        'locationId': l.locationId
                                    });
                                });
                            }
                            else {
                                return { value: false };
                            }
                        };
                        try {
                            for (_a = __values(report.accounts), _b = _a.next(); !_b.done; _b = _a.next()) {
                                account = _b.value;
                                state_1 = _loop_2(account);
                                if (typeof state_1 === "object")
                                    return [2 /*return*/, state_1.value];
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        return [4 /*yield*/, this.locationService.fetchLocationsExistence(locationsIds)];
                    case 1:
                        AllLocations = _d.sent();
                        if (AllLocations.some(function (r) { return !r.exist; })) {
                            DeletedLocations_1 = AllLocations.filter(function (l) { return !l.exist; });
                            nouns = DeletedLocations_1.length > 1 ? ['were', 'locations have'] : ['was', 'location has'];
                            textDescription = ("This report used to contain " + AllLocations.length + " locations but " + DeletedLocations_1.length + " " +
                                (nouns[0] + " removed from the system. The missing " + nouns[1] + " now been removed from this report."));
                            this.locationService.deleteReportLocation(DeletedLocations_1, DeletedLocations_1[0]['gid'], reportId);
                            dialogRef_1 = this.dialog.open(DialogDeleteLocationsComponent, {
                                width: '400px',
                                data: {
                                    title: 'Heads Up',
                                    description: textDescription,
                                    DeletedLocations: DeletedLocations_1,
                                }
                            });
                            dialogRef_1.disableClose = true;
                            dialogRef_1.afterClosed().subscribe(function () {
                                dialogRef_1.close();
                                _this.deleteLocations(DeletedLocations_1);
                            });
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    ReportsComponent.prototype.deleteLocations = function (deletedLocations) {
        var _this = this;
        var _a, _b, _c;
        this._spinnerS.loading$.next(true);
        deletedLocations.forEach(function (el) {
            var _a, _b, _c, _d;
            var indexAccount = (_b = (_a = _this.report) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.findIndex(function (a) { return a.accountId == el.accountId; });
            var indexLoc = (_c = _this.report.accounts[indexAccount].locations) === null || _c === void 0 ? void 0 : _c.findIndex(function (l) { return l.locationId == el.locationId; });
            (_d = _this.report.accounts[indexAccount].locations) === null || _d === void 0 ? void 0 : _d.splice(indexLoc, 1);
        });
        this.report.accounts = this.locationService.deleteServiceArea(this.report.accounts);
        if (this.viewModeChecked != 'new') {
            if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.id) {
                this.reportS.update(false, (_b = this.report) === null || _b === void 0 ? void 0 : _b.gid, (_c = this.report) === null || _c === void 0 ? void 0 : _c.id, this.report).then(function (res) {
                    location.reload();
                }, function (err) {
                    _this._handleDeleteError();
                });
            }
            else {
                location.reload();
            }
        }
        else {
            if (this.reportType == 'performance-comparison') {
                this.report.metrics = this.report.metricsName;
            }
            this.reportS.updateReportsInMongo(this.report['_id'], this.report).subscribe(function (res) {
                var _a;
                if ((_a = res) === null || _a === void 0 ? void 0 : _a['error']) {
                    _this._handleDeleteError();
                }
                else {
                    location.reload();
                }
            }, function (err) {
                _this._handleDeleteError();
            });
        }
    };
    ReportsComponent.prototype._handleDeleteError = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._spinnerS.loading$.next(false);
                        return [4 /*yield*/, this.modalService.openErrorModal('Heads up', "There was an error while removing the locations.")];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsComponent.prototype._loadDataModal = function (accounts, type) {
        var _a;
        accounts = accounts.filter(Boolean);
        if (accounts.reduce(function (r, ac) { return r += ac.locations.length; }, 0) <= 9)
            return;
        var steps = 0;
        var reportType = type.includes('-report') ? type.replace('-report', '') : type;
        reportType = reportType.includes('performance') ? (_a = reportType) === null || _a === void 0 ? void 0 : _a.slice("performance-".length) : reportType;
        switch (reportType) {
            case 'rollup':
                steps = 5;
                break;
            case 'qanda':
                steps = 1;
                break;
            case 'revenue':
                steps = 1;
                break;
            case 'review':
                steps = 3;
                break;
            case 'comparison':
                steps = 2;
                break;
            case 'grade':
                steps = 3;
                break;
            case 'Review Assistant':
                steps = 6;
                break;
            case 'keyword':
                steps = 1;
                break;
        }
        return this.loadingService.open(steps);
    };
    ReportsComponent.prototype.processReport = function (isAnonymous, report, automatedReport) {
        if (automatedReport === void 0) { automatedReport = false; }
        var _a, _b, _c, _d, _e, _f;
        // FIXME: 'isAnonymous' is unused.
        this._spinnerS.loading$.next(false);
        this.reportType = ((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('-location')) ? (_b = this.reportType) === null || _b === void 0 ? void 0 : _b.replace('-location', '') : this.reportType;
        this.dataPicker = this.reportS.reportToDataPicker(report, this.id, automatedReport, this.viewModeChecked);
        this.dataPicker.aggregation = ((_c = report) === null || _c === void 0 ? void 0 : _c.aggregation) || ((_d = this.dataPicker) === null || _d === void 0 ? void 0 : _d.aggregation);
        this.dataPicker.multiChart = ((_e = report) === null || _e === void 0 ? void 0 : _e.multiChart) || ((_f = this.dataPicker) === null || _f === void 0 ? void 0 : _f.multiChart);
        this.showMultilocations = _.has(report, 'dynamic');
        this.updateClass();
        this.isProcessed = true;
    };
    ReportsComponent.prototype.ngOnDestroy = function () {
        this.reportType = null;
        this._spinnerS.loading$.next(false);
    };
    ReportsComponent.prototype.handleDatapicker = function ($event) {
        if ($event) {
            this.dataPicker = $event;
            this.refresh.next(true);
        }
    };
    ReportsComponent.prototype.updateClass = function () {
        if (this.shared) {
            if (this.reportType.includes('rollup') ||
                this.reportType.includes('review') ||
                this.reportType.includes('comparison') ||
                this.reportType.includes('revenue') ||
                this.reportType.includes('keyword')) {
                return;
            }
            var isGrade = (this.reportType === 'grade');
            this.contentClass = {
                'content content--padding': isGrade,
                'dashboard-content dashboard-content--sm': !isGrade
            };
        }
    };
    return ReportsComponent;
}());
export { ReportsComponent };
