import { __awaiter, __extends, __generator } from "tslib";
// dep
import { ErrorHandler } from '@angular/core';
var GlobalErrorHandler = /** @class */ (function (_super) {
    __extends(GlobalErrorHandler, _super);
    function GlobalErrorHandler(_sessionS, _authProxyS, _modalS) {
        var _this = _super.call(this) || this;
        _this._sessionS = _sessionS;
        _this._authProxyS = _authProxyS;
        _this._modalS = _modalS;
        // Used to solve a cyclic dep by removing a SessionService->ModalService dep 
        _this._sessionS.state$.subscribe(function (state) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (state !== 'ERROR_LOADING')
                            return [2 /*return*/];
                        return [4 /*yield*/, this._modalS.openErrorLoadingModal(5)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._authProxyS.signOut()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return _this;
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(((_a = error) === null || _a === void 0 ? void 0 : _a.name) === 'FirebaseError')) return [3 /*break*/, 2];
                        console.error('Error FirebaseError from GlobalErrorHandler', error);
                        return [4 /*yield*/, this._authProxyS.signOut()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: throw error;
                }
            });
        });
    };
    return GlobalErrorHandler;
}(ErrorHandler));
export { GlobalErrorHandler };
