import { __assign, __extends } from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReportsFilterConfigService } from './reports-filter-config.service';
import { KeywordsReportDataService } from './keywords-report-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./keywords-report-data.service";
var KeywordsFilterConfigService = /** @class */ (function (_super) {
    __extends(KeywordsFilterConfigService, _super);
    function KeywordsFilterConfigService(_http, _keyRepDataS) {
        var _this = _super.call(this, _http) || this;
        _this._http = _http;
        _this._keyRepDataS = _keyRepDataS;
        _this.keywordsFilterConfigObject = null;
        return _this;
    }
    KeywordsFilterConfigService.prototype.setInitialFilterConfigData = function (configData) {
        this.keywordsFilterConfigObject = {
            gid: configData.gid,
            accountId: configData.accountId,
            locationId: configData.locationId,
            startDate: this._starterMinDate,
            endDate: this._starterMaxDate,
            sort: {
                sortBy: 'impressions',
                sortOrder: -1
            },
            page: 1,
            size: 25,
            filteredKeyword: ''
        };
    };
    // calls keywordsReportDataService and prompts the report data retrieval process
    // name pending, this one is not good
    KeywordsFilterConfigService.prototype.updateFilterConfigAndGetReportData = function (selectedDates) {
        console.log('updateFilterConfig called');
        // update the keywordsFilterConfigObject accordingly
        this.keywordsFilterConfigObject = __assign(__assign({}, this.keywordsFilterConfigObject), { startDate: selectedDates.minDate, endDate: selectedDates.maxDate });
        // call the getReportData method
        this._keyRepDataS.getKeywordsResults(this.keywordsFilterConfigObject);
    };
    KeywordsFilterConfigService.prototype.getTableDataFilteredByKeyword = function (keyword) {
        // when a new keyword filter is requested (or removed), we reset sorting and pagination
        this.keywordsFilterConfigObject = __assign(__assign({}, this.keywordsFilterConfigObject), { sort: {
                sortBy: 'impressions',
                sortOrder: -1
            }, page: 1, size: 25, filteredKeyword: keyword });
        this._keyRepDataS.getKeywordsTableData(this.keywordsFilterConfigObject, keyword);
    };
    KeywordsFilterConfigService.prototype.getTableDataPaginated = function (tableType, pagination) {
        this.keywordsFilterConfigObject = __assign(__assign({}, this.keywordsFilterConfigObject), { page: pagination.page, size: pagination.size });
        this._keyRepDataS.getTableDataPaginated(this.keywordsFilterConfigObject, tableType);
    };
    KeywordsFilterConfigService.prototype.getTableDataSorted = function (sort) {
        this.keywordsFilterConfigObject = __assign(__assign({}, this.keywordsFilterConfigObject), { sort: {
                sortBy: sort.sortBy,
                sortOrder: sort.sortOrder === 'asc' ? 1 : -1
            } });
        this._keyRepDataS.getTableDataSorted(this.keywordsFilterConfigObject);
    };
    KeywordsFilterConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KeywordsFilterConfigService_Factory() { return new KeywordsFilterConfigService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.KeywordsReportDataService)); }, token: KeywordsFilterConfigService, providedIn: "root" });
    return KeywordsFilterConfigService;
}(ReportsFilterConfigService));
export { KeywordsFilterConfigService };
