import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';

import {DataPicker} from '../../constants/data-picker';
import {InsightsService} from '../../services/insights.service';
import {ReportService} from 'src/app/services/report.service';
import {map} from 'rxjs/operators';
import Report from '../../constants/firestore/report';
import {ActivatedRoute} from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocationRef } from 'src/app/constants/firestore/location-object';

@Component({
  selector: 'app-dashboard-revenue',
  templateUrl: './dashboard-revenue.component.html',
  styleUrls: ['./dashboard-revenue.component.scss']
})
export class DashboardRevenueComponent implements OnInit {
  public locations: LocationRef[];
  public searchStat: any;
  public views: any;
  public selectDate: any;
  public direction = 0;
  public mobile = 0;
  public website = 0;
  public ctr = 0;
  public form: FormGroup;
  public actions: any;
  public potentialStore = 0;
  public potentialSales = 0;
  public potentialRevenue = 0;
  public labels: any;
  public progress = true;
  public isProgressCSV: boolean;
  public isLoading = false;
  private _gid: any;
  private _id: any;

  @Input() dataPicker: DataPicker;
  @Input() shared = false;
  @Input() lockDates = false;
  @Input() report: Report;
  @Input() reportName: string;
  @Input() reportType:string;
  @Input() reportId;
  @Input() viewModeChecked = 'legacy';

  constructor(
    private _insightS: InsightsService,
    private _fb: FormBuilder,
    private _reportS: ReportService,
    private _route: ActivatedRoute,
    private _loadingService: LoadingService,
    private _snackS: SnackbarService,
  ) {
    this._id = this._route?.snapshot?.params?.id;
    this._gid = this._route?.snapshot?.params?.gid;
  }

  ngOnInit() {
    this.locations = this.dataPicker.locations;
    this.initForm();
    this.getData();
  }

  getData() {
    this.selectDate = this._reportS.selectDatesFromDataPicker(this.dataPicker);

    this._insightS.getInsights(this.locations, this.dataPicker, this.viewModeChecked).then(
      res => {
        this.progress = false;
        this.actions = res.stats[0];
        this.labels = res.labels;
        const totalActions = res.stats[0].totals || res.stats[0].total;
        const totalViews   = res.stats[1].totals || res.stats[1].total;
        this.direction = this.actions.ACTIONS_DRIVING_DIRECTIONS.total.numeric;
        this.mobile    = this.actions.ACTIONS_PHONE.total.numeric;
        this.website   = this.actions.ACTIONS_WEBSITE.total.numeric;

        res.stats?.forEach(s => {
          if (s?.title === 'Search impressions') {
            this.searchStat = s;
          }
          if (s?.title === 'total views on search') {
            this.views = s;
          }

          if (totalViews > 0) {
            this.ctr = (totalActions / totalViews) * 100;
          }

        });

        // if (this.report.revenueInfo) {
        //   this.calculateValues();
        // }
        this.calculateValues();
        this._loadingService.reportAdvanced(1, 'Revenue');
      }, error => {
        console.error(error);
      }
    );
  }

  initForm() {
    this.form = this._fb.group({
      directionControl: [this.report?.metadata?.directionControl || 0],
      mobileControl: [this.report?.metadata?.mobileControl || 0],
      websiteControl: [this.report?.metadata?.websiteControl || 0],
      potentialStoreControl: [this.report?.metadata?.potentialStoreControl || 0],
      potentialSalesControl: [this.report?.metadata?.potentialSalesControl || 0],
    });

    this.form.valueChanges.pipe(map(next => {
      return this.calculateValues();
    })).subscribe(next => {
      this._reportS.setMerge(this._gid, this._id, next).then();
    });


    this.potentialRevenue = this.report.revenueInfo?.potentialRevenue ? this.report?.revenueInfo?.potentialRevenue : 0;
  }


  calculateValues() {
    this.potentialStore = 0;
    this.potentialSales = 0;
    this.potentialRevenue = 0;
    this.potentialStore += this.direction * (parseInt(this.directionControl.value, 0) / 100);
    this.potentialStore += this.website * (parseInt(this.websiteControl.value, 0) / 100);
    this.potentialStore += this.mobile * (parseInt(this.mobileControl.value, 0) / 100);
    this.potentialSales += this.potentialStore * (parseInt(this.potentialStoreControl.value, 0) / 100);
    this.potentialRevenue += this.potentialSales * (parseInt(this.potentialSalesControl.value, 0));
    return {
      revenueInfo: {
        potentialStore: this.potentialStoreControl.value,
        potentialSales: this.potentialSalesControl.value,
        potentialRevenue: this.potentialRevenue,
        actions: {
          mobile: this.mobileControl.value,
          website: this.websiteControl.value,
          direction: this.directionControl.value,
        }
      }
    };
  }

  get totalAction() {
    return this.direction + this.website + this.mobile;
  }

  get directionControl() {
    return this.form.get('directionControl');
  }


  get mobileControl() {
    return this.form.get('mobileControl');
  }

  get websiteControl() {
    return this.form.get('websiteControl');
  }

  get potentialStoreControl() {
    return this.form.get('potentialStoreControl');
  }

  get potentialSalesControl() {
    return this.form.get('potentialSalesControl');
  }

  handleDataPicker(event: any) {
    this.dataPicker = { ...event };
    if(!this.dataPicker.hasOwnProperty('locations')) {
      this.dataPicker.locations = this.locations;
    }
  }

  async exportCSV(event) {
    if (event != 'csv') return;

    this.isProgressCSV = true;

    if (this.viewModeChecked == 'legacy') {
      await this._reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName);
    } else {
      await this._reportS.handleExportCsvMongo(this.reportId, null, null, null, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation);
    }
    
    this.isProgressCSV = false;
  }

  triggerNumberUp(control: AbstractControl) {
    (control.value + 1 < 0) ? control.setValue(0) : control.setValue(control.value + 1);
  }

  triggerNumberDown(control: AbstractControl) {
    (control.value - 1 < 0) ? control.setValue(0) : control.setValue(control.value - 1);
  }

  clearValues(): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).reset(0);
    });
  }

  saveValues(): void {
    this.isLoading = true;
    const data = {...this.report};
    data.metadata = {
      directionControl: this.directionControl?.value,
      mobileControl: this.mobileControl?.value,
      websiteControl: this.websiteControl?.value,
      potentialStoreControl: this.potentialStoreControl?.value,
      potentialSalesControl: this.potentialSalesControl?.value
    }

    this._reportS.updateReportsInMongo(this._id, data).subscribe(
      res => {
        if(res?.error) {
          this._snackS.openError('There was an error while saving the data. Please try again or contact support', 4000);
        } else {
          this._snackS.openSuccess('The data was saved successfully', 2000);
        }
        this.isLoading = false;
      },
      err => {
        this._snackS.openError('There was an error while saving the data. Please try again or contact support', 4000);
        this.isLoading = false;
      }
    );
  }
}
