// dep
import {Component, Input } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { BaseComponent } from 'src/app/components/base.component';

// app
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls:  ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent {
  @Input() customPosition = false;
  date: Date = new Date();
  showFooter = false;

  domain$ = this._sessionS.domain$;

  constructor(
    private _router: Router,
    private _sessionS : SessionService,
    public navigationS: NavigationService
  ) {
    super();
    this._subscribeSafe(_router.events, (event) => {
      if(event instanceof NavigationEnd)
        this._refresh();
    });
    this._refresh()
  }

  private _refresh() : void {
    const url = this._router.url;
    this.showFooter = !((url.includes('login') || url.includes('register') || url.includes('forgot-password')));
  }

}
