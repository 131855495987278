import { __awaiter, __extends, __generator } from "tslib";
import { MatDialogRef } from '@angular/material';
// app
import { GoogleAccountService } from '../services/google-account.service';
import { GoogleService } from '../services/google.service';
import { SnackbarService } from '../services/snackbar.service';
import { PostService } from '../services/post.service';
import { ModalConfirmData } from '../classes/modal-confirm-data';
import { SessionService } from '../services/session.service';
import { BaseComponent } from './base.component';
var PostsErrorsComponent = /** @class */ (function (_super) {
    __extends(PostsErrorsComponent, _super);
    function PostsErrorsComponent(data, dialogRef, postService, googleAccountService, google, snack, _sessionS) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this.postService = postService;
        _this.googleAccountService = googleAccountService;
        _this.google = google;
        _this.snack = snack;
        _this._sessionS = _sessionS;
        _this.consumeData = null;
        _this.locationsPost = [];
        _this.loadingPostErrors = true;
        _this.setUp();
        return _this;
    }
    PostsErrorsComponent.prototype.setUp = function () {
        var _this = this;
        this.consumeData = this.data.data;
        if (this.consumeData.bulk) {
            this.postService.getBulkLocationPost(this.consumeData.bulkId).then(function (data) {
                _this.loadingPostErrors = false;
                _this.locationsPost = data;
            });
        }
        else {
            this.loadingPostErrors = false;
            this.locationsPost = [this.consumeData.location];
        }
    };
    PostsErrorsComponent.prototype.openReauth = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.onAddNewAccount(element)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PostsErrorsComponent.prototype.onAddNewAccount = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, uid, gid, url, oauth_1, popupTick_1, e_1, message;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._subscribeSafe(this.googleAccountService.onLoadAll$, function (ev) {
                            if (ev.success !== true) {
                                return _this.snack.openError(ev.message);
                            }
                            else {
                                //
                            }
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings");
                        _a = this._sessionS.getSession(), uid = _a.uid, gid = _a.gid;
                        return [4 /*yield*/, this.google.authenticate(element.gid || gid, uid, element.accountId)];
                    case 2:
                        url = _b.sent();
                        oauth_1 = window.open(url, '_blank');
                        popupTick_1 = setInterval(function () {
                            if (oauth_1.closed) {
                                clearInterval(popupTick_1);
                                _this.googleAccountService.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        message = 'There was an error with the GBP Authentication service';
                        return [2 /*return*/, this.snack.openError(message, 4000)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Unused, remove?
    // cleanErrors(): void {
    //   if (this.consumeData.bulk) {
    //     //
    //   } else {
    //     this.postService.clearErrorsV3(this.locationsPost[0].id, 'LOCAL').then();
    //   }
    // }
    PostsErrorsComponent.prototype.retry = function (post) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var id, type, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        id = post.id;
                        type = ((_a = this.consumeData) === null || _a === void 0 ? void 0 : _a.bulk) ? 'BULK' : 'LOCAL';
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        this.loadingPostErrors = true;
                        return [4 /*yield*/, this.postService.retry(id, type, 'Pending')];
                    case 2:
                        _d.sent();
                        this.loadingPostErrors = false;
                        this.dialogRef.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _d.sent();
                        this.snack.openError(((_c = (_b = error_1) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message) || error_1, 3500);
                        this.loadingPostErrors = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PostsErrorsComponent.prototype.retryAll = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.loadingPostErrors = true;
                        return [4 /*yield*/, this.postService.retryAll(this.consumeData.bulkId, 'BULK', 'Pending')];
                    case 1:
                        _c.sent();
                        this.loadingPostErrors = false;
                        this.dialogRef.close(true);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _c.sent();
                        this.snack.openError(((_b = (_a = error_2) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) || error_2, 3500);
                        this.loadingPostErrors = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PostsErrorsComponent;
}(BaseComponent));
export { PostsErrorsComponent };
export var AlertType;
(function (AlertType) {
    AlertType[AlertType["INFO"] = 0] = "INFO";
    AlertType[AlertType["WARNING"] = 1] = "WARNING";
    AlertType[AlertType["ERROR"] = 2] = "ERROR";
})(AlertType || (AlertType = {}));
