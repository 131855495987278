// dep
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import moment from 'moment';

// app
import { Pagination } from 'src/app/constants/api-response';
import { DataPicker } from 'src/app/constants/data-picker';
import { InsightsService } from 'src/app/services/insights.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PdfService } from 'src/app/services/pdf.service';
import { ReportService } from 'src/app/services/report.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Sort } from '../../constants/sort';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-multilocations-info-table',
  templateUrl: './multilocations-info-table.component.html',
  styleUrls: ['./multilocations-info-table.component.scss']
})
export class MultilocationsInfoTableComponent implements OnInit {
  public isEmpty = true;
  public isSingleReview: boolean;
  public isProgressCSV = false;
  
  @Input() isLoading = true;
  @Input() sort: Sort;
  @Input() dataSource = new MatTableDataSource([]);
  @Input() pagination = Pagination;
  @Input() displayedColumns: string[];
  @Input() fieldsColumns;
  @Input() title = 'multi locations table';
  @Input() hasExportPDF = false;
  @Input() hasExportCSV = false;
  @Input() hasScroll = false;
  @Input() isSticky = false;
  @Input() isShared = false;
  @Input() locations;
  @Input() reportType;
  @Input() isLegacy = false;
  @Input() reportId = null;
  @Input() reportName = null;
  @Input() dataPicker: DataPicker;
  
  @Output() handleMultilocSort = new EventEmitter();
  @Output() handleMultilocPaginate = new EventEmitter();
  
  constructor(
    private _sessionS : SessionService,
    public loadingService: LoadingService,
    private _router: Router,
    private _pdfS: PdfService,
    private _reportS: ReportService,
    private _afsStorage: AngularFireStorage,
    private _snack: SnackbarService,
    private _insightS: InsightsService
  ) {
  }

  ngOnInit() {
    if (!this.displayedColumns?.length) {
      this.setDisplayColumns();
    } 
    
    this.loadingService.reportAdvanced(1, 'Insights Resume')
    this.isLoading = false;
  }

  setDisplayColumns() {
    if (this._router.url.includes('reviews')) {
      this.displayedColumns = ['rating', 'date', 'status', 'reviewer', 'actions'];
      this.isSingleReview = false;
    } else {
      if (this.isShared) {
        this.displayedColumns = ['company', 'rating', 'date', 'status', 'reviewer'];
      } else {
        this.displayedColumns = ['company', 'rating', 'date', 'status', 'reviewer', 'actions'];
      }
      this.isSingleReview = true;
    }
  }

  locationUrl(accountId, locationId, destiny) {
    return `/account/${accountId}/location/${locationId}/${destiny}`;
  }

  sortBy($event) {
    this.sort = {
      sortBy: $event.active,
      direction: $event.direction
    };
  }

  handleSort(event) : void {
    this.handleMultilocSort.emit(event);
  }

  handleReload(event) : void {
    this.handleMultilocPaginate.emit(event);
  }

  buildName(col) {
    return col.replaceAll('_', ' ');
  }

  exportPDF() : void {
    this._pdfS.exportTableToPDF('#table-location-container', 'Multilocations-PDF', 'Multi Locations Table');
  }

  async exportCSV() : Promise<void>{
    const date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    this.isProgressCSV = true;
    if (!this.isLegacy) {
      await this._reportS.resumeExportCsv(this._sessionS.getSession().gid, this.reportType, this.sort, this.dataPicker, this.reportName).subscribe(
        result => {
          this._afsStorage.ref(result['data']).getDownloadURL().toPromise().then(filename => {
            this._insightS.openDownloadInNewTab(filename);
            this.isProgressCSV = false;
            this._snack.openSuccess("The file exported successfully", 2000)
          })
        },
        err => {
          this.isProgressCSV = false;
          console.error(err);
          this._snack.openError("The file export failed. Please try again later.", 2000)
        });   
    }
  }

  typeElement(element) {
    return typeof(element);
  }
}
