// GMB Location Object (See Google specs)

// import AccountLocation from './account-location';
import SavedLocation from './saved-location';

/**
 * A location returned by Google
 */
export default interface LocationObject {
  [x: string]: any;

  // placeId?: string;
  locationKey: {
    [x: string]: any;
  };
  locationName: string;
  // accountLocation?: AccountLocation;
}

// Not nominal type yet but good for self-documentation
export type AccountID  = string
export type LocationID = string
export type GroupID = string

export interface LocationRef {
  accountId:  AccountID
  locationId: LocationID // | { locationId : string}
}


// TODO: Normalize data on callers site to don't need this function
export function normalizeLocationRef(l: LocationRef | { accountId: string, 
                                                        locationId: SavedLocation }): LocationRef {
  return {
    accountId: l.accountId,
    locationId: (l.locationId as SavedLocation).locationId || (l.locationId as string)
  }

}

export function locationRefsToPaths(locations: LocationRef[]) : string[] {
  return locations.map(l => `${l.accountId}/${l.locationId}`)
}

/**
 * Splits the location.name field ('accounts/{accountId}/locations/{locationId}')
 * Don't confuse with location.locationName (the description of the location).
 */
export function locationNameToRef(loc : LocationObject) : LocationRef {
  const s = loc.name.split('/');
  return { accountId : s[1], 
          locationId : s[3] };
}