import { __awaiter, __extends, __generator } from "tslib";
// dep
import { Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// app
import { SubscriptionService } from '../../services/subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { LocationService } from '../../services/location.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var BODY_CSS_CLASS = 'trial-message-wrapper';
var TrialMessageComponent = /** @class */ (function (_super) {
    __extends(TrialMessageComponent, _super);
    function TrialMessageComponent(
    // dep:
    _document, _route, _renderer, 
    // app:
    _subscriptionS, _locationS, _sessionS) {
        var _this = _super.call(this) || this;
        _this._document = _document;
        _this._route = _route;
        _this._renderer = _renderer;
        _this._subscriptionS = _subscriptionS;
        _this._locationS = _locationS;
        _this._sessionS = _sessionS;
        _this._refreshState = null;
        _this.messageType = null;
        _this.session$ = _this._sessionS.session$;
        _this._subscribeSafe(_this.session$, function (_) { return _this._refresh(); });
        _this._subscribeSafe(_this._route.params, function (_) { return _this._refresh(); });
        _this._subscribeSafe(_this._locationS.someLocationChanged$, function (_) { return _this._refresh(); });
        return _this;
    }
    TrialMessageComponent.prototype._refresh = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var s, _b, accountId, locationId, st;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._refreshState === 'RUNNING' || this._refreshState === 'SCHEDULED') {
                            this._refreshState = 'SCHEDULED';
                        }
                        this._refreshState = 'RUNNING';
                        s = this._sessionS.getSession();
                        if (!s.isMember) return [3 /*break*/, 1];
                        this.messageType = null;
                        return [3 /*break*/, 5];
                    case 1:
                        if (!s.isTrial) return [3 /*break*/, 2];
                        this.messageType = 'SUBSCRIPTION_TRIAL';
                        return [3 /*break*/, 5];
                    case 2:
                        _b = this._route.snapshot.parent.params, accountId = _b.accountId, locationId = _b.locationId;
                        if (!!(accountId && locationId && s.subscription.pricingVersion < 3)) return [3 /*break*/, 3];
                        this.messageType = null;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, ((_a = this._locationS.fetchMultipleLocations(s.gid, [{ accountId: accountId, locationId: locationId }], ['subscriptionType'])[0]) === null || _a === void 0 ? void 0 : _a.subscriptionType)];
                    case 4:
                        st = _c.sent();
                        this.messageType = (st == LOCATION_SUBSCRIPTION_TYPE.FREE ||
                            st == LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) ? 'LOCATION_FREE' : null;
                        _c.label = 5;
                    case 5:
                        // Must add/remove class to the 'body' element (outside any Angular component) to adjust
                        // padding when the trial message is shown.
                        // TODO: Remove the js-trial-message class on all files, not removed now to avoid
                        // breaking smoke tests.
                        // TODO: impersonate-message-component is dependent on this, must be refactored, so
                        // both components are part of header.component.ts, and no class is added/removed
                        // to the body element. Also note that currently if Trial+Impersonate are both
                        // active, only the Impersonate message will show. 
                        // Refactor also taking into account the usage of the grade-message CSS class, as it 
                        // also adds a message on top. 
                        if (this.messageType || s.isImpersonating)
                            this._renderer.addClass(this._document.body, BODY_CSS_CLASS);
                        else
                            this._renderer.removeClass(this._document.body, BODY_CSS_CLASS);
                        // @ts-ignore 
                        // TS doesn't understand that this member can be changed by a concurrent call
                        if (this._refreshState === 'SCHEDULED') {
                            setTimeout(function () { return _this._refresh(); }, 0);
                        }
                        this._refreshState = null;
                        return [2 /*return*/];
                }
            });
        });
    };
    TrialMessageComponent.prototype.openLocationUpgrade = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, accountId, locationId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this._route.snapshot.parent.params, accountId = _a.accountId, locationId = _a.locationId;
                        if (!accountId || !locationId) {
                            // must never happen
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this._subscriptionS.flowChangeLocationsPlan([{ locationId: locationId, accountId: accountId }])];
                    case 1:
                        if (_b.sent())
                            this._refresh();
                        return [2 /*return*/];
                }
            });
        });
    };
    TrialMessageComponent.prototype.openSubscriptionUpgrade = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subscription;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        subscription = this._sessionS.getSession().subscription;
                        return [4 /*yield*/, this._subscriptionS.stopTrial(subscription)];
                    case 1:
                        if (_a.sent())
                            this._refresh();
                        return [2 /*return*/];
                }
            });
        });
    };
    return TrialMessageComponent;
}(BaseComponent));
export { TrialMessageComponent };
