import { __awaiter, __generator } from "tslib";
// dep
import { ChangeDetectorRef, OnInit, ViewRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { COLORS_CHART } from 'src/app/constants/chart-color';
import { SearchKeywordsService } from 'src/app/services/search-keywords.service';
import { ReportService } from 'src/app/services/report.service';
import { PaginatorComponent } from '../../charts/paginator/paginator.component';
import { ModalService } from 'src/app/services/modal.service';
import { LocationService } from 'src/app/services/location.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
var SearchKeywordsComponent = /** @class */ (function () {
    function SearchKeywordsComponent(route, _cdRef, _SearchesKeywordsServ, _reportS, _modalS, _locationS, _router, _navigationS, _sessionS) {
        this.route = route;
        this._cdRef = _cdRef;
        this._SearchesKeywordsServ = _SearchesKeywordsServ;
        this._reportS = _reportS;
        this._modalS = _modalS;
        this._locationS = _locationS;
        this._router = _router;
        this._navigationS = _navigationS;
        this._sessionS = _sessionS;
        this.isReport = false;
        this.isShared = false;
        this.minDate = null;
        this.maxDate = null;
        this.loading = true;
        this.updatingData = false;
        this.locations = [];
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        this.selectDate = {};
        this.searchKeywordsData = { graph: [], comparison: {} };
        this.searchImpressionsData = {};
        this.keywordsDatasource = new MatTableDataSource([]);
        this.displayedColumnsKeywords = ['keywords', 'impressions'];
        this.paginate = { size: 25, page: 1 };
        this.pagination = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.paginationMultiloc = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.dataRange = { value: null, displayName: 'None' };
        this.colors = COLORS_CHART;
        this.labelsGraph = [];
        this.sort = {
            sortBy: 'impressions',
            sortOrder: -1,
            sortDirection: 'desc'
        };
        this.sortMultiloc = {
            sortBy: 'locationName',
            direction: 'asc'
        };
        this.displayedColumnsMultLoc = ['location', 'Impressions', 'Keywords', 'Branded', 'Non-branded'];
        this.fieldsColumnsMultiLoc = [
            { displayName: 'locationName', fieldSort: 'locationName' },
            { displayName: 'totalImpressions', fieldSort: 'totalImpressions' },
            { displayName: 'totalKeywords', fieldSort: 'totalKeywords' },
            { displayName: 'brandedImpressions', fieldSort: 'brandedImpressions' },
            { displayName: 'nonBrandedImpressions', fieldSort: 'nonBrandedImpressions' }
        ];
        this.paginateMultiloc = { size: 10, page: 1 };
        this.isComparisonVisible = false;
        this.keywordSearchInput = new FormControl();
        this.locationIds = [];
        this.keywordSubject = new Subject();
        this.isProgressCSV = false;
        this.fieldsColumnsTopics = ['rank', 'details'];
        this.dataSourceTopics = new MatTableDataSource([]);
        this.topicsPaginate = { size: 25, page: 1 };
        this.topicPagination = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.tableLoading = true;
        this.visibleGraph = 'total-impressions';
        this.keywordsStats = {};
        this.impressionsStats = {};
        this.dataSet = [];
        this.hasError = false;
    }
    SearchKeywordsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!this.isReport) {
            this.accountId = this.route.parent.snapshot.params.accountId;
            this.locationId = this.route.parent.snapshot.params.locationId;
        }
        else {
            this.reportId = this.route.snapshot.params.id;
            if (((_b = (_a = this.report) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                this.locations = (_d = (_c = this.report) === null || _c === void 0 ? void 0 : _c.accounts[0]) === null || _d === void 0 ? void 0 : _d.locations;
            }
            this.dataRange = (((_e = this.report) === null || _e === void 0 ? void 0 : _e.compareToValue) && Object.keys((_f = this.report) === null || _f === void 0 ? void 0 : _f.compareToValue).length) ? (_g = this.report) === null || _g === void 0 ? void 0 : _g.compareToValue : this.dataRange;
            this.report.showComparison = this.report.showComparison || false;
            if (this.report.showComparison) {
                this.isComparisonVisible = true;
                this.displayedColumnsKeywords = ['keywords', 'impressions', 'differencePercentage'];
                this.sort = {
                    sortBy: (_h = this.dataRange) === null || _h === void 0 ? void 0 : _h.value,
                    sortOrder: -1,
                    sortDirection: 'desc'
                };
            }
            ;
        }
        // (ONLY WHEN DARK LAUNCH FLAG)
        // replace old datepicker functionality with new subscription to subject dateRangeSubject inside reports-filter-config.service 
        // all logic should be handled by the service (if possible)
        this.minDate = (_j = this.minDate) === null || _j === void 0 ? void 0 : _j.startOf('month');
        this.maxDate = (_k = this.maxDate) === null || _k === void 0 ? void 0 : _k.endOf('month');
        this.gid = this._sessionS.getSession().gid;
        this.location = [{ accountId: this.accountId, locationId: this.locationId }];
        this.selectDate = this.isReport ? { start: this.report.startDatetime, end: this.report.endDatetime } : this._locationS.buildDatepickerDate('keyword', this.maxDate, this.minDate);
        this.keywordSearchInput.valueChanges
            .subscribe(function (keyword) {
            _this.keywordSubject.next(keyword || '');
        });
        this.keywordSubject.pipe(debounceTime(650), switchMap(function (keyword) {
            var _a, _b, _c, _d;
            if (_this.keywordCurrentValue !== keyword) {
                _this.paginate.page = 1;
                _this.keywordCurrentValue = keyword;
                _this.paginatorChild.reset();
            }
            _this.updatingData = true;
            var startDate = _this.getDays().startDate;
            var endDate = _this.getDays().endDate;
            if (keyword && !_this.isReport) {
                return _this._SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, _this.paginate, startDate, endDate, (_a = _this.dataRange) === null || _a === void 0 ? void 0 : _a.value, [_this.locationId], _this.sort);
            }
            else if (keyword && _this.isReport) {
                return _this._SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, _this.paginate, startDate, endDate, (_b = _this.dataRange) === null || _b === void 0 ? void 0 : _b.value, _this.locationIds, _this.sort);
            }
            else if (!keyword && _this.isReport) {
                return _this._SearchesKeywordsServ.getSearchKeywords(_this.gid, _this.reportId, _this.locationId, _this.accountId, (_c = _this.dataRange) === null || _c === void 0 ? void 0 : _c.value, startDate, endDate, _this.sort, _this.paginate);
            }
            else if (!keyword && !_this.isReport) {
                return _this._SearchesKeywordsServ.getSearchKeywords(_this.gid, _this.reportId, _this.locationId, _this.accountId, (_d = _this.dataRange) === null || _d === void 0 ? void 0 : _d.value, startDate, endDate, _this.sort, _this.paginate);
            }
        }))
            .subscribe(function (result) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            _this.updatingData = false;
            if (_this.keywordSearchInput.value) {
                _this.keywordsDatasource = new MatTableDataSource((_a = result) === null || _a === void 0 ? void 0 : _a.data);
                _this.pagination.per_page = _this.paginate.size;
                _this.pagination.page = _this.paginate.page;
                _this.pagination.hasNext = (_b = result) === null || _b === void 0 ? void 0 : _b.hasNext;
                _this.pagination.hasPrev = (_c = result) === null || _c === void 0 ? void 0 : _c.hasPrev;
                _this.pagination.items = (_d = result) === null || _d === void 0 ? void 0 : _d.data;
                _this.pagination.pages = Math.ceil(((_e = result) === null || _e === void 0 ? void 0 : _e.total) / _this.pagination.per_page);
                _this.pagination.total = (_f = result) === null || _f === void 0 ? void 0 : _f.total;
            }
            else {
                _this.keywordsDatasource = new MatTableDataSource((_g = result) === null || _g === void 0 ? void 0 : _g.items);
                _this.pagination = {
                    per_page: (_h = result) === null || _h === void 0 ? void 0 : _h.per_page,
                    page: (_j = result) === null || _j === void 0 ? void 0 : _j.page,
                    hasNext: (_k = result) === null || _k === void 0 ? void 0 : _k.hasNext,
                    hasPrev: (_l = result) === null || _l === void 0 ? void 0 : _l.hasPrev,
                    pages: (_m = result) === null || _m === void 0 ? void 0 : _m.pages,
                    total: (_o = result) === null || _o === void 0 ? void 0 : _o.keywordsCount,
                    items: (_p = result) === null || _p === void 0 ? void 0 : _p.items
                };
            }
        }, function (err) {
            _this.updatingData = false;
            _this.keywordsDatasource = new MatTableDataSource([]);
        });
        if (this.isReport || this.isShared) {
            this.getReportData();
        }
        else {
            this.getData();
        }
    };
    SearchKeywordsComponent.prototype.getDays = function () {
        var _a, _b, _c, _d;
        return {
            startDate: ((_a = this.selectDate) === null || _a === void 0 ? void 0 : _a.start) ? moment((_b = this.selectDate) === null || _b === void 0 ? void 0 : _b.start).format('YYYY-MM-DD') : null,
            endDate: ((_c = this.selectDate) === null || _c === void 0 ? void 0 : _c.end) ? moment((_d = this.selectDate) === null || _d === void 0 ? void 0 : _d.end).format('YYYY-MM-DD') : null
        };
    };
    SearchKeywordsComponent.prototype.getReportData = function () {
        var _this = this;
        var _a, _b;
        if (this.hasError) {
            this.updatingData = false;
            return;
        }
        ;
        this.tableLoading = true;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        this.dataPicker.range = {
            start: startDate,
            end: endDate
        };
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        forkJoin([
            this._SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.value, startDate, endDate, this.sort, this.paginate),
            this._reportS.getSearchKeywordsBrands(null, null, this.gid, this.reportId, startDate, endDate, (_b = this.dataRange) === null || _b === void 0 ? void 0 : _b.value),
            this._reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc, this.sortMultiloc, this.dataPicker),
            this._SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, null, null)
        ]).subscribe(function (result) {
            var _a, _b;
            _this.hasError = false;
            _this.locationIds = (_a = result[0]) === null || _a === void 0 ? void 0 : _a.locationIds;
            _this.buildSearchData(result[0]);
            _this.buildGraphs(result[1]);
            _this.buildMultiLocTable((_b = result[2]) === null || _b === void 0 ? void 0 : _b.data);
            _this.buildTopics(result[3]);
            _this.visibleGraph = _this.graphIsVisible ? _this.visibleGraph : 'total-impressions';
            _this.setGraphData();
            _this.loading = false;
            _this.updatingData = false;
            _this.tableLoading = false;
            _this.detectChanges();
        }, function (_err) { return __awaiter(_this, void 0, void 0, function () {
            var msg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.hasError = true;
                        this.loading = false;
                        this.updatingData = false;
                        this.tableLoading = false;
                        msg = this.minDate && this.maxDate ?
                            "The data for the selected date range is unavailable on Google. Keyword data is currently available from " + this.minDate.format('YYYY-MM-DD') + " to " + this.maxDate.format('YYYY-MM-DD') + ". New data will be available after the 7th of each month." :
                            "The data for the selected date range is unavailable on Google.";
                        return [4 /*yield*/, this._modalS.openErrorModal('No Data Available', msg)];
                    case 1:
                        _a.sent();
                        this._router.navigate(['/keywords']);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SearchKeywordsComponent.prototype.getData = function () {
        var _this = this;
        var _a, _b, _c;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        console.log('this datarange.value', (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.value);
        forkJoin([
            this._SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, (_b = this.dataRange) === null || _b === void 0 ? void 0 : _b.value, startDate, endDate, this.sort, this.paginate),
            this._reportS.getSearchKeywordsBrands(this.accountId, this.locationId, this.gid, null, startDate, endDate, (_c = this.dataRange) === null || _c === void 0 ? void 0 : _c.value),
            this._SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, null, this.locationId, this.accountId)
        ]).subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
            var dateValidations, dates;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._locationS.getDateValidations('keyword', [this.accountId], [this.gid], [this.locationId])];
                    case 1:
                        dateValidations = _c.sent();
                        dates = this._locationS.dateValidation(dateValidations);
                        this.minDate = (_a = dates.minDate) === null || _a === void 0 ? void 0 : _a.startOf('month');
                        this.maxDate = (_b = dates.maxDate) === null || _b === void 0 ? void 0 : _b.endOf('month');
                        this.buildSearchData(result[0]);
                        this.buildGraphs(result[1]);
                        this.buildTopics(result[2]);
                        this.visibleGraph = this.graphIsVisible ? this.visibleGraph : 'total-impressions';
                        this.setGraphData();
                        this.loading = false;
                        this.updatingData = false;
                        this.detectChanges();
                        return [2 /*return*/];
                }
            });
        }); }, function (err) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = false;
                        this.updatingData = false;
                        console.error(err);
                        return [4 /*yield*/, this._modalS.openErrorLoadingModal(3)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._navigationS.signOut()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SearchKeywordsComponent.prototype.getKeywordsTable = function () {
        var _this = this;
        var _a;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        this._SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.value, startDate, endDate, this.sort, this.paginate).subscribe(function (result) {
            _this.buildSearchData(result);
            _this.updatingData = false;
            _this.detectChanges();
        }, function (err) {
            _this.updatingData = false;
        });
    };
    SearchKeywordsComponent.prototype.buildGraphs = function (results) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        this.labelsGraph = (_b = (_a = results) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.labels;
        this.searchImpressionsData = (_e = (_d = (_c = results) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.comparison) === null || _e === void 0 ? void 0 : _e.impressions;
        this.impressionsStats = (_g = (_f = results) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.impressions;
        this.searchKeywordsData.graph = (_k = (_j = (_h = results) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.comparison) === null || _k === void 0 ? void 0 : _k.keywords;
        this.keywordsStats = (_m = (_l = results) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.keywords;
        this.setGraphData();
    };
    SearchKeywordsComponent.prototype.setGraphData = function () {
        this.dataSet = this.visibleGraph === 'total-impressions' ? this.impressionsStats : this.keywordsStats;
    };
    SearchKeywordsComponent.prototype.getMultilocTable = function () {
        var _this = this;
        this.updatingData = true;
        this.tableLoading = true;
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        this.dataPicker.range = {
            start: this.getDays().startDate,
            end: this.getDays().endDate
        };
        this._reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc, this.sortMultiloc, this.dataPicker).subscribe(function (res) {
            var _a;
            _this.buildMultiLocTable((_a = res) === null || _a === void 0 ? void 0 : _a.data);
            _this.tableLoading = false;
            _this.updatingData = false;
        }, function (err) { return _this.updatingData = false; });
    };
    SearchKeywordsComponent.prototype.getTopicsTable = function () {
        var _this = this;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        this._SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, this.locationId, this.accountId).subscribe(function (res) {
            _this.updatingData = false;
            _this.buildTopics(res);
        }, function (err) {
            _this.updatingData = false;
        });
    };
    SearchKeywordsComponent.prototype.buildSearchData = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        this.searchKeywordsData.comparison = (_b = (_a = data) === null || _a === void 0 ? void 0 : _a.comparison) === null || _b === void 0 ? void 0 : _b.keywords;
        this.keywordsDatasource = new MatTableDataSource((_c = data) === null || _c === void 0 ? void 0 : _c.items);
        this.pagination = {
            per_page: (_d = data) === null || _d === void 0 ? void 0 : _d.per_page,
            page: (_e = data) === null || _e === void 0 ? void 0 : _e.page,
            hasNext: (_f = data) === null || _f === void 0 ? void 0 : _f.hasNext,
            hasPrev: (_g = data) === null || _g === void 0 ? void 0 : _g.hasPrev,
            pages: (_h = data) === null || _h === void 0 ? void 0 : _h.pages,
            total: (_k = (_j = data) === null || _j === void 0 ? void 0 : _j.comparison.keywords) === null || _k === void 0 ? void 0 : _k.actual,
            items: (_l = data) === null || _l === void 0 ? void 0 : _l.items
        };
    };
    SearchKeywordsComponent.prototype.buildCardsData = function (data, start, end) {
        this.selectDate = {
            start: moment(start),
            end: moment(end)
        };
    };
    SearchKeywordsComponent.prototype.buildMultiLocTable = function (data) {
        var _a;
        // data.items.forEach(i => i.estTotalimpressions = `${new Intl.NumberFormat().format(i?.totalImpressionsLow || 0)} - ${new Intl.NumberFormat().format(i?.totalImpressionsHigh || 0)}`);
        this.dataSourceMultiLoc = new MatTableDataSource((_a = data) === null || _a === void 0 ? void 0 : _a.items);
        this.paginationMultiloc = {
            items: data['items'],
            page: data['page'],
            pages: data['totalPages'],
            per_page: data['pageSize'],
            total: data['total'],
            hasNext: data['hasNext'],
            hasPrev: data['hasPrev']
        };
    };
    SearchKeywordsComponent.prototype.buildTopics = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        this.dataSourceTopics = new MatTableDataSource((_b = (_a = data) === null || _a === void 0 ? void 0 : _a.keyword_trends) === null || _b === void 0 ? void 0 : _b.items);
        this.topicPagination = {
            per_page: (_d = (_c = data) === null || _c === void 0 ? void 0 : _c.keyword_trends) === null || _d === void 0 ? void 0 : _d.per_page,
            page: (_f = (_e = data) === null || _e === void 0 ? void 0 : _e.keyword_trends) === null || _f === void 0 ? void 0 : _f.page,
            hasNext: (_h = (_g = data) === null || _g === void 0 ? void 0 : _g.keyword_trends) === null || _h === void 0 ? void 0 : _h.hasNext,
            hasPrev: (_k = (_j = data) === null || _j === void 0 ? void 0 : _j.keyword_trends) === null || _k === void 0 ? void 0 : _k.hasPrev,
            pages: (_m = (_l = data) === null || _l === void 0 ? void 0 : _l.keyword_trends) === null || _m === void 0 ? void 0 : _m.pages,
            total: (_p = (_o = data) === null || _o === void 0 ? void 0 : _o.keyword_trends) === null || _p === void 0 ? void 0 : _p.total,
            items: (_r = (_q = data) === null || _q === void 0 ? void 0 : _q.keyword_trends) === null || _r === void 0 ? void 0 : _r.items
        };
    };
    SearchKeywordsComponent.prototype.topicsHandleReload = function (event) {
        this.updatingData = true;
        this.topicsPaginate = event;
        this.getTopicsTable();
    };
    SearchKeywordsComponent.prototype.handleExportCsvTopic = function (item) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isProgressCSV = true;
                        startDate = this.getDays().startDate;
                        endDate = this.getDays().endDate;
                        return [4 /*yield*/, this._SearchesKeywordsServ.handleExportCsvTopic(this.gid, this.reportId, this.accountId, this.locationId, startDate, endDate, (_a = item) === null || _a === void 0 ? void 0 : _a.topics)];
                    case 1:
                        _b.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.handleExportCsvKeywordsSearch = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate, locations;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isProgressCSV = true;
                        startDate = this.getDays().startDate;
                        endDate = this.getDays().endDate;
                        if (this.locationIds.length > 0) {
                            locations = this.locationIds;
                        }
                        else {
                            locations = [this.locationId];
                        }
                        return [4 /*yield*/, this._SearchesKeywordsServ.handleExportCsvKeywordsSearch(startDate, endDate, locations, this.keywordSearchInput.value, (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.value, this.sort)];
                    case 1:
                        _b.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.handleReload = function (event) {
        this.paginate = event;
        if (this.keywordSearchInput.value) {
            this.keywordSearchInput.setValue(this.keywordSearchInput.value);
        }
        else {
            this.updatingData = true;
            if (this.isReport || this.isShared) {
                this.getReportData();
            }
            else {
                this.getData();
            }
        }
    };
    SearchKeywordsComponent.prototype.sortChanged = function (event, onlyTable) {
        if (onlyTable === void 0) { onlyTable = false; }
        this.updatingData = true;
        this.sort = {
            sortBy: event.active === 'impressions' ? event.active : this.dataRange.value,
            sortOrder: event.direction === 'asc' ? 1 : -1,
            sortDirection: event.direction
        };
        this.paginate = {
            page: 1,
            size: this.paginate.size
        };
        if (this.keywordSearchInput.value) {
            this.keywordSearchInput.setValue(this.keywordSearchInput.value);
        }
        else {
            this.updatingData = true;
            if (this.isReport || this.isShared) {
                this.getReportData();
            }
            else {
                this.getData();
            }
        }
    };
    SearchKeywordsComponent.prototype.handleDataPicker = function (event) {
        this.updatingData = true;
        this.selectDate.start = event.range.start;
        this.selectDate.end = event.range.end;
        if (this.isReport || this.isShared) {
            this.getReportData();
        }
        else { // not report
            this.getData();
        }
    };
    SearchKeywordsComponent.prototype.handleSelectRange = function (event) {
        if (this.dataRange.value == event.value) {
            return;
        }
        this.dataRange = event;
        var sort = {
            active: this.isComparisonVisible ? 'differencePercentage' : 'impressions',
            direction: 'desc'
        };
        this.sortChanged(sort);
    };
    SearchKeywordsComponent.prototype.handleMultilocSort = function (event) {
        var _a, _b;
        this.updatingData = true;
        this.paginateMultiloc = { size: this.paginateMultiloc.size, page: 1 };
        this.sortMultiloc = {
            sortBy: (_a = event) === null || _a === void 0 ? void 0 : _a.active.replace('_', ''),
            direction: (_b = event) === null || _b === void 0 ? void 0 : _b.direction
        } || null; // FIXME: What was the intention of this? Will never be null
        this.getMultilocTable();
    };
    SearchKeywordsComponent.prototype.handleMultilocPaginate = function (event) {
        this.updatingData = true;
        this.paginateMultiloc = event;
        this.getMultilocTable();
    };
    SearchKeywordsComponent.prototype.handleExport = function ($event) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate, period;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.isProgressCSV = true;
                        startDate = this.getDays().startDate;
                        endDate = this.getDays().endDate;
                        period = !this.isComparisonVisible ? null : (_b = (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.displayName) === null || _b === void 0 ? void 0 : _b.split(' ')[1];
                        return [4 /*yield*/, this._reportS.handleExportCsvMongo(this.reportId, this.gid, this.accountId, this.locationId, startDate, endDate, '', "keywords", period)];
                    case 1:
                        _c.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.getImpressionsValue = function (numberOfImpressions) {
        return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
    };
    SearchKeywordsComponent.prototype.changedViewComparison = function (event) {
        this.isComparisonVisible = event;
        this.displayedColumnsKeywords = this.isComparisonVisible ? ['keywords', 'impressions', 'differencePercentage'] : ['keywords', 'impressions'];
        var sort = {
            active: this.isComparisonVisible ? 'differencePercentage' : 'impressions',
            direction: 'desc'
        };
    };
    SearchKeywordsComponent.prototype.getComparisonBoxClass = function (field, row) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var percentage;
        switch (field) {
            case 'keywords':
                percentage = (_b = (_a = this.searchKeywordsData) === null || _a === void 0 ? void 0 : _a.comparison) === null || _b === void 0 ? void 0 : _b.trend;
                break;
            case 'impressions':
                percentage = (_d = (_c = this.searchImpressionsData) === null || _c === void 0 ? void 0 : _c.trend) === null || _d === void 0 ? void 0 : _d.total;
                break;
            case 'branded':
                percentage = (_f = (_e = this.searchImpressionsData) === null || _e === void 0 ? void 0 : _e.trend) === null || _f === void 0 ? void 0 : _f.direct;
                break;
            case 'non-branded':
                percentage = (_h = (_g = this.searchImpressionsData) === null || _g === void 0 ? void 0 : _g.trend) === null || _h === void 0 ? void 0 : _h.discovery;
                break;
            case 'keywords-table':
                percentage = (_k = (_j = row) === null || _j === void 0 ? void 0 : _j.prevComparison) === null || _k === void 0 ? void 0 : _k.percentage;
                break;
        }
        if (percentage > 0) {
            return 'chip--trend--success';
        }
        else if (percentage < 0) {
            return 'chip--trend--fail';
        }
        else {
            return 'chip--trend--info';
        }
    };
    SearchKeywordsComponent.prototype.changedVisibleGrapg = function (event) {
        this.visibleGraph = event.value;
        this.setGraphData();
    };
    SearchKeywordsComponent.prototype.detectChanges = function () {
        if (this._cdRef !== null && this._cdRef !== undefined && !this._cdRef.destroyed) {
            this._cdRef.detectChanges();
        }
    };
    SearchKeywordsComponent.prototype.getChipValue = function (value) {
        return (value === 0 ? '-' :
            value > 1000 ? 1000 :
                value);
    };
    Object.defineProperty(SearchKeywordsComponent.prototype, "graphIsVisible", {
        get: function () {
            var start = typeof (this.selectDate.start) === 'string' ? moment(this.selectDate.start) : this.selectDate.start;
            var end = typeof (this.selectDate.end) === 'string' ? moment(this.selectDate.end) : this.selectDate.end;
            var diff = end.diff(start, 'months');
            return diff !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchKeywordsComponent.prototype, "currentVisibleGraph", {
        get: function () {
            return this.visibleGraph.replace('-', ' ');
        },
        enumerable: true,
        configurable: true
    });
    return SearchKeywordsComponent;
}());
export { SearchKeywordsComponent };
