import { __awaiter, __generator } from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient /*, HttpParams*/ } from '@angular/common/http';
import { GROUPS, SEARCH_INDEX } from '../constants/firestore/collections';
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
// TODO: Inconsistent API naming on backend, solve
var groupURL = function (gid) { return ENV.apiUrl + "/v2/groups/" + gid; };
var ɵ0 = groupURL;
var groupURL2 = function (gid) { return ENV.apiUrl + "/v2/groups/gid/" + gid; };
var ɵ1 = groupURL2;
var GroupService = /** @class */ (function () {
    // private groupRef = this.afs.collection(GROUPS);
    function GroupService(_afs, _http) {
        this._afs = _afs;
        this._http = _http;
    }
    GroupService.prototype.updateGroup = function (gid, changes) {
        return this._http.put(groupURL(gid), changes).toPromise();
    };
    // TODO: Unused, remove
    // 
    // searchIndex(gid): Observable<Group> {
    //   return this.afs.collection<Group>(GROUPS).doc<Group>(gid).valueChanges();
    // }
    GroupService.prototype.fetchGroup = function (gid) {
        return this._http.get(groupURL(gid)).toPromise();
    };
    GroupService.prototype.getIndexes = function (gid) {
        return this._afs.collection(GROUPS + "/" + gid + "/" + SEARCH_INDEX).valueChanges();
    };
    GroupService.prototype.getReportIndexes = function (gid, type) {
        return this._afs.collection(GROUPS + "/" + gid + "/" + SEARCH_INDEX, function (rf) { return rf.where('type', '==', 'report').where('reportType', '==', type); }).valueChanges();
    };
    // TODO: Unused, remove
    // getLocationIndexes(docId: string) {
    //   return this.afs.collection(`${GROUPS}/${docId}/${SEARCH_INDEX}`,
    //     rf => rf.where('type', '==', 'location')
    //   ).valueChanges();
    // }
    // TODO: Unused, remove?
    // 
    //   getGroupByDomain(domain: string) {
    //     return this.afs.collection(GROUPS,
    //       rf => rf.where('domain', '==', domain)
    //     ).get();
    //   }
    // 
    //   refreshIndexes(enqueue: string): Observable<any> {
    //     let params = new HttpParams();
    //     if (enqueue && ENV.queuesEnabled) {
    //       params = params.append('enqueue', enqueue);
    //     }
    // 
    //     return this.http.get<any>(`${ENV.apiUrl}/v2/groups/refresh/indexes`, { params });
    //   }
    // 
    //   async groupsByUser(list) {
    //     await Promise.all(list.map(
    //       async (user) => {
    //         if (user.gid) {
    //           const group = this.getByDocId(user.gid);
    //           const asyncData = await Promise.all([group]);
    //           if (asyncData[0]) {
    //             await new Promise(resolve =>
    //               asyncData[0]
    //                 .subscribe(g => {
    //                   user.locationsCount = _.get(g, 'totalLocationsCount', 0);
    //                   resolve(g);
    //                 }));
    //           }
    //         } else {
    //           return;
    //         }
    //       }
    //     ));
    //   }
    GroupService.prototype.get_accounts = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.get(groupURL2(gid) + '/get_accounts').toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    // TODO: Probably can be replaced by locationService.fetchLocationsByAccount, duplicate backend endpoints?
    GroupService.prototype.get_locations = function (gid, accountId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.get(groupURL2(gid) + ("/account/" + accountId + "/get_locations")).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data || []];
                }
            });
        });
    };
    GroupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GroupService_Factory() { return new GroupService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: GroupService, providedIn: "root" });
    return GroupService;
}());
export { GroupService };
export { ɵ0, ɵ1 };
