import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../../services/location.service';
import { FoodMenu, defaultMenu } from '../../constants/google/menuList';
import { GoogleService } from '../../services/google.service';
import { MenuLocationComponent } from '../dashboard-location/menu-location/menu-location.component';
import { SnackbarService } from '../../services/snackbar.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit {
  
  progress: boolean = false;
  accountId: string = '';
  locationId: string = '';
  isUpdatedMenu: boolean = false;
  foodMenu: FoodMenu[] = []
  @ViewChild('MenuLocation', {static: false}) menuComponent: MenuLocationComponent;

  constructor(
    private activateRoute: ActivatedRoute, 
    private _sessionS: SessionService, 
    private snackS: SnackbarService, 
    private googleService: GoogleService, 
    private locationS: LocationService) { }

  ngOnInit() {
    this.locationId = this.activateRoute.snapshot.parent.params.locationId;
    this.accountId  = this.activateRoute.snapshot.parent.params.accountId;
    this.locationS.getRef(this._sessionS.getSession().gid, this.accountId, this.locationId).subscribe(result => {
      if (result === undefined) return;
      if (result.menuList && result.menuList.length > 0) {
        this.foodMenu = Object.assign([], result.menuList);
      }
      else this.foodMenu = Object.assign([], defaultMenu);
    })
  }

  dispatchAction(action: string) {
    if (action === 'updateMenu') {
      this.isUpdatedMenu = true;
      this.foodMenu = this.menuComponent.MenuList;
      this.googleService.updateFoodMenu(this.accountId, this.locationId, {menuList: this.foodMenu}).toPromise().then( m => {
        if (m.length > 0) {
          this.snackS.openSuccess('The menu has been updated successfully', 2000);
        } else {
          this.snackS.openError('An error has occurred with google', 2000);
        }
        this.isUpdatedMenu = false;
      }).catch( err => {
        this.isUpdatedMenu = false;
      })
    }
  }

  updateMenu(menu: FoodMenu[]) {
    this.foodMenu = menu;
  }

}
