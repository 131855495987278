// See https://docs.datadoghq.com/real_user_monitoring/browser/#typescript
import '@datadog/browser-rum/bundle/datadog-rum'
import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// app
import { AppModule } from './app/app.module';
import { environment } from '@environment';
// import * as VERSION   from './assets/version.json';

if (environment.production) {
  enableProdMode();
}

// enforced | informational
// https://developers.google.com/identity/gsi/web/guides/migration#identifying_affected_code_and_testing
// document.cookie = "G_AUTH2_MIGRATION=informational; expires=Sun, 1 Jan 2024 00:00:00 UTC; SameSite=None; Secure; path=/"

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (environment.env == 'prod') {
  // TODO: This is also called from index.html
  // @ts-ignore: TS2339
  window.DD_RUM.init({
    env: 'production',
    clientToken: 'pubd2fb0d8206d05a9ad5110272ea710668',
    applicationId: '9f685936-f530-48a4-9b57-9da85cc0f74b',
    site: 'datadoghq.com',
    service: 'map-labs',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  
  // @ts-ignore: TS2339
  window.DD_RUM.startSessionReplayRecording();
}

console.log('Active Configuration:', environment)
// console.log('Main-Frontend VERSION:', VERSION)
