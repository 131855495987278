// dep
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

// app
import {VERIFICATION_EMAIL} from "../constants/firestore/collections";

@Injectable({
  providedIn: "root"
})
export class VerificationEmailService {
  
  constructor(
    private afs: AngularFirestore
  ) {
  } 

  // TODO: Unused, remove
  /**
   * this method save in firestore entity Verification Email
   * @param string 
   */
  // save(uid: string, gid: string, hash: any) {
  //   return this.afs.collection(VERIFICATION_EMAIL).add({'key1': uid, 'key2':gid, 'hash': hash});
  // }

  update(id: string, data: object) : void {
    this.afs.collection(VERIFICATION_EMAIL).doc(id).set({...data}, {merge: true})
  }

  /**
   * Get user with hash doc id to verify account
   */
  getVerificationToHash(hash: string) {
    return this.afs.collection(VERIFICATION_EMAIL, rf => rf.where('hash', '==', hash)).get();
  } 
  
  /**
   * Get user with gid and uid
   */
  getVerification(uid: string, gid: string) {
    return this.afs.collection(VERIFICATION_EMAIL, rf => rf.where('key1', '==', uid).where('key2', '==', gid)).get();
  } 
}
