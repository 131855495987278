// dep
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';

// app
import { REGEX_24_HOURS, REGEX_URL } from '../../constants/regex';
import { DatesService, JsonTime } from '../../services/dates.service';
import { FormInputUploadComponent } from '../form-input-upload/form-input-upload.component';
import { LocalPost, BulkPost } from 'src/app/v3.models/posts.models';
import { Recurring } from '../../v3.models/posts.models';
import { SchedulePost } from '../../constants/post';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-form-post',
  templateUrl: './form-post.component.html',
  styleUrls: ['./form-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPostComponent extends BaseComponent implements OnInit, OnChanges {
  date: Date;
  @Input() placeId;
  @Input() postType: string;
  @Input() post: LocalPost | BulkPost;
  @Input() touched: boolean;
  @Input() savedData;
  @Input() isBulk = false;
  @Input() isEditing = false;
  @Input() recurring: Recurring = null;
  @Output() recurringChange = new EventEmitter<Recurring>();
  @Input() publishRecurringNow = false;
  @Output() publishRecurringNowChange = new EventEmitter<Recurring>();
  @Output() isRecurringValid = new EventEmitter<Recurring>();
  @Input() scheduled: SchedulePost = null;
  @Input() planningDates$: Observable<{start: Date, end: Date}[]> = of([]);
  @Output() isValid = new EventEmitter();
  @Output() data = new EventEmitter();
  @Output() handleIsRecurring = new EventEmitter();
  @ViewChild('formElement', {static: true}) formElement;
  @ViewChild('inputUpload', {static: false}) inputUpload: FormInputUploadComponent;

  postDateAvaible = false;
  postTypeName: string;

  // TODO: Refactor against src/app/constants/post.ts 
  placeHolders: {key: string, value: string}[] = [
    {key: '%websiteUrl%', value: 'Website URL'},
    {key: '%url_menu%', value: 'Menu URL'},
    {key: '%url_order_ahead%', value: 'Order Ahead URL'},
    {key: '%url_covid_19_info_page%', value: 'COVID-19 URL'},
    {key: '%url_facility_telemedicine_page%', value: 'Virtual Care URL'},
    {key: '%url_appointment%', value: 'Appointment URL'},
    {key: '%url_reservations%', value: 'Reservations URL'},
  ];
  imageRequirements = {
    type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
    min_width: 400,
    min_height: 300,
    min_size: 10240,
    max_size: 5000000
  };
  percentUpload: number;
  form: FormGroup;
  imageUrl: string[] = [];
  imageSelectedUrl: string = null;
  ref: AngularFireStorageReference;
  types: { [key: string]: string } = {
    BOOK: 'Book',
    ORDER: 'Order Online',
    LEARN_MORE: 'Learn More',
    SIGN_UP: 'Sign Up',
    SHOP: 'Buy',
    CALL: 'Call Now'
  };
  timeChecked = false;
  index_focus_out = '';

  // LIST POST TYPE
  listPostType = {
    STANDARD: 'Standard',
    EVENT: 'Event',
    OFFER: 'Offer',
    ALERT: 'Alert',
  };

  isDisabledTime = false;

  constructor(
    private fb: FormBuilder,
    private dateS: DatesService,
    private _sessionS : SessionService
  ) {
    super();
    this.date = new Date();
  }

  ngOnInit(): void {
    this.postTypeName = this.listPostType[this.postType];
    this.postDateAvaible = (this.postTypeName === 'Event' || this.postTypeName === 'Offer')

    if (!this.placeId) {
      // FIXME: gid as a placeId? This has any sense?
      this.placeId = this._sessionS.getSession().gid;
    }

    if (this.scheduled) {
      this.date = moment(this.scheduled.date).local().toDate();
    }

    this._subscribeSafe(this.planningDates$,
     planning => {
      if (planning.length > 0) {
        const startDate = planning[0].start;
        const endDate = planning[0].end;
        this.startDate?.setValue(startDate);
        this.endDate?.setValue(endDate);
        if (this.recurring?.eventTimeEnd || this.recurring?.eventTimeStart) {
          const startTime = this.recurring?.eventTimeStart || `${startDate?.getHours()}:${startDate?.getMinutes()}`;
          const endTime = this.recurring?.eventTimeEnd || `${endDate?.getHours()}:${endDate?.getMinutes()}`;
          this.startTime?.setValue(startTime);
          this.endTime?.setValue(endTime);
        }
        this.form?.updateValueAndValidity();
      }
    });

    this.initForm();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.touched?.firstChange) {
      return
    }

    if (changes.scheduled || changes.recurring) {
      if (this.scheduled) {
        this.date = moment(this.scheduled.date).local().toDate();
      } else {
        this.date = new Date();
      }
      if (this.postType !== 'STANDARD' && this.postType !== 'ALERT' && !this.recurring) {
        this.endDate.setValidators([Validators.required, this.valitadeSameDate()]);
        this.endDate.updateValueAndValidity();
        this.validateEqualDate();
      } else if (this.postType !== 'STANDARD' && this.postType !== 'ALERT' && this.recurring) {
        this.endDate.setValidators([Validators.required]);
        this.endDate.updateValueAndValidity();
      }
    }


    if (changes.touched && !changes.touched.firstChange) {
      this.touched = changes.touched.currentValue;
      if (this.touched) {
        this.form.markAllAsTouched();
      } else if (this.touched === false) {
        this.form.markAsUntouched();
      }
    }
  }

  handleUrl($event: any, added = false): void {

    if (!$event) return;
    const url = $event.url ? $event.url : $event;

    if (this.imageSelectedUrl) {
      const indexImage = this.imageUrl.findIndex(i => i === this.imageSelectedUrl);
      this.imageUrl[indexImage] = url;
      this.imageSelectedUrl = url;
      this.image.at(indexImage).setValue(url);
    } else {
      const imageExist = this.imageUrl.find(i => i === url);
      if (!imageExist) {
        const index = this.imageUrl.length > 0 ? this.imageUrl.length : 0;
        this.imageUrl.push(url);
        if (!added) {
          this.imageSelectedUrl = this.imageUrl[index];
        }
        this.image.push(new FormControl(url));
      }
    }
  }

  isRecurringEventOrOffer(){
    return !!((this.postTypeName === 'Event'|| this.postTypeName === 'Offer') && 
              this.recurring?.frecuency)
  }

  selectImage(i: number): void {
    this.imageSelectedUrl = this.imageUrl[i];
  }

  clearImagen(): void {
    this.inputUpload.reset();
    const imageIndex = this.imageUrl.findIndex(i => i === this.imageSelectedUrl);
    this.imageUrl = this.imageUrl.filter( image => image !== this.imageSelectedUrl);
    this.image.removeAt(imageIndex);
    if (this.imageUrl.length > 0) 
      this.imageSelectedUrl = this.imageUrl[this.imageUrl.length - 1];
    else 
      this.imageSelectedUrl = null;
    this.handleUrl(null);
  }

  addNewImage(): void {
    this.imageSelectedUrl = null;
  }

  handlePercent($event: number): void {
    this.percentUpload = $event;
  }

  handleRef($event: AngularFireStorageReference): void {
    this.ref = $event;
  }

  initForm(): void {
    this.form = this.fb.group({
      description: ['', Validators.required],
      offer: this.fb.group({})
    });

    if (this.postType !== 'ALERT') {
      this.form.addControl('image', new FormArray([]));
    }

    if (this.postType !== 'OFFER') {
      this.form.addControl('type', new FormControl(''));
      this.form.addControl('url', new FormControl(''));
      if (this.post) {
        this.type.setValue(this.post.postInfo.callToAction?.actionType || '');
        this.url.setValue( this.post.postInfo.callToAction?.url || '');
      } else {
        this.type.setValue(this.savedData?.type || '');
        this.url.setValue(this.savedData?.url || '');
      }
    }

    if (this.postType !== 'STANDARD' && this.postType !== 'ALERT') {
      this.form.addControl('title', new FormControl('', Validators.required));
      this.form.addControl('startDate', new FormControl('', Validators.required));
      this.form.addControl('endDate', new FormControl(this.nowDate.setHours(0, 0, 0, 0), [Validators.required]));
      if (!this.recurring) {
        this.endDate.setValidators([Validators.required, this.valitadeSameDate()]);
      }
      this.form.addControl('startTime', new FormControl('', []));
      this.form.addControl('endTime', new FormControl('', []));
      if (this.post) {
        this.title.setValue(this.post.postInfo.event && this.post.postInfo.event.title && this.post.postInfo.event.title || '');
        if (this.post.postInfo?.event?.schedule?.startDate) {
          this.startDate.setValue(this.dateS.dateJsonToDate(this.post.postInfo.event.schedule.startDate));
        }
        if (this.post.postInfo?.event?.schedule?.endDate) {
          this.endDate.setValue(this.dateS.dateJsonToDate(this.post.postInfo.event.schedule.endDate));
        }
        if (this.post.postInfo?.event?.schedule?.startTime) {
          this.startTime.setValue(this.dateS.jsonTimeToTime(this.post.postInfo.event.schedule.startTime as JsonTime));
        }
        if (this.post.postInfo?.event?.schedule?.endTime) {
          this.endTime.setValue(this.dateS.jsonTimeToTime(this.post.postInfo.event.schedule.endTime as JsonTime));
        }
        this.timeChecked = !!(this.post.postInfo.event.schedule.startTime && this.post.postInfo.event.schedule.endTime);
      } else {
        this.title.setValue(this.savedData && this.savedData.title || '');
        if (this.savedData?.startDate) this.startDate.setValue(this.savedData.startDate);
        if (this.savedData?.endDate) this.endDate.setValue(this.savedData.endDate);
        if (this.savedData?.startTime) this.startTime.setValue(this.savedData.startTime);
        if (this.savedData?.endTime) this.endTime.setValue(this.savedData.endTime);
        this.timeChecked = !!(this.savedData?.startTime || this.savedData?.endTime);
      }
    }

    if (this.postType === 'OFFER') {
      this.offer.addControl('couponCode', new FormControl('', []));
      this.offer.addControl('redeemOnlineUrl', new FormControl('', [Validators.pattern(REGEX_URL)]));
      this.offer.addControl('termsConditions', new FormControl('', []));
      if (this.post) {
        this.couponCode.setValue(this.post.postInfo.offer && this.post.postInfo.offer.couponCode || '');
        this.redeemOnlineUrl.setValue(this.post.postInfo.offer && this.post.postInfo.offer.redeemOnlineUrl || '');
        this.termsConditions.setValue(this.post.postInfo.offer && this.post.postInfo.offer.termsConditions || '');
      } else {
        this.couponCode.setValue(this.savedData && this.savedData.offer && this.savedData.offer.couponCode || '');
        this.redeemOnlineUrl.setValue(this.savedData && this.savedData.offer && this.savedData.offer.redeemOnlineUrl || '');
        this.termsConditions.setValue(this.savedData && this.savedData.offer && this.savedData.offer.termsConditions || '');
      }
    }

    if (this.post) {
      this.description.setValue(this.post.postInfo.summary);
      // tslint:disable-next-line:max-line-length
      if (this.postType !== 'ALERT') {
        if (this.post.postInfo.media && this.post.postInfo.media.length > 0) {
          for (const media of this.post.postInfo.media) {
            const component = {
              url: media.sourceUrl ? media.sourceUrl : media.googleUrl ? media.googleUrl : null,
              preview: null,
              mediaFormat: 'PHOTO'
            };
            this.handleUrl(component, true);
          }
          const imagePost = this.post.postInfo.media[this.post.postInfo.media.length - 1];
          this.imageSelectedUrl = imagePost.sourceUrl ? imagePost.sourceUrl : imagePost.googleUrl ? imagePost.googleUrl : null;
        }
      }

      this.data.emit(this.form);
      this.isValid.emit(this.form.valid);

    } else {
      this.description.setValue(this.savedData && this.savedData.description || '');
      if (this.postType !== 'ALERT' && this.savedData?.image) this.handleUrl(this.savedData.image[0] || null);
    }

    this._subscribeSafe(this.form.valueChanges, () => {
      if (this.postType !== 'STANDARD' && this.postType !== 'ALERT') {
        const momentStartDate = moment(this.startDate.value);
        const momentEndDate = moment(this.endDate.value);
        const isSame = momentEndDate.isSame(momentStartDate, 'date');
        this.validateGreaterThanEnd();

        const isEndDateVoid = typeof(this.form.controls.endDate.value) != "number";
        if (isEndDateVoid && isSame) {
          if (!this.timeChecked){
            this.handleCheckTime({checked: true});
          }
          this.isDisabledTime = true;
        }
        if (!isSame) {
          this.isDisabledTime = false;
        }
      }

      this.data.emit(this.form);
      this.isValid.emit(this.form.valid);
    });


    if (this.touched) {
      this.form.markAllAsTouched();
    }

  }


  getData(): FormGroup {
    return this.form;
  }



  handleCheckTime($event): void {
    const momentStartDate = moment(this.startDate.value);
    const momentEndDate = moment(this.endDate.value);
    const isSame = momentEndDate.isSame(momentStartDate, 'date');
    if (!$event.checked && isSame) return;

    this.timeChecked = $event.checked;
    if ($event.checked) {
      this.startTime.setValidators([Validators.required, Validators.pattern(REGEX_24_HOURS)]);
      this.endTime.setValidators([Validators.required, Validators.pattern(REGEX_24_HOURS)]);
      if (isSame) {
        this.endTime.setValidators([Validators.required, Validators.pattern(REGEX_24_HOURS), this.valitadeGreaterTime()]);
        this.startTime.setValue('00:01');
        this.endTime.setValue('23:59');
      }
    } else {
      this.startTime.setValidators([Validators.pattern(REGEX_24_HOURS)]);
      this.endTime.setValidators([Validators.pattern(REGEX_24_HOURS)]);
      this.startTime.setValue('');
      this.endTime.setValue('');
    }

    this.startTime.updateValueAndValidity();
    this.endTime.updateValueAndValidity();

    this._subscribeSafe(this.form.valueChanges, () => {
      this.data.emit(this.form);
      this.isValid.emit(this.form.valid);
    });
  }

  valitadeGreaterTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateClean = new Date();
      const time = this.startTime.value.toString().split(':');
      if (!control.value) 
        return {minorDate: true};

      dateClean.setHours(time[0], time[1], 0, 0);
      const value = control.value.split(':');

      const endDate = new Date();
      endDate.setHours(value[0], value[1], 0, 0);

      if (dateClean.getTime() >= endDate.getTime()) {
        return {minorDate: true};
      }

      return null;
    };
  }

  validateGreaterThanEnd(): void {
    this.startDate.setErrors(null);
    
    if (this.startDate.value > this.endDate.value) {
      this.startDate.setErrors({ beforeEnd: true });
    }

    if (!this.startDate.value) {
      this.startDate.setErrors({ required: true })
    }
  }

  addPlaceHolder(placeHolder: string): void {
    this.url.setValue(`${placeHolder}`);
  }

  get nowDate(): Date {
    return new Date()
  }


  validateEqualDate(): void {
    const dateClean = this.date;
    dateClean.setHours(0, 0 , 0, 0);
    const endDate = new Date(this.endDate.value);
    endDate.setHours(0, 0, 0, 0);
    if (dateClean.getTime() === endDate.getTime()) {
      const endTime = this.endTime;
      const normalDate = new Date();
      if (!endTime) {
        this.endDate.setErrors({sameDate: true});
        return;
      }
      if (endTime.value === '') {
        this.endDate.setErrors({sameDate: true});
        return;
      }
      if (endTime.errors) {
        this.endDate.setErrors({sameDate: true});
        return;
      }

      const time = endTime.value.split(':');
      endDate.setHours(time[0], time[1], 0, 0);
      if (normalDate.getTime() >= endDate.getTime()) {
        this.endDate.setErrors({sameDate: true});
        return;
      }
    } else if (dateClean.getTime() > endDate.getTime()) {
      this.endDate.setErrors({sameDate: true});
      return;
    }
    this.endDate.setErrors(null);
    this.endDate.updateValueAndValidity();
  }

  valitadeSameDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateClean = new Date();
      dateClean.setHours(0, 0, 0, 0);
      const value = control.value;
      if (!value) return null;

      const endDate = new Date(value);
      endDate.setHours(0, 0, 0, 0);

      if (dateClean.getTime() === endDate.getTime()) {
        const endTime = this.endTime;
        const normalDate = new Date();
        if (!endTime) return {sameDate: true};
        if (endTime.errors) return {sameDate: true};

        const time = endTime.value.split(':');
        endDate.setHours(time[0], time[1], 0, 0);
        if (normalDate.getTime() >= endDate.getTime()) return {sameDate: true};
      }

      return null;
    };
  }



  get title(): AbstractControl {
    return this.form.get('title');
  }

  get image(): FormArray {
    return this.form.get('image') as FormArray;
  }

  get description(): AbstractControl {
    return this.form.get('description');
  }

  get type(): AbstractControl {
    return this.form.get('type');
  }

  get url(): AbstractControl {
    return this.form.get('url');
  }

  get startTime(): AbstractControl {
    return this.form?.get('startTime');
  }

  get endTime(): AbstractControl {
    return this.form?.get('endTime');
  }

  get startDate(): AbstractControl {
    return this.form?.get('startDate');
  }

  get endDate(): AbstractControl {
    return this.form?.get('endDate');
  }

  get offer(): FormGroup {
    return this.form.get('offer') as FormGroup;
  }

  get couponCode(): AbstractControl {
    return this.offer.get('couponCode');
  }

  get redeemOnlineUrl(): AbstractControl {
    return this.offer.get('redeemOnlineUrl');
  }


  get termsConditions(): AbstractControl {
    return this.offer.get('termsConditions');
  }

  changeRecurring($event) {
    this.handleIsRecurring.emit($event.checked);
  }

  handleRecurringChange($event) {
    this.recurringChange.emit($event);
  }

  handlepublishRecurringNowChange($event) {
    this.publishRecurringNowChange.emit($event);
  }

  handleIsRecurringValid($event) {
    this.isRecurringValid.emit($event);
  }

  reset(): void {
    this.inputUpload.reset();
    const imageIndex = this.imageUrl.findIndex(i => i === this.imageSelectedUrl);
    this.imageUrl = this.imageUrl.filter( image => image !== this.imageSelectedUrl);
    this.image.removeAt(imageIndex);
    this.imageSelectedUrl = null;
  }
}
