import { __extends } from "tslib";
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, interval } from 'rxjs';
// app
import { BaseComponent } from '../base.component';
var LoadModalComponent = /** @class */ (function (_super) {
    __extends(LoadModalComponent, _super);
    function LoadModalComponent(data, dialogRef) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this.steps = 0;
        _this.complete = 0;
        _this.completed = null;
        _this.progress = 0;
        _this.loadingValue$ = new BehaviorSubject(0);
        _this.steps = data.steps;
        _this.complete = data.complete;
        _this.completed = data.completed;
        var sub = _this._subscribeSafe(interval(500), function () {
            if (_this.progress >= 60) {
                sub.unsubscribe();
            }
            else {
                _this.completed = "Stage 1 - Fetching Location Insights";
                _this.progress += Math.ceil(Math.random() * (30 - 10) + 10);
                _this.loadingValue$.next(_this.progress);
            }
        });
        return _this;
    }
    LoadModalComponent.prototype.updatedData = function (complete, completed) {
        var _this = this;
        this.complete += complete;
        //this.completed = `Stage ${this.complete} - Fetching ${completed}`;
        var progressCompleted = ((this.complete / this.steps) * 100) + 60;
        if (progressCompleted >= 60) {
            this.progress = progressCompleted;
            if (this.complete === this.steps) {
                this.completed = 'Stage 3 - Complete!';
                setTimeout(function () {
                    _this.dialogRef.close();
                }, 1000);
            }
            if (progressCompleted >= 100 || (this.complete - this.steps) < 3)
                this.loadingValue$.next(90);
            this.loadingValue$.next(progressCompleted);
            this.completed = "Stage 2 - Fetching Review Data";
        }
    };
    return LoadModalComponent;
}(BaseComponent));
export { LoadModalComponent };
