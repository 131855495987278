import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import {LocationEditService} from '../../services/location-edit.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AttributeOpenInfoStatusType } from 'src/app/constants/google/attributes';
import { AlertComponent } from '../../components/alert.component';
@Component({
  selector: 'app-modal-date',
  templateUrl: './modal-open-date.component.html',
  styleUrls: ['./modal-open-date.component.scss']
})
export class ModalOpenDateComponent implements OnInit {
  months: any;
  days: any;
  selectedYear: string;
  selectedMonth: any;
  selectedDay: any;
  public form: FormGroup;
  listOpen = AttributeOpenInfoStatusType;
  statusBusiness: string = 'OPEN';

  @Input() bulk: boolean = false;
  @Output() validResult = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalOpenDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationEdit: LocationEditService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {

    this.months = [
      {value: 1, name: 'January', days: 31},
      {value: 2, name: 'February', days: 28},
      {value: 3, name: 'March', days: 31},
      {value: 4, name: 'April', days: 30},
      {value: 5, name: 'May', days: 31},
      {value: 6, name: 'June', days: 30},
      {value: 7, name: 'July', days: 31},
      {value: 8, name: 'August', days: 31},
      {value: 9, name: 'September', days: 30},
      {value: 10, name: 'October', days: 31},
      {value: 11, name: 'November', days: 30},
      {value: 12, name: 'December', days: 31}
    ];

    this.days = Array.from(Array(31), (e, i) => i + 1);

  }

  ngOnInit() {
    this.statusBusiness = this.data?.status;
    this.initForm();
  }

  apply(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (this.statusBusiness === 'CLOSED_PERMANENTLY') {
        const dialog =  this.dialog.open(AlertComponent, {
          width: '680px',
          data: {
            title: 'Closed Permanently',
            alertType: 0,
            content: 'Are you sure you want to close this business permanently?',
            closeButtonLabel: 'Confirm'  
          }
        });

        dialog.afterClosed().subscribe( data => {
          if (data) {
            this.locationEdit.locationEdit.openInfo.status = this.statusBusiness;
            this.locationEdit.locationEdit.openInfo.openingDate = this.getData();
            if (this.statusBusiness == 'OPEN' && !this.year.value && !this.month.value) {
              delete this.locationEdit.locationEdit.openInfo.openingDate
            }
            this.locationEdit.setAndUpdate().toPromise().then(() => {
              this.dialogRef.close();
            });
          }
        })
      } else {
        this.locationEdit.locationEdit.openInfo.status = this.statusBusiness;
        this.locationEdit.locationEdit.openInfo.openingDate = this.getData();
        if (this.statusBusiness == 'OPEN' && !this.year.value && !this.month.value) {
          delete this.locationEdit.locationEdit.openInfo.openingDate
        }
        this.locationEdit.setAndUpdate().toPromise().then(() => {
          this.dialogRef.close();
        });
      }
    }
  }

  changeMonth() {
    const days = this.months.find(el => el.value == this.month.value)?.days || 31;
    this.days = Array.from(Array(days), (e, i) => i + 1)
  }

  getData() {
    let data = {};

    if (this.year.value && this.month.value) {
      data['year'] = Number(this.year.value),
      data['month'] = Number(this.month.value)

      if (Number(this.day.value) > 0) {
        data['day'] = Number(this.day.value)
      }
    }

    return data;
  }


  initForm() {
    this.form = this.fb.group({
      year: ['', [Validators.pattern('[0-9]{4}')]],
      month: ['', [Validators.max(12)]],
      day: ['', [Validators.pattern('[1-3]?[0-9]|31')]],
    });

    if (this.data?.openingDate) {
      this.setForm();
    }
  }

  setForm() {
    this.form = this.fb.group({
      year: [(this.data.openingDate.year || ''), [Validators.pattern('[0-9]{4}')]],
      month: [(this.data.openingDate.month || ''), [Validators.max(12)]],
      day: [(this.data.openingDate.day || ''), [Validators.pattern('[1-3]?[0-9]|31')]],
    });
  }

  isValidYear(year) {
    if (year.value === '0000') {
      this.year.setErrors({incorrectError: true})
    }
  }

  isRequired(field, compareField) : boolean {
    const compareValue = compareField == 'year' ? this.year.value : this.months.value;

    return !!(field.value || compareValue || this.day.value && this.day.value != "0")
  }

  statusChanged() {
    this.initForm();
  }

  getResult(): { status: string, openingDate } {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.validResult.emit(true);
      return {
        status: this.statusBusiness, 
        openingDate: this.getData()
      }
    }
  }

  get year() {
    return this.form.get('year');
  }

  get month() {
    return this.form.get('month');
  }

  get day() {
    return this.form.get('day');
  }
}
