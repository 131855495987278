import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Protocol, SAVE_COMPLETE_SCAN, SAVE_COMPLETE_EDIT_SCAN, SAVE_COMPLETE_PROTOCOL, SAVE_COMPLETE_EDIT_PROTOCOL } from '../../constants/firestore/protocol';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { ProtocolService } from '../../services/protocol.service';
import { LocationService } from '../../services/location.service';
import { ReportService } from '../../services/report.service';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AlertType } from '../../components/alert.component';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { ModalCreateProtocolComponent } from '../modal-create-protocol/modal-create-protocol.component';
import * as _ from 'lodash';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-protocol-list',
  templateUrl: './protocol-list.component.html',
  styleUrls:  ['./protocol-list.component.scss']
})
export class ProtocolListComponent extends BaseComponent implements OnInit, AfterViewChecked {
  @Input() displayedColumns: string[] = ['id', 'name', 'status', 'triggers', 'enable', 'retroactive', 'action-btn'];
  @Input() ColumnsSize: number[] = [5, 30, 15, 15, 10, 15, 10];
  @Input() isScan = false;
  dataSource = new MatTableDataSource([]);
  NotFoundSearch$ = new BehaviorSubject(false);
  protocolsExist = false;
  progress = new BehaviorSubject(false);
  last : any = null;
  next : any = null;
  filtered = false;
  searchText = '';
  loadingEnabled = false
  // custom pagination elements
  manualPage: number;
  errorMessage: boolean;

  // custom pagination with page numbers
  @Input() page = 1;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  selectionProtocol = new SelectionModel<Protocol>(true, []);
  protocols: Protocol[];
  size = 10;
  private paginate: Pageable = {size: this.size, page: this.page};
  private previousPageable: Pageable={page: 1, size: 10};
  private tmpPaginate: Pageable = {size: this.size, page: this.page};
  private tmpPreviousPageable: Pageable={page: 1, size: 10};
  ElementChecks: Observable<{ enable: boolean, retroactive: boolean}> = of({ enable: false,retroactive: false})
  pagination: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  selectedLogs = 0;
  @Output() selectedLogsEmit = new EventEmitter<number>();

  // TimeOut search
  timeout: any = null;
  searchArrayProtocols;

  constructor(private protocolS: ProtocolService,
    private locationS: LocationService,
    private _sessionS : SessionService,
    private reportS: ReportService,
    private modalService: ModalService,
    private changeDetector: ChangeDetectorRef,
    private snack: SnackbarService) { 
      super();
    }


  isDisplayField(name: string): boolean  {
    return this.displayedColumns.some(d => d === name)
  }

  getSizeColumn(name: string): string  {
    const index = this.displayedColumns.indexOf(name);
    if (index >= 0) {
      return `${this.ColumnsSize[index]}%`
    }
  }

  async ngOnInit() {
    this.progress.next(true);
    await this.refresh();
    this.getData(this.paginate);
    this.manualPage = 1;
    this.errorMessage = false;
  }

  async refresh() {
    this.searchArrayProtocols = await this.protocolS.get_all_flex(this._sessionS.getSession().gid, this.isScan);
  }

  getData(paginate: Pageable, refresh = false) {
    this.progress.next(true)
    if (this.previousPageable) {
      if (this.previousPageable.page === paginate.page) {
        this.last = null;
      } else if (this.previousPageable.page < paginate.page) {
        if (this.dataSource.data) {
          const selector = (paginate.size * (paginate.page - 1)) - 1;
          this.next = this.searchArrayProtocols[selector]
          this.last = null;
        }
      } else if (this.previousPageable.page > paginate.page) {
        if (this.searchArrayProtocols) {
          const selector = (paginate.size * paginate.page);
          this.last = this.searchArrayProtocols[selector]
          this.next = null;
        }
      }
    } else {
      this.next = null;
      this.last = null;
    }

    this._subscribeSafe(
     this.protocolS.paginate(this._sessionS.getSession().gid, paginate, this.next, this.last, true, 
                             { field: "scan", operator: "==", value: this.isScan}),

     (result) => {
      if (!this.filtered && !refresh) {
        if (!this._sessionS.getSession().isAdmin) {
          result.items = this.filterByLocationMember(result.items);
        }
        this.protocols = result.items as Protocol[];
        this.pagination = result;
        this.previousPageable = {size: result.per_page, page: result.page}
        this.protocols = this.protocols.map( p => {
          if (!p.enabled) {
            if (p.retroactive) {
              p.retroactive = false;
              this.protocolS.update(p, p.protocolId);
            }
          }
          return p;
        });
        const data = this.protocols.filter(el => this.selectedLogs == 3 ? el?.scan : !el?.scan);
        this.dataSource = new MatTableDataSource(data);
        this.protocolsExist = this.dataSource.data.length > 0;
        this.progress.next(false);
      }
    },
    _err => this.progress.next(false));
  }

  private filterByLocationMember(items) : any[] {
    const {user} = this._sessionS.getSession();
    const memberLocations = [];

    user?.accounts?.forEach( account => {
      account.locations.forEach(location => {
        memberLocations.push(location.locationId)
      });
    });

    const validReviewAssistance = [];
    
    for (const item of items) {
      const reportLocations = [];
      if (!_.isEmpty(item.accounts)) {
        for (const account of item.accounts) {
          account.locations.forEach(location => {
            reportLocations.push(location.locationId);
          });
        }

        if( reportLocations.every(locationId => memberLocations.includes(locationId))) {
          validReviewAssistance.push(item);
        }
      }
    }

    return validReviewAssistance;
  }

  // apply filter from search
  applyFilter($event: string, key: any): void {
    this.NotFoundSearch$.next(false);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
        if (!$event || $event === '') {
          this.filtered = false;
          this.progress.next(true);
          this.paginate = this.tmpPaginate ;
          this.previousPageable = this.tmpPreviousPageable ;
          this.getData(this.paginate);
          this.searchText = ''
        } else {
          if ($event[$event.length-1] !== ' ') {
            if (!this.filtered) this.initPaginate();
            this.filtered = true;
            this.progress.next(true);
            const text = $event.toLowerCase().trim();
            this.searchText = text;
            const search = this.searchArrayProtocols.filter(s => s.name.trim().toLowerCase().indexOf(text) > -1);
            this.setData(search)
          }
        }

    }, 350);
  }

  // TODO: Unused, remove.
  // deleteFromSearchArray(id): void {
  //   this.searchArrayProtocols= this.searchArrayProtocols.filter( p => {
  //     return p.protocolId !== id
  //   });
  // }

  // check for nested objects
  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  isAllSelectedProtocol(): boolean {
    const numSelected = this.selectionProtocol.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggleProtocol() {
    this.isAllSelectedProtocol() ?
      this.selectionProtocol.clear() :
      this.dataSource.data.forEach(row => this.selectionProtocol.select(row));
  }

  checkboxLabelProtocol(row?: Protocol): string {
    if (!row) {
      return `${this.isAllSelectedProtocol() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionProtocol.isSelected(row) ? 'deselect' : 'select'} row ${row.protocolId + 1}`;
  }

  progressValue(element): number {
    return (element.status.replied / element.status.total) * 100;
  }

  async toggleValue(element) {
    if (element.enabled) {
      this.loadingEnabled = true;
      if (!element.retroactive) {
        const title = 'Activate Retroactively';
        const message = 'You are about to run this protocol retroactively. This will schedule a reply for every matching review will begin replying within the next few hours.';
        this.modalService.openConfirmModal(title, message, async (confirm: boolean) => {
          if (confirm) {
            element.retroactive = !element.retroactive;
            const accounts = element.accounts.map(account => {
              if ('locationsBasics' in account) delete account.locationsBasics
              return account
            })
            const protocol = {
              ...element,
              accounts
            }
            await this.protocolS.update(protocol, protocol.protocolId);
            this.protocols = [...this.dataSource.data].map( dt => {
              if (dt.protocolId === element.protocolId) {
                dt.retroactive =  element.retroactive;
              }
              return dt;
            });
            this.dataSource = new MatTableDataSource<Protocol>(this.protocols);
            const check_protocols = await this.protocolS.check_protocol(element.gid, element.protocolId)
            if (!check_protocols)  {
              this.snack.openError('Failed to activate retroactive', 3500);
            }
            if (this.filtered) this.reloadFilter()
            //else this.getData(this.paginate)
          }
        }, AlertType.INFO)
      } else {
        element.retroactive = !element.retroactive;
        const accounts = element.accounts.map(account => {
          if ('locationsBasics' in account) delete account.locationsBasics
          return account
        })
        const protocol = {
          ...element,
          accounts
        }
        await this.protocolS.update(protocol, protocol.protocolId);
        this.dataSource.data = [...this.dataSource.data].map( dt => {
          if (protocol.protocolId == dt.protocolId) {
            dt.retroactive = element.retroactive;
          }
          return dt;
        });
        if (this.filtered) this.reloadFilter()
        //else this.getData(this.paginate)
      }
      this.loadingEnabled = false;
    } else {
      this.snack.openInfo('The protocol must be turned on before activating the retroactive feature.', 3000);
    }
  }

  newProtocol(isEdit: boolean, element = null) {
    const config: any = {
      width: '540px',
      data: {}
    };

    config.data.scan = this.isScan;

    if (isEdit) {
      config.data.edit = true;
      // element.enabled = false;
      const accounts = element.accounts.map(account => {
        if ('locationsBasics' in account) delete account.locationsBasics
        return account
      })
      const protocol = {
        ...element,
        accounts
      }
      protocol.retroactive = false;
      config.data.protocol = protocol;
    }
    const modal = this.modalService.openGenericModal(ModalCreateProtocolComponent, config.data, null, config.width)

    const sub = this._subscribeSafe(modal.componentInstance.completeAll, 
     complete => {
        if (complete === SAVE_COMPLETE_SCAN || complete === SAVE_COMPLETE_EDIT_SCAN ) {
          if (this.selectedLogs !== 3) this.selectedLogs = 3
        } else if (complete === SAVE_COMPLETE_PROTOCOL || complete === SAVE_COMPLETE_EDIT_PROTOCOL ) {
          if (this.selectedLogs !== 0) this.selectedLogs = 0
        }
        this.selectedLogsEmit.emit(this.selectedLogs);
        if (this.filtered && isEdit) {
          this.reloadFilter();
        } else {
          this.handleReload(this.paginate)
        }
        sub.unsubscribe();
      });

  }

  async toggleEnable(event: any, i: any, element) {
    // this.progress.next(true)
    /*element.enabled = event.checked;
    if (!element.enabled) {
      element.retroactive = false;
    }*/
    this.loadingEnabled = true;
    const accounts = element.accounts.map(account => {
      if ('locationsBasics' in account) delete account.locationsBasics
      return account
    })
    const protocol = {
      ...element,
      enabled: event.checked,
      retroactive: element.enabled ? element.retroactive : false,
      accounts
    }
    await this.protocolS.update(protocol, element.protocolId);
    this.protocols = [...this.dataSource.data].map( dt => {
      if (dt.protocolId === element.protocolId) {
        dt.enabled = event.checked;
        dt.retroactive = element.enabled ? element.retroactive : false;
      }
      return dt;
    });
    if (this.filtered)this.reloadFilter()
    this.dataSource = new MatTableDataSource<Protocol>(this.protocols);
    if (protocol.enabled) {
      this.protocolS.check_protocol(element.gid, element.protocolId).then( check_protocols => {
        if (!check_protocols)  {
          this.snack.openError('Failed to activate the protocol', 3500);
        }
        this.loadingEnabled = false;
      });
    } else {
      this.protocolS.delete_reviews_to_reply(element.gid, element.protocolId).then();
      this.loadingEnabled = false;
    }
  }

  delete(element: any) {
    const data = {name: element.name};
    const dialog = this.modalService.openGenericModal(ModalDeleteComponent, data, null, 680 );

    const sub = this._subscribeSafe(dialog.componentInstance.deleted,
     r => {
      if(!r)
        return;
      this.protocolS.delete(element.protocolId).then(() => {
        dialog.componentInstance.complete();
        if (this.filtered) {
          this.reloadFilter()
        } else {
          this.handleReload(this.paginate)
        }
        setTimeout(() => {
          sub.unsubscribe();
          dialog.close();
        }, 450);
      });
    });
  }

  countLocations(element: any) : number {
    return this.reportS.countLocation(element);
  }


  async reloadFilter() : Promise<void> {
    await this.refresh();
    const search = this.searchArrayProtocols.filter(s => s.name.trim().toLowerCase().indexOf(this.searchText) > -1);
    this.setData(search);
  }

  async handleReload($event: Pageable = this.paginate) {
    this.progress.next(true);
    this.paginate = $event;
    if (this.filtered) {
      this.applyFilter(this.searchText, 'Enter');
    } else {
      await this.refresh();
      this.getData($event);
    }
  }

  initPaginate(): void {
    this.tmpPaginate = this.paginate;
    this.tmpPreviousPageable = this.previousPageable;
    this.paginate = { page: 1, size: 10 };
    this.previousPageable = null;
    this.dataSource = new MatTableDataSource<Protocol>([] as Protocol[]);
  }

  async setData(results) {
    if (results.length > 0) {
      const data = _.chunk(results, this.paginate.size);
      const items = data[this.paginate.page - 1];
      const source = this.protocolS.getProtocolsPaginate(results.length, this.paginate, items);
      if (!this._sessionS.getSession().isAdmin) {
        source.items = this.filterByLocationMember(source.items);
      }
      await this.locationS.basicLocations(source.items);
      this.previousPageable = { size: source.per_page, page: source.page };
      this.pagination = source;
      this.dataSource = new MatTableDataSource<Protocol>(source.items);
      this.protocolsExist = true;
      this.progress.next(false);
    } else {
      this.protocolsExist = false;
      this.progress.next(false);
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

}
