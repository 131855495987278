// TODO: This component seems to have a very different appearence/functionality when
// included from a report (isReport=true). Probably the report functionality
// should be refactored out. 
import { __awaiter, __extends, __generator, __read, __spread, __values } from "tslib";
// dep
import { EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
var FlexSearch = require('flexsearch');
// app
import { GroupService } from '../../services/group.service';
import { NotificationService } from '../../services/notification.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { NOTIFICATION_ERROR_FETCH_DATA, NOTIFICATION_ERROR_LOCATION, NOTIFICATION_WARNING, NOTIFICATION_GENERAL, NOTIFICATION_PROTOCOLS } from '../../constants/notifications';
import { GlobalSearchService } from '../../services/global-search.service';
import { AccountService } from '../../services/account.service';
// import { ModalGetAddOn } from '../modal-get-addon/modal-get-addon.component';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { BaseComponent } from 'src/app/components/base.component';
var HeaderComponent = /** @class */ (function (_super) {
    __extends(HeaderComponent, _super);
    function HeaderComponent(
    // Public attributes for services
    dialog, navigationS, 
    // Private attributes for services
    _sessionS, _router, _groupS, _notificationS, _locationS, _globalSearchService, _accountS, _modalService) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.navigationS = navigationS;
        _this._sessionS = _sessionS;
        _this._router = _router;
        _this._groupS = _groupS;
        _this._notificationS = _notificationS;
        _this._locationS = _locationS;
        _this._globalSearchService = _globalSearchService;
        _this._accountS = _accountS;
        _this._modalService = _modalService;
        // NOTIFICATIONS TYPES
        _this.fetchLocationInfoData = NOTIFICATION_ERROR_FETCH_DATA;
        _this.authFail = NOTIFICATION_ERROR_LOCATION;
        _this.oldAuthFail = NOTIFICATION_WARNING;
        _this.informative = NOTIFICATION_GENERAL;
        _this.type_protocol = NOTIFICATION_PROTOCOLS;
        _this.page = 1;
        _this.size = 5;
        _this.loadingNotifications = new BehaviorSubject(false);
        _this.paginate = { size: _this.size, page: _this.page };
        _this.previousPageable = { page: 1, size: 5 };
        _this.loadingNoti = false;
        _this.pagination = {
            items: [],
            per_page: _this.size,
            page: _this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        _this.sidenavToggle = new EventEmitter();
        _this.flexSearch = new FlexSearch({
            encode: "advanced",
            tokenize: "reverse",
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'accountName',
                    'locationName',
                    'locName',
                    'address',
                    'reportName',
                    'labels'
                ]
            }
        });
        _this.selectedSearch = new FormControl();
        _this.isUser = false;
        _this.countNotification = 0;
        _this.notifications = [];
        _this._searchSubject = new Subject();
        _this.isLoading = true;
        _this.showChromeWarning = false;
        _this.domain$ = _this._sessionS.domain$;
        _this.session$ = _this._sessionS.session$;
        _this.isShared = _.endsWith(_this._router.url, 'shared');
        // This component can be used both from logged-off and logged-in routes (is too overloaded,
        // TODO: must be refactored. This will not be executed for logged-off routes.
        var onHasSessionExecuted = false;
        _this._subscribeSafe(_this._sessionS.session$, function () {
            if (onHasSessionExecuted)
                return;
            onHasSessionExecuted = true;
            _this._onSessionLoaded();
        });
        return _this;
    }
    HeaderComponent.prototype._onSessionLoaded = function () {
        var _this = this;
        var gid = this._sessionS.getSession().gid;
        this._subscribeSafe(this._groupS.getIndexes(gid), function (result) {
            var e_1, _a;
            var _b;
            if (!((_b = result) === null || _b === void 0 ? void 0 : _b.length))
                return;
            var _loop_1 = function (index) {
                index.id = JSON.stringify(index);
                if (_.has(index, 'address'))
                    index.address = _this._locationS.formatAddress(index.address);
                var _a = _this._sessionS.getSession(), isAdmin = _a.isAdmin, user = _a.user;
                if (!isAdmin) {
                    if (index.type !== 'location' && index.type !== 'account') {
                        return "continue";
                    }
                    if (index.type === 'account') {
                        var accounts = user.accounts;
                        var findAccount = accounts.find(function (it) { return it.accountId === index.source; });
                        if (!findAccount) {
                            return "continue";
                        }
                    }
                    else {
                        var dataLoc = index.source.split('/');
                        var accountId_1 = dataLoc[0];
                        var locationId_1 = dataLoc[1];
                        var accounts = user.accounts;
                        var findAccount = accounts.find(function (it) { return it.accountId === accountId_1; });
                        if (!findAccount || !findAccount.locations.find(function (it) { return it.locationId === locationId_1; }))
                            return "continue";
                    }
                }
                _this._addDoc(index);
            };
            try {
                for (var result_1 = __values(result), result_1_1 = result_1.next(); !result_1_1.done; result_1_1 = result_1.next()) {
                    var index = result_1_1.value;
                    _loop_1(index);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (result_1_1 && !result_1_1.done && (_a = result_1.return)) _a.call(result_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.isUser = true;
        });
    };
    HeaderComponent.prototype.capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this._searchSubject.pipe(debounceTime(500)), function (result) { return _this.search(result); });
        /*this.notificationS.countNotifications(this.auth.session.gid).subscribe(count => {
          this.countNotification = count;
        });*/
        //this.getNotifications(this.paginate);
        if (this.isReport) {
            return;
        }
    };
    HeaderComponent.prototype.getNotifications = function (paginate) {
        var _this = this;
        var last = null;
        var next = null;
        if (this.previousPageable) {
            if (this.previousPageable.page < paginate.page) {
                if (this.notifications) {
                    last = this.notifications[(paginate.size * (paginate.page - 1)) - 1];
                }
            }
            else if (this.previousPageable.page > paginate.page) {
                if (this.notifications) {
                    next = this.notifications[0];
                }
            }
        }
        this._subscribeSafe(this._notificationS.paginateNotifications(paginate, last, next), function (result) {
            var _a;
            _this.loadingNoti = false;
            (_a = _this.notifications).push.apply(_a, __spread(result.items));
            _this.countNotification = result.total;
            _this.pagination = result;
            _this.previousPageable = { size: result.per_page, page: result.page };
            _this.loadingNotifications.next(false);
        });
    };
    HeaderComponent.prototype.getReportTypeString = function (input) {
        var data = {
            'keyword': 'Keyword',
            'qanda': 'Questions and Answers',
            'review': 'Review',
            'rollup': 'Performand Rollup',
            'revenue': 'Revenue',
            'performance-revenue': 'Performance Revenue',
            'performance-rollup': 'Performance Rollup',
            'performance-comparison': 'Performance Comparison',
            'comparison': 'Comparison',
            'grade': 'Grade',
        };
        return data[input] || input;
    };
    HeaderComponent.prototype.clearAllNotifications = function () {
        this._notificationS.deleteAll();
    };
    HeaderComponent.prototype.deleteAll = function () {
        this._notificationS.deleteAll();
    };
    HeaderComponent.prototype.deleteNotification = function (n) {
        this._notificationS.deleteNotification(n.id);
    };
    // TODO: Unused, remove?
    //   openFetchDialog(result, accountId, locationId, notifyErrors=false): void {
    //     const dialogRef = this.dialog.open(ModalFetchComponent, {
    //       width: '1000px',
    //       data: {
    //         difference: result.difference,
    //         placeId: locationId,
    //         history: null,
    //         notifyErrors
    //       }
    //     });
    // 
    //     dialogRef.disableClose = true;
    // 
    //     dialogRef.afterClosed().subscribe(refresh => {
    //       if (refresh) {
    //         this.router.navigate(['/account', accountId, 'location', locationId, 'location-info']).then();
    //       }
    // 
    //     });
    //   }
    HeaderComponent.prototype.handleReload = function (e) {
        e.preventDefault();
        if (!this.loadingNoti) {
            var $event = {
                page: (this.paginate.page + 1),
                size: 5
            };
            this.loadingNoti = true;
            this.paginate = $event;
            //this.getNotifications($event);
        }
    };
    // TODO: referenced from a commented-out section on header.component.html, obsolete? if yes remove.
    // viewed(n): void {
    //   switch (n.type) {
    //     case 'protocols':
    //       this.router.navigate(['/review-assistant']).then();
    //       break;
    //     case 'warning':
    //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
    //       break;
    //     case NOTIFICATION_ERROR_LOCATION:
    //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
    //       break;
    //     case NOTIFICATION_ERROR_FETCH_DATA:
    //       this.fetchProgress = true;
    //       this.googleS.fetchDifference(n.accountId, n.location.locationId).take(1).subscribe(result => {
    //         this.fetchProgress = false;
    //         this.openFetchDialog(result, n.accountId, n.location.locationId, true);
    //       }, error => {
    //         this.fetchProgress = false;
    //         this.snack.openError('failed to fetch', 4000);
    //       });
    //       break;
    //     case NOTIFICATION_GENERAL:
    //       break;
    //     default:
    //       this.router.navigate(['/account', n.accountId, 'location', n.location.locationId, 'workshop']).then();
    //       break;
    //   }
    //   // this.notificationS.merge(this.auth.session.gid, n.id, {status: 'viewed'}).then(() => {
    //   //
    //   // });
    // }
    HeaderComponent.prototype.onToggleSidenav = function () {
        this.sidenavToggle.emit();
    };
    HeaderComponent.prototype._addDoc = function (doc) {
        this.flexSearch.add(doc);
    };
    HeaderComponent.prototype.handleChangeSearch = function ($event) {
        this._searchSubject.next($event);
    };
    HeaderComponent.prototype.search = function ($event) {
        var _this = this;
        if (!this._sessionS.hasSession())
            return;
        var text = $event.target.value.toLowerCase();
        if (!text) {
            this.results = [];
            return;
        }
        // TODO: This should be cancelled if this method is called again
        this._globalSearchService.search(text, this._sessionS.getSession().gid).subscribe(function (result) {
            _this.results = result;
        });
    };
    HeaderComponent.prototype.display = function (value) {
        if (!value) {
            return null;
        }
        switch (value.type) {
            case 'account':
                return "" + value.accountName;
            case 'location':
                return value.locationName + " ";
            case 'report':
                return value.type + ": " + value.reportName;
        }
    };
    // TODO: Refactor against locations.component.ts
    HeaderComponent.prototype._validateVerifiedStatus = function (location) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(((_b = (_a = location) === null || _a === void 0 ? void 0 : _a.locationState) === null || _b === void 0 ? void 0 : _b.isVerified) == false)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._modalService.openErrorModal("Verification required in Google Business Profile", "<div class='txt--left'>\n          This location requires verification, which prevents access to your dashboard.\n          <div>\n            <br>Step 1: Login to your Google Business Profile and verify your location.\n            <br>Step 2: Once verified, return to Map Labs and click the refresh button.\n          </div>\n        </div>")];
                    case 1:
                        _c.sent();
                        return [2 /*return*/, false];
                    case 2: return [2 /*return*/, true];
                }
            });
        });
    };
    HeaderComponent.prototype.selected = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var value, _b, url;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        value = this.selectedSearch.value;
                        _b = ((_a = value) === null || _a === void 0 ? void 0 : _a.type) == "location";
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._validateVerifiedStatus(value)];
                    case 1:
                        _b = !(_c.sent());
                        _c.label = 2;
                    case 2:
                        if (_b) {
                            return [2 /*return*/];
                        }
                        url = '';
                        switch (value.type) {
                            case 'account':
                                url = "/accounts/" + value.accountId + "/locations";
                                break;
                            case 'location':
                                url = "/account/" + value.accountId + "/location/" + value.locationId + "/insights";
                                break;
                            case 'report':
                                url = "/report/" + value.gid + "/" + value.reportId + "/" + value.reportType;
                                break;
                        }
                        this._router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
                        this._router.navigate([url]);
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.goToProfiles = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var gid, accounts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this._router.url.includes('share')) return [3 /*break*/, 1];
                        this._router.navigate(['/']);
                        return [3 /*break*/, 3];
                    case 1:
                        gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this._accountS.getAccountPaginate(gid, { page: 1, size: 5 }, [])];
                    case 2:
                        accounts = (_b.sent()).items;
                        this._router.navigate([accounts.length ?
                                "accounts/" + ((_a = accounts[0]) === null || _a === void 0 ? void 0 : _a.accountId) + "/locations" :
                                'accounts']);
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return HeaderComponent;
}(BaseComponent));
export { HeaderComponent };
