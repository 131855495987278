import { __extends } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { BaseComponent } from 'src/app/components/base.component';
/**
 * "Add User" dialog
 */
var UserDialogComponent = /** @class */ (function (_super) {
    __extends(UserDialogComponent, _super);
    function UserDialogComponent(data, dialogRef, usersService, snack, formBuilder) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this.usersService = usersService;
        _this.snack = snack;
        _this.formBuilder = formBuilder;
        _this.user = _this.data.user;
        _this.isNew = _this.user === null;
        return _this;
    }
    Object.defineProperty(UserDialogComponent.prototype, "form", {
        get: function () {
            return this.userForm;
        },
        enumerable: true,
        configurable: true
    });
    UserDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buildForm();
        this._subscribeSafe(this.usersService.users, function (users) { return _this.users = users; });
    };
    UserDialogComponent.prototype.buildForm = function () {
        var readOnlyFields = this.user !== null;
        this.userForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(5)]],
            email: [{ value: '', disabled: readOnlyFields }, [Validators.required, Validators.email]],
            active: [true],
            role: ['']
        });
        if (null !== this.user) {
            this.userForm.patchValue({
                name: this.user.displayName,
                email: this.user.email,
                active: this.user.isActive,
                role: this.user.role
            });
        }
    };
    UserDialogComponent.prototype.save = function () {
        var userData = this.userForm.getRawValue();
        if (this.isNew) {
            if (this.users.some(function (user) { return user.displayName === userData.name ||
                user.email === userData.email; })) {
                this.snack.openError('The user already exists.');
                return;
            }
        }
        this.dialogRef.close({ displayName: userData.name, email: userData.email, isActive: userData.active, role: userData.role });
    };
    UserDialogComponent.OPTS_DEFAULT = { config: { width: '680px' } };
    return UserDialogComponent;
}(BaseComponent));
export { UserDialogComponent };
