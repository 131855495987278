<app-header [isReport]="true" *ngIf="shared"></app-header>

<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="(loadingSpinner$ | async)">
  <mat-spinner></mat-spinner>
</div>

<!--else notAllowed-->
<div [ngClass]="contentClass" [class.mt--120]="shared" *ngIf="report && isProcessed && reportType && !sharedNotAllowed;">
  <ng-container [ngSwitch]="reportType">
    <ng-container *ngSwitchCase="'rollup'">
      <app-dashboard-insights [reportName]='report?.reportName' [dataPicker]="dataPicker" [shared]="shared" [lockDates]="lockDates" *ngIf="dataPicker" (changeDataPicker)="handleDatapicker($event)" ></app-dashboard-insights>
    </ng-container>
    <ng-container *ngSwitchCase="'performance-rollup'">
      <app-dashboard-performance-insight [reportName]='report?.reportName' [report]="report" [minDate]="minDate" [maxDate]="maxDate" [reportId]="reportId" [dataPicker]="dataPicker" [shared]="shared" [lockDates]="lockDates" *ngIf="dataPicker" (changeDataPicker)="handleDatapicker($event)" ></app-dashboard-performance-insight>
    </ng-container>
    <ng-container *ngSwitchCase="'revenue'">
      <app-dashboard-revenue [viewModeChecked]="viewModeChecked" [reportType]="'revenue'" [reportId]="reportId" [reportName]='report?.reportName' [dataPicker]="dataPicker" [shared]="shared" [lockDates]="lockDates" [report]="report" *ngIf="dataPicker"></app-dashboard-revenue>
    </ng-container>
    <ng-container *ngSwitchCase="'performance-revenue'">
      <app-dashboard-revenue [viewModeChecked]="viewModeChecked" [reportType]="'performance-revenue'" [reportId]="reportId" [reportName]='report?.reportName' [dataPicker]="dataPicker" [shared]="shared" [lockDates]="lockDates" [report]="report" *ngIf="dataPicker"></app-dashboard-revenue>
    </ng-container>
    <ng-container *ngSwitchCase="'review'">
      <app-dashboard-reviews [viewModeChecked]="viewModeChecked" [report]="report" [minDate]="minDate" [maxDate]="maxDate" [reportType]="'review'" [reportId]="reportId" [reportName]='report?.reportName' [dataPicker]="dataPicker" [isShared]="shared" [lockDates]="lockDates" *ngIf="dataPicker" [reportType]="'review'" ></app-dashboard-reviews>
    </ng-container>
    <ng-container *ngSwitchCase="'performance-review'">
      <app-dashboard-reviews [viewModeChecked]="viewModeChecked" [report]="report" [reportType]="'performance-review'" [reportId]="reportId" [reportName]='report?.reportName' [dataPicker]="dataPicker" [isShared]="shared" [lockDates]="lockDates" *ngIf="dataPicker" [reportType]="'review'" ></app-dashboard-reviews>
    </ng-container>
    <ng-container *ngSwitchCase="'comparison'">
      <app-comparison-expansion [viewModeChecked]="viewModeChecked" [reportId]="reportId" [reportType]="'comparison'" [reportName]='report?.reportName' [metrics]='report?.metrics' [dataPicker]="dataPicker" [isShared]="shared" [lockDates]="lockDates"  (changeDataPicker)="handleDatapicker($event)"  *ngIf="dataPicker"></app-comparison-expansion>
    </ng-container>
    <ng-container *ngSwitchCase="'performance-comparison'">
      <app-comparison-expansion [viewModeChecked]="viewModeChecked" [isReport]="true" [report]="report" [minDate]="minDate" [maxDate]="maxDate" [reportType]="'performance-comparison'" [reportId]="reportId" [reportName]='report?.reportName' [metrics]='report?.metricsName' [dataPicker]="dataPicker" [isShared]="shared" [lockDates]="lockDates"  (changeDataPicker)="handleDatapicker($event)"  *ngIf="dataPicker"></app-comparison-expansion>
    </ng-container>
    <ng-container *ngSwitchCase="'grade'">
      <app-dashboard-grade [gradeReport]="true" [dataPicker]="dataPicker" [isShared]="shared"></app-dashboard-grade>
    </ng-container>
    <ng-container *ngSwitchCase="'Review Assistant'">
      <app-protocol-report [reportName]="report?.reportName" [dataPicker]="dataPicker" [isShared]="shared" [lockDates]="lockDates" ></app-protocol-report>
    </ng-container>
    <ng-container *ngSwitchCase="'keyword'">
      <app-search-keywords [isReport]="true" [minDate]="minDate" [maxDate]="maxDate" [report]="report" [dataPicker]="dataPicker" [isShared]="shared"></app-search-keywords>
      </ng-container>
    <ng-container *ngSwitchCase="'qanda'">
      <app-questions-answer [report]="report" [reportId]="reportId" [isReport]="true" [isShared]="shared" [periodType]="report?.dynamicRange" [dataPicker]="dataPicker"></app-questions-answer>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="dataPicker && (reportType=='comparison' || reportType=='rollup' || reportType=='performance-comparison') && showMultilocations">
    <app-table-multilocations
      [reportType]="reportType"
      [reportName]="report?.reportName"
      [reportId]="reportId"
      [dataPicker]="dataPicker"
      [locations]="dataPicker.locations"
      [refresh]="refresh"
      [disableIcons]="shared && showMultilocations"
      *ngIf="dataPicker.locations && reportType != 'performance-comparison'">
    </app-table-multilocations>
  </ng-container>

  <app-footer *ngIf="shared && route.snapshot?.params?.automatedReport" [customPosition]="true"></app-footer>
</div>

<!--<ng-template #notAllowed>-->
<!--  <app-no-data message="Not is not shared"></app-no-data>-->
<!--</ng-template>-->