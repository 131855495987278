/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./impersonate-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./impersonate-message.component";
import * as i8 from "../../services/session.service";
import * as i9 from "../../services/navigation.service";
var styles_ImpersonateMessageComponent = [i0.styles];
var RenderType_ImpersonateMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImpersonateMessageComponent, data: {} });
export { RenderType_ImpersonateMessageComponent as RenderType_ImpersonateMessageComponent };
function View_ImpersonateMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "trial-message js-trial-message row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["class", "m--4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Impersonating user: "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "strong", [["class", "m--4"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn--white btn--xs ml--10"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationS.signOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stop Impersonation..."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.session$)).user.email; _ck(_v, 5, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 9).disabled || null); var currVal_2 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_ImpersonateMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ImpersonateMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.session$)).isImpersonating; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImpersonateMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-impersonate-message", [], null, null, null, View_ImpersonateMessageComponent_0, RenderType_ImpersonateMessageComponent)), i1.ɵdid(1, 49152, null, 0, i7.ImpersonateMessageComponent, [i8.SessionService, i9.NavigationService], null, null)], null, null); }
var ImpersonateMessageComponentNgFactory = i1.ɵccf("app-impersonate-message", i7.ImpersonateMessageComponent, View_ImpersonateMessageComponent_Host_0, {}, {}, []);
export { ImpersonateMessageComponentNgFactory as ImpersonateMessageComponentNgFactory };
