import { __extends, __read, __values } from "tslib";
export var BROWSER_DOMAIN = {
    /**
     * Current domain (e.g "app.maplas.com"). No protocol o port are included.
     */
    domainName: window.location.hostname,
    /**
     * Current domain name in 'xdomain' format (e.g. "app_maplabs_com"),
     * "slugDomain". It was created that way because Firestore couldn't
     * store documents id's that included dots.
     */
    xDomainName: window.location.hostname.replace(/\./g, '_'),
    /**
     * Current domain with port (e.g "app.maplas.com:443"). No protocol is included.
     * If the port is 80 or 443, it is omitted.
     */
    domainNameWithPort: window.location.port ? window.location.hostname + ":" + window.location.port : window.location.hostname
};
/**
 * Returns if the current page is running inside an iframe
 * Used for https://www.theflightschoolinc.com/reviews (see MAP-2017, MAP-2030)
 */
export function isRunningEmbedded() {
    return (window.location !== window.parent.location);
}
//----------------------------------------------------------------------------
// PromisedObservable
//----------------------------------------------------------------------------
var ValueNotAvailableError = /** @class */ (function (_super) {
    __extends(ValueNotAvailableError, _super);
    function ValueNotAvailableError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ValueNotAvailableError;
}(Error));
export { ValueNotAvailableError };
/**
 * Given an RXJS Subject, returns a PromisedObservable, that is, an Observer augmented
 * with the currentValPromise()/getSync() and destroy() methods
 *
 * Differences between currentValPromise() and Observable.toPromise()
 *   - Observable.toPromise() requires the Observable to be complete()'ed to
 *     resolve.
 *   - This resolves to multiple values (by calling currentValPromise() multiple times),
 *     Observable.toPromise() resolves to a single value
 *   - If the Observable is .completed() without emmiting a single value,
 *     then Observable.toPromise() resolves to 'undefined' (the signature is
 *     wrong on RXJS 6, but solved on RXJS 7). This excepts.
 *
 * - toPromise() should have been called "lastValPromise()" to avoid confusion.
 *
 * TODO: Some usages can be replaced by firstValueFrom when upgrading RXJS from 6 to 7
 * (which requires TS 4.2, but we cannot upgrade to that TS version given our
 *  current Angular 8.3.4 version)
 */
export function asPromisedObservable(upstreamSubject, opts) {
    // Downstream
    // private _subject = new Subject<T>()
    var e_1, _a;
    var _promise = null;
    var _promiseResolve;
    var _promiseReject;
    var _createPromise = function () {
        _promise = new Promise(function (resolve, reject) {
            _promiseResolve = resolve;
            _promiseReject = reject;
        });
        // Avoid the "Uncaught (in promise)" error log
        _promise.catch(function () { });
    };
    var _hasLastValue = false;
    var _lastValue;
    var _onNewValue = function (v) {
        _lastValue = v;
        _hasLastValue = true;
        var _promiseResolvePrev = _promiseResolve;
        _createPromise();
        _promiseResolvePrev(v);
        // _subject.next(v)
    };
    var destroy = function (err) {
        if (err === void 0) { err = "PromisedObservable destroyed"; }
        if (!_promise)
            return;
        _promise = null;
        _upstreamSubscription.unsubscribe();
        // __subject.complete()
        _promiseReject(err);
    };
    // init
    _createPromise();
    var _upstreamSubscription = upstreamSubject.subscribe({
        next: _onNewValue,
        error: destroy,
        complete: function () { return destroy("PromisedObservable completed"); }
    });
    var currentValPromise = function () {
        if (_hasLastValue)
            return Promise.resolve(_lastValue);
        if (!_promise)
            throw Error('PromisedObservable already completed/destroyed');
        return _promise;
    };
    var hasValue = function () { return _hasLastValue; };
    var getSync = function () {
        if (!_hasLastValue)
            throw new ValueNotAvailableError();
        return _lastValue;
    };
    var o = upstreamSubject.asObservable();
    try {
        for (var _b = __values([['destroy', destroy],
            ['currentValPromise', currentValPromise],
            ['hasValue', hasValue],
            ['getSync', getSync]]), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), k = _d[0], v = _d[1];
            if (k in o) {
                // maybe added in newer RXJS versions
                throw Error("Patching Observable will override existing attr " + k);
            }
            o[k] = v;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (opts && 'injectVal' in opts) {
        upstreamSubject.next(opts.injectVal);
        // Ensure the value is synchronously available after
        // returning from this function
        _onNewValue(opts.injectVal);
    }
    return o;
}
export function makeOpenPromise() {
    var p_resolve;
    var p_reject;
    var p = new Promise(function (resolve, reject) {
        p_resolve = resolve;
        p_reject = reject;
    });
    p.resolve = p_resolve;
    p.reject = p_reject;
    return p;
}
//----------------------------------------------------------------------------
// Array helpers
//----------------------------------------------------------------------------
export function markedPositions(arr) {
    var r = [];
    for (var i = 0; i < arr.length; ++i)
        r.push({ value: arr[i],
            isFirst: i === 0,
            isLast: i === (arr.length - 1) });
    return r;
}
