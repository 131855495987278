/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-reader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-quill/ngx-quill.ngfactory";
import * as i3 from "ngx-quill";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "ngx-intl-tel-input";
import * as i8 from "@angular/router";
import * as i9 from "./text-reader.component";
import * as i10 from "../../services/session.service";
var styles_TextReaderComponent = [i0.styles];
var RenderType_TextReaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextReaderComponent, data: {} });
export { RenderType_TextReaderComponent as RenderType_TextReaderComponent };
function View_TextReaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "quill-editor", [["class", "mb--20 bg--white w--90"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.editor = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_QuillEditorComponent_0, i2.RenderType_QuillEditorComponent)), i1.ɵdid(1, 4898816, null, 0, i3.QuillEditorComponent, [i1.ElementRef, i4.DomSanitizer, i5.DOCUMENT, i1.PLATFORM_ID, i1.Renderer2, i1.NgZone, i3.QUILL_CONFIG_TOKEN], { theme: [0, "theme"], modules: [1, "modules"], readOnly: [2, "readOnly"] }, null), i1.ɵpod(2, { toolbar: 0 }), i1.ɵprd(1024, null, i6.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.QuillEditorComponent]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.QuillEditorComponent]), i1.ɵdid(5, 671744, null, 0, i6.NgModel, [[8, null], [6, i6.NG_VALIDATORS], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(7, 81920, null, 0, i7.NativeElementInjectorDirective, [i6.NgControl, i1.ElementRef], null, null), i1.ɵdid(8, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "bubble"; var currVal_8 = _ck(_v, 2, 0, false); var currVal_9 = true; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.editor; _ck(_v, 5, 0, currVal_10); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TextReaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "content--full d-flex bg--white align-items-center flex-column text-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "content__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["class", "m--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextReaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "box box--md box--padding-xxs box--rounded bg--darkerblue txt--center btn-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "span", [["class", "d-inline-block mr--5 txt--white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Back to "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [["class", "txt--lightblue"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.editor; _ck(_v, 6, 0, currVal_1); var currVal_4 = _ck(_v, 12, 0, "/login"); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 11).target; var currVal_3 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_TextReaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-reader", [], null, null, null, View_TextReaderComponent_0, RenderType_TextReaderComponent)), i1.ɵdid(1, 114688, null, 0, i9.TextReaderComponent, [i8.ActivatedRoute, i10.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextReaderComponentNgFactory = i1.ɵccf("app-text-reader", i9.TextReaderComponent, View_TextReaderComponent_Host_0, {}, {}, []);
export { TextReaderComponentNgFactory as TextReaderComponentNgFactory };
