<div class="content__header d-flex justify-content-between align-items-center">
  <h2 class="m--0"><strong>User Management</strong></h2>
  <button (click)="onAddNewUser()" mat-flat-button color="accent" class="btn btn--icon btn--md">
    <em class="fab fa-google"></em> Add New User
  </button>
</div>

<div class="content__body">
  <div class="table--shadow bg--white">

    <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center">
			<div class="input-field input-field--search">
				<div class="table__icon table__icon--lg">
					<em class="fas fa-search"></em>
				</div>
				<input (input)="applyFilter($event.target.value, $event)" placeholder="Type to search">
			</div>
		</div>

    <table mat-table [dataSource]="accounts | async" class="table">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><strong>Name</strong></th>
        <td mat-cell *matCellDef="let element" class="cursor--pointer">
          <div class="cell__content">
            {{ element.displayName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef><strong>Username</strong></th>
        <td mat-cell *matCellDef="let element">
          <div class="cell__content">
            {{ element.email }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef><strong>Created On</strong></th>
        <td mat-cell *matCellDef="let element">
          <div class="cell__content">
            {{ element.createdAt?.seconds * 1000 | date:'MM/dd/yyyy' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef><strong>Role</strong></th>
        <td mat-cell *matCellDef="let element">
          <div class="cell__content">
            {{ element.role | uppercase }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Actions</strong></th>
        <td mat-cell *matCellDef="let element" class="txt--right">
          <div class="cell__content">
            <button *ngIf="element.role=='member'" mat-flat-button color="primary" class="btn btn-icon mr--10" (click)="onEditSetting(element)">
              <em class="fas fa-sliders-v-square"></em>
            </button>

            <button mat-flat-button color="accent" class="btn btn-icon mr--10" (click)="onEditUser(element)">
              <em class="fas fa-pencil"></em>
            </button>
            <span class="alternate-theme">
                <button mat-flat-button color="primary" class="btn btn-icon" 
                        (click)="onDeleteUser(element)" 
                        [disabled]="(session$ | async).user.email === element.email">
                   <em class="far fa-trash-alt"></em>
                </button>
            </span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="mat-row mat-row--padding-md bg--white d-flex justify-content-start align-items-center">
      <div class="pagination">
        <button (click)="onPrev()" class="pagination__item" [disabled]="manualPage === 1"><em
          class="fas fa-angle-left"></em></button>
        <button *ngFor="let pageNum of getPages()"
                class="pagination__item"
                (click)="onPage(pageNum)"
                [ngClass]="{ 'active': pageNum == manualPage }">
          {{ pageNum }}
        </button>
        <button (click)="onNext()" class="pagination__item" [disabled]=""><em class="fas fa-angle-right"></em>
        </button>
      </div>
      <!--div class="input-field input-field--pagination">
        <label>Go to:</label>
        <input type="text" matInput [(ngModel)]="manualPage" (ngModelChange)="updateManualPage($event)">
        <mat-error class="mat-error--absolute" *ngIf="errorMessage">
          <p>Enter valid number</p>
        </mat-error>
      </div-->
      <mat-paginator
        [length]="paginator?.length"
        [pageSize]="paginator?.pageSize"
        [pageSizeOptions]="[1, 2, 4, 6, 8, 10]"
      ></mat-paginator>
    </div>
  </div>
</div>
