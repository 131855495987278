import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public loading$ = new BehaviorSubject(false)

  // TODO: To avoid conflicts between diferent components trying to
  // enable/disable the global spinner, this must be implemented:
  // c = createSpinnerControl()
  // c.startSpinner()
  // c.stopSpinner()
  //
  // c.stopSpinner() only really stops the spinner if there aren't other
  // controls started
}
