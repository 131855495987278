// dep
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// app
import {SessionService} from './session.service';
import {GoogleService} from './google.service';
import User, { GoogleAuth } from '../constants/firestore/user';
import GoogleLocation from '../constants/firestore/google-location';
import { ExternalGradeService } from './external-grade.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Fetchs locations from Google. 
 */
export class GoogleLocationService {
  private _locations = new BehaviorSubject<GoogleLocation[]>([]);
  public readonly locations$ = this._locations.asObservable();

  constructor(
    private _sessionS : SessionService,
    private _googleS: GoogleService,
    private _externalGradeS: ExternalGradeService,
  ) {
  }

  async loadAll(accountId: string, googleAuth? : GoogleAuth) : Promise<GoogleLocation[]> {
    if(!googleAuth)
      googleAuth = this._sessionS.getSession().user.googleAuth

    const locations = await this._googleS.getLocations(googleAuth, accountId);
    locations.sort((a, b) => (a.locationName > b.locationName) ? 1 : -1);
    this._locations.next(locations);
    return locations
  }

  async loadAllGrade(user: User, accountId: string, externalGradeId : string) : Promise<void> {
    const googleAuth = await this._externalGradeS.getExternalGradeToken(user.gid, externalGradeId);
    this.loadAll(accountId, googleAuth);
  }

  // reset(): void {
  //   // TODO: Why not this._locations.next([]) ?
  //   // Note that subscriptions are made thru the locations() getter, so probably it was a
  //   // (wrong and leaking) way to "desubscribe" previous subscribers
  //   this._locations = new BehaviorSubject<GoogleLocation[]>([]);
  // }

  reset() : void {
    this._locations.next([]);
  }

}
