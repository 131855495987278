import { __extends } from "tslib";
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ProtocolService } from '../../services/protocol.service';
import { DEFAULT_CHART_DATE_SELECT } from '../../constants/datePicker';
import { LoadingService } from '../../services/loading.service';
import { ReportService } from 'src/app/services/report.service';
import { BaseComponent } from 'src/app/components/base.component';
var ReviewsNumberProtocolsComponent = /** @class */ (function (_super) {
    __extends(ReviewsNumberProtocolsComponent, _super);
    function ReviewsNumberProtocolsComponent(protocolS, loadingService, reportS) {
        var _this = _super.call(this) || this;
        _this.protocolS = protocolS;
        _this.loadingService = loadingService;
        _this.reportS = reportS;
        _this.selectedDate = DEFAULT_CHART_DATE_SELECT;
        _this.isProgressNumber = true;
        return _this;
    }
    ReviewsNumberProtocolsComponent.prototype.ngOnInit = function () {
        var range = {
            start: moment().subtract(3, 'month').day(1).utc(false).format('YYYY-MM-DD 00:00:00'),
            end: moment().utc(false).format('YYYY-MM-DD 23:59:59')
        };
        this.dataPicker.range = { start: range.start, end: range.end };
        this.dataPicker.aggregation = 'Month';
        this.getData();
    };
    ReviewsNumberProtocolsComponent.prototype.getData = function () {
        var _this = this;
        this._subscribeSafe(this.protocolS.getProtocolDataChartCounters(this.locations, this.dataPicker), function (result) {
            // Remove extra days
            if (_this.dataPicker.aggregation === 'Day') {
                var start = moment(_this.dataPicker.range.start);
                var end = moment(_this.dataPicker.range.end);
                var days = end.diff(start, 'days') + 1;
                var dropValues = result.labels.length - days;
                result['data'] = _.dropRight(result.data, dropValues);
                result['labels'] = _.dropRight(result.labels, dropValues);
            }
            _this.isProgressNumber = false;
            _this.datasetBarNumber = [{ data: result.data, label: 'Number' }];
            _this.labelsBarRating = result.labels;
            _this.total = _this.getTotal(_this.datasetBarNumber[0].data);
            _this.loadingService.reportAdvanced(1, "Protocol Charts");
        });
    };
    ReviewsNumberProtocolsComponent.prototype.datesUpdated = function ($event) {
        this.dataPicker.aggregation = this.reportS.correctDateAggregate($event);
        this.getData();
    };
    ReviewsNumberProtocolsComponent.prototype.changeText = function (aggregation) {
        this.dataPicker.aggregation = aggregation;
        this.getData();
    };
    /**
     * this method get total sum of a array
     */
    ReviewsNumberProtocolsComponent.prototype.getTotal = function (array) {
        var total = 0;
        array.forEach(function (x) {
            total += x;
        });
        return total;
    };
    return ReviewsNumberProtocolsComponent;
}(BaseComponent));
export { ReviewsNumberProtocolsComponent };
