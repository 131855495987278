import { MatSnackBar } from '@angular/material';
// app
import { SnackBarComponent } from '../modules/snack-bar/snack-bar.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var SnackType;
(function (SnackType) {
    SnackType["SUCCESS"] = "success";
    SnackType["ERROR"] = "error";
    SnackType["INFO"] = "info";
    SnackType["WARNING"] = "warning";
})(SnackType || (SnackType = {}));
var DEFAULT_DURATION = 4000;
var SnackbarService = /** @class */ (function () {
    function SnackbarService(snackBar) {
        this.snackBar = snackBar;
    }
    SnackbarService.prototype.openSuccess = function (message, duration) {
        if (duration === void 0) { duration = DEFAULT_DURATION; }
        this._open(message, duration, SnackType.SUCCESS);
    };
    SnackbarService.prototype.openError = function (message, duration) {
        if (duration === void 0) { duration = DEFAULT_DURATION; }
        this._open(message, duration, SnackType.ERROR);
    };
    SnackbarService.prototype.openInfo = function (message, duration) {
        if (duration === void 0) { duration = DEFAULT_DURATION; }
        this._open(message, duration, SnackType.INFO);
    };
    SnackbarService.prototype.openWarning = function (message, duration) {
        if (duration === void 0) { duration = DEFAULT_DURATION; }
        this._open(message, duration, SnackType.WARNING);
    };
    SnackbarService.prototype._open = function (message, duration, panelClass) {
        this.snackBar.openFromComponent(SnackBarComponent, {
            data: {
                message: message,
                panelClass: panelClass
            },
            panelClass: panelClass,
            duration: duration,
            horizontalPosition: 'right',
            verticalPosition: 'top',
        });
    };
    SnackbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackbarService_Factory() { return new SnackbarService(i0.ɵɵinject(i1.MatSnackBar)); }, token: SnackbarService, providedIn: "root" });
    return SnackbarService;
}());
export { SnackbarService };
