// dep
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// app
import { SessionService } from './../../../../../services/session.service';
import { KeywordsReportDataService } from './../../../services/keywords-report-data.service';
import { KeywordsFilterConfigService } from './../../../services/keywords-filter-config.service';
import { IKeywordsReportData, IKeywordsReportParams } from '../../../../../constants/keywords-report';
import { IPaginationData } from './../../../../../constants/report-filter-config-data';
import { COLORS_CHART } from './../../../../../constants/chart-color';
import { Pageable } from './../../../../../constants/pageable';
import { Pagination } from './../../../../../constants/api-response';

@Component({
  selector: 'app-search-keywords',
  templateUrl: './search-keywords.component.html',
  styleUrls: ['./search-keywords.component.scss']
})
export class SearchKeywordsComponent implements OnInit {
  @Input() isReport: boolean = false;
  @Input() report;
  @Input() isShared = false;

  public gid: string;
  public accountId: string;
  public locationId: string;
  public reportId: string;
  public keywordsReportParams: IKeywordsReportParams;
  public keywordsReportData: IKeywordsReportData;
  
  // original identifiers (might be changed)
  public loading = true;
  public updatingData = true;
  public isComparisonVisible = false;
  
  public keywordSearchInput = '';
  public dataRange = { value: null, displayName: 'None'}; // <-- not entirely sure what it does
  public colors = COLORS_CHART;
  public currentVisibleGraph = "Total Impressions"
  public visibleGraph = 'total-impressions';
  public sort = {
    sortBy: 'impressions',
    sortOrder: -1,
    sortDirection: 'desc'
  };
  public sortMultiloc = {
    sortBy: 'locationName',
    direction: 'asc'
  };
  public displayedColumnsKeywords = ['keywords', 'impressions'];
  public displayedColumnsMultLoc = ['location', 'Impressions', 'Keywords', 'Branded', 'Non-branded'];
  public fieldsColumnsMultiLoc = [
    { displayName:'locationName', fieldSort: 'locationName' },
    { displayName:'totalImpressions', fieldSort: 'totalImpressions' },
    { displayName:'totalKeywords', fieldSort: 'totalKeywords' },
    { displayName:'brandedImpressions', fieldSort: 'brandedImpressions' },
    { displayName:'nonBrandedImpressions', fieldSort: 'nonBrandedImpressions' }
  ];
  public fieldsColumnsTopics = ['rank', 'details'];
  public lineChartDataSet: any;
  public keywordsPaginate: Pageable = {size: 25, page: 1};
  public keywordsPagination : Pagination = {
    items: [],
    total: 0,
    page: 1,
    pages: 0,
    per_page: this.keywordsPaginate.size,
    hasNext: false,
    hasPrev: false,
  };
  public topicsPaginate: Pageable = {size: 25, page: 1};
  public topicsPagination  = {
    items: [],
    total: 0,
    page: 1,
    pages: 0,
    per_page: this.topicsPaginate.size,
    hasNext: false,
    hasPrev: false,
  };

  private _keywordChangedSubject$ = new Subject<string>();
  private _paginationChangedSubject$ = new Subject<{tableType: string, pagination: IPaginationData}>();
  private _tableOrderChangedSubject$ = new Subject<{sortBy: string, sortOrder: string}>();

  constructor(
    private _keyFilConfS: KeywordsFilterConfigService,
    private _keyRepDataS: KeywordsReportDataService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sessionS: SessionService
  ) { }

  async ngOnInit() {
    
    this.gid = this._sessionS.getSession().gid;
    if (!this.isReport) { // is location-view keywords page
      this.accountId = await this._route.parent.snapshot.params.accountId;
      this.locationId = await this._route.parent.snapshot.params.locationId;
      this.keywordsReportParams = {
        gid: this.gid,
        accountId: this.accountId,
        locationId: this.locationId
      }
      this._keyFilConfS.setInitialFilterConfigData(this.keywordsReportParams)
    } else { // is a keywords report, not yet supported (reports will connect to the old component until we feature flag them)

      // this.reportId = this._route.snapshot.params.id;
      // if (this.report?.accounts?.length > 0) {
      //   this.locations = this.report?.accounts[0]?.locations;
      // }
      // this.dataRange = (this.report?.compareToValue && Object.keys(this.report?.compareToValue).length) ? this.report?.compareToValue : this.dataRange;
      // this.report.showComparison = this.report.showComparison || false;
      // if(this.report.showComparison) {
      //   this.isComparisonVisible = true;
      //   this.displayedColumnsKeywords = ['keywords', 'impressions', 'differencePercentage'];
      //   this.sort = {
      //     sortBy: this.dataRange?.value,
      //     sortOrder: -1,
      //     sortDirection: 'desc'
      //   }
      // };
    }
    this._initializeFilterConfig();

    this._keyRepDataS.keywordsReportDataSubject$
    .subscribe((keywordsReportData: any) => { // replace with correct type once exists
      console.log('report data', keywordsReportData);
      this.keywordsReportData = keywordsReportData;
      this.lineChartDataSet = this.keywordsReportData.impressionsStats; // <- we initialize the graph with keywords data
      this.loading = false
      this.updatingData = false;
    }, (error) => {
      console.error('error while retrieving report data')
      this.loading = false;
      this.updatingData = false;
    });

    // reload table data when the keyword filter changes
    this._keywordChangedSubject$
      .pipe(debounceTime(650))
      .subscribe(value => {
        this.updatingData = true;
        this._keyFilConfS.getTableDataFilteredByKeyword(value);
      });

    // reload table data when pagination changes
    this._paginationChangedSubject$
    .subscribe(value => {
      this.updatingData = true;
      this._keyFilConfS.getTableDataPaginated(value.tableType, value.pagination);
    })

    this._tableOrderChangedSubject$
    .subscribe(value => {
      this.updatingData = true;
      this._keyFilConfS.getTableDataSorted(value);
    })
  }

  changedVisibleGraph(event): void {
    this.visibleGraph = event.value;
    this.currentVisibleGraph = event.value.replace('-', ' ');
    this.setGraphData();
  }

  setGraphData(): void {
    this.lineChartDataSet = this.visibleGraph === 'total-impressions' 
      ? this.keywordsReportData.impressionsStats 
      : this.keywordsReportData.keywordsStats;
  }  

  getImpressionsValue(numberOfImpressions): string {
    return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
  }

  getComparisonBoxClass(field, row?): string {
    let percentage;

    switch(field){
      case 'keywords':
        percentage = this.keywordsReportData?.searchKeywordsData?.comparison?.trend;
        break;
      case 'impressions':
        percentage = this.keywordsReportData?.searchImpressionsData?.trend?.total;
        break;
      case 'branded':
        percentage = this.keywordsReportData?.searchImpressionsData?.trend?.direct;
        break;
      case 'non-branded':
        percentage = this.keywordsReportData?.searchImpressionsData?.trend?.discovery;
        break;
      case 'keywords-table':
        percentage = row?.prevComparison?.percentage;
        break;
    }

    if (percentage > 0) {
      return 'chip--trend--success';
    } else if (percentage < 0) {
      return 'chip--trend--fail';
    } else {
      return 'chip--trend--info';
    }
  }

  getChipValue(value) {
    return (
      value === 0 ? '-' : 
      value > 1000 ? 1000 : 
      value
    );
  }

  keywordsSortChanged(event): void {
    console.log(event);
    this._tableOrderChangedSubject$.next({sortBy: event.active, sortOrder: event.direction})
  }

  keywordsFilterChanged(event): void {
    this._keywordChangedSubject$.next(event);
  }

  tablePaginationChanged(tableType: string, event: IPaginationData): void {
    this._paginationChangedSubject$.next({tableType, pagination: event})
  }

  goToLocations(): void {
    this._router.navigate(['/accounts', this.accountId,  'locations'])
  }

  handleExportCsvKeywordsSearch(): void {
    // to be defined, or removed
  }

  handleExportCsvTopic(value: any): void {
    // to be defined, or removed
  }

  private _initializeFilterConfig() {
    this.updatingData = true;
    this._keyFilConfS.getDateValidation('keyword', [this.accountId], [this.gid], [this.locationId])
    .subscribe(data => {
      if (data && data.minDate && data.maxDate) {
        this._keyFilConfS.initializeDateRestriction(data, 'keyword');

      }
      this.updatingData = false;
    });
  }

}
