import { __extends } from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatTabGroup } from '@angular/material';
import { ModalCategories, CATEGORIES_DATA } from 'src/app/constants/google-media';
import { Messages } from 'src/app/constants/messages';
import { ModalConfirmData } from 'src/app/classes/modal-confirm-data';
import { GoogleService } from 'src/app/services/google.service';
import { StorageImageService } from 'src/app/services/storage-image.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocationService } from 'src/app/services/location.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';
import { ModalShowMediaComponent } from '../../modal-show-media/modal-show-media.component';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var PhotosLocationComponent = /** @class */ (function (_super) {
    __extends(PhotosLocationComponent, _super);
    function PhotosLocationComponent(dialog, googleS, storageImageS, snackS, activateRoute, _sessionS, locationS) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.googleS = googleS;
        _this.storageImageS = storageImageS;
        _this.snackS = snackS;
        _this.activateRoute = activateRoute;
        _this._sessionS = _sessionS;
        _this.locationS = locationS;
        _this.imageUrl = [];
        _this.imageSelectedUrl = null;
        _this.previewImage = null;
        _this.thumbNailImageUrl = null;
        _this.categories = [];
        _this.listFiles = new ModalCategories();
        // private fileRef: AngularFireStorageReference; // TODO: Unused, remove. 
        _this.isProgress = true;
        _this.isProgressPublish = false;
        _this.selectedCategory = null;
        _this.imageRequirements = {
            accepts: '.png, .jpg, .jpeg, .webp',
            type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
            min_width: 480,
            min_height: 270,
            max_width: 2120,
            max_height: 1192,
            min_size: 10240,
            minRatio: 1.3
        };
        _this.accountId = null;
        _this.locationId = null;
        _this.locationType = '';
        _this.showOnlyMedia = false;
        _this.accounts = null;
        _this.error = {};
        _this.error_details = [];
        _this.locationCategories = {
            restaurant: false,
            hotel: false,
            normal: false
        };
        _this.imageEmmiter = new EventEmitter();
        _this.swiperConfig = {
            slidesPerView: 4,
            initialSlide: 0,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            simulateTouch: true,
            slideToClickedSlide: true
        };
        _this.categories = CATEGORIES_DATA;
        _this.category = { name: 'CATEGORY_UNSPECIFIED' };
        return _this;
    }
    PhotosLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.selectedCategory)
            this.selectedCategory = { name: 'COVER', value: 'COVER' };
        if (!this.bulk) {
            if (this.locationType === '') {
                this.locationId = this.activateRoute.snapshot.parent.params.locationId;
                this.accountId = this.activateRoute.snapshot.parent.params.accountId;
                this.locationS.getRef(this._sessionS.getSession().gid, this.accountId, this.locationId).subscribe(function (data) {
                    var _a;
                    var tmpCategory = CATEGORIES_DATA;
                    var lodging = ((_a = data.location.locationState) === null || _a === void 0 ? void 0 : _a.canOperateLodgingData) ? data.location.locationState.canOperateLodgingData : false;
                    var restaurant = data.location.locationState.canHaveFoodMenus ? data.location.locationState.canHaveFoodMenus : false;
                    if (!restaurant && !lodging) {
                        tmpCategory = tmpCategory.filter(function (category) { return category.value !== 'MENU' && category.value !== 'FOOD_AND_DRINK' && category.value !== 'ROOMS' && category.value !== 'COMMON_AREA'; });
                    }
                    else if (!restaurant) {
                        tmpCategory = tmpCategory.filter(function (category) { return category.value !== 'MENU' && category.value !== 'FOOD_AND_DRINK'; });
                    }
                    else if (!lodging) {
                        tmpCategory = tmpCategory.filter(function (category) { return category.value !== 'ROOMS' && category.value !== 'COMMON_AREA'; });
                    }
                    _this.categories = tmpCategory;
                    _this.getMedia();
                });
            }
            else {
                this.getMedia();
            }
        }
        else {
            if (this.accountId && this.locationId) {
                this.getMedia();
            }
            else {
                this.isProgress = false;
            }
        }
        this.getRatio();
    };
    PhotosLocationComponent.prototype.ngOnDestroy = function () {
        /* FIXME: smell bad */
        if (this._refSubscription) {
            this._refSubscription.unsubscribe();
        }
        if (this._deleteSubcription) {
            this._deleteSubcription.unsubscribe();
        }
        _super.prototype.ngOnDestroy.call(this);
    };
    Object.defineProperty(PhotosLocationComponent.prototype, "VideoList", {
        get: function () {
            return this.listFiles['ADDITIONAL'].filter(function (l) { return l.mediaFormat === 'VIDEO'; });
        },
        enumerable: true,
        configurable: true
    });
    PhotosLocationComponent.prototype.getMedia = function () {
        var _this = this;
        this.listFiles = new ModalCategories();
        this.googleS.getMedia(this.accountId, this.locationId).subscribe(function (media) {
            _this.media = media;
            _this.isProgress = false;
            _this.media.forEach(function (x) {
                var _a;
                (_a = _this.listFiles[x.locationAssociation.category]) === null || _a === void 0 ? void 0 : _a.push(x);
            });
        });
    };
    PhotosLocationComponent.prototype.tabChange = function ($event, input) {
        if (this._refSubscription) {
            this._refSubscription.unsubscribe();
            this._deleteSubcription.unsubscribe();
        }
        if (!this.showOnlyMedia) {
            var changeCategory_1 = $event.tab.textLabel;
            var selectedCategory = this.categories.find(function (a) { return a.name === changeCategory_1; });
            if (selectedCategory.name === 'VIDEO') {
                this.imageRequirements.accepts = '.mp4';
                this.imageRequirements.type = ['video/mp4'];
            }
            else if (selectedCategory.name === 'COVER' || selectedCategory.name === 'PROFILE') {
                this.imageRequirements.accepts = '.png, .jpg, .jpeg, .webpm';
                this.imageRequirements.type = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
            }
            else {
                this.imageRequirements.accepts = '.png, .jpg, .jpeg, .webpm, .mp4';
                this.imageRequirements.type = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'video/mp4'];
            }
            this.selectedCategory = selectedCategory;
            this.fileUrl = '';
            this.description = '';
            this.selectedFile = '';
            this.selectedFileName = '';
            this.fileType = '';
            this.clearAll();
        }
        this.getRatio();
    };
    PhotosLocationComponent.prototype.getRatio = function () {
        this.imageRequirements.minRatio = (this.selectedCategory.value === 'COVER' ? 1.7 : 1.3);
    };
    PhotosLocationComponent.prototype.publish = function (category) {
        var _this = this;
        if (!this.imageUrl.length) {
            return;
        }
        this.imageUrl.forEach(function (el) {
            if (el.fileName === undefined) {
                return;
            }
            _this.isProgressPublish = true;
            _this.category = category;
            var mediaItem = {
                mediaFormat: _this.mediaFormat,
                locationAssociation: { category: category },
                sourceUrl: el.url,
                description: el.description
            };
            _this.saveMedia(mediaItem, category);
        });
    };
    PhotosLocationComponent.prototype.saveMedia = function (mediaItem, category) {
        var _this = this;
        this.googleS.saveMedia(this.accountId, this.locationId, mediaItem).subscribe(function () {
            _this.snackS.openSuccess(Messages.upload.SEND_SUCCESS, 4000);
            _this.removeRefs(category);
        }, function (e) {
            console.error(e);
            _this.snackS.openError('Error publishing the image, try again', 4000);
        });
    };
    PhotosLocationComponent.prototype.removeRefs = function (category) {
        var _this = this;
        this._refSubscription = this.storageImageS.ref$.subscribe(function (fileRef) {
            _this.description = "";
            _this.isProgressPublish = false;
            _this._deleteSubcription = fileRef.delete().subscribe(function () {
                _this.getMedia();
                _this.clearAll();
            });
        });
    };
    PhotosLocationComponent.prototype.fileChanged = function (target) {
        var _this = this;
        this.storageImageS.fileChanged(target, true, null, this.locationId);
        // FIXME: Multiple subscriptions
        this._subscribeSafe(this.storageImageS.url$, function (url) {
            if (url) {
                _this.imageSelectedUrl = url.url;
                _this.thumbNailImageUrl = url.preview;
                _this.mediaFormat = url.type;
                _this.fileUrl = url.url;
            }
        });
        // TODO: Unused, remove. 
        // this.storageImageS.ref$.subscribe(fileRef => {
        //   this.fileRef = fileRef;
        // });
    };
    PhotosLocationComponent.prototype.handleUrl = function ($event, added) {
        if (added === void 0) { added = false; }
        if (!$event)
            return;
        var url = $event.url;
        this.mediaFormat = $event.mediaFormat;
        this.isUploading = true;
        var indexImage = this.getimage(url);
        if (this.imageSelectedUrl && (indexImage !== -1)) {
            this.imageSelectedUrl = url;
            this.previewImage = url;
            if ($event.preview)
                this.previewImage = $event.preview;
            else
                this.previewImage = url;
        }
        else {
            if (indexImage === -1) {
                var index = this.imageUrl.length > 0 ? this.imageUrl.length : 0;
                this.imageUrl.push({
                    url: url,
                    preview: $event.mediaFormat == "VIDEO" ? $event.preview : url,
                    description: null,
                    fileName: $event.fileName
                });
                if (!added) {
                    this.imageSelectedUrl = this.imageUrl[index].url;
                }
            }
        }
        this.fileUrl = url;
        if (this.fileUrl) {
            this.isUploading = false;
        }
    };
    PhotosLocationComponent.prototype.getimage = function (data) {
        return this.imageUrl.findIndex(function (i) { return i.url === data; });
    };
    PhotosLocationComponent.prototype.changeDescription = function () {
        var index = this.getimage(this.imageSelectedUrl);
        if (index > -1) {
            this.imageUrl[index].description = this.description;
        }
    };
    PhotosLocationComponent.prototype.changePreview = function (url) {
        if (!url) {
            return;
        }
        var index = this.getimage(url);
        this.description = (index != -1 ? this.imageUrl[index].description : null);
        this.imageSelectedUrl = url;
        this.storageImageS.setImageUrl(url);
    };
    PhotosLocationComponent.prototype.clearImagen = function () {
        var indexImage = this.getimage(this.imageSelectedUrl);
        this.imageUrl.splice(indexImage, 1);
    };
    PhotosLocationComponent.prototype.removeImagen = function () {
        var _a;
        this.clearImagen();
        this.changePreview(((_a = this.imageUrl[0]) === null || _a === void 0 ? void 0 : _a.url) || null);
        if (!this.imageUrl.length) {
            this.inputUpload.reset();
        }
    };
    PhotosLocationComponent.prototype.clearAll = function () {
        this.imageSelectedUrl = null;
        this.imageUrl = [];
        this.inputUpload.reset();
    };
    PhotosLocationComponent.prototype.addNewImage = function () {
        this.imageSelectedUrl = null;
        this.imageUrl.push({ url: '/assets/images/camera.svg', preview: '/assets/images/camera.svg', fileName: null, description: null });
        this.changePreview('/assets/images/camera.svg');
    };
    PhotosLocationComponent.prototype.showMedia = function (filelist, file, category, index) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalShowMediaComponent, {
            width: '680px',
            data: new ModalConfirmData({
                data: { filelist: filelist, media: file, category: category, index: index, locationId: this.locationId, accountId: this.accountId },
                title: null,
                content: null,
                confirmButtonLabel: 'Save',
                closeButtonLabel: 'Cancel'
            })
        });
        dialogRef.disableClose = true;
        var Subs = dialogRef.componentInstance.onDelete.subscribe(function (item) {
            if (item !== null)
                _this.getMedia();
        });
        dialogRef.afterClosed().take(2).subscribe(function () {
            // unsubscribe onAdd
            Subs.unsubscribe();
        });
    };
    PhotosLocationComponent.prototype.getResult = function () {
        var _this = this;
        var _a;
        if (!((_a = this.selectedCategory) === null || _a === void 0 ? void 0 : _a.value)) {
            return;
        }
        return this.imageUrl.map(function (el) {
            var mediaItem = {
                mediaFormat: _this.mediaFormat,
                locationAssociation: { category: _this.selectedCategory.value },
                sourceUrl: el.url,
                description: el.description
            };
            return mediaItem;
        });
    };
    return PhotosLocationComponent;
}(BaseComponent));
export { PhotosLocationComponent };
