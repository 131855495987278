import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ModalDeleteMediaComponent} from '../modal-delete-media/modal-delete-media.component';
import {ModalService} from '../../services/modal.service';
import {GoogleService} from '../../services/google.service';
import {SnackbarService} from '../../services/snackbar.service';
import {SwiperComponent} from "ngx-swiper-wrapper";
import { BehaviorSubject } from 'rxjs';
declare var Swiper: any;

@Component({
  selector: 'app-modal-show-media',
  templateUrl: './modal-show-media.component.html',
  styleUrls: ['./modal-show-media.component.scss']
})
export class ModalShowMediaComponent implements OnInit, AfterViewInit {
  @ViewChild('gallery', { static: false }) gallery: SwiperComponent;
  @ViewChild('galleryThumbs', { static: false }) galleryThumbs: SwiperComponent;
  onDelete: BehaviorSubject<any> = new BehaviorSubject(null);

  private readonly data: any;
  category: { value: string };
  mediaFiles: any;
  index: any;
  media: any;
  galleryThumbsConfig: any = {
    slidesPerView: 4,
    initialSlide: 0,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    simulateTouch: true,
    slideToClickedSlide: true
  };
  galleryConfig: any = {
    loop: true,
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 0, // Space between each Item,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    grabCursor: true

  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: any,
    public dialog: MatDialogRef<ModalShowMediaComponent>,
    private modalS: ModalService,
    private googleS: GoogleService,
    private snackS: SnackbarService,
  ) {
    this.data = this.config.data;
    this.mediaFiles = this.data.filelist;
    this.media = this.data.media;
    this.index = this.data.index;
    this.category = this.data.category;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.gallery.directiveRef.setIndex(this.index);
    this.galleryThumbs.directiveRef.setIndex(this.index);
  }

  changeGalleryIndex(e) {
    if ( e.target.tagName.toLowerCase() === 'img' ) {
      let thumbIndex = this.getSlideIndex(e.target.parentNode);
      this.gallery.directiveRef.setIndex(thumbIndex);
    }
  }
  getSlideIndex(el) {
    return [...el.parentNode.children].findIndex(c => c == el)
  }


  openDelete(file) {
    this.modalS.openGenericModal(ModalDeleteMediaComponent, {media: file}, confirm => {
      if (confirm) {
        this.googleS.deleteMedia(this.data.accountId, this.data.locationId, file.name).subscribe(result => {
          this.snackS.openSuccess('Media item removed successfully', 4000);
          this.onDelete.next(file)
          this.mediaFiles = this.mediaFiles.filter( mi => mi !== file);
        }, error => {
          this.snackS.openError('Delete media failed');
          console.error(error);
        });
      }
    });
  }

}
