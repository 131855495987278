// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// app
import { SessionService } from 'src/app/services/session.service';
var TextReaderComponent = /** @class */ (function () {
    function TextReaderComponent(_route, _sessionS) {
        this._route = _route;
        this._sessionS = _sessionS;
    }
    TextReaderComponent.prototype.ngOnInit = function () {
        var _a, _b, _c;
        var domain = this._sessionS.getDomain();
        var text = this._route.snapshot.params.text;
        var content = domain.content;
        var value = '';
        switch (text) {
            case 'terms-and-conditions':
                value = ((_a = content) === null || _a === void 0 ? void 0 : _a.terms_service_text) || '';
                this.title = 'Terms of Service';
                break;
            case 'privacy-policy':
                value = ((_b = content) === null || _b === void 0 ? void 0 : _b.privacy_policy_text) || '';
                this.title = 'Privacy Policy';
                break;
            case 'payment-policy':
                value = ((_c = content) === null || _c === void 0 ? void 0 : _c.payment_policy_text) || '';
                this.title = 'Payment Policy';
                break;
            default:
                break;
        }
        this.editor = value;
    };
    return TextReaderComponent;
}());
export { TextReaderComponent };
