import { __awaiter, __generator } from "tslib";
// dep
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// app
import { ModalService } from '../services/modal.service';
import { GROUP_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SubscriptionService } from '../services/subscription.service';
import { FeaturedComponent } from '../components/featured.component';
import { ModalGetAddOn } from '../modules/modal-get-addon/modal-get-addon.component';
import { SessionService } from '../services/session.service';
var GroupGatedPaidDirective = /** @class */ (function () {
    function GroupGatedPaidDirective(_modalService, _sessionS, _subscriptionService, _route, _element) {
        this._modalService = _modalService;
        this._sessionS = _sessionS;
        this._subscriptionService = _subscriptionService;
        this._route = _route;
        this._element = _element;
        this.gmbGGActive = function () { };
        this.session$ = this._sessionS.session$;
    }
    GroupGatedPaidDirective.prototype.ngOnInit = function () {
        var sub = this._sessionS.getSession().subscription;
        if (sub.status === GROUP_SUBSCRIPTION_TYPE.PAID) {
            this._element.nativeElement.removeAllListeners('click');
            this._element.nativeElement.addEventListener('click', this.handler.bind(this));
        }
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    GroupGatedPaidDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('GroupGatedPaidDirective handler');
                        return [4 /*yield*/, this._modalService.openModal(FeaturedComponent, null)];
                    case 1:
                        _a = (_b.sent());
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._subscriptionService.flowChangeLocationsPlan([this._route.snapshot.parent.params])];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (_a)
                            console.log("upgrade successful - group-gated.directive");
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupGatedPaidDirective;
}());
export { GroupGatedPaidDirective };
var GroupGatedTrialDirective = /** @class */ (function () {
    function GroupGatedTrialDirective(_modalService, _sessionS, _subscriptionService, _route, _element) {
        this._modalService = _modalService;
        this._sessionS = _sessionS;
        this._subscriptionService = _subscriptionService;
        this._route = _route;
        this._element = _element;
        this.gmbSubscriptionInTrial = function () { };
        this.session$ = this._sessionS.session$;
    }
    GroupGatedTrialDirective.prototype.ngOnInit = function () {
        var s = this._sessionS.getSession();
        if ((s.subscription.pricingVersion < 3) && s.isTrial) {
            this._element.nativeElement.removeAllListeners('click');
            this._element.nativeElement.addEventListener('click', this.handler.bind(this));
        }
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    GroupGatedTrialDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('GroupGatedTrialDirective handler');
                        return [4 /*yield*/, this._modalService.openModal(FeaturedComponent, null)];
                    case 1:
                        _a = (_b.sent());
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._subscriptionService.flowChangeLocationsPlan([this._route.snapshot.parent.params])];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (_a)
                            console.log("upgrade successful - group-gated.directive");
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupGatedTrialDirective;
}());
export { GroupGatedTrialDirective };
var GroupGatedBulkUpdateAddOnDirective = /** @class */ (function () {
    function GroupGatedBulkUpdateAddOnDirective(_modalService, _sessionS, _element) {
        this._modalService = _modalService;
        this._sessionS = _sessionS;
        this._element = _element;
        this.gmbBulkUpdateAddOn = function () { };
        this.session$ = this._sessionS.session$;
    }
    GroupGatedBulkUpdateAddOnDirective.prototype.ngOnInit = function () {
        if (!this._sessionS.getSession().bulkActionsEnabled) {
            this._element.nativeElement.removeAllListeners('click');
            this._element.nativeElement.addEventListener('click', this.handler.bind(this));
        }
    };
    GroupGatedBulkUpdateAddOnDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openModal(ModalGetAddOn, {
                            title: 'Add-On: Bulk Actions and Roll-Up Reports',
                            message: '',
                            buttons: {
                                'cancel': 'Maybe Later',
                                'accept': 'Get Add-On'
                            }
                        }, {
                            config: {
                                width: '830px'
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupGatedBulkUpdateAddOnDirective;
}());
export { GroupGatedBulkUpdateAddOnDirective };
