import { __awaiter, __generator } from "tslib";
import { Router } from '@angular/router';
// app
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
/**
 * This guard will enable the route iff:
 *   - There is a persistent authentication session available.
 *   - The session is included in the allowedSessionTypes on the route.
 */
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(_authS, _router) {
        this._authS = _authS;
        this._router = _router;
    }
    AuthGuardService.prototype.canActivate = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var auth;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authS.waitAuthSession()];
                    case 1:
                        auth = _a.sent();
                        if (auth && route.data.allowedSessionTypes.includes(auth.sessionType))
                            return [2 /*return*/, true];
                        return [2 /*return*/, this._router.parseUrl('/login')];
                }
            });
        });
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
