import { __awaiter, __generator } from "tslib";
// dep
import { ElementRef, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
var UserFeatureDirective = /** @class */ (function () {
    function UserFeatureDirective(_sessionS, _element) {
        this._sessionS = _sessionS;
        this._element = _element;
    }
    UserFeatureDirective.prototype.ngOnInit = function () {
        this._buildFeature();
    };
    UserFeatureDirective.prototype._buildFeature = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function () {
            var f, userFeature, generalFeature, newFeature, htmlEl, itemsToAddBeta_1, message_1;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        f = (_h.sent()).features;
                        this.userFeatures = (_a = f) === null || _a === void 0 ? void 0 : _a.userFeatures;
                        this.generalFeatures = (_b = f) === null || _b === void 0 ? void 0 : _b.generalFeatures;
                        this.newFeature = (_d = (_c = f) === null || _c === void 0 ? void 0 : _c.generalFeatures) === null || _d === void 0 ? void 0 : _d.isNewFeature;
                        userFeature = (_e = this.userFeatures) === null || _e === void 0 ? void 0 : _e[this.appUserFeature];
                        generalFeature = (_f = this.generalFeatures) === null || _f === void 0 ? void 0 : _f[this.appUserFeature];
                        newFeature = (_g = this.newFeature) === null || _g === void 0 ? void 0 : _g[this.appUserFeature];
                        if (generalFeature || userFeature) {
                            htmlEl = this._element.nativeElement;
                            htmlEl.classList.remove('d-none'); // Show the element
                            if (newFeature || !generalFeature) {
                                itemsToAddBeta_1 = htmlEl.getElementsByClassName('title-dark-launch');
                                message_1 = newFeature ? 'New' : 'Beta';
                                Object.keys(itemsToAddBeta_1).forEach(function (el) {
                                    itemsToAddBeta_1[el].innerHTML += "<span class=\"beta-mesage\">" + message_1 + "</span>";
                                });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return UserFeatureDirective;
}());
export { UserFeatureDirective };
