// dep 
import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

// app
import {LocationEditService} from "../../services/location-edit.service";
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-modal-company-address',
  templateUrl: './modal-company-address.component.html',
  styleUrls: ['./modal-company-address.component.scss']
})
export class ModalCompanyAddressComponent {

  @ViewChild("placesRef", {static: false}) placesRef : GooglePlaceDirective;

  public address: any;
  public optionsGoogle: any;

  constructor(
    public dialogRef: MatDialogRef<ModalCompanyAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _locationEditS: LocationEditService,
  ) {
    this.address = JSON.parse(JSON.stringify(this._locationEditS.locationEdit.address));
    if (!this.address.languageCode) this.address.languageCode = 'en';
    this.optionsGoogle = {
      types: ["street_address"],
      componentRestrictions: { country: this.address?.regionCode  }
      }
  }

  addStreet(): void {
    this.address.addressLines.push('');
  }

  removeAddress(index: number): void {
    if (index > 0) {
      this.address.addressLines.splice(index, 1);
    }
  }

  apply(): void {
    this.address.addressLines = this.address.addressLines.filter(el => (el != null) && (el != ""));
    this._locationEditS.locationEdit.address = this.address;
    this._locationEditS.setAndUpdate().toPromise().then(()=>{
      this.dialogRef.close();
    });
  }

  public handleAddressChange(adress: Address, i: number): void {
    
    const administrativeArea = adress.vicinity
    const stateCodeComponent = adress.address_components.find(component => component.types.includes("administrative_area_level_1"));
    const postalCodeComponent = adress.address_components.find(component => component.types.includes("postal_code"));
    
    // city
    this.address.locality = administrativeArea || this.address.administrativeArea;
    // state
    this.address.administrativeArea = stateCodeComponent? stateCodeComponent.short_name : this.address.locality;
    // zip code
    this.address.postalCode = postalCodeComponent? postalCodeComponent.short_name : this.address.postalCode;
    // street address
    this.address.addressLines[i] = adress.name;
  }
  
  handleChange($event, i: number): void {
    this.address.addressLines[i] = $event.target.value;
  }
}
