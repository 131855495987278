import { __awaiter, __generator } from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from './firestore.service';
import { GROUPS, USER } from '../constants/firestore/collections';
// import { Pageable } from '../constants/pageable';
import { environment as ENV } from '@environment';
import { HEADERS_NO_AUTH } from '../constants/auth';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "./firestore.service";
var UserService = /** @class */ (function () {
    function UserService(_http, _afs, _fs) {
        this._http = _http;
        this._afs = _afs;
        this._fs = _fs;
        this.dataStore = { accounts: [] };
        this._users = new BehaviorSubject([]);
        this.loading = false;
    }
    UserService.prototype.deleteUser = function (gid, uid) {
        return this._http.delete(ENV.apiUrl + "/v2/groups/" + gid + "/user/" + uid).toPromise();
    };
    UserService.prototype.addUserToGroup = function (gid, body) {
        return this._http.post(ENV.apiUrl + "/v2/groups/" + gid + "/user", body);
    };
    Object.defineProperty(UserService.prototype, "users", {
        get: function () {
            return this._users.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.setUsers = function (users) {
        this._users.next(users);
    };
    UserService.prototype._userRef = function (gid, uid) {
        return this._afs.collection(GROUPS).doc(gid).collection(USER).doc(uid);
    };
    UserService.prototype.getUserByUid = function (uid) {
        return __awaiter(this, void 0, void 0, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._afs.collectionGroup(USER, function (ref) { return ref.where('uid', '==', uid); }).get().toPromise()];
                    case 1:
                        r = _a.sent();
                        if (!r.docs.length)
                            return [2 /*return*/, null];
                        return [2 /*return*/, r.docs[0].data()];
                }
            });
        });
    };
    UserService.prototype.fetchUser = function (gid, uid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._userRef(gid, uid).get().toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data() || null];
                }
            });
        });
    };
    // TODO: Unused, remove
    // getByEmail(email: string) {
    //   return this.afs.collectionGroup<User>(USER, ref => ref.where('email', '==', email)).valueChanges();
    // }
    /**
     * Save in firestore entity User
     */
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    UserService.prototype.save = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._userRef(user.gid, user.uid).set(user)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.updateLastLogin = function (gid, uid, lastLoginDate, lastLoginXDomain) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // TODO: Date assigned on client side, must be done in the backend
                    return [4 /*yield*/, this.updateUser(gid, uid, { lastLogin: firebase.firestore.Timestamp.fromDate(lastLoginDate),
                            lastLoginDomain: lastLoginXDomain })];
                    case 1:
                        // TODO: Date assigned on client side, must be done in the backend
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Unused, remove
    /**
     * this method delete user entity in firestore
     * @param uid  this doc is key in firestore
     */
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    // delete(gid : string, uid : string) {
    //   return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(uid).delete();
    // }
    UserService.prototype.loadAll = function (gid) {
        var _this = this;
        this.loading = true;
        var query = this._afs.collection(GROUPS).doc(gid).collection(USER, function (ref) { return ref.where('gid', '==', gid)
            .orderBy('role', 'asc')
            .orderBy('displayName', 'asc')
            .limit(_this.paginator.pageSize); }).ref;
        this.paginator.pageIndex = 1;
        this._fs.colWithIds$(USER, function (ref) { return query; }).subscribe(function (data) {
            _this.dataStore.accounts = data;
            _this._users.next(Object.assign({}, _this.dataStore).accounts);
            _this.loading = false;
        });
    };
    // TODO: Unused, remove
    //   filter(user: User, filter?: any) {
    //     this.loading = true;
    //     let query; 
    //     if (_.has(filter, 'sortBy')) {
    //       query = this.afs.collectionGroup(USER, ref => ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
    //         .orderBy(filter.sortBy, filter.direction));
    //     } else {
    //       query = this.afs.collectionGroup(USER, ref => ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
    //         .orderBy('email', 'asc'));
    //     }
    // 
    //     this.afs.collectionGroup(USER, ref => query).get().subscribe(users => {
    //       const result = [];
    //       users.docs.forEach(u => {
    //         result.push(u.data());
    //       });
    //       this.dataStore.accounts = result;
    //       this.paginator.length = this.dataStore.accounts.length;
    //       this._users.next(Object.assign({}, this.dataStore).accounts);
    //       this.loading = false;
    //     });
    //   }
    // TODO: Unused, remove
    //
    //   getUsersByGroup(groupId: string) {
    //     const query = this.afs.collection(GROUPS).doc(groupId).collection(USER, ref => ref.where('gid', '==', groupId)
    //       .orderBy('email', 'asc')).ref;
    // 
    //     return this.fs.colWithIds$<User>(USER, ref => query);
    //   }
    /**
     * Update user entity in firestore
     */
    // TODO: Direct FS mutation, this must be replaced with a backend endpoint
    UserService.prototype.updateUser = function (gid, uid, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Update fields but will fail if the document doesn't exist
                    return [4 /*yield*/, this._userRef(gid, uid).update(data)];
                    case 1:
                        // Update fields but will fail if the document doesn't exist
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getUserChangesStream = function (gid, uid) {
        return this._userRef(gid, uid).valueChanges();
    };
    // TODO: Unused, remove
    //
    // countUsers() {
    //   return this.afs.collectionGroup(USER).valueChanges().pipe(map(r => r.length));
    // }
    //
    // getUsers(pageable: Pageable = {
    //   size: 10,
    //   page: 1
    // }, domain, next, prev, order = 'createdAt', direction = 'desc'): Observable<any> {
    //   return this.fs.paginateUsers(USER, domain, order, direction, pageable, next, prev);
    // }
    // 
    // getAllUsers() {
    //   return this.afs.collectionGroup(USER).get().map(query => query.docs.map(doc => doc.data()));
    // }
    // 
    // getUsersPaginate(count, pageable, actions) {
    //   return this.fs.formatPagination(count, pageable, actions);
    // }
    UserService.prototype.getEmailIsVerified = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var u;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchUser(user.gid, user.uid)];
                    case 1:
                        u = (_a.sent());
                        return [2 /*return*/, (u.emailVerificationHash === null) || (u.emailVerified !== null)];
                }
            });
        });
    };
    UserService.prototype.getUserFeature = function (uid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.get(ENV.apiUrl + "/v2/users/" + uid + "/enabled-features").toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserService.prototype.domainValidation = function (domain, gid, uid, domainSurfing) {
        var body = { domain: domain, gid: gid, uid: uid, domainSurfing: domainSurfing };
        return this._http.post(ENV.apiUrl + "/v2/auth/domain-validation", body, HEADERS_NO_AUTH).toPromise();
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.FirestoreService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
