import * as i0 from "@angular/core";
var AppConfigService = /** @class */ (function () {
    function AppConfigService() {
    }
    AppConfigService.prototype.fireConfig = function () {
        return {
            apiKey: 'AIzaSyAna8iSk-6bFXPsKwcD6Enj4fvgQIioW18',
            authDomain: (['localhost', 'app.maplabs.dev', 'maplabs.dev', 'app.nat.com'].includes(window.location.hostname) ?
                'app.maplabs.com' :
                window.location.hostname),
            databaseURL: 'https://gmb-review-dashboard.firebaseio.com',
            projectId: 'gmb-review-dashboard',
            storageBucket: 'gmb-review-dashboard.appspot.com',
            messagingSenderId: '156411061417'
        };
    };
    AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };
