import { __extends } from "tslib";
// app
import { ModalTrialEnded } from '../modules/modal-trial-ended/modal-trial-ended.component';
import { ModalService } from './modal.service';
import { SessionService } from './session.service';
import { BaseComponent } from '../components/base.component';
import * as i0 from "@angular/core";
import * as i1 from "./modal.service";
import * as i2 from "./session.service";
var BLOCK_REASON_MESSAGES = {
    'TRIAL_EXPIRED': { title: 'Your Free Subscription Has Ended',
        messageV2: ('Schedule a call with us to upgrade your account and make the best local search ' +
            'investment for your business; 100 TIMES MORE users than other local search channels!'),
        messageV3: ('Reach out to our sales team to upgrade your account and make the best local search ' +
            'investment for your business; 100 TIMES MORE users than other local search channels!') },
    'UNPAID_TOO_LONG': { title: 'Your Subscription is Unpaid',
        messageV2: 'Schedule a call with us to assist with your payments to continue using Map Labs.',
        messageV3: 'Reach out to our sales team to assist with your payments and continue using Map Labs.' },
    'MANUAL_BLOCK': { title: 'Your Subscription has been Blocked ',
        messageV2: 'Schedule a call with us to unblock your subscription and continue using Map Labs.',
        messageV3: 'Reach out to our sales team to unblock your subscription and continue using Map Labs.' }
};
// Only used on transition, TODO: Remove after MAP-2504 backend is deployed
var BLOCK_REASON_MESSAGES_DEFAULT = 'MANUAL_BLOCK';
var PaywallService = /** @class */ (function (_super) {
    __extends(PaywallService, _super);
    function PaywallService(_modalService, _sessionS) {
        var _this = _super.call(this) || this;
        _this._modalService = _modalService;
        _this._sessionS = _sessionS;
        var paywallIsOpen = false;
        _this._subscribeSafe(_this._sessionS.session$, function (s) {
            if (s.restrictToPaywall && !paywallIsOpen) {
                paywallIsOpen = true;
                _this._openPaywallModal(s);
            }
        });
        return _this;
    }
    PaywallService.prototype._blockReason = function () {
        return BLOCK_REASON_MESSAGES[this._sessionS.getSession().subscription.statusReason || BLOCK_REASON_MESSAGES_DEFAULT];
    };
    PaywallService.prototype._openAndRestrictToModal = function (buttons, message) {
        this._modalService.openModal(ModalTrialEnded, {
            title: this._blockReason().title,
            message: message,
            buttons: buttons
        }, {
            config: {
                width: '750px',
                // Restrict navigation, the user cannot navigate out of the Paywall modal
                disableClose: true
            }
        });
    };
    /**
     * Returns false if the user is not authenticated
     * Waits until the session is loaded by SessionService.
     */
    /*
    async canActivate(): Promise<boolean> {
      return true;
  
      const auth = await this._authS.waitAuthSession();
      if(!auth)
        // No persistent session found.
        return false;
  
      // if(auth.sessionType === 'EXTERNAL_GRADER')
      //   // External Grader is only allowed to navigate External Component
      //   return false;
  
      // Ensure the session is loaded before continuing loading
      // the route, so components can get the session
      // synchronously.
      // Other router guards must still use waitSession(), as they will be
      // executed in parallel with this.
      await this._sessionS.waitSession();
  
      return true;
    }
    */
    PaywallService.prototype._openPaywallModal = function (session) {
        var message;
        var buttons;
        switch (session.subscription.pricingVersion) {
            case 1:
            case 2: {
                message = this._blockReason().messageV2,
                    buttons = {
                        'accept_icon': 'far fa-calendar',
                        'accept_text': 'Schedule Call',
                        'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                        'cancel_text': 'Logout',
                    };
                break;
            }
            case 3: {
                message = this._blockReason().messageV3,
                    buttons = {
                        'accept_icon': 'fas fa-paper-plane',
                        'accept_text': 'Contact Sales',
                        'accept_cta': 'mailto:sales@maplabs.com',
                        'cancel_text': 'Logout',
                    };
                break;
            }
        }
        this._openAndRestrictToModal(buttons, message);
        return true;
    };
    PaywallService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaywallService_Factory() { return new PaywallService(i0.ɵɵinject(i1.ModalService), i0.ɵɵinject(i2.SessionService)); }, token: PaywallService, providedIn: "root" });
    return PaywallService;
}(BaseComponent));
export { PaywallService };
