// TODO: Refactor against modal-bulk-location-info.component.ts
import { __read, __spread } from "tslib";
// dep
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
// app
import { COMPONENTS_CORE, COMPONENTS_MISC } from '../../constants/location-info';
import { BulkLocationsEditService } from '../../services/bulk-locations-edit.service';
import { GoogleService } from '../../services/google.service';
import { ReportService } from '../../services/report.service';
import { ModalBulkLocationInfoComponent } from '../modal-bulk-location-info/modal-bulk-location-info.component';
import { ModalViewBulkLocationsChangesComponent } from '../modal-view-bulk-locations-changes/modal-view-bulk-locations-changes.component';
import { SessionService } from 'src/app/services/session.service';
var LocationInfoListComponent = /** @class */ (function () {
    function LocationInfoListComponent(_dialog, _reportS, _bulkEditService, _sessionS, _bulkLocationService, _googleS) {
        this._dialog = _dialog;
        this._reportS = _reportS;
        this._bulkEditService = _bulkEditService;
        this._sessionS = _sessionS;
        this._bulkLocationService = _bulkLocationService;
        this._googleS = _googleS;
        this.listCoreComponents = COMPONENTS_CORE;
        this.listMiscComponents = COMPONENTS_MISC;
        this.listActionLink = [];
        this.displayedColumns = ['createDate', 'property', 'locations', 'changes', 'progress', 'status'];
        this.dataSource = new MatTableDataSource([]);
        this.pendingBulkLocationExist = false;
        this.isSpinner = false;
        this.progressBulkLocation = true;
        this.paginate = { page: 1, size: 10 };
        this.selected = new FormControl(0);
        this.dateNow = new Date();
        this.last = null;
        this.next = null;
    }
    LocationInfoListComponent.prototype.ngOnInit = function () {
        var _this = this;
        var day = this.dateNow.getDate();
        var month = this.dateNow.getMonth();
        var year = this.dateNow.getFullYear();
        this.dateNow = new Date(year, month, day - 1);
        if (this.previousPageable) {
            if (this.previousPageable.page < this.pagination.page) {
                if (this.dataSource.data) {
                    this.last = this.dataSource.data[this.pagination.per_page - 1];
                }
            }
            else if (this.previousPageable.page > this.pagination.page) {
                if (this.dataSource.data) {
                    this.next = this.dataSource.data[0];
                }
            }
        }
        this.getBulkEdits(this.last, this.next);
        this.refreshIntervalStatus = setInterval(function () {
            _this.getBulkProgress(false);
        }, 10000);
    };
    LocationInfoListComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.refreshIntervalStatus);
    };
    LocationInfoListComponent.prototype.getBulkEdits = function (last, next) {
        var _this = this;
        this._bulkEditService.getByGid(this.paginate, this._sessionS.getSession().gid, last, next).subscribe(function (result) {
            _this.previousPageable = { size: result.per_page, page: result.page };
            _this.pagination = result;
            console.log(_this.dataSource.data);
            _this.dataSource.data = (result.items || []);
            _this.dataSource.data.forEach(function (el) {
                if (el['progress'] === undefined) {
                    el['progress'] = {
                        "data": {
                            "failed_locations": 0,
                            "total_locations": 0,
                            "updated_locations": 0
                        },
                        "message": "get bulkedit status"
                    };
                    el['createdDate'] = new Date(el['createdAt']);
                }
            });
            _this.getBulkProgress(true);
            _this.pendingBulkLocationExist = !!(_this.dataSource.data.length);
        }, function (err) {
            _this.progressBulkLocation = false;
        });
    };
    LocationInfoListComponent.prototype.progressValue = function (element) {
        var d = element.progress.data;
        var planned = (d.failed_locations + d.updated_locations);
        var value = (planned / d.total_locations) * 100;
        return value;
    };
    LocationInfoListComponent.prototype.getBulkProgress = function (force) {
        var _this = this;
        var count = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource.data.forEach(function (el) {
                if (force || el.status == 'PENDING') {
                    _this._bulkEditService.getBulkProgress(el._id).subscribe(function (res) {
                        count++;
                        el['progress'] = res;
                        el['progress']['value'] = _this.progressValue(el);
                        el['status'] = res['data']['bulk_status']['status'];
                        if (_this.dataSource.data.length == count) {
                            _this.progressBulkLocation = false;
                        }
                    }, function (err) {
                        _this.progressBulkLocation = false;
                        console.error(err);
                    });
                }
            });
        }
        else {
            this.progressBulkLocation = false;
        }
    };
    LocationInfoListComponent.prototype.GetNameProperty = function (component) {
        var _a, _b, _c;
        var componentChanged = (_a = component) === null || _a === void 0 ? void 0 : _a.component;
        if (!componentChanged)
            return '';
        if (!componentChanged.includes("Secondary-")) {
            if (componentChanged !== 'placeActionLinks') {
                var name_1 = this.listCoreComponents.find(function (l) { return l.key === componentChanged; });
                if (name_1)
                    return name_1.value;
                else
                    return this.listMiscComponents.find(function (l) { return l.key === componentChanged; }).value;
            }
            else {
                return "Place Action Links: " + ((_c = (_b = component) === null || _b === void 0 ? void 0 : _b.change) === null || _c === void 0 ? void 0 : _c.displayName);
            }
        }
        else {
            return "Secondary Website URLs";
        }
    };
    LocationInfoListComponent.prototype.create = function () {
        var _this = this;
        var dialog = this._dialog.open(ModalBulkLocationInfoComponent, { width: '60%' });
        dialog.disableClose = true;
        dialog.afterClosed().take(1).subscribe(function (bulkSaved) {
            if (!bulkSaved)
                return;
            _this.getBulkEdits(_this.last, _this.next);
            switch (bulkSaved.location_edit.component) {
                case 'photos':
                    _this.proccesMediaData(bulkSaved);
                    break;
                case 'service':
                    _this.proccesServiceList(bulkSaved);
                    break;
                case 'menu':
                    _this.proccesMenuList(bulkSaved);
                    break;
                default:
                    _this._bulkLocationService.processBulkEdit(bulkSaved).then();
                    break;
            }
        });
    };
    // TODO: Unused, remove?
    // repeatRequest(element: BulkLocationInfo): void {
    //   switch (element.component) {
    //     case 'photos':
    //       break;
    //     case 'service':
    //       break;
    //     case 'menu':
    //       break;
    //     default:
    //       this.getBulkEdits(this.last,this.next);
    //       this.bulkLocationService.processBulkEdit({ location_edit: element, updates: element.change }).then();  // TODO: then() intention was to await?
    //       break;
    //   }
    // }
    // TODO: refactor proccessMediaData, proccesServiceList, proccesMenuList, too much code repetition
    LocationInfoListComponent.prototype.proccesMediaData = function (bulkSaved) {
        var _this = this;
        var promises = [];
        var bulk_id = bulkSaved.location_edit._id;
        bulkSaved.location_edit.accounts.forEach(function (account) {
            var gid = account.gid;
            var accountId = account.accountId;
            account.locations.forEach(function (location) {
                var locationId = location.locationId;
                var data = [];
                bulkSaved.updates.forEach(function (media) {
                    data.push({
                        'media': media,
                        'bulk_id': bulk_id['$oid'],
                        'action': 'bulk edit'
                    });
                });
                promises.push(_this._googleS.saveBulkMedia(accountId, locationId, data).toPromise()
                    .then(function () { return null; })
                    .catch(function (error) { return ({ gid: gid, accountId: accountId, locationId: locationId }); }));
            });
        });
        var updateBulk = { status: 'COMPLETED' };
        Promise.all(promises).then(function (results) {
            results = results.filter(Boolean);
            if (results.length > 0) {
                if (bulkSaved.location_edit.accounts.reduce(function (n, item) { return n += item.locations.length; }, 0) === results.length) {
                    updateBulk.status = 'ERROR';
                }
                else {
                    updateBulk.status = 'PARTIAL';
                }
                var ErrorsAccounts = bulkSaved.location_edit.accounts.map(function (account) {
                    var group = results.find(function (e) { return e.gid === account.gid && e.accountId === account.accountId; });
                    if (group && group !== undefined) {
                        var locationsErrors = [];
                        var location_1 = account.locations.find(function (l) { return l.locationId === group.locationId; });
                        account.locations = account.locations.filter(function (l) { return l.locationId !== group.locationId; });
                        location_1['error'] = true;
                        locationsErrors.push(location_1);
                        account.locations = __spread(account.locations, locationsErrors);
                        return account;
                    }
                    return account;
                });
                updateBulk['accounts'] = ErrorsAccounts;
            }
            _this._bulkEditService.editBulk(bulkSaved.location_edit.gid, bulkSaved.location_edit._id['$oid'], updateBulk).then();
        });
    };
    LocationInfoListComponent.prototype.proccesServiceList = function (bulkSaved) {
        var _this = this;
        var promises = [];
        var bulk_id = bulkSaved.location_edit._id;
        bulkSaved.location_edit.accounts.forEach(function (account) {
            var gid = account.gid;
            var accountId = account.accountId;
            account.locations.forEach(function (location) {
                var locationId = location.locationId;
                var data = {
                    'serviceList': bulkSaved.updates,
                    'bulk_id': bulk_id['$oid'],
                    'action': 'bulk edit'
                };
                promises.push(_this._googleS.updateBulkServices(accountId, locationId, data).toPromise().then(function (service) {
                    if (!service)
                        return { gid: gid, accountId: accountId, locationId: locationId };
                    return null;
                }).catch(function (error) { return ({ gid: gid, accountId: accountId, locationId: locationId }); }));
            });
        });
        var updateBulk = { status: 'COMPLETED' };
        Promise.all(promises).then(function (results) {
            results = results.filter(Boolean);
            if (results.length > 0) {
                if (bulkSaved.location_edit.accounts.reduce(function (n, item) { return n += item.locations.length; }, 0) === results.length) {
                    updateBulk.status = 'ERROR';
                }
                else {
                    updateBulk.status = 'PARTIAL';
                }
                var ErrorsAccounts = bulkSaved.location_edit.accounts.map(function (account) {
                    var group = results.find(function (e) { return e.gid === account.gid && e.accountId === account.accountId; });
                    if (group && group !== undefined) {
                        var locationsErrors = [];
                        var location_2 = account.locations.find(function (l) { return l.locationId === group.locationId; });
                        account.locations = account.locations.filter(function (l) { return l.locationId !== group.locationId; });
                        location_2['error'] = true;
                        locationsErrors.push(location_2);
                        account.locations = __spread(account.locations, locationsErrors);
                        return account;
                    }
                    return account;
                });
                updateBulk['accounts'] = ErrorsAccounts;
            }
            _this._bulkEditService.editBulk(bulkSaved.location_edit.gid, bulkSaved.location_edit._id['$oid'], updateBulk).then();
        });
    };
    LocationInfoListComponent.prototype.proccesMenuList = function (bulkSaved) {
        var _this = this;
        var promises = [];
        var bulk_id = bulkSaved.location_edit._id;
        bulkSaved.location_edit.accounts.forEach(function (account) {
            var gid = account.gid;
            var accountId = account.accountId;
            account.locations.forEach(function (location) {
                var locationId = location.locationId;
                var data = {
                    'serviceList': bulkSaved.updates,
                    'bulk_id': bulk_id['$oid'],
                    'action': 'bulk edit'
                };
                promises.push(_this._googleS.updateFoodMenu(accountId, locationId, data).toPromise().then(function (service) {
                    if (!service)
                        return { gid: gid, accountId: accountId, locationId: locationId };
                    return null;
                }).catch(function (error) { return ({ gid: gid, accountId: accountId, locationId: locationId }); }));
            });
        });
        var updateBulk = { status: 'COMPLETED' };
        Promise.all(promises).then(function (results) {
            results = results.filter(Boolean);
            if (results.length > 0) {
                if (bulkSaved.location_edit.accounts.reduce(function (n, item) { return n += item.locations.length; }, 0) === results.length) {
                    updateBulk.status = 'ERROR';
                }
                else {
                    updateBulk.status = 'PARTIAL';
                }
                var ErrorsAccounts = bulkSaved.location_edit.accounts.map(function (account) {
                    var group = results.find(function (e) { return e.gid === account.gid && e.accountId === account.accountId; });
                    if (group && group !== undefined) {
                        var locationsErrors = [];
                        var location_3 = account.locations.find(function (l) { return l.locationId === group.locationId; });
                        account.locations = account.locations.filter(function (l) { return l.locationId !== group.locationId; });
                        location_3['error'] = true;
                        locationsErrors.push(location_3);
                        account.locations = __spread(account.locations, locationsErrors);
                        return account;
                    }
                    return account;
                });
                updateBulk['accounts'] = ErrorsAccounts;
            }
            _this._bulkEditService.editBulk(bulkSaved.location_edit.gid, bulkSaved.location_edit._id['$oid'], updateBulk).then();
        });
    };
    LocationInfoListComponent.prototype.countLocation = function (element) {
        return this._reportS.countLocation(element);
    };
    LocationInfoListComponent.prototype.handleReload = function ($event) {
        this.paginate = $event;
        this.ngOnInit();
    };
    LocationInfoListComponent.prototype.selectColor = function (element) {
        switch (element.toLowerCase()) {
            case 'pending':
                return 'primary';
            case 'completed':
                return 'accent';
            case 'partial':
                return 'warn';
            case 'error':
                return 'warn';
        }
    };
    LocationInfoListComponent.prototype.openViewChanges = function (element, deleted) {
        var _this = this;
        if (deleted === void 0) { deleted = false; }
        var dialogRef = this._dialog.open(ModalViewBulkLocationsChangesComponent, {
            width: '640px',
            data: {
                status: element.status,
                _id: element._id,
                accounts: element.accounts,
                component: element.component,
                deleted: deleted
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.progressBulkLocation = true;
                _this.ngOnInit();
            }
        });
    };
    return LocationInfoListComponent;
}());
export { LocationInfoListComponent };
