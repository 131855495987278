import { __assign, __awaiter, __extends, __generator, __read, __values } from "tslib";
// dep
import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { Messages, string_message } from "../../constants/messages";
import { CREATE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from "../../constants/notifications";
import { AccountService } from "../../services/account.service";
import { SessionTraceService as SessionTraceService } from "../../services/session-trace.service";
import { GoogleLocationService } from "../../services/google-location.service";
import { LocationService } from "../../services/location.service";
import { NotificationService } from "../../services/notification.service";
import { SpinnerService } from "../../services/spinner.service";
import { SessionService } from "src/app/services/session.service";
import { BaseComponent } from "src/app/components/base.component";
import { locationNameToRef } from "src/app/constants/firestore/location-object";
/**
 * A Dialog to choose N unadded Locations from a single Google Account to
 * add them.
 */
var DialogLocationsComponent = /** @class */ (function (_super) {
    __extends(DialogLocationsComponent, _super);
    function DialogLocationsComponent(data, dialogRef, _googleLocationService, _accountService, _spinnerService, _sessionS, _locationService, _notificationService, _route, _sessionTracingService) {
        var _a;
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this._googleLocationService = _googleLocationService;
        _this._accountService = _accountService;
        _this._spinnerService = _spinnerService;
        _this._sessionS = _sessionS;
        _this._locationService = _locationService;
        _this._notificationService = _notificationService;
        _this._route = _route;
        _this._sessionTracingService = _sessionTracingService;
        _this.googleLocations = [];
        _this.noDataFound$ = new BehaviorSubject(false);
        _this.progressBar$ = new BehaviorSubject(false);
        _this.selectedLocation = [];
        _this._debugMode = false;
        _this.selectedAccountId = _this.data.selectedAccountId;
        _this._googleAccounts$ = _this.data.googleAccounts;
        _this.accountId = (_a = _this.selectedAccountId) === null || _a === void 0 ? void 0 : _a.split('/')[1];
        _this._googleLocationService.reset();
        _this._googleLocationService.loadAll(_this.selectedAccountId);
        _this._addFinalizer(function () { return _this._accountService.reset(); });
        return _this;
    }
    DialogLocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this._route.queryParams, function (params) { var _a; return _this._debugMode = (((_a = params) === null || _a === void 0 ? void 0 : _a.mode) === 'debug'); });
        this.progressBar$.next(true);
        this._subscribeSafe(this._googleLocationService.locations$, function (result) { return _this._onLocationsChanged(result); }, function () {
            _this.noDataFound$.next(true);
            _this.progressBar$.next(false);
        });
    };
    DialogLocationsComponent.prototype._onLocationsChanged = function (result) {
        return __awaiter(this, void 0, void 0, function () {
            var resultRequest, gid, sl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        if (!result) {
                            this.noDataFound$.next(true);
                        }
                        resultRequest = result.map(function (_a) {
                            var address = _a.address, locationName = _a.locationName, name = _a.name, serviceArea = _a.serviceArea;
                            return ({ address: address, locationName: locationName, name: name, serviceArea: serviceArea });
                        });
                        gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this._locationService.filterOutLocationsAlreadyAdded(gid, this.accountId, resultRequest)];
                    case 1:
                        sl = _a.sent();
                        // TODO: This code was WRONG, gl.id was never existant so nothing/everything was filtered, remove.
                        // const filterL              = result.filter(g => g !== undefined && !sl.some(gl => locationName(g.name).locationId === gl.id));
                        // const alreadyExistLocation = result.filter(g => g !== undefined &&  sl.some(gl => locationName(g.name).locationId === gl.id));
                        // this.googleLocations = filterL;
                        // TODO: this had no effect as this.googleLocations was mutated here, but then it was overwritten by filterL, remove.
                        // this._verificateLocations(this.googleLocations);
                        this.googleLocations = sl;
                        this.resultSearchLocation = sl;
                        // TODO: As the filters before were wrong, using alreadyExistsLocation was also wrong, remove.
                        // if (this.googleLocations.length == 0 && alreadyExistLocation.length == 0 || (sl && sl.length === 0)) {
                        //   this.noDataFound$.next(true);
                        // } else {
                        //   this.noDataFound$.next(false);
                        // }
                        this.noDataFound$.next(!sl);
                        return [3 /*break*/, 3];
                    case 2:
                        this.progressBar$.next(false);
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogLocationsComponent.prototype.locationCheckboxDisabled = function (location) {
        var _a;
        return !!(!this._debugMode && ((_a = location) === null || _a === void 0 ? void 0 : _a.exists));
    };
    DialogLocationsComponent.prototype.allCheckedCheckboxDisabled = function (googleLocations) {
        var disabledReturn = true;
        if (this._debugMode) {
            disabledReturn = false;
        }
        googleLocations.forEach(function (element) {
            var _a;
            if (((_a = element) === null || _a === void 0 ? void 0 : _a.exists) !== true) {
                disabledReturn = false;
            }
        });
        return disabledReturn;
    };
    DialogLocationsComponent.prototype._verificateLocations = function (locations) {
        locations.forEach(function (location) {
            var ls = location.locationState;
            if (ls) {
                if (ls.isDisconnected) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Disconnected';
                    ls.fill = 'review-response--error';
                    ls.txt = 'txt--orange';
                }
                else if (ls.hasPendingVerification) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Pending verification';
                    ls.fill = 'review-response--error';
                    ls.txt = 'txt--orange';
                }
                else if (ls.isDisabled) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Disabled';
                    ls.fill = 'review-response--info';
                    ls.txt = 'txt--blue';
                }
                else if (ls.needsReverification) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Needs Reverification';
                    ls.fill = 'review-response--error';
                    ls.txt = 'txt--orange';
                }
                else if (ls.isSuspended) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Suspended';
                    ls.fill = 'review-response--info';
                    ls.txt = 'txt--blue';
                }
                else if (ls.isDuplicate) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Duplicate';
                    ls.fill = 'review-response--error';
                    ls.txt = 'txt--orange';
                }
                else if (!ls.isVerified) {
                    ls.description = 'Unavailable';
                    ls.tooltip = 'Location Unverified';
                    ls.fill = 'review-response--error';
                    ls.txt = 'txt--orange';
                }
            }
        });
    };
    DialogLocationsComponent.prototype.filterLocation = function () {
        var _this = this;
        var search = this.search.toLowerCase();
        this.googleLocations = this.resultSearchLocation.filter(function (location) {
            return location.locationName.toLowerCase().includes(search) ||
                _this._locationService.formatAddress(location.address || location.location.address).toLowerCase().includes(search);
        });
    };
    DialogLocationsComponent.prototype.addLocation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gid, subscription, _b, _c, loc, googleAcc, _d, acc, _e, _f, sl, l, meta, locationId, err_1;
            var e_1, _g, e_2, _h;
            var _this = this;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _a = this._sessionS.getSession(), gid = _a.gid, subscription = _a.subscription;
                        try {
                            for (_b = __values(this.selectedLocation), _c = _b.next(); !_c.done; _c = _b.next()) {
                                loc = _c.value;
                                delete loc.isChecked;
                                loc.formatAddress = this._locationService.formatAddress(loc.address);
                                loc.gid = gid;
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_g = _b.return)) _g.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        ;
                        _j.label = 1;
                    case 1:
                        _j.trys.push([1, 6, 7, 8]);
                        this._spinnerService.loading$.next(true);
                        return [4 /*yield*/, this._googleAccounts$.pipe(take(1)).toPromise()];
                    case 2:
                        googleAcc = (_j.sent()).find(function (acc) { return acc.name === _this.selectedAccountId; });
                        return [4 /*yield*/, this._accountService.saveAll([googleAcc])];
                    case 3:
                        _d = __read.apply(void 0, [_j.sent(), 1]), acc = _d[0];
                        //-- Add the list of selected locations 
                        return [4 /*yield*/, this._locationService.addNewLocations(acc.accountId, this.selectedLocation.map(function (loc) { return ({ locationName: loc.locationName,
                                locationId: locationNameToRef(loc).locationId }); }))];
                    case 4:
                        //-- Add the list of selected locations 
                        _j.sent();
                        //-- Refresh
                        return [4 /*yield*/, this._locationService.fetchAccountLocationsAndEmit(gid, acc.accountId)];
                    case 5:
                        //-- Refresh
                        _j.sent();
                        //-- Trace Locations adding
                        // Getting first location added date before saving locations
                        if (!subscription.firstLocationAddedOn)
                            this._sessionTracingService.onFirstLocationAdded();
                        try {
                            // this.onLocationsSaved.emit(true); // TODO: Unused, remove. 
                            for (_e = __values(this.selectedLocation), _f = _e.next(); !_f.done; _f = _e.next()) {
                                sl = _f.value;
                                l = __assign({ accountId: acc, locationId: locationNameToRef(sl).locationId }, sl);
                                meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, l);
                                this._notificationService.saveNotification(string_message(Messages.notifications.LOCATION_TOGGLE, [l.locationName, l.formatAddress, CREATE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_h = _e.return)) _h.call(_e);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        locationId = locationNameToRef(this.selectedLocation[0]).locationId;
                        this._sessionTracingService.onLocationAdded(acc.accountId, locationId, this.selectedLocation.length);
                        return [3 /*break*/, 8];
                    case 6:
                        err_1 = _j.sent();
                        console.error('Error saving accounts', err_1);
                        return [3 /*break*/, 8];
                    case 7:
                        this._spinnerService.loading$.next(false);
                        this.dialogRef.close(true);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    // check/uncheck all checkbox
    DialogLocationsComponent.prototype.toggleCheckAll = function () {
        var _this = this;
        if (!this.allChecked)
            this.selectedLocation = [];
        this.googleLocations.forEach(function (location) {
            var _a;
            if (!((_a = location) === null || _a === void 0 ? void 0 : _a.exists) || _this._debugMode) {
                location.isChecked = _this.allChecked;
                if (_this.allChecked) {
                    _this.selectedLocation.push(location);
                }
            }
        });
    };
    // if every item is checked, check the Select All checkbox
    DialogLocationsComponent.prototype.isAllChecked = function (location) {
        if (location.isChecked) {
            this.selectedLocation.push(location);
        }
        else {
            this.selectedLocation = this.selectedLocation.filter(function (l) { return location.name !== l.name; });
        }
        // TODO: Code without any effects, intention was?, remove. 
        // this.googleLocations.every(item => {
        //   return item.isChecked === true;
        // });
    };
    Object.defineProperty(DialogLocationsComponent.prototype, "someCheck", {
        // TODO: Getter called from template, slow
        get: function () {
            return this.selectedLocation.some(function (item) { return item.isChecked === true; });
        },
        enumerable: true,
        configurable: true
    });
    return DialogLocationsComponent;
}(BaseComponent));
export { DialogLocationsComponent };
