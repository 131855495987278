import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
// app
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocalPostsComponent } from '../local-posts/local-posts.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationService } from 'src/app/services/location.service';
import { AccountService } from 'src/app/services/account.service';
import { SessionService } from 'src/app/services/session.service';
var DashboardPostsComponent = /** @class */ (function () {
    function DashboardPostsComponent(_route, _snack, _modalService, _router, _locationS, _accountS, _sessionS) {
        this._route = _route;
        this._snack = _snack;
        this._modalService = _modalService;
        this._router = _router;
        this._locationS = _locationS;
        this._accountS = _accountS;
        this._sessionS = _sessionS;
        this.isRefreshing = false;
        this.isPurging = false;
        this.isNewPost = false;
        this.location = {};
    }
    DashboardPostsComponent.prototype.ngOnInit = function () {
        var _a;
        this.isNewPost = (_a = this._router.url) === null || _a === void 0 ? void 0 : _a.includes('post-management');
        this.accountId = this._route.snapshot.parent.params.accountId;
        this.locationId = this._route.snapshot.parent.params.locationId;
        this.getLocation();
    };
    DashboardPostsComponent.prototype.getLocation = function () {
        var _this = this;
        var gid = this._sessionS.getSession().gid;
        forkJoin([
            this._locationS.fetchLocation(gid, this.accountId, this.locationId),
            this._accountS.get(gid, this.accountId)
        ]).subscribe(function (res) {
            var _a, _b, _c, _d, _e, _f;
            _this.location = [{
                    "accountId": _this.accountId,
                    "accountName": (_a = res[1]) === null || _a === void 0 ? void 0 : _a.accountName,
                    gid: gid,
                    "locations": [{
                            "accountId": _this.accountId,
                            "locationId": _this.locationId,
                            "locationName": (_b = res[0]) === null || _b === void 0 ? void 0 : _b.locationName,
                            "address": (_d = (_c = res[0]) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.address,
                            "serviceArea": (_f = (_e = res[0]) === null || _e === void 0 ? void 0 : _e.location) === null || _f === void 0 ? void 0 : _f.serviceArea
                        }]
                }];
        });
    };
    DashboardPostsComponent.prototype.handleRefreshButton = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.isRefreshing = true;
                        return [4 /*yield*/, this.localpost.refresh()];
                    case 1:
                        _a.sent();
                        this._snack.openSuccess('Finished refreshing! ', 2000);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        this._snack.openError('Refresh request fail, try again later', 2000);
                        return [3 /*break*/, 4];
                    case 3:
                        this.isRefreshing = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPostsComponent.prototype.openModalConfirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openConfirmModal('Heads Up', 'Purging will delete any posts that are not currently on GBP', null, AlertType.INFO)];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.handlePurgeButton()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPostsComponent.prototype.handlePurgeButton = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.isPurging = true;
                        return [4 /*yield*/, this.localpost.purge()];
                    case 1:
                        _a.sent();
                        this._snack.openSuccess('Finished purging!');
                        return [3 /*break*/, 4];
                    case 2:
                        error_2 = _a.sent();
                        this._snack.openError('Purge request fail, try again later');
                        return [3 /*break*/, 4];
                    case 3:
                        this.isPurging = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DashboardPostsComponent;
}());
export { DashboardPostsComponent };
