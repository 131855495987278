import { __assign, __awaiter, __extends, __generator } from "tslib";
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import firebase from 'firebase/app';
// app
import { GoogleAccountService } from '../../services/google-account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleService } from '../../services/google.service';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { LocationService } from '../../services/location.service';
import { AccountLocSelectorComponent } from '../account-loc-selector/account-loc-selector.component';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import { LogsService } from 'src/app/services/logs.service';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
import { locationNameToRef } from '../../constants/firestore/location-object';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { BaseComponent } from 'src/app/components/base.component';
var ExternalGradeComponent = /** @class */ (function (_super) {
    __extends(ExternalGradeComponent, _super);
    function ExternalGradeComponent(dialog, snack, googleS, googleAccountS, accountService, locationService, gradeLeadService, _authS, titleService, logService, externalGradeService, _sessionS, navigationS) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.snack = snack;
        _this.googleS = googleS;
        _this.googleAccountS = googleAccountS;
        _this.accountService = accountService;
        _this.locationService = locationService;
        _this.gradeLeadService = gradeLeadService;
        _this._authS = _authS;
        _this.titleService = titleService;
        _this.logService = logService;
        _this.externalGradeService = externalGradeService;
        _this._sessionS = _sessionS;
        _this.navigationS = navigationS;
        /**
         * Authorization against Google was completed and grade was
         * saved, can show it.
         */
        _this.grade = false;
        _this.loading = false;
        _this.showGradePopup = true;
        _this.domain$ = _this._sessionS.domain$;
        _this.session$ = _this._sessionS.session$;
        _this.titleService.setTitle('Grader | ' + _this._sessionS.getDomain().branding.company_name);
        _this._subscribeSafe(_this._sessionS.session$, function (s) {
            if (s.sessionType !== 'EXTERNAL_GRADER') {
                console.debug('Logging out an non-EXTERNAL_GRADER session');
                _this._authS.signOut(false);
            }
        });
        return _this;
    }
    // async ngOnInit() {
    // localStorage.setItem(STORAGE_EXTERNAL_GRADE_ID, this.externalGradeId);
    // Unfinished code path. It seems this was intended to open the
    // grade without requiring the user to select the account/location,
    // using a previously saved selection
    // const gradeLeadJSON = localStorage.getItem(STORAGE_GRADE_LEAD)
    // if (gradeLeadJSON && this._auth.session) 
    //   await this._loadGradeLead(JSON.parse(gradeLeadJSON)); 
    // }
    // ngOnDestroy() : void {
    //   localStorage.removeItem(STORAGE_GRADE_LEAD);
    //   super.ngOnDestroy();
    // }
    /**
     * User clicks on "Start Grading"
     */
    ExternalGradeComponent.prototype.openAuth = function () {
        return __awaiter(this, void 0, void 0, function () {
            var externalGradeId, _a, uid, gid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        externalGradeId = "" + Date.now() + Math.round(Math.random() * 1000);
                        this.loading = true;
                        return [4 /*yield*/, this._authS.signInExternalGradeUser(externalGradeId)];
                    case 1:
                        _b.sent();
                        this.loading = false;
                        this._subscribeToGoogleResponse();
                        this.snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 5000);
                        _a = this._sessionS.getSession().user, uid = _a.uid, gid = _a.gid;
                        return [4 /*yield*/, this.googleAccountS.openPopupGoogleOAuth(gid, uid, externalGradeId)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ExternalGradeComponent.prototype._handleSelected = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gid, externalGradeId, location_1, account, googleAuth, accountExists, accountSave, saved, googleUser, registrationDomain, gradeLead, d, companyName, domain, metaData, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 9, 10, 11]);
                        _a = this._sessionS.getSession('EXTERNAL_GRADER'), gid = _a.gid, externalGradeId = _a.externalGradeId;
                        location_1 = $event.location;
                        account = $event.account;
                        this.accountId = locationNameToRef(location_1).accountId;
                        this.locationId = locationNameToRef(location_1).locationId;
                        this.location = { locationId: this.locationId,
                            accountId: this.accountId };
                        return [4 /*yield*/, this.externalGradeService.getExternalGradeToken(gid, externalGradeId)];
                    case 1:
                        googleAuth = _b.sent();
                        return [4 /*yield*/, this.accountService.get(gid, this.accountId).toPromise()];
                    case 2:
                        accountExists = _b.sent();
                        if (!!accountExists) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.accountService.save(__assign(__assign({}, account), { gid: gid, accountId: this.accountId, googleAuth: googleAuth }), true)];
                    case 3:
                        accountSave = _b.sent();
                        this.accountId = accountSave.accountId;
                        _b.label = 4;
                    case 4: return [4 /*yield*/, this.locationService.addNewLocations(this.accountId, [{ locationId: this.locationId,
                                locationName: location_1.locationName }])];
                    case 5:
                        saved = _b.sent();
                        if (!saved) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.googleS.getUserProfile(googleAuth)];
                    case 6:
                        googleUser = _b.sent();
                        registrationDomain = (new URL(window.location.href)).host;
                        gradeLead = {
                            locationId: this.locationId,
                            locationName: $event.location.locationName,
                            accountId: $event.account.name.split('/')[1],
                            accountName: $event.account.accountName,
                            createdAt: firebase.firestore.Timestamp.now(),
                            name: googleUser.name,
                            email: googleUser.email,
                            registrationDomain: registrationDomain,
                            address: $event.location.address,
                            viewed: false
                        };
                        d = this._sessionS.getDomain();
                        companyName = d.branding.company_name;
                        domain = d.branding.domain;
                        this.titleService.setTitle("Grade | " + gradeLead.locationName + " | " + companyName);
                        metaData = {
                            accountId: this.accountId,
                            locationId: this.locationId,
                            locationName: gradeLead.locationName,
                            email: googleUser.email,
                            displayName: googleUser.name
                        };
                        return [4 /*yield*/, this.logService.saveLog({
                                create_at: new Date(),
                                domain: domain,
                                gid: gid,
                                message: "A new public grade was created for " + gradeLead.locationName,
                                type_log: 'Grade',
                                meta_data: metaData
                            })];
                    case 7:
                        _b.sent();
                        this.gradeLeadService.save(gradeLead);
                        this.grade = true;
                        _b.label = 8;
                    case 8: return [3 /*break*/, 11];
                    case 9:
                        e_1 = _b.sent();
                        console.error(e_1);
                        this.snack.openError('There was a problem accessing your account. Please try again later.');
                        return [3 /*break*/, 11];
                    case 10:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Unfinished code-path, see STORAGE_GRADE_LEAD comments. Remove.
    // private async _loadGradeLead(gradeLead : GradeLead) : Promise<void> {
    //   this.accountId = gradeLead.accountId;
    //   this.loading = true;
    //   this.userExternal = await this._auth.signInExternalGradeUser();
    //   this.loading = false;
    //   this.locationId = gradeLead.locationId;
    //   this.location = { locationId: this.locationId, 
    //                      accountId: this.accountId };
    //   this.grade = true;
    //
    //   const {company_name} = this._sessionS.getDomain().branding;
    //   this.titleService.setTitle(`Grader | ${gradeLead.locationName} | ${company_name}`)
    // }
    ExternalGradeComponent.prototype.closePopup = function (event) {
        event.preventDefault();
        this.showGradePopup = false;
    };
    ExternalGradeComponent.prototype._subscribeToGoogleResponse = function () {
        var _this = this;
        var sub = this._subscribeSafe(this.googleAccountS.accounts$, function (accounts) {
            if (!_.isNil(accounts) && !_.isEmpty(accounts) && !_this.addAccountDialog) {
                _this.addAccountDialog = _this.dialog.open(AccountLocSelectorComponent, {
                    width: '680px',
                    data: { accounts: accounts,
                        user: _this._sessionS.getSession().user },
                    autoFocus: false
                });
                _this.addAccountDialog.backdropClick().subscribe(function () {
                    _this.addAccountDialog = null;
                    _this.dialog.closeAll();
                });
                _this.addAccountDialog.afterClosed()
                    .subscribe(function (response) {
                    sub.unsubscribe();
                    _this.googleAccountS.resetAccounts();
                    _this.addAccountDialog = null;
                    _this.dialog.closeAll();
                    if (response) {
                        _this.loading = true;
                        _this._handleSelected(response);
                    }
                });
            }
        });
    };
    return ExternalGradeComponent;
}(BaseComponent));
export { ExternalGradeComponent };
