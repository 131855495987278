// dep
import { Component, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// app
import { SubscriptionService } from '../../services/subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { LocationService } from '../../services/location.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { DOCUMENT } from '@angular/common';

const BODY_CSS_CLASS = 'trial-message-wrapper'

@Component({
  selector: 'app-trial-message',
  templateUrl: './trial-message.component.html'
})
export class TrialMessageComponent extends BaseComponent {
  private _refreshState : null | 'RUNNING' | 'SCHEDULED' = null;

  public messageType : null | 'SUBSCRIPTION_TRIAL' | 'LOCATION_FREE' = null
  public session$ = this._sessionS.session$;

  constructor(
    // dep:
    @Inject(DOCUMENT) 
    private _document: Document,
    private _route: ActivatedRoute,
    private _renderer : Renderer2,
    // app:
    private _subscriptionS: SubscriptionService,
    private _locationS: LocationService,
    private _sessionS : SessionService,
  ) {
    super();
    this._subscribeSafe(this.session$,                        _ => this._refresh());
    this._subscribeSafe(this._route.params,                   _ => this._refresh());
    this._subscribeSafe(this._locationS.someLocationChanged$, _ => this._refresh());
  }


  private async _refresh() : Promise<void> {
    if(this._refreshState === 'RUNNING' || this._refreshState === 'SCHEDULED') {
      this._refreshState = 'SCHEDULED';
    }
    this._refreshState = 'RUNNING'

    const s = this._sessionS.getSession();

    if(s.isMember)
      this.messageType = null;
    else if(s.isTrial)
      this.messageType = 'SUBSCRIPTION_TRIAL';
    else {
      const { accountId, locationId } = this._route.snapshot.parent.params;

      if(!(accountId && locationId && s.subscription.pricingVersion < 3)) {
        this.messageType = null;
      } else {
        // Is on a location page

        // TODO: This fetchs the location every time the route changes, must be optimized
        // so it uses an already cached _sessionS.location$
        const st = await this._locationS.fetchMultipleLocations(s.gid, [{accountId, locationId}], 
                                                                ['subscriptionType'] as ['subscriptionType'])[0]?.subscriptionType
        this.messageType = (st == LOCATION_SUBSCRIPTION_TYPE.FREE || 
                            st == LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) ? 'LOCATION_FREE' : null;
      } 
    } 

    // Must add/remove class to the 'body' element (outside any Angular component) to adjust
    // padding when the trial message is shown.
    // TODO: Remove the js-trial-message class on all files, not removed now to avoid
    // breaking smoke tests.
    // TODO: impersonate-message-component is dependent on this, must be refactored, so
    // both components are part of header.component.ts, and no class is added/removed
    // to the body element. Also note that currently if Trial+Impersonate are both
    // active, only the Impersonate message will show. 
    // Refactor also taking into account the usage of the grade-message CSS class, as it 
    // also adds a message on top. 
    if(this.messageType || s.isImpersonating)
      this._renderer.addClass(this._document.body, BODY_CSS_CLASS);
    else
      this._renderer.removeClass(this._document.body, BODY_CSS_CLASS);

    // @ts-ignore 
    // TS doesn't understand that this member can be changed by a concurrent call
    if(this._refreshState === 'SCHEDULED') {
      setTimeout(() => this._refresh(), 0);
    }

    this._refreshState = null;    
  }

  public async openLocationUpgrade() : Promise<void> {
    const { accountId, locationId } = this._route.snapshot.parent.params;
    if(!accountId || !locationId) {
      // must never happen
      return;
    }

    if(await this._subscriptionS.flowChangeLocationsPlan([{ locationId, accountId }]))
      this._refresh();
  }
    
  public async openSubscriptionUpgrade() : Promise<void> {
    const {subscription} = this._sessionS.getSession();

    if(await this._subscriptionS.stopTrial(subscription))
      this._refresh();
  }


}
