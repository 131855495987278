// dep
import { ErrorHandler } from '@angular/core';

// app
import { SessionService } from './session.service';
// Uses AuthProxyService instead of NavigationService to avoid runtime cyclic dep
import {AuthProxyService} from './auth.proxy.service';
import { ModalService } from './modal.service';

export class GlobalErrorHandler extends ErrorHandler {  

  constructor(private _sessionS: SessionService,
              private _authProxyS : AuthProxyService,
              private _modalS : ModalService
  ) {
    super();
    
    // Used to solve a cyclic dep by removing a SessionService->ModalService dep 
    this._sessionS.state$.subscribe(async (state) => {
      if(state !== 'ERROR_LOADING')
        return
      await this._modalS.openErrorLoadingModal(5);
      await this._authProxyS.signOut()
    })
  }

  async handleError(error : any) : Promise<void> {
    if (error?.name === 'FirebaseError') {
      console.error('Error FirebaseError from GlobalErrorHandler', error);
      await this._authProxyS.signOut()
    }
    throw error;
  }


}