// dep
import {EventEmitter, Injectable, OnDestroy } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// app
import {GoogleService} from './google.service';
import { GoogleAuth } from '../constants/firestore/user';
import GoogleAccount from '../constants/firestore/google-account';
import { ExternalGradeService } from './external-grade.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})

// This refers to Google GMB Accounts.
export class GoogleAccountService implements OnDestroy {
  private _accounts = new BehaviorSubject<GoogleAccount[]>([]);
  public readonly accounts$ = this._accounts.asObservable();

  onLoadAll$ = new EventEmitter<{success : true, token : GoogleAuth} | 
                                {success : false, details : Error, message : string }>();

  constructor(
    private _googleS: GoogleService,
    private _externalGradeS: ExternalGradeService,
    private _sessionS : SessionService
  ) {
  }

  resetAccounts() : void {
    this._accounts.next([]);
  }

  loadAll() : void { 
    const {googleAuth} = this._sessionS.getSession().user;
    if(googleAuth)     
      this._loadAccounts(googleAuth);
  }

  async loadAllGrade(gid : string, externalGradeId : string) : Promise<void> {
    const googleAuth = await this._externalGradeS.getExternalGradeToken(gid, externalGradeId)
    if(!googleAuth) {
      // User probably closed the google authentication popup without loggin-in
      return
    } else if (googleAuth.token_expiry) {
      await this._loadAccounts(googleAuth);
    }
  }

  private async _loadAccounts(googleAuth : GoogleAuth) : Promise<void> {
    try {
      const accounts = await this._googleS.getAccounts(googleAuth);

      accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1)
      this._accounts.next(accounts)
      this.onLoadAll$.emit({success: true, token: googleAuth})
    } catch(err) {
      const code = (err?.details as any)?.status;
      const message = ({ 901 : 'Oops! There was an error in the application',
                         401 : 'Google said you are not authorized! - Try again'}[code] ||
                       'Some odd error happened. Try again.')

      this.onLoadAll$.emit({success: false, details: err, message});
    }
  }

  // TODO: Must refactor as an high level flow against all callers of google.authenticate
  public async openPopupGoogleOAuth(gid : string, uid : string, externalId? : string) : Promise<void> {
    const urlOAuth = await this._googleS.authenticate(gid, uid, externalId);
    const win = window.open(urlOAuth, '_blank');
    // This popup ends up being a redirection so we cannot detect the real close event.
    // So we use an interval trick to overcome this.
    const popupInterval = setInterval(() => {
      if (win.closed) {
        clearInterval(popupInterval);
        this.loadAllGrade(gid, externalId);
      }
    }, 500);
  }

  ngOnDestroy() {
    this.onLoadAll$.complete();
  }

}


