/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-user-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./delete-user-dialog.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../services/user.service";
import * as i10 from "../../../services/snackbar.service";
var styles_DeleteUserDialogComponent = [i0.styles];
var RenderType_DeleteUserDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteUserDialogComponent, data: {} });
export { RenderType_DeleteUserDialogComponent as RenderType_DeleteUserDialogComponent };
export function View_DeleteUserDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog__header dialog__header--warn txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Are you sure you want to remove ", "?"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "dialog-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "txt--lg txt--center m--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This action cannot be undone!"])), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "dialog__footer mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(16, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn--action"], ["color", "warn"], ["mat-flat-button", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_6 = "primary"; _ck(_v, 15, 0, currVal_6); var currVal_7 = ""; _ck(_v, 16, 0, currVal_7); var currVal_10 = "warn"; _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.user.displayName; _ck(_v, 4, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 15).disabled || null); var currVal_3 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 16).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 16).type; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = (i1.ɵnov(_v, 19).disabled || null); var currVal_9 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_8, currVal_9); }); }
export function View_DeleteUserDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-user-dialog", [], null, null, null, View_DeleteUserDialogComponent_0, RenderType_DeleteUserDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.DeleteUserDialogComponent, [i2.MAT_DIALOG_DATA, i8.Router, i2.MatDialogRef, i9.UserService, i10.SnackbarService], null, null)], null, null); }
var DeleteUserDialogComponentNgFactory = i1.ɵccf("app-delete-user-dialog", i7.DeleteUserDialogComponent, View_DeleteUserDialogComponent_Host_0, {}, {}, []);
export { DeleteUserDialogComponentNgFactory as DeleteUserDialogComponentNgFactory };
