import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import * as _ from 'lodash';
import { switchMap } from 'rxjs/operators';
import { Pagination } from 'src/app/constants/api-response';
import { SIZE_OPTIONS } from 'src/app/constants/filter-reviews';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import { LocationService } from '../../services/location.service';
import { GoogleService } from '../../services/google.service';
import { ModalService } from '../../services/modal.service';
import { PdfService } from '../../services/pdf.service';
import { LoadingService } from '../../services/loading.service';
import { ReviewsService } from '../../services/reviews.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReportService } from 'src/app/services/report.service';
import { InsightsService } from 'src/app/services/insights.service';
import { SessionService } from 'src/app/services/session.service';
var ReviewReportTableComponent = /** @class */ (function () {
    function ReviewReportTableComponent(_sessionS, _route, _locationS, _googleService, _modalService, _pdfS, _loadingService, _reviewService, _afsStorage, _snack, _reportS, _insightS) {
        this._sessionS = _sessionS;
        this._route = _route;
        this._locationS = _locationS;
        this._googleService = _googleService;
        this._modalService = _modalService;
        this._pdfS = _pdfS;
        this._loadingService = _loadingService;
        this._reviewService = _reviewService;
        this._afsStorage = _afsStorage;
        this._snack = _snack;
        this._reportS = _reportS;
        this._insightS = _insightS;
        this.isShared = false;
        this.sizeOption = SIZE_OPTIONS;
        this.isLoading = false;
        this.isProgressCSV = false;
        this.isDowloadReviewLinks = false;
        this.isReportListAccordionOpen = false;
        this.gid = this._route.snapshot.params.gid || this._sessionS.getSession().gid;
    }
    ReviewReportTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.sort = {
            sortBy: 'reviewCount',
            direction: 'desc'
        };
        this.pagination = { page: 1, per_page: 25, items: [], hasNext: false, hasPrev: false, total: this.locations.length, pages: 0 };
        var numberPage = Math.ceil(this.locations.length / this.pagination.per_page);
        this.pagination.pages = numberPage;
        if (numberPage > 1) {
            this.pagination.hasNext = true;
        }
        (_a = this.refreshFilter) === null || _a === void 0 ? void 0 : _a.subscribe(function (result) {
            _this.dataPicker = result;
            _this.getTable();
        });
        this.getTable();
    };
    ReviewReportTableComponent.prototype.getTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.isLoading = true;
                this.displayedColumns = ['location', 'rating', 'total', 'responded', 'unresponded', 'link'];
                if (!this.dataPicker.hasOwnProperty('locations')) {
                    this.dataPicker.locations = this.locations;
                }
                this._reportS.getTableData(this.gid, 'reviews', { page: this.pagination.page, size: this.pagination.per_page }, this.sort, this.dataPicker).subscribe(function (res) {
                    var data = res.data;
                    _this.pagination = {
                        items: data['items'],
                        page: data['page'],
                        pages: data['totalPages'],
                        per_page: data['pageSize'],
                        total: data['total'],
                        hasNext: data['hasNext'],
                        hasPrev: data['hasPrev']
                    };
                    _this.setItems();
                    _this._loadingService.reportAdvanced(1, null);
                    _this.isLoading = false;
                }),
                    function (err) {
                        _this.isLoading = false;
                    };
                return [2 /*return*/];
            });
        });
    };
    /**
     * change to previous page
     */
    ReviewReportTableComponent.prototype.onPrev = function () {
        if (!this.pagination.hasPrev) {
            return;
        }
        this.onChangePage(this.pagination.page - 1);
    };
    /**
     * change a next page
     */
    ReviewReportTableComponent.prototype.onNext = function () {
        if (this.pagination.hasNext)
            this.onChangePage(this.pagination.page + 1);
    };
    /**
     * this method change page and consume http resource that filter reviews
     * @param $event
     */
    ReviewReportTableComponent.prototype.onChangePage = function ($event) {
        if ($event instanceof Object) {
            this.pagination.per_page = $event.pageSize;
            this.onChangePage(1);
            return;
        }
        this.pagination.page = $event;
        this.getTable();
    };
    ReviewReportTableComponent.prototype.setItems = function () {
        this.pagination.items = this.sort.direction === '' ? _.orderBy(this.pagination.items, ['location'], ['asc']) : _.orderBy(this.pagination.items, [this.sort.sortBy], [this.sort.direction]);
        this.source = this.pagination.items;
    };
    ReviewReportTableComponent.prototype.sortBy = function ($event) {
        this.sort = {
            sortBy: $event.active,
            direction: $event.direction,
        };
        this.onChangePage(1);
    };
    ReviewReportTableComponent.prototype.showReviewLink = function (locationId) {
        var _this = this;
        this._googleService.dynamicUrl(locationId).subscribe(function (url) {
            _this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url);
        });
    };
    ReviewReportTableComponent.prototype.locationUrl = function (accountId, locationId, destiny) {
        return "/account/" + accountId + "/location/" + locationId + "/" + destiny;
    };
    ReviewReportTableComponent.prototype.exportPDF = function (event) {
        event.stopPropagation();
        this._pdfS.exportTableToPDF('#table-location-container', 'Locations-PDF', 'Locations Table', true, 'Location');
    };
    ReviewReportTableComponent.prototype.exportCSV = function (event) {
        var _this = this;
        event.stopPropagation();
        // TODO: Never used, remove
        // let sort;
        // if (this.sort && this.sort.sortBy) {
        //   sort = this.sort;
        // }
        this.isProgressCSV = true;
        this._reportS.resumeExportCsv(this._sessionS.getSession().gid, 'reviews', this.sort, this.dataPicker, this.reportName)
            .pipe(switchMap(function (result) { return _this._afsStorage.ref(result['data']).getDownloadURL(); }))
            .subscribe(function (filename) {
            _this._insightS.openDownloadInNewTab(filename);
            _this.isProgressCSV = false;
            _this._snack.openSuccess("The file exported successfully");
        }, function (err) {
            _this.isProgressCSV = false;
            console.error(err);
            _this._snack.openError("The file export failed. Please try again later.");
        });
    };
    ReviewReportTableComponent.prototype.reportListAccordionChange = function () {
        this.isReportListAccordionOpen = !this.isReportListAccordionOpen;
    };
    ReviewReportTableComponent.prototype.exportCSVReviewLinks = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                event.stopPropagation();
                this.isDowloadReviewLinks = true;
                this._reviewService.reportReviewList(this.locations, this.dataPicker, 1, this.locations.length, this.sort).take(1).subscribe(function (data) {
                    _this.exportToCsv(data.items);
                }, function (err) {
                    _this.isDowloadReviewLinks = false;
                    console.error(err);
                    _this._snack.openError("The file export failed. Please try again later.", 2000);
                });
                return [2 /*return*/];
            });
        });
    };
    ReviewReportTableComponent.prototype.exportToCsv = function (locations) {
        return __awaiter(this, void 0, void 0, function () {
            var dataToExport, result, fileName, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.getExportData(locations)];
                    case 1:
                        dataToExport = _a.sent();
                        return [4 /*yield*/, this._reviewService
                                .exportCsvData(this.reportName + " review links.csv", dataToExport)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        return [4 /*yield*/, this._afsStorage
                                .ref(result['message']["srcFilename"])
                                .getDownloadURL()
                                .toPromise()];
                    case 3:
                        fileName = _a.sent();
                        window.open("" + fileName, "_blank");
                        this._snack.openSuccess("The file exported successfully", 2000);
                        this.isDowloadReviewLinks = false;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this._snack.openError("Error exporting the file, try again", 4000);
                        this.isDowloadReviewLinks = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ReviewReportTableComponent.prototype.getExportData = function (locations) {
        return __awaiter(this, void 0, void 0, function () {
            var urlPromises, urls;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlPromises = locations.map(function (location) { var _a; return _this._googleService.dynamicUrl((_a = location) === null || _a === void 0 ? void 0 : _a.placeId).toPromise(); });
                        return [4 /*yield*/, Promise.all(urlPromises)];
                    case 1:
                        urls = _a.sent();
                        return [2 /*return*/, locations.map(function (location, i) {
                                var _a, _b;
                                var address = _this._locationS.formatAddress((_a = location) === null || _a === void 0 ? void 0 : _a.address);
                                var serviceAreaPlaces = _this._locationS.formatServiceArea((_b = location) === null || _b === void 0 ? void 0 : _b.serviceArea);
                                return {
                                    'Location name': location.name,
                                    'Address/City/State/Zip/Country/Service Area': (address !== '--' && !!address) ? address : serviceAreaPlaces ? serviceAreaPlaces : '--',
                                    'Review link': urls[i].shortLink
                                };
                            })];
                }
            });
        });
    };
    return ReviewReportTableComponent;
}());
export { ReviewReportTableComponent };
