// dep
import { Observable, Subject } from "rxjs"


export const BROWSER_DOMAIN = {
    /** 
     * Current domain (e.g "app.maplas.com"). No protocol o port are included.
     */
     domainName  : window.location.hostname,

    /**
     * Current domain name in 'xdomain' format (e.g. "app_maplabs_com"), 
     * "slugDomain". It was created that way because Firestore couldn't 
     * store documents id's that included dots.
     */
     xDomainName : window.location.hostname.replace(/\./g, '_'),

    /** 
     * Current domain with port (e.g "app.maplas.com:443"). No protocol is included.
     * If the port is 80 or 443, it is omitted.
     */    
    domainNameWithPort : window.location.port ? `${window.location.hostname}:${window.location.port}` : window.location.hostname
    }
 
/**
 * Returns if the current page is running inside an iframe
 * Used for https://www.theflightschoolinc.com/reviews (see MAP-2017, MAP-2030)
 */
export function isRunningEmbedded() : boolean {
    return (window.location !== window.parent.location)
}

//----------------------------------------------------------------------------
// PromisedObservable
//----------------------------------------------------------------------------

export class ValueNotAvailableError extends Error {}

export type PromisedObservable<T> = Observable<T> & {
    /** Returns the latest value emmited by the Subject wrapped in a dummy
        Promise or returns a Promise that resolves when the Subject emits
        his first value.
        The promise is rejected when the Subject complete()'s.
    */
    currentValPromise : () => Promise<T>,

    /** Returns if a value was already emmited  */
    hasValue : () => boolean,

    /** Returns the latest value emmited by the Subject synchronously,
        if no value has been emmited yet, it will throw an ValueNotAvailableError
        (you can query hasValue() before calling this method)   
    */
    getSync  : () => T,

    /** Cancels the subscription and rejects the Promise returned by getValue(),
        is necesary to call this method to dispose the Subject subscription and
        avoid pipeline/memory leaks.
    */
    destroy : () => void 
}

/**
 * Given an RXJS Subject, returns a PromisedObservable, that is, an Observer augmented 
 * with the currentValPromise()/getSync() and destroy() methods
 * 
 * Differences between currentValPromise() and Observable.toPromise()
 *   - Observable.toPromise() requires the Observable to be complete()'ed to
 *     resolve.
 *   - This resolves to multiple values (by calling currentValPromise() multiple times), 
 *     Observable.toPromise() resolves to a single value
 *   - If the Observable is .completed() without emmiting a single value,
 *     then Observable.toPromise() resolves to 'undefined' (the signature is
 *     wrong on RXJS 6, but solved on RXJS 7). This excepts. 
 * 
 * - toPromise() should have been called "lastValPromise()" to avoid confusion.
 * 
 * TODO: Some usages can be replaced by firstValueFrom when upgrading RXJS from 6 to 7
 * (which requires TS 4.2, but we cannot upgrade to that TS version given our
 *  current Angular 8.3.4 version)
 */
export function asPromisedObservable<T>(upstreamSubject : Subject<T>, opts? : {injectVal? : T}) : PromisedObservable<T> {
       // Downstream
       // private _subject = new Subject<T>()

       let _promise : Promise<T> | null = null
       let _promiseResolve : (t : T) => void
       let _promiseReject  : (reason? : any) => void

       const _createPromise = () => {
            _promise = new Promise<T>((resolve, reject) => {
                _promiseResolve = resolve
                _promiseReject  = reject
            })
            // Avoid the "Uncaught (in promise)" error log
            _promise.catch(()=>{ /** empty on purpose **/ })
        }

       let _hasLastValue = false
       let _lastValue : T 

       const _onNewValue = (v : T) => {
            _lastValue = v
            _hasLastValue = true
            const _promiseResolvePrev = _promiseResolve
            _createPromise()
            _promiseResolvePrev(v)
            // _subject.next(v)
        }

        const destroy = (err : any = "PromisedObservable destroyed") : void => {
            if(!_promise)
                return
    
            _promise = null
            _upstreamSubscription.unsubscribe()
            // __subject.complete()
            _promiseReject(err)
        }

        // init
        _createPromise()
        const _upstreamSubscription = upstreamSubject.subscribe({
                next     : _onNewValue,
                error    : destroy,
                complete : () => destroy("PromisedObservable completed")
            })
            
        const currentValPromise = () : Promise<T> => {
            if(_hasLastValue)
                return Promise.resolve(_lastValue)
                
            if(!_promise)
                throw Error('PromisedObservable already completed/destroyed')
    
            return _promise
        }

        const hasValue = () : boolean => _hasLastValue

        const getSync = () : T => {
            if(!_hasLastValue) 
                throw new ValueNotAvailableError()
            return _lastValue
        }

        const o = upstreamSubject.asObservable()        
        for(const [k,v] of [['destroy',    destroy],
                            ['currentValPromise', currentValPromise],
                            ['hasValue',   hasValue],
                            ['getSync',    getSync]] as const) {
            if(k in o) {
                // maybe added in newer RXJS versions
                throw Error(`Patching Observable will override existing attr ${k}`);
            }
            o[k] = v
        }

        if(opts && 'injectVal' in opts) {
            upstreamSubject.next(opts.injectVal)
            // Ensure the value is synchronously available after
            // returning from this function
            _onNewValue(opts.injectVal)
        }

        return o as PromisedObservable<T>
    }

//----------------------------------------------------------------------------
// OpenPromise
//----------------------------------------------------------------------------

/**
 * A promise that exposes his resolve() and reject() functions so it can
 * be resolved/rejected by injecting values from the outside.
 */
export type OpenPromise<T> = Promise<T> & {resolve : (v : T | PromiseLike<T>) => void,
                                           reject  : (reason? : any) => void } 

export function makeOpenPromise<T>() : OpenPromise<T> {
    let p_resolve : OpenPromise<T>['resolve']
    let p_reject  : OpenPromise<T>['reject']

    const p = new Promise<T>((resolve, reject) => {
        p_resolve = resolve
        p_reject  = reject
    }) as OpenPromise<T>

    p.resolve = p_resolve
    p.reject  = p_reject
    return p
}

//----------------------------------------------------------------------------
// Array helpers
//----------------------------------------------------------------------------
export function markedPositions<T>(arr : T[]) : {isFirst : boolean, isLast: boolean, value : T}[] {
    const r = []
    for(let i = 0; i < arr.length ; ++i)
        r.push({value : arr[i],
                isFirst : i === 0,
                isLast  : i === (arr.length - 1)})
    return r
}