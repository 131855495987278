<div *ngIf="isLoading; else content" class="spinner-cont d-flex flex-column">
    <h1 class="mb--30">Loading Info...</h1>
    <mat-spinner></mat-spinner>
</div>
<ng-template #content>
    <mat-card>
        <ng-container *ngIf="subscription && pricingObject">
            <div cols="6" gutterSize="25px" class="plan-billing-gridlist">
                <!-- header -->
                <div class="plan-billing-header w--100 d-flex">
                    <div class="w--100 txt--xl mb--20">
                        Plan and Billing
                    </div>
                    <div class="w--100 txt--right disclaimer-pricing" *ngIf="isPricingVisible">All pricing is per profile, in USD, billed monthly.</div>
                </div>
                
                <!-- first row -->
                <div class="grid-row">
                    <div class="grid-tile mb--25 custom--w--33">
                        <mat-card class="w--100">
                            <mat-card-title>Usage</mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content class="d-flex pt--20">
                                <div class="w--50 txt--14">
                                    <div>Profiles</div>
                                    <div class="txt--24">{{ pricingObject.totalLocations }}</div>
                                </div>
                                    
                                <div class="w--50 txt--right" *ngIf="isPricingVisible">
                                    <div>Pricing</div>
                                    <div>$<span class="txt--24">{{ pricingObject.unitPrice }}</span>/mo</div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div  class="grid-tile mb--25 custom--w--33">
                        <mat-card class="w--100">
                            <mat-card-title>Add-ons</mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content class="p--tb-14 card-content">

                                <!-- this shows only if addons are part of the subscription -->
                                <div class="w--100">
                                    <ng-container *ngIf="!pricingObject.activePacks.includes('pkg_bulk_actions') || pricingObject.totalLocations <= 1">
                                        <div class="w--100">No Add-ons yet.</div>
                                    </ng-container>
                                    <ng-container *ngIf="pricingObject.activePacks.includes('pkg_bulk_actions') && pricingObject.totalLocations > 1">
                                        <div *ngIf="isPricingVisible" class="txt--md w--100 addon-line txt--14 justify-content-end">Pricing</div>
                                        
                                        <!-- this should be dynamic in the future (ngFor) and load all available addons -->
                                        <div class="txt--md w--100 addon-line justify-content-between">
                                            <div class="addon-item"><i class="far fa-check-circle addon-icon"></i>Bulk Actions</div>
                                            <div *ngIf="isPricingVisible && pricingObject.bulkPrice > 0">$<span class="txt--24">{{ pricingObject.bulkPrice }}</span>/mo</div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="w--100">
                                    <button 
                                    *ngIf="!pricingObject.activePacks.includes('pkg_bulk_actions') 
                                    && pricingObject.totalLocations > 1" 
                                    (click)="onAddAddons()" 
                                    class="pricing-button">
                                        Manage
                                    </button>
                                    <div *ngIf="pricingObject.totalLocations <= 1" class="txt--sm">Add-ons are only available for accounts with more than 1 profile</div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="grid-tile mb--25 custom--w--33" *ngIf="isPricingVisible">
                        <mat-card class="w--100">
                            <mat-card-title>Payment Method</mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content class="p--tb-14 card-content">

                                <div *ngFor="let card_info of pricingObject.cards; let i = index">
                                    <div class="pt--0 mb--20">
                                        <div class="row align-items-center">
                                            <div class="col-2">
                                                <div class="d-flex align-items-center">
                                                    <!-- could be moved to a new dumb component -->
                                                    <div class="credit-card">
                                                        <img src="/assets/images/cards/american-express.jpg" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'American Express'">
                                                        <img src="/assets/images/cards/diners-club.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'Diners Club'">
                                                        <img src="/assets/images/cards/discover.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'Discover'">
                                                        <img src="/assets/images/cards/jcb.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'JCB'">
                                                        <img src="/assets/images/cards/mastercard.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'MasterCard'">
                                                        <img src="/assets/images/cards/union-pay.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'UnionPay'">
                                                        <img src="/assets/images/cards/visa.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'Visa'">
                                                        <img src="/assets/images/cards/unknown.png" alt="{{card_info.brand}} Logo" *ngIf="card_info.brand === 'Unknown'">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-7 d-flex flex-column gap--5">
                                                <div class="fw--medium">
                                                    {{ card_info.brand }} end in {{ card_info.last4 }}
                                                </div>
                                                <div>
                                                    Expiry {{ card_info.exp_month }}/{{card_info.exp_year }}
                                                </div>

                                            </div>
                                            <!-- first card -->
                                            <ng-container *ngIf="i === 0">
                                                <div class="col-3">
                                                    <div class="pricing-badge compass-badge-blue">Default</div>
                                                </div>
                                            </ng-container>

                                            <!-- second to last cards -->
                                        </div>
                                        <div class="row mt--10">
                                            <ng-container>
                                                <div class="col-2"></div>
                                                <div class="col-8 d-flex gap--10">
                                                    <button class="card-actions" (click)="handleDefaultSource({card: card_info})" [disabled]="i === 0"> Make default </button>
                                                    <button class="card-actions" (click)="handleDeleteSource({card: card_info})"> Delete </button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="w--100">
                                    <button (click)="onAddCard()" class="pricing-button">
                                        Add New Card
                                    </button>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                
                <!-- second row -->
                <div class="grid-row">
                    <div class="grid-tile mb--25 custom--w--100" >
                        <mat-card class="w--100">
                            <mat-card-title>Billing Summary <span *ngIf="pricingObject.billingOverride" class="pricing-badge compass-badge-orange">This account has external billing.</span></mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content class="d-flex gap--20 pt--20">
                                <ng-container *ngIf="pricingObject.trialActive">

                                    <div class="w--33">
                                        <div class="billing-summary-subtitle mb--5">Trial ends on</div>
                                        <div class="billing-summary-date txt--24 mb--20">{{pricingObject.trialEnd | date: 'MM/dd/yyyy'}}</div>
                                        <div class="billing-summary-progress">
                                            <mat-progress-bar class="trial-progress-bar" mode="determinate" [value]="pricingObject.trialProgress"></mat-progress-bar>
                                        </div>
                                        <div class="billing-summary-daysleft">{{pricingObject.trialLeft}} days left in trial</div>
                                    </div>
                                    
                                    <div class="w--66">
                                        <div class="p--20 table--container">
                                            <div class="table-row d-flex justify-content-between txt--black align-items-center">
                                                <div>Monthly Total</div>
                                                <div class="free-trial-box">
                                                    <div class="pricing-badge compass-badge-green">Free during trial!</div>
                                                    <div>$<span class="txt--24 fw--700">0</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!pricingObject.trialActive">
                                    <div class="w--33">
                                        <div class="billing-summary-subtitle mb--5">Next Invoice</div>
                                        <div class="billing-summary-date txt--24 mb--20">{{pricingObject.nextInvoice | date: 'MMM dd yyyy'}}</div>
                                        <button *ngIf="!pricingObject.billingOverride"
                                                class="pricing-button" 
                                                (click)="onDownloadInvoicePDF(pricingObject?.invoices[0])"
                                                [matTooltip]="!pricingObject?.invoices[0] ?
                                                              'Invoice not available, please contact support' : ''" 
                                                [disabled]="!pricingObject?.invoices[0]">
                                            Download Last Invoice
                                        </button>
                                    </div>
                                    <div class="w--66">
                                        <div class="p--10 table--cont w--100" *ngIf="pricingObject.billingOverride">
                                            <div class="table--row fw--medium txt--14 w--100 d-flex txt--center">
                                                <br />
                                                <span>Contact our support team to request a copy of your Invoice.</span>
                                                <br />
                                            </div>
                                        </div>
                                        <div class="p--10 table--cont w--100" *ngIf="isPricingVisible">
                                            <div class="table--row fw--medium underline-row txt--14 w--100 d-flex txt--right">
                                                <div class="w--40 txt--left">Invoice Items</div>
                                                <div class="w--15">Qty</div>
                                                <div class="w--15">Unit Price</div>
                                                <div *ngIf="pricingObject.unitDiscount > 0" class="w--15">Discount</div>
                                                <div [class]="pricingObject.unitDiscount > 0 ? 'w--15' : 'w--30'">Total</div>
                                            </div>
                                            <div class="table--row underline-row txt--md  w--100 d-flex txt--right">
                                                <div class="w--40 txt--left">Base Profiles</div>
                                                <div class="w--15">{{ pricingObject.totalLocations }}</div>
                                                <div class="w--15">{{ pricingObject.unitPrice | currency:'USD':'symbol':'1.2-2' }}</div>
                                                <div *ngIf="pricingObject.unitDiscount > 0" class="w--15">-{{ pricingObject.unitDiscount | currency:'USD':'symbol':'1.2-2' }}</div>
                                                <div [class]="pricingObject.unitDiscount > 0 ? 'w--15' : 'w--30'">
                                                    {{ (pricingObject.totalLocations * (pricingObject.unitPrice - pricingObject.unitDiscount)) | currency:'USD':'symbol':'1.2-2' }}
                                                </div>
                                            </div>
                                            <ng-container *ngIf="pricingObject.activePacks.includes('pkg_bulk_actions') && pricingObject.totalLocations > 1">
                                                <div class="table--row underline-row txt--md  w--100 d-flex txt--right">
                                                    <div class="w--40 txt--left">Bulk Actions Add-On</div>
                                                    <div class="w--15">{{ pricingObject.totalLocations }}</div>
                                                    <div class="w--15">{{ pricingObject.bulkPrice | currency:'USD':'symbol':'1.2-2' }}</div>
                                                    <div class="w--30">
                                                        {{ pricingObject.totalLocations * pricingObject.bulkPrice | currency:'USD':'symbol':'1.2-2' }}
                                                    </div>
                                                </div>

                                            </ng-container>
                                            <div class="table--row txt--md  w--100 d-flex">
                                                <div class="w--50 txt--left">Monthly Total</div>
                                                <div class="w--50 txt--right txt--22 fw--700"><span class="txt--18">$</span>{{ pricingObject.totalPrice | currency:'USD':'':'1.2-2' }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                
                <!-- third row -->
                <div class="grid-row" *ngIf="!pricingObject.billingOverride">
                    <div class="grid-tile mb--25 custom--w--100">
                        <mat-card class="w--100">
                            <mat-card-title>Invoices History</mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content class="pt--20 invoice-card">
                                <div *ngIf="!(pricingObject?.invoices?.length > 0)">No invoices yet.</div>
                                <ng-container *ngIf="pricingObject?.invoices?.length > 0">
                                    
                                    <table mat-table [dataSource]="pricingObject.invoices"
                                        class="table table--bordered cells-no-border">

                                        <!-- Number Column -->
                                        <ng-container matColumnDef="number">
                                            <th mat-header-cell *matHeaderCellDef><strong>Invoice #</strong></th>
                                            <td mat-cell *matCellDef="let element" class=""> {{element._id.$oid}}</td>
                                        </ng-container>

                                        <!-- Date Column -->
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef class="txt--left"><strong>Date</strong></th>
                                            <td mat-cell *matCellDef="let element" class="txt--left"> {{element.date.$date | date:
                                                'MM/dd/yy'}}</td>
                                        </ng-container>

                                        <!-- Status Column -->
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="mat-header-cell txt--left">
                                                <strong>Status </strong>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="txt--left">
                                                <span
                                                    class="pricing-badge compass-badge-green"
                                                    *ngIf="element.status.toLowerCase() === 'paid'">{{element.status}}</span>
                                                <span
                                                    class="pricing-badge compass-badge-orange"
                                                    *ngIf="element.status.toLowerCase() === 'pending' || element.status.toLowerCase() === 'pre authorized'">{{element.status}}</span>
                                                <span
                                                    class="pricing-badge compass-badge-red"
                                                    *ngIf="element.status.toLowerCase() === 'unpaid' || element.status.toLowerCase() === 'canceled'">{{element.status}}</span>
                                            </td>
                                        </ng-container>

                                        <!-- Amount Column -->
                                        <ng-container matColumnDef="amount">
                                            <th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Amount</strong></th>
                                            <td mat-cell *matCellDef="let element" class="txt--right"> {{element.total | currency}} </td>
                                        </ng-container>

                                        <!-- Actions Column -->
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef class="txt--right column--xs">
                                                <!-- <strong>Actions</strong> -->
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="txt--right column--xs">
                                                <button 
                                                class="download-invoice-button"
                                                (click)="onDownloadInvoicePDF(element)"
                                                >
                                                    <i class="fa fa-download download-invoice-icon" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: listColumns;"></tr>
                                    </table>




                                </ng-container>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                
                <!-- fourth row -->
                <div class="grid-row">
                    <div class="grid-tile software-pricing-cont w--100">
                        <div class="pricing-header d-flex justify-content-between">
                            <div class="pricing-title txt--xxl">Software Pricing</div>
                            <div class="pricing-button pricing-details-button txt--14 fw--medium" (click)="navigationS.goToPricingWeb()">Full Pricing Details <i class="fas fa-external-link"></i></div>
                        </div>
                        <div class="pricing-card-cont">
                            <mat-card class="w--100">
                                <mat-card-header class="maplabs-card">
                                    <mat-card-title class="txt--xxl fw--700">Map Labs</mat-card-title>
                                    <mat-card-subtitle>Simple monthly pricing, based on your business's number of profiles</mat-card-subtitle>
                                </mat-card-header>
                                    <mat-divider></mat-divider>
                                <mat-card-content class="software-pricing-maplabs-cont">
                                    <div class="software-pricing-cont-row software-pricing-cont-header">
                                        <div>Number of Profiles</div>
                                        <div>Price per Profile</div>
                                    </div>
                                    <div 
                                    *ngFor="let tier of pricingObject.coreTiers; let i = index" 
                                    class="software-pricing-cont-row" 
                                    [ngClass]="{
                                        'active': tier.isActive, 
                                        'underline-row': i !== pricingObject.coreTiers.length - 1
                                    }"
                                    >
                                        <div>{{tier.profileSegment}}</div>
                                        <div>{{tier.segmentPriceString}}</div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="w--100">
                                <mat-card-header class="addon-card">
                                    <mat-card-title class="txt--xxl fw--700">Add-on: Bulk Actions</mat-card-title>
                                    <mat-card-subtitle>Add Roll-Up Reports, Bulk updates, and Bulk Posts to your plan</mat-card-subtitle>
                                </mat-card-header>
                                <mat-divider></mat-divider>
                                <mat-card-content class="software-pricing-bulk-cont">
                                    <div class="software-pricing-cont-row software-pricing-cont-header">
                                        <div>Number of Profiles</div>
                                        <div>Price per Profile</div>
                                    </div>
                                    <div 
                                    *ngFor="let tier of pricingObject.bulkActionsTiers; let i = index" 
                                    class="software-pricing-cont-row" 
                                    [ngClass]="{
                                        'active': tier.isActive, 
                                        'underline-row': i !== pricingObject.bulkActionsTiers.length - 1
                                    }"
                                    >
                                        <div>{{tier.profileSegment}}</div>
                                        <div>{{tier.segmentPriceString !== '$0/mo' ? tier.segmentPriceString : '-'}}</div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="prof-serv-cont w--100 mt--30 d-flex flex-column gap--30">
                            <mat-card>
                                <mat-card-header>
                                    <mat-card-title class="txt--xxl fw--black mt--10">Professional Services</mat-card-title>
                                    <mat-card-subtitle>
                                        <div class="w--66">
                                            Map Labs offers specialized services such as coaching and consultation with Map Labs expert, end-to-end management of Google Maps marketing and Google Ads. 
                                        </div>
                                    </mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content>
                                    <button class="pricing-button prof-serv-button mt--10 mb--10" (click)="navigationS.goToProfessionalServicesWeb()">
                                        <span>Contact us to learn more</span>
                                        <i class="fas fa-external-link"></i>
                                    </button>
                                    <img class="prof-serv-logo" src="/assets/images/maplabs_logo_white.svg" alt="maplabs_white">    
                                </mat-card-content>
                            </mat-card>
                            <!-- <div class="w-100 disclaimer-pricing">All pricing is per profile, in USD, billed monthly.</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-card>
</ng-template>