import { __awaiter, __generator } from "tslib";
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// app
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
var ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/13075898/23djv7q/';
var SessionTraceService = /** @class */ (function () {
    function SessionTraceService(
    // private _http: HttpClient
    ) {
        this._isGtagEnabled = false;
        this._isUserGuidingEnabled = false;
        this._uid = null;
        this._info = null;
        // pass
    }
    /**
     * Set if the service is enabled, if not, all calls will be ignored and no
     * communication with UserGuiding will be made.
     * @param enabled
     */
    SessionTraceService.prototype.setEnableUserGuiding = function (enabled) {
        this._isUserGuidingEnabled = enabled;
    };
    /**
     * Set if the gtag service is enabled, if not, all calls will be ignored and no
     * communication with gtag will be made.
     * @param enabled
     */
    SessionTraceService.prototype.setEnableGtag = function (enabled) {
        this._isGtagEnabled = enabled;
    };
    SessionTraceService.prototype._analyticsQ = function () {
        var w = window;
        w.jitsuQ = w.jitsuQ || [];
        return w.jitsuQ;
    };
    SessionTraceService.prototype._userGuiding = function () {
        return __awaiter(this, void 0, void 0, function () {
            var w, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        w = window;
                        return [4 /*yield*/, w.userGuidingIsLoaded];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, w.userGuiding];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionTraceService.prototype._track = function (event, info) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        // Gtag event
                        if (this._isGtagEnabled) {
                            try {
                                gtag('event', event);
                            }
                            catch (err) {
                                console.error(err);
                            }
                        }
                        // SEE https://docs.jitsu.com/sending-data/html#jitsu-processing-queue
                        this._analyticsQ().push(function (jitsu) {
                            jitsu.track(event, info);
                        });
                        if (!this._isUserGuidingEnabled) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._userGuiding()];
                    case 1: 
                    // TODO: UserGuiding doesn't have an specific way to signal user logout?
                    return [2 /*return*/, (_a.sent()).track(event, info)];
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        console.error(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SessionTraceService.prototype._datadogSetUser = function (uid, email) {
        if (ENV.env == 'prod') {
            // Now loading via async so need to ensure we 'wait' via onReady
            var DD_RUM_1 = window.DD_RUM;
            DD_RUM_1.onReady(function () {
                DD_RUM_1.setUser({
                    'id': uid,
                    'email': email
                });
            });
        }
    };
    SessionTraceService.prototype._identify = function (uid, info) {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        // SEE https://docs.jitsu.com/sending-data/html#jitsu-processing-queue
                        this._analyticsQ().push(function (jitsu) { return jitsu.identify(uid, info); });
                        if (!this._isUserGuidingEnabled) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._userGuiding()];
                    case 1:
                        (_a.sent()).identify(uid, info);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        console.error(err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Must be called when the user logs in.
     */
    SessionTraceService.prototype.onLogin = function (uid, info) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this._uid)
                    // avoid double log-in miscalls
                    return [2 /*return*/];
                this._uid = uid;
                this._info = info;
                this._datadogSetUser(uid, info.email);
                this._identify(uid, info);
                return [2 /*return*/];
            });
        });
    };
    /**
     * Must be called when the user logs out.
     */
    SessionTraceService.prototype.onLogout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uid;
            return __generator(this, function (_a) {
                uid = this._uid;
                this._uid = null;
                this._info = null;
                this._track("session logout", { userId: uid });
                return [2 /*return*/];
            });
        });
    };
    /**
     * Must be called when a new Location is added.
     */
    SessionTraceService.prototype.onLocationAdded = function (accountId, locationId, totalLocations) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._track("location added", { userId: this._uid,
                    accountId: accountId,
                    locationId: locationId,
                    totalLocations: totalLocations });
                return [2 /*return*/];
            });
        });
    };
    SessionTraceService.prototype.onFirstLocationAdded = function () {
        return __awaiter(this, void 0, void 0, function () {
            var body, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        body = { data: {
                                email: this._info['email'],
                                gid: this._info['gid'],
                                uid: this._uid,
                                add_first_listing: "true"
                            }
                        };
                        // When using Angular HTTPClient, a default Content-Type 'application/json' is
                        // added. This rejected by Zapier CORS, see:
                        // - https://help.zapier.com/hc/en-us/articles/8496291737485-Troubleshoot-webhooks-in-Zapier#h_01HCZE2Q4N14HEFS0CWTXZ833C
                        // So we need to use plain fetch(), as the Angular HTTPClient doesn't seems to allow
                        // not sending the Content-Type header.
                        //
                        // await this._http.post<any>(ZAPIER_URL, body, HEADERS_NO_AUTH).toPromise()
                        return [4 /*yield*/, fetch(ZAPIER_URL, { method: 'POST',
                                body: JSON.stringify(body),
                                mode: 'no-cors' })];
                    case 1:
                        // When using Angular HTTPClient, a default Content-Type 'application/json' is
                        // added. This rejected by Zapier CORS, see:
                        // - https://help.zapier.com/hc/en-us/articles/8496291737485-Troubleshoot-webhooks-in-Zapier#h_01HCZE2Q4N14HEFS0CWTXZ833C
                        // So we need to use plain fetch(), as the Angular HTTPClient doesn't seems to allow
                        // not sending the Content-Type header.
                        //
                        // await this._http.post<any>(ZAPIER_URL, body, HEADERS_NO_AUTH).toPromise()
                        _a.sent();
                        console.info("Event succeeded: Added first listing");
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        console.error('Error sending info to Zapier', err_4);
                        return [3 /*break*/, 3];
                    case 3:
                        this._track('addFirstListing');
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionTraceService.prototype.onRegistration = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._track('completeSignup');
                return [2 /*return*/];
            });
        });
    };
    SessionTraceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionTraceService_Factory() { return new SessionTraceService(); }, token: SessionTraceService, providedIn: "root" });
    return SessionTraceService;
}());
export { SessionTraceService };
