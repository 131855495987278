import { __extends } from "tslib";
// dep
import { Router } from '@angular/router';
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
// app
import { ReviewsService } from '../../services/reviews.service';
import { LoadingService } from '../../services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { Subject } from 'rxjs';
var ReviewsNumberComponent = /** @class */ (function (_super) {
    __extends(ReviewsNumberComponent, _super);
    function ReviewsNumberComponent(_reviewsS, _loadingService, _router, _sessionS) {
        var _this = _super.call(this) || this;
        _this._reviewsS = _reviewsS;
        _this._loadingService = _loadingService;
        _this._router = _router;
        _this._sessionS = _sessionS;
        _this.tooltip = null;
        _this.isProgressNumber = true;
        _this.destroySubs$ = new Subject();
        return _this;
    }
    ReviewsNumberComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.refreshFilter) {
            this._subscribeSafe(this.refreshFilter, function (result) {
                _this.isProgressNumber = true;
                _this.dataPicker = result;
                _this.getData();
            });
        }
        this.getData();
    };
    ReviewsNumberComponent.prototype.ngOnChanges = function (changes) {
        if (changes.reviewsData) {
            this.getData();
        }
    };
    ReviewsNumberComponent.prototype.getData = function () {
        var _this = this;
        var isReport = this._router.url.includes('report');
        if (this.reviewsData) {
            this.buildData(this.reviewsData);
        }
        else {
            if (isReport) {
                this._subscribeSafe(this._reviewsS.aggregationNumber(this.locations, this.dataPicker), function (result) { return _this.buildData(result); });
            }
            else {
                this._subscribeSafe(this._reviewsS.getRatingsAndReviewsData(this.locations[0].accountId, this._sessionS.getSession().gid, this.locations[0].locationId, this.dataPicker), function (result) { return _this.buildData({ data: result.reviews,
                    labels: result.dates,
                    total: result.total_reviews }); });
            }
        }
    };
    ReviewsNumberComponent.prototype.buildData = function (result) {
        var _a, _b;
        // Remove extra days
        if (this.dataPicker.aggregation === 'Day') {
            var start = moment(this.dataPicker.range.start);
            var end = moment(this.dataPicker.range.end);
            var days = end.diff(start, 'days') + 1;
            var dropValues = ((_a = result.labels) === null || _a === void 0 ? void 0 : _a.length) - days;
            result['data'] = _.dropRight(result.data, dropValues);
            result['labels'] = _.dropRight(result.labels, dropValues);
        }
        this.datasetBarNumber = [{ data: result.data, label: 'Number' }];
        this.labelsBarRating = result.labels;
        if ((_b = result) === null || _b === void 0 ? void 0 : _b.total) {
            this.total = result.total;
        }
        else {
            this.total = this.getTotal(this.datasetBarNumber[0].data);
        }
        this.isProgressNumber = false;
        this._loadingService.reportAdvanced(1, 'Number Reviews');
    };
    /**
     * this method get total sum of a array
     */
    ReviewsNumberComponent.prototype.getTotal = function (array) {
        var _a;
        var total = 0;
        (_a = array) === null || _a === void 0 ? void 0 : _a.forEach(function (x) {
            total += x;
        });
        return total;
    };
    return ReviewsNumberComponent;
}(BaseComponent));
export { ReviewsNumberComponent };
