<div class="dialog__header">
  <div class="dialog__close">
    <button mat-icon-button mat-dialog-close tabIndex="-1" class="btn btn-icon btn-icon--txt" *ngIf="isDiffMask">
      <em class="fal fa-times"></em>
    </button>
  </div>

  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{title | titlecase}}</strong></h2>
</div>
<div class="dialog__content p--0 border-bottom mb--25" mat-dialog-content *ngIf="difference?.length > 0">

  <mat-progress-bar mode='indeterminate' *ngIf='isProgress'></mat-progress-bar>

  <div *ngIf="!isEmptyObject(error)" class="row m--0 dialog--padding border-bottom mt--10 mb--10">
    <app-error-message [error]="error" [error_details]="error_details"></app-error-message>
  </div>

  <div class="alert alert-warning m--10" role="alert" *ngIf="pendingMask?.length > 0 && !history">
    <p>
      <em class="fas fa-exclamation-triangle"></em>
      This field has a pending update from Google which means that any recent updates or changes have not been
      published or approved yet. Once the pending status has been cleared, you will see the most up-to-date version of this data.
      <br>
      <span>{{pendingMask?.join(', ') | titlecase}}</span>
    </p>
  </div>

  <div class="row m--0 dialog--padding border-bottom">
    <div class="col-12 col-md-6 p--tb-20 border-right"><h3 class="fw--medium m--0">Google's Data</h3></div>
    <div class="col-12 col-md-6 p--tb-20"><h3 class="fw--medium m--0">Our Data</h3></div>
  </div>

  <div class="dialog-row dialog--padding">
    <div class="row m--0" *ngFor="let diff of difference">
      <div class="col-12">
        <div class="row border-bottom" *ngIf="diff?.key == 'locationname' || diff?.key == 'title'">
          <div class="col-12 col-md-6 border-right">
            <app-location-name [locationName]="diff?.diffGoogle" [isEdit]="false"></app-location-name>
          </div>
          <div class="col-12 col-md-6">
            <app-location-name [locationName]="diff?.diffLoc" [isEdit]="false"></app-location-name>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='primaryphone'">
          <div class="col-12 col-md-6 border-right">
            <app-location-phone [primaryPhone]="diff?.diffGoogle" [isEdit]="false"></app-location-phone>
          </div>
          <div class="col-12 col-md-6">
            <app-location-phone [primaryPhone]="diff?.diffLoc" [isEdit]="false"></app-location-phone>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='additionalphones'">
          <div class="col-12 col-md-6 border-right">
            <app-location-phone [additionalPhones]="diff?.diffGoogle" [hasPrimaryPhone]="false" [isEdit]="false"></app-location-phone>
          </div>
          <div class="col-12 col-md-6">
            <app-location-phone [additionalPhones]="diff?.diffLoc" [hasPrimaryPhone]="false" [isEdit]="false"></app-location-phone>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='address'">
          <div class="col-12 col-md-6 border-right">
            <app-location-address *ngIf="diff?.googleLocation" [address]="diff?.googleLocation?.diffGoogle" [isEdit]="false"></app-location-address>
          </div>
          <div class="col-12 col-md-6">
            <app-location-address *ngIf="diff?.location" [address]="diff?.location?.diffLoc" [isEdit]="false"></app-location-address>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='servicearea'">
          <div class="col-12 col-md-6 border-right">
            <app-location-service-area *ngIf="diff?.googleLocation" [serviceArea]="diff?.googleLocation" [isEdit]="false"></app-location-service-area>
          </div>
          <div class="col-12 col-md-6">
            <app-location-service-area *ngIf="diff?.location" [serviceArea]="diff?.location" [isEdit]="false"></app-location-service-area>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='locationstate'">
          <div class="col-12 col-md-6 border-right">
            <app-location-state *ngIf="diff?.googleLocation" [state]="diff?.googleLocation" [isEdit]="false"></app-location-state>
          </div>
          <div class="col-12 col-md-6">
            <app-location-state *ngIf="diff?.location" [state]="diff?.location" [isEdit]="false"></app-location-state>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='primarycategory'">
          <div class="col-12 col-md-6 border-right">
            <app-location-primary-categ [placeId]="locationId" [primaryCategory]="{displayName: diff?.diffGoogle}" [isEdit]="false"></app-location-primary-categ>
          </div>
          <div class="col-12 col-md-6">
            <app-location-primary-categ [placeId]="locationId" [primaryCategory]="{displayName: diff?.diffLoc}" [isEdit]="false"></app-location-primary-categ>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='additionalcategories'">
          <div class="col-12 col-md-6 border-right">
            <app-location-primary-categ [placeId]="locationId" [additionalCategories]="diff?.googleLocation" [isEdit]="false"></app-location-primary-categ>
          </div>
          <div class="col-12 col-md-6">
            <app-location-primary-categ [placeId]="locationId" [additionalCategories]="diff?.location" [isEdit]="false"></app-location-primary-categ>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='updatehaspendings'">
          <div class="col-12 p--tb-20 border-right text-center"><h3 class="fw--medium m--0">Locations pushed to GBP with no changes.</h3></div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='regularhours'">
          <div class="col-12 col-md-6 border-right">
            <app-location-regular-hours [regularHours]="diff?.googleLocation" [placeId]="locationId" [isEdit]="false"></app-location-regular-hours>
          </div>
          <div class="col-12 col-md-6">
            <app-location-regular-hours [regularHours]="diff?.location" [placeId]="locationId" [isEdit]="false"></app-location-regular-hours>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='morehours'">
          <div class="col-12 col-md-6 border-right">
            <app-location-more-hours [moreHours]="diff?.googleLocation" [moreHoursLabels]="moreHoursLabels" [location]="location" [placeId]="locationId" [isEdit]="false"></app-location-more-hours>
          </div>
          <div class="col-12 col-md-6">
            <app-location-more-hours [moreHours]="diff?.location" [moreHoursLabels]="moreHoursLabels" [location]="location" [placeId]="locationId" [isEdit]="false"></app-location-more-hours>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='specialhours'">
          <div class="col-12 col-md-6 border-right">
            <app-location-special-hours [specialHours]="diff?.googleLocation" [isEdit]="false"></app-location-special-hours>
          </div>
          <div class="col-12 col-md-6">
            <app-location-special-hours [specialHours]="diff?.location" [isEdit]="false"></app-location-special-hours>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='websiteurl'">
          <div class="col-12 col-md-6 border-right">
            <app-location-url [url]="diff?.diffGoogle" [isEdit]="false"></app-location-url>
          </div>
          <div class="col-12 col-md-6">
            <app-location-url [url]="diff?.diffLoc" [isEdit]="false"></app-location-url>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='products'">
          <div class="col-12 col-md-6 border-right">
            <app-location-products [priceList]="diff?.googleLocation"></app-location-products>
          </div>
          <div class="col-12 col-md-6">
            <app-location-products [priceList]="diff?.location"></app-location-products>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='services'">
          <div class="col-12 col-md-6 border-right">
            <app-location-products [priceList]="diff?.googleLocation"></app-location-products>
          </div>
          <div class="col-12 col-md-6">
            <app-location-products [priceList]="diff?.location"></app-location-products>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='attributes' && hasAttributesUrl && !hasSocialMedia">
          <div class="col-12 col-md-6 border-right">
            <app-location-url-attributes [attributes]="diff?.diffGoogle" [isEdit]="false"></app-location-url-attributes>
          </div>
          <div class="col-12 col-md-6">
            <app-location-url-attributes [attributes]="diff?.diffLoc" [isEdit]="false"></app-location-url-attributes>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='attributes' && (hasAttributes || hasSocialMedia)">
          <div class="col-12 col-md-6 border-right">
            <app-location-attributes [attributes]="diff?.googleLocation" [isEdit]="false"></app-location-attributes>
          </div>
          <div class="col-12 col-md-6">
            <app-location-attributes [attributes]="diff?.location" [isEdit]="false"></app-location-attributes>
          </div>
        </div>
        <div class="row border-bottom" *ngIf="diff?.key=='profile'">
          <div class="col-12 col-md-6 border-right">
            <app-location-description *ngIf="diff?.diffGoogle" [description]="diff?.diffGoogle" [isEdit]="false"></app-location-description>
          </div>
          <div class="col-12 col-md-6">
            <app-location-description *ngIf="diff?.diffLoc" [description]="diff?.diffLoc" [isEdit]="false"></app-location-description>
          </div>
        </div>

        <div class="row border-bottom" *ngIf="diff?.key=='storecode'">
          <div class="col-12 col-md-6 box__item border-right">
            <span [outerHTML]="diff?.diffGoogle"></span>
          </div>
          <div class="col-12 col-md-6 box__item">
            <span [outerHTML]="diff?.diffLoc"></span>
          </div>
        </div>

        <div class="row border-bottom" *ngIf="diff?.key=='labels'">
          <div class="col-12 col-md-6 box__item border-right">
            <p *ngFor="let item of diff?.diffGoogle" class="m--0 txt--lightgray">
              <span [outerHTML]="item"></span>
            </p>
          </div>
          <div class="col-12 col-md-6 box__item">
            <p *ngFor="let item of diff?.diffLoc" class="m--0 txt--lightgray">
              <span [outerHTML]="item"></span>
            </p>
          </div>
        </div>

        <div class="row border-bottom" *ngIf="diff?.key=='adwordslocationextensions'">
          <div class="col-12 col-md-6 box__item border-right">
            <span [outerHTML]="diff?.diffGoogle || '-'"></span>
          </div>
          <div class="col-12 col-md-6 box__item">
            <span [outerHTML]="diff?.diffLoc || '-'"></span>
          </div>
        </div>

        <div class="row border-bottom" *ngIf="diff?.key=='openinfo'">
          <div class="col-12 col-md-6 border-right">
            <div class="box__item box__item--editable" *ngIf="diff?.googleLocation" [class.align-items-start]="diff?.googleLocation?.openingDate">
              <div class="d-flex w--100 align-items-center">
                <div class="icon icon--rounded icon--gray mr--20">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="w--100" *ngIf="diff?.googleLocation?.status">
                  <div class="w--100">
                    <p class="m--0">
                      <strong *ngIf="diff?.googleLocation?.status != 'OPEN'">
                        {{formatStatus(diff?.googleLocation?.status) | titlecase}}
                      </strong>
                      <strong *ngIf="diff?.googleLocation?.status == 'OPEN' && hasOpeningData(diff?.googleLocation)">
                        {{formatStatus(diff?.googleLocation?.status) | titlecase}} 
                        <span *ngIf="diff?.googleLocation?.status == 'OPEN' && diff?.googleLocation?.openingDate?.day">on {{ openInfoDate(diff?.googleLocation?.openingDate) | date : 'MMMM d, yyyy'}}</span>
                        <span *ngIf="diff?.googleLocation?.status == 'OPEN' && !diff?.googleLocation?.openingDate?.day">on {{ openInfoDate(diff?.googleLocation?.openingDate) | date: "MMMM yyyy" }}</span>
                      </strong>
                    </p>
                  </div>
                  <div *ngIf="diff?.googleLocation?.status == 'OPEN' && !hasOpeningData(diff?.googleLocation)">
                    <div class="w--100">
                      <p class="m--0">
                        <strong >No Open Date</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="box__item box__item--editable" *ngIf="diff?.location" [class.align-items-start]="diff?.location?.openingDate">
              <div class="d-flex w--100 align-items-center">
                <div class="icon icon--rounded icon--gray mr--20">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="w--100" *ngIf="diff?.location?.status">
                  <div class="w--100">
                    <p class="m--0">
                      <strong *ngIf="diff?.location?.status != 'OPEN'">
                        {{formatStatus(diff?.location?.status) | titlecase}}
                      </strong>
                      <strong *ngIf="diff?.location?.status == 'OPEN' && hasOpeningData(diff?.location)">
                        {{formatStatus(diff?.location?.status) | titlecase}} 
                        <span *ngIf="diff?.location?.status == 'OPEN' && diff?.location?.openingDate?.day">on {{ openInfoDate(diff?.location?.openingDate) | date : 'MMMM d, yyyy'}}</span>
                        <span *ngIf="diff?.location?.status == 'OPEN' && !diff?.location?.openingDate?.day">on {{ openInfoDate(diff?.location?.openingDate) | date: "MMMM yyyy" }}</span>
                      </strong>
                    </p>
                  </div>
                  <div *ngIf="diff?.location?.status == 'OPEN' && !hasOpeningData(diff?.location)">
                    <div class="w--100">
                      <p class="m--0">
                        <strong>No Open Date</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row border-bottom" *ngIf="diff?.key=='placeactionlinks'">
          <div class="col-12 col-md-6 border-right">
            <app-location-action-links *ngIf="diff?.diffGoogle" [actionLinks]="diff?.diffGoogle" [isEdit]="false"></app-location-action-links>
          </div>
          <div class="col-12 col-md-6">
            <app-location-action-links *ngIf="diff?.diffLoc" [actionLinks]="diff?.diffLoc" [isEdit]="false"></app-location-action-links>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>

<div class="dialog__content" mat-dialog-content *ngIf="difference?.length == 0 && !isDiffMask">
  
  <div *ngIf="!isEmptyObject(error)" class="row m--0 dialog--padding border-bottom mt--10 mb--10">
    <app-error-message [error]="error" [error_details]="error_details"></app-error-message>
  </div>

  <div class="dialog-row">
    <h3 class="txt--center m--0"><strong>There are no differences between Google's Data and Our data.</strong></h3>
  </div>
</div>

<div *ngIf="isDiffMask">
  <p class="txt--center mt--10">You can accept and update Map Labs with Google's data. If you choose "Don't Accept", Map Labs will keep its data and update Google's data to match.</p>
  <div class="row m--0 dialog--padding border-bottom">
    <div class="col-12 col-md-6 p-tb--20"><h3 class="fw--medium m--0">Attributes</h3></div>
    <div class="col-12 col-md-6 p-tb--20"><h3 class="fw--medium m--0">Google's Data</h3></div>
  </div>

  <div class="dialog-row dialog--padding mb--20">
    <div class="row m--0" *ngFor="let diff of diffMask">
      <ng-container *ngIf="isString(diff?.value)">
        <div class="row border-bottom col-12 mt--20">
            <p class="col-6 d--inl-block vertical--align--top p--0">{{diff?.displayName | titlecase}}</p>
            <p class="col-6 d--inl-block">{{diff?.value | titlecase}}</p>
        </div>
      </ng-container>

      <ng-container *ngIf="!isString(diff?.value)">
        <div class="row border-bottom col-12 mt--20">
          <p class="d--block col-12 p--0">{{diff?.displayName | titlecase}}</p>
          <ng-container *ngFor="let value of diff?.value | keyvalue">
            <p class="col-6 d--inl-block vertical--align--top p--0 pl--20 txt--dark--gray">{{value?.key | titlecase}}</p>
            <p class="col-6 d--inl-block">{{getValue(value)}}</p>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div> 
</div>

<div mat-dialog-actions class="dialog__footer" *ngIf='!history'>
  <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
  </span>
  <div *ngIf="difference?.length > 0 && !notifyErrors; else ResolveErrors">
    <button mat-flat-button tabIndex="-1" [disabled]="difference?.length == 0 || isProgress" (click)="apply(0)" color="warn" class="btn btn--action mr--10">Fetch</button>
    <button mat-flat-button tabIndex="-1" [disabled]="difference?.length == 0 || isProgress" (click)="apply(1)" color="accent" class="btn btn--action">Push</button>
  </div>

  <div *ngIf="isDiffMask">
    <span class="alternate-theme">
      <button mat-flat-button tabIndex="-1" [disabled]="isProgress" color="primary" class="btn btn--action mr--10" (click)="apply(1)">Don’t Accept</button>
    </span>
    <button mat-flat-button tabIndex="-1" [disabled]="isProgress" color="accent" class="btn btn--action" (click)="acceptChangesFromGoogle()">Accept And Update</button>
  </div>

  <ng-template #ResolveErrors>
    <div>
      <button mat-flat-button tabIndex="-1" *ngIf="notifyErrors" [disabled]="difference?.length == 0" (click)="apply(1)" color="accent" class="btn btn--action mr--10">Solve Errors</button>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions class="dialog__footer" *ngIf='history'>
  <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Close</button>
  </span>
</div>
