// dep
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';

const FlexSearch = require('flexsearch');

// app
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import SavedAccount from '../../constants/firestore/saved-account';
import { UserService } from '../../services/user.service';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import User from '../../constants/firestore/user';
import { DeleteUserDialogComponent } from './delete-user-dialog/delete-user-dialog.component';
import {UserSettingDialogComponent} from './user-setting-dialog/user-setting-dialog.component';
import { Pageable } from '../../constants/pageable';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls:  ['./users-management.component.scss']
})
export class UsersManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  accounts; 
  displayedColumns = ['name', 'username', 'date', 'role', 'actions'] as const
  dataSource = new MatTableDataSource<SavedAccount[]>(this.accounts);
  manualPage: number;
  // errorMessage: boolean; // TODO: Unused
  timeout: any = null;
  flexSearch = new FlexSearch({
    encode: "advanced",
    tokenize: "reverse",
    suggest: true,
    cache: true,
    doc: {
      id: 'id',
      field: [
        'displayName',
        'email',
      ]
    }
  })

  paginate: Pageable = { page: 1, size: 10 };
  previousPageable: { size: any; page: any };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatSort, {static: true}) sort: MatSort;

  session$ = this._sessionS.session$;

  constructor(
    public accountService: AccountService,
    public usersService: UserService,
    private _sessionS : SessionService,
    private cdr: ChangeDetectorRef,
    private snack: SnackbarService,
    private _modalS : ModalService
  ) {
    super();
  }

  ngOnInit() : void {
    this.cdr.detectChanges();

    this._setData();
    this._loadUsers();
  }

  ngOnDestroy() : void {
    this.usersService.setUsers([]);
    super.ngOnDestroy();
  }

  private _setData() {
    this.accounts = this.usersService.users
      .pipe(
        map(account => account.filter(user => !user.masterGid))
      )
      .pipe(map(res => res.sort((a, b) => {
        const aRole = a.role;
        const bRole = b.role;
        const aLow = a.displayName;
        const bLow = b.displayName;
        if (aRole === bRole) {
          return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
        } else {
          return (aRole < bRole) ? -1 : 1;
        }
      })));
    this.paginator.pageSize = 100;
    this.usersService.paginator = this.paginator;
    this.manualPage = 1;
    // this.errorMessage = false; 
  }

  // apply filter from search
  applyFilter($event: string, key: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (!$event || $event === '') {
        this.initPaginator();
        this._setData();
      } else {
        const searchValue = $event.toLowerCase();
        this.accounts = this.usersService.users.pipe(
          map(r => r.filter(user => user.displayName.toLowerCase().indexOf(searchValue) > -1 ||
            user.email.toLowerCase().indexOf(searchValue) > -1))
        );
      }

    }, 350);
  }

  initPaginator(): void {
    this.paginate = { page: 1, size: 10 };
    this.previousPageable = null;
  }


  onAddNewUser(): void {
    this._displayModalUser(null);
  }

  onEditUser(user: User | null): void {
    this._displayModalUser(user);
  }

  onEditSetting(user: User | null): void {
    this._displayModalSettingUser(user);
  }

  public async onDeleteUser(user: User): Promise<void> {
    if(await this._modalS.openModal(DeleteUserDialogComponent, { user })) {
      this.snack.openSuccess('The user was successfully deleted.');
      this._loadUsers();
    }
  }

  private async _displayModalSettingUser(user: User | null): Promise<void> {
    if(await this._modalS.openModal(UserSettingDialogComponent, { user })) {
      this.snack.openSuccess('The user was updated successfully.');
      this._loadUsers();
    }
  }

  private async _displayModalUser(user: User | null): Promise<void> {
    const response = await this._modalS.openModal(UserDialogComponent, {user});
    if(!response) {
      return;
    }

    const {gid} = this._sessionS.getSession();

    if (user?.uid) {
      user = { ...user, ...response };
      try {
        await this.usersService.updateUser(gid, user.uid, user);
        this.snack.openSuccess('The user was updated successfully.');
      } catch(error) {
        this.snack.openError('An error occurred trying updating the user.');
      }
      this._loadUsers();
      return;
    }

    const domain = this._sessionS.getDomain();

    const userData = {
      email: response.email,
      displayName: response.displayName,
      timezoneOffsetMinutes: new Date().getTimezoneOffset(),
      registrationDomain: domain.domainName,
      role: response.role,
      isActive: response.isActive
    }

    const verificationEmailData = {
      domain:      domain.domainNameWithPort, // TODO: Bug? must be domainName?
      companyName: domain.branding.company_name,
      mainWebsite: domain.branding.main_website
    }

    try {
      await this.usersService.addUserToGroup(gid, {userData, verificationEmailData}).toPromise();
      this.snack.openSuccess('The user was created successfully.');
    } catch(error) {
      this.snack.openError(error.error?.message ? `Error: ${error.error.message}` : 
                                                  'Unexpected Error: Please contact support');
    }   
  }


  updateManualPage($event): void { }

  getPages(): number[] {
    return [1];
  }

  lastPage(): boolean {
    return this.paginator.pageSize * (this.paginator.pageIndex + 1) >= this.paginator.length;
  }

  onPage(index: number): void {
    this.updateManualPage(this.paginator.pageIndex);
  }

  onPrev(): void {
    this.updateManualPage(this.paginator.pageIndex);
  }

  onNext(): void {
    this.paginator.pageIndex += 2;
    this.updateManualPage(this.paginator.pageIndex);
  }

  private _loadUsers(): void {
    this.usersService.setUsers([]);
    this.usersService.loadAll(this._sessionS.getSession().gid);
  }
}