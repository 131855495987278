
	  <div class="dialog__header txt--center dialog__header--info">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title }}</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>
		  <div class="dialog-row">
			  <div class="txt--lg txt--center m--0" [innerHTML]="data.content"></div>
		  </div>
	  </div>

	  <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
   
      </span>
	  </div>
  