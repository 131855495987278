// dep
import {Injectable} from '@angular/core'
import * as _ from 'lodash'
import { Observable, timer} from 'rxjs';
import {debounce, map } from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';

// app
import { HEADERS_NO_AUTH } from '../constants/auth'
import GoogleAccountResponse from '../constants/firestore/google-account-response';
import GoogleLocationResponse from '../constants/firestore/google-location-response';
import {ApiResponse} from '../constants/api-response';
import {GoogleAttributes, GroupAttributes} from '../constants/google/attributes';
import {Pageable} from '../constants/pageable';
import {environment as ENV} from '@environment';
import {DataPicker} from '../constants/data-picker';
import GoogleUserProfile from '../constants/firestore/google-user-profile';
import { ServiceList } from '../constants/google/service-list';
import { GoogleAuth } from '../constants/firestore/user';
import AccountObject from '../constants/firestore/account-object';
import GoogleLocation from '../constants/firestore/google-location';

const DYNAMIC_URL = 'https://search.google.com/local/writereview?placeid=';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  constructor(private _http: HttpClient) {
  }


  /**
   * This will continue an OAuth flow, at the end the resultant token will be stored
   * on Firestore under GROUP[gid].USER[uid].googleAuth and in 
   * GROUP[gid].EXTERNAL_GRADES(accountId).googleAuth
   * TODO: Note that externalId here is passed as 'accountId', but in the backend is used as 
   * 'externalGradeId', accountId is a misleading query param. 
   * TODO: Also some callers are passing a real accountId, we are polluting the EXTERNAL
   * grade collections with documents keyed by accountId not later used?
   * @returns an URL
   */
  async authenticate(gid : string, uid : string, accountId : string | null = null) : Promise<string> {
    const apiUrl = ENV.apiUrl
    const domain = apiUrl.endsWith('/api') ? apiUrl.substring(0, apiUrl.length - 4) : apiUrl;
    // FIXME: This endpoint should be a POST, not a GET?
    return await this._http.get<string>(`${apiUrl}/google/auth_url?uid=${uid}&gid=${gid}&accountId=${accountId}&domain=${domain}`, 
                                        HEADERS_NO_AUTH
                                      ).toPromise();
  }


  //--------------------------------------------------------------------------
  // Requests to Google
  //--------------------------------------------------------------------------

// TODO: Unused, remove. 
//   async updateProductList(accountId, locationId, priceList) : Promise<void> {
//     this.auth.getGmbToken().subscribe(token => {
//       this._http.patch(`${this.googleApiUrl}/accounts/${accountId}/locations/${locationId}?updateMask=priceLists`, 
//                       {"priceLists": priceList}, this.headersGoogle(token)).toPromise();
//     });
// 
//   }

  getUserProfile(googleAuth : GoogleAuth): Promise<GoogleUserProfile> {
    return this._http.get<GoogleUserProfile>(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${googleAuth.access_token}`,
                                             HEADERS_NO_AUTH).toPromise()
  }

  private _headersGoogle(googleAuth : GoogleAuth) {
    return {headers: HEADERS_NO_AUTH.headers.set('Authorization', `Bearer ${googleAuth?.access_token || ''}`)}
  }

  private async _fetchAllPages<P extends {nextPageToken? : string}>(url : string, 
                                                                    urlParams : HttpParams | null, 
                                                                    googleAuth : GoogleAuth) : Promise<P[]> {
    const headers = this._headersGoogle(googleAuth)

    let pageToken = '';
    const r : P[] = []
    do {
      let params = (urlParams || new HttpParams());
      if(pageToken) 
        params = params.set('pageToken', pageToken);
      const page = await this._http.get<P>(url, {params, ... headers }).toPromise();
      r.push(page);
      pageToken = page.nextPageToken
    } while(pageToken)

    return r
  }

  async getAccounts(googleAuth : GoogleAuth) : Promise<AccountObject[]> {
    const pages = await this._fetchAllPages<GoogleAccountResponse>(ENV.googleAccountManagementApi + '/accounts', null, googleAuth);
    const accounts : AccountObject[] = []
    for(const p of pages) 
      accounts.push(... p.accounts)
    return accounts
  }

  // TODO: Type return value only including readMask values
  async getLocations(googleAuth : GoogleAuth, accountId : string) : Promise<GoogleLocation[]> {

    const readMask = ("storeCode,regularHours,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri," +
                      "regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,"+
                      "profile,relationshipData,moreHours,metadata");

    const url = `${ENV.googleApiLocations}/${accountId}/locations`;

    const params = new HttpParams().set('readMask',   readMask)
                                   .set('pageSize',   '100');

    const pages = await this._fetchAllPages<GoogleLocationResponse>(url, params, googleAuth);
    const locations : GoogleLocation[] = []
    for(const p of pages)
      for(const l of (p.locations || [])) {
        l.locationName = l.title;
        l.name         = accountId + '/' + l.name;
        l.address      = l.storefrontAddress;
        locations.push(l)
    }
    return locations
  }

  //--------------------------------------------------------------------------

  async updateMenuList(accountId : string, locationId: string, priceList): Promise<{success: boolean, message: string}> {
    return await this._http.post<any>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/menu_list`, { priceList })
    .pipe(
      map( value => value ? value.data : {})
    ).toPromise();
  }

  saveMedia(accountId: string, locationId: string, data)  {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media`;
    return this._http.post(url, data);
  }

  saveBulkMedia(accountId: string, locationId: string, data) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/bulk_media`;
    return this._http.post(url, data);
  }

  deleteMedia(accountId: string, locationId: string, name : string) {
    const params = new HttpParams().set('name', name);
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media`;
    return this._http.delete<ApiResponse>(url, {params}).pipe(map(value => value.data || []));
  }

  getMedia(accountId : string, locationId : string) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media`;
    return this._http.get<ApiResponse>(url).pipe(map(value => value.data || []));
  }


  getProfilePhoto(accountId: string, locationId : string) {
    const url = `${ENV.apiUrl}/v2/google/${accountId}/${locationId}/media/profile`;
    return this._http.get<ApiResponse>(url).pipe(map(value => value.data || []));
  }

  getCategories(displayName, usedCategories, region_code) {
    const params = new HttpParams().set('q', displayName).set('region_code', region_code);
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/categories/search`, {
      params
    }).pipe(
      map(value => {
        if (value?.data) {
          const data = [...value.data];
          usedCategories.forEach(cat => {
            const categoryId = cat?.categoryId?.includes('categories') ? cat?.categoryId?.split('/')[1] : cat?.categoryId;
            const index = data.findIndex(d => d.categoryId == categoryId);
            if (index > -1) {
              data.splice(index, 1);
            }
          })
          return data;
        }
        return null
      }),
      debounce(() => timer(1000))
    );
  }

  // ATTRIBUTES SERVICES, FOOD MENU, PRODUCTS
  saveMenu(accountId: string, locationId : string) {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/menu`
    ).pipe(map(value => value.data || []));
  }


  saveServices(accountId: string, locationId : string) {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list` 
    ).pipe(map(value => value.data || []));
  }

  updateServices(accountId: string, locationId, serviceList: ServiceList[]) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list`, { serviceList }
    ).pipe(map(value => value.data || []));
  }

  updateBulkServices(accountId: string, locationId: string, data: any) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list`, data
    ).pipe(map(value => value.data || []));
  }

  updateFoodMenu(accountId: string, locationId: string,  data: any) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/menu_list`, data
    ).pipe(map(value => value.data || []));
  }

  getAttributes(gids, accountIds, locationIds) {
    // const params = new HttpParams().set('categoryId', categoryId);
    const data = {
      gids: gids,
      accountIds: accountIds,
      locationIds: locationIds
    }  

    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/attributes`, data).pipe(map(value => value.data ? value?.data[0]?.attributes : []));
  }

  // TODO: Unused, remove
  //
  // getGoogleData(accountId, locationId) {
  //   return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/getGoogleData`)
  //     .pipe(map(value => value.data || null));
  // }

  fetchDifference(accountId : string, locationId : string) {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/fetchDifferences`)
      .pipe(map(value => value.data || null));
  }

  diffMaskPush(gid : string, accountId : string, locationId : string, differences = null):Observable <any> {
    const data = {
      gid: gid,
      differences: differences
    }
    return this._http.post(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/accept_google_updates`, data);
  }

  fetch(select : string, accountId : string, locationId : string) {
    const params = new HttpParams().set('select', select);

    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/fetch`, {
      params,
    }).pipe(map(value => value || null)).toPromise();
  }

  listLockHistory(accountId: string, placeId: string, pageable: Pageable) {
    const params = new HttpParams()
                        .set('size', pageable.size.toString())
                        .set('accountId', accountId)
                        .set('page', pageable.page.toString());
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${placeId}/lockHistory`, {
      params,
    }).pipe(map(value => value ? value.data : null));
  }


  saveLockHistory(accountId : string, locationId : string, action: string, status: string) {
    return this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/lockHistory`, {
      status,
      action
    }).pipe(map(value => value ? value.data : null));
  }

  async push(accountId : string, locationId : string) : Promise<ApiResponse> {
    return await this._http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/push`, {})
      .pipe(map(value => value || null)).toPromise()
  }


  async metrics(accountId : string, locationId : string, dataPicker?: DataPicker) : Promise<any[]> {
    const loc = dataPicker?.locations[0]
    if (loc && loc.accountId && loc.locationId){
        accountId  = loc.accountId;
        locationId = loc.locationId;
    }

    const r = await this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/${accountId}/${locationId}/grade`).toPromise();
    return r ? r.data : []
  }

  /**
   * Given a locationId, returns a short url to it by generating a Firebase Dynamic Link
   * 
   * See https://firebase.google.com/docs/reference/dynamic-links/link-shortener
   */
  dynamicUrl(locationId: string): Observable<{shortLink : string, previewLink : string}> {
    const url = DYNAMIC_URL + locationId;
    return this._http.post<any>(ENV.fbDynamicLinkApi, {
      longDynamicLink: `${ENV.fbDynamicLinkDomain}/?link=${url}`,
      suffix: {
        option: 'SHORT'
      }
    }, HEADERS_NO_AUTH);
  }

  attributesToGMB(groups: GroupAttributes[]) {
    let itemsActive: GoogleAttributes[] = [];
    groups.forEach(group => {
      const active = group.items.filter(item => {
        return item.active === true || item.active === false || item.attributeId.startsWith('url');
      }).filter((item) => {
        return !(item.valueType === 'URL' && item.urlValues === undefined) 
      });
      itemsActive = itemsActive.concat(active);
    });

    const attributes = itemsActive.map(item => this._toLocationAttributes(item))

    return attributes;
  }


  private _toLocationAttributes(item: GoogleAttributes) {
    switch (item.valueType) {
      case 'BOOL':
        return { valueType: item.valueType, attributeId: item.attributeId, values: [item.active] };

      case 'ENUM':
        return { valueType: item.valueType, attributeId: item.attributeId, values: [item.valueMetadata] };

      case 'URL':
        return { valueType: item.valueType, attributeId: item.attributeId, urlValues: item.urlValues };

      case 'REPEATED_ENUM': {
        const valuesSet   = _.reject(item.valueMetadata.map(v => v.active && v.value), (o) =>  !o);
        const valuesUnSet = _.reject(item.valueMetadata.map(v => v.active === false && v.value), (o) =>  !o);
        const enumValues  = {};

        if(!_.isEmpty(valuesSet)) 
          enumValues['setValues'] = valuesSet;

        if(!_.isEmpty(valuesUnSet))
          enumValues['unsetValues'] = valuesUnSet;

        return { valueType: item.valueType, attributeId: item.attributeId, repeatedEnumValue: enumValues };
      }
      default:
        return {}
    }
  }


  /**
   * organize the items in google attributes object
   * @return  this.groups organized
   */
  private _toAttributeGroups(googleAttributes: GoogleAttributes[]): GroupAttributes[] {
    const attributes = _.cloneDeep(googleAttributes);
    const groups: GroupAttributes[] = [];

    if (!googleAttributes) {
      return [];
    }

    attributes.forEach(value => {

      const groupName = value.groupDisplayName;
      let exist = false;
      let index = null;
      groups.forEach((group, i) => {
        if (groupName === group.groupName) {
          exist = true;
          index = i;
        }
      });
      value.active = null;

      if (exist) {
        groups[index].items.push(value);
      } else {
        groups.push({
          groupName,
          items: [value],
        });
      }
    });

    groups.forEach((group) => {
      group.items = _.orderBy(group.items, ['valueType'], ['asc']);
    });

    return groups;
  }


  /*
    FIXME: smell bad
    attribute onlyActive never used
  */
  private _activeSavedAttributes(groups: GroupAttributes[], locationAttributes, onlyActive = false) {

    if (!locationAttributes) {
      return;
    }

    if (locationAttributes.length === 0) {
      return groups;
    }

    groups.forEach(group => {
      group.items.forEach(item => {
        if (item.valueType === 'URL') group.active = true;
        locationAttributes.forEach(locAttribute => {
          if (item.attributeId === locAttribute.attributeId) {
            if (item.valueType === 'URL') {
              item.urlValues = locAttribute.urlValues;
              group.active = true;
            }
            if (item.valueType === 'BOOL') {
              item.active = locAttribute.values[0];
              group.active = true;
            }
            if (item.valueType === 'REPEATED_ENUM' || item.valueType === 'ENUM') {
              item?.valueMetadata?.map(metadata => {
                metadata.active = null;
                if (locAttribute?.repeatedEnumValue?.setValues?.findIndex(value => value === metadata.value) > -1) {
                  group.active = true;
                  item.active = true;
                  metadata.active = true;
                } else if (locAttribute?.repeatedEnumValue?.unsetValues?.findIndex(value => value === metadata.value) > -1) {
                  group.active = true;
                  item.active = true;
                  metadata.active = false;
                }
              });
            }

          }
        });
      });
    });
    return groups;
  }

  private _getExcludeAtributes(googleAttributes: GoogleAttributes[], locationAttributes: GoogleAttributes[] = []) {
    const result = [];
    locationAttributes.forEach(locAttribute => {
      const findAttribute = _.find(googleAttributes, { 'attributeId': locAttribute.attributeId });
      if (!findAttribute || _.isEqual(locAttribute.valueType, 'URL')) {
        result.push(locAttribute);
      }
    });

    return result;
  }

  async groupsWithAttributeSaved(gid, accountId, locationId, primaryCategory, locationAttributes, googleAttributes: GoogleAttributes[] = []) {
    if (googleAttributes?.length === 0) {
      googleAttributes = await this.getAttributes([gid], [accountId], [locationId]).toPromise();
    }
    const excludedAtributes = this._getExcludeAtributes(googleAttributes, locationAttributes);
    const groups = this._toAttributeGroups(googleAttributes);
    this._activeSavedAttributes(groups, locationAttributes);
    return {groups, googleAttributes, excludedAtributes};
  }

  async groupsWithAttribute(accountId, gid, locationIds) {

    const googleAttributes = await this.getAttributes(gid, accountId, locationIds).toPromise();
    const excludedAtributes = [];
    const groups = this._toAttributeGroups(googleAttributes);
    return {groups, googleAttributes, excludedAtributes};
  }


}
