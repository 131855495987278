import { __awaiter, __generator } from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { HttpClient } from '@angular/common/http';
// app
import { FirestoreService } from './firestore.service';
import { GROUPS, NOTIFICATIONS } from '../constants/firestore/collections';
import { TYPE_LOG_USER, TYPE_LOG_LOCATION, TYPE_LOG_GRADE, TYPE_LOG_SUBSCRIPTION } from "../constants/notifications";
import { environment } from '@environment';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./firestore.service";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/common/http";
import * as i4 from "./session.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(_fs, _afs, _http, _sessionS) {
        this._fs = _fs;
        this._afs = _afs;
        this._http = _http;
        this._sessionS = _sessionS;
    }
    NotificationService.prototype._col = function () {
        var gid = this._sessionS.getSession().gid;
        return this._afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS);
    };
    NotificationService.prototype.saveNotification = function (message, type, typeLog, meta) {
        return __awaiter(this, void 0, void 0, function () {
            var gid, domainName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        domainName = this._sessionS.getDomain().domainName;
                        return [4 /*yield*/, this._col().add({ gid: gid, domainName: domainName, message: message, type: type, meta: meta, createAt: firestore.Timestamp.now(), status: 'unviewed', typeLog: typeLog })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationService.prototype.getNotifications = function () {
        var gid = this._sessionS.getSession().gid;
        return this._fs.colWithIds$(GROUPS + "/" + gid + "/" + NOTIFICATIONS, function (ref) { return ref
            .where('status', '==', 'unviewed').orderBy('createAt', 'desc').limit(100); });
    };
    NotificationService.prototype.paginateNotifications = function (pageable, next, prev, where) {
        var gid = this._sessionS.getSession().gid;
        return this._fs.paginate(GROUPS + "/" + gid + "/" + NOTIFICATIONS, 'createAt', null, {
            size: pageable.size,
            page: pageable.page
        }, false, next, prev, null, null, 'desc');
    };
    NotificationService.prototype.deleteAll = function () {
        var gid = this._sessionS.getSession().gid;
        return this._http.get(environment.apiUrl + "/v2/groups/gid/" + gid + "/delete/notification").toPromise();
    };
    NotificationService.prototype.deleteNotification = function (docId) {
        this._col().doc(docId).delete();
    };
    // TODO: Unused, remove
    // countNotifications(gid) {
    //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS,
    //     ref => ref.where('status', '==', 'unviewed')).snapshotChanges().pipe(map(documents => documents.length));
    // }
    // 
    // merge(gid, docId, data) {
    //   return this.afs.collection(GROUPS).doc(gid).collection(NOTIFICATIONS).doc(docId).set(data, {merge: true});
    // }
    NotificationService.prototype.getMetaTypeLog = function (typeLog, data) {
        switch (typeLog) {
            case TYPE_LOG_USER:
                return { email: data.email, displayName: data.displayName, role: data.role, uid: data.uid };
            case TYPE_LOG_LOCATION:
                return { accountId: data.accountId, locationName: data.locationName, locationId: data.locationId, address: data.address };
            case TYPE_LOG_GRADE:
                return { accountId: data.accountId, email: data.email, displayName: data.displayName, role: data.role, uid: data.uid };
            case TYPE_LOG_SUBSCRIPTION:
                return { accountId: data.accountId, locationId: data.locationId, locationName: data.locationName,
                    subscriptionType: data.subscriptionType, planBefore: data.planBefore, planAfter: data.planAfter };
            default:
                break;
        }
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.FirestoreService), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.SessionService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
