
<mat-drawer-container [class.z-index--auto]="isPostSliderOpened" [hasBackdrop]="true">
    <mat-drawer-content [class.z-index--999]="isPostSliderOpened">
        <div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isSpinner">
        <mat-spinner></mat-spinner>
        </div>
        <div class="content__header d-flex justify-content-between align-items-center">
            <h1>
                <strong><span *ngIf="isBulkPost">Bulk </span>Post Management</strong>
                <app-info-tooltip [tooltip]="tooltip"></app-info-tooltip>
            </h1>

            <mat-drawer #drawer mode="'over'" position="end"
                [ngClass]="{'top-space': (session$ | async).isImpersonating || (!(session$ | async).isMember && (session$ | async).isTrial) }"
                (keydown.escape)="closeSlider()">
                <app-post-manegement-slider 
                    [drawer]="drawer"
                    [isBulk]="isBulkPost"
                    [postToEdit]="postToEdit"
                    [location]="location"
                    [isSliderOpened]="isPostSliderOpened"
                    (closeSlider)="closeSlider($event)"
                ></app-post-manegement-slider>
            </mat-drawer>

            <div class="row pr--15">
                <button mat-flat-button color="primary" class="btn btn--icon btn--md txt--18 mr--20" 
                 *ngIf="dashboardType === 'LOCAL_POST'" 
                 matTooltip="This will fetch updates from Google for any new and revised posts. This process may take a few minutes."
                 matTooltipPosition="above"
                 (click)="postFetch()"
                >
                    Fetch
                </button>

                <button mat-flat-button color="accent" class="btn btn--icon btn--md txt--18" (click)="openSlider(drawer)">
                    <em class="far fa-plus txt--14 mr--10"></em> Create Post 
                </button>
            </div>
        </div>

        <div class="content__body">
            <div class="table--shadow mb--30">
                <div class="mat-row p--tb-14 mat-row--border d-flex justify-content-between align-items-center bg--white">
                    <mat-tab-group #tabGroup [selectedIndex]="selected.value" (selectedIndexChange)="indexChange($event)" (selectedTabChange)="tabChange($event)" class="w--100">
                        <mat-tab [label]="'Posts'">
                            <app-post-management-list #tabPosted 
                                post_type="Posted"
                                [postDrawer]="drawer"
                                [dashboardType]="dashboardType"
                                [accountId]="accountId"
                                [locationId]="locationId"
                                [updateList]="updateList"
                                [isImpersonating]="(session$ | async).isImpersonating"
                                [isMember]="(session$ | async).isMember"
                                (editElement)="editElement($event, drawer)"
                            ></app-post-management-list>
                        </mat-tab>

                        <mat-tab [label]="'Archive'">
                            <app-post-management-list #tabArchive 
                                post_type="Archive"
                                [postDrawer]="null"
                                [dashboardType]="dashboardType"
                                [accountId]="accountId"
                                [locationId]="locationId"
                                [updateList]="updateList"
                                [isImpersonating]="(session$ | async).isImpersonating"
                                [isMember]="(session$ | async).isMember"
                            ></app-post-management-list>
                        </mat-tab>
                    </mat-tab-group>
                </div>

            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>