import { __awaiter, __generator } from "tslib";
// app
import { LocationService } from './location.service';
import { ModalService } from './modal.service';
import { SubscriptionService } from './subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { FeaturedComponent } from '../components/featured.component';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "./location.service";
import * as i3 from "./modal.service";
import * as i4 from "./subscription.service";
var LocationFreeGuardService = /** @class */ (function () {
    function LocationFreeGuardService(_sessionS, locationService, modalService, subscriptionService) {
        this._sessionS = _sessionS;
        this.locationService = locationService;
        this.modalService = modalService;
        this.subscriptionService = subscriptionService;
    }
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    LocationFreeGuardService.prototype.canActivate = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gid, subscription, isTrial, _b, locationId, accountId, subscriptionType;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        _a = _c.sent(), gid = _a.gid, subscription = _a.subscription, isTrial = _a.isTrial;
                        _b = route.parent.params, locationId = _b.locationId, accountId = _b.accountId;
                        return [4 /*yield*/, this.locationService.fetchMultipleLocations(gid, [{ locationId: locationId, accountId: accountId }], ['subscriptionType'])];
                    case 2:
                        subscriptionType = (_c.sent())[0].subscriptionType;
                        if (subscription.pricingVersion < 3 && !isTrial &&
                            (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
                            this.modalService.openModal(FeaturedComponent, null, { onClose: function (res) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!res) {
                                                    return [2 /*return*/];
                                                }
                                                console.log('LocationFreeGuardService upgrade suscription');
                                                return [4 /*yield*/, this.subscriptionService.flowChangeLocationsPlan([{ locationId: locationId, accountId: accountId }])];
                                            case 1:
                                                if (_a.sent())
                                                    console.log("upgrade successful - location-guard");
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } });
                            // TODO: returns false without awaiting the previous code, evaluate if that asynchronity is really needed
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    LocationFreeGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationFreeGuardService_Factory() { return new LocationFreeGuardService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.LocationService), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.SubscriptionService)); }, token: LocationFreeGuardService, providedIn: "root" });
    return LocationFreeGuardService;
}());
export { LocationFreeGuardService };
