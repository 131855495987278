import { __awaiter, __generator } from "tslib";
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from "@angular/forms";
import * as uuid from 'uuid';
// app
import { LocationEditService } from "../../services/location-edit.service";
import { SectionType } from "../../constants/google/price-list-section";
import { LocationService } from "../../services/location.service";
var ModalServiceSectionComponent = /** @class */ (function () {
    function ModalServiceSectionComponent(dialogRef, data, locationEditS, locationS, fb) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.locationEditS = locationEditS;
        this.locationS = locationS;
        this.fb = fb;
        this.isEdit = this.data.edit;
        this.type = this.data.type;
        this.priceIndex = this.data.priceIndex;
        this.initForm();
        if (this.data.section) {
            this.isEdit = true;
            this.sectionIndex = this.data.sectionIndex;
            this.name.setValue(this.data.section.labels[0].displayName);
        }
    }
    ModalServiceSectionComponent.prototype.apply = function () {
        if (this.form.invalid) {
            return;
        }
        this.savePriceList();
        this._saveInFirestore();
    };
    ModalServiceSectionComponent.prototype.savePriceList = function () {
        if (this.isEdit) {
            this.editSection(this.priceIndex, this.sectionIndex, this.name.value);
        }
        else if (this.priceIndex === undefined) {
            this.setPriceListItem();
        }
        else {
            this.setSection(this.priceIndex, this.name.value);
        }
    };
    ModalServiceSectionComponent.prototype.setPriceListItem = function () {
        var priceItem = this.createPriceList(this.type);
        if (this.locationEditS.locationEdit.priceLists && this.locationEditS.locationEdit.priceLists.length == 0) {
            this.locationEditS.locationEdit.priceLists = [priceItem];
            this.setSection(0, this.name.value);
        }
        else {
            this.locationEditS.locationEdit.priceLists.push(priceItem);
            var index = this.locationEditS.locationEdit.priceLists.length - 1;
            this.setSection(index, this.name.value);
        }
    };
    ModalServiceSectionComponent.prototype.setSection = function (index, name) {
        var section = this.createSection(name);
        this.locationEditS.locationEdit.priceLists[index].sections.push(section);
    };
    ModalServiceSectionComponent.prototype.editSection = function (index, sectionIndex, name) {
        var section = this.createSection(name);
        this.locationEditS.locationEdit.priceLists[index].sections[this.sectionIndex] = section;
    };
    ModalServiceSectionComponent.prototype._saveInFirestore = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.locationEditS.setAndUpdate().toPromise()];
                    case 1:
                        _a.sent();
                        this.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalServiceSectionComponent.prototype.createPriceList = function (displayName) {
        displayName = this.locationS.convertPriceListType(displayName);
        var priceItem = {
            priceListId: uuid.v4(),
            sections: [],
            labels: [{ displayName: displayName }]
        };
        return priceItem;
    };
    ModalServiceSectionComponent.prototype.createSection = function (displayName) {
        var secType;
        if (this.type == 'menu') {
            secType = SectionType.FOOD;
        }
        else if (this.type == 'services' || this.type == 'jobs') {
            secType = SectionType.SERVICES;
        }
        var section = {
            sectionId: uuid.v4(),
            labels: [{ displayName: displayName }],
            sectionType: secType,
            items: []
        };
        return section;
    };
    ModalServiceSectionComponent.prototype.remove = function () {
        this.locationEditS.locationEdit.priceLists[this.priceIndex].sections.splice(this.sectionIndex);
        this._saveInFirestore();
    };
    // form init and fields
    ModalServiceSectionComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            name: ['', Validators.required],
        });
    };
    Object.defineProperty(ModalServiceSectionComponent.prototype, "name", {
        get: function () {
            return this.form.get('name');
        },
        enumerable: true,
        configurable: true
    });
    return ModalServiceSectionComponent;
}());
export { ModalServiceSectionComponent };
