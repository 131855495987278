import { __awaiter, __extends, __generator, __values } from "tslib";
// dep
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
// app
import { AccountService } from '../../services/account.service';
import { GoogleService } from '../../services/google.service';
import { GoogleAccountService } from '../../services/google-account.service';
// import { GoogleLocationService } from '../../services/google-location.service';
import { LocationService } from '../../services/location.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ObservationService } from '../../services/observation.service';
import { SpinnerService } from '../../services/spinner.service';
import { ModalService } from '../../services/modal.service';
import { DialogLocationsComponent } from './dialog-locations.component';
import { AlertType } from '../../components/alert.component';
import { GroupService } from '../../services/group.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var AccountsComponent = /** @class */ (function (_super) {
    __extends(AccountsComponent, _super);
    function AccountsComponent(_router, accountService, _sessionS, _dialog, _googleAccountS, _locationService, _groupService, _google, _cdr, _snack, _obsService, _modalService) {
        var _this = _super.call(this) || this;
        _this._router = _router;
        _this.accountService = accountService;
        _this._sessionS = _sessionS;
        _this._dialog = _dialog;
        _this._googleAccountS = _googleAccountS;
        _this._locationService = _locationService;
        _this._groupService = _groupService;
        _this._google = _google;
        _this._cdr = _cdr;
        _this._snack = _snack;
        _this._obsService = _obsService;
        _this._modalService = _modalService;
        _this.accounts = null;
        _this.displayedColumns = ['name', 'locations', 'actions'];
        _this.loading_refresh_account$ = new BehaviorSubject(false);
        _this.size = 25;
        _this.page = 1;
        _this.pagination = {
            items: [],
            per_page: _this.size,
            page: _this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        // public accountVerified = false;
        _this._paginate = { size: _this.size, page: _this.page };
        _this.session$ = _this._sessionS.session$;
        _this.dataSource = new MatTableDataSource(null);
        // this.subscription$.subscribe(subscription => {
        //   this.subscription = subscription;
        //   if (this.subscription?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL && !this.accountVerified) {
        //     this.showAccountsMessage();
        //   }
        // });
        if (!_this._sessionS.getSession().isAdmin) {
            _this.displayedColumns = ['name'];
        }
        _this._subscribeSafe(_this._obsService.getAddAccount(), function () { return _this.onAddNewAccount(); });
        return _this;
    }
    AccountsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._locationService.setPaginate({ size: 10, page: 1 });
        this._cdr.detectChanges();
        this.accounts = this.accountService.accounts;
        this.accountService.pagination = this.pagination;
        this.accountService.previousPageable = this._previousPageable;
        this.dataSource.sort = this.sort;
        this._subscribeSafe(this.accounts, function () {
            _this.pagination = _this.accountService.pagination;
            _this._previousPageable = _this.accountService.previousPageable;
        });
        this.dataSource.filterPredicate = function (data, filter) {
            var accumulator = function (currentTerm, key) {
                return _this._nestedFilterCheck(currentTerm, data, key);
            };
            var dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            var transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };
        // this.manualPage = 1;
        // this.errorMessage = false;
        this._getData(this._paginate);
    };
    // TODO: Unused, remove?
    // showAccountsMessage() {
    //   // const paginate: Pageable = { size: 5, page: 1 };
    //   this.accountVerified = true;
    //   this.accounts?.subscribe(acc => {
    //     // check if no accounts message should be seen
    //     this._cdr.detectChanges();
    //   })
    // }
    AccountsComponent.prototype.enableNotifications = function (accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openConfirmModal('Enable Notifications', 'Are you sure want you enable notifications?')];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 5];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.accountService.enableNotifications(accountId)];
                    case 3:
                        _a.sent();
                        this._snack.openSuccess('The notifications were successfully enabled.');
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this._snack.openError(err_1.message);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype.openReauth = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!account.googleAuthEmailAddress) return [3 /*break*/, 1];
                        dialogRef = this._dialog.open(DialogAccountReauthComponent, {
                            width: '680px',
                            data: {
                                account: account
                            }
                        });
                        dialogRef.componentInstance.onAccept
                            .pipe(take(2))
                            .subscribe(function (r) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!r) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.onAddNewAccount(account.accountId)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.onAddNewAccount(account.accountId)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype._getData = function (_event) {
        return __awaiter(this, void 0, void 0, function () {
            var s, f, group, result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        s = this._sessionS.getSession();
                        f = s.features;
                        group = s.group;
                        return [4 /*yield*/, this.accountService.loadAll(s.user, this._paginate)];
                    case 1:
                        result = _a.sent();
                        if (!(result['items'].length === 0 &&
                            !group.autoAddLocationsTriggered &&
                            (f.userFeatures.autoAddLocationsTrigger || f.generalFeatures.autoAddLocationsTrigger))) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._modalService.openConfirmModal('Attention: Redirecting you to Google Business Profile Login', 'After clicking Continue, please ensure you select the account you use for managing your Google Business Profiles', null, AlertType.INFO, 'Continue')];
                    case 2:
                        if (!_a.sent()) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.onAddNewAccount()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this._groupService.updateGroup(s.gid, { autoAddLocationsTriggered: true });
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this._snack.openError('There was an error while loading the data. Please try again or contact support');
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype.onAddNewAccount = function (accountId) {
        if (accountId === void 0) { accountId = null; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, uid, gid, url, oauth_1, popupTick_1, e_2, message;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._dialog.closeAll();
                        this.da = null;
                        this.dl = null;
                        this._subscribeSafe(this._googleAccountS.onLoadAll$, function (ev) {
                            if (ev.success !== true) {
                                _this._snack.openError(ev.message);
                                return;
                            }
                            // Everything looks good...
                            // Prevent multiple dialogs from being opened
                            if (!_this.da)
                                _this._openAddAccountsDialog(accountId);
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this._snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings");
                        _a = this._sessionS.getSession(), uid = _a.uid, gid = _a.gid;
                        return [4 /*yield*/, this._google.authenticate(gid, uid)];
                    case 2:
                        url = _b.sent();
                        oauth_1 = window.open(url, '_blank');
                        popupTick_1 = setInterval(function () {
                            var _a;
                            if ((_a = oauth_1) === null || _a === void 0 ? void 0 : _a.closed) {
                                clearInterval(popupTick_1);
                                // TODO: Must wait before loadAll as is not sure the oauth flow impacted firestore?
                                // race condition here?
                                _this._googleAccountS.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        message = 'There was an error with the GBP Authentication service';
                        console.error(message, e_2);
                        this._snack.openError(message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype.onDeleteAccount = function (account) {
        var _this = this;
        var dialogRef = this._dialog.open(DialogDeleteAccountComponent, {
            width: '680px',
            data: {
                account: account
            }
        });
        dialogRef.disableClose = true;
        this._subscribeSafe(dialogRef.componentInstance.onDelete, function () {
            _this._snack.openSuccess('The account was successfully deleted.');
            _this.ngOnInit();
        });
    };
    // TODO: Unused, remove?
    // // apply filter from search
    // applyFilter(filterValue: string) : void {
    //   this.dataSource.filter = filterValue;
    //
    //   if (this.dataSource.paginator) {
    //     this.dataSource.paginator.firstPage();
    //   }
    // }
    // check for nested objects
    AccountsComponent.prototype._nestedFilterCheck = function (search, data, key) {
        if (typeof data[key] === 'object') {
            for (var k in data[key]) {
                if (data[key][k] !== null) {
                    search = this._nestedFilterCheck(search, data[key], k);
                }
            }
        }
        else {
            search += data[key];
        }
        return search;
    };
    AccountsComponent.prototype._openAddAccountsDialog = function (accountId) {
        var _this = this;
        if (accountId === void 0) { accountId = null; }
        // TODO: Replace for modal service
        this.da = this._dialog.open(DialogAccountsComponent, {
            width: '680px',
            data: { googleAccounts: this._googleAccountS.accounts$,
                accountId: accountId },
            autoFocus: false
        });
        this._subscribeSafe(this.da.backdropClick(), function () {
            _this._dialog.closeAll();
            _this.da = null;
        });
        this._subscribeSafe(this.da.afterClosed(), function (selectedAccountId) {
            _this._dialog.closeAll();
            //this.getData(this.paginate);
            if (!selectedAccountId) {
                _this.da = null;
                return;
            }
            if (accountId) {
                _this.da = null;
                _this._dialog.closeAll();
                return;
            }
            _this.dl = _this._dialog.open(DialogLocationsComponent, {
                width: '680px',
                data: { selectedAccountId: selectedAccountId,
                    googleAccounts: _this._googleAccountS.accounts$ }
            });
            // Destroy the dialogs on close to get clean data.
            _this._subscribeSafe(_this.dl.afterClosed(), function (data) {
                var _a;
                _this.dl = null;
                _this.da = null;
                if (data === 'back') {
                    _this._openAddAccountsDialog(null);
                }
                else if (data !== '') {
                    var accountId_1 = (_a = selectedAccountId) === null || _a === void 0 ? void 0 : _a.split('/')[1];
                    _this._router.navigate(['accounts', accountId_1, 'locations']); //, 'locations'
                }
                _this._getData(_this._paginate);
            });
        });
    };
    AccountsComponent.prototype.handleReload = function ($event) {
        this._paginate = $event;
        this._getData($event);
    };
    AccountsComponent.prototype.goLocation = function (element) {
        var _a, _b, _c, _d;
        var errorAccount = true;
        if ((_b = (_a = element) === null || _a === void 0 ? void 0 : _a.gauthStatus) === null || _b === void 0 ? void 0 : _b.isValid) {
            errorAccount = !((_d = (_c = element) === null || _c === void 0 ? void 0 : _c.gauthStatus) === null || _d === void 0 ? void 0 : _d.isValid);
        }
        this._router.navigate(['/accounts/', element.id || element.accountId, 'locations'], { queryParams: { errorAccount: errorAccount } });
    };
    AccountsComponent.prototype.refresh = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var elementHtml, r, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.loading_refresh_account$.getValue()) {
                            this._snack.openInfo("Please wait while we update your account");
                            return [2 /*return*/];
                        }
                        this.loading_refresh_account$.next(true);
                        elementHtml = document.getElementById("spin-" + element.accountId);
                        elementHtml.classList.add('active-spin');
                        this._snack.openInfo("Account locations are being refreshed. This may take several minutes");
                        // FIXME: Why fixed timeout?
                        setTimeout(function () {
                            elementHtml.classList.remove('active-spin');
                        }, 2500);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.accountService.refreshAccountLocations(element)];
                    case 2:
                        r = _a.sent();
                        if (r.data.status === 'COMPLETE') {
                            this._snack.openSuccess(r.data.response);
                        }
                        else if (r.data.status === 'FAIL') {
                            throw r;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        this._snack.openError('The operation has been failed');
                        return [3 /*break*/, 4];
                    case 4:
                        this.loading_refresh_account$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    return AccountsComponent;
}(BaseComponent));
export { AccountsComponent };
// Modal "Select an account"
var DialogAccountsComponent = /** @class */ (function (_super) {
    __extends(DialogAccountsComponent, _super);
    function DialogAccountsComponent(data, dialogRef, _router, _accountService, _sessionS, _snack) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this._router = _router;
        _this._accountService = _accountService;
        _this._sessionS = _sessionS;
        _this._snack = _snack;
        _this.selectedAccount = null;
        _this._subscribeSafe(_this.data.googleAccounts, function (result) {
            _this.googleAccounts = result;
            _this.resultSearchAccount = result;
            if (_this.data.accountId != null) {
                _this.close();
                _this._accountService.updateGauth(_this._sessionS.getSession().gid, _this.data.accountId).then(function (res) {
                    _this._router.navigate(['accounts', _this.data.accountId, 'locations']);
                    _this._snack.openSuccess('Your account has been re-authenticated!');
                });
            }
        });
        return _this;
    }
    DialogAccountsComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    DialogAccountsComponent.prototype.addAccount = function () {
        this.dialogRef.close(this.selectedAccount);
    };
    DialogAccountsComponent.prototype.filterAccount = function () {
        var _this = this;
        this.resultSearchAccount = this.googleAccounts.filter(function (account) { return account.accountName.toLowerCase().includes(_this.search.toLowerCase()); });
    };
    return DialogAccountsComponent;
}(BaseComponent));
export { DialogAccountsComponent };
// Delete Dialog
var DialogDeleteAccountComponent = /** @class */ (function () {
    function DialogDeleteAccountComponent(data, dialogRef, _accountService, _locationService, _sessionS, _spinnerService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this._accountService = _accountService;
        this._locationService = _locationService;
        this._sessionS = _sessionS;
        this._spinnerService = _spinnerService;
        this.onDelete = new EventEmitter();
        this.domain$ = this._sessionS.domain$;
        this.account = data.account;
    }
    DialogDeleteAccountComponent.prototype.handleDeleteAccount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, accountId, locations, locations_1, locations_1_1, loc, e_4_1, err_2;
            var e_4, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        accountId = this.account.accountId;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 12, 13, 15]);
                        this._spinnerService.loading$.next(true);
                        return [4 /*yield*/, this._locationService.fetchAccountLocations(gid, accountId)];
                    case 2:
                        locations = _b.sent();
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 8, 9, 10]);
                        locations_1 = __values(locations), locations_1_1 = locations_1.next();
                        _b.label = 4;
                    case 4:
                        if (!!locations_1_1.done) return [3 /*break*/, 7];
                        loc = locations_1_1.value;
                        return [4 /*yield*/, this._locationService.deleteLocation(gid, accountId, loc, { notifyChange: false, deleteEmptyAccount: false })];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6:
                        locations_1_1 = locations_1.next();
                        return [3 /*break*/, 4];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_4_1 = _b.sent();
                        e_4 = { error: e_4_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (locations_1_1 && !locations_1_1.done && (_a = locations_1.return)) _a.call(locations_1);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7 /*endfinally*/];
                    case 10: 
                    // All Account locations deleted, delete account
                    return [4 /*yield*/, this._accountService.delete(gid, accountId)];
                    case 11:
                        // All Account locations deleted, delete account
                        _b.sent();
                        this._locationService.notifySomeLocationChanged();
                        return [3 /*break*/, 15];
                    case 12:
                        err_2 = _b.sent();
                        console.error("Error deleting account gid=" + gid + " accountId=" + accountId, err_2);
                        return [3 /*break*/, 15];
                    case 13: return [4 /*yield*/, this._sessionS.refresh()];
                    case 14:
                        _b.sent();
                        this.onDelete.emit(true);
                        this.dialogRef.close();
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    return DialogDeleteAccountComponent;
}());
export { DialogDeleteAccountComponent };
// Delete Dialog
// TODO: Replace with openConfirmDialog
var DialogAccountReauthComponent = /** @class */ (function () {
    function DialogAccountReauthComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.onAccept = new EventEmitter(false);
        this.account = data.account;
    }
    DialogAccountReauthComponent.prototype.ReAuth = function () {
        this.onAccept.emit(true);
    };
    return DialogAccountReauthComponent;
}());
export { DialogAccountReauthComponent };
