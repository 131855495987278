import {Injectable} from '@angular/core';
import {DataPicker} from '../constants/data-picker';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  multiCharset(stats: any [], dataPicker?: DataPicker) {
    const dataset = [];

    if (!dataPicker.multiChart)
      return [];

    if (!dataPicker) {
      stats?.forEach(stats => {
        Object.keys(stats)?.forEach(key => {
          const stat = stats[key];
          if (key !== 'title' && key !== 'totals') {
            dataset.push(stat);
          }
        });
      });
      return dataset;
    }

    dataPicker.multiChart?.forEach(m => {
      stats?.forEach(stats => {
        Object.keys(stats)?.forEach(key => {
          const stat = stats[key];
          const mId = m['key'] ? (m['key']).toUpperCase() : m.id;
          const id = typeof(mId) == 'string' ? key.toUpperCase() : typeof(stat?.id) == 'string' ? Number(stat?.id) : stat?.id;
          if (key !== 'title' && key !== 'totals' && mId === id) {
            dataset.push(stat);
          }
        });
      });
    });

    return dataset;
  }

}
