import { __extends } from "tslib";
// dep
import { OnInit, EventEmitter } from '@angular/core';
// app
import { KeywordsFilterConfigService } from './../services/keywords-filter-config.service';
import { Granularity } from '../../../constants/data-picker';
// import { IReportFilterConfigData } from '../../../constants/report-filter-config-data';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var ReportsFilterComponent = /** @class */ (function (_super) {
    __extends(ReportsFilterComponent, _super);
    function ReportsFilterComponent(_keywordsFilterConfigS, _sessionS) {
        var _this = _super.call(this) || this;
        _this._keywordsFilterConfigS = _keywordsFilterConfigS;
        _this._sessionS = _sessionS;
        _this.navigateBackEmitter = new EventEmitter(); // <- the parent should handle where to redirect
        _this.initialized = false;
        _this.minDateSelected = '';
        _this.maxDateSelected = '';
        _this.dateReady = false;
        _this.dateRangeObject = {
            dateRangeLimits: {
                minDate: null,
                maxDate: null
            },
            selectedRange: { minDate: null, maxDate: null },
            granularity: {
                start: Granularity.Year,
                depth: Granularity.Year
            },
            presetDateRanges: null,
            viewBy: Granularity.Day
        };
        _this.session$ = _this._sessionS.session$;
        return _this;
    }
    ReportsFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this._keywordsFilterConfigS.dateRestrictionSubject$, function (dateRestriction) {
            var _a, _b, _c, _d;
            console.log('dateRestriction', dateRestriction);
            if (dateRestriction) {
                _this.dateRangeObject.dateRangeLimits.minDate = dateRestriction.minDate;
                _this.dateRangeObject.dateRangeLimits.maxDate = dateRestriction.maxDate;
                console.log('initialDates updated');
                _this.dateReady = true;
                if (!_this.initialized) {
                    _this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({
                        // testing, we need to initialize with the default min/max dates (defined in doc) if possible
                        minDate: (_b = (_a = dateRestriction) === null || _a === void 0 ? void 0 : _a.minDate) === null || _b === void 0 ? void 0 : _b.toLocaleDateString('en-CA'),
                        maxDate: (_d = (_c = dateRestriction) === null || _c === void 0 ? void 0 : _c.maxDate) === null || _d === void 0 ? void 0 : _d.toLocaleDateString('en-CA')
                    });
                    _this.initialized = true;
                }
            }
        });
    };
    ReportsFilterComponent.prototype.dateChanged = function (event) {
        // Date().toISOString() creates the wrong date, 31/12/2023 if you select 01/01/2024 and you are in Tokyo (UTC+9)
        // Date().toLocaleDateString() creates the correct date, 01/01/2024 if you select 01/01/2024 and you are in Tokyo (UTC+9)
        // same scenario if you are in LA (UTC-8) or anywhere else in the world
        this.minDateSelected = event.startDate.toLocaleDateString('en-CA');
        this.maxDateSelected = event.endDate.toLocaleDateString('en-CA');
        // should prompt a report update
        this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({ minDate: this.minDateSelected, maxDate: this.maxDateSelected });
        console.log('dateChanged', this.minDateSelected, this.maxDateSelected);
    };
    ReportsFilterComponent.prototype.hasLastMonth = function () {
        return false; // TESTING
    };
    ReportsFilterComponent.prototype.navigateBack = function () {
        this.navigateBackEmitter.emit(true);
    };
    return ReportsFilterComponent;
}(BaseComponent));
export { ReportsFilterComponent };
