import { __extends } from "tslib";
import { MatDialogRef } from '@angular/material';
import { GoogleLocationService } from '../../services/google-location.service';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';
// import AccountLocation from '../../constants/firestore/account-location';
/**
 * Dialog to select an Account (from a list provided externally) and a Location.
 * Used only from External Grade
 */
var AccountLocSelectorComponent = /** @class */ (function (_super) {
    __extends(AccountLocSelectorComponent, _super);
    function AccountLocSelectorComponent(data, dialogRef, _googleLocationS, _sessionS) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this._googleLocationS = _googleLocationS;
        _this._sessionS = _sessionS;
        _this.locations$ = _this._googleLocationS.locations$;
        _this.user = _this.data.user;
        _this.googleAccounts = _this.data.accounts;
        _this._subscribeSafe(_this._googleLocationS.locations$, function (locations) { return _this._verificateLocations(locations); });
        return _this;
    }
    AccountLocSelectorComponent.prototype.backAccount = function () {
        this._googleLocationS.reset();
    };
    /**
     * When the user chooses an Account, fetch all his locations
     */
    AccountLocSelectorComponent.prototype.selectAccount = function () {
        var externalGradeId = this._sessionS.getSession('EXTERNAL_GRADER').externalGradeId;
        this._googleLocationS.loadAllGrade(this.user, this.selectedAccount.name, externalGradeId);
    };
    /**
     * User press 'Grade location' with a Location selected, and the dialog is closed.
     */
    AccountLocSelectorComponent.prototype.selectLocation = function () {
        this.dialogRef.close({ location: this.selectedLocation, account: this.selectedAccount });
        this._googleLocationS.reset();
    };
    AccountLocSelectorComponent.prototype._verificateLocations = function (locations) {
        locations.forEach(function (location) {
            var _a;
            if ((_a = location) === null || _a === void 0 ? void 0 : _a.locationState) {
                var s = location.locationState;
                if (s.hasPendingVerification) {
                    s.description = 'Pending verification';
                    s.fill = 'review-response--error';
                    s.txt = 'txt--orange';
                }
                else if (s.isDisabled) {
                    s.description = 'Disabled';
                    s.fill = 'review-response--info';
                    s.txt = 'txt--blue';
                }
                else if (s.needsReverification) {
                    s.description = 'Needs Reverification';
                    s.fill = 'review-response--error';
                    s.txt = 'txt--orange';
                }
                else if (s.isSuspended) {
                    s.description = 'Suspended';
                    s.fill = 'review-response--info';
                    s.txt = 'txt--blue';
                }
                else if (s.isDuplicate) {
                    s.description = 'Duplicate';
                    s.fill = 'review-response--error';
                    s.txt = 'txt--orange';
                }
            }
        });
    };
    return AccountLocSelectorComponent;
}(BaseComponent));
export { AccountLocSelectorComponent };
