import { __awaiter, __generator } from "tslib";
import { VERSION, isDevMode } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppConfigService } from './app.config';
// import { AuthService } from './services/auth.service';
import { AuthProxyService } from './services/auth.proxy.service';
import { SessionService } from './services/session.service';
import { ModalService } from './services/modal.service';
// import { EditCardComponent } from './settings/billing/edit-card/edit-card.component';
import { environment as ENV } from '@environment';
import { GlobalErrorHandler } from './services/error-handle.service';
import { PaywallService } from './services/paywall.service';
// import { BillingGuardService } from './services/billing-guard.service';
// import { PricingGuardService } from './services/pricing-guard.service';
// import { WidgetComponent } from './widget/components/widget.component';
// import {SkeletonInvoicesComponent} from './modules/skeletons/skeleton-invoices/skeleton-invoices.component';
// import {skeletonPaymentMethodComponent} from './modules/skeletons/skeleton-payment-method/skeleton-payment-method.component';
var DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};
var DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'MM/DD/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
// To avoid dependencies issues, we need to create a factory for the GlobalErrorHandler
export function errorHandlerFactory(sessionS, authProxyS, modalS) {
    return new GlobalErrorHandler(sessionS, authProxyS, modalS);
}
var ɵ0 = {}, ɵ1 = DEFAULT_SWIPER_CONFIG, ɵ2 = DATE_FORMAT, ɵ3 = { autoClose: true };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function initializeApp(appConfig) {
    console.log("Angular version: " + VERSION.full);
    console.log('Mode: ' + (isDevMode() ? 'DEVELOPMENT' : 'PRODUCTION'));
    return appConfig.fireConfig();
}
/**
 * Will ensure they domain is loaded before any component is instanced, so
 * all of them can use the synchrounous sessionS.getDomain()
 */
export function makeInitializerFn(appConfigS, sessionS, paywallS, html) {
    var _this = this;
    return function () { return __awaiter(_this, void 0, void 0, function () {
        var domain;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, sessionS.waitDomain()];
                case 1:
                    domain = _a.sent();
                    if (ENV.apiUrlOverwriteWithWL) {
                        ENV.apiUrl = domain.apiUrl;
                    }
                    html.getElementById('favicon').setAttribute('href', domain.branding.favicon || '/assets/images/favicon-wl/favicon.ico');
                    return [2 /*return*/];
            }
        });
    }); };
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
