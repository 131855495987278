// dep
import { Component } from '@angular/core';

// app
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';

// TODO: The addition of this class iss managed by trial-message-component.ts,
// must be refactored
// const BODY_CSS_CLASS = 'trial-message-wrapper'

@Component({
  selector: 'app-impersonate-message',
  templateUrl: './impersonate-message.component.html',
  styleUrls:  ['./impersonate-message.component.scss']
})
export class ImpersonateMessageComponent {

  session$ = this._sessionS.session$
  constructor(private _sessionS : SessionService,
              public navigationS : NavigationService,              
  ) {}
  
  
}
