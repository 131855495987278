// dep
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environment';

// app
import { EXTERNAL_GRADES, GROUPS } from '../constants/firestore/collections';
import { GoogleAuth } from '../constants/firestore/user';
import { Domain } from '../constants/firestore/domain';

@Injectable({
  providedIn: 'root'
})
export class ExternalGradeService {

  constructor(
    private _afs: AngularFirestore,
    private _http: HttpClient,
  ) { }

  async getExternalGradeToken(gid : string, externalGradeId : string) : Promise<GoogleAuth | null> {
    return (await this._afs.collection(GROUPS).doc(gid).collection(EXTERNAL_GRADES).doc(externalGradeId).get().toPromise()).data()?.googleAuth || null
  }

  uploadExternalGradePDF(gid: string, loc_id: string, acc_id: string, 
                         pdf: string, grade_lead_id: string, branding: Domain['branding']): Promise<any> {
    const data = {
      gid,
      loc_id,
      acc_id,
      pdf,
      grade_lead_id,
      branding
    }

    return this._http.post(`${environment.apiUrl}/v2/pdf/save`, data).toPromise()
  }
}
