<mat-drawer-container [class.z-index--auto]="isSliderOpened" [hasBackdrop]="true">
  <mat-drawer-content [class.z-index--999]="isSliderOpened">
    <div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="updatingData">
        <mat-spinner></mat-spinner>
    </div>

    <!-- filter -->
    <app-reports-filter
      *ngIf="!loading"
      [isReport]="isReport"
      [accountId]="accountId"
      [locations]="isReport ? locations : location"
      [isShared]="isShared"
      [type]="'qanda'"
      [accounts]="accounts"
      [periodReportValue]="periodType"
      [questionsTypeReport]="report?.questionsType"
      (questionsType)="questionTypeChanged($event)"
      (questionsPeriod)="questionPeriodChanged($event)"
      (locationsChanged)="locationsFilteredChanged($event)"
      (keywordSearch)="keywordSearch($event)"
    >
    </app-reports-filter>
      
    <app-skeleton-questions-answer *ngIf="loading"></app-skeleton-questions-answer>

    <section class="col-12 mb--30" *ngIf="!loading" [class.p--0]="!isReport">

      <mat-drawer #slider openedStart mode="'over'" position="end" [ngClass]="{'top-space': (session$ | async).isImpersonating }">
        <app-slider-questions 
          [slider]="slider"
          [question]="questionSlider"
          [gid]="(session$ | async).gid"
          [accountId]="accountId"
          [locationId]="locationId"
          [displayName]="(session$ | async).user.displayName"
          [isImpersonating]="(session$ | async).isImpersonating"
          [isReport]="isReport"
          (closedSlider)="sliderClosed()">
        </app-slider-questions>
      </mat-drawer>
  
      <!-- TOTALS CARDS -->
      <div class="row justify-content-between box--height mt--60">
        <div class="col-12 col-sm-6 col-lg-3 mb--30">
          <div class="box box--padding-xxs box--shadow box--rounded bg--white">
            <h2 class="min--height-title-card">
              <strong>Total Questions For Location</strong>
            </h2>
            <div class="d-flex justify-content-between flex-column">
              <div class="d-flex align-items-center justify-content-start mb--25">
                <p class="txt--xxxl txt--lightblue fw--medium m--0">
                  {{ totalsData?.totalQuestions | number }}
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-12 col-sm-6 col-lg-3 mb--30">
          <div class="box box--padding-xxs box--shadow box--rounded bg--white">
            <h2 class="min--height-title-card">
              <strong>Total Questions Answered by Owner</strong>
            </h2>
            <div class="d-flex justify-content-between flex-column">
              <div class="d-flex align-items-center justify-content-start mb--25">
                <p class="txt--xxxl txt--lightblue fw--medium m--0">
                  {{ totalsData?.withMerchantResponses | number }}
                </p>
              </div>
            </div>
          </div>
        </div>
    
        <div class="col-12 col-sm-6 col-lg-3 mb--30">
            <div class="box box--padding-xxs box--shadow box--rounded bg--white">
            <h2 class="min--height-title-card">
                <strong>Total Questions Without Answer by Owner</strong>
            </h2>
            <div class="d-flex justify-content-between flex-column">
                <div class="d-flex align-items-center justify-content-start mb--25">
                <p class="txt--xxxl txt--lightblue fw--medium m--0">
                    {{ totalsData?.withoutMerchantResponses | number }}
                </p>
                </div>
            </div>
          </div>
        </div>
    
        <div class="col-12 col-sm-6 col-lg-3 mb--30">
            <div class="box box--padding-xxs box--shadow box--rounded bg--white">
              <h2 class="min--height-title-card">
                <strong>New Questions in Last 30 Days</strong>
              </h2>
              <div class="d-flex justify-content-between flex-column">
                  <div class="d-flex align-items-center justify-content-start mb--25">
                  <p class="txt--xxxl txt--lightblue fw--medium m--0">
                      {{ totalsData?.totalQuestionsLast30Days | number }}
                  </p>
                  </div>
              </div>
            </div>
        </div>
      </div>
      
      <!-- QUESTIONS -->
      <div *ngIf="questionsData?.items?.length > 0" class="row box--height">
        <ng-container *ngFor="let item of questionsData?.items">
            <div class="col-12 col-sm-6 col-lg-4 mb--30">
              <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                <section [class.location]="!isReport" class="header-card">
                  <div class="col-12 row p--0 m--0">
                    <span *ngIf="!item?.question?.author?.profilePhotoUri" [class.img-profile-default-location]="!isReport" class="img-profile-default mr--25 bg--dark--gray border--radius-50 txt--center">
                      <em class="fa fa-user txt--dark--white txt--58 pt--15"></em>
                    </span>

                    <img *ngIf="item?.question?.author?.profilePhotoUri" class="object-fit-contain w--16 mr--25" src="{{item?.question?.author?.profilePhotoUri}}" alt="image profile"/>

                    <div class="row w--82">
                      <div class="col-10 p--0 header-card-data">
                        <p class="txt--md fw--semibold m--0 min--height--20">{{item?.question?.author?.displayName}}</p>
                        <p class="txt--sm mt--5 mb--5">{{item?.question?.createTime | dateAgo}}</p>
                        <span *ngIf="item?.answers?.totalSize > 0" class="txt--xs bg--gray txt--dark--gray p--tb-2 p--lr-5 border--radius-3">
                          {{item?.answers?.totalSize}} Answers
                        </span>
                        <span class="border--radius-3 txt--white p--tb-2 p--lr-5 txt--xs"
                        [ngClass]="{
                          'bg--red': item?.status == 'No Answer',
                          'bg--yellow': item?.status == 'No Owner Answer',
                          'bg--green': item?.status == 'Answered By Owner'
                        }">{{item?.status}}</span>
                      </div>

                      <button mat-flat-button class="btn btn-icon btn-icon--lg col-1 bg--gray" (click)="openQuestions(slider, item)">
                        <em class="far fa-long-arrow-up txt--blue txt--price-sup"></em>
                      </button>
                    </div>

                    <p class="col-12 txt--md mt--10 ml--5 p--0 questions">{{item?.question?.text}}</p>
                    
                    <div *ngIf="isReport && item?.locationName || item?.address" class="bg---gray-200 w--100 mt--10 mb--15 p--10 border--radius-3">
                      <p class="m--0 txt--blue" *ngIf="item?.locationName">{{item?.locationName}}</p>
                      <gmb-address 
                        class="txt--lightgray m--0"
                        *ngIf="item?.address || item?.serviceArea"
                        [address]="item?.address"
                        [serviceAreas]="item?.serviceArea?.places?.placeInfos">
                      </gmb-address>
                    </div>
                  </div>
                  
                </section>

                <mat-divider></mat-divider>

                <section class="vh--24 answer-card">
                    <p *ngIf="!item?.answers?.length" class="txt--medium-gray">This question has no answers yet.</p>
                    
                    <div *ngFor="let answer of item?.answers" class="col-12 row p--0 m--0 answer-card-info mb--20">
                    
                      <span *ngIf="!answer?.author?.profilePhotoUri" class="img-answer-profile-default mr--10 bg--dark--gray border--radius-50" [class.img-answer-profile-default-report]="isReport">
                        <em class="fa fa-user txt--dark--white pt--10"></em>
                      </span> 
                      <img *ngIf="answer?.author?.profilePhotoUri" class="object-fit-contain w--8-5 mr--10" src="{{answer?.author?.profilePhotoUri}}" alt="image profile"/>
                      
                      <div class="col-10 p--0 w--100" [class.ml--10]="isReport" [class.w--86]="!answer?.author?.profilePhotoUri" [class.pl--45]="answer?.author?.profilePhotoUri">
                        <section>
                          <p class="col-10 p--0 txt--14 txt--dark--gray m--0 pr--5 display-in-bl" [class.w--85]="answer?.status == 'FAIL' || answer?.status == 'PENDING'">{{answer?.text}}</p>
                          <p *ngIf="answer?.status == 'FAIL'" class="d--inl-block ml--20 position--absolute txt--red" matTooltip="There was an error while deleting the answer." matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                            <em class="fas fa-exclamation-triangle txt--22"></em>
                          </p>
                          <p *ngIf="answer?.status == 'PENDING'" class="d--inl-block ml--20 position--absolute txt--yellow" matTooltip="The request is being processed by Google and changes may take a few moments to be updated on your business profile." matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                            <em class="fas fa-exclamation-triangle txt--22"></em>
                          </p>
                        </section>
                        <p class="txt--xs m--0 mt--5">{{answer?.createTime | dateAgo}}</p>
                      </div>
                    </div>
                </section>

                <mat-divider></mat-divider>

                <section class="send-card">
                  <div class="col-12 row m--0 mt--15 mb--15">
                    <p class="col-12 p--0">{{!item?.hasOwner ? null : 'Answer from the business owner'}}</p>
                    <span class="w--8 h--8 mr--10 txt--center">
                      <em class="fa fa-store txt--lightgray txt--18 pt--5 pb--5"></em>
                    </span>
                    <textarea matInput placeholder="Post your answer publicly..." [class.textarea-hasOwner]="item?.hasOwner" id="{{item?.questionId}}" [appEmailPhoneValidation]="getErrorId(item?.questionId)">{{getOwnerAnswer(item)?.text}}</textarea>
                    <button *ngIf="!item?.hasOwner" mat-flat-button class="btn btn-icon buttons-actions btn-send ml--10 bg--green txt--white" (click)="sendNewAnswer(item, null, getErrorId(item?.questionId))">
                      <em class="fa fa-location-arrow"></em>
                    </button>
                    <button *ngIf="item?.hasOwner" mat-flat-button color="primary" class="btn btn-icon buttons-actions ml--10" (click)="sendNewAnswer(item, null, getErrorId(item?.questionId))" matTooltip="Edit your answer" matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                      <em class="fas fa-pencil"></em>
                    </button>
                    <button *ngIf="item?.hasOwner" mat-flat-button color="warn" class="btn btn-icon buttons-actions ml--10" (click)="deleteQuestions(item)" matTooltip="Delete your answer" matTooltipPosition="below" matTooltipClass="tooltip tooltip--black">
                      <em class="far fa-trash-alt"></em>
                    </button>
                    <div [class.error-container]="isReport" id="{{getErrorId(item?.questionId)}}" class="txt--red txt--sm mt--5 ml--45"></div>
                  </div>
                </section>
              </div>
            </div>
        </ng-container>
      </div>
    
      <div *ngIf="questionsData?.items?.length == 0" class="box box--rounded box--shadow bg--white">
        <div class="box__header d-flex align-items-center justify-content-center">
          <h2 class="txt--capitalize"><strong>No Questions and Answers</strong></h2>
        </div>
      </div>

      <!-- BTN LOAD MORE -->
      <section class="txt--align--center mb--25">
        <button *ngIf="questionsData?.items?.length < questionsData?.total" mat-flat-button class="btn btn--action" color="primary" (click)="loadMoreQuestions()">
          Load More Questions <em class="fal fa-angle-down"></em>
        </button>
      </section>

      <!-- Multilocations table -->
      <app-multilocations-info-table *ngIf="isReport && hasSeveralLocations()"
        class="d-none"
        [isLoading]="tableLoading"
        [reportType]="'qanda'"
        [reportId]="reportId"
        [title]="'Multi Locations Table'"
        [reportName]="report?.reportName"
        [dataPicker]="dataPicker"
        [isLegacy]="false"
        [isShared]="isShared"
        [hasExportPDF]="true"
        [hasExportCSV]="true"
        [locations]="locations"
        [displayedColumns]="displayedColumnsMultLoc"
        [fieldsColumns]="fieldsColumnsMultiLoc"
        [dataSource]="dataSourceMultiLoc"
        [pagination]="paginationMultiloc"
        [sort]="sortMultiloc"
        (handleMultilocSort)="handleMultilocSort($event)"
        (handleMultilocPaginate)="handleMultilocPaginate($event)"
        [appUserFeature]="'searchTermsMultilocationTable'"
      >
      </app-multilocations-info-table>
    </section>
  </mat-drawer-content>
</mat-drawer-container>
