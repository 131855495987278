<div class="content__header d-flex justify-content-between align-items-center">
  <h1><strong>Locations</strong></h1>
  <app-options-filter class="ml--auto mr--20" 
                      *ngIf="accountsOptions.length > 1 && !(session$ | async).isMember" 
                      [allOptions]="accountsOptions" 
                      [isMultiple]="false" 
                      [labelFiltered]="labelAccountsFiltered" 
                      [hasApply]="false" 
                      [hasClear]="false" 
                      (filterChanged)="filterChanged($event)">
  </app-options-filter>
  <button mat-flat-button *ngIf="(session$ | async).isAdmin" color="accent" class="btn btn--icon btn--md ml--20" (click)="onAddAccount()">
    Add New Listing
  </button>
</div>
<div class="content__body">
  <div class="table--shadow bg--white">
    <div
      class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center"
    >
      <div class="input-field input-field--search">
        <div class="table__icon table__icon--lg">
          <em class="fas fa-search"></em>
        </div>
        <input
          (input)="applyFilter($event.target.value)"
          [(ngModel)]="textSearch"
          placeholder="Type to search"
        />
      </div>

      <div *ngIf="(session$ | async).isAdmin" class="table__buttons alternate-theme">
        <ng-container>
          <button
            (click)="changePlan(listCheckLocations)"
            mat-flat-button
            color="primary"
            class="btn btn--xs mr--10"
            [disabled]="!(listCheckLocations.length > 0)"
            *ngIf="(session$ | async).subscription.pricingVersion < 3"
          >
            Change Subscription
          </button>
          <button
            (click)="deleteSelected()"
            mat-flat-button
            color="primary"
            class="btn btn--xs mr--10"
            [disabled]="!(listCheckLocations.length > 0)"
          >
            Delete Selected
          </button>
          <button
            (click)="refreshSelected()"
            mat-flat-button
            color="primary"
            class="btn btn--xs mr--10"
            [disabled]="!(listCheckLocations.length > 0)"
          >
            Refresh Selected
          </button>
        </ng-container>
      </div>
    </div>

    <app-skeleton-location
      *ngIf="loadingTable$ | async"
    ></app-skeleton-location>

    <app-no-data
      [type]="'box--shadow'"
      *ngIf="NotFoundSearch$ | async"
    ></app-no-data>

    <table
      mat-table
      matSort
      [dataSource]="locations"
      class="table table--sm table--last-row-border"
      *ngIf="!(loadingTable$ | async) && !(NotFoundSearch$ | async)"
    >
      <!--show if list view enabled-->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [(ngModel)]="allChecked"
            (change)="toggleCheckAll($event)"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            class="cell__content d-flex justify-content-start align-items-center"
          >
            <mat-checkbox
              [id]="element.locationId"
              [(ngModel)]="element.isChecked"
              (change)="selectLocation(element, $event)"
            ></mat-checkbox>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="!allChecked && !listCheckLocations.length">
            <div
              style="background-color: #eff0f3; border-radius: 5px; padding: 10px 15px; width: fit-content;cursor:context-menu"
            >
              Select Locations
            </div>
          </ng-container>

          <ng-container *ngIf="allChecked">
            <div
              style="background-color: #eff0f3; border-radius: 10px; padding: 10px 15px; width: fit-content; display: inline; margin-right:5px"
            >
              <ng-container *ngIf="allLocationCheck; else elseTemplate">
                Selected
                <span style="color: #424656;"
                  >({{ listCheckLocations.length }})</span
                >.
                <a
                  style="color: #2f89fc; cursor: pointer; font-weight: bold;"
                  (click)="selectAll()"
                  >Unselect All</a
                >
              </ng-container>
              <ng-template #elseTemplate>
                Selected
                <span style="color: #424656;"
                  >({{ listCheckLocations?.length }})</span
                >.
                <a
                  style="color: #2f89fc; cursor: pointer;"
                  (click)="selectAll()"
                  >Select All Locations ({{ pagination?.total }})</a
                >
              </ng-template>
            </div>
          </ng-container>
          <ng-container *ngIf="!allChecked && listCheckLocations.length > 0">
            <div
              style="background-color: #eff0f3; border-radius: 5px; padding: 10px 15px; width: fit-content;margin-right: 10px; display:inline; cursor:context-menu"
            >
              Selected
              <span style="color: #424656;"
                >({{ listCheckLocations.length }})</span
              >
            </div>
          </ng-container>
          <ng-container *ngIf="listCheckLocations.length > 0">
            <div
              style="background-color: #eff0f3; border-radius: 5px; padding: 10px 15px; width: fit-content; display: inline; cursor: pointer;"
              (click)="goNewTabSelected()"
            >
              <span style="cursor: pointer;">Open Selected in New Tab</span>
            </div>
          </ng-container>
        </th>
        <!-- <td
          mat-cell
          [gmbRefreshLockCheck]="element"
          *matCellDef="let element"
          (click)="goLocation(accountObjectId, element)"
        > -->
        <td
          mat-cell
          *matCellDef="let element"
          (click)="goLocation(accountObjectId, element)"
        >
          <ng-container>
            <div class="cell__content d-flex">
              <div class="company">
                <div class="table__icon">
                  <svg-icon src="/assets/images/icons/pin.svg"></svg-icon>
                </div>
                <div class="company__info cursor--pointer">
                  <h3 class="company__title m--0">
                    <strong>
                      {{ element?.locationName }}
                      <em
                        class="fal fa-external-link"
                        src="/assets/images/icons/newTabIcon.svg"
                        (click)="goNewTab(accountObjectId, element, $event)"
                      ></em>
                    </strong>

                    <ng-container
                      *ngIf="!errorAccount || element?.errorLog"
                      [ngSwitch]="setElementSwitch(element)"
                    >
                    <span *ngSwitchCase="verifRequired">
                      <em class="fas fa-exclamation-triangle text-error ml--5"></em><span class="warning-complement txt--red ml--5">Action required. Click for details.</span>
                    </span>
                      <em *ngSwitchCase="'NOT_AUTHORIZED'"
                        matTooltip='This location is no longer authenticated with Google. To fix this, first you must remove the location from Map Labs, then click on "Add New Profiles" and reconnect your Google account to Map Labs.'
                        matTooltipPosition="right"
                        matTooltipClass="tooltip tooltip--white"
                        class="fas fa-exclamation-triangle text-warning"
                      ></em>
                      <em
                        *ngSwitchCase="'NOT_FOUND'"
                        matTooltip="This location was not found in GBP. Did you move it or delete it from GBP?"
                        matTooltipPosition="right"
                        matTooltipClass="tooltip tooltip--white"
                        class="fas fa-exclamation-triangle text-warning"
                      ></em>
                      <em
                        *ngSwitchCase="accountError"
                        matTooltip="The authentication for your account failed. Go to accounts and click Authenticate to reconnect"
                        matTooltipPosition="right"
                        matTooltipClass="tooltip tooltip--white"
                        class="fas fa-exclamation-triangle text-warning"
                      ></em>
                      <em
                        *ngSwitchCase="'errorLog'"
                        [matTooltip]="element?.errorLog?.message"
                        matTooltipPosition="right"
                        matTooltipClass="tooltip tooltip--white"
                        class="fas fa-exclamation-triangle text-warning"
                      ></em>
                      <span *ngSwitchDefault></span>
                    </ng-container>
                    
                  </h3>
                  
                  <p class="company__address m--0">
                    <gmb-address [address]="element?.location?.address" [serviceAreas]="element?.location?.serviceArea?.places?.placeInfos"></gmb-address>
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <!--show if list view enabled-->
      <ng-container matColumnDef="subscription">
        <th mat-header-cell *matHeaderCellDef class="txt--center">
          <strong>Subscription</strong>
        </th>
        <td mat-cell *matCellDef="let element" class="txt--center">
          <div class="cell__content alternate-theme">
            <button
              (click)="changePlan(element)"
              mat-flat-button
              color="primary"
              class="btn btn--badge btn--badge--lg"
            >
              <strong
                >{{ element?.subscriptionStatus
                }}<em class="fas fa-pen ml--5"></em>
              </strong>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="txt--right">
          <strong>Actions</strong>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="txt--right"
        >
          <div class="cell__content">
            <button
              mat-flat-button
              color="primary"
              (click)="refreshLocation(element.locationId)"
              class="btn btn-icon mr--10"
            >
              <em
                class="fas fa-redo"
                [ngClass]="{
                  'active-spin': element['lastImpressions-fastTask']?.status === 'SENT' || 
                                  element['lastKeywords-fastTask']?.status === 'SENT'
                }"
              ></em>
            </button>
            <span class="alternate-theme d-flex align-items-center">
              <button
                *ngIf="
                  element?.pendingChange?.status == STATUS_CHANGE_PLAN.PENDING
                "
                matTooltipPosition="left"
                matTooltip="Plan change scheduled for {{ getNextDueDate() }} from {{ element?.subscriptionStatus }}  to {{ element?.pendingChange?.nextPlan }} "
                matTooltipClass="tooltip tooltip--white"
                mat-flat-button
                color="warn"
                class="btn btn-icon mr--10"
                (click)="cancelChangePlan(element)"
              >
                <em class="fas fa-stopwatch"></em>
              </button>
              <button
                matTooltip="Delete"
                matTooltipClass="tooltip tooltip--white"
                mat-flat-button
                color="primary"
                class="btn btn-icon"
                (click)="openDeleteDialog(element)"
              >
                <em class="far fa-trash-alt"></em>
              </button>
            </span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <app-paginator
      #paginator
      *ngIf="!(NotFoundSearch$ | async)"
      [pagination]="pagination"
      (reload)="handleReload($event)"
      [options]="[10, 25, 50, 100, 200, 500]"
    >
    </app-paginator>
  </div>
</div>
