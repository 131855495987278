// dep
import { AfterViewChecked, Component, ChangeDetectorRef, ViewRef } from '@angular/core';

// app
import { SpinnerService } from 'src/app/services/spinner.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-layout',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent /*implements AfterViewChecked*/ {
  public session$ = this._sessionS.session$;
  public domain$  = this._sessionS.domain$;
  
  constructor(
    public spinnerService: SpinnerService,
    // private _cdRef: ChangeDetectorRef,
    private _sessionS : SessionService,
  ) {
  }

  // ngAfterViewChecked() : void {
  //   this.detectChanges();
  // }

  // detectChanges () : void {
  //   if (!(this._cdRef as ViewRef).destroyed) {
  //     this._cdRef.detectChanges();
  //   }
  // }

}
