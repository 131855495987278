// dep
import { Component, Input, OnInit, Pipe, ViewChild } from '@angular/core';
import {MatDialog } from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { Location as AngularLocation} from '@angular/common';

// app
import { Protocol, SAVE_COMPLETE_SCAN, SAVE_COMPLETE_EDIT_SCAN, SAVE_COMPLETE_PROTOCOL, SAVE_COMPLETE_EDIT_PROTOCOL } from '../../constants/firestore/protocol';
import {ReportService} from '../../services/report.service';
import {ModalCreateProtocolComponent} from '../modal-create-protocol/modal-create-protocol.component';
import { ProtocolScanComponent } from '../protocol-scan/protocol-scan.component';
import { ProtocolListComponent } from '../protocol-list/protocol-list.component';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-review-auto-responder',
  templateUrl: './review-auto-responder.component.html',
  styleUrls: ['./review-auto-responder.component.scss']
})
export class ReviewAutoResponderComponent extends BaseComponent implements OnInit {

  // custom pagination elements
  manualPage: number;
  errorMessage: boolean;
  // custom pagination with page numbers
  @Input() page  = 1;
  @ViewChild('protocolScan', {static: false}) protocolScan: ProtocolScanComponent;
  @ViewChild('protocolsList', {static: false}) protocolList: ProtocolListComponent;
  action_button_review  = "New Protocol";
  show_action_button_review  = true;
  selectionProtocol = new SelectionModel<Protocol>(true, []);
  selectedLogs = 0;
  tabs = ['Protocols', 'Activity', 'Scan'];

  constructor(
    private reportS: ReportService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _angularLocation: AngularLocation,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedLogs = this.activatedRoute.snapshot.queryParamMap?.get('tab') === null ? 0 : Number(this.activatedRoute.snapshot.queryParamMap?.get('tab'));
    if (this.selectedLogs == 2) 
      this._angularLocation.go("/review-assistant");
    this.manualPage = 1;
    this.errorMessage = false;
  }

  newProtocol(isEdit: boolean, element = null) {
    const config: any = {
      width: '540px',
      data: {
        protocol: null,
        edit: false,
        retroactive: false,
        scan: this.selectedLogs === 3 ? true : false
      }
    };

    if (isEdit) {
      config.data.edit = true;
      element.retroactive = false;
      const protocol = {
        ...element,
        accounts: [...element.accounts.map(account => ({accountId: account.accountId, gid: account.gid, locations: account.locations}))]
      }
      config.data.protocol = protocol;
    }

    const modal = this.dialog.open(ModalCreateProtocolComponent, {
      width: '680px',
      data: config.data
    });

    modal.disableClose = true;

    const sub = this._subscribeSafe(modal.componentInstance.completeAll,
     complete => {
        if (complete === SAVE_COMPLETE_SCAN || complete === SAVE_COMPLETE_EDIT_SCAN ) {
          if (this.selectedLogs !== 3) this.selectedLogs = 3
        } else if (complete === SAVE_COMPLETE_PROTOCOL || complete === SAVE_COMPLETE_EDIT_PROTOCOL ) {
          if (this.selectedLogs !== 0) this.selectedLogs = 0
        }
        if (this.protocolScan) this.protocolScan.handleReload();
        this.protocolList.handleReload();
        sub.unsubscribe();
      });

  }

  countLocations(element: any) {
    return this.reportS.countLocation(element);
  }

  tabChange($event) {
    this.selectedLogs = $event.index;
    switch (this.selectedLogs) {
      case 0:
        this.action_button_review = "New Protocol";
        this.show_action_button_review = true;
        this.protocolList.selectedLogs = this.selectedLogs;
        this.protocolList.handleReload();
        break;
      case 2:
        this.show_action_button_review = false;
        this.protocolList.selectedLogs = this.selectedLogs;
        break;
      case 3:
        this.show_action_button_review = true;
        this.action_button_review = "New Scan";
        this.protocolScan.handleReload();
        break;
      default:
        this.action_button_review = "New Protocol";
        this.show_action_button_review = true;
        this.protocolList.selectedLogs = this.selectedLogs;
        break;
    }
  }

}

@Pipe({
  name: 'limitTo'
})
export class TruncatePipe {
  transform(value: string, args: string): string {
    const limit = args ? parseInt(args, 10) : 10;
    const trail = '...';
    if (value?.length){
      return value.length > limit ? value.substring(0, limit) + trail : value;
    } else {
      return value
    }
  }
}

//deprecated

// custom labels for pagination
// export class CustomPaginatorLocalPosts extends MatPaginatorIntl {
//   constructor() {
//     super();
//     this.itemsPerPageLabel = 'Items per page';
//   }

//   // change range label
//   getRangeLabel = (page: number, pageSize: number, length: number) => {
//     if (length === 0 || pageSize === 0) {
//       return `0 / ${length}`;
//     }
//     length = Math.max(length, 0);
//     const startIndex = page * pageSize;
//     const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
//     return `${startIndex + 1} - ${endIndex} of ${length} items`;
//   };
// }
