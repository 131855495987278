// app
import { SearchKeywordsComponent as oldSearchKeywordsComponent } from 'src/app/modules/search-keywords/search-keywords-report/search-keywords.component';
import { SearchKeywordsComponent as newSearchKeywordsComponent } from 'src/app/modules/reporting/keywords/search-keywords-wrapper/search-keywords/search-keywords.component';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
var FeatureFlagResolver = /** @class */ (function () {
    function FeatureFlagResolver(_sessionS) {
        this._sessionS = _sessionS;
    }
    FeatureFlagResolver.prototype.resolve = function (route, state) {
        // return oldSearchKeywordsComponent; // for testing purposes
        return (this._sessionS.getSession().features.userFeatures.updatedDatePicker ?
            newSearchKeywordsComponent :
            oldSearchKeywordsComponent);
    };
    FeatureFlagResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagResolver_Factory() { return new FeatureFlagResolver(i0.ɵɵinject(i1.SessionService)); }, token: FeatureFlagResolver, providedIn: "root" });
    return FeatureFlagResolver;
}());
export { FeatureFlagResolver };
