
<ng-container *ngIf="googleAccounts?.length > 0 && (locations$|async).length == 0">
  <div class="dialog__header txt--center">
    <h2 class="m--0 txt--xl"><strong>Account</strong></h2>
    <p class ="txt--lg m--0">Select an account</p>
  </div>

  <div class="dialog__content" mat-dialog-content>
    <div class="dialog-row border-bottom m--0" *ngFor="let googleAccount of googleAccounts;">
      <mat-radio-group [(ngModel)]="selectedAccount">
        <mat-radio-button [value]="googleAccount">
          <div class="checkbox__content">
            <h3 class="d-block"><strong>{{ googleAccount.accountName}} </strong></h3>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button tabIndex="-1" 
      color="accent" 
      (click)="selectAccount()" 
      class="btn btn--lg btn--txt--lg"
      [disabled]="!selectedAccount"
    >
      Choose Account
    </button>
  </div>
</ng-container>

<ng-container *ngIf="(locations$|async).length > 0">
  <div class="dialog__header txt--center">
    <h2 mat-dialog-title class="m--0 txt--xl">Select the location you would like to grade.</h2>
  </div>

  <div class="dialog__content" mat-dialog-content>
    <div *ngIf="(locations$|async)[0]">
      <div class="dialog-row border-bottom txt--left" *ngFor="let location of (locations$|async)">
        <mat-radio-group *ngIf="location" 
          [(ngModel)]="selectedLocation"
        >
          <mat-radio-button [value]="location">
            <div class="checkbox__content">
              <div class="d-flex">
                <label><b>{{ location?.locationName }}</b></label>
                <div class="ml--15 review-response review-response--xs">
                  <p class="m--0 {{location?.locationState?.txt}} txt--uppercase" *ngIf="location?.locationState?.description"><strong>{{ location?.locationState?.description }} Damn</strong></p>
                </div>
              </div>
              <p class="txt--lightgray m--0">
                <gmb-address 
                  *ngIf="location?.address || location?.serviceArea"
                  [address]="location?.address ? location?.address : null"
                  [serviceAreas]="location?.serviceArea?.places?.placeInfos">
                </gmb-address>
              </p>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <p class ="txt--lg m--0" 
      *ngIf="!(locations$|async)[0]"
    >
      Sorry, there does not appear to be a location associated with your Google account.
    </p>
  </div>

  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel" (click)="backAccount()">Go Back</button>
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel" (click)="backAccount()">Cancel</button>
    </span>
    <button mat-flat-button 
      tabIndex="-1" 
      color="accent" 
      (click)="selectLocation()" 
      class="btn btn--lg btn--txt--lg"
      [disabled]="!selectedLocation"
    >
      Grade Location
    </button>
  </div>
</ng-container>
