// dep
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import firebase from 'firebase/app';

// app
import { GoogleAccountService } from '../../services/google-account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleService } from '../../services/google.service';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { LocationService } from '../../services/location.service';
import { AccountLocSelectorComponent } from '../account-loc-selector/account-loc-selector.component';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import { LogGrade, LogsService } from 'src/app/services/logs.service';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
import LocationObject, { locationNameToRef, LocationRef } from '../../constants/firestore/location-object';
import User from 'src/app/constants/firestore/user';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
import GradeLead from 'src/app/constants/firestore/grade-leads';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-external-grade',
  templateUrl: './external-grade.component.html',
  styleUrls:  ['./external-grade.component.scss']
})
export class ExternalGradeComponent extends BaseComponent {
  userExternal : User
  public location: LocationRef

  /**
   * Authorization against Google was completed and grade was
   * saved, can show it. 
   */
  grade = false;
  locationId: string;
  accountId: string;
  loading = false;
  addAccountDialog;
  showGradePopup = true;

  domain$  = this._sessionS.domain$;
  session$ = this._sessionS.session$;

  constructor(
    public dialog: MatDialog,
    private snack: SnackbarService,
    private googleS: GoogleService,
    private googleAccountS: GoogleAccountService,
    private accountService: AccountService,
    private locationService: LocationService,
    private gradeLeadService: GradeLeadService,
    private _authS: AuthService,
    private titleService: Title,
    private logService: LogsService,
    private externalGradeService: ExternalGradeService,
    private _sessionS : SessionService,
    public navigationS : NavigationService
  ) {
    super();
    this.titleService.setTitle('Grader | ' + this._sessionS.getDomain().branding.company_name);

    this._subscribeSafe(this._sessionS.session$, s => {
      if(s.sessionType !== 'EXTERNAL_GRADER') {
        console.debug('Logging out an non-EXTERNAL_GRADER session');
        this._authS.signOut(false);
      }
    })

  }

  // async ngOnInit() {
    // localStorage.setItem(STORAGE_EXTERNAL_GRADE_ID, this.externalGradeId);

    // Unfinished code path. It seems this was intended to open the
    // grade without requiring the user to select the account/location,
    // using a previously saved selection
    // const gradeLeadJSON = localStorage.getItem(STORAGE_GRADE_LEAD)
    // if (gradeLeadJSON && this._auth.session) 
    //   await this._loadGradeLead(JSON.parse(gradeLeadJSON)); 
  // }

  // ngOnDestroy() : void {
  //   localStorage.removeItem(STORAGE_GRADE_LEAD);
  //   super.ngOnDestroy();
  // }

  /**
   * User clicks on "Start Grading"
   */
  async openAuth() : Promise<void> {
    const externalGradeId = `${Date.now()}${Math.round(Math.random() * 1000)}`;

    this.loading = true;
    await this._authS.signInExternalGradeUser(externalGradeId);
    this.loading = false;
    this._subscribeToGoogleResponse();
    this.snack.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`, 5000);
    const {uid,gid} = this._sessionS.getSession().user;
    await this.googleAccountS.openPopupGoogleOAuth(gid, uid, externalGradeId);
  }

  private async _handleSelected($event: any) : Promise<void> {
    try {
      //const gid = this._auth.externalSession.gid;
      const { gid, externalGradeId } = this._sessionS.getSession('EXTERNAL_GRADER'); 

      const location : LocationObject = $event.location;
      const account  = $event.account;
      this.accountId  = locationNameToRef(location).accountId;
      this.locationId = locationNameToRef(location).locationId;

      this.location = { locationId: this.locationId, 
                        accountId: this.accountId };
      const googleAuth = await this.externalGradeService.getExternalGradeToken(gid, externalGradeId);

      const accountExists = await this.accountService.get(gid, this.accountId).toPromise();

      if (!accountExists) {
        const accountSave = await this.accountService.save({
          ...account,
          gid,
          accountId: this.accountId,
          googleAuth
        }, true);
        this.accountId = accountSave.accountId;
      }

      const saved = await this.locationService.addNewLocations(this.accountId, [{locationId   : this.locationId,
                                                                                 locationName : location.locationName}]);

      if (saved) {
        const googleUser = await this.googleS.getUserProfile(googleAuth);
        const registrationDomain = (new URL(window.location.href)).host;

        const gradeLead : GradeLead = {
          locationId:   this.locationId,
          locationName: $event.location.locationName,
          accountId:    $event.account.name.split('/')[1],
          accountName:  $event.account.accountName,
          createdAt: firebase.firestore.Timestamp.now(),
          name:  googleUser.name,
          email: googleUser.email,
          registrationDomain,
          address: $event.location.address,
          viewed: false
        };
        const d           = this._sessionS.getDomain();
        const companyName = d.branding.company_name;
        const domain      = d.branding.domain;
        this.titleService.setTitle(`Grade | ${gradeLead.locationName} | ${companyName}`)

        const metaData: LogGrade = {
          accountId: this.accountId,
          locationId: this.locationId,
          locationName: gradeLead.locationName,
          email: googleUser.email,
          displayName: googleUser.name
        };

        await this.logService.saveLog({
          create_at: new Date(),
          domain: domain,
          gid,
          message: `A new public grade was created for ${gradeLead.locationName}`,
          type_log: 'Grade',
          meta_data: metaData
        });

        this.gradeLeadService.save(gradeLead);
        this.grade = true;
      } 
    } catch (e) {
      console.error(e);
      this.snack.openError('There was a problem accessing your account. Please try again later.');
    } finally {
      this.loading = false;
    }
  }

  // TODO: Unfinished code-path, see STORAGE_GRADE_LEAD comments. Remove.
  // private async _loadGradeLead(gradeLead : GradeLead) : Promise<void> {
  //   this.accountId = gradeLead.accountId;
  //   this.loading = true;
  //   this.userExternal = await this._auth.signInExternalGradeUser();
  //   this.loading = false;
  //   this.locationId = gradeLead.locationId;
  //   this.location = { locationId: this.locationId, 
  //                      accountId: this.accountId };
  //   this.grade = true;
  //
  //   const {company_name} = this._sessionS.getDomain().branding;
  //   this.titleService.setTitle(`Grader | ${gradeLead.locationName} | ${company_name}`)
  // }

  closePopup(event: any) : void {
    event.preventDefault();
    this.showGradePopup = false;
  }

  private _subscribeToGoogleResponse() : void {
    const sub = this._subscribeSafe(this.googleAccountS.accounts$,
     accounts => {
      if (!_.isNil(accounts) && !_.isEmpty(accounts) && !this.addAccountDialog) {
        this.addAccountDialog = this.dialog.open(AccountLocSelectorComponent, {
          width: '680px',
          data: { accounts, 
                  user: this._sessionS.getSession().user },
          autoFocus: false
        });

        this.addAccountDialog.backdropClick().subscribe(() => {
          this.addAccountDialog = null;
          this.dialog.closeAll();
        });

        this.addAccountDialog.afterClosed()
          .subscribe(response => {
            sub.unsubscribe();
            this.googleAccountS.resetAccounts();
            this.addAccountDialog = null;
            this.dialog.closeAll();
            if (response) {
              this.loading = true;
              this._handleSelected(response);
            }
          });
      }
    });
  }

}
