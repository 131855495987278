// dep
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http'
import {AngularFirestore} from "@angular/fire/firestore";

// app
import {environment as ENV} from "@environment";
import {DOMAINS} from "../constants/firestore/collections";
import { HEADERS_NO_AUTH } from '../constants/auth';
import { Domain } from '../constants/firestore/domain';
import { BROWSER_DOMAIN } from '../helpers/utils.helpers'

@Injectable({
  providedIn: 'root'
})

/**
 * This service fetchs the Domain info
 */
export class DomainService {
  constructor(
    private _afs  : AngularFirestore,
    private _http : HttpClient,
    ) {}  

  private async _fetchDomainFromFirestore(xdomain : string) : Promise<Domain> {
    return (await this._afs.collection(DOMAINS).doc(xdomain).get().toPromise()).data() as Domain
  }

  async fetchCurrentDomain() : Promise<Domain> {
    // TODO: Should be coalesced to the coreApi endpoint when ditching Firestore

    // Fetch from Backend->Mongo
    const domainP = this._http.get<any>(`${ENV.coreApiUrl}/domains/${BROWSER_DOMAIN.domainName}`, HEADERS_NO_AUTH).toPromise()

    // Fetch from Firestore (TODO: Remove)
    const domainFs = await this._fetchDomainFromFirestore(BROWSER_DOMAIN.xDomainName)

    let domain = { trialDays : 30 }
    try {
      domain = (await domainP).data
    } catch(e) {
      // Can fail because not all domains are yet in Mongo, pass
      // Remove this try/catch after they are migrated from FS
    }

    return {...domainFs,
            ...domain }
  }


  // TODO: Unused, remove
  // get apiUrlForGoogle() : string {
  //   const url = environment.apiUrl
  //   return url.endsWith('/api') ? url.substring(0, url.length - 4) : url
  // }

 /**
   * Returns the public key used to create add-credit-card tokens configured
   * for the current domain for the Development environment (Stripe test mode)
   */
  async getStripeTestMaplabsPublicKey() : Promise<string> {
    return (await this._fetchDomainFromFirestore('localhost')).stripe_keys.public_key
  }

  // TODO: Replace usages of those with usages thru SessionService
  /**
   * Returns the public key used to create add-credit-card tokens configured
   * for the current domain.
   */
  async getStripePublicKey() : Promise<string> {
    // Always uses the maplabs PK, no matter the WL domain, because billing-api 
    // runs with the maplabs Stripe key, not the one with the WL Stripe (Connect)
    // Accounts.
    return (await this._fetchDomainFromFirestore('app_maplabs_com')).stripe_keys.public_key
  }

  async getStripeConnectAccountId() : Promise<string | undefined> {
    return (await this.fetchCurrentDomain())?.stripeConnect?.stripeAccountId
  }

}
