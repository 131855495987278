// dep
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

// app
import { UserFeatures } from '../constants/user-features'
import { SessionService } from '../services/session.service';


@Directive({
  selector: '[appUserFeature]'
})
export class UserFeatureDirective implements OnInit {
  @Input() appUserFeature: keyof UserFeatures['userFeatures'];

  public userFeatures   : UserFeatures['userFeatures'];
  public generalFeatures: UserFeatures["generalFeatures"];
  public newFeature: boolean;

  constructor(
    private _sessionS : SessionService,
    private _element: ElementRef
  ) { }

  ngOnInit(): void {
    this._buildFeature();    
  }

  async _buildFeature(): Promise<void> {
    const f = (await this._sessionS.waitSession()).features

    this.userFeatures    = f?.userFeatures;
    this.generalFeatures = f?.generalFeatures;
    this.newFeature      = f?.generalFeatures?.isNewFeature;

    const userFeature    = this.userFeatures?.[this.appUserFeature];
    const generalFeature = this.generalFeatures?.[this.appUserFeature];
    const newFeature     = this.newFeature?.[this.appUserFeature];

    if (generalFeature || userFeature) {
      const htmlEl = this._element.nativeElement; 
      htmlEl.classList.remove('d-none'); // Show the element

      if (newFeature || !generalFeature) {
        const itemsToAddBeta = htmlEl.getElementsByClassName('title-dark-launch');
        const message = newFeature ? 'New' : 'Beta'
        
        Object.keys(itemsToAddBeta).forEach(el => {
          itemsToAddBeta[el].innerHTML += `<span class="beta-mesage">${message}</span>`
        })
      }
    }
  }
}
