// dep
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

// app
import { Router } from '@angular/router'
import { SessionService } from './session.service'

@Injectable({
  providedIn: 'root'
})
export class BulkActionsGuardService implements CanActivate {
  
  constructor(
    private _sessionS : SessionService,
    private _router: Router
  ) {
  }

  async canActivate(_route: ActivatedRouteSnapshot) : Promise<boolean> {
    if (!(await this._sessionS.waitSession()).bulkActionsEnabled) {
      console.log('BulkActionsGuardService#canActivate: bulk actions not enabled')
      this._router.navigate(['/settings/billing'])
      return false;
    } else {
      console.log('BulkActionsGuardService#canActivate: bulk actions enabled')
      return true;
    }
  }
}