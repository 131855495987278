import { __extends, __read, __spread, __values } from "tslib";
// dep
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
// app
import { InsightsService } from '../../services/insights.service';
import { MULTIKEY_METRIC, MULTIKEY_METRIC_V2_NEW } from '../../constants/google/location-stats';
import { PdfService } from 'src/app/services/pdf.service';
import { SnackbarService } from "../../services/snackbar.service";
import { LoadingService } from 'src/app/services/loading.service';
import { ReportService } from 'src/app/services/report.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var TableMultilocationsComponent = /** @class */ (function (_super) {
    __extends(TableMultilocationsComponent, _super);
    function TableMultilocationsComponent(_loadingService, _detectStrategy, _insightS, _pdfS, _afsStorage, _snack, _reportS, _sessionS) {
        var _this = _super.call(this) || this;
        _this._loadingService = _loadingService;
        _this._detectStrategy = _detectStrategy;
        _this._insightS = _insightS;
        _this._pdfS = _pdfS;
        _this._afsStorage = _afsStorage;
        _this._snack = _snack;
        _this._reportS = _reportS;
        _this._sessionS = _sessionS;
        _this.disableIcons = false;
        _this.reportType = '';
        _this.reportName = '';
        _this.progress = true;
        _this.isEmpty = true;
        _this.showScrollbar = false;
        _this.isOverflowY = false;
        _this.isProgressCSV = false;
        _this.viewModeChecked = 'legacy';
        _this._pageable = { size: 10, page: 1 };
        return _this;
    }
    TableMultilocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.refresh) {
            this._subscribeSafe(this.refresh, function (result) {
                _this.getData(_this._pageable);
            });
        }
        this.getData(this._pageable);
    };
    TableMultilocationsComponent.prototype.ngOnChanges = function (changes) {
        var _a, _b, _c, _d;
        if ((_a = changes.dataPicker) === null || _a === void 0 ? void 0 : _a.currentValue) {
            this.dataPicker = (_b = changes.dataPicker) === null || _b === void 0 ? void 0 : _b.currentValue;
        }
        if ((_c = changes.externalLocations) === null || _c === void 0 ? void 0 : _c.currentValue) {
            this.externalLocations = (_d = changes.externalLocations) === null || _d === void 0 ? void 0 : _d.currentValue;
        }
        this.getData(this._pageable);
    };
    TableMultilocationsComponent.prototype.ngAfterViewInit = function () {
        this.manualPage = 1;
        this.errorMessage = false;
        this._detectStrategy.detectChanges();
    };
    TableMultilocationsComponent.prototype.getData = function ($event) {
        var _this = this;
        this.viewModeChecked = this.reportType.includes('performance') ? 'new' : 'legacy';
        if (this.externalLocations) {
            this.progress = true;
            if ($event) {
                this._pageable = $event;
            }
            var sort = void 0;
            if (this.sort && this.sort.sortBy) {
                sort = this.sort;
            }
            var metricsList = this.viewModeChecked == 'new' ? MULTIKEY_METRIC_V2_NEW : MULTIKEY_METRIC;
            var metrics_1 = [];
            this.displayedMetricColumns = {};
            metricsList.forEach(function (metric) {
                metrics_1.push({ id: _.camelCase(metric.name), name: metric.name });
            });
            if (this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison') {
                metrics_1 = __spread([{ id: 'location', name: 'Location' }], metrics_1);
            }
            else {
                metrics_1 = __spread([{ id: 'location', name: 'Location' }], _.reject(metrics_1, { 'id': 'averageStarRating' }));
            }
            metrics_1.forEach(function (metric) {
                _this.displayedMetricColumns[metric.id] = metric.name;
            });
            this.displayedColumns = metrics_1.map(function (res) { return res.id; });
            this.dataSource = new MatTableDataSource([]);
            if (this.viewModeChecked == 'new') {
                this._reportS.getTableData(this._sessionS.getSession().gid, this.reportType, this._pageable, this.sort, this.dataPicker)
                    .subscribe(function (result) {
                    var _a;
                    var data = (_a = result) === null || _a === void 0 ? void 0 : _a.data;
                    _this.pagination = {
                        items: data['items'],
                        page: data['page'],
                        pages: data['totalPages'],
                        per_page: data['pageSize'],
                        total: data['total'],
                        hasNext: data['hasNext'],
                        hasPrev: data['hasPrev']
                    };
                    _this._loadingService.reportAdvanced(1, 'Insights Resume');
                    _this.removeFoodOrdersColumns(data['items']);
                    _this._updateTable(data['items'], _this.displayedColumns);
                    _this.progress = false;
                }, function (err) {
                    _this.progress = false;
                });
            }
            else {
                if (!sort) {
                    sort = {
                        direction: 'ACTIONS_DRIVING_DIRECTIONS',
                        sortBy: 'asc'
                    };
                }
                this._insightS.resume(this.externalLocations, this._pageable, this.dataPicker, sort)
                    .subscribe(function (result) {
                    result.items = result.items.filter(function (r) { return 'locationName' in r; });
                    _this.progress = false;
                    _this.pagination = result;
                    _this._loadingService.reportAdvanced(1, 'Insights Resume');
                    _this._updateTable(result.items, _this.displayedColumns);
                }, function (err) {
                    _this.progress = false;
                });
            }
        }
        this.showScrollbar = !this.checkScrollbar();
    };
    TableMultilocationsComponent.prototype.removeFoodOrdersColumns = function (items) {
        var e_1, _a;
        var _b;
        var propertiesToRemove = ['BOOKINGS', 'CONVERSATION', 'FOOD_MENU_CLICKS', 'FOOD_ORDERS'];
        var _loop_1 = function (prop) {
            if (!((_b = items[0]) === null || _b === void 0 ? void 0 : _b.hasOwnProperty(prop))) {
                var field = prop == 'CONVERSATION' ? 'messages' : prop == 'FOOD_MENU_CLICKS' ? 'menu_views' : prop;
                var formattedProp_1 = _.camelCase(field);
                var index = this_1.displayedColumns.findIndex(function (el) { return el == formattedProp_1; });
                if (index > -1) {
                    this_1.displayedColumns.splice(index, 1);
                }
                delete this_1.displayedMetricColumns[formattedProp_1];
            }
        };
        var this_1 = this;
        try {
            for (var propertiesToRemove_1 = __values(propertiesToRemove), propertiesToRemove_1_1 = propertiesToRemove_1.next(); !propertiesToRemove_1_1.done; propertiesToRemove_1_1 = propertiesToRemove_1.next()) {
                var prop = propertiesToRemove_1_1.value;
                _loop_1(prop);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (propertiesToRemove_1_1 && !propertiesToRemove_1_1.done && (_a = propertiesToRemove_1.return)) _a.call(propertiesToRemove_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TableMultilocationsComponent.prototype.locationUrl = function (accountId, locationId, destiny) {
        return "/account/" + accountId + "/location/" + locationId + "/" + destiny;
    };
    TableMultilocationsComponent.prototype.checkScrollbar = function () {
        var scrollableElem = document.createElement('div');
        var innerElem = document.createElement('div');
        scrollableElem.style.width = '30px';
        scrollableElem.style.height = '30px';
        scrollableElem.style.overflow = 'scroll';
        scrollableElem.style.borderWidth = '0';
        innerElem.style.width = '30px';
        innerElem.style.height = '60px';
        scrollableElem.appendChild(innerElem);
        document.body.appendChild(scrollableElem);
        var diff = scrollableElem.offsetWidth - scrollableElem.clientWidth;
        document.body.removeChild(scrollableElem);
        return diff > 0;
    };
    // when table is scrolled right via button click
    TableMultilocationsComponent.prototype.moveRight = function () {
        this._scrollTable('right');
    };
    // when table is scrolled left via button click
    TableMultilocationsComponent.prototype.moveLeft = function () {
        this._scrollTable('left');
    };
    // when table is scrolled via the scrollbar
    TableMultilocationsComponent.prototype.scrollTable = function () {
        this._scrollTable('');
    };
    TableMultilocationsComponent.prototype.isOverflownVertically = function (element) {
        if (element && element.length > 0) {
            var scroll_1 = element[0].scrollHeight || 0;
            var client = element[0].clientHeight || 0;
            return scroll_1 > client;
        }
        return false;
    };
    TableMultilocationsComponent.prototype.handleReload = function ($event) {
        this.getData($event);
    };
    TableMultilocationsComponent.prototype.exportPDF = function () {
        this._pdfS.exportTableToPDF('#table-location-container', 'Multilocations-PDF', 'Multi Locations Table');
    };
    TableMultilocationsComponent.prototype.exportCSV = function () {
        var _this = this;
        this.isProgressCSV = true;
        this._reportS.resumeExportCsv(this._sessionS.getSession().gid, this.reportType, this.sort, this.dataPicker, this.reportName)
            .pipe(switchMap(function (result) { return _this._afsStorage.ref(result['data']).getDownloadURL(); }))
            .subscribe(function (filename) {
            _this._insightS.openDownloadInNewTab(filename);
            _this.isProgressCSV = false;
            _this._snack.openSuccess("The file exported successfully");
        }, function (err) {
            _this.isProgressCSV = false;
            console.error(err);
            _this._snack.openError("The file export failed. Please try again later.");
        });
    };
    TableMultilocationsComponent.prototype.sortBy = function ($event) {
        this.sort = {
            sortBy: $event.active,
            direction: $event.direction,
        };
        if ($event.direction === '')
            this.sort = null;
        this.handleReload({ page: 1, size: this.pagination.per_page || 10 });
    };
    TableMultilocationsComponent.prototype.resetSort = function () {
        this.sort = null;
        this.getData();
    };
    TableMultilocationsComponent.prototype._updateTable = function (resume, columns) {
        // data for table
        this.dataSource = new MatTableDataSource(resume);
        this.isEmpty = !(this.dataSource.data.length);
        if (!this.isEmpty) {
            var table = document.getElementsByClassName('js-table-scroll');
            this.isOverflowY = this.isOverflownVertically(table);
        }
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
    };
    // check visibility of the left and right scroll buttons while scrolling
    TableMultilocationsComponent.prototype._checkButtonsOnScroll = function (table, btnLeft, btnRight) {
        btnLeft.style.display = (table.scrollLeft > 0) ? 'block' : 'none';
        btnRight.style.display = (table.scrollLeft < (table.scrollWidth - table.clientWidth)) ? 'block' : 'none';
    };
    TableMultilocationsComponent.prototype._scrollTable = function (direction) {
        var table = document.getElementsByClassName('js-table-scroll')[0];
        var btnLeft = document.getElementsByClassName('js-table-scroller-left');
        var btnRight = document.getElementsByClassName('js-table-scroller-right');
        if (direction === 'left') {
            this._scrollLeft(table, btnLeft[0], btnRight[0]);
        }
        else if (direction === 'right') {
            this._scrollRight(table, btnLeft[0], btnRight[0]);
        }
        else {
            this._checkButtonsOnScroll(table, btnLeft[0], btnRight[0]);
        }
    };
    // scroll the table left
    TableMultilocationsComponent.prototype._scrollLeft = function (table, btnLeft, btnRight) {
        table.scrollLeft -= 150;
        this._checkButtonsOnScroll(table, btnLeft, btnRight);
    };
    // scroll the table right
    TableMultilocationsComponent.prototype._scrollRight = function (table, btnLeft, btnRight) {
        table.scrollLeft += 150;
        this._checkButtonsOnScroll(table, btnLeft, btnRight);
    };
    return TableMultilocationsComponent;
}(BaseComponent));
export { TableMultilocationsComponent };
