import { __assign, __awaiter, __generator, __read, __spread, __values } from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';
// app
import { ACCOUNTS, GROUPS, LOCATIONS, POST_MANAGEMENT_GROUP, REPORTS, PROTOCOLS, WIDGET_INFO } from '../constants/firestore/collections';
import { Queue } from '../constants/firestore/enqueue';
import { WEEK_DAYS } from '../constants/google/week-days';
import { HoursAmPmPipe } from '../pipes/hours-am-pm.pipe';
import { InsightsService } from './insights.service';
import { ReviewsService } from './reviews.service';
import { PostService } from './post.service';
import { ObservationService } from './observation.service';
import { GoogleService } from './google.service';
import { DatesService } from "../services/dates.service";
import { environment as ENV } from '@environment';
import { SessionService } from './session.service';
import { NotificationService } from './notification.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from '../constants/notifications';
import { Messages, string_message } from '../constants/messages';
import { AccountService } from './account.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/common/http";
import * as i4 from "./google.service";
import * as i5 from "./reviews.service";
import * as i6 from "./insights.service";
import * as i7 from "./post.service";
import * as i8 from "./observation.service";
import * as i9 from "./dates.service";
import * as i10 from "./notification.service";
import * as i11 from "./account.service";
var LocationService = /** @class */ (function () {
    function LocationService(_sessionS, _afs, _http, _googleS, _reviewsService, _insightsService, _postService, _observationS, _dateS, _notificationS, _accountS) {
        this._sessionS = _sessionS;
        this._afs = _afs;
        this._http = _http;
        this._googleS = _googleS;
        this._reviewsService = _reviewsService;
        this._insightsService = _insightsService;
        this._postService = _postService;
        this._observationS = _observationS;
        this._dateS = _dateS;
        this._notificationS = _notificationS;
        this._accountS = _accountS;
        this.loading = false; // TODO: Really used?
        this._locations = new BehaviorSubject([]);
        this._location = new BehaviorSubject(null);
        this._paginate = new BehaviorSubject({ size: 10, page: 1 });
        this.location$ = this._location.asObservable();
        this.accountAllLocations$ = this._locations.asObservable();
        /**
         * Will trigger when some location changed (e.g. by an upgrade), to signal
         * that locations should be refreshed.
         */
        this.someLocationChanged$ = new BehaviorSubject(null);
    }
    LocationService.prototype.getRegionCode = function (result) {
        var _a, _b, _c, _d, _e, _f;
        return (((_c = (_b = (_a = result) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.regionCode) || ((_f = (_e = (_d = result) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.serviceArea) === null || _f === void 0 ? void 0 : _f.regionCode) ||
            navigator.language.split('-')[1]);
    };
    LocationService.prototype.fetchAccountLocationsPaginated = function (gid, accountId, pageable, locationIds) {
        // deprecated
        //
        // const params = new HttpParams()
        //   .set('page', pageable.page.toString())
        //   .set('pageSize', pageable.size.toString())
        //   .set('locationIds', locationIds.join(','));
        // return this._http.get(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, { params });
        var body = {
            page: pageable.page,
            pageSize: pageable.size,
            locationIds: locationIds
        };
        return this._http.post(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/all", body).toPromise();
        // return this._http.get(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, { params }).toPromise(); 
    };
    LocationService.prototype.getLocationsIdsByStringQuery = function (gid, queryString) {
        return this._http.post(ENV.apiUrl + "/v2/search/gid/" + gid + "/account-locations?query=" + queryString, { 'query': queryString }).toPromise();
    };
    // TODO: Optimize a lot of fetchLocations callers that doesn't need the entire location, 
    // should add the specific fields they need and a singature here like fetchMultipleLocations
    LocationService.prototype.fetchLocation = function (gid, accountId, locationId) {
        return this.getRef(gid, accountId, locationId).toPromise();
    };
    // TODO: move all .getRef(...).toPromise() calls to .fetchLocation(), also change multiple calls to getRef 
    // to a single getMultipleLocations
    LocationService.prototype.getRef = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId);
    };
    /**
     * Fetchs all gid locations or only a subset of them (if locationRefs is specified)
     * If fields is specified, only those fields will be fetched, if not, all fields should be.
     */
    LocationService.prototype.fetchMultipleLocations = function (gid, locationRefs, fields) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/locations/by-gid/" + gid +
                            (fields ? '?fields=' + fields.join(',') : ''), (locationRefs ? { 'locations': locationRefs } : undefined)).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getPendingMask = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId + "/pendingMask").toPromise();
    };
    LocationService.prototype.getObservations = function (gid, accountId, locationId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchLocation(gid, accountId, locationId)];
                    case 1: return [2 /*return*/, (_a.sent()).googleObservations || null];
                }
            });
        });
    };
    LocationService.prototype.isLocked = function (locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + locationId + "/locked");
    };
    // reset(): void {
    //   // TODO: Reassignation instead of .next([])/.next(null) ? Why?
    //   // A (wrong, leaking) way to "desubscribe" previous subscribers? as
    //   // observables are accessed thru the locations() and location() getters
    //   this._locations = new BehaviorSubject([]);
    //   this._location  = new BehaviorSubject(null);
    // }
    LocationService.prototype.reset = function () {
        this._locations.next([]);
        this._location.next(null);
    };
    LocationService.prototype.fetchAccountLocationsAndEmit = function (gid, accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var locations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.fetchAccountLocations(gid, accountId)];
                    case 1:
                        locations = _a.sent();
                        this._locations.next(locations);
                        return [3 /*break*/, 3];
                    case 2:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.fetchLocationsExistence = function (locations) {
        return this._http.post(ENV.apiUrl + "/v2/locations/byIds", locations).toPromise();
    };
    LocationService.prototype.getPaginate = function () {
        return this._paginate.asObservable();
    };
    LocationService.prototype.setPaginate = function (paginate) {
        return this._paginate.next(paginate);
    };
    LocationService.prototype.get = function (gid, accountId, locationId) {
        return __awaiter(this, void 0, void 0, function () {
            var loc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.fetchLocation(gid, accountId, locationId)];
                    case 1:
                        loc = _a.sent();
                        this._location.next(loc);
                        return [3 /*break*/, 3];
                    case 2:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.addNewLocations = function (accountId, locations /*, gidExternalGrade? : string*/) {
        return __awaiter(this, void 0, void 0, function () {
            var r, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, Promise.all(locations.map(function (loc) { return _this._addNewLocation(accountId, loc.locationId, loc.locationName /*, gidExternalGrade*/); }))];
                    case 1:
                        r = _a.sent();
                        return [2 /*return*/, r.every(function (rr) { return !!rr; })];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [2 /*return*/, false];
                    case 3:
                        this.notifySomeLocationChanged();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.notifySomeLocationChanged = function () {
        this.someLocationChanged$.next(null);
    };
    /**
     * Deletes a Location and references to it.
     * If the account that contains the location has no more locations, the account is also deleted.
     * TODO: Migrate to backend, all of this should be done on a backend endpoint that
     *       who must also accept multiple locations.
     * @return true if the account was also deleted, false if not.
     */
    LocationService.prototype.deleteLocation = function (gid, accountId, loc, opts) {
        if (opts === void 0) { opts = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var locationId, locationAddress, meta, accountLocations, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        locationId = loc.locationId;
                        return [4 /*yield*/, Promise.all([this._deleteReferencesToLocation(gid, locationId),
                                this._http.delete(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId).toPromise()])];
                    case 1:
                        _a.sent();
                        locationAddress = this.formatAddress(loc.location.address);
                        meta = this._notificationS.getMetaTypeLog(TYPE_LOG_LOCATION, __assign({ accountId: accountId, address: locationAddress }, loc));
                        return [4 /*yield*/, this._notificationS.saveNotification(string_message(Messages.notifications.LOCATION_TOGGLE, [loc.locationName, locationAddress, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta)];
                    case 2:
                        _a.sent();
                        if (!(!('deleteEmptyAccount' in opts) || opts.deleteEmptyAccount)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.fetchAccountLocations(gid, accountId)];
                    case 3:
                        accountLocations = _a.sent();
                        if (!!accountLocations) return [3 /*break*/, 7];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this._accountS.delete(gid, accountId)];
                    case 5:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 6:
                        err_2 = _a.sent();
                        console.error("Error deleting Account after deleting locations: gid=" + gid + " accountId=" + accountId, err_2);
                        return [3 /*break*/, 7];
                    case 7:
                        if (!('notifyChange' in opts) || opts.notifyChange)
                            this.notifySomeLocationChanged();
                        return [2 /*return*/, false];
                }
            });
        });
    };
    LocationService.prototype.deleteReportLocation = function (locationsToRemove, gid, reportId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var reportRef, reportData, accountIds, notDuplicatedAccountIds, accountsCopy, _loop_1, notDuplicatedAccountIds_1, notDuplicatedAccountIds_1_1, accountId;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        reportRef = this._afs.collection(GROUPS).doc(gid).collection(REPORTS).doc(reportId);
                        return [4 /*yield*/, reportRef.get().toPromise()];
                    case 1:
                        reportData = (_c.sent()).data();
                        accountIds = locationsToRemove.map(function (loc) { return loc.accountId; });
                        notDuplicatedAccountIds = __spread(new Set(accountIds));
                        accountsCopy = __spread((_a = reportData) === null || _a === void 0 ? void 0 : _a.accounts);
                        _loop_1 = function (accountId) {
                            var e_2, _a;
                            var reportAccount = accountsCopy.find(function (account) { return account.accountId == accountId; });
                            var reportAccountIndex = accountsCopy.indexOf(reportAccount);
                            var locationsAccount = locationsToRemove.filter(function (loc) { return loc.accountId == accountId; });
                            var locationsIdRemove = locationsAccount.map(function (loc) { return loc.locationId; });
                            var newReportLocations = [];
                            try {
                                for (var _b = (e_2 = void 0, __values(reportAccount.locations)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var loc = _c.value;
                                    var isLocationForRemove = !locationsIdRemove.includes(loc['locationId']);
                                    if (isLocationForRemove)
                                        newReportLocations.push(loc);
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            if (newReportLocations.length > 0) {
                                accountsCopy[reportAccountIndex].locations = newReportLocations;
                            }
                            else {
                                accountsCopy.splice(reportAccountIndex, 1);
                            }
                        };
                        try {
                            for (notDuplicatedAccountIds_1 = __values(notDuplicatedAccountIds), notDuplicatedAccountIds_1_1 = notDuplicatedAccountIds_1.next(); !notDuplicatedAccountIds_1_1.done; notDuplicatedAccountIds_1_1 = notDuplicatedAccountIds_1.next()) {
                                accountId = notDuplicatedAccountIds_1_1.value;
                                _loop_1(accountId);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (notDuplicatedAccountIds_1_1 && !notDuplicatedAccountIds_1_1.done && (_b = notDuplicatedAccountIds_1.return)) _b.call(notDuplicatedAccountIds_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        reportRef.update({ 'accounts': accountsCopy });
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: This should be done on backend side using a single endpoint. 
    // TODO: accountId is not part of the args
    LocationService.prototype._deleteReferencesToLocation = function (gid, locationId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, _c, coll, ref, items, items_1, items_1_1, item, data, id, newData, e_3_1, e_4_1;
            var e_4, _d, e_3, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 15, 16, 17]);
                        _b = __values([POST_MANAGEMENT_GROUP,
                            REPORTS,
                            PROTOCOLS]), _c = _b.next();
                        _f.label = 1;
                    case 1:
                        if (!!_c.done) return [3 /*break*/, 14];
                        coll = _c.value;
                        ref = this._afs.collection(GROUPS).doc(gid).collection(coll);
                        return [4 /*yield*/, ref.get().toPromise()];
                    case 2:
                        items = (_f.sent()).docs;
                        _f.label = 3;
                    case 3:
                        _f.trys.push([3, 11, 12, 13]);
                        items_1 = (e_3 = void 0, __values(items)), items_1_1 = items_1.next();
                        _f.label = 4;
                    case 4:
                        if (!!items_1_1.done) return [3 /*break*/, 10];
                        item = items_1_1.value;
                        data = item.data();
                        id = item.id;
                        newData = Object.assign({}, data);
                        this._analyzeAccounts(newData, locationId);
                        if (!((_a = newData.accounts) === null || _a === void 0 ? void 0 : _a.length)) return [3 /*break*/, 7];
                        if (!(newData.hasOwnProperty('accounts') && newData.accounts.length !== data.accounts.length)) return [3 /*break*/, 6];
                        return [4 /*yield*/, ref.doc(id).update(newData)];
                    case 5:
                        _f.sent();
                        _f.label = 6;
                    case 6: return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, ref.doc(id).delete()];
                    case 8:
                        _f.sent();
                        _f.label = 9;
                    case 9:
                        items_1_1 = items_1.next();
                        return [3 /*break*/, 4];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_3_1 = _f.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (items_1_1 && !items_1_1.done && (_e = items_1.return)) _e.call(items_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                        return [7 /*endfinally*/];
                    case 13:
                        _c = _b.next();
                        return [3 /*break*/, 1];
                    case 14: return [3 /*break*/, 17];
                    case 15:
                        e_4_1 = _f.sent();
                        e_4 = { error: e_4_1 };
                        return [3 /*break*/, 17];
                    case 16:
                        try {
                            if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype._analyzeAccounts = function (item, locationId) {
        if (item.hasOwnProperty('accounts')) {
            var accounts = item.accounts;
            var newAccounts = accounts.filter(function (account) {
                if (account.hasOwnProperty('locations')) {
                    var locations = account.locations;
                    var newLocations = locations.filter(function (location) { return location.locationId !== locationId; });
                    if (newLocations.length !== locations.length) {
                        account.locations = newLocations;
                    }
                    if (newLocations.length !== 0) {
                        return account;
                    }
                }
            });
            if (newAccounts.length !== accounts.length) {
                item.accounts = newAccounts;
            }
        }
    };
    LocationService.prototype._addNewLocation = function (accountId, locationId, locationName /*, gidExternalGrade? : string*/) {
        return __awaiter(this, void 0, void 0, function () {
            var gid, location, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        location = {
                            locationName: locationName,
                            lockedOn: null,
                            gid: gid,
                            accountId: accountId,
                            locationId: locationId
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/locations/add", location).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.locationRefreshAllDeps(accountId, locationId, gid /*, isExternal*/)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 4:
                        err_3 = _a.sent();
                        console.error("Error adding new location locationId=" + locationId + " accountId=" + accountId, err_3);
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.checkLocation = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/check/gid/" + gid + "/account/" + accountId + "/location/" + locationId);
    };
    LocationService.prototype.checkService = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/google/account/" + accountId + "/location/" + locationId + "/service_list");
    };
    LocationService.prototype.saveInsights = function (accountId, locationId) {
        return this._insightsService.saveInsights(accountId, locationId);
    };
    LocationService.prototype.saveReviews = function (accountId, locationId /*, isExternal = false*/) {
        return this._reviewsService.saveReviews(accountId, locationId /*, isExternal*/);
    };
    LocationService.prototype.saveV3Posts = function (gid, accountId, locationId) {
        return this._postService.saveV3All(gid, accountId, locationId);
    };
    LocationService.prototype.saveObservations = function (accountId, locationId) {
        return this._observationS.save(accountId, locationId);
    };
    LocationService.prototype.saveServices = function (accountId, locationId) {
        return this._googleS.saveServices(accountId, locationId);
    };
    LocationService.prototype.saveMenu = function (accountId, locationId) {
        return this._googleS.saveMenu(accountId, locationId).toPromise();
    };
    LocationService.prototype.update = function (gid, accountId, locationId, data) {
        return this._http.post(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId + "/save", data);
    };
    LocationService.prototype.initLocationEdit = function (gid, accountId, locationId, locationEdit) {
        return this.update(gid, accountId, locationId, { locationEdit: locationEdit });
    };
    LocationService.prototype.organizeServiceList = function (serviceList, categories) {
        var dataSource = categories.map(function (ac) {
            return {
                categoryId: ac.categoryId,
                displayName: ac.displayName,
                principal: ac.primary,
                services: serviceList.reduce(function (result, sl, i) {
                    var _a;
                    if (sl.freeFormServiceItem) {
                        sl.freeFormServiceItem.categoryId = sl.freeFormServiceItem.category;
                        if (sl.freeFormServiceItem.categoryId === ac.categoryId) {
                            result.push({
                                isFreeFormServiceItem: true,
                                positionEdit: i,
                                isOffered: sl.isOffered,
                                serviceTypeId: null,
                                description: sl.freeFormServiceItem.label.description,
                                displayName: sl.freeFormServiceItem.label.displayName,
                                languageCode: sl.freeFormServiceItem.label.displayName,
                                price: sl.price
                            });
                        }
                    }
                    else if (sl.structuredServiceItem) {
                        var serviceType = (_a = ac.serviceTypes) === null || _a === void 0 ? void 0 : _a.find(function (st) { return st.serviceTypeId === sl.structuredServiceItem.serviceTypeId; });
                        if (serviceType) {
                            result.push({
                                isFreeFormServiceItem: false,
                                positionEdit: i,
                                isOffered: sl.isOffered,
                                serviceTypeId: serviceType.serviceTypeId,
                                description: sl.structuredServiceItem.description,
                                displayName: serviceType.displayName,
                                languageCode: null,
                                price: sl.price
                            });
                        }
                    }
                    return result;
                }, [])
            };
        });
        return dataSource;
    };
    LocationService.prototype.convertPriceListType = function (type) {
        type = type.toLowerCase();
        if (type === 'jobs') {
            type = 'Jobs';
        }
        if (type === 'services') {
            type = 'Services';
        }
        if (type === 'menu') {
            type = 'Menu';
        }
        return type;
    };
    LocationService.prototype.getReviewSummary = function (gid, accountsId, locationsIs) {
        var data = {
            "gid": [gid],
            "accountId": accountsId,
            "locationId": locationsIs
        };
        return this._http.post(ENV.apiUrl + "/v2/locations/global-review-summary", data);
    };
    LocationService.prototype.review_summary = function (gid, locations) {
        var _this = this;
        if (!gid) {
            gid = this._sessionS.getSession().gid;
        }
        var normalizeSummary = function (rs) {
            if (!rs || !('googleResume' in rs) || !('difference' in rs))
                // missing or broken location review_summary
                return null;
            if ('totalReviewCount' in rs)
                // New format after MAP-172
                return rs;
            // Convert old format to new format
            return {
                difference: rs.difference,
                googleResume: rs.googleResume,
                answered: rs.googleResume.answered,
                notAnswered: rs.googleResume.notAnswered,
                totalReviewCount: rs.googleResume.answered + rs.googleResume.notAnswered,
                averageRating: rs.googleResume.averageRating
            };
        };
        if (locations.length === 1) {
            return this.getRef(gid, locations[0].accountId, locations[0].locationId)
                .pipe(map(function (location) { var _a; return normalizeSummary((_a = location) === null || _a === void 0 ? void 0 : _a.review_summary); }));
        }
        else if (locations.length > 1) {
            var $resumes_1 = [];
            locations.forEach(function (p) {
                $resumes_1.push(_this.getRef(gid, p.accountId, p.locationId)
                    .pipe(map(function (location) { var _a; return normalizeSummary((_a = location) === null || _a === void 0 ? void 0 : _a.review_summary); })));
            });
            return combineLatest($resumes_1);
        }
    };
    LocationService.prototype.locationRefreshAllDeps = function (accountId, locationId, gid /*, isExternal = false*/) {
        return __awaiter(this, void 0, void 0, function () {
            var e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, (ENV.saveLocationInChain ?
                                this._saveInChain(accountId, locationId) :
                                zip(
                                // TODO: All of this must be collapsed to a single backend endpoint
                                this.checkLocation(gid, accountId, locationId), this.checkService(gid, accountId, locationId), this.saveInsights(accountId, locationId), this.saveReviews(accountId, locationId /*, isExternal*/), this.saveObservations(accountId, locationId), this.saveV3Posts(gid, accountId, locationId))).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        e_5 = _a.sent();
                        console.error("Error refreshing location gid=" + gid + " accountId=" + accountId + " locationId=" + locationId, e_5);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.formatAddress = function (address) {
        if (!address)
            return '';
        return ((address.addressLines !== undefined ? address.addressLines[0] : '--') + " " +
            ((address.locality || '') + ", ") +
            ((address.administrativeArea || '') + " ") +
            ("" + (address.postalCode || '')));
    };
    LocationService.prototype.formatServiceArea = function (serviceArea) {
        var _a, _b;
        if (!serviceArea) {
            return null;
        }
        var placeNames = [];
        (_b = (_a = serviceArea.places) === null || _a === void 0 ? void 0 : _a.placeInfos) === null || _b === void 0 ? void 0 : _b.forEach(function (place) {
            placeNames.push(place.placeName || '--');
        });
        return placeNames.join(' | ');
    };
    LocationService.prototype.verifyOpen = function (periods) {
        var _this = this;
        if (!periods) {
            periods = [];
        }
        periods = JSON.parse(JSON.stringify(periods));
        var periodsResult = [];
        WEEK_DAYS.forEach(function (day) {
            var e_6, _a;
            var currentPeriods = periods.filter(function (period) { return period.openDay === day; });
            try {
                for (var currentPeriods_1 = __values(currentPeriods), currentPeriods_1_1 = currentPeriods_1.next(); !currentPeriods_1_1.done; currentPeriods_1_1 = currentPeriods_1.next()) {
                    var period = currentPeriods_1_1.value;
                    if (period) {
                        var hoursPipe = new HoursAmPmPipe();
                        var openTime = _this._dateS.getStringHours(period.openTime);
                        var closeTime = _this._dateS.getStringHours(period.closeTime);
                        period.openTime = hoursPipe.transform(openTime);
                        period.closeTime = hoursPipe.transform(closeTime);
                        if (period.open !== false) {
                            period.open = true;
                        }
                        periodsResult.push(period);
                    }
                    if (period.open === false) {
                        return;
                    }
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (currentPeriods_1_1 && !currentPeriods_1_1.done && (_a = currentPeriods_1.return)) _a.call(currentPeriods_1);
                }
                finally { if (e_6) throw e_6.error; }
            }
            if (currentPeriods.length === 0) {
                periodsResult.push({
                    openDay: day,
                    closeDay: day,
                    closeTime: '',
                    openTime: '',
                    open: false
                });
            }
        });
        return periodsResult;
    };
    LocationService.prototype.sortPeriodsByDay = function (periods) {
        return periods.reduce(function (r, a) {
            r[a.openDay] = r[a.openDay] || [];
            r[a.openDay].push(a);
            return r;
        }, {});
    };
    LocationService.prototype.joinByDay = function (periods) {
        var data = {};
        periods.forEach(function (el) {
            var date = el.startDate.day + "/" + el.startDate.month + "/" + el.startDate.year;
            if (Object.keys(data).includes(date)) {
                data[date].push(el);
            }
            else {
                data[date] = [el];
            }
        });
        return this._getContinuousSpecialHours(data);
    };
    LocationService.prototype.getContinuousHours = function (periods) {
        var _this = this;
        var data = [];
        periods.forEach(function (p, i) {
            var _a, _b, _c;
            var day = __assign({}, p);
            var isOpen24 = (day.openTime === '12:00 AM' && day.closeTime === '12:00 AM');
            i = (day.openDay == 'SATURDAY' ? -1 : i);
            var nextDay = periods[i + 1];
            var nextDayIsOpen24 = (nextDay.openTime === '12:00 AM' && nextDay.closeTime === '12:00 AM');
            if (day.closeTime != '' &&
                p.closeTime == ((_a = nextDay) === null || _a === void 0 ? void 0 : _a.openTime) &&
                !isOpen24 &&
                !nextDayIsOpen24 &&
                _this._isBefore1230PM(nextDay.closeTime)) {
                day.closeTime = (_b = nextDay) === null || _b === void 0 ? void 0 : _b.closeTime;
                if (day.openDay == nextDay.openDay || nextDay.openDay == ((_c = periods[i + 2]) === null || _c === void 0 ? void 0 : _c.openDay) || day.openDay == 'SATURDAY') {
                    periods.splice(periods[i + 1], 1);
                }
                else {
                    nextDay.openTime = '';
                    nextDay.closeTime = '';
                    nextDay.open = false;
                }
                if (day.openDay == 'SATURDAY' && p.closeTime == nextDay.openTime) {
                    if (data[1].openDay == 'SUNDAY') {
                        data.splice(0, 1);
                    }
                    else {
                        data[0].openTime = '';
                        data[0].closeTime = '';
                        data[0].open = false;
                    }
                }
            }
            data.push(day);
        });
        return data;
    };
    LocationService.prototype._isBefore1230PM = function (time) {
        return time && (time === '12:00 PM' || time.includes('AM'));
    };
    LocationService.prototype.fetchAccountLocations = function (gid, accountId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId).toPromise();
    };
    /**
     * Returns the same locations data passed as argument, filtered and augmented with:
     *   - If the location exists on the DB with the same gid/accountId, is filtered out.
     *   - If the location exists on the DB under other gid/accountId, is included in the result
     *     but a { exists : true} property is added.
     */
    LocationService.prototype.filterOutLocationsAlreadyAdded = function (gid, accountId, locations) {
        return this._http.post(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/getLocations", locations).toPromise();
    };
    LocationService.prototype.isAllLocationsUltimate = function (locations) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this._http.post(ENV.apiUrl + "/v2/locations/is-all-ultimate", { locationPaths: locations }).toPromise()];
                    case 1:
                        response = _c.sent();
                        return [2 /*return*/, (_b = (_a = response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.isAllLocationsUltimate];
                }
            });
        });
    };
    LocationService.prototype.basicLocations = function (list) {
        return __awaiter(this, void 0, void 0, function () {
            var accountsLocations;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountsLocations = [];
                        return [4 /*yield*/, Promise.all(list.map(function (i) { return __awaiter(_this, void 0, void 0, function () {
                                var location_1, asyncData_1;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!i.accounts) return [3 /*break*/, 5];
                                            if (!(i.accounts.length > 0)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, Promise.all(i.accounts.map(function (account) { return __awaiter(_this, void 0, void 0, function () {
                                                    var exists, basicLocations_1;
                                                    var _a;
                                                    return __generator(this, function (_b) {
                                                        switch (_b.label) {
                                                            case 0:
                                                                account.locationsBasics = [];
                                                                exists = accountsLocations.filter(function (al) { return al.accountId === account.accountId; });
                                                                if (!(exists.length === 0)) return [3 /*break*/, 2];
                                                                return [4 /*yield*/, this.getBasicLocations(i.gid || this._sessionS.getSession().gid, account.accountId)];
                                                            case 1:
                                                                basicLocations_1 = _b.sent();
                                                                accountsLocations.push((_a = {}, _a[account.accountId] = basicLocations_1, _a));
                                                                account.locations.forEach(function (location) {
                                                                    basicLocations_1.forEach(function (bl) {
                                                                        if (bl.locationId === location.locationId) {
                                                                            account.locationsBasics.push(bl);
                                                                        }
                                                                    });
                                                                });
                                                                return [3 /*break*/, 3];
                                                            case 2: return [2 /*return*/];
                                                            case 3: return [2 /*return*/];
                                                        }
                                                    });
                                                }); }))];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 5];
                                        case 2:
                                            if (!(i.gid && i.accountId && i.placeId)) return [3 /*break*/, 5];
                                            location_1 = this.getRef(i.gid, i.accountId, i.placeId);
                                            return [4 /*yield*/, Promise.all([location_1])];
                                        case 3:
                                            asyncData_1 = _a.sent();
                                            if (!asyncData_1[0]) return [3 /*break*/, 5];
                                            return [4 /*yield*/, new Promise(function (resolve) {
                                                    return asyncData_1[0]
                                                        .subscribe(function (loc) { return __awaiter(_this, void 0, void 0, function () {
                                                        var asyncAccount;
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0:
                                                                    i.location = loc.data();
                                                                    return [4 /*yield*/, loc.ref.parent.parent.get()];
                                                                case 1:
                                                                    asyncAccount = _a.sent();
                                                                    i.account = asyncAccount.data();
                                                                    resolve(loc);
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); });
                                                })];
                                        case 4:
                                            _a.sent();
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationService.prototype.getBasicLocations = function (gid, accountId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchAccountLocations(gid, accountId)];
                    case 1: 
                    // FIXME: This returns all locations, not only BASIC, it's ok? Redundant method if yes. 
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getByPrimaryCategory = function (gid, accountId, locationId) {
        return this._http.get(ENV.apiUrl + "/v2/locations/" + gid + "/" + accountId + "/" + locationId + "/category");
    };
    LocationService.prototype.saveWidget = function (gid, accountId, locationId, data) {
        return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
            .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId)
            .set(__assign({}, data), { merge: true });
    };
    LocationService.prototype.deleteWidget = function (gid, accountId, locationId) {
        return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
            .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId).ref.delete();
    };
    /// TODO: Unused, remove?
    //
    //   getLocationsPaginate(count, pageable, actions): Pagination {
    //     return this.formatPagination(count, pageable, actions);
    //   }
    //
    //   formatPagination(count: number, pageable, actions): Pagination {
    //     const pages = Math.ceil(count / pageable.size);
    //     let hasPrev = true;
    //     let hasNext = true;
    //     if (pages === pageable.page && pages > 1) {
    //       hasNext = false;
    //       hasPrev = true;
    //     } else if (pages === pageable.page && pages === 1) {
    //       hasNext = false;
    //       hasPrev = false;
    //     } else if (pageable.page === 1 && pages !== 0) {
    //       hasPrev = false;
    //       hasNext = true;
    //     } else if (pageable.page > 1 && pageable.page < pages) {
    //       hasPrev = true;
    //       hasNext = true;
    //     } else {
    //       hasPrev = false;
    //       hasNext = false;
    //     }
    // 
    //     return {
    //       items: actions,
    //       total: count,
    //       per_page: pageable.size,
    //       page:     pageable.page,
    //       pages,
    //       hasPrev,
    //       hasNext,
    //     }
    //   }
    LocationService.prototype.formatDates = function (date) {
        var _a, _b, _c;
        return ((_a = date) === null || _a === void 0 ? void 0 : _a.includes('T')) ? (_b = date) === null || _b === void 0 ? void 0 : _b.split('T')[0] : (_c = date) === null || _c === void 0 ? void 0 : _c.split(' ')[0];
    };
    LocationService.prototype.getDateValidations = function (reportType, accountIds, gids, locationIds) {
        var body = {
            "locationIds": locationIds,
            "accountIds": accountIds,
            "gids": gids,
            "type": reportType
        };
        return this._http.post(ENV.apiUrl + "/v2/locations/date-validation", body).toPromise();
    };
    // TODO: Rethink this function, as it mutates the input parameter
    LocationService.prototype.dateValidation = function (dates) {
        var _a, _b;
        var r = { minDate: null,
            maxDate: null };
        if ((_a = dates) === null || _a === void 0 ? void 0 : _a.maxDate) {
            dates.maxDate = dates.maxDate.split(' ')[0] + "T23:59:59";
            r.maxDate = moment(dates.maxDate);
        }
        if ((_b = dates) === null || _b === void 0 ? void 0 : _b.minDate) {
            dates.minDate = dates.minDate.split(' ')[0] + "T23:59:59";
            r.minDate = moment(dates.minDate);
        }
        return r;
    };
    // TODO: Why this is on LocationService?
    LocationService.prototype.buildDatepickerDate = function (reportType, maxDate, minDate) {
        var _a, _b, _c, _d;
        var now = moment();
        var today = now.clone().subtract(7, 'days');
        var todayStr = today.format('YYYY-MM-DD 23:59:59');
        var endOfMonth = now.clone().endOf('month').format('YYYY-MM-DD 23:59:59');
        var isFullMonth = (todayStr == endOfMonth);
        // same month between minDate and maxDate validation (has to take into consideration that it might be a moment or a date)
        var isValidDate = function (date) { return date instanceof Date || moment.isMoment(date); };
        var getMonth = function (date) {
            if (date instanceof Date) {
                return date.getMonth();
            }
            else if (moment.isMoment(date)) {
                return date.format('YYYY-MM');
            }
            else {
                return null;
            }
        };
        var sameMonthBetweenMinAndMaxDate = isValidDate(minDate) && isValidDate(maxDate) && getMonth(minDate) == getMonth(maxDate);
        //Case 1: when the moth is complete
        var start = isFullMonth ? today.clone().subtract(1, 'year') : today.clone().subtract({ months: 1, years: 1 });
        var end = isFullMonth ? today.clone() : today.clone().subtract({ months: 1 });
        // Case 2: if the month is incomplete
        if (!isFullMonth && (((_a = reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) || reportType == 'performance-comparison') && maxDate) {
            // we create a clone of maxDate to prevent mutating the original date which caused SO many issues...
            var maxDateClone = maxDate.clone();
            var maxDateString = maxDateClone.format('YYYY-MM-DD 23:59:59');
            endOfMonth = maxDateClone.endOf('month').format('YYYY-MM-DD 23:59:59');
            isFullMonth = (maxDateString == endOfMonth);
            start = isFullMonth ? (_b = maxDateClone) === null || _b === void 0 ? void 0 : _b.clone().subtract(1, 'year') : (_c = maxDateClone) === null || _c === void 0 ? void 0 : _c.clone().subtract({ months: 1, years: 1 });
            if (sameMonthBetweenMinAndMaxDate) {
                end = maxDateClone;
            }
            else {
                end = isFullMonth ? maxDateClone : (_d = maxDateClone) === null || _d === void 0 ? void 0 : _d.clone().subtract({ months: 1 });
            }
        }
        return { start: start.startOf('month'),
            end: end.endOf('month')
        };
    };
    LocationService.prototype.deleteServiceArea = function (accounts) {
        var _a;
        (_a = accounts) === null || _a === void 0 ? void 0 : _a.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (l) {
                var _a;
                if ((_a = Object.keys(l)) === null || _a === void 0 ? void 0 : _a.includes('serviceArea')) {
                    delete l.serviceArea;
                }
            });
        });
        return accounts;
    };
    LocationService.prototype.deleteAddress = function (accounts) {
        var _a;
        (_a = accounts) === null || _a === void 0 ? void 0 : _a.forEach(function (acc) {
            var _a;
            (_a = acc) === null || _a === void 0 ? void 0 : _a.locations.forEach(function (l) {
                var _a;
                if ((_a = Object.keys(l)) === null || _a === void 0 ? void 0 : _a.includes('address')) {
                    delete l.address;
                }
            });
        });
        return accounts;
    };
    LocationService.prototype._getContinuousSpecialHours = function (hours) {
        var keys = Object.keys(hours);
        keys.forEach(function (d) {
            hours[d].forEach(function (h, i) {
                var _a, _b;
                var nextHour = hours[d][i + 1];
                if (h.closeTime == ((_a = nextHour) === null || _a === void 0 ? void 0 : _a.openTime)) {
                    h.closeTime = (_b = nextHour) === null || _b === void 0 ? void 0 : _b.closeTime;
                    hours[d].splice(i + 1, 1);
                }
            });
        });
        return hours;
    };
    LocationService.prototype._saveInChain = function (accountId, locationId) {
        var params = new HttpParams();
        if (ENV.queuesEnabled) {
            params = params.append('enqueue', Queue.COMBINED_EXPRESS);
        }
        return this._http.post(ENV.apiUrl + "/v2/locations/" + accountId + "/" + locationId + "/save", {}, { params: params });
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.GoogleService), i0.ɵɵinject(i5.ReviewsService), i0.ɵɵinject(i6.InsightsService), i0.ɵɵinject(i7.PostService), i0.ɵɵinject(i8.ObservationService), i0.ɵɵinject(i9.DatesService), i0.ɵɵinject(i10.NotificationService), i0.ɵɵinject(i11.AccountService)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
