
<div class="content--full  bg--radial-gradient-blue d-flex justify-content-center align-items-center flex-column" 
     [ngStyle]="{'background-color': (domain$ | async).branding?.primaryColor || ''}">

  <div class="login--cont">
    <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="passwordProgress">
      <mat-spinner></mat-spinner>
    </div>

    <div cols="2" class="login--card-container pb--0">
      <div class="login--card-container-col col-first pt--80">
        <div class="logo">
          <div>
            <img 
            class="login-logo mb--20" 
            alt="Logo" 
            [src]="(domain$ | async).branding?.loginLogo || (domain$ | async).branding?.mainLogo || '/assets/images/logo/blankmap.png'" 
            width="170" 
            height="59"/>
          </div>
          <div>
            <div>
              <span class="txt--24 mb--10">Forgot Password</span>
            </div>
            <div>
              <span class="txt--14">Enter your details to recover your account:</span>
            </div>
          </div>
        </div>
      </div>
      <div class="login--card-container-col pt--80 pb--80">
        <form class="login--passrecovery-form" [formGroup]="forgotForm" (submit)="forgotPassword()">

          <mat-form-field hideRequiredMarker class="input-group input-group--override input-group--override-new">
            <input matInput placeholder="Email" name="email" type="email" formControlName="email" 
            class="input-group__field" required>
            <mat-error *ngIf="email?.invalid && email?.touched"><p>Please enter valid email</p></mat-error>
          </mat-form-field>
  
          <button mat-raised-button color="primary" class="btn border--radius-25 btn--full p--tb-10 p--lr-14 mb--20" 
          [disabled]="forgotForm.invalid"><strong>Remember password</strong></button>
          <div class="mt--75 login--back-button">
            <a class="txt--normal" (click)="navigationS.goToLogin()">Back to Login</a>
          </div>
        </form>
      </div>
    </div>

    <div class="footer footer--noborder flex-column box box--md txt--center">
			<ul class="footer__nav d-flex justify-content-center mt--20 mb--40">
				<li><a class="txt--white" (click)="navigationS.goToTermsOfService()">Terms and Conditions</a></li>
				<li><a class="txt--white" (click)="navigationS.goToPrivacyPolicy()">Privacy Policy</a></li>
				<li><a class="txt--white" (click)="navigationS.goToPaymentPolicy()">Payment Policy</a></li>
			</ul>
			<p class="txt--normal txt--white m--0">&copy; {{ (domain$ | async).branding?.company_name }} All Rights Reserved.</p>
		</div>

  </div>
</div>


