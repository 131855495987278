import { __awaiter, __generator } from "tslib";
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
// app
import { UserService } from '../../../services/user.service';
import { SnackbarService } from '../../../services/snackbar.service';
var DeleteUserDialogComponent = /** @class */ (function () {
    function DeleteUserDialogComponent(data, router, dialogRef, userService, snack) {
        this.data = data;
        this.router = router;
        this.dialogRef = dialogRef;
        this.userService = userService;
        this.snack = snack;
        this.user = data.user;
    }
    /**
     * User clicks on 'Delete user'
     */
    DeleteUserDialogComponent.prototype.delete = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var err_1, msg;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.userService.deleteUser(this.user.gid, this.user.uid)];
                    case 1:
                        _b.sent();
                        this.dialogRef.close(true);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        console.error(err_1);
                        msg = (_a = err_1.error) === null || _a === void 0 ? void 0 : _a.message;
                        this.snack.openError(msg ? "Error: " + msg : 'Unexpected Error: Please contact support');
                        this.dialogRef.close(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeleteUserDialogComponent.OPTS_DEFAULT = { config: { width: '680px' } };
    return DeleteUserDialogComponent;
}());
export { DeleteUserDialogComponent };
