import { __awaiter, __generator } from "tslib";
// dep
import { EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
// app
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LoadingService } from '../../services/loading.service';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
import { SessionService } from 'src/app/services/session.service';
var GradeMetricsComponent = /** @class */ (function () {
    function GradeMetricsComponent(googleS, loadingService, snackService, _sessionS, gradeLoaded) {
        this.googleS = googleS;
        this.loadingService = loadingService;
        this.snackService = snackService;
        this._sessionS = _sessionS;
        this.gradeLoaded = gradeLoaded;
        this.metrics = false;
        this.progress = true;
        this.hasError = false;
        this.isExternal = false;
        this.finish = new EventEmitter();
        this.grade = new EventEmitter();
    }
    GradeMetricsComponent.prototype.ngOnInit = function () {
        this.getMetrics();
    };
    GradeMetricsComponent.prototype.getMetrics = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, metrics, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.progress = true;
                        this.hasError = false;
                        return [4 /*yield*/, this.googleS.metrics(this.accountId, this.locationId, this.dataPicker)];
                    case 1:
                        result = _a.sent();
                        metrics = _.omitBy(result, _.isNil);
                        this.metrics = this._calculateGrade(metrics);
                        this.progress = false;
                        this.finish.emit(true);
                        this.loadingService.reportAdvanced(1, "Metrics");
                        this.gradeLoaded.loaded$.next(true);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.hasError = true;
                        this.progress = false;
                        this.finish.emit(true);
                        this.snackService.openError(error_1.error.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GradeMetricsComponent.prototype._calculateGrade = function (metrics) {
        var totals = Object.keys(metrics).reduce(function (total, prop) {
            return total + metrics[prop].total;
        }, 0);
        var maxs = Object.keys(metrics).reduce(function (max, prop) {
            var value = metrics[prop].max || 0;
            return max + value;
        }, 0);
        this.grade.emit({ maxs: maxs, totals: totals });
        return metrics;
    };
    return GradeMetricsComponent;
}());
export { GradeMetricsComponent };
