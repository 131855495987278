import { __assign } from "tslib";
import { AngularFirestore } from "@angular/fire/firestore";
// app
import { VERIFICATION_EMAIL } from "../constants/firestore/collections";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var VerificationEmailService = /** @class */ (function () {
    function VerificationEmailService(afs) {
        this.afs = afs;
    }
    // TODO: Unused, remove
    /**
     * this method save in firestore entity Verification Email
     * @param string
     */
    // save(uid: string, gid: string, hash: any) {
    //   return this.afs.collection(VERIFICATION_EMAIL).add({'key1': uid, 'key2':gid, 'hash': hash});
    // }
    VerificationEmailService.prototype.update = function (id, data) {
        this.afs.collection(VERIFICATION_EMAIL).doc(id).set(__assign({}, data), { merge: true });
    };
    /**
     * Get user with hash doc id to verify account
     */
    VerificationEmailService.prototype.getVerificationToHash = function (hash) {
        return this.afs.collection(VERIFICATION_EMAIL, function (rf) { return rf.where('hash', '==', hash); }).get();
    };
    /**
     * Get user with gid and uid
     */
    VerificationEmailService.prototype.getVerification = function (uid, gid) {
        return this.afs.collection(VERIFICATION_EMAIL, function (rf) { return rf.where('key1', '==', uid).where('key2', '==', gid); }).get();
    };
    VerificationEmailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerificationEmailService_Factory() { return new VerificationEmailService(i0.ɵɵinject(i1.AngularFirestore)); }, token: VerificationEmailService, providedIn: "root" });
    return VerificationEmailService;
}());
export { VerificationEmailService };
