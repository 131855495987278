// dep
import {Component, Renderer2, Inject} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import _ from 'lodash';
// import {Title} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

// app
import { ModalService } from './services/modal.service';
import { HEADERS_NO_AUTH } from './constants/auth';
import { STORAGE_CUSTOM_SCRIPT } from "./constants/session"
import { AlertType } from './components/alert.component';
import { isRunningEmbedded } from './helpers/utils.helpers';
import { SessionService } from './services/session.service';
import { BaseComponent } from './components/base.component';
import { AuthService } from './services/auth.service';

// Global set from Google Analytics Library
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls:  ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private httpClient: HttpClient,
    private modalService: ModalService,
    private _sessionS : SessionService,
    private _authS : AuthService,

    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    const domain = this._sessionS.getDomain();
    //this.titleService.setTitle(domain.branding.company_name);

    const {branding} = domain
    console.debug(`Domain ${domain.domainName} loaded`);

    this._subscribeSafe(this.router.events, (event) => {
      if(!(event instanceof NavigationEnd))
        return;

      const isShared = _.endsWith(this.router.url, 'shared');
      if(!isShared) {
        if (branding.podium) {
          this._loadPodiumScript(branding.podium);
        } else if(branding.beacon) {
          this._loadBeaconScript(branding.beacon);
        }
      }

      if (typeof gtag === 'function') { // Check if gtag is defined
        gtag('config', branding.analytics || 'UA-138537135-2', { 'page_path': event.urlAfterRedirects });
      } else {
        console.error('gtag is not defined');
      }
        
    });

    if (!isRunningEmbedded()) {
      localStorage.setItem(STORAGE_CUSTOM_SCRIPT, branding.customScript);
    }

    this._spawnPeriodicVersionChecking (1000 * 60 * 2);
    this._spawnPeriodicDomainValidation(1000 * 60 * 1);
  }

  private _spawnPeriodicVersionChecking(freq : number) : void {
    // TODO: Note, there is a very improbable race condition that happens
    // if browserVersion is first fetched after the code was changed.
    // browserVersion should start from the local /assets/version.json
    let running = false
    let browserVersion : string | null = null
    const f = async () => {
      try {
        if(running)
          return
        running = true  
        const remoteVersion = (await this.httpClient.get<any>( '/assets/version.json', HEADERS_NO_AUTH).toPromise()).version
        if(browserVersion && browserVersion !== remoteVersion) {
          await this.modalService.openAlertModal('New Version Available',
                                                 'A new version is available, please refresh the page to continue.',
                                                 AlertType.WARNING,
                                                 null,
                                                 'Reload Page')
          // Reload without signout
          window.location.reload();
        }
        browserVersion = remoteVersion;
      } finally {
        running = false
      }
    }
    setInterval(f, freq);
  }

  private _spawnPeriodicDomainValidation(freq : number) : void {
    let running = false
    const f = async () => {  
      try {
        if(running)
          return
        running = true

        const isShared           = _.endsWith(this.router.url, 'shared');
        const isExternalUrlGrade = _.endsWith(this.router.url, 'grade');

        if(isShared || isExternalUrlGrade)
          return

        const s = await this._sessionS.waitSession()

        if(s.sessionType !== 'NORMAL')
          return

        await this._authS.logoutIfDomainValidationFails(s.user);

      } catch(err) {
        console.log(err);
      } finally {
        running = false
      }
    }
    setInterval(f, freq); 
  }


  private _loadPodiumScript(token : string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script['defer'] = true;
    script['type'] = 'text/javascript';
    script['src'] = `https://connect.podium.com/widget.js#API_TOKEN=${token}`;
    script['id']="podium-widget";
    script['data-api-token'] = token;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  private _loadBeaconScript(id) {
    const container = this.renderer.createElement('section');

    const script = this.renderer.createElement('script');
    script['type'] = 'text/javascript';
    script.innerText = function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e['Beacon']=n=function(t,n,a){e['Beacon'].readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e['attachEvent']?e['attachEvent']("onload",a):e.addEventListener("load",a,!1)}(window,document,window['Beacon']||function(){});
    container.appendChild(script);

    const scriptId = this.renderer.createElement('script');
    scriptId['type'] = 'text/javascript';
    scriptId.innerText = window['Beacon']('init', id);
    container.appendChild(scriptId);

    this.renderer.appendChild(this.document.head, container);
    return container;
  }


}
