/**
 * This is the old Billing page used for subscriptions with pricingVersion <= 2
 */

// dep
import { Component, OnInit } from '@angular/core';

// app
import { ModalService } from 'src/app/services/modal.service';
import { UpdateCardComponent } from './update-card/update-card.component';
// import { EditCardComponent } from './edit-card/edit-card.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PaymentsService } from '../../services/payments.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls:  ['./billing.component.scss']
})
export class BillingComponent extends BaseComponent implements OnInit {
  paymentsHistory: any[];
  cards: any[];
  loadingHistory: boolean;
  loadingCards: boolean;
  readonly listColumns = ['number', 'date', 'amount', 'status'] as const
  basicPlan;
  ultimatePlan;

  session$ = this._sessionS.session$
  
  constructor(private _sessionS : SessionService,
              private paymentService: PaymentsService,
              private subscriptionService: SubscriptionService,
              public navigationS : NavigationService,
              private _modalS: ModalService,
              private _snackS: SnackbarService) {
    super();
    this._subscribeSafe(this.session$, _ => {
      // if (this.subscription?.blocked) {
      //   this.modalService.openWarningModal(
      //     'Your payment method failed.',
      //     'Please update your credit card or contact your bank.'
      //   );
      // } 
        this.getCards();
      });
  }

  async onAddCard() : Promise<void> {
    if(!(await this._modalS.openModal(UpdateCardComponent, {})))
      return
    await this._sessionS.refresh()
    await this.getCards()
  }

  ngOnInit(): void {
    this.loadingCards = true;
    this._getPlanInfo();
    this.fetchInvoices();
    this.getCards();
  }

  private _getPlanInfo(): void {
    this.paymentService.getPlan(LOCATION_SUBSCRIPTION_TYPE.ULTIMATE)
      .subscribe(plan => {
        const interval = plan['data']['interval'];
        const amount   = plan['data']['price'];
        this.ultimatePlan = {
          interval,
          amount: amount,
          isFree: (amount == 0)
        };
      });
      
    this.paymentService.getPlan(LOCATION_SUBSCRIPTION_TYPE.BASIC)
      .subscribe(plan => {
        const interval = plan['data']['interval'];
        const amount   = plan['data']['price'];
        this.basicPlan = {
          interval,
          amount: amount,
          isFree: (amount == 0)
        };
      });
  }

  async fetchInvoices(): Promise<void> {
    const invoices = await this.subscriptionService.fetchInvoices(this._sessionS.getSession().gid)
    this.paymentsHistory = invoices.data.filter(el => ['paid', 'pending', 'pre authorized', 'unpaid', 'canceled'].includes(el.status.toLowerCase()))
  }

  private async getCards(): Promise<void> {
    this.loadingCards = true
    try {
      this.cards = await this.paymentService.getCards(this._sessionS.getSession().gid)
    } catch {
      // pass
    } finally { 
       this.loadingCards = false  
    }
  }

  async handleDefaultSource(card): Promise<void> {
    if(await this._modalS.openConfirmModal("Set as default",
                                           "Are you sure you want use this Credit Card as the default one?")) {
        this.loadingCards = true;
        this.applyDefaultSource(card.card); 
      }
  }

  async applyDefaultSource(card): Promise<void> {
    await this.paymentService.setDefaultSource(this._sessionS.getSession().gid, card.id)
    this._snackS.openSuccess('Set as default Credit Card succesfully');
    this.getCards();
  }

  async handleDeleteSource(card): Promise<void> {
    if(await this._modalS.openConfirmModal("Remove Credit Card",
                                           "Are you sure you want to remove this Credit Card?")) {
      this.loadingCards = true;
      this.deleteSource(card.card);
    }
  }

  private async deleteSource(card): Promise<void> {
    await this.paymentService.deleteCard(this._sessionS.getSession().gid, card.id) 
    this._snackS.openSuccess('Removed Credit Card succesfully')
    this.getCards();
  }

}
