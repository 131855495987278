// dep
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _ from 'lodash';

// app
import {ReportService} from '../../services/report.service';
import {DataPicker} from '../../constants/data-picker';
import {REPORT_TYPE} from '../../constants/firestore/report';
import {EmailerService} from '../../services/emailer.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-modal-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss']
})
export class ModalShareComponent implements OnInit {
  private form: FormGroup;
  private dataPicker: DataPicker;
  private readonly data: any;
  url: string;
  isCopy: boolean;
  private reportType: REPORT_TYPE;
  successSend: boolean;
  isToShare = false;
  progress: boolean;
  failedSend: boolean;
  timeoutMessage: boolean;
  report: any;
  comparisonMetrics: any[] = [];
  reportName = '';
  showComparison = null;
  questionsType = null;
  periodDaysValue = null;
  compareToValue = null;
  public createNew = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) private modalConfirmData,
    private fb: FormBuilder,
    private reportS: ReportService,
    private _sessionS: SessionService,
    private emailerS: EmailerService,
  ) {
    this.data = this.modalConfirmData.data;
    this.report = this.modalConfirmData.data.report;
    this.dataPicker = this.data.dataPicker;
    this.reportType = this.data.reportType;
    this.createNew = this.data?.createNew;
    this.comparisonMetrics = this.data?.comparisonMetrics || [];
    this.reportName = this.data?.reportName;
    this.showComparison = this.data?.showComparison;
    this.questionsType = this.data?.questionsType;
    this.periodDaysValue = this.data?.periodDaysValue;
    this.compareToValue = this.data?.compareToValue;

    const paramId = this.data?.route?.snapshot?.params?.id;
    const paramGid = this.data?.route?.snapshot?.params?.gid;
    const id = paramId || (this.report?.id || this.report?._id);
    const gid = paramGid || this.report?.gid;
    const {domainNameWithPort} = this._sessionS.getDomain()
    
    if (this.createNew) {
      this.reportS.share(this.dataPicker, this.reportType, this.comparisonMetrics, this.reportName, this.showComparison, this.questionsType, this.periodDaysValue, this.compareToValue).subscribe(report => {
        if (this.reportType != 'performance-insights' && !this.reportType.includes('-location') && !this.reportType.includes('-report')) {
          report.get().then(result => {
            const reportId = result.id;
            const reportGid = gid || result.data().gid;
            this.url = `https://${domainNameWithPort}/report/${reportGid}/${reportId}/${this.reportType}/shared`;
          });
        } else {
          const reportId = report.reportId;
          const reportGid = gid || this._sessionS.getSession().gid;
          this.url = `https://${domainNameWithPort}/report/${reportGid}/${reportId}/${this.reportType}/shared`;
        }
      });
    } else {
      this.url = `https://${domainNameWithPort}/report/${gid}/${id}/${this.reportType}/shared`;
      this.reportS.setMerge(gid, id, {shared: true}).then();
    }


  }

  ngOnInit() : void {
    this.initForm();
    this.timeoutMessage = true;
  }

  initForm() : void {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  get email() {
    return this.form.get('email');
  }

  copyInputMessage($event) {
    $event.preventDefault();
    const selBox = document.createElement('input');
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopy = true;

    setTimeout(() => {
      this.timeoutMessage = false;
    }, 3000);
  }


  async send() : Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.progress = true;
    const b = this._sessionS.getDomain().branding

    const message = `
    <h2>${b.company_name}</h2>
    <h3>A report has been shared with you!</h3>
    <hr>
    <p>Someone at ${b.company_name} shared a report with you. Click <a href="${this.url}" target="_BLANK">here</a> 
    to view it or copy and paste the link below.</p>
    <p>${this.url}</p>
    <hr>
    <p><center><a href="${b.main_website}">${b.company_name}</a></center></p>
    `;

    try {
      await this.emailerS.send(this.email.value, 'A report has been shared', message).toPromise()
      this.successSend = true;
    } catch(err) {
      this.failedSend = true;
      console.error(err);
    }
    this.progress = false;

  }
}
