import { __extends } from "tslib";
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base.component';
// app
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
var FooterComponent = /** @class */ (function (_super) {
    __extends(FooterComponent, _super);
    function FooterComponent(_router, _sessionS, navigationS) {
        var _this = _super.call(this) || this;
        _this._router = _router;
        _this._sessionS = _sessionS;
        _this.navigationS = navigationS;
        _this.customPosition = false;
        _this.date = new Date();
        _this.showFooter = false;
        _this.domain$ = _this._sessionS.domain$;
        _this._subscribeSafe(_router.events, function (event) {
            if (event instanceof NavigationEnd)
                _this._refresh();
        });
        _this._refresh();
        return _this;
    }
    FooterComponent.prototype._refresh = function () {
        var url = this._router.url;
        this.showFooter = !((url.includes('login') || url.includes('register') || url.includes('forgot-password')));
    };
    return FooterComponent;
}(BaseComponent));
export { FooterComponent };
