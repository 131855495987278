import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

import { Subject, Observable } from 'rxjs';

import { IDateRange, ISelectedDateRange, Granularity } from '../../../constants/data-picker';


/* Definition of abstract service to be implemented by each report service
*
*/

export abstract class ReportsFilterConfigService {

  // convert into single observable/subject for all date-related stuff
  private _dateRestrictionSubject = new Subject<IDateRange>();
  private _dateRangeSubject = new Subject<ISelectedDateRange>();
  private _reportDataSubject = new Subject<any>();
  protected _starterMinDate: any;
  protected _starterMaxDate: any;

  constructor(
    protected _http: HttpClient
  ) { }

  get dateRestrictionSubject$(): Observable<IDateRange> {
    return this._dateRestrictionSubject.asObservable();
  }

  get dateRangeSubject$(): Observable<ISelectedDateRange> {
    return this._dateRangeSubject.asObservable();
  }

  get keywordsReportSubject$(): Observable<any> {
    return this._reportDataSubject.asObservable();
  }
  initializeDateRestriction(dateRangeLimits: IDateRange, reportType: string): void {
    console.log();
    
    let newDateRangeLimits = {
      minDate: new Date(dateRangeLimits.minDate), 
      maxDate: new Date(dateRangeLimits.maxDate)
    };
    console.log('newDateRangeLimits', newDateRangeLimits);
    
    // definition of new date variables
    let newMaxDate: Date = null;
    let newMinDate: Date = null;

    // definition of calculation variables
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();

    const currentMaxDate = new Date(newDateRangeLimits.maxDate);
    const currentMaxDateDay = currentMaxDate.getDate();
    const currentMaxDateMonth = currentMaxDate.getMonth();
    const currentMaxDateYear = currentMaxDate.getFullYear();

    const currentMinDate = new Date(newDateRangeLimits.minDate);
    const currentMinDateDay = currentMinDate.getDate();
    const currentMinDateMonth = currentMinDate.getMonth();
    const currentMinDateYear = currentMinDate.getFullYear();

    // first convert currentMaxDate to be the last day of the previous month, if its not already the last day of its month
    if (currentMaxDateDay !== new Date(currentMaxDateYear, currentMaxDateMonth + 1, 0).getDate()) { // not the last day of the month
      newMaxDate = new Date(currentMaxDateYear, currentMaxDateMonth, 0); // last day of the previous month
      console.log('newMaxDate', newMaxDate);
      newDateRangeLimits = {...newDateRangeLimits, maxDate: newMaxDate}
    }
    
    // if today is between 1 and 6 of the month and currentMaxDateMonth is this month, we substract one month from newMaxDate
    // only applies for keywords and performance reports
    
    if ((reportType === 'keyword' || reportType === 'performance') 
      && todayDay >= 1 && todayDay <= 6 
      && ((newDateRangeLimits.maxDate.getMonth() === todayMonth) || (newDateRangeLimits.maxDate.getMonth() === todayMonth - 1))) {

      if (newDateRangeLimits.maxDate.getMonth() === 0) {
        newMaxDate = new Date(newMaxDate.getFullYear() - 1, 11, 31);
      } else {
        newMaxDate = new Date(newMaxDate.getFullYear(), newMaxDate.getMonth(), 0); // last day of the previous month
      }
      newDateRangeLimits = {...newDateRangeLimits, maxDate: newMaxDate}
    }

    // we convert the currentMinDate to be the first day of the next month, if its not already the first day of its month
    if (currentMinDateDay !== 1) { // not the first day of the month
      console.log('not the first day of the month');
      
      if (currentMinDateMonth === 11) { // last month
        newMinDate = new Date(currentMinDateYear + 1, 0, 1);
      } else {
        newMinDate = new Date(currentMinDateYear, currentMinDateMonth + 1, 1);
      }
      newDateRangeLimits = {...newDateRangeLimits, minDate: newMinDate}
    }

    console.log('newDateRangeLimits after conversions', newDateRangeLimits);
    this._starterMaxDate = newDateRangeLimits.minDate;
    this._starterMaxDate = newDateRangeLimits.maxDate;

    this._dateRestrictionSubject.next(newDateRangeLimits);
  }

  emitDateRestriction(dateRestriction: IDateRange): void {
    if (dateRestriction) {
      this._dateRestrictionSubject.next(dateRestriction);
    }
  }

  // should fire the re-renderization of associated graphs
  emitDateRange(dateRange: ISelectedDateRange): void {
    this._dateRangeSubject.next(dateRange);
  }

  buildGranularity(startDate: Date, endDate: Date): string {
    let granularity: Granularity = null;

    // DAY available when selectedRange = 1 month (default on 1 month)
    // WEEK available when selectedRange >= 2 months AND selectedRange <= 12 months (default on 2 - 5 months)
    // MONTH available when selectedRange >= 3 months (default on 6+ months)

    if (startDate.getMonth() === endDate.getMonth()) {
      granularity = Granularity.Day;
    } else if (endDate.getMonth() - startDate.getMonth() >= 1 && endDate.getMonth() - startDate.getMonth() <= 2) {
      granularity = Granularity.Day;
    } else if (endDate.getMonth() - startDate.getMonth() >= 3) {
      granularity = Granularity.Month;
    }
    
    return granularity;
  }

  getDateValidation(reportType : string, accountIds : string[], gids : string[], locationIds : string[]): Observable<any> {
    const data = {
      "locationIds": locationIds,
      "accountIds": accountIds,
      "gids": gids,
      "type": reportType
      
      }
    return this._http.post(`${environment.apiUrl}/v2/locations/date-validation`, data)
  }

  /**
   * Provides a method to update the filterConfig object
   * and call the associated reportData service to retrieve
   * new report data based on the new filter parameters
   */
  abstract updateFilterConfigAndGetReportData(params: any): void
}

