import { __awaiter, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// app
import { SnackbarService } from '../../services/snackbar.service';
import { NavigationService } from '../../services/navigation.service';
import { EmailerService } from 'src/app/services/emailer.service';
import { SessionService } from 'src/app/services/session.service';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(_sessionS, emailerService, router, snack, navigationS) {
        this._sessionS = _sessionS;
        this.emailerService = emailerService;
        this.router = router;
        this.snack = snack;
        this.navigationS = navigationS;
        this.passwordProgress = false;
        this.domain$ = this._sessionS.domain$;
        this.forgotForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        // This was not needed, TODO: Remove
        // await this.authService.afAuth.auth.signOut();
    };
    ForgotPasswordComponent.prototype.forgotPassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var email, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = this.email.value;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.passwordProgress = true;
                        return [4 /*yield*/, this.emailerService.sendResetPasswordMail({ email: email })];
                    case 2:
                        _a.sent();
                        this.router.navigateByUrl('/login');
                        this.snack.openSuccess("A reset password email has been sent to " + email);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        this.snack.openError("The email " + email + " is incorrect or not registered.");
                        return [3 /*break*/, 5];
                    case 4:
                        this.passwordProgress = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "email", {
        get: function () {
            return this.forgotForm.get('email');
        },
        enumerable: true,
        configurable: true
    });
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
