import { __awaiter, __extends, __generator } from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { PostService } from '../../services/post.service';
import { FormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material';
import { PostListComponent } from './post-list/post-list.component';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';
var LocalPostsComponent = /** @class */ (function (_super) {
    __extends(LocalPostsComponent, _super);
    function LocalPostsComponent(
    // private dialog: MatDialog,
    postS, route, _sessionS, snack, locationService, _router) {
        var _this = _super.call(this) || this;
        _this.postS = postS;
        _this.route = route;
        _this._sessionS = _sessionS;
        _this.snack = snack;
        _this.locationService = locationService;
        _this._router = _router;
        _this.displayedColumns = ['img', 'info', 'date', 'dateUpdate', 'actions'];
        _this.displayedPendingColumns = ['img', 'info', 'date', 'status', 'progress', 'actions'];
        _this.displayedDraftColumns = ['img', 'info', 'date', 'status', 'actions'];
        _this.dataSource = new MatTableDataSource([]);
        _this.dataArchiveSource = new MatTableDataSource([]);
        _this.dataSourceNew = new MatTableDataSource([]);
        _this.postsArchiveExist = false;
        _this.postsExist = false;
        _this.pendingPostsExist = false;
        _this.focusTab$ = new BehaviorSubject(0);
        _this.isSpinner = false;
        _this.progressPost = true;
        _this.progressArchivePost = true;
        _this.tabs = ['Posted', 'Pending', 'Drafts', 'Archive'];
        _this.selected = new FormControl(0);
        _this.locationId = _this.route.parent.snapshot.params.locationId;
        _this.accountId = _this.route.parent.snapshot.params.accountId;
        return _this;
    }
    LocalPostsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this.postS.posts$, function (newPost) {
            if (newPost) {
                switch (newPost.tab) {
                    case 'Posted':
                        if (!_this.tabPosted || _this.tabPosted === undefined)
                            break;
                        if (_this.selected.value !== 0)
                            _this.selected.setValue(0);
                        _this.tabPosted.refresh();
                        break;
                    case 'Pending':
                        if (!_this.tabPending || _this.tabPending === undefined)
                            break;
                        if (_this.selected.value !== 1)
                            _this.selected.setValue(1);
                        _this.tabPending.refresh();
                        break;
                    case 'Drafts':
                        if (!_this.tabDraft || _this.tabDraft === undefined)
                            break;
                        if (_this.selected.value !== 2)
                            _this.selected.setValue(2);
                        _this.tabDraft.refresh();
                        break;
                    default:
                        if (!_this.tabArchive || _this.tabArchive !== undefined)
                            break;
                        if (_this.selected.value !== 3)
                            _this.selected.setValue(3);
                        _this.tabArchive.refresh();
                        break;
                }
            }
        });
        if (this.dashboardType === 'LOCAL_POST') {
            //this.tabs = ['Posted', 'Pending', 'Archive'];
            this.bulkPost = false;
        }
        else if (this.dashboardType === 'BULK_POST') {
            this.bulkPost = true;
        }
    };
    LocalPostsComponent.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, locationId, accountId, selectedTabIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        locationId = this.route.parent.snapshot.params.locationId;
                        accountId = this.route.parent.snapshot.params.accountId;
                        this.snack.openSuccess('Refresh request sent!', 2000);
                        return [4 /*yield*/, this.locationService.checkLocation(gid, accountId, locationId).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.postS.saveV3All(gid, accountId, locationId, 'enqueue')];
                    case 2:
                        _a.sent();
                        selectedTabIndex = this.selected.value;
                        this.refreshPostsByIndex(selectedTabIndex);
                        return [2 /*return*/];
                }
            });
        });
    };
    LocalPostsComponent.prototype.purge = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, locationId, accountId, selectedTabIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        locationId = this.route.parent.snapshot.params.locationId;
                        accountId = this.route.parent.snapshot.params.accountId;
                        this.snack.openSuccess('Purge request sent!', 2000);
                        return [4 /*yield*/, this.locationService.checkLocation(gid, accountId, locationId).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.postS.purgeGmbDeleted(gid, accountId, locationId)];
                    case 2:
                        _a.sent();
                        selectedTabIndex = this.selected.value;
                        this.refreshPostsByIndex(selectedTabIndex);
                        return [2 /*return*/];
                }
            });
        });
    };
    LocalPostsComponent.prototype.create = function () {
        var url = this.dashboardType === 'BULK_POST' ? "/posts-management" : "/account/" + this.accountId + "/location/" + this.locationId + "/post-management";
        this._router.navigate([url]);
        // let tabSelected: 'Posted' | 'Pending' | 'Drafts' | 'Archive' = 'Posted'
        // if (this.selected.value === 1) tabSelected = 'Pending'
        // if (this.selected.value === 2) tabSelected = 'Drafts'
        // if (this.selected.value === 3) tabSelected = 'Archive'
        // this.dialog.open(ModalLocalPostsComponent, {
        //   width: '700px',
        //   data: {posts: this.bulkPost, locationId: this.locationId, accountId: this.accountId, post_type: tabSelected}
        // });
    };
    LocalPostsComponent.prototype.tabChange = function ($event) {
        this.refreshPostsByIndex($event.index);
    };
    LocalPostsComponent.prototype.refreshPostsByIndex = function (index) {
        switch (index) {
            case 0:
                this.tabPosted.refresh();
                break;
            case 1:
                this.tabPending.refresh();
                break;
            case 2:
                this.tabDraft.refresh();
                break;
            default:
                this.tabArchive.refresh();
                break;
        }
    };
    LocalPostsComponent.prototype.indexChange = function ($event) {
        this.focusTab$.next($event);
        this.selected.setValue($event);
    };
    return LocalPostsComponent;
}(BaseComponent));
export { LocalPostsComponent };
