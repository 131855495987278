import { __awaiter, __extends, __generator, __read, __spread } from "tslib";
// dep
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
// app
import { ACCOUNTS, GROUPS, LOCATIONS, WIDGET_INFO } from '../../constants/firestore/collections';
import { LocationService } from '../../services/location.service';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var DashboardWidgetComponent = /** @class */ (function (_super) {
    __extends(DashboardWidgetComponent, _super);
    function DashboardWidgetComponent(_sessionS, locationS, route, googleS, fb, snack, afs, cdr) {
        var _this = _super.call(this) || this;
        _this._sessionS = _sessionS;
        _this.locationS = locationS;
        _this.route = route;
        _this.googleS = googleS;
        _this.fb = fb;
        _this.snack = snack;
        _this.afs = afs;
        _this.cdr = cdr;
        _this.location = null;
        _this.selectedStarts = [];
        _this.eventsSubject = new Subject();
        _this.masterReset = 0;
        _this.locationId = _this.route.snapshot.parent.params.locationId;
        _this.accountId = _this.route.parent.snapshot.params.accountId;
        _this.initForm();
        return _this;
    }
    DashboardWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.googleS.dynamicUrl(this.locationId).subscribe(function (url) {
            _this.url = url.shortLink;
        });
        this.cdr.detectChanges();
    };
    Object.defineProperty(DashboardWidgetComponent.prototype, "_generateFrameSource", {
        get: function () {
            var _a = this._sessionS.getSession(), uid = _a.uid, gid = _a.gid;
            var domainNameWithPort = this._sessionS.getDomain().domainNameWithPort;
            var order = this.order.value;
            // if (domain === 'localhost') {
            //   domain = domain + ':4200';
            // }
            var source = "https://" + domainNameWithPort + "/widget/" + gid + "/" + this.accountId + "/" + this.locationId + "/review?&uid=" + uid;
            if (order) {
                source += "&order=" + order;
            }
            if (this.backgroundColor) {
                source += "&back_color=" + this.backgroundColor.replace('#', '');
            }
            if (this.buttonColor) {
                source += "&btn_color=" + this.buttonColor.replace('#', '');
            }
            if (this.buttonTextColor) {
                source += "&btn_txt_color=" + this.buttonTextColor.replace('#', '');
            }
            if (this.textColor) {
                source += "&text_color=" + this.textColor.replace('#', '');
            }
            if (this.selectedStarts) {
                source += "&filter=" + this.joinStars;
            }
            var newWidth;
            if (this.width) {
                newWidth = this.width.value + this.measure.value;
            }
            return "<iframe width=\"" + newWidth + "\" height=\"100%\" id=\"gmb-container-1\" src=\"" + source + "\" ></iframe>";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardWidgetComponent.prototype, "joinStars", {
        get: function () {
            if (this.selectedStarts) {
                return this.selectedStarts.join();
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * handle method submit form and generate source url by iframe html
     */
    DashboardWidgetComponent.prototype.submit = function () {
        if (this.width.invalid) {
            return;
        }
        this.frameSource = this._generateFrameSource;
        this.eventsSubject.next();
    };
    DashboardWidgetComponent.prototype.getDataWidget = function () {
        var data = {};
        var order = this.order.value;
        if (order) {
            data.order = order;
        }
        data.responses = this.responses.value;
        if (this.backgroundColor) {
            data.backgroundColor = this.backgroundColor;
        }
        if (this.buttonColor) {
            data.buttonColor = this.buttonColor;
        }
        if (this.buttonTextColor) {
            data.buttonTextColor = this.buttonTextColor;
        }
        if (this.textColor) {
            data.textColor = this.textColor;
        }
        if (this.selectedStarts) {
            data.selectedStarts = this.selectedStarts;
        }
        if (this.width) {
            data.measure = this.measure.value;
            data.width = this.width.value;
        }
        return data;
    };
    /**
     * manage change selected  starts
     */
    DashboardWidgetComponent.prototype.handleStarts = function ($event, num) {
        if (!this.selectedStarts) {
            this.selectedStarts = [];
        }
        if ($event.checked === true) {
            this.selectedStarts.push(num);
        }
        if ($event.checked === false) {
            var index = this.selectedStarts.findIndex(function (value) { return value === num; });
            this.selectedStarts.splice(index, 1);
        }
        if (this.selectedStarts.length > 0) {
            this.selectedStarts.sort();
        }
    };
    DashboardWidgetComponent.prototype.selectedBackground = function ($event) {
        this.backgroundColor = $event;
    };
    DashboardWidgetComponent.prototype.selectedButton = function ($event) {
        this.buttonColor = $event;
    };
    DashboardWidgetComponent.prototype.selectedButtonText = function ($event) {
        this.buttonTextColor = $event;
    };
    DashboardWidgetComponent.prototype.selectedText = function ($event) {
        this.textColor = $event;
    };
    /**
     * this method set backgroundColor to undefined
     */
    DashboardWidgetComponent.prototype.clearedBackground = function () {
        this.backgroundColor = undefined;
    };
    /**
     * this method set buttonColor to undefined
     */
    DashboardWidgetComponent.prototype.clearedButtonColor = function () {
        this.buttonColor = undefined;
    };
    /**
     * this method set buttonTextColor to undefined
     */
    DashboardWidgetComponent.prototype.clearedButtonText = function () {
        this.buttonTextColor = undefined;
    };
    /**
     * this method clear text color
     */
    DashboardWidgetComponent.prototype.clearedText = function () {
        this.textColor = undefined;
    };
    // form initialize and getters
    DashboardWidgetComponent.prototype.initForm = function () {
        var _this = this;
        var gid = this._sessionS.getSession().gid;
        this._subscribeSafe(this.afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(this.accountId)
            .collection(LOCATIONS).doc(this.locationId).collection(WIDGET_INFO).doc(this.locationId)
            .valueChanges(), function (res) {
            if (res) {
                _this.selectedStarts = __spread(res.selectedStarts);
                _this.backgroundColor = res.backgroundColor;
                _this.textColor = res.textColor;
                _this.buttonTextColor = res.buttonTextColor;
                _this.buttonColor = res.buttonColor;
                _this.form = _this.fb.group({
                    width: [res.width],
                    measure: [res.measure],
                    order: [res.order],
                    responses: [res.responses],
                });
                _this.submit();
            }
            else {
                _this.form = _this.fb.group({
                    width: ['100'],
                    measure: ['%'],
                    order: ['createTime'],
                    responses: [true],
                });
                _this.submit();
            }
            _this.frameSource = _this._generateFrameSource;
        });
    };
    Object.defineProperty(DashboardWidgetComponent.prototype, "width", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('width');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardWidgetComponent.prototype, "order", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('order');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardWidgetComponent.prototype, "measure", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('measure');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardWidgetComponent.prototype, "responses", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('responses');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * this method copies the iframe content to clipboard
     */
    DashboardWidgetComponent.prototype.copyIframe = function (content) {
        content.select();
        document.execCommand('copy');
        content.setSelectionRange(0, 0);
        this.snack.openSuccess('The widget code has been copied to your clipboard!');
    };
    /**
     * this method reset the filters
     */
    DashboardWidgetComponent.prototype.resetOptions = function () {
        this.masterReset += 1;
        this.selectedStarts = [];
        this.clearedBackground();
        this.clearedButtonColor();
        this.clearedButtonText();
        this.clearedText();
        this.initForm();
    };
    DashboardWidgetComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.locationS.deleteWidget(gid, this.accountId, this.locationId)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.locationS.saveWidget(gid, this.accountId, this.locationId, this.getDataWidget())];
                    case 3:
                        _a.sent();
                        this.snack.openSuccess('The widget has been saved');
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        console.error(err_1);
                        this.snack.openError('There was an error saving the widget');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return DashboardWidgetComponent;
}(BaseComponent));
export { DashboardWidgetComponent };
