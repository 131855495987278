import { __extends, __values } from "tslib";
// dep
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
// app
import { REGEX_24_HOURS, REGEX_URL } from '../../constants/regex';
import { DatesService } from '../../services/dates.service';
import { FormInputUploadComponent } from '../form-input-upload/form-input-upload.component';
import { SchedulePost } from '../../constants/post';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var FormPostComponent = /** @class */ (function (_super) {
    __extends(FormPostComponent, _super);
    function FormPostComponent(fb, dateS, _sessionS) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.dateS = dateS;
        _this._sessionS = _sessionS;
        _this.isBulk = false;
        _this.isEditing = false;
        _this.recurring = null;
        _this.recurringChange = new EventEmitter();
        _this.publishRecurringNow = false;
        _this.publishRecurringNowChange = new EventEmitter();
        _this.isRecurringValid = new EventEmitter();
        _this.scheduled = null;
        _this.planningDates$ = of([]);
        _this.isValid = new EventEmitter();
        _this.data = new EventEmitter();
        _this.handleIsRecurring = new EventEmitter();
        _this.postDateAvaible = false;
        // TODO: Refactor against src/app/constants/post.ts 
        _this.placeHolders = [
            { key: '%websiteUrl%', value: 'Website URL' },
            { key: '%url_menu%', value: 'Menu URL' },
            { key: '%url_order_ahead%', value: 'Order Ahead URL' },
            { key: '%url_covid_19_info_page%', value: 'COVID-19 URL' },
            { key: '%url_facility_telemedicine_page%', value: 'Virtual Care URL' },
            { key: '%url_appointment%', value: 'Appointment URL' },
            { key: '%url_reservations%', value: 'Reservations URL' },
        ];
        _this.imageRequirements = {
            type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
            min_width: 400,
            min_height: 300,
            min_size: 10240,
            max_size: 5000000
        };
        _this.imageUrl = [];
        _this.imageSelectedUrl = null;
        _this.types = {
            BOOK: 'Book',
            ORDER: 'Order Online',
            LEARN_MORE: 'Learn More',
            SIGN_UP: 'Sign Up',
            SHOP: 'Buy',
            CALL: 'Call Now'
        };
        _this.timeChecked = false;
        _this.index_focus_out = '';
        // LIST POST TYPE
        _this.listPostType = {
            STANDARD: 'Standard',
            EVENT: 'Event',
            OFFER: 'Offer',
            ALERT: 'Alert',
        };
        _this.isDisabledTime = false;
        _this.date = new Date();
        return _this;
    }
    FormPostComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.postTypeName = this.listPostType[this.postType];
        this.postDateAvaible = (this.postTypeName === 'Event' || this.postTypeName === 'Offer');
        if (!this.placeId) {
            // FIXME: gid as a placeId? This has any sense?
            this.placeId = this._sessionS.getSession().gid;
        }
        if (this.scheduled) {
            this.date = moment(this.scheduled.date).local().toDate();
        }
        this._subscribeSafe(this.planningDates$, function (planning) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            if (planning.length > 0) {
                var startDate = planning[0].start;
                var endDate = planning[0].end;
                (_a = _this.startDate) === null || _a === void 0 ? void 0 : _a.setValue(startDate);
                (_b = _this.endDate) === null || _b === void 0 ? void 0 : _b.setValue(endDate);
                if (((_c = _this.recurring) === null || _c === void 0 ? void 0 : _c.eventTimeEnd) || ((_d = _this.recurring) === null || _d === void 0 ? void 0 : _d.eventTimeStart)) {
                    var startTime = ((_e = _this.recurring) === null || _e === void 0 ? void 0 : _e.eventTimeStart) || ((_f = startDate) === null || _f === void 0 ? void 0 : _f.getHours()) + ":" + ((_g = startDate) === null || _g === void 0 ? void 0 : _g.getMinutes());
                    var endTime = ((_h = _this.recurring) === null || _h === void 0 ? void 0 : _h.eventTimeEnd) || ((_j = endDate) === null || _j === void 0 ? void 0 : _j.getHours()) + ":" + ((_k = endDate) === null || _k === void 0 ? void 0 : _k.getMinutes());
                    (_l = _this.startTime) === null || _l === void 0 ? void 0 : _l.setValue(startTime);
                    (_m = _this.endTime) === null || _m === void 0 ? void 0 : _m.setValue(endTime);
                }
                (_o = _this.form) === null || _o === void 0 ? void 0 : _o.updateValueAndValidity();
            }
        });
        this.initForm();
    };
    FormPostComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        if ((_a = changes.touched) === null || _a === void 0 ? void 0 : _a.firstChange) {
            return;
        }
        if (changes.scheduled || changes.recurring) {
            if (this.scheduled) {
                this.date = moment(this.scheduled.date).local().toDate();
            }
            else {
                this.date = new Date();
            }
            if (this.postType !== 'STANDARD' && this.postType !== 'ALERT' && !this.recurring) {
                this.endDate.setValidators([Validators.required, this.valitadeSameDate()]);
                this.endDate.updateValueAndValidity();
                this.validateEqualDate();
            }
            else if (this.postType !== 'STANDARD' && this.postType !== 'ALERT' && this.recurring) {
                this.endDate.setValidators([Validators.required]);
                this.endDate.updateValueAndValidity();
            }
        }
        if (changes.touched && !changes.touched.firstChange) {
            this.touched = changes.touched.currentValue;
            if (this.touched) {
                this.form.markAllAsTouched();
            }
            else if (this.touched === false) {
                this.form.markAsUntouched();
            }
        }
    };
    FormPostComponent.prototype.handleUrl = function ($event, added) {
        var _this = this;
        if (added === void 0) { added = false; }
        if (!$event)
            return;
        var url = $event.url ? $event.url : $event;
        if (this.imageSelectedUrl) {
            var indexImage = this.imageUrl.findIndex(function (i) { return i === _this.imageSelectedUrl; });
            this.imageUrl[indexImage] = url;
            this.imageSelectedUrl = url;
            this.image.at(indexImage).setValue(url);
        }
        else {
            var imageExist = this.imageUrl.find(function (i) { return i === url; });
            if (!imageExist) {
                var index = this.imageUrl.length > 0 ? this.imageUrl.length : 0;
                this.imageUrl.push(url);
                if (!added) {
                    this.imageSelectedUrl = this.imageUrl[index];
                }
                this.image.push(new FormControl(url));
            }
        }
    };
    FormPostComponent.prototype.isRecurringEventOrOffer = function () {
        var _a;
        return !!((this.postTypeName === 'Event' || this.postTypeName === 'Offer') && ((_a = this.recurring) === null || _a === void 0 ? void 0 : _a.frecuency));
    };
    FormPostComponent.prototype.selectImage = function (i) {
        this.imageSelectedUrl = this.imageUrl[i];
    };
    FormPostComponent.prototype.clearImagen = function () {
        var _this = this;
        this.inputUpload.reset();
        var imageIndex = this.imageUrl.findIndex(function (i) { return i === _this.imageSelectedUrl; });
        this.imageUrl = this.imageUrl.filter(function (image) { return image !== _this.imageSelectedUrl; });
        this.image.removeAt(imageIndex);
        if (this.imageUrl.length > 0)
            this.imageSelectedUrl = this.imageUrl[this.imageUrl.length - 1];
        else
            this.imageSelectedUrl = null;
        this.handleUrl(null);
    };
    FormPostComponent.prototype.addNewImage = function () {
        this.imageSelectedUrl = null;
    };
    FormPostComponent.prototype.handlePercent = function ($event) {
        this.percentUpload = $event;
    };
    FormPostComponent.prototype.handleRef = function ($event) {
        this.ref = $event;
    };
    FormPostComponent.prototype.initForm = function () {
        var e_1, _a;
        var _this = this;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        this.form = this.fb.group({
            description: ['', Validators.required],
            offer: this.fb.group({})
        });
        if (this.postType !== 'ALERT') {
            this.form.addControl('image', new FormArray([]));
        }
        if (this.postType !== 'OFFER') {
            this.form.addControl('type', new FormControl(''));
            this.form.addControl('url', new FormControl(''));
            if (this.post) {
                this.type.setValue(((_b = this.post.postInfo.callToAction) === null || _b === void 0 ? void 0 : _b.actionType) || '');
                this.url.setValue(((_c = this.post.postInfo.callToAction) === null || _c === void 0 ? void 0 : _c.url) || '');
            }
            else {
                this.type.setValue(((_d = this.savedData) === null || _d === void 0 ? void 0 : _d.type) || '');
                this.url.setValue(((_e = this.savedData) === null || _e === void 0 ? void 0 : _e.url) || '');
            }
        }
        if (this.postType !== 'STANDARD' && this.postType !== 'ALERT') {
            this.form.addControl('title', new FormControl('', Validators.required));
            this.form.addControl('startDate', new FormControl('', Validators.required));
            this.form.addControl('endDate', new FormControl(this.nowDate.setHours(0, 0, 0, 0), [Validators.required]));
            if (!this.recurring) {
                this.endDate.setValidators([Validators.required, this.valitadeSameDate()]);
            }
            this.form.addControl('startTime', new FormControl('', []));
            this.form.addControl('endTime', new FormControl('', []));
            if (this.post) {
                this.title.setValue(this.post.postInfo.event && this.post.postInfo.event.title && this.post.postInfo.event.title || '');
                if ((_h = (_g = (_f = this.post.postInfo) === null || _f === void 0 ? void 0 : _f.event) === null || _g === void 0 ? void 0 : _g.schedule) === null || _h === void 0 ? void 0 : _h.startDate) {
                    this.startDate.setValue(this.dateS.dateJsonToDate(this.post.postInfo.event.schedule.startDate));
                }
                if ((_l = (_k = (_j = this.post.postInfo) === null || _j === void 0 ? void 0 : _j.event) === null || _k === void 0 ? void 0 : _k.schedule) === null || _l === void 0 ? void 0 : _l.endDate) {
                    this.endDate.setValue(this.dateS.dateJsonToDate(this.post.postInfo.event.schedule.endDate));
                }
                if ((_p = (_o = (_m = this.post.postInfo) === null || _m === void 0 ? void 0 : _m.event) === null || _o === void 0 ? void 0 : _o.schedule) === null || _p === void 0 ? void 0 : _p.startTime) {
                    this.startTime.setValue(this.dateS.jsonTimeToTime(this.post.postInfo.event.schedule.startTime));
                }
                if ((_s = (_r = (_q = this.post.postInfo) === null || _q === void 0 ? void 0 : _q.event) === null || _r === void 0 ? void 0 : _r.schedule) === null || _s === void 0 ? void 0 : _s.endTime) {
                    this.endTime.setValue(this.dateS.jsonTimeToTime(this.post.postInfo.event.schedule.endTime));
                }
                this.timeChecked = !!(this.post.postInfo.event.schedule.startTime && this.post.postInfo.event.schedule.endTime);
            }
            else {
                this.title.setValue(this.savedData && this.savedData.title || '');
                if ((_t = this.savedData) === null || _t === void 0 ? void 0 : _t.startDate)
                    this.startDate.setValue(this.savedData.startDate);
                if ((_u = this.savedData) === null || _u === void 0 ? void 0 : _u.endDate)
                    this.endDate.setValue(this.savedData.endDate);
                if ((_v = this.savedData) === null || _v === void 0 ? void 0 : _v.startTime)
                    this.startTime.setValue(this.savedData.startTime);
                if ((_w = this.savedData) === null || _w === void 0 ? void 0 : _w.endTime)
                    this.endTime.setValue(this.savedData.endTime);
                this.timeChecked = !!(((_x = this.savedData) === null || _x === void 0 ? void 0 : _x.startTime) || ((_y = this.savedData) === null || _y === void 0 ? void 0 : _y.endTime));
            }
        }
        if (this.postType === 'OFFER') {
            this.offer.addControl('couponCode', new FormControl('', []));
            this.offer.addControl('redeemOnlineUrl', new FormControl('', [Validators.pattern(REGEX_URL)]));
            this.offer.addControl('termsConditions', new FormControl('', []));
            if (this.post) {
                this.couponCode.setValue(this.post.postInfo.offer && this.post.postInfo.offer.couponCode || '');
                this.redeemOnlineUrl.setValue(this.post.postInfo.offer && this.post.postInfo.offer.redeemOnlineUrl || '');
                this.termsConditions.setValue(this.post.postInfo.offer && this.post.postInfo.offer.termsConditions || '');
            }
            else {
                this.couponCode.setValue(this.savedData && this.savedData.offer && this.savedData.offer.couponCode || '');
                this.redeemOnlineUrl.setValue(this.savedData && this.savedData.offer && this.savedData.offer.redeemOnlineUrl || '');
                this.termsConditions.setValue(this.savedData && this.savedData.offer && this.savedData.offer.termsConditions || '');
            }
        }
        if (this.post) {
            this.description.setValue(this.post.postInfo.summary);
            // tslint:disable-next-line:max-line-length
            if (this.postType !== 'ALERT') {
                if (this.post.postInfo.media && this.post.postInfo.media.length > 0) {
                    try {
                        for (var _0 = __values(this.post.postInfo.media), _1 = _0.next(); !_1.done; _1 = _0.next()) {
                            var media = _1.value;
                            var component = {
                                url: media.sourceUrl ? media.sourceUrl : media.googleUrl ? media.googleUrl : null,
                                preview: null,
                                mediaFormat: 'PHOTO'
                            };
                            this.handleUrl(component, true);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_1 && !_1.done && (_a = _0.return)) _a.call(_0);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    var imagePost = this.post.postInfo.media[this.post.postInfo.media.length - 1];
                    this.imageSelectedUrl = imagePost.sourceUrl ? imagePost.sourceUrl : imagePost.googleUrl ? imagePost.googleUrl : null;
                }
            }
            this.data.emit(this.form);
            this.isValid.emit(this.form.valid);
        }
        else {
            this.description.setValue(this.savedData && this.savedData.description || '');
            if (this.postType !== 'ALERT' && ((_z = this.savedData) === null || _z === void 0 ? void 0 : _z.image))
                this.handleUrl(this.savedData.image[0] || null);
        }
        this._subscribeSafe(this.form.valueChanges, function () {
            if (_this.postType !== 'STANDARD' && _this.postType !== 'ALERT') {
                var momentStartDate = moment(_this.startDate.value);
                var momentEndDate = moment(_this.endDate.value);
                var isSame = momentEndDate.isSame(momentStartDate, 'date');
                _this.validateGreaterThanEnd();
                var isEndDateVoid = typeof (_this.form.controls.endDate.value) != "number";
                if (isEndDateVoid && isSame) {
                    if (!_this.timeChecked) {
                        _this.handleCheckTime({ checked: true });
                    }
                    _this.isDisabledTime = true;
                }
                if (!isSame) {
                    _this.isDisabledTime = false;
                }
            }
            _this.data.emit(_this.form);
            _this.isValid.emit(_this.form.valid);
        });
        if (this.touched) {
            this.form.markAllAsTouched();
        }
    };
    FormPostComponent.prototype.getData = function () {
        return this.form;
    };
    FormPostComponent.prototype.handleCheckTime = function ($event) {
        var _this = this;
        var momentStartDate = moment(this.startDate.value);
        var momentEndDate = moment(this.endDate.value);
        var isSame = momentEndDate.isSame(momentStartDate, 'date');
        if (!$event.checked && isSame)
            return;
        this.timeChecked = $event.checked;
        if ($event.checked) {
            this.startTime.setValidators([Validators.required, Validators.pattern(REGEX_24_HOURS)]);
            this.endTime.setValidators([Validators.required, Validators.pattern(REGEX_24_HOURS)]);
            if (isSame) {
                this.endTime.setValidators([Validators.required, Validators.pattern(REGEX_24_HOURS), this.valitadeGreaterTime()]);
                this.startTime.setValue('00:01');
                this.endTime.setValue('23:59');
            }
        }
        else {
            this.startTime.setValidators([Validators.pattern(REGEX_24_HOURS)]);
            this.endTime.setValidators([Validators.pattern(REGEX_24_HOURS)]);
            this.startTime.setValue('');
            this.endTime.setValue('');
        }
        this.startTime.updateValueAndValidity();
        this.endTime.updateValueAndValidity();
        this._subscribeSafe(this.form.valueChanges, function () {
            _this.data.emit(_this.form);
            _this.isValid.emit(_this.form.valid);
        });
    };
    FormPostComponent.prototype.valitadeGreaterTime = function () {
        var _this = this;
        return function (control) {
            var dateClean = new Date();
            var time = _this.startTime.value.toString().split(':');
            if (!control.value)
                return { minorDate: true };
            dateClean.setHours(time[0], time[1], 0, 0);
            var value = control.value.split(':');
            var endDate = new Date();
            endDate.setHours(value[0], value[1], 0, 0);
            if (dateClean.getTime() >= endDate.getTime()) {
                return { minorDate: true };
            }
            return null;
        };
    };
    FormPostComponent.prototype.validateGreaterThanEnd = function () {
        this.startDate.setErrors(null);
        if (this.startDate.value > this.endDate.value) {
            this.startDate.setErrors({ beforeEnd: true });
        }
        if (!this.startDate.value) {
            this.startDate.setErrors({ required: true });
        }
    };
    FormPostComponent.prototype.addPlaceHolder = function (placeHolder) {
        this.url.setValue("" + placeHolder);
    };
    Object.defineProperty(FormPostComponent.prototype, "nowDate", {
        get: function () {
            return new Date();
        },
        enumerable: true,
        configurable: true
    });
    FormPostComponent.prototype.validateEqualDate = function () {
        var dateClean = this.date;
        dateClean.setHours(0, 0, 0, 0);
        var endDate = new Date(this.endDate.value);
        endDate.setHours(0, 0, 0, 0);
        if (dateClean.getTime() === endDate.getTime()) {
            var endTime = this.endTime;
            var normalDate = new Date();
            if (!endTime) {
                this.endDate.setErrors({ sameDate: true });
                return;
            }
            if (endTime.value === '') {
                this.endDate.setErrors({ sameDate: true });
                return;
            }
            if (endTime.errors) {
                this.endDate.setErrors({ sameDate: true });
                return;
            }
            var time = endTime.value.split(':');
            endDate.setHours(time[0], time[1], 0, 0);
            if (normalDate.getTime() >= endDate.getTime()) {
                this.endDate.setErrors({ sameDate: true });
                return;
            }
        }
        else if (dateClean.getTime() > endDate.getTime()) {
            this.endDate.setErrors({ sameDate: true });
            return;
        }
        this.endDate.setErrors(null);
        this.endDate.updateValueAndValidity();
    };
    FormPostComponent.prototype.valitadeSameDate = function () {
        var _this = this;
        return function (control) {
            var dateClean = new Date();
            dateClean.setHours(0, 0, 0, 0);
            var value = control.value;
            if (!value)
                return null;
            var endDate = new Date(value);
            endDate.setHours(0, 0, 0, 0);
            if (dateClean.getTime() === endDate.getTime()) {
                var endTime = _this.endTime;
                var normalDate = new Date();
                if (!endTime)
                    return { sameDate: true };
                if (endTime.errors)
                    return { sameDate: true };
                var time = endTime.value.split(':');
                endDate.setHours(time[0], time[1], 0, 0);
                if (normalDate.getTime() >= endDate.getTime())
                    return { sameDate: true };
            }
            return null;
        };
    };
    Object.defineProperty(FormPostComponent.prototype, "title", {
        get: function () {
            return this.form.get('title');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "image", {
        get: function () {
            return this.form.get('image');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "description", {
        get: function () {
            return this.form.get('description');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "type", {
        get: function () {
            return this.form.get('type');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "url", {
        get: function () {
            return this.form.get('url');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "startTime", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('startTime');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "endTime", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('endTime');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "startDate", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('startDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "endDate", {
        get: function () {
            var _a;
            return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('endDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "offer", {
        get: function () {
            return this.form.get('offer');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "couponCode", {
        get: function () {
            return this.offer.get('couponCode');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "redeemOnlineUrl", {
        get: function () {
            return this.offer.get('redeemOnlineUrl');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPostComponent.prototype, "termsConditions", {
        get: function () {
            return this.offer.get('termsConditions');
        },
        enumerable: true,
        configurable: true
    });
    FormPostComponent.prototype.changeRecurring = function ($event) {
        this.handleIsRecurring.emit($event.checked);
    };
    FormPostComponent.prototype.handleRecurringChange = function ($event) {
        this.recurringChange.emit($event);
    };
    FormPostComponent.prototype.handlepublishRecurringNowChange = function ($event) {
        this.publishRecurringNowChange.emit($event);
    };
    FormPostComponent.prototype.handleIsRecurringValid = function ($event) {
        this.isRecurringValid.emit($event);
    };
    FormPostComponent.prototype.reset = function () {
        var _this = this;
        this.inputUpload.reset();
        var imageIndex = this.imageUrl.findIndex(function (i) { return i === _this.imageSelectedUrl; });
        this.imageUrl = this.imageUrl.filter(function (image) { return image !== _this.imageSelectedUrl; });
        this.image.removeAt(imageIndex);
        this.imageSelectedUrl = null;
    };
    return FormPostComponent;
}(BaseComponent));
export { FormPostComponent };
