// dep
import { OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
// app
import { ReviewsService } from '../../services/reviews.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
var ReviewsAverageRatingComponent = /** @class */ (function () {
    function ReviewsAverageRatingComponent(_reviewsS, _loadingService, _router, _sessionS) {
        this._reviewsS = _reviewsS;
        this._loadingService = _loadingService;
        this._router = _router;
        this._sessionS = _sessionS;
        this.tooltip = null;
        this.isProgressRating = true;
    }
    ReviewsAverageRatingComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        (_a = this.refreshFilter) === null || _a === void 0 ? void 0 : _a.subscribe(function (result) {
            _this.dataPicker = result;
            _this.getData();
        });
        this.getData();
    };
    ReviewsAverageRatingComponent.prototype.ngOnChanges = function (changes) {
        if (changes.averageData) {
            this.getData();
        }
    };
    ReviewsAverageRatingComponent.prototype.getData = function () {
        var _this = this;
        var isReport = this._router.url.includes('report');
        this.isProgressRating = true;
        if (!this.locations || !this.dataPicker) {
            return;
        }
        if (this.averageData) {
            this.buildData(this.averageData);
        }
        else {
            if (isReport) {
                this.aggregationRating$ = this._reviewsS.aggregationRating(this.locations, this.dataPicker).subscribe(function (result) {
                    _this.buildData(result);
                }, function () {
                    _this.isProgressRating = false;
                });
            }
            else {
                var gid = this._sessionS.getSession().gid;
                var loc = this.locations[0];
                this.aggregationRating$ = this._reviewsS.getRatingsAndReviewsData(loc.accountId, gid, loc.locationId, this.dataPicker).subscribe(function (result) {
                    _this.buildData({
                        data: result.avg_rating,
                        labels: result.dates,
                        total: result.total_avg
                    });
                }, function () {
                    _this.isProgressRating = false;
                });
            }
        }
    };
    ReviewsAverageRatingComponent.prototype.buildData = function (result) {
        var _a, _b, _c, _d;
        // Remove extra days
        if (this.dataPicker.aggregation === 'Day') {
            var start = moment(this.dataPicker.range.start);
            var end = moment(this.dataPicker.range.end);
            var days = end.diff(start, 'days') + 1;
            var dropValues = ((_a = result.labels) === null || _a === void 0 ? void 0 : _a.length) - days;
            result['data'] = _.dropRight(result.data, dropValues);
            result['labels'] = _.dropRight(result.labels, dropValues);
        }
        this.datasetLineRating = [{ data: result.data, label: 'Rating' }];
        if ((_b = result) === null || _b === void 0 ? void 0 : _b.total) {
            this.avgRating = result.total;
        }
        else {
            this.avgRating = 0;
            var totalRating = _.isEmpty(result.data) ? 0 : result.data.reduce(function (accumulator, currentValue) { return accumulator + currentValue; });
            this.avgRating = totalRating / ((_d = (_c = this.datasetLineRating[0]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.length);
        }
        this.labelsLineRating = result.labels;
        this.isProgressRating = false;
        this._loadingService.reportAdvanced(1, 'Rating Reviews');
    };
    ReviewsAverageRatingComponent.prototype.ngOnDestroy = function () {
        var _a;
        (_a = this.aggregationRating$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    };
    return ReviewsAverageRatingComponent;
}());
export { ReviewsAverageRatingComponent };
