// dep
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// app
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { LocationService } from '../../services/location.service';
import SavedLocation from '../../constants/firestore/saved-location';
import { ServiceList } from '../../constants/google/service-list';
import { FoodMenu } from '../../constants/google/menuList';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {
  //---General
  @Input() typeDashboard: string;
  @Input() accountId: string = null;
  @Input() progress = false;
  @Output() ClickEvent = new EventEmitter<string>();
  //---Location Info type
  @Input() location: SavedLocation = null;
  @Input() locationId: string = null;
  @Input() isProgressPush = false;
  @Input() isProgressFetch = false;
  topBanner = false;
  public location$;

  // --- Services
  updateService = false;
  @Output() UpdateServices       = new EventEmitter<ServiceList[]>()
  @Output() UpdateMenuList       = new EventEmitter<FoodMenu[]>()
  @Output() RefreshButtonPressed = new EventEmitter<boolean>()
  @Output() PurgeButtonPressed   = new EventEmitter<boolean>()

  @Input() isRefreshing = false;
  @Input() isPurging = false;
  @Input() isProgressSave = false;
  @Input() isShared = false;

  session$ = this._sessionS.session$ 
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private modalService: ModalService,
    private _sessionS : SessionService
  ) {
  }

  async ngOnInit() {
    if (this.route.snapshot.parent.params.locationId) {
      if (this.locationId === null) {
        this.locationId = this.route.snapshot.parent.params.locationId;
      }
    
      const loc = await this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId)
      const {isTrial} = this._sessionS.getSession();
      if (isTrial || (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
                      loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)) {
        this.topBanner = true;
      }
    } else {
      this.topBanner = false;
    }
  }

  async updateServices() : Promise<void> {
    this.updateService = true;
    await this.locationService.saveServices(this.accountId, this.locationId).toPromise()
    await this.getServices();
  }

  async getServices() : Promise<void> {
    const loc = await this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId);
    this.UpdateServices.emit(loc.serviceList ?? []);
    this.updateService = false;
  }

  async updateMenu() : Promise<void> {
    this.updateService = true;
    await this.locationService.saveMenu(this.accountId, this.locationId)
    await this.getMenu();
  }

  async getMenu() : Promise<void> {
    const loc = await this.locationService.fetchLocation(this._sessionS.getSession().gid, this.accountId, this.locationId);
    this.UpdateMenuList.emit(loc.menuList);
    this.updateService = false;
  }

  async launcAction(action: 'lock' | string) : Promise<void> {
    if (action === 'lock') {
      const res = await this.locationService.isLocked(this.locationId).toPromise()
      if(res.locked) {
        await this.modalService.openInfoModal("Heads Up",
                                              "An identical location in our network is already locked. Please contact support.")
        return
      }
    } 
    this.ClickEvent.emit(action);
  }

  GoBack() {
    this.router.navigate(['/accounts', this.accountId, 'locations'])
  }

  refreshButtonPressed() {
    this.RefreshButtonPressed.emit();
  }

  purgeButtonPressed() {
    this.PurgeButtonPressed.emit();
  }


}
