import { __extends } from "tslib";
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
// app
import { DataTransferService } from '../../services/data-transfer.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var MainComponent = /** @class */ (function (_super) {
    __extends(MainComponent, _super);
    function MainComponent(_router, _activatedRoute, _titleS, _sessionS, _dataTransferS) {
        var _a;
        var _this = _super.call(this) || this;
        _this._router = _router;
        _this._activatedRoute = _activatedRoute;
        _this._titleS = _titleS;
        _this._sessionS = _sessionS;
        _this._dataTransferS = _dataTransferS;
        _this.domain$ = _this._sessionS.domain$;
        _this.session$ = _this._sessionS.session$;
        _this.pageNeedsSession = !!((_a = _this._activatedRoute.snapshot.data) === null || _a === void 0 ? void 0 : _a.pageNeedsSession);
        _this._subscribeSafe(_router.events, function (event) { return (event instanceof NavigationEnd) && _this._refreshContentClass(); });
        _this._subscribeSafe(_this._dataTransferS.getData(), function (reportName) { return _this._refreshContentClass(reportName); });
        _this._refreshContentClass();
        return _this;
    }
    MainComponent.prototype._refreshContentClass = function (reportName) {
        var _a, _b, _c;
        var title = (((_c = (_b = (_a = this._activatedRoute.firstChild) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.title) ||
            this._titleS.getTitle());
        var company_name = this._sessionS.getDomain().branding.company_name;
        // FIXME: Comparison against description string
        if (title == "Report") {
            if (reportName) {
                this._titleS.setTitle(reportName + " | " + company_name);
            }
        }
        else {
            this._titleS.setTitle(title + " | " + company_name);
        }
        var url = this._router.url;
        var isFullWidth = url.includes('report/');
        var isAuth = (url.includes('login') || url.includes('register') || url.includes('forgot-password'));
        var isHeader = url.includes('report/');
        var biggerPadding = url.includes('billing');
        this.contentClass = {
            '': isAuth,
            'content--full': isFullWidth,
            'content': !isFullWidth && !isAuth,
            'content-header': isHeader,
            'content--padding': biggerPadding
        };
    };
    return MainComponent;
}(BaseComponent));
export { MainComponent };
