/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./review-auto-responder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../protocol-list/protocol-list.component.ngfactory";
import * as i7 from "../protocol-list/protocol-list.component";
import * as i8 from "../../services/protocol.service";
import * as i9 from "../../services/location.service";
import * as i10 from "../../services/session.service";
import * as i11 from "../../services/report.service";
import * as i12 from "../../services/modal.service";
import * as i13 from "../../services/snackbar.service";
import * as i14 from "../protocol-log/protocol-log.component.ngfactory";
import * as i15 from "../protocol-log/protocol-log.component";
import * as i16 from "../../services/reviews.service";
import * as i17 from "../../services/loading.service";
import * as i18 from "@angular/common";
import * as i19 from "../protocol-scan/protocol-scan.component.ngfactory";
import * as i20 from "../protocol-scan/protocol-scan.component";
import * as i21 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i22 from "@angular/material/tabs";
import * as i23 from "./review-auto-responder.component";
import * as i24 from "@angular/material/dialog";
import * as i25 from "@angular/router";
var styles_ReviewAutoResponderComponent = [i0.styles];
var RenderType_ReviewAutoResponderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewAutoResponderComponent, data: {} });
export { RenderType_ReviewAutoResponderComponent as RenderType_ReviewAutoResponderComponent };
function View_ReviewAutoResponderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn--icon btn--md"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.newProtocol(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "far fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.action_button_review; _ck(_v, 3, 0, currVal_3); }); }
function View_ReviewAutoResponderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-protocol-list", [], null, [[null, "selectedLogsEmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedLogsEmit" === en)) {
        var pd_0 = ((_co.selectedLogs = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ProtocolListComponent_0, i6.RenderType_ProtocolListComponent)), i1.ɵdid(2, 8634368, [[2, 4], ["protocolsList", 4]], 0, i7.ProtocolListComponent, [i8.ProtocolService, i9.LocationService, i10.SessionService, i11.ReportService, i12.ModalService, i1.ChangeDetectorRef, i13.SnackbarService], null, { selectedLogsEmit: "selectedLogsEmit" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReviewAutoResponderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-protocol-log", [], null, null, null, i14.View_ProtocolLogComponent_0, i14.RenderType_ProtocolLogComponent)), i1.ɵdid(1, 114688, null, 0, i15.ProtocolLogComponent, [i16.ReviewsService, i8.ProtocolService, i13.SnackbarService, i12.ModalService, i17.LoadingService], { locations: [0, "locations"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵEMPTY_ARRAY; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ReviewAutoResponderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewAutoResponderComponent_5)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedLogs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReviewAutoResponderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-protocol-scan", [], null, [[null, "selectedLogs"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedLogs" === en)) {
        var pd_0 = ((_co.selectedLogs = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_ProtocolScanComponent_0, i19.RenderType_ProtocolScanComponent)), i1.ɵdid(2, 114688, [[1, 4], ["protocolScan", 4]], 0, i20.ProtocolScanComponent, [], null, { selectedLogs: "selectedLogs" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReviewAutoResponderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i21.View_MatTab_0, i21.RenderType_MatTab)), i1.ɵdid(1, 770048, [[3, 4]], 2, i22.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReviewAutoResponderComponent_3)), i1.ɵdid(5, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReviewAutoResponderComponent_4)), i1.ɵdid(7, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReviewAutoResponderComponent_6)), i1.ɵdid(9, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit === "Protocols"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit === "Activity"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.$implicit === "Scan"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ReviewAutoResponderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { protocolScan: 0 }), i1.ɵqud(671088640, 2, { protocolList: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "content__header d-flex justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [], [[4, "margin", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Review Assistant"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewAutoResponderComponent_1)), i1.ɵdid(7, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "content__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "table--shadow mb--30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center bg--white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "mat-tab-group", [["class", "w--100 mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.tabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_MatTabGroup_0, i21.RenderType_MatTabGroup)), i1.ɵdid(12, 3325952, null, 1, i22.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i22.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], { selectedIndex: [0, "selectedIndex"] }, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 3, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewAutoResponderComponent_2)), i1.ɵdid(15, 278528, null, 0, i18.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.show_action_button_review; _ck(_v, 7, 0, currVal_1); var currVal_4 = _co.selectedLogs; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.tabs; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.show_action_button_review ? "10px 0px 15px 0px" : null); _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 12).dynamicHeight; var currVal_3 = (i1.ɵnov(_v, 12).headerPosition === "below"); _ck(_v, 11, 0, currVal_2, currVal_3); }); }
export function View_ReviewAutoResponderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-review-auto-responder", [], null, null, null, View_ReviewAutoResponderComponent_0, RenderType_ReviewAutoResponderComponent)), i1.ɵdid(1, 245760, null, 0, i23.ReviewAutoResponderComponent, [i11.ReportService, i24.MatDialog, i25.ActivatedRoute, i18.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewAutoResponderComponentNgFactory = i1.ɵccf("app-review-auto-responder", i23.ReviewAutoResponderComponent, View_ReviewAutoResponderComponent_Host_0, { page: "page" }, {}, []);
export { ReviewAutoResponderComponentNgFactory as ReviewAutoResponderComponentNgFactory };
