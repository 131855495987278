// dep
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

// app
import {REPORT_TYPE} from '../../constants/firestore/report';

@Component({
  selector: 'app-rollup-report',
  templateUrl: './rollup-report.component.html',
  styleUrls: ['./rollup-report.component.scss']
})
export class RollupReportComponent implements OnInit {
  reportType: REPORT_TYPE = 'performance-rollup-report';


  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    ) {}

  ngOnInit() : void {
    this.reportType = this._route.snapshot?.routeConfig?.path?.replace('-report','') as REPORT_TYPE;
  }

}
