// dep
import { ChangeDetectionStrategy, Component, Inject, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

// app
import { ProtocolService } from '../../services/protocol.service';
import { Protocol, SAVE_COMPLETE_EDIT_PROTOCOL, SAVE_COMPLETE_EDIT_SCAN, SAVE_COMPLETE_PROTOCOL, SAVE_COMPLETE_SCAN } from '../../constants/firestore/protocol';
import AccountReport from '../../services/account-report';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PROTOCOL_SCAN_ACTION } from '../modal-stepper/modal-stepper.component';
import { ResumeCreateProtocolComponent } from '../resume-create-protocol/resume-create-protocol.component';
import { ModalService } from '../../services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-modal-create-protocol',
  templateUrl: './modal-create-protocol.component.html',
  styleUrls: ['./modal-create-protocol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCreateProtocolComponent {
  @ViewChild('resume', { static: false }) resumeComponent: ResumeCreateProtocolComponent;

  @Output() completeAll = new EventEmitter<string>();

  public formCreate: any;
  public parcial_protocol: Protocol;
  public titleModal = 'New Review Response Protocol';
  public step = 0;
  public complete1 = false;
  public step_complete2 = false;
  public step_complete4 = false;
  public responsesAdditional = new FormArray([]);
  public keywords: FormArray;
  public defaultResponse;
  public placeholders: string;
  public placeholders_array: string[];
  public locationsSelected: AccountReport[] = [];
  public isEdit = false;
  public isScan = false;
  public protocolEdit: Protocol = null;
  public progress = new BehaviorSubject<boolean>(false);
  public scan_action = true;
  public index_focus_out = 0;
  public isLoadingStep3 = false

  constructor(
    private _protocolService: ProtocolService,
    private _dialogRef: MatDialogRef<ModalCreateProtocolComponent>,
    private _sessionS: SessionService,
    private _changeDetector: ChangeDetectorRef,
    private _snackS: SnackbarService,
    private _modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.defaultResponse = new FormControl('', [Validators.required, Validators.minLength(10)]);
    if (this.data) {
      if ('data' in this.data) this.data = this.data.data;
      this.isEdit = this.data.edit ? this.data.edit : false;
      this.isScan = this.data.hasOwnProperty('scan') ? this.data.scan : true;
      if (this.isEdit) {
        this.titleModal = 'Edit Review Response Protocol';
        this.protocolEdit = this.data.protocol;
        this.defaultResponse.setValue(this.protocolEdit.response[0]);
        this.scan_action = false;
        if (this.isScan) {
          this.scan_action = true;
          this.titleModal = 'Edit Scan Review Response Protocol';
          this.parcial_protocol = this.protocolEdit;
        }
        for (let i = 1; i < this.protocolEdit.response.length; i++) {
          this.responsesAdditional.push(new FormControl(this.protocolEdit.response[i]));
        }

        if (this.protocolEdit.accounts) {
          this.locationsSelected = this.protocolEdit.accounts;
        }

      }
    }

  }


  createIsValid($event: any): void {
    if (!$event) {
      this.complete1 = $event;
      return $event;
    }
    this.formCreate = $event;
    this.complete1 = true;

    this.parcial_protocol = {
      ...this.formCreate,
      status: { replied: 0, total: 0 },
      enabled: false,
      retroactive: false
    };
    const {gid, uid} = this._sessionS.getSession();
    this.parcial_protocol.gid = gid;
    this.parcial_protocol.uid = uid;

    this.placeholders = '%username% %city% %business_name%';
    this.placeholders_array = ['%username%', '%city%', '%business_name%'];
    this.keywords = this.formCreate.triggers.keywords;
    for (let i = 0; i < this.keywords.length; i++) {
      this.placeholders = this.placeholders.concat(` %keyword%`);
      this.placeholders_array.push(` %keyword%`)
    }
  }

  addResponse(): void {
    this.responsesAdditional.push(new FormControl('', [Validators.required, Validators.minLength(10)]));
  }

  AddPlaceHolder(placeholder): void {
    const textAreas = document.getElementsByTagName('textarea');
    const text = textAreas[this.index_focus_out].value;
    if (text !== '') textAreas[this.index_focus_out].value = `${text} ${placeholder}`;
    else textAreas[this.index_focus_out].value = `${placeholder}`;
  }

  removeResponse(i: number): void {
    this.responsesAdditional.removeAt(i);
  }

  handleSelect($event: any): void {
    this.locationsSelected = $event;
  }

  current_step(e): void {
    if (e === 2) {
      this.resumeComponent.GetCounters();
    }
  }

  get complete2(): boolean {
    if (this.locationsSelected?.length === 0) {
      return false;
    }
    this.parcial_protocol.accounts = this.locationsSelected;
    this.parcial_protocol.triggers = this.formCreate.triggers;
    this.step_complete2 = true;
    return true;
  }

  get complete3(): boolean {
    return !this.isLoadingStep3;
  }

  get complete4(): boolean {
    if (!this.defaultResponse.valid) {
      this.defaultResponse.touched = true;
    }
    return this.responsesAdditional.valid && this.defaultResponse.valid;
  }

  async edit_protocol(protocol: Protocol): Promise<void> {
    const exists_protocol = await this._protocolService.verifyProtocol(protocol, this.protocolEdit.protocolId);
    if (exists_protocol?.result) {
      this.progress.next(true);
      protocol.scan = false;
      const update = await this._protocolService.update(protocol, this.protocolEdit.protocolId);
      this._protocolService.check_protocol(this.protocolEdit.gid, this.protocolEdit.protocolId).then(check_protocols => {
        this.completeAll.emit(SAVE_COMPLETE_EDIT_PROTOCOL);
        if (!check_protocols) {
          this._snackS.openError('Failed update protocol', 4000);
        }

      });
      this.completeAll.emit(SAVE_COMPLETE_EDIT_PROTOCOL);
      this._dialogRef.close(true);
    } else {
      this.duplicatedProtocolError(exists_protocol?.duplicatedProtocol);
    }
  }

  async Edit_scan_protocol(protocol: Protocol): Promise<void> {
    const exists_protocol = await this._protocolService.verifyProtocol(protocol, this.protocolEdit.protocolId);
    if (exists_protocol?.result) {
      this.progress.next(true);
      protocol.scan = true;
      await this._protocolService.update(protocol, this.protocolEdit.protocolId);
      this.completeAll.emit(SAVE_COMPLETE_EDIT_SCAN);
      this._dialogRef.close(true);
    } else {
      this.duplicatedProtocolError(exists_protocol?.duplicatedProtocol);
    }
  }

  async Save_new_protocol(protocol: Protocol): Promise<void> {
    const no_exists_protocol = await this._protocolService.verifyProtocol(protocol);
    if (no_exists_protocol?.result) {
      this.progress.next(true);
      const saved = await this._protocolService.save(protocol, false);
      if (!saved) {
        this._snackS.openError('Failed save protocol', 2500);
      }
      this.completeAll.emit(SAVE_COMPLETE_PROTOCOL);
      this._dialogRef.close(true);
    } else {
      this.duplicatedProtocolError(no_exists_protocol?.duplicatedProtocol);
    }
  }


  async Save_scan_protocol(protocol: Protocol): Promise<void> {
    const no_exists_protocol = await this._protocolService.verifyProtocol(protocol);
    if (no_exists_protocol?.result) {
      this.progress.next(true);
      const saved = await this._protocolService.save(protocol, true);
      if (!saved) {
        this._snackS.openError('Failed save scan protocol', 2500);
      }
      this.completeAll.emit(SAVE_COMPLETE_SCAN);
      this._dialogRef.close(true);
    } else {
      this.duplicatedProtocolError(no_exists_protocol?.duplicatedProtocol);
    }
  }

  duplicatedProtocolError(protocol: Protocol): void {
    this._snackS.openError(
      `Looks like one or more locations already exist in another Protocol with the same Triggers.` 
      + ` Please check ${protocol?.name !== undefined ? '"' + protocol?.name + '"' : 'the existing Protocols'}.`,
      4000);
  }

  async completed(typeAction: number): Promise<void> {
    if (this.locationsSelected?.length === 0) {
      return;
    }

    if (!this.responsesAdditional.valid || (!this.defaultResponse.valid && typeAction !== PROTOCOL_SCAN_ACTION)) return;

    const responses: string[] = [this.defaultResponse.value];
    const fusion = responses.concat(this.responsesAdditional.value);
    const protocol: Protocol = {
      ...this.formCreate,
      response: fusion,
      status: { replied: this.resumeComponent.Counters.Replied, total: this.resumeComponent.Counters.Total },
      enabled: false,
      retroactive: false
    };

    const {gid, uid} = this._sessionS.getSession();
    protocol.gid = gid;
    protocol.uid = uid;
    protocol.accounts = this.locationsSelected;

    if(protocol?.withOutComment) {
      protocol.triggers.keywords = [];
    }

    if (this.isEdit) {
      this._modalService.openConfirmModal(
        'Notice',
        'Editing an autoresponder will disable it as well as the retroactive. Make sure to re-enable it afterwards.',
        response => {
          if (!response) return;

          if (this.protocolEdit) {
            if (typeAction === PROTOCOL_SCAN_ACTION) {
              this.Edit_scan_protocol(protocol);
            } else {
              this.edit_protocol(protocol);
            }
          } else {
            if (typeAction === PROTOCOL_SCAN_ACTION) {
              this.Save_scan_protocol(protocol);
            } else {
              this.Save_new_protocol(protocol);
            }
          }
        },
        AlertType.INFO,
        'Ok'
      );
    } else {
      if (this.protocolEdit) {
        if (typeAction === PROTOCOL_SCAN_ACTION) {
          this.Edit_scan_protocol(protocol);
        } else {
          this.edit_protocol(protocol);
        }
      } else {
        if (typeAction === PROTOCOL_SCAN_ACTION) {
          this.Save_scan_protocol(protocol);
        } else {
          this.Save_new_protocol(protocol);
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    this._changeDetector.detectChanges();
  }

  onStepChange(event: number): void {
    this.step = event;

    if (this.step === 2) {
      this.isLoadingStep3 = true;
    }
  }

  loadingStep3(event) {
    this.isLoadingStep3 = false;
  }

  get disabledNextStep(): boolean {
    return !(
      this.step === 0 ? this.complete1 && !this.progress.value : 
      this.step === 1 ? this.complete2 && !this.progress.value : 
      this.step === 2 ? this.complete3 && !this.progress.value :
      this.step === 3 ? this.complete4 && !this.progress.value : true
    );
  }
}
