import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

export interface MultipleLocations {
  id: number;
  ranking: number;
  location: any;
  directionRequest: number;
  mobilePhoneCalls: number;
  website: number;
  localPostActions: number;
  localPostViews: number;
  customerPhotoCount: number;
  merchantPhotoCount: number;
  customerPhotoViews: number;
  merchantPhotoViews: number;
  directSearches: number;
  discoverySearches: number;
  mapViews: number;
  searchViews: number;
  numOfReviews: number;
}
const MULTIPLELOCATION_DATA: MultipleLocations[] = [
  {id: 1, ranking: 1, location: { name: 'Codeart', address: 'Bulevar Ilinded'}, directionRequest: 0, mobilePhoneCalls: 0, website: 1, localPostActions: 0, localPostViews: 1, customerPhotoCount: 10, merchantPhotoCount: 2, customerPhotoViews: 30, merchantPhotoViews: 20, directSearches: 100, discoverySearches: 200, mapViews: 10, searchViews: 1, numOfReviews: 3},
  {id: 2, ranking: 2, location: { name: 'Codeart', address: 'Bulevar Ilinded'}, directionRequest: 0, mobilePhoneCalls: 0, website: 1, localPostActions: 0, localPostViews: 1, customerPhotoCount: 10, merchantPhotoCount: 2, customerPhotoViews: 30, merchantPhotoViews: 20, directSearches: 100, discoverySearches: 200, mapViews: 10, searchViews: 1, numOfReviews: 3},
  {id: 3, ranking: 3, location: { name: 'Codeart', address: 'Bulevar Ilinded'}, directionRequest: 0, mobilePhoneCalls: 0, website: 1, localPostActions: 0, localPostViews: 1, customerPhotoCount: 10, merchantPhotoCount: 2, customerPhotoViews: 30, merchantPhotoViews: 20, directSearches: 100, discoverySearches: 200, mapViews: 10, searchViews: 1, numOfReviews: 3},
  {id: 4, ranking: 4, location: { name: 'Codeart', address: 'Bulevar Ilinded'}, directionRequest: 0, mobilePhoneCalls: 0, website: 1, localPostActions: 0, localPostViews: 1, customerPhotoCount: 10, merchantPhotoCount: 2, customerPhotoViews: 30, merchantPhotoViews: 20, directSearches: 100, discoverySearches: 200, mapViews: 10, searchViews: 1, numOfReviews: 3},
  {id: 5, ranking: 5, location: { name: 'Codeart', address: 'Bulevar Ilinded'}, directionRequest: 0, mobilePhoneCalls: 0, website: 1, localPostActions: 0, localPostViews: 1, customerPhotoCount: 10, merchantPhotoCount: 2, customerPhotoViews: 30, merchantPhotoViews: 20, directSearches: 100, discoverySearches: 200, mapViews: 10, searchViews: 1, numOfReviews: 3},
];

@Component({
  selector: 'app-revenue-report-insight',
  templateUrl: './revenue-report-insight.component.html',
  styleUrls: ['./revenue-report-insight.component.scss']
})
export class RevenueReportInsightComponent implements OnInit, AfterViewInit  {

  // data for table
  multipleLocationsData: any;
  displayedColumns: string[];
  dataSource: any;
  // custom pagination elements
  manualPage: number;
  errorMessage: boolean;
  // custom pagination with page numbers
  @Input() page: number;
  @Input() totalPages: number;
  // events for custom pagination
  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<boolean>();

  @ViewChild(MatPaginator, { read: MatPaginator, static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { read: MatSort, static: true }) sort: MatSort;
  constructor(public dialog: MatDialog, private detectStrategy: ChangeDetectorRef) {
    this.multipleLocationsData = MULTIPLELOCATION_DATA;
  }

  ngOnInit() {
    this._updateTable();
  }
  ngAfterViewInit() {
    this.manualPage = 1;
    this.errorMessage = false;
    this.detectStrategy.detectChanges();
  }

  _updateTable() {
    // data for table
    this.displayedColumns = ['ranking', 'location', 'directionRequest', 'mobilePhoneCalls', 'website', 'localPostActions', 'localPostViews', 'customerPhotoCount', 'merchantPhotoCount', 'customerPhotoViews', 'merchantPhotoViews', 'directSearches', 'discoverySearches', 'mapViews', 'searchViews', 'numOfReviews'];
    this.dataSource = new MatTableDataSource<MultipleLocations>(MULTIPLELOCATION_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // change page manually
  updateManualPage(index: number): void {
    index = +index;
    const totalPages = Math.ceil(this.paginator.length / this.paginator.pageSize);
    this.manualPage = index;
    if (index <= totalPages && index !== 0) {
      this.paginator.pageIndex = index - 1;
      this.errorMessage = false;
    } else {
      this.paginator.pageIndex = totalPages - 1;
      this.errorMessage = true;
    }
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex - 1,
      pageSize: this.paginator.pageSize - 1,
      length: this.paginator.length - 1
    });
  }
  getPages(): number[] {
    const pageLength = Math.ceil(this.paginator.length / this.paginator.pageSize);
    const currentPage = this.paginator.pageIndex || 1;
    const totalPages = this.paginator.length;
    const pages: number[] = [];
    pages.push(currentPage);

    for (let i = 0; i < totalPages - 1; i++) {
      if (pages.length < totalPages) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
        if (Math.max.apply(null, pages) < pageLength) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }
  lastPage(): boolean {
    return this.paginator.pageSize * (this.paginator.pageIndex + 1) >= this.paginator.length;
  }
  onPage(index: number): void {
    this.paginator.pageIndex = index;
    this.updateManualPage(this.paginator.pageIndex);
  }
  onPrev(): void {
    this.updateManualPage(this.paginator.pageIndex);
  }
  onNext(): void {
    this.paginator.pageIndex += 2;
    this.updateManualPage(this.paginator.pageIndex);
  }

}
