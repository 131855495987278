import { __awaiter, __generator } from "tslib";
// app
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "@angular/router";
var BulkActionsGuardService = /** @class */ (function () {
    function BulkActionsGuardService(_sessionS, _router) {
        this._sessionS = _sessionS;
        this._router = _router;
    }
    BulkActionsGuardService.prototype.canActivate = function (_route) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        if (!(_a.sent()).bulkActionsEnabled) {
                            console.log('BulkActionsGuardService#canActivate: bulk actions not enabled');
                            this._router.navigate(['/settings/billing']);
                            return [2 /*return*/, false];
                        }
                        else {
                            console.log('BulkActionsGuardService#canActivate: bulk actions enabled');
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BulkActionsGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BulkActionsGuardService_Factory() { return new BulkActionsGuardService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.Router)); }, token: BulkActionsGuardService, providedIn: "root" });
    return BulkActionsGuardService;
}());
export { BulkActionsGuardService };
