import { __awaiter, __extends, __generator } from "tslib";
import { MatDialogRef } from '@angular/material/dialog';
// app
import { GoogleAccountService } from '../services/google-account.service';
import { GoogleService } from '../services/google.service';
import { SnackbarService } from '../services/snackbar.service';
import { ReviewsService } from '../services/reviews.service';
import { SessionService } from '../services/session.service';
import { BaseComponent } from './base.component';
var AutoReplyReviewErrorComponent = /** @class */ (function (_super) {
    __extends(AutoReplyReviewErrorComponent, _super);
    function AutoReplyReviewErrorComponent(data, dialogRef, googleAccountService, google, snack, reviewService, _sessionS) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this.googleAccountService = googleAccountService;
        _this.google = google;
        _this.snack = snack;
        _this.reviewService = reviewService;
        _this._sessionS = _sessionS;
        _this.review = {};
        _this.user = _this._sessionS.getSession().user;
        _this.setUp();
        _this.review = data.data;
        return _this;
    }
    AutoReplyReviewErrorComponent.prototype.setUp = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AutoReplyReviewErrorComponent.prototype.getError = function (error) {
        if (error) {
            switch (error) {
                case 'INVALID_ARGUMENT':
                    return error.message + " " + error.details[0].errorDetails[0].message;
                default:
                    return error.message;
            }
        }
        return error.errorMessage;
    };
    AutoReplyReviewErrorComponent.prototype.openReauth = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.onAddNewAccount(element)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoReplyReviewErrorComponent.prototype.replyReview = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var r, accountId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        r = this.review;
                        accountId = r.name.split('/')[1];
                        return [4 /*yield*/, this.reviewService.reply_with_errors(accountId, r.locationId, r.reviewId, r.comment, r.protocolId, r.replyId).toPromise()];
                    case 1:
                        _a.sent();
                        this.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoReplyReviewErrorComponent.prototype.onAddNewAccount = function (element) {
        return __awaiter(this, void 0, void 0, function () {
            var url, oauth_1, popupTick_1, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._subscribeSafe(this.googleAccountService.onLoadAll$, function (ev) {
                            if (ev.success !== true) {
                                return _this.snack.openError(ev.message);
                            }
                            else {
                                var accountId = _this.data.name.split('/')[1];
                                _this.reviewService.reply_with_errors(accountId, _this.review.locationId, _this.review.reviewId, _this.review.comment, _this.review.protocolId, _this.review.replyId).toPromise()
                                    .then(function (d) {
                                    if (d == '') {
                                        _this.snack.openInfo("The reply can't send, please edit it and send again", 2000);
                                    }
                                    _this.snack.openInfo("The reply can't send, please edit it and send again", 2000);
                                    _this.dialogRef.close();
                                });
                            }
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 2000);
                        return [4 /*yield*/, this.google.authenticate(element.gid || this.user.gid, this.user.uid, element.accountId)];
                    case 2:
                        url = _a.sent();
                        oauth_1 = window.open(url, '_blank');
                        popupTick_1 = setInterval(function () {
                            if (oauth_1.closed) {
                                clearInterval(popupTick_1);
                                _this.googleAccountService.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        // This popup ends up being a redirection so we cannot detect the real close event.
                        // So we use an interval trick to overcome this, with an event
                        return [2 /*return*/, this.snack.openError('There was an error with the GBP Authentication service')];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AutoReplyReviewErrorComponent;
}(BaseComponent));
export { AutoReplyReviewErrorComponent };
