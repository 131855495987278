import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GradeLoadedService {

  public loaded$ = new BehaviorSubject<boolean>(false);

}
