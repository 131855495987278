import { __awaiter, __extends, __generator, __read, __spread, __values } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDrawer, MatTableDataSource } from '@angular/material';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
// app
import { ObservationService } from '../../services/observation.service';
import { AccountService } from '../../services/account.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ModalService } from '../../services/modal.service';
import { LocationService } from '../../services/location.service';
import { SpinnerService } from '../../services/spinner.service';
import { GroupService } from '../../services/group.service';
import { ProfilesService } from '../../services/profiles/profiles.service';
import { AccountContainer, LocationModel, ProfileModel } from '../../lib/api-requests/create-profile-request';
import { GoogleAccountService } from '../../services/google-account.service';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleLocationService } from '../../services/google-location.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { locationNameToRef } from 'src/app/constants/firestore/location-object';
import { markedPositions } from 'src/app/helpers/utils.helpers';
var ProfilesComponent = /** @class */ (function (_super) {
    __extends(ProfilesComponent, _super);
    function ProfilesComponent(router, _sessionS, obsService, accountService, modalService, profilesService, locationService, spinnerService, groupService, googleAccountService, snackbarService, googleService, _googleLocationS) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this._sessionS = _sessionS;
        _this.obsService = obsService;
        _this.accountService = accountService;
        _this.modalService = modalService;
        _this.profilesService = profilesService;
        _this.locationService = locationService;
        _this.spinnerService = spinnerService;
        _this.groupService = groupService;
        _this.googleAccountService = googleAccountService;
        _this.snackbarService = snackbarService;
        _this.googleService = googleService;
        _this._googleLocationS = _googleLocationS;
        _this.DEFAULT_PAGE_SIZE = 10;
        _this.loadingTable = true;
        _this.allChecked = false;
        _this.displayedColumns = [];
        _this.locations = new MatTableDataSource([]);
        _this.paginate = { size: _this.DEFAULT_PAGE_SIZE, page: 1 };
        _this.pagination = {
            items: [],
            per_page: _this.paginate.size,
            page: _this.paginate.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        _this.sort = {
            sortBy: 'locationName',
            sortOrder: 'desc'
        };
        _this.filterAccountsControl = new FormControl('');
        _this.addProfileFilterAccountsControl = new FormControl('');
        _this.accountsOptions = [];
        _this.labelAccountsFiltered = 'All';
        _this.filteredAccounts = [];
        _this.allLocationCheck = false;
        _this.selectedLocations = [];
        _this.drawerOpened = false;
        _this.googleAccounts = [];
        _this.profilesOptions = [];
        _this.accountProfilesOptions = [];
        _this.addingProfile = false;
        _this.profileStatus = {
            'verified': 'verified',
            'pending_edits': 'pending edits',
            'not_verified': 'not verified',
            'verified_locked': 'verified & locked',
        };
        _this.session$ = _this._sessionS.session$;
        return _this;
    }
    ProfilesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._formatAdminColumns();
        this.loadAccounts();
        this.filteredOptions = this.filterAccountsControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value || ''); }));
        this.addProfileFilterSelected = this.addProfileFilterAccountsControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value || ''); }));
    };
    ProfilesComponent.prototype._formatAdminColumns = function () {
        if (this._sessionS.getSession().isAdmin) {
            this.displayedColumns = ['check', 'company', 'storeCode', 'status', 'actions', 'subscription'];
        }
        else {
            this.displayedColumns = ['check', 'company', 'storeCode', 'status', 'subscription'];
        }
    };
    ProfilesComponent.prototype.loadAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, _a, _b, _c, acc;
            var e_1, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        _a = this;
                        return [4 /*yield*/, this.accountService.getAccountPaginate(gid, { page: 1, size: 1000 }, [])];
                    case 1:
                        _a.accounts = (_e.sent()).items;
                        try {
                            for (_b = __values(this.accounts), _c = _b.next(); !_c.done; _c = _b.next()) {
                                acc = _c.value;
                                acc.checked = false;
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        this.accountsOptions = __spread(this.accounts);
                        this.loadProfilesTable(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.loadProfilesTable = function (resetPaginator) {
        var _this = this;
        if (resetPaginator === void 0) { resetPaginator = false; }
        this.loadingTable = true;
        if (resetPaginator) {
            this.pagination = {
                items: [],
                per_page: this.DEFAULT_PAGE_SIZE,
                page: 1,
                hasNext: false,
                hasPrev: false,
                pages: 0,
                total: 0
            };
        }
        var accountsForSearch = this.filteredAccounts.length > 0 ? this.filteredAccounts : this.accounts;
        // FIXME: This is wrong, must be an array of {gid,accountIds,locationIds}, also fix
        // on backend. Currently the response is too inclusive. 
        var profileRequest = {
            gids: __spread(new Set(accountsForSearch.map(function (item) { return item.gid; }))),
            accountIds: __spread(new Set(accountsForSearch.map(function (item) { return item.accountId; }))),
            locationIds: [],
            page: this.pagination.page,
            pageSize: this.pagination.per_page,
            sortKey: "locationName",
            reverse: false
        };
        if (accountsForSearch.length > 0) {
            this.profilesService.getProfiles(profileRequest)
                .subscribe(function (result) {
                var d = result.data;
                _this.locations = new MatTableDataSource(d.items);
                _this.pagination.items = d.items;
                _this.pagination.total = d.total;
                _this.pagination.hasNext = d.hasNext;
                _this.pagination.hasPrev = d.hasPrev;
                _this.pagination.page = d.page;
                _this.pagination.per_page = d.pageSize;
                _this.pagination.pages = d.totalPages;
                _this.loadingTable = false;
            });
        }
    };
    ProfilesComponent.prototype.onAddAccount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.router.navigate(['profiles'])];
                    case 1:
                        _a.sent();
                        this.obsService.sendAddAccount();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.actionSelected = function (event) {
        console.log(event);
    };
    ProfilesComponent.prototype.goLocation = function (location) {
        this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights']);
    };
    ProfilesComponent.prototype.toggleCheckAll = function (event) {
        if (event.checked) {
            this.selectAll();
        }
        else {
            this.clearAll();
        }
    };
    ProfilesComponent.prototype.selectLocation = function (element, event) {
        if (event.checked) {
            this.selectedLocations.push(element);
        }
        else {
            this.selectedLocations = this.selectedLocations.filter(function (item) { return item.locationId !== element.locationId; });
        }
    };
    ProfilesComponent.prototype.selectAll = function () {
        var _this = this;
        this.locations.data.forEach(function (element) {
            element.isChecked = true;
            _this.selectedLocations.push(element);
        });
    };
    ProfilesComponent.prototype.clearAll = function () {
        this.locations.data.forEach(function (element) {
            element.isChecked = false;
        });
        this.selectedLocations = [];
    };
    ProfilesComponent.prototype.refresh = function (profile) {
        var gid = this._sessionS.getSession().gid;
        this.locationService.locationRefreshAllDeps(profile.accountId, profile.locationId, gid);
    };
    ProfilesComponent.prototype.lockSelectedLocations = function () {
        var _this = this;
        var anyIsAlreadyLocked = false;
        var observables = this.selectedLocations.map(function (profile) {
            return _this.locationService.isLocked(profile.locationId);
        });
        forkJoin(observables).subscribe(function (results) {
            for (var i = 0; i < results.length; i++) {
                var res = results[i];
                var profile = _this.selectedLocations[i];
                if (res && res.locked) {
                    anyIsAlreadyLocked = true;
                }
            }
            if (anyIsAlreadyLocked) {
                var dialogRef = _this.modalService.openInfoModal("Heads Up", "There is identical location in our network is already locked. Try a different selection or contact support.");
            }
            else {
                _this.modalService.openConfirmModal('Are you sure you want to lock these locations?', ('Locking a location will periodically update GBP with the locked data. ' +
                    'Any changes you make directly in GBP will be overwritten.'), function (res) {
                    if (!res) {
                        return;
                    }
                    var observables = _this.selectedLocations.map(function (profile) {
                        return _this.locationService.update(_this._sessionS.getSession().gid, profile.accountId, profile.locationId, { lockedOn: new Date() }).pipe(switchMap(function (loc) {
                            return _this.googleService.saveLockHistory(profile.accountId, profile.locationId, 'locked', 'success');
                        }));
                    });
                    forkJoin(observables).subscribe(function () {
                        _this.loadProfilesTable();
                        _this.snackbarService.openSuccess('Locations successfully locked!', 2000);
                    }, function (err) {
                        _this.loadProfilesTable();
                        _this.snackbarService.openError('There was a problem locking these locations. Please try again later or contact support.', 2000);
                        console.error('Error locking a location', err);
                    });
                }, 2);
            }
        });
    };
    ProfilesComponent.prototype.lockLocation = function (profile) {
        var _this = this;
        this.locationService.isLocked(profile.locationId)
            .subscribe(function (res) {
            if (res.locked) {
                var dialogRef = _this.modalService.openInfoModal("Heads Up", "An identical location in our network is already locked. Please contact support.");
            }
            if (res && !res.locked) {
                // this.progress = true;
                _this.modalService.openConfirmModal('Are you sure you want to lock this location?', 'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.', function (res) {
                    if (!res) {
                        return;
                    }
                    _this.locationService.update(_this._sessionS.getSession().gid, profile.accountId, profile.locationId, { lockedOn: new Date() }).toPromise().then(function (loc) {
                        _this.googleService.saveLockHistory(profile.accountId, profile.locationId, 'locked', 'success').subscribe(function (result) {
                            _this.loadProfilesTable();
                            _this.snackbarService.openSuccess('Location successfully locked!', 2000);
                        });
                    }, function (err) {
                        _this.snackbarService.openError('There was a problem locking this location. Please try again later or contact support.', 2000);
                        console.error('Error locking a location', err);
                    });
                }, 2);
            }
        });
    };
    ProfilesComponent.prototype.unlockLocation = function (profile) {
        var _this = this;
        this.locationService.update(this._sessionS.getSession().gid, profile.accountId, profile.locationId, { lockedOn: null })
            .toPromise()
            .then(function (loc) {
            _this.googleService.saveLockHistory(profile.accountId, profile.locationId, 'unlock', 'success')
                .subscribe(function (result) {
                _this.loadProfilesTable();
                _this.snackbarService.openSuccess('Location successfully unlocked!', 2000);
            });
        }, function (err) {
            _this.snackbarService.openError('There was a problem unlocking this location. Please try again later or contact support.', 2000);
            console.error('Error unlocking a location', err);
        });
    };
    ProfilesComponent.prototype.deleteBulk = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid_1, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.selectedLocations.length) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        this.spinnerService.loading$.next(true);
                        gid_1 = this._sessionS.getSession().gid;
                        return [4 /*yield*/, Promise.all(markedPositions(this.selectedLocations).map(function (e) { return _this.locationService.deleteLocation(gid_1, e.value.accountId, e.value, { notifyChange: e.isLast, deleteEmptyAccount: e.isLast }); }))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        err_1 = _a.sent();
                        console.error('Error deleting locations', err_1);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.loadAccounts()];
                    case 5:
                        _a.sent();
                        this.spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.deleteLocationModal = function (location) {
        return __awaiter(this, void 0, void 0, function () {
            var gid, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!location)
                            return [2 /*return*/];
                        if (!this.modalService.openConfirmModal("Are you sure you want to delete " + location.locationName + " ?", 'This action cannot be reverted.')) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        this.spinnerService.loading$.next(true);
                        gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this.locationService.deleteLocation(gid, location.accountId, location)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        err_2 = _a.sent();
                        console.error("Error deleting location", err_2);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.loadAccounts()];
                    case 5:
                        _a.sent();
                        this.spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.onMatDrawerOpenedChange = function (event) {
        this.drawerOpened = event;
    };
    ProfilesComponent.prototype.toggleDrawer = function () {
        this.drawer.toggle();
        this.drawerOpened = this.drawer.opened;
    };
    ProfilesComponent.prototype.addPhotos = function () {
        this.router.navigate(['local-bulk']);
    };
    ProfilesComponent.prototype.goToLocation = function (location) {
        this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights']);
    };
    ProfilesComponent.prototype.goToLocationNewTab = function (location) {
        var url = this.router.serializeUrl(this.router.createUrlTree(['/account', location.accountId, 'location', location.locationId, 'insights']));
        window.open(url, '_blank');
    };
    ProfilesComponent.prototype.editLocation = function (element) {
    };
    ProfilesComponent.prototype.handleReload = function ($event) {
        this.paginate = $event;
        this.loadProfilesTable();
    };
    ProfilesComponent.prototype.filterAccountChanged = function (menu, clear) {
        if (clear || this.filteredAccounts.length === 0) {
            this.accounts.forEach(function (acc) { return acc.checked = false; });
            this.labelAccountsFiltered = 'All';
            this.filteredAccounts = [];
        }
        this.filterAccountsControl.setValue(null);
        menu.close.next(false);
        var resetPaginator = true;
        this.loadProfilesTable(resetPaginator);
    };
    ProfilesComponent.prototype._filter = function (value) {
        var _this = this;
        var _a, _b;
        this.accountsOptions = [];
        var filterValue = (_a = value) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (value != '') {
            (_b = this.accounts) === null || _b === void 0 ? void 0 : _b.forEach(function (acc) {
                var name = acc.accountName.toLowerCase();
                if (name.includes(filterValue)) {
                    _this.accountsOptions.push(acc);
                }
            });
        }
        else {
            this.accountsOptions = __spread(this.accounts);
        }
    };
    ProfilesComponent.prototype.getLocationsFiltered = function (event, account) {
        var _this = this;
        this.filteredAccounts = [];
        this.labelAccountsFiltered = null;
        this.accounts.forEach(function (acc) {
            acc.checked = acc.accountId == account.account ? event.checked : acc.checked;
            if (acc.checked) {
                _this.filteredAccounts.push(acc);
                _this.labelAccountsFiltered = _this.labelAccountsFiltered ? _this.labelAccountsFiltered + " - " + acc.accountName : acc.accountName;
            }
        });
    };
    ProfilesComponent.prototype.checkAllAccounts = function (event) {
        this.googleAccounts.forEach(function (element) {
            element.checked = event.checked;
        });
    };
    ProfilesComponent.prototype.checkProfiles = function (event, locations) {
        locations.forEach(function (element) {
            element.checked = event.checked;
        });
    };
    ProfilesComponent.prototype.hasAnySelectedAccount = function () {
        var result = false;
        this.googleAccounts.forEach(function (element) {
            if (element.checked) {
                result = true;
            }
        });
        return result;
    };
    ProfilesComponent.prototype.hasAnySelectedProfile = function () {
        var result = false;
        this.googleAccounts.forEach(function (element) {
            var _a, _b;
            (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.profiles) === null || _b === void 0 ? void 0 : _b.forEach(function (profile) {
                if (profile.checked) {
                    result = true;
                }
            });
        });
        return result;
    };
    ProfilesComponent.prototype.openAccountExpansionPanel = function (account) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var locations;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.groupService.get_locations(this._sessionS.getSession().gid, account.accountId)];
                    case 1:
                        locations = _b.sent();
                        if (!locations)
                            return [2 /*return*/];
                        (_a = this.googleAccounts) === null || _a === void 0 ? void 0 : _a.forEach(function (option) {
                            if (!option.locations) {
                                option.locations = [];
                            }
                            if (option.locations) {
                                locations.forEach(function (loc) {
                                    if (option.accountId === loc.accountId) {
                                        option.locations.push(loc);
                                    }
                                });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.addNewProfiles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, gid, uid, url, oauth_1, popupTick_1, e_2, message;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.da = null;
                        this.dl = null;
                        this._subscribeSafe(this.googleAccountService.onLoadAll$, function (ev) {
                            if (ev.success !== true) {
                                _this.snackbarService.openError(ev.message);
                                return;
                            }
                            // Everything looks good...
                            // Prevent multiple dialogs from being opened
                            !_this.da && _this.toggleDrawer();
                            _this._subscribeSafe(_this.googleAccountService.accounts$, function (accounts) {
                                accounts.forEach(function (element) {
                                    element.checked = false;
                                });
                                _this.googleAccounts = accounts;
                            });
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.snackbarService.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 2000);
                        _a = this._sessionS.getSession(), gid = _a.gid, uid = _a.uid;
                        return [4 /*yield*/, this.googleService.authenticate(gid, uid)];
                    case 2:
                        url = _b.sent();
                        oauth_1 = window.open(url, '_blank');
                        popupTick_1 = setInterval(function () {
                            var _a;
                            if ((_a = oauth_1) === null || _a === void 0 ? void 0 : _a.closed) {
                                clearInterval(popupTick_1);
                                _this.googleAccountService.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        message = 'There was an error with the GBP Authentication service';
                        console.error(message, e_2);
                        this.snackbarService.openError(message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProfilesComponent.prototype.loadStep2Profiles = function () {
        var _this = this;
        this.profilesOptions = [];
        this.googleAccounts.forEach(function (account) {
            if (account.checked) {
                _this._googleLocationS.loadAll(account.name);
            }
        });
        // FIXME: Multiple subscriptions, every click adds one
        this._subscribeSafe(this._googleLocationS.locations$, function (locations) {
            var _a;
            var accountName = '';
            if (locations && locations.length > 0 && ((_a = locations[0]) === null || _a === void 0 ? void 0 : _a.name)) {
                var _b = __read(locations[0].name.split('/'), 2), firstPart = _b[0], secondPart = _b[1];
                if (firstPart && secondPart) {
                    accountName = firstPart + "/" + secondPart;
                }
            }
            var account = _this.googleAccounts.find(function (_) { return _.name === accountName; });
            if (account) {
                account.profiles = locations;
            }
        });
    };
    ProfilesComponent.prototype.checkedGoogleAccounts = function () {
        var _a;
        if ((_a = this.googleAccounts) === null || _a === void 0 ? void 0 : _a.length)
            return this.googleAccounts.filter(function (_) { return _.checked; });
        return [];
    };
    ProfilesComponent.prototype.addProfiles = function () {
        var _this = this;
        this.addingProfile = true;
        var request = {
            profiles: []
        };
        this.googleAccounts.forEach(function (account) {
            var _a, _b;
            if (account.checked) {
                var accountId_1 = account.name.split('/')[1];
                var profileModel_1 = new ProfileModel();
                var accountContainer = new AccountContainer();
                accountContainer.gid = _this._sessionS.getSession().gid;
                accountContainer.accountId = accountId_1;
                accountContainer.accountName = account.accountName;
                accountContainer.account =
                    {
                        name: account.name,
                        accountName: account.accountName,
                        type: account.type,
                        role: account.role,
                        verificationState: account.verificationState,
                        vettedState: account.vettedState,
                        accountNumber: account.accountNumber,
                        permissionLevel: account.permissionLevel,
                    };
                accountContainer.googleAuthEmailAddress = _this._sessionS.getSession().user.email;
                accountContainer.gauthStatus = { isValid: true };
                accountContainer.googleAuth = _this._sessionS.getSession().user.googleAuth;
                profileModel_1.account = accountContainer;
                profileModel_1.locations = [];
                (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.profiles) === null || _b === void 0 ? void 0 : _b.forEach(function (profile) {
                    if (profile.checked) {
                        var locationContainer = new LocationModel();
                        locationContainer.accountId = accountId_1;
                        locationContainer.gid = _this._sessionS.getSession().gid;
                        locationContainer.locationId = locationNameToRef(profile).locationId;
                        locationContainer.locationName = profile.locationName;
                        locationContainer.lockedOn = null;
                        locationContainer.subscriptionType = LOCATION_SUBSCRIPTION_TYPE.FREE;
                        profileModel_1.locations.push(locationContainer);
                    }
                });
                request.profiles.push(profileModel_1);
            }
        });
        this.profilesService.createProfile(request)
            .subscribe(function (response) {
            _this.addingProfile = false;
            _this.toggleDrawer();
            _this.loadAccounts();
        });
    };
    return ProfilesComponent;
}(BaseComponent));
export { ProfilesComponent };
