import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import AccountReport from '../services/account-report';

@Component({
  selector: 'app-upgrade-or-remove',
  template: `    
    <div class="dialog__header txt--center dialog__header--info">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>Select locations to Upgrade</strong></h2>
    </div>

    <div class="dialog__content" mat-dialog-content>
      <app-toggle-locations-in-account (selected)="handleLocations($event)"
                                       [checkUpgrade]="true">
      </app-toggle-locations-in-account>
    </div>
    <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
        <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel">Cancel</button>
      </span>
      <button tabIndex="-1" mat-flat-button color="accent" (click)="save()" class="btn btn--action" [disabled]="accountsSelected.length == 0">Next</button>
    </div>
  `,
})
export class UpgradeLocationsComponent {
  static readonly OPTS_DEFAULT = { config : {width: '1080px' } }

  public accountsSelected: AccountReport[] = [];
  
 
  // Note that strictNullChecks must be enabled to make TS keep the "| undefined" type
  RESULT_TYPE : UpgradeLocationsComponent['accountsSelected'] | undefined 

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {closeButtonLabel : string},
    public dialogRef: MatDialogRef<any>,
  ) {}

  save() {
    this.dialogRef.close(this.accountsSelected);
  }

  upgradeNow() {
    this.dialogRef.close();
  }

  handleLocations($event) {
    this.accountsSelected = $event;
  }

}
