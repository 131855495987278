// dep
import { Router } from '@angular/router';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ChartDataSets, ChartPoint } from 'chart.js';

// app
import { ReviewsService } from '../../services/reviews.service';
import { DataPicker } from '../../constants/data-picker';
import { LoadingService } from '../../services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reviews-number',
  templateUrl: './reviews-number.component.html',
  styleUrls: ['./reviews-number.component.scss']
})
export class ReviewsNumberComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() locations;
  @Input() dataPicker: DataPicker;
  @Input() refreshFilter : Subject<DataPicker> | undefined
  @Input() reviewsData;
  @Input() tooltip = null;

  public isProgressNumber = true;
  public datasetBarNumber: ChartDataSets[];
  public labelsBarRating: any;
  public total: any;

  public destroySubs$: Subject<boolean> = new Subject();

  constructor(
    private _reviewsS: ReviewsService,
    private _loadingService: LoadingService,
    private _router: Router,
    private _sessionS: SessionService
  ) {
    super();
  }

  ngOnInit() : void {
    if(this.refreshFilter) {
      this._subscribeSafe(this.refreshFilter, 
        result => {
          this.isProgressNumber = true;
          this.dataPicker = result;
          this.getData();
        });
    }
    
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reviewsData) {
      this.getData();
    }
  }

  getData() : void {
    const isReport = this._router.url.includes('report');
    
    if (this.reviewsData) {
      this.buildData(this.reviewsData);
    } else {
      if (isReport) {
        this._subscribeSafe(this._reviewsS.aggregationNumber(this.locations, this.dataPicker),
                            result => this.buildData(result));
      } else {
        this._subscribeSafe(this._reviewsS.getRatingsAndReviewsData(this.locations[0].accountId, this._sessionS.getSession().gid, 
                                                                    this.locations[0].locationId, this.dataPicker),

                            result => this.buildData({ data:   result.reviews,
                                                       labels: result.dates,
                                                       total:  result.total_reviews}));
      }
    }
  }

  buildData(result) : void {
    // Remove extra days
    if (this.dataPicker.aggregation === 'Day') {
      const start = moment(this.dataPicker.range.start);
      const end = moment(this.dataPicker.range.end);
      const days = end.diff(start, 'days') + 1;
      const dropValues = result.labels?.length - days;
      result['data'] = _.dropRight(result.data, dropValues);
      result['labels'] = _.dropRight(result.labels, dropValues);
    }

    this.datasetBarNumber = [{data: result.data, label: 'Number'}];
    this.labelsBarRating = result.labels;

    if (result?.total) {
      this.total = result.total;
    } else {
      this.total = this.getTotal(this.datasetBarNumber[0].data);
    }
    this.isProgressNumber = false;
    this._loadingService.reportAdvanced(1, 'Number Reviews')
  }

  /**
   * this method get total sum of a array
   */
  getTotal(array: number[] | ChartPoint[]) {
    let total = 0;
    array?.forEach(x => {
      total += x;
    });
    return total;
  }


}
