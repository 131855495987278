import { __assign, __extends, __read, __spread } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { Location as AngularLocation } from '@angular/common';
// app
import { SAVE_COMPLETE_SCAN, SAVE_COMPLETE_EDIT_SCAN, SAVE_COMPLETE_PROTOCOL, SAVE_COMPLETE_EDIT_PROTOCOL } from '../../constants/firestore/protocol';
import { ReportService } from '../../services/report.service';
import { ModalCreateProtocolComponent } from '../modal-create-protocol/modal-create-protocol.component';
import { ProtocolScanComponent } from '../protocol-scan/protocol-scan.component';
import { ProtocolListComponent } from '../protocol-list/protocol-list.component';
import { BaseComponent } from 'src/app/components/base.component';
var ReviewAutoResponderComponent = /** @class */ (function (_super) {
    __extends(ReviewAutoResponderComponent, _super);
    function ReviewAutoResponderComponent(reportS, dialog, activatedRoute, _angularLocation) {
        var _this = _super.call(this) || this;
        _this.reportS = reportS;
        _this.dialog = dialog;
        _this.activatedRoute = activatedRoute;
        _this._angularLocation = _angularLocation;
        // custom pagination with page numbers
        _this.page = 1;
        _this.action_button_review = "New Protocol";
        _this.show_action_button_review = true;
        _this.selectionProtocol = new SelectionModel(true, []);
        _this.selectedLogs = 0;
        _this.tabs = ['Protocols', 'Activity', 'Scan'];
        return _this;
    }
    ReviewAutoResponderComponent.prototype.ngOnInit = function () {
        var _a, _b;
        this.selectedLogs = ((_a = this.activatedRoute.snapshot.queryParamMap) === null || _a === void 0 ? void 0 : _a.get('tab')) === null ? 0 : Number((_b = this.activatedRoute.snapshot.queryParamMap) === null || _b === void 0 ? void 0 : _b.get('tab'));
        if (this.selectedLogs == 2)
            this._angularLocation.go("/review-assistant");
        this.manualPage = 1;
        this.errorMessage = false;
    };
    ReviewAutoResponderComponent.prototype.newProtocol = function (isEdit, element) {
        var _this = this;
        if (element === void 0) { element = null; }
        var config = {
            width: '540px',
            data: {
                protocol: null,
                edit: false,
                retroactive: false,
                scan: this.selectedLogs === 3 ? true : false
            }
        };
        if (isEdit) {
            config.data.edit = true;
            element.retroactive = false;
            var protocol = __assign(__assign({}, element), { accounts: __spread(element.accounts.map(function (account) { return ({ accountId: account.accountId, gid: account.gid, locations: account.locations }); })) });
            config.data.protocol = protocol;
        }
        var modal = this.dialog.open(ModalCreateProtocolComponent, {
            width: '680px',
            data: config.data
        });
        modal.disableClose = true;
        var sub = this._subscribeSafe(modal.componentInstance.completeAll, function (complete) {
            if (complete === SAVE_COMPLETE_SCAN || complete === SAVE_COMPLETE_EDIT_SCAN) {
                if (_this.selectedLogs !== 3)
                    _this.selectedLogs = 3;
            }
            else if (complete === SAVE_COMPLETE_PROTOCOL || complete === SAVE_COMPLETE_EDIT_PROTOCOL) {
                if (_this.selectedLogs !== 0)
                    _this.selectedLogs = 0;
            }
            if (_this.protocolScan)
                _this.protocolScan.handleReload();
            _this.protocolList.handleReload();
            sub.unsubscribe();
        });
    };
    ReviewAutoResponderComponent.prototype.countLocations = function (element) {
        return this.reportS.countLocation(element);
    };
    ReviewAutoResponderComponent.prototype.tabChange = function ($event) {
        this.selectedLogs = $event.index;
        switch (this.selectedLogs) {
            case 0:
                this.action_button_review = "New Protocol";
                this.show_action_button_review = true;
                this.protocolList.selectedLogs = this.selectedLogs;
                this.protocolList.handleReload();
                break;
            case 2:
                this.show_action_button_review = false;
                this.protocolList.selectedLogs = this.selectedLogs;
                break;
            case 3:
                this.show_action_button_review = true;
                this.action_button_review = "New Scan";
                this.protocolScan.handleReload();
                break;
            default:
                this.action_button_review = "New Protocol";
                this.show_action_button_review = true;
                this.protocolList.selectedLogs = this.selectedLogs;
                break;
        }
    };
    return ReviewAutoResponderComponent;
}(BaseComponent));
export { ReviewAutoResponderComponent };
var TruncatePipe = /** @class */ (function () {
    function TruncatePipe() {
    }
    TruncatePipe.prototype.transform = function (value, args) {
        var _a;
        var limit = args ? parseInt(args, 10) : 10;
        var trail = '...';
        if ((_a = value) === null || _a === void 0 ? void 0 : _a.length) {
            return value.length > limit ? value.substring(0, limit) + trail : value;
        }
        else {
            return value;
        }
    };
    return TruncatePipe;
}());
export { TruncatePipe };
//deprecated
// custom labels for pagination
// export class CustomPaginatorLocalPosts extends MatPaginatorIntl {
//   constructor() {
//     super();
//     this.itemsPerPageLabel = 'Items per page';
//   }
//   // change range label
//   getRangeLabel = (page: number, pageSize: number, length: number) => {
//     if (length === 0 || pageSize === 0) {
//       return `0 / ${length}`;
//     }
//     length = Math.max(length, 0);
//     const startIndex = page * pageSize;
//     const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
//     return `${startIndex + 1} - ${endIndex} of ${length} items`;
//   };
// }
