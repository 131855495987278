import { __extends } from "tslib";
import { SnackbarService } from 'src/app/services/snackbar.service';
import { OnInit, EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { StorageImageService } from 'src/app//services/storage-image.service';
import { BaseComponent } from 'src/app/components/base.component';
var FormInputUploadMultipleComponent = /** @class */ (function (_super) {
    __extends(FormInputUploadMultipleComponent, _super);
    function FormInputUploadMultipleComponent(_storageImgService, _modalService, _snackService) {
        var _this = _super.call(this) || this;
        _this._storageImgService = _storageImgService;
        _this._modalService = _modalService;
        _this._snackService = _snackService;
        _this.multiple = true;
        _this.maxNumberPhotos = 10;
        // @Input() category: TypeImages = 'PHOTO';
        _this.imageId = '';
        _this.actionText = 'post';
        // @Output() url = new EventEmitter();
        // @Output() percentUpload = new EventEmitter();
        _this.ref = new EventEmitter();
        _this.isUploading = false;
        _this.index = 0;
        _this.mediaUrlArray = [];
        _this.swiperConfig = {
            slidesPerView: 4,
            initialSlide: 0,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            simulateTouch: true,
            slideToClickedSlide: true
        };
        _this.imageTxt = 'Upload images';
        return _this;
    }
    FormInputUploadMultipleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.imageTxt = this.multiple ? 'Upload images' : 'Upload an image';
        this.fileDeletedSubscription = this._storageImgService.fileDeleted$.subscribe(function () {
            _this.resetFileInput();
        });
    };
    FormInputUploadMultipleComponent.prototype.resetFileInput = function () {
        this.fileInput.nativeElement.value = '';
    };
    // target should be the type returned by the event.target of a file input
    FormInputUploadMultipleComponent.prototype.filesChanged = function (target) {
        var files = target.files;
        // const inputElement = target as HTMLInputElement;
        if (files instanceof FileList && files.length > 0) {
            if (files.length > this.maxNumberPhotos) {
                this._snackService.openWarning("You can only upload up to " + this.maxNumberPhotos + " images.", 4000);
                return;
            }
            this._storageImgService.fileChanged(target, false, this.requirements, null, this.maxNumberPhotos);
        }
    };
    return FormInputUploadMultipleComponent;
}(BaseComponent));
export { FormInputUploadMultipleComponent };
