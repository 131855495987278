import { __awaiter, __generator } from "tslib";
// dep
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environment';
// app
import { EXTERNAL_GRADES, GROUPS } from '../constants/firestore/collections';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
var ExternalGradeService = /** @class */ (function () {
    function ExternalGradeService(_afs, _http) {
        this._afs = _afs;
        this._http = _http;
    }
    ExternalGradeService.prototype.getExternalGradeToken = function (gid, externalGradeId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this._afs.collection(GROUPS).doc(gid).collection(EXTERNAL_GRADES).doc(externalGradeId).get().toPromise()];
                    case 1: return [2 /*return*/, ((_a = (_b.sent()).data()) === null || _a === void 0 ? void 0 : _a.googleAuth) || null];
                }
            });
        });
    };
    ExternalGradeService.prototype.uploadExternalGradePDF = function (gid, loc_id, acc_id, pdf, grade_lead_id, branding) {
        var data = {
            gid: gid,
            loc_id: loc_id,
            acc_id: acc_id,
            pdf: pdf,
            grade_lead_id: grade_lead_id,
            branding: branding
        };
        return this._http.post(environment.apiUrl + "/v2/pdf/save", data).toPromise();
    };
    ExternalGradeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExternalGradeService_Factory() { return new ExternalGradeService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: ExternalGradeService, providedIn: "root" });
    return ExternalGradeService;
}());
export { ExternalGradeService };
