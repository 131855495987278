
      <div class="dialog__content dialog--featured" mat-dialog-content>
          <img src="/assets/images/modal-star.png" alt="Image of a star" class="dialog__icon" width="128" height="128">
          <button mat-dialog-close="" class="btn btn-dialog-close btn--absolute"><i class="txt--lg fal fa-times"></i>
          </button>
          <div *ngIf="!(session$ | async).isMember">
            <div class="dialog-row txt--center">
                <h2 class="heading--xl mb--10 mt--10 txt--black"><strong>Just as a heads up...</strong></h2>
                <p class="heading-h2 mb--10">You will only be able to access the last 12 months of data. If you'd like to
                    see more, please upgrade.</p>
            </div>
            <div class="d-flex justify-content-center mb--10">
                <button mat-flat-button color="accent" class="btn btn--md txt--md" mat-dialog-close (click)="openUpgrade()">
                Upgrade Now
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <a class="txt--lightblue cursor--pointer mt--5" (click)="dismissModal()" mat-dialog-close>
                Dismiss until tomorrow
            </a>
            </div>
          </div>
          <div *ngIf="!(session$ | async).isMember">
            <div class="dialog-row txt--center">
                <h2 class="heading--xl mb--10 mt--10 txt--black"><strong>Just as a heads up...</strong></h2>
                <p class="heading-h2 mb--10">You will only be able to access the last 12 months of data. If you'd like to
                    see more, please contact your account administrator.</p>
            </div>
          </div>
      </div>
  