// dep
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Title } from '@angular/platform-browser';

// app
import {LocationService} from '../../services/location.service';
import {ModalShareComponent} from '../modal-share/modal-share.component';
import {DataPicker} from '../../constants/data-picker';
import {ModalService} from '../../services/modal.service';
import {GradeExplainComponent} from '../grade-explain/grade-explain.component';
import {PdfService} from '../../services/pdf.service';
import { LoadingService } from '../../services/loading.service';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { GradeLoadedService } from 'src/app/services/grade-loaded.service';
import { ExternalGradeService } from 'src/app/services/external-grade.service';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import { SessionService } from 'src/app/services/session.service';
import { BROWSER_DOMAIN } from 'src/app/helpers/utils.helpers';

@Component({
  selector: 'app-grade-header-location',
  templateUrl: './grade-header-location.component.html',
  styleUrls: ['./grade-header-location.component.scss']
})
export class GradeHeaderLocationComponent implements OnInit {
  @Output() finish = new EventEmitter();
  @Input() accountId: string;
  @Input() locationId: string;
  @Input() dataPicker: DataPicker;
  @Input() gid: string;
  @Input() grade: number;
  @Input() isExternal = false;

  location: SavedLocation
  isShared: boolean;
  progress = true;
  id: any;
  circleColorFirst: string;
  circleColorSecond: string;
  circlePercent: number;
  circleGrade: string;
  firebaseGradeLeadId: string;
  exportInProgress = false;

  constructor(
    private route: ActivatedRoute,
    private locationS: LocationService,
    private modalS: ModalService,
    private _sessionS: SessionService,
    private titleService: Title,
    private pdfS: PdfService,
    private loadingService: LoadingService,
    private gradeLoaded: GradeLoadedService,
    private gradeLeadService: GradeLeadService,
    private externalGradeService: ExternalGradeService
  ) {
  }

  ngOnInit() : void {
    // TODO: the two below should be dynamic (the colors too if they depend on the percentage)
    this.circlePercent = 40;
    this.circleGrade = 'A';
    this.circleColorFirst = '#ff4949';
    this.circleColorSecond = '#E91616';

    if (!this.gid) {
      this.id = this.route.snapshot?.params?.id;
      // if (this.isExternal) 
      //     this.gid =  this.auth.externalSession.gid;
      // else 
      //     this.gid = this.route.snapshot?.params?.gid || this._sessionS.getSession().gid;

      if (this.isExternal) 
          this.gid = this._sessionS.getSession().gid;
      else 
          this.gid = this.route.snapshot?.params?.gid || this._sessionS.getSession().gid;
 
      if (this.id) {
        this.isShared = true;
      }
    }

    this.locationS.getRef(this.gid, this.accountId, this.locationId).subscribe( loc => {
      this.progress = false;
      this.location = loc;
      this.finish.emit(true);
      if (this.isExternal) {
        const {branding} = this._sessionS.getDomain();
        this.titleService.setTitle(`Grade | ${loc.locationName} | ${branding.company_name}`);
      }
      this.loadingService.reportAdvanced(1, "Locations")
    }, () => {
      this.finish.emit(false);
      this.progress = false;
    });

    if(this.isExternal){
      this.gradeLoaded.loaded$.subscribe(result => {
        if(result){
          setTimeout(()=>{
            this._exportPdf(false);
          },2000); // Time to finish animations before export
        }
      })
    }

    this.gradeLeadService.firebaseGradeId$.subscribe(id =>{
      this.firebaseGradeLeadId = id;
    })
  }

  handleShared() : void {
    this.modalS.openGenericModal(ModalShareComponent, {
      createNew: true,
      dataPicker: this.dataPicker,
      reportType: 'grade',
    });
  }

  openExplain() : void {
    this.modalS.openGenericModal(GradeExplainComponent, {}, null, 900);
 }

  handlePdf() : void {
    this._exportPdf()
  }

  private async _exportPdf(saveToDisk = true) : Promise<void> {
    const filename = `Grader - ${this.location.locationName}`;
    const pdfBase64 = await this.pdfS.base64AndSave('charts-container', filename, '', saveToDisk); 

    this.exportInProgress = true;
    if(saveToDisk){
      this.exportInProgress = false;
      return;
    }

    if(this.isExternal) {
      await this.externalGradeService.uploadExternalGradePDF(
        this.gid, 
        this.locationId, 
        this.accountId, 
        pdfBase64, 
        this.firebaseGradeLeadId,
        this._sessionS.getDomain().branding);

      const domain = BROWSER_DOMAIN.domainNameWithPort;
      try {
        const gradeLead = await this.gradeLeadService.getGradeLeadById(this.firebaseGradeLeadId, domain);
        this.gradeLeadService.setHasPdf(gradeLead);
      } finally {
        this.exportInProgress = false;
      }
    }

    if (!pdfBase64) {
      return;
    }
  }
}
