// dep
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

// app
import {GoogleService} from '../../../services/google.service';
import {LocationEditService} from '../../../services/location-edit.service';
import {GoogleAttributes, GroupAttributes} from '../../../constants/google/attributes';
import {ModalAttributesComponent} from '../../modal-attributes/modal-attributes.component';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-location-attributes',
  templateUrl: './location-attributes.component.html',
  styleUrls: ['./location-attributes.component.scss']
})
export class LocationAttributesComponent implements OnInit {
  @Input() attributes = [];
  @Input() socialMedia = [];
  @Input() isEdit = true;
  @Output() urls = new EventEmitter<GoogleAttributes[]>();
  @Output() onUpdate= new EventEmitter<boolean>();

  public googleItemActive: boolean;
  public isProgressAttributes: boolean;
  public attributesEnabled: boolean;
  public googleAttributes: GoogleAttributes[];
  public attributesInGroups: GroupAttributes[];
  public leastOneGroupActive: boolean;
  public urlAttributes: GoogleAttributes[] = [];
  public excludedAtributes: GoogleAttributes[] = [];
  public socialMediaAttributes = {};
  public allSocialMediaOptions = [];

  constructor(
    private _sessionS: SessionService,
    private _dialogS: MatDialog,
    private _googleS: GoogleService,
    private _locationEditS: LocationEditService,
  ) {
  }

  ngOnInit() {
    this.allSocialMediaOptions = this._locationEditS.allSocialMediaOptions;

    if (this.isEdit) {
      this.sortAttributes();
    } else {
        const clonedAttributes = [...this.attributes];
        this.socialMedia = clonedAttributes.filter(el => this._locationEditS.isSocialMedia(el));
        this.attributes = clonedAttributes.filter(el => !this._locationEditS.isSocialMedia(el));
    }
  }

  sortAttributes(): void {
    this._locationEditS.locationEdit$.subscribe(locationEdit => {
      if (locationEdit) {
        this.isProgressAttributes = true;

        this._googleS
          .groupsWithAttributeSaved(
            this._sessionS.getSession().gid,
            this._locationEditS.accountId,
            this._locationEditS.locationId,
            locationEdit.primaryCategory.categoryId,
            this.attributes,
            this.googleAttributes
          )
          .then(groupResult => {
            this.attributesInGroups = [];
            this.urlAttributes = [];
            this.socialMediaAttributes = [];
            this.isProgressAttributes = false;
            this.socialMediaAttributes = groupResult.groups.find(el => el.groupName == 'Place page URLs');
            this.attributesInGroups = this.sortAttrInGroups(groupResult.groups);
            const socialMediaIndex = groupResult.groups.findIndex(g => g.groupName === 'Place page URLs');
            // this.attributesInGroups.splice(socialMediaIndex, 1);
            this.attributesInGroups[socialMediaIndex].items = this.removeSocialMedia(groupResult.groups, socialMediaIndex);
            this.excludedAtributes = groupResult.excludedAtributes;
            this.leastOneGroupActive = false;
            this.googleItemActive = false;
            this.attributesInGroups.forEach(group => {
              if (group.active) {
                group.items.forEach(item => {
                  if (item.valueType === "URL" && !this._locationEditS.isSocialMedia(item)) {
                    const excludedItem = groupResult.excludedAtributes.find(el => el.attributeId === item.attributeId);
                    if (excludedItem?.uriValues) {
                      item.urlValues = excludedItem.uriValues;
                      item.urlValues.forEach(el => {
                        el.url = el['uri'];
                        delete el['uri']
                      });
                    }
                    this.urlAttributes.push(item);

                  }
                  if (item.active) {
                    this.googleItemActive = true;
                  }
                });

                this.urls.emit(this.urlAttributes);
                this.leastOneGroupActive = true;
              }
            });
            this.googleAttributes = groupResult.googleAttributes;
          });
      }
    });
  }

  removeSocialMedia(groups, socialMediaIndex) {
    const excludedAttributeIds = ["url_facebook", "url_pinterest", "url_youtube", "url_instagram", "url_twitter", "url_linkedin", "url_tiktok"];

    return groups[socialMediaIndex]?.items?.filter(item => !excludedAttributeIds.includes(item.attributeId));
  }

  sortAttrInGroups(data): [] {
    data = data.sort(function(a, b){ return (a.groupName < b.groupName ? -1 : 1)});
    const indexFromBuss = data.findIndex(el => el.groupName == 'From the business')

    if (indexFromBuss != -1) {
      data.unshift(data[indexFromBuss]);
      data.splice((indexFromBuss + 1), 1);
    }
    return data;
  }

  // edit attributes
  openAttributesDialog(isSocialMedia = false): void {
    const dialogRef = this._dialogS.open(ModalAttributesComponent, {
      width: '680px',
      data: {
        isSocialMedia: isSocialMedia,
        groupAttributes: this.attributesInGroups,
        excludedAtributes: this.excludedAtributes,
        socialMedia: this.socialMedia
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result != '') {
        this.attributes = result.attributes;
        this.attributesEnabled = result.attributesEnabled;
        this.sortAttributes();
        this.onUpdate.emit(true);
      }
    });
  }

  getSocialMediaItems(item): {} {
    return this.allSocialMediaOptions.find(opt => opt.value === item);
  }

  getUrl(item): string {
    return item?.urlValues?.[0]?.url || '-';
  }

  hasAttributes(): boolean {
    return !!this.attributes.find(el => el.valueType != 'URL')
  }

  get socialMediaItems(): any [] {
    return this.socialMedia;
  }

  get attributesItems(): any [] {
    return this.attributes;
  }


}

