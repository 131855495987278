import { __assign } from "tslib";
import { environment } from '@environment';
import { Subject } from 'rxjs';
import { Granularity } from '../../../constants/data-picker';
/* Definition of abstract service to be implemented by each report service
*
*/
var ReportsFilterConfigService = /** @class */ (function () {
    function ReportsFilterConfigService(_http) {
        this._http = _http;
        // convert into single observable/subject for all date-related stuff
        this._dateRestrictionSubject = new Subject();
        this._dateRangeSubject = new Subject();
        this._reportDataSubject = new Subject();
    }
    Object.defineProperty(ReportsFilterConfigService.prototype, "dateRestrictionSubject$", {
        get: function () {
            return this._dateRestrictionSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "dateRangeSubject$", {
        get: function () {
            return this._dateRangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "keywordsReportSubject$", {
        get: function () {
            return this._reportDataSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ReportsFilterConfigService.prototype.initializeDateRestriction = function (dateRangeLimits, reportType) {
        console.log();
        var newDateRangeLimits = {
            minDate: new Date(dateRangeLimits.minDate),
            maxDate: new Date(dateRangeLimits.maxDate)
        };
        console.log('newDateRangeLimits', newDateRangeLimits);
        // definition of new date variables
        var newMaxDate = null;
        var newMinDate = null;
        // definition of calculation variables
        var today = new Date();
        var todayDay = today.getDate();
        var todayMonth = today.getMonth();
        var currentMaxDate = new Date(newDateRangeLimits.maxDate);
        var currentMaxDateDay = currentMaxDate.getDate();
        var currentMaxDateMonth = currentMaxDate.getMonth();
        var currentMaxDateYear = currentMaxDate.getFullYear();
        var currentMinDate = new Date(newDateRangeLimits.minDate);
        var currentMinDateDay = currentMinDate.getDate();
        var currentMinDateMonth = currentMinDate.getMonth();
        var currentMinDateYear = currentMinDate.getFullYear();
        // first convert currentMaxDate to be the last day of the previous month, if its not already the last day of its month
        if (currentMaxDateDay !== new Date(currentMaxDateYear, currentMaxDateMonth + 1, 0).getDate()) { // not the last day of the month
            newMaxDate = new Date(currentMaxDateYear, currentMaxDateMonth, 0); // last day of the previous month
            console.log('newMaxDate', newMaxDate);
            newDateRangeLimits = __assign(__assign({}, newDateRangeLimits), { maxDate: newMaxDate });
        }
        // if today is between 1 and 6 of the month and currentMaxDateMonth is this month, we substract one month from newMaxDate
        // only applies for keywords and performance reports
        if ((reportType === 'keyword' || reportType === 'performance')
            && todayDay >= 1 && todayDay <= 6
            && ((newDateRangeLimits.maxDate.getMonth() === todayMonth) || (newDateRangeLimits.maxDate.getMonth() === todayMonth - 1))) {
            if (newDateRangeLimits.maxDate.getMonth() === 0) {
                newMaxDate = new Date(newMaxDate.getFullYear() - 1, 11, 31);
            }
            else {
                newMaxDate = new Date(newMaxDate.getFullYear(), newMaxDate.getMonth(), 0); // last day of the previous month
            }
            newDateRangeLimits = __assign(__assign({}, newDateRangeLimits), { maxDate: newMaxDate });
        }
        // we convert the currentMinDate to be the first day of the next month, if its not already the first day of its month
        if (currentMinDateDay !== 1) { // not the first day of the month
            console.log('not the first day of the month');
            if (currentMinDateMonth === 11) { // last month
                newMinDate = new Date(currentMinDateYear + 1, 0, 1);
            }
            else {
                newMinDate = new Date(currentMinDateYear, currentMinDateMonth + 1, 1);
            }
            newDateRangeLimits = __assign(__assign({}, newDateRangeLimits), { minDate: newMinDate });
        }
        console.log('newDateRangeLimits after conversions', newDateRangeLimits);
        this._starterMaxDate = newDateRangeLimits.minDate;
        this._starterMaxDate = newDateRangeLimits.maxDate;
        this._dateRestrictionSubject.next(newDateRangeLimits);
    };
    ReportsFilterConfigService.prototype.emitDateRestriction = function (dateRestriction) {
        if (dateRestriction) {
            this._dateRestrictionSubject.next(dateRestriction);
        }
    };
    // should fire the re-renderization of associated graphs
    ReportsFilterConfigService.prototype.emitDateRange = function (dateRange) {
        this._dateRangeSubject.next(dateRange);
    };
    ReportsFilterConfigService.prototype.buildGranularity = function (startDate, endDate) {
        var granularity = null;
        // DAY available when selectedRange = 1 month (default on 1 month)
        // WEEK available when selectedRange >= 2 months AND selectedRange <= 12 months (default on 2 - 5 months)
        // MONTH available when selectedRange >= 3 months (default on 6+ months)
        if (startDate.getMonth() === endDate.getMonth()) {
            granularity = Granularity.Day;
        }
        else if (endDate.getMonth() - startDate.getMonth() >= 1 && endDate.getMonth() - startDate.getMonth() <= 2) {
            granularity = Granularity.Day;
        }
        else if (endDate.getMonth() - startDate.getMonth() >= 3) {
            granularity = Granularity.Month;
        }
        return granularity;
    };
    ReportsFilterConfigService.prototype.getDateValidation = function (reportType, accountIds, gids, locationIds) {
        var data = {
            "locationIds": locationIds,
            "accountIds": accountIds,
            "gids": gids,
            "type": reportType
        };
        return this._http.post(environment.apiUrl + "/v2/locations/date-validation", data);
    };
    return ReportsFilterConfigService;
}());
export { ReportsFilterConfigService };
