<!--loader-->
<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!(location && grade)" class="screen-height d-flex align-items-center justify-content-center">
  <div class="container--md">
    <div class="box box--padding bg--white flex-column justify-content-center align-items-center d-flex">

      <img alt="Google My Business Logo" src="/assets/images/my_business_logo.png"
           class="mb--20 img--round"/>

      <h2 class="heading--bigger txt--black fw--black txt--center txt--gray mb--15">
        Grade your Google Business Profile
      </h2>
      <p class="txt--lg txt--center mb--30">
        To get your report, just log in with Google My Business account on the next page and click "allow".
      </p>

      <button (click)="openAuth()" class="btn btn--icon btn--lg " mat-flat-button color="accent">
        <span class="txt--lg"><strong>Start Grading</strong></span> <i class="far fa-arrow-right ml--15"></i>
      </button>

    </div>
  </div>
</div>

<div *ngIf="location && grade">
  <!-- Shown on top -->
  <div class="grade-message">
    <p class="m--0">
      <strong>Improve your grade! Get started on {{(domain$ | async).branding.company_name}}</strong> 
      <button mat-button class="btn btn--white btn--xs ml--10" (click)="navigationS.goToRegister()"><strong>Sign Up Now</strong></button>
    </p>
  </div>
  <mat-toolbar class="header header-grade d-flex justify-content-between bg--white">
    <div class="logo logo-grade">
      <a [routerLink]="['/login']" class="d-block p--0">
        <img alt="Homepage." src="{{(domain$ | async).branding.loginLogo || '/assets/images/logo/blankmap.png'}}" class="logo" width="300" height="115"/>
      </a>
    </div>

    <nav class="nav-grade">
      <span class="d-none">
        <a href="https://maplabs.codeart.rocks/articles/">articles</a>
        <a href="https://maplabs.codeart.rocks/pricing/">pricing</a>
        <a href="https://maplabs.codeart.rocks/contact/">contact</a>
      </span>
      <button class="btn btn--white btn--grade ml--15" [routerLink]="['/login']">Log In</button>
      <button mat-flat-button color="accent" class="btn btn--grade ml--20" (click)="navigationS.goToRegister()">Sign Up</button>
    </nav>
  </mat-toolbar>
  <div *ngIf="!!(session$ | async)" class="container--full container--padding-lg">
    <app-dashboard-grade [locations]="[location]" 
                         [externalAccountId]="accountId" 
                         [externalLocationId]="locationId" 
                         [userExternal]="userExternal" 
                         [user]="(session$ | async).user">
    </app-dashboard-grade>

    <div class="grade-popup grade-circles" [class.close]="!showGradePopup">
      <div class="grade-popup__content grade-border txt--center">
        <a href="" class="grade-popup-close" (click)="closePopup($event)"><i class="fal fa-times"></i></a>
        <h2 class="heading--bigger txt--black fw--black mb--10">Improve your grade!</h2>
        <p class="txt--lg">Get Started Today</p>
        <button mat-flat-button color="accent" class="btn btn--md" (click)="navigationS.goToRegister()">Sign Up Now</button>
      </div>
    </div>
  </div>
</div>

