import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  constructor(
    private http: HttpClient
  ) { }

  search(query: string, gid: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v2/search/global-search/${gid}`, {query});
  }
}
