import { SkeletonLocationInfoBoxComponent } from './skeleton-location-info/skeleton-location-info-box/skeleton-location-info-box.component';
import { MatCheckboxModule, MatButtonModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonWorkshopComponent } from './skeleton-workshop/skeleton-workshop.component';
import { SkeletonTableMultilocationsComponent } from './skeleton-table-multilocations/skeleton-table-multilocations.component';
import { SkeletonSearchKeywordsComponent } from './skeleton-search-keywords/skeleton-search-keywords.component';
import { SkeletonReviewsComponent } from './skeleton-reviews/skeleton-reviews.component';
import { SkeletonReviewReportComponent } from './skeleton-review-report/skeleton-review-report.component';
import { SkeletonRevenueComponent } from './skeleton-revenue/skeleton-revenue.component';
import { SkeletonReportsComponent } from './skeleton-reports/skeleton-reports.component';
import { SkeletonQuestionsAnswerComponent } from './skeleton-questions-answer/skeleton-questions-answer.component';
import { SkeletonProtocolLogComponent } from './skeleton-protocol-log/skeleton-protocol-log.component';
import { SkeletonProtocolComponent } from './skeleton-protocol/skeleton-protocol.component';
import { SkeletonPostsComponent } from './skeleton-posts/skeleton-posts.component';
import { skeletonPaymentMethodComponent } from './skeleton-payment-method/skeleton-payment-method.component';
import { SkeletonLocationInfoListComponent } from './skeleton-location-info-list/skeleton-location-info-list.component';
import { SkeletonLocationInfoComponent } from './skeleton-location-info/skeleton-location-info.component';
import { SkeletonLocationComponent } from './skeleton-location/skeleton-location.component';
import { SkeletonInvoicesComponent } from './skeleton-invoices/skeleton-invoices.component';
import { SkeletonInsightsComponent } from './skeleton-insights/skeleton-insights.component';
import { SkeletonGradeComponent } from './skeleton-grade/skeleton-grade.component';
import { SkeletonComparisonComponent } from './skeleton-comparison/skeleton-comparison.component';
import { SkeletonAccountsComponent } from './skeleton-accounts/skeleton-accounts.component';

@NgModule({
    declarations: [
        SkeletonAccountsComponent,
        SkeletonComparisonComponent,
        SkeletonGradeComponent,
        SkeletonInsightsComponent,
        SkeletonInvoicesComponent,
        SkeletonLocationComponent,
        SkeletonLocationInfoComponent,
        SkeletonLocationInfoBoxComponent,
        SkeletonLocationInfoListComponent,
        skeletonPaymentMethodComponent,
        SkeletonPostsComponent,
        SkeletonProtocolComponent,
        SkeletonProtocolLogComponent,
        SkeletonQuestionsAnswerComponent,
        SkeletonReportsComponent,
        SkeletonRevenueComponent,
        SkeletonReviewReportComponent,
        SkeletonReviewsComponent,
        SkeletonSearchKeywordsComponent,
        SkeletonTableMultilocationsComponent,
        SkeletonWorkshopComponent
    ],
    imports: [
        CommonModule,
        NgxSkeletonLoaderModule,
        MatCheckboxModule,
        MatButtonModule,
    ],
    entryComponents: [
    ],
    providers: [
    ],
    exports: [
        SkeletonAccountsComponent,
        SkeletonComparisonComponent,
        SkeletonGradeComponent,
        SkeletonInsightsComponent,
        SkeletonInvoicesComponent,
        SkeletonLocationComponent,
        SkeletonLocationInfoComponent,
        SkeletonLocationInfoBoxComponent,
        SkeletonLocationInfoListComponent,
        skeletonPaymentMethodComponent,
        SkeletonPostsComponent,
        SkeletonProtocolComponent,
        SkeletonProtocolLogComponent,
        SkeletonQuestionsAnswerComponent,
        SkeletonReportsComponent,
        SkeletonRevenueComponent,
        SkeletonReviewReportComponent,
        SkeletonReviewsComponent,
        SkeletonSearchKeywordsComponent,
        SkeletonTableMultilocationsComponent,
        SkeletonWorkshopComponent
    ]
})
export class SkeletonsModule { }
